import { Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import type { ProjectDetails_study$data } from "../../__generated__/ProjectDetails_study.graphql";
import type { ProjectDetails_tenant$data } from "../../__generated__/ProjectDetails_tenant.graphql";
import type { ProjectDetails_viewer$data } from "../../__generated__/ProjectDetails_viewer.graphql";
import ProjectDetailsPage from "../Configure/ProjectDetailsPage";

const { Title } = Typography;

type Props = {
  study: ProjectDetails_study$data;
  tenant: ProjectDetails_tenant$data;
  viewer: ProjectDetails_viewer$data;
  languages: any[];
};
const ProjectDetails = ({ study, tenant, viewer, languages }: Props) => {
  return (
    <ProjectDetailsPage
      study={study}
      languages={languages}
      privacyPolicyOptions={null}
      isSettingsPage={true}
      tenant={tenant}
      title={
        <Title level={2} className="create-project-title">
          Project Details
        </Title>
      }
      viewer={viewer}
    />
  );
};

export default createFragmentContainer(ProjectDetails, {
  study: graphql`
    fragment ProjectDetails_study on StudyNode {
      ...ProjectDetailsPage_study
    }
  `,
  tenant: graphql`
    fragment ProjectDetails_tenant on TenantNode {
      ...ProjectDetailsPage_tenant
    }
  `,
  viewer: graphql`
    fragment ProjectDetails_viewer on Viewer {
      ...ProjectDetailsPage_viewer
    }
  `,
});
