import { Button, Modal, Table } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { PROJECT_INCENTIVE_TYPES, PROJECT_STATUSES } from "../../constants";
import { usdFormatter } from "../../utils/misc";
import { SpendingDetails_study$data } from "../../__generated__/SpendingDetails_study.graphql";
import { ExportSpendingDetailsButton } from "./ExportSpendingDetailsButton";

type Props = {
  visible: boolean;
  onClose: () => void;
  study: SpendingDetails_study$data;
};
const SpendingDetails: React.FC<Props> = ({ visible, onClose, study }) => {
  const renderNumberCell = (value: string, formatter: (value: string) => string) => {
    const float = Number.parseFloat(value);
    return <div className="number">{!isNaN(float) ? formatter(value) : "—"}</div>;
  };
  const renderNumber = (value: string) => renderNumberCell(value, value => value);
  const renderUsd = (value: string) => renderNumberCell(value, value => usdFormatter.format(Number(value)));

  const dataSource = [
    // Spread recruits nodes for data rows
    ...study.recruits.edges.map((recruit, i) => ({
      ...recruit?.node,
      key: i,
      costPerParticipant: String(Number(recruit?.node?.participantBaseCost) + Number(recruit?.node?.fee)),
    })),

    // Use study for the totals row
    {
      ...study,
      participantBaseCost: study.totalBaseCosts,
      fee: study.totalFees,
      costPerParticipant: study.totalPerParticipantCosts,
      key: "totals",
      name: "Total",
    },
  ];

  const columns = [
    { title: "Recruiting Round", dataIndex: "name", width: 150 },
    { title: "Recruitment goal", dataIndex: "goalPaidForCount", render: renderNumber, width: 50 },
    {
      title: study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL ? "Base cost" : "Incentive",
      dataIndex: "participantBaseCost",
      render: renderUsd,
    },
    { title: "Fee", dataIndex: "fee", render: renderUsd },
    { title: "Cost per Participant", dataIndex: "costPerParticipant", render: renderUsd },
    { title: "Recruiting Round Cost", dataIndex: "totalDeposits", render: renderUsd },
    // Show actual refunds for complete studies, otherwise refunds due
    study.status === PROJECT_STATUSES.COMPLETE
      ? {
          title: "Refunded",
          dataIndex: "totalRefunds",
          render: renderUsd,
        }
      : {
          title: "Balance Remaining",
          dataIndex: "totalRefundsDue",
          render: renderUsd,
        },
    { title: "Total Costs", dataIndex: "totalPayouts", render: renderUsd },
  ];

  return (
    <StyledSpendingDetails
      open={visible}
      onCancel={onClose}
      title={<div className="header">Project Costs</div>}
      closable={false}
      width="960px"
      // Default antd modal z-index is 1000; 1001 prevents newly-created modals from obscuring this one
      zIndex={1001}
      footer={
        <div className="footer">
          <Button onClick={onClose}>Close</Button>
          <ExportSpendingDetailsButton study={study} />
        </div>
      }
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        rowClassName={record => (record.key === "totals" ? "totals-row" : "")}
      />
    </StyledSpendingDetails>
  );
};

const StyledSpendingDetails = styled(Modal)`
  .ant-modal-header,
  .ant-modal-footer {
    border-width: 0;
  }

  .header {
    font-size: 20px;
  }

  .header,
  .number,
  .footer {
    text-align: center;
  }

  .totals-row {
    font-weight: bold;
  }
`;

export default createFragmentContainer(SpendingDetails, {
  study: graphql`
    fragment SpendingDetails_study on StudyNode {
      dId
      status
      incentiveType
      tenant {
        name
      }
      goalPaidForCount
      totalBaseCosts
      totalFees
      totalPerParticipantCosts
      totalDeposits
      totalPayouts
      totalRefunds
      totalRefundsDue
      recruits {
        edges {
          node {
            name
            goalPaidForCount
            participantBaseCost
            fee
            totalDeposits
            totalPayouts
            totalRefunds
            totalRefundsDue
          }
        }
      }
      ...ExportSpendingDetailsButton_study
    }
  `,
});
