import { MoreOutlined } from "@ant-design/icons";
import { App, Dropdown, Menu, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import moment, { Moment as MomentType } from "moment";
import { useContext, useState } from "react";
import { useFragment } from "react-relay";
import { PROJECT_TYPES } from "../../constants";
import { GRAY_8 } from "../../style";
import { ActionType } from "../../types";
import { mutation, TimeZoneContext, useConfirm } from "../../utils";
import { join, maybeElement } from "../../utils/misc";
import { ScheduledSlotCell_respondent$key } from "../../__generated__/ScheduledSlotCell_respondent.graphql";
import { ScheduledSlotCell_study$key } from "../../__generated__/ScheduledSlotCell_study.graphql";
import { Moment } from "../Moment";
import { ScheduleModal } from "../Slots/ScheduleModal";

import { CellInner } from "./Cells";

export const ScheduleMenu = ({ onClick }: { onClick: () => void }) => (
  <Menu>
    <Menu.Item {...{ onClick }}>Manually schedule</Menu.Item>
  </Menu>
);

export const ScheduledSlotCell = ({
  study: _study,
  respondent: _respondent,
  refreshData,
}: {
  study: ScheduledSlotCell_study$key;
  respondent: ScheduledSlotCell_respondent$key;
  refreshData: () => void;
}) => {
  const { notification } = App.useApp();
  const { shiftDate } = useContext(TimeZoneContext);

  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const study = useFragment(
    graphql`
      fragment ScheduledSlotCell_study on StudyNode {
        id
        type
        availabilitySlots {
          edges {
            node {
              id
            }
          }
        }
        ...ScheduleModal_study
      }
    `,
    _study
  );

  const respondent = useFragment(
    graphql`
      fragment ScheduledSlotCell_respondent on RespondentNode {
        id
        scheduledSlots {
          session {
            name
          }
          start
          end
          interviewed
        }
        ...ScheduleModal_respondent
      }
    `,
    _respondent
  );

  const resetSlotMutation = (reschedule: Boolean) =>
    mutation({
      variables: {
        input: {
          studyId: study.id,
          respondentId: respondent.id,
          reschedule: reschedule,
        },
      },
      mutation: graphql`
        mutation ScheduledSlotCell_ResetSlot_Mutation($input: ResetScheduledSlotInput!) {
          resetScheduledSlot(input: $input) {
            respondent {
              id
              masterStatus
            }
            study {
              approvedTabCount
              scheduledCount
              needsReviewCount
            }
          }
        }
      `,
    }).then(() => {
      notification.success({
        message: reschedule ? "Slot canceled and reschedule email sent" : "Slot canceled and respondent notified",
      });
      refreshData();
    });

  const confirm = useConfirm();

  const resetSlotConfirm = () =>
    confirm({
      title: "Reschedule this slot?",
      content: (
        <p>
          The calendar invite will be canceled and the participant will receive an email inviting them to choose a new
          slot.
        </p>
      ),
      okText: "Reset",
      onOk: () => resetSlotMutation(true),
    });

  const cancelSlot = () =>
    confirm({
      title: "Cancel this slot?",
      content: (
        <p>
          The calendar invite will be canceled and the participant will receive an email notifying them of the
          cancellation. This will decline the participant and they will not be invited to reschedule.
        </p>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk: () => resetSlotMutation(false),
    });

  const menu = respondent.scheduledSlots?.length ? (
    <Menu>
      {(study.type === PROJECT_TYPES.INTERVIEW ||
        (study.type === PROJECT_TYPES.FOCUS_GROUP && study.availabilitySlots.edges.length > 1)) && (
        <Menu.Item onClick={resetSlotConfirm} key={1}>
          Smart reschedule
        </Menu.Item>
      )}
      {study.type === PROJECT_TYPES.INTERVIEW && (
        <Menu.Item onClick={() => setShowScheduleModal(true)} key={2}>
          Manually reschedule
        </Menu.Item>
      )}
      <Menu.Item onClick={cancelSlot} key={3}>
        Cancel
      </Menu.Item>
    </Menu>
  ) : (
    <ScheduleMenu onClick={() => setShowScheduleModal(true)} />
  );

  return (
    <CellInner width="100%">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          width: "100%",
        }}
        onClick={e => e.stopPropagation()}
      >
        {respondent.scheduledSlots.length === 1 ? (
          <div>
            {study.type === PROJECT_TYPES.FOCUS_GROUP
              ? "Focus Group on "
              : study.type === PROJECT_TYPES.INTERVIEW
              ? "Interview on "
              : " "}
            <Moment format="M/D/YY h:mm A">{shiftDate(respondent.scheduledSlots[0]!.start as MomentType)}</Moment>
            <span> - </span>
            <Moment format="h:mm A">{shiftDate(respondent.scheduledSlots[0]!.end as MomentType)}</Moment>
          </div>
        ) : (
          <Tooltip
            trigger={[...maybeElement<ActionType>(!!respondent.scheduledSlots.length, "hover")]}
            overlayStyle={{ maxWidth: 350 }}
            title={join(
              [...respondent.scheduledSlots],
              s => (
                <>
                  {s?.session?.name ?? "<Unnamed session>"}: {shiftDate(moment(s?.start)).format("M/D/YY h:mm A")} -{" "}
                  {shiftDate(moment(s?.end)).format("h:mm A")} {s?.interviewed && " (interviewed)"}
                </>
              ),
              <br />
            )}
          >
            <div>-</div>
          </Tooltip>
        )}
        <ScheduleModal
          study={study}
          respondent={respondent}
          showModal={showScheduleModal}
          setShowModal={setShowScheduleModal}
          refreshData={refreshData}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <MoreOutlined
            style={{
              fontSize: "0.8rem",
              marginLeft: "0.2rem",
              cursor: "pointer",
              padding: "0.3rem",
              color: GRAY_8,
            }}
          />
        </Dropdown>
      </div>
    </CellInner>
  );
};
