/**
 * @generated SignedSource<<fb2a01b2eecd1428df44111a1ad28bd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClearLabelsInput = {
  clientMutationId?: string | null;
  studyId: string;
};
export type ProjectScreenerPage_ClearLabels_Mutation$variables = {
  input: ClearLabelsInput;
};
export type ProjectScreenerPage_ClearLabels_Mutation$data = {
  readonly clearLabels: {
    readonly screener: {
      readonly elements: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly label: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};
export type ProjectScreenerPage_ClearLabels_Mutation = {
  response: ProjectScreenerPage_ClearLabels_Mutation$data;
  variables: ProjectScreenerPage_ClearLabels_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectScreenerPage_ClearLabels_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClearLabelsPayload",
        "kind": "LinkedField",
        "name": "clearLabels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenerNode",
            "kind": "LinkedField",
            "name": "screener",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ElementNodeConnection",
                "kind": "LinkedField",
                "name": "elements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ElementNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ElementNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectScreenerPage_ClearLabels_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClearLabelsPayload",
        "kind": "LinkedField",
        "name": "clearLabels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenerNode",
            "kind": "LinkedField",
            "name": "screener",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ElementNodeConnection",
                "kind": "LinkedField",
                "name": "elements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ElementNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ElementNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68be952f93ed42b591c2908f4bbbf72a",
    "id": null,
    "metadata": {},
    "name": "ProjectScreenerPage_ClearLabels_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectScreenerPage_ClearLabels_Mutation(\n  $input: ClearLabelsInput!\n) {\n  clearLabels(input: $input) {\n    screener {\n      elements {\n        edges {\n          node {\n            label\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d17e6e636f373bd0803263ec59317ea0";

export default node;
