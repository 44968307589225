import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";
import { PANELIST_BONUS_POINTS_FEE_PERCENT, USD_PER_POINT } from "../../constants";
import { mutation } from "../../utils";
import { numberFormatter } from "../../utils/misc";
import { GivePointsToPanelistModal_givePointsToPanelist_Mutation } from "../../__generated__/GivePointsToPanelistModal_givePointsToPanelist_Mutation.graphql";
import { GivePointsToPanelistModal_panelist$data } from "../../__generated__/GivePointsToPanelistModal_panelist.graphql";
import { GivePointsToPanelistModal_tenant$data } from "../../__generated__/GivePointsToPanelistModal_tenant.graphql";
import { CostsSummary, GivePointsModal } from "../Participants/GivePointsModal";

const givePointsToPanelist = async (panelistId: string, points: number, expectedCostUsdCents: number) => {
  await mutation<GivePointsToPanelistModal_givePointsToPanelist_Mutation>({
    variables: { input: { panelistId, points, expectedCostUsdCents } },
    mutation: graphql`
      mutation GivePointsToPanelistModal_givePointsToPanelist_Mutation($input: GivePointsToPanelistInput!) {
        givePointsToPanelist(input: $input) {
          panelist {
            rewardsBalance
          }
        }
      }
    `,
  });
  return Promise.resolve();
};

const _GivePointsToPanelistModal = ({
  tenant,
  panelist,
  visible,
  setVisible,
}: {
  tenant: GivePointsToPanelistModal_tenant$data;
  panelist: GivePointsToPanelistModal_panelist$data;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}) => (
  <GivePointsModal
    config={{
      title: `Give points to ${panelist.person?.firstName} ${panelist.person?.lastName?.[0]}.`,
      header: <p>How many points do you want to give?</p>,
      footer: <p>Panelists with points can claim rewards in Participant Portal.</p>,
      buttonLabel: "Next",
      getSuccessNotification: (_, points: number) => ({
        title: `Points given to ${panelist.person?.firstName} ${panelist.person?.lastName?.[0]}.`,
        message: `${panelist.person?.firstName} ${
          panelist.person?.lastName?.[0]
        }. successfully received ${numberFormatter.format(points)} points.`,
      }),
    }}
    computeCostsSummary={async (points: number) => {
      const pointsCostUsdCents = points * Math.round(USD_PER_POINT * 100);
      const feesUsdCents = Math.round(pointsCostUsdCents * PANELIST_BONUS_POINTS_FEE_PERCENT);

      return {
        feesUsdCents,
        pointsCostUsdCents,
        nonTestParticipantCount: 1,
        testParticipantCount: 0,
      };
    }}
    givePoints={(costsSummary: CostsSummary, points: number) =>
      givePointsToPanelist(panelist.id, points, costsSummary.pointsCostUsdCents + costsSummary.feesUsdCents)
    }
    {...{ tenant, visible, setVisible }}
  />
);

export const GivePointsToPanelistModal = createFragmentContainer(_GivePointsToPanelistModal, {
  tenant: graphql`
    fragment GivePointsToPanelistModal_tenant on TenantNode {
      ...GivePointsModal_tenant
    }
  `,
  panelist: graphql`
    fragment GivePointsToPanelistModal_panelist on PanelistNode {
      id
      person {
        firstName
        lastName
      }
    }
  `,
});
