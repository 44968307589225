import { useRerender } from "@react-hookz/web";
import { Form, InputNumber, Select } from "antd";
import type { NamePath } from "antd/lib/form/interface";
import classNames, { type Argument } from "classnames";
import { useMemo, type CSSProperties } from "react";
import styled from "styled-components";
import type { IterableElement, SetNonNullable, SetRequired } from "type-fest";
import { ELEMENT_TYPES, RESPONSE_VALIDATION_TYPE as TYPE } from "../../constants";
import type { ResponseValidationType } from "../../types";
import {
  responseValidationFormKeys,
  type ResponseValidationFormInput,
  type ResponseValidationFormProps,
} from "../../utils";

// JSX doesn't like types with >1 param
type PropsForm<T> = SetNonNullable<
  SetRequired<Pick<ResponseValidationFormProps<T>, "form" | "formRootPath">, "form">,
  "form"
>;

export const ResponseValidationFormItems = <T extends {} = ResponseValidationFormInput>({
  className,
  elementAnswersLength,
  elementType,
  form,
  formRootPath,
  onChange: _onChange,
  style,
}: PropsForm<T> & {
  className?: Argument;
  elementAnswersLength: number;
  elementType: ELEMENT_TYPES | `${ELEMENT_TYPES}`;
  onChange?: () => void;
  style?: CSSProperties;
}) => {
  const isNumber = elementType === ELEMENT_TYPES.NUMBER;
  const isSelectMultiple = elementType === ELEMENT_TYPES.MULTI_SELECT;

  const namePaths = useMemo(() => {
    return responseValidationFormKeys.reduce(
      (acc, k) => ({ ...acc, [k]: formRootPath ? [formRootPath, k].flat() : k }),
      {} as Record<IterableElement<typeof responseValidationFormKeys>, NamePath>
    );
  }, [formRootPath]);

  const rerender = useRerender();
  const onChange = async () => {
    try {
      await form.validateFields(Object.values(namePaths));
    } catch {
    } finally {
      _onChange?.();
      rerender();
    }
  };

  return (
    <Root className={classNames("hub-response-validation-form-items", className)} style={style}>
      <Form.Item
        name={namePaths.responseValidation}
        className="hub-response-validation-form-items-select"
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select an option..."
          onChange={(value: ResponseValidationType) => {
            if (value !== TYPE.BETWEEN) form.setFieldValue(namePaths.target2, undefined);
            onChange();
          }}
        >
          {isSelectMultiple ? (
            <>
              <Select.Option value={TYPE.EXACTLY}>Select exactly</Select.Option>
              <Select.Option value={TYPE.AT_LEAST}>Select at least</Select.Option>
              <Select.Option value={TYPE.AT_MOST}>Select at most</Select.Option>
              <Select.Option value={TYPE.BETWEEN}>Select between</Select.Option>
            </>
          ) : isNumber ? (
            <>
              <Select.Option value={TYPE.AT_LEAST}>Answer must be at least</Select.Option>
              <Select.Option value={TYPE.AT_MOST}>Answer must be at most</Select.Option>
              <Select.Option value={TYPE.BETWEEN}>Answer must be between</Select.Option>
            </>
          ) : null}
        </Select>
      </Form.Item>

      <Form.Item
        className="hub-response-validation-form-items-number"
        name={namePaths.target}
        rules={
          isSelectMultiple
            ? [
                {
                  validator: (_, value: ResponseValidationFormInput["target"]) => {
                    if (value == null) return Promise.reject("Required");

                    const target2: number | null | undefined = form.getFieldValue(namePaths.target2);
                    const min = 1;
                    const max = (target2 == null ? elementAnswersLength : target2) - 1;
                    if (value < min || value > max) return Promise.reject(`Must be between ${min} and ${max}`);

                    return Promise.resolve();
                  },
                },
              ]
            : isNumber
            ? [
                {
                  validator: (_, value: ResponseValidationFormInput["target"]) => {
                    if (value == null) return Promise.reject("Required");

                    const target2: number | null | undefined = form.getFieldValue(namePaths.target2);
                    if (target2 != null && value >= target2) return Promise.reject(`Must be at most ${target2 - 1}`);

                    return Promise.resolve();
                  },
                },
              ]
            : []
        }
      >
        <InputNumber
          onChange={onChange}
          placeholder={
            form.getFieldValue(namePaths.responseValidation) === TYPE.EXACTLY
              ? "Number"
              : form.getFieldValue(namePaths.responseValidation) === TYPE.AT_MOST
              ? "Max"
              : "Min"
          }
        />
      </Form.Item>

      {form.getFieldValue(namePaths.responseValidation) === TYPE.BETWEEN && (
        <Form.Item
          className="hub-response-validation-form-items-number"
          name={namePaths.target2}
          rules={
            isSelectMultiple
              ? [
                  {
                    validator: (_, value: ResponseValidationFormInput["target2"]) => {
                      if (value == null) return Promise.reject("Required");

                      const min = (form.getFieldValue(namePaths.target) ?? 1) + 1;
                      const max = elementAnswersLength;
                      if (value < min || value > max) return Promise.reject(`Must be between ${min} and ${max}`);

                      return Promise.resolve();
                    },
                  },
                ]
              : isNumber
              ? [
                  {
                    validator: (_, value: ResponseValidationFormInput["target2"]) => {
                      if (value == null) return Promise.reject("Required");

                      const target: number | null | undefined = form.getFieldValue(namePaths.target);
                      if (target != null && value <= target) return Promise.reject(`Must be at least ${target + 1}`);

                      return Promise.resolve();
                    },
                  },
                ]
              : []
          }
        >
          <InputNumber onChange={onChange} placeholder="Max" />
        </Form.Item>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  gap: 12px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .hub-response-validation-form-items-select {
    flex: 1;
  }

  > *:not(.hub-response-validation-form-items-select) {
    flex: 0;
  }

  .hub-response-validation-form-items-number {
    flex-basis: 100px;

    .ant-input-number {
      width: 100%;
    }
  }
`;
