import { isEqualSimple } from "@react-hookz/deep-equal";
import { useUpdateEffect } from "@react-hookz/web";
import { Form, FormProps } from "antd";
import { useEffect, useState } from "react";

export const useBoundForm = <T extends {}, Values extends {}>(
  x: T,
  {
    createInitialValues,
    onFinish: _onFinish,
    onReset: _onReset,
    onTouch: _onTouch,
  }: {
    createInitialValues: (x: T) => Values;
    onFinish?: (changedValues: Partial<Values>, values: Values) => void;
    onReset?: () => void;
    onTouch?: () => void;
  }
) => {
  const [form] = Form.useForm<Values>();

  const _initialValues = createInitialValues(x);
  const [initialValues, setInitialValues] = useState(_initialValues);

  useUpdateEffect(() => {
    if (!isEqualSimple(_initialValues, initialValues)) setInitialValues(_initialValues);
  }, [JSON.stringify(_initialValues)]);
  useUpdateEffect(() => {
    form.resetFields();
    setReset(true);
  }, [initialValues]);

  const [changedValues, setChangedValues] = useState<Partial<Values>>({});
  const getChangedValues = <T extends Partial<Values>>(values: T) =>
    Object.fromEntries(
      Object.entries({ ...changedValues, ...values }).filter(
        ([k, v]) => !isEqualSimple(v, initialValues[k as keyof Values])
      )
    ) as Partial<Values>;
  const applyChangedValues = <T extends Partial<Values>>(values: T) => {
    const _changedValues = getChangedValues(values);
    if (!isEqualSimple(_changedValues, changedValues)) setChangedValues(_changedValues);
  };

  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (reset) {
      setChangedValues({});
      setReset(false);
      setTouched(false);
      _onReset?.();
    }
  }, [_onReset, reset, setChangedValues]);

  const [touched, setTouched] = useState(false);
  const touchedPrevious = useState(false);
  useEffect(() => {
    if (touched && !touchedPrevious) _onTouch?.();
  }, [_onTouch, touched, touchedPrevious]);

  const onFinish: FormProps<Values>["onFinish"] = values => {
    applyChangedValues(values);
    _onFinish?.(getChangedValues(values), values);
  };
  const onReset: FormProps<Values>["onReset"] = () => setReset(true);
  const onValuesChange: FormProps<Values>["onValuesChange"] = (_changedValues: Partial<typeof values>, values) => {
    applyChangedValues(_changedValues);

    if (isEqualSimple(values, initialValues)) {
      if (!reset) setReset(true);
    } else if (!touched) setTouched(true);
  };

  return { changedValues, form, initialValues, onFinish, onReset, onValuesChange, touched };
};
