import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Table, type TableProps } from "antd";
import classNames, { type Argument } from "classnames";
import React from "react";
import styled from "styled-components";
import { GRAY_1 } from "../../style";

const AntDTable = ({
  alignPaginatorLeft,
  applyLeftPadding,
  className,
  columns,
  components,
  data,
  defaultFetchCount,
  loading,
  onPageChange,
  onRow,
  page,
  rowSelection,
  style,
  totalRecords,
}: {
  alignPaginatorLeft?: boolean;
  applyLeftPadding?: boolean;
  className?: Argument;
  columns: () => TableProps<any>["columns"];
  components?: TableProps<any>["components"];
  data: any;
  defaultFetchCount: number;
  loading: boolean;
  onPageChange: (value: number) => void;
  onRow?: TableProps<any>["onRow"];
  page: number;
  rowSelection?: TableProps<any>["rowSelection"];
  style?: React.CSSProperties;
  totalRecords: number;
}) => {
  const Paginator = (alignPaginatorLeft: { totalRecords: number; alignPaginatorLeft: boolean | undefined }) => {
    // We use a custom pagination component that only allows to move forward and backward (not to page X),
    // in line with Relay's ability
    return (
      <div
        style={{
          margin: "1rem 1rem 0 0",
          display: "flex",
          justifyContent: alignPaginatorLeft ? "flex-start" : "flex-end",
          alignItems: "center",
          paddingLeft: "16px",
        }}
      >
        <Button
          disabled={page === 1}
          icon={<LeftOutlined />}
          onClick={() => {
            if (page > 1) onPageChange(page - 1);
          }}
        />
        <div style={{ margin: "0 1rem" }}>
          {`${page} / ${Math.max(1, Math.ceil(totalRecords / defaultFetchCount))}`}
        </div>
        <Button
          disabled={page >= Math.ceil(totalRecords / defaultFetchCount)}
          icon={<RightOutlined />}
          loading={loading}
          onClick={() => {
            if (page < Math.ceil(totalRecords / defaultFetchCount)) onPageChange(page + 1);
          }}
        />
      </div>
    );
  };

  return (
    <Styles className={classNames(className)} style={style} applyLeftPadding={applyLeftPadding}>
      <Table
        columns={columns()}
        components={components}
        dataSource={React.useMemo(() => data, [data])}
        loading={loading}
        onRow={record => onRow?.(record || null) ?? {}}
        pagination={false}
        scroll={{ x: "max-content" }}
        sticky
        rowClassName={applyLeftPadding ? "custom-left-padding row-height" : "row-height"}
        rowKey={record => record.node.id}
        rowSelection={rowSelection}
      />
      <Paginator totalRecords={totalRecords} alignPaginatorLeft={alignPaginatorLeft} />
    </Styles>
  );
};

const Styles = styled.div<{ applyLeftPadding?: boolean | undefined }>`
  .ant-table-empty .ant-table-header {
    display: none;
  }

  .ant-table-sticky-scroll {
    display: none;
  }

  .ant-table-tbody .custom-left-padding td:nth-child(1) {
    padding-left: ${({ applyLeftPadding }) => (applyLeftPadding ? "8px" : "0")};
  }

  .ant-table-thead tr th:nth-child(1) {
    padding-left: ${({ applyLeftPadding }) => (applyLeftPadding ? "16px" : "8px")};
  }

  .ant-table-thead tr th {
    padding-left: 8px;
    background: ${GRAY_1};
  }

  .ant-table-tbody tr td {
    padding: 0;
  }

  .ant-table-cell.ant-table-selection-column {
    padding-right: 0;
    padding-left: ${({ applyLeftPadding }) => (applyLeftPadding ? 16 : 8)}px !important;
  }

  div.ant-typography {
    margin-bottom: 0;
  }

  .row-height {
    height: 60px;
  }
`;

export default AntDTable;
