/**
 * @generated SignedSource<<ca4ec08a890b3cf7c5e84c8057733fad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddOption_element$data = {
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly other: boolean;
        readonly position: number;
        readonly quota: number | null;
        readonly terminate: boolean;
        readonly text: string | null;
      } | null;
    } | null>;
  };
  readonly id: string;
  readonly type: ScreenersElementTypeChoices;
  readonly " $fragmentType": "AddOption_element";
};
export type AddOption_element$key = {
  readonly " $data"?: AddOption_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddOption_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddOption_element",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AnswerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quota",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "other",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "82cbd4ddbc4c4fc3ce42c0ae5bc3342f";

export default node;
