/**
 * @generated SignedSource<<c1b2e8b1d6cf3d2625859947ab0f6e4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
export type ScreenersScreenerTerminateRespondentChoices = "ET" | "IT" | "%future added value";
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyLanguageCodeChoices = "AF" | "AR" | "AR_DZ" | "AST" | "AZ" | "BE" | "BG" | "BN" | "BR" | "BS" | "CA" | "CKB" | "CS" | "CY" | "DA" | "DE" | "DSB" | "EL" | "EN" | "EN_AU" | "EN_GB" | "EO" | "ES" | "ES_AR" | "ES_CO" | "ES_MX" | "ES_NI" | "ES_VE" | "ET" | "EU" | "FA" | "FI" | "FR" | "FY" | "GA" | "GD" | "GL" | "HE" | "HI" | "HR" | "HSB" | "HU" | "HY" | "IA" | "ID" | "IG" | "IO" | "IS" | "IT" | "JA" | "KA" | "KAB" | "KK" | "KM" | "KN" | "KO" | "KY" | "LB" | "LT" | "LV" | "MK" | "ML" | "MN" | "MR" | "MS" | "MY" | "NB" | "NE" | "NL" | "NN" | "OS" | "PA" | "PL" | "PT" | "PT_BR" | "RO" | "RU" | "SK" | "SL" | "SQ" | "SR" | "SR_LATN" | "SV" | "SW" | "TA" | "TE" | "TG" | "TH" | "TK" | "TR" | "TT" | "UDM" | "UK" | "UR" | "UZ" | "VI" | "ZH_HANS" | "ZH_HANT" | "%future added value";
export type ScreenersStudyScheduledByChoices = "P" | "T" | "%future added value";
export type ScreenersStudySchedulingTypeChoices = "CL" | "HX" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
export type ScreenersTagColorChoices = "BL" | "CN" | "GN" | "GY" | "OG" | "PL" | "RD" | "YW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsPage_study$data = {
  readonly allowAutoApproval: boolean;
  readonly dId: any | null;
  readonly defaultIncentive: string | null;
  readonly defaultIncentivePoints: number | null;
  readonly externalIncentive: {
    readonly claimLink: string | null;
    readonly rewardDescription: string;
  } | null;
  readonly id: string;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly languageCode: ScreenersStudyLanguageCodeChoices | null;
  readonly name: string | null;
  readonly namePublic: string | null;
  readonly privacyPolicy: {
    readonly language: string;
    readonly languageCode: string;
  };
  readonly privacyPolicyOptions: ReadonlyArray<{
    readonly language: string;
    readonly languageCode: string;
  } | null> | null;
  readonly recruits: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly type: ScreenersRecruitTypeChoices | null;
      } | null;
    } | null>;
  };
  readonly scheduledBy: ScreenersStudyScheduledByChoices;
  readonly schedulingType: ScreenersStudySchedulingTypeChoices;
  readonly screener: {
    readonly overquotaExitLink: string | null;
    readonly quotaExitLink: string | null;
    readonly terminateRespondent: ScreenersScreenerTerminateRespondentChoices;
    readonly terminatedExitLink: string | null;
  } | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tags: ReadonlyArray<{
    readonly color: ScreenersTagColorChoices;
    readonly id: string;
    readonly name: string;
  }>;
  readonly tenant: {
    readonly surveyDomain: string | null;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly usesApiToMarkComplete: boolean;
  readonly voxpopmeRecorderEnabled: boolean;
  readonly " $fragmentType": "ProjectDetailsPage_study";
};
export type ProjectDetailsPage_study$key = {
  readonly " $data"?: ProjectDetailsPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "language",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsPage_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surveyDomain",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namePublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TagNode",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAutoApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedulingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PrivacyPolicyNode",
      "kind": "LinkedField",
      "name": "privacyPolicy",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PrivacyPolicyNode",
      "kind": "LinkedField",
      "name": "privacyPolicyOptions",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ScreenerNode",
      "kind": "LinkedField",
      "name": "screener",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminateRespondent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminatedExitLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quotaExitLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overquotaExitLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitNodeConnection",
      "kind": "LinkedField",
      "name": "recruits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecruitNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalIncentiveNode",
      "kind": "LinkedField",
      "name": "externalIncentive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "claimLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usesApiToMarkComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voxpopmeRecorderEnabled",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "f385c4887783f70d4ebb1dcdea98bda0";

export default node;
