/**
 * @generated SignedSource<<f89ae307f6cf84b8db68f70984309435>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Element_element$data = {
  readonly type: ScreenersElementTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"AuditionTextElement_element" | "AuditionVideoElement_element" | "CommentElement_element" | "DateElement_element" | "DropdownElement_element" | "ElementBase_element" | "FilesElement_element" | "GridSingleSelectElement_element" | "MultiChoiceElement_element" | "NumberElement_element" | "OpenEndElement_element" | "RankElement_element" | "SingleChoiceElement_element" | "VideoElement_element">;
  readonly " $fragmentType": "Element_element";
};
export type Element_element$key = {
  readonly " $data"?: Element_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"Element_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Element_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SingleChoiceElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultiChoiceElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RankElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GridSingleSelectElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DropdownElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OpenEndElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuditionTextElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuditionVideoElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NumberElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VideoElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DateElement_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FilesElement_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "7ed0dde99afb0412e94d48ac96018365";

export default node;
