import { Icon } from "@iconify/react";
import { Avatar as AntAvatar, ConfigProvider, type AvatarProps } from "antd";
import type { ScreenSizeMap } from "antd/es/_util/responsiveObserver";
import styled from "styled-components";
import { ThemeColor } from "../antd";
import { GRAY_3, GRAY_5, GRAY_7 } from "../style";
import { useFlag } from "../utils";

export const Avatar = ({
  shape = "circle",
  size,
  src,
  ...props
}: Omit<AvatarProps, "size" | "src"> & {
  size: Exclude<AvatarProps["size"], ScreenSizeMap>;
  src?: string | null;
}) => {
  const isVoxpopme = useFlag("platform-hubux-template");

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: isVoxpopme ? ThemeColor.VoxLavender : GRAY_5,
          colorTextLightSolid: isVoxpopme ? ThemeColor.VoxGray : GRAY_7,
        },
      }}
    >
      <StyledAvatar
        {...props}
        $isVoxpopme={isVoxpopme}
        icon={
          !src && (
            <div style={{ height: "100%", display: "grid", placeContent: "center" }}>
              <Icon
                icon="mdi:account"
                height={
                  size === "large" ? 24 : !size || size === "default" ? 18 : size === "small" ? 14 : (size * 7) / 12
                }
              />
            </div>
          )
        }
        size={size}
        shape={shape}
        src={src}
      />
    </ConfigProvider>
  );
};

const StyledAvatar = styled(AntAvatar)<{ $isVoxpopme: boolean }>`
  &.ant-avatar-icon {
    border-color: ${props => (props.$isVoxpopme ? ThemeColor.VoxStroke : GRAY_3)};
  }
`;
