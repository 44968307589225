import { useMountEffect } from "@react-hookz/web";
import { useRouter } from "found";
import { useState } from "react";
import styled from "styled-components";
import {
  HEADER_FONT_SIZE,
  HEADER_FONT_WEIGHT,
  PAGE_WIDTH_LG,
  PAGE_WIDTH_XL,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../style";
import { getS3DownloadUrl } from "../utils/s3Download";

export const DownloadPage = () => {
  const { match } = useRouter();
  const s3Key = match.location.query["key"];

  const [titleMessage, setTitleMessage] = useState("Preparing download");
  const [downloadMessage, setDownloadMessage] = useState("Getting a secure link to your file...");

  useMountEffect(async () => {
    try {
      if (!s3Key) throw new Error();

      setTitleMessage("Redirecting...");
      setDownloadMessage("Your file will download shortly");

      window.location.href = await getS3DownloadUrl(s3Key);
    } catch (e) {
      setTitleMessage("Couldn't load file");
      setDownloadMessage("An error occurred obtaining a secure link to your file. Please try again later.");
      throw e;
    }
  });

  return (
    <Styled>
      <div className="wrapper">
        <div className="inner">
          <div className="heading">
            <div className="img-wrapper">
              <img src="/team-teamwork.png" srcSet="/team-teamwork2x.png 2x" alt="Teamwork" width="150" height="150" />
            </div>
            <h1>{titleMessage}</h1>
          </div>
          <p>
            <span>Status: </span>
            {downloadMessage}
          </p>
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .wrapper {
    margin: auto;
    padding-bottom: 24px;
    @media (max-width: 1080px) {
      width: ${PAGE_WIDTH_LG};
    }
    @media (min-width: 1200px) {
      width: ${PAGE_WIDTH_XL};
    }
    .inner {
      max-width: 600px;
      margin: auto;
      padding-top: 48px;
      .heading {
        padding: 24px 0 24px 0;
        .img-wrapper {
          display: flex;
          justify-content: center;
        }
        h1 {
          text-align: center;
          font-size: ${HEADER_FONT_SIZE};
          font-weight: ${HEADER_FONT_WEIGHT};
          color: #000000;
        }
      }
      .setting {
        margin-top: 24px;
        .setting-description {
          font-size: 12px;
          color: ${SECONDARY_TEXT_COLOR};
        }
        .setting-button-container {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          margin-bottom: 12px;
          .button-info {
            margin-left: 12px;
            font-size: 14px;
            font-weight: bold;
            color: ${PRIMARY_TEXT_COLOR};
          }
        }
      }
      p {
        text-align: center;
      }
      span {
        font-weight: bold;
      }
    }
  }
`;
