import { graphql } from "babel-plugin-relay/macro";
import useRouter from "found/useRouter";
import React, { useEffect } from "react";
import { useMutation } from "react-relay";
import LoadingPage from "../LoadingPage";

// This component is loaded when Stripe's setup payment process redirects back to us following a request to
// edit payment details. The route will have a 'session_id' query param if successful and an 'error' param if not
const ManagePaymentFinish: React.FC = () => {
  const { match, router } = useRouter();

  const [commit] = useMutation(graphql`
    mutation ManagePaymentFinishMutation($input: AddPaymentMethodInput!) {
      addPaymentMethod(input: $input) {
        tenant {
          id
          stripePaymentMethodId
        }
      }
    }
  `);

  useEffect(() => {
    // URL will contain an error parameter if there was an error in the auth process e.g. user denied access
    if (match.location.query.error) {
      const originUrl = match.location.query.origin_url;
      console.error(match.location.query.error);
      router.replace(`${originUrl}?update-payment-success=false`);
    } else if (match.location.query.session_id) {
      const sessionId = match.location.query.session_id;
      const originUrl = match.location.query.origin_url;
      commit({
        variables: {
          input: {
            sessionId,
          },
        },
        onCompleted(data) {
          router.replace(`${originUrl}?update-payment-success=true`);
        },
        onError(e) {
          console.error(e);
          router.replace(`${originUrl}?update-payment-success=false`);
        },
      });
    } else {
      router.replace("/workspace/settings?update-payment-success=false");
    }
  }, [match, router, commit]);

  return <LoadingPage />;
};

export default ManagePaymentFinish;
