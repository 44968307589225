import { ConfigProvider } from "antd";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { ThemeColor } from "./config";

export const ConfigProviderAccentColorContext = createContext<string | null>(null);

export const ConfigProviderAccent = ({ children }: { children: ReactNode }) => {
  const colorAccent = useContext(ConfigProviderAccentColorContext);
  const colorPrimary = useMemo(() => colorAccent || ThemeColor.VoxGrape, [colorAccent]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary,
          },
          Tabs: {
            colorPrimary,
          },
        },
        token: {
          colorPrimary,
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
