import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { useUpdateEffect } from "@react-hookz/web";
import {
  App,
  AutoComplete,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputRef,
  Modal,
  Popover,
  Select,
  Switch,
  Tag,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { uniqueId } from "lodash-es";
import mimeTypes, { type MimeEntry } from "mime-db";
import { getType } from "mime/lite";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { createRefetchContainer, useMutation, type RelayRefetchProp } from "react-relay";
import styled from "styled-components";
import type { IterableElement } from "type-fest";

import { ButtonLabel } from "..";
import { ResponseValidationFormItems } from "../../components";
import { ELEMENT_TYPES, QUAL_ELEMENTS, SAVING_STATES, SELECTABLE_ELEMENT_TYPES } from "../../constants";
import { ScreenersElementTypeChoices } from "../../schema";
import {
  FADED_TEXT_COLOR,
  GRAY_7,
  GRAY_8,
  INPUT_WIDTH_NARROW,
  PAGE_PADDING,
  PAGE_WIDTH_2XS,
  PORTAL_HEADER_COLOR,
  SUBTITLE_FONT_SIZE,
} from "../../style";
import type { ResponseValidationType } from "../../types";
import {
  flattenEdges,
  moveEdge,
  mutation,
  responseValidationFormKeys,
  useResponseValidationForm,
  type ResponseValidationFormInput,
} from "../../utils";
import type { ActiveElementContent_addAnswer_Mutation } from "../../__generated__/ActiveElementContent_addAnswer_Mutation.graphql";
import type { ActiveElementContent_ChangeElementType_Mutation } from "../../__generated__/ActiveElementContent_ChangeElementType_Mutation.graphql";
import type { ActiveElementContent_ChangeUsePreviousAnswersElement_Mutation } from "../../__generated__/ActiveElementContent_ChangeUsePreviousAnswersElement_Mutation.graphql";
import type { ActiveElementContent_CreateAsset_Mutation } from "../../__generated__/ActiveElementContent_CreateAsset_Mutation.graphql";
import type { ActiveElementContent_DeleteAsset_Mutation } from "../../__generated__/ActiveElementContent_DeleteAsset_Mutation.graphql";
import type { ActiveElementContent_element$data } from "../../__generated__/ActiveElementContent_element.graphql";
import type { ActiveElementContent_MoveAnswer_Mutation } from "../../__generated__/ActiveElementContent_MoveAnswer_Mutation.graphql";
import type { ActiveElementContent_screener$data } from "../../__generated__/ActiveElementContent_screener.graphql";
import type { ActiveElementContent_UpdateElement_Mutation } from "../../__generated__/ActiveElementContent_UpdateElement_Mutation.graphql";
import { GroupOp, InputFilterGroupGroup, isValidFilterGroupGroup } from "../Filters";
import type { SavingStateType } from "../Saving";

import { AddOption, Option, UsePreviousAnswers } from ".";
import GridQuestionEditor from "./GridQuestionEditor";

const { useToken } = theme;
const { Paragraph: P, Text } = Typography;

const ELEMENTS = [
  { type: ELEMENT_TYPES.SINGLE_SELECT, label: "Single Choice" },
  { type: ELEMENT_TYPES.MULTI_SELECT, label: "Multi Choice" },
  { type: ELEMENT_TYPES.GRID_SINGLE_SELECT, label: "Grid" },
  { type: ELEMENT_TYPES.DROPDOWN_SELECT, label: "Dropdown Select" },
  { type: ELEMENT_TYPES.RANK, label: "Rank Items" },
  { type: ELEMENT_TYPES.NUMBER, label: "Number" },
  { type: ELEMENT_TYPES.OPENEND, label: "Open-Ended" },
  { type: ELEMENT_TYPES.AUDITION_TEXT, label: "Audition – Text" },
  { type: ELEMENT_TYPES.AUDITION_VIDEO, label: "Audition – Video" },
  { type: ELEMENT_TYPES.COMMENT, label: "Comment" },
  { type: ELEMENT_TYPES.VIDEO, label: "Video" },
  { type: ELEMENT_TYPES.DATE, label: "Date" },
  { type: ELEMENT_TYPES.FILES, label: "Files" },
];

const formatMimeTypeExtensions = (x: MimeEntry) => x.extensions?.join("/") ?? "";

const ActiveElementContent = ({
  relay,
  screener,
  element,
  setLoading,
  elementActions,
  showLabel,
  setSavingState,
}: {
  relay: RelayRefetchProp;
  screener: ActiveElementContent_screener$data;
  element: ActiveElementContent_element$data;
  setLoading: (loading: boolean) => void;
  elementActions?: JSX.Element;
  showLabel: boolean;
  setSavingState: (savingState: SavingStateType) => void;
}) => {
  const { notification } = App.useApp();
  const refetch = () => relay.refetch({ elementId: element.id });

  const changeElementType = (input: { elementType: ScreenersElementTypeChoices }) => {
    return mutation<ActiveElementContent_ChangeElementType_Mutation>({
      variables: {
        input: {
          elementId: element.id || "",
          ...input,
        },
      },
      mutation: graphql`
        mutation ActiveElementContent_ChangeElementType_Mutation($input: ChangeElementTypeInput!) {
          changeElementType(input: $input) {
            element {
              text
              ...ActiveElementContent_element
            }
          }
        }
      `,
    });
  };

  const updateElement = useCallback(
    async (input: {
      text?: string;
      label?: string;
      type?: ScreenersElementTypeChoices;
      target?: number | null;
      target2?: number | null;
      targetTypes?: string[] | null;
      responseValidation?: string | null;
      randomize?: boolean;
      showIfCondition?: any;
      usePreviousAnswers?: any;
    }) => {
      try {
        setSavingState(SAVING_STATES.SAVING);
        await mutation<ActiveElementContent_UpdateElement_Mutation>({
          variables: {
            input: {
              elementId: element.id || "",
              ...input,
            },
          },
          mutation: graphql`
            mutation ActiveElementContent_UpdateElement_Mutation($input: UpdateElementInput!) {
              updateElement(input: $input) {
                element {
                  id
                  dbId
                  text
                  type
                  target
                  target2
                  responseValidation
                  label
                  position
                  randomize
                  showIfCondition
                  usePreviousAnswers
                  ...InputFilterGroupGroup_element
                }
              }
            }
          `,
        });
        setSavingState(SAVING_STATES.SAVED);
      } catch {
        setSavingState(SAVING_STATES.ERROR);
      }
    },
    [element.id, setSavingState]
  );

  const addAnswer = async ({ other, position }: { other: boolean; position?: number }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ActiveElementContent_addAnswer_Mutation>({
        variables: {
          input: {
            elementId: element.id || "",
            other,
            terminate: false,
            position,
          },
        },
        mutation: graphql`
          mutation ActiveElementContent_addAnswer_Mutation($input: AddAnswerInput!) {
            addAnswer(input: $input) {
              element {
                id
                answers {
                  edges {
                    node {
                      id
                      dbId
                      text
                      terminate
                      quota
                      other
                      position
                      exclusive
                    }
                  }
                }
              }
            }
          }
        `,
      });
      // focus the answer at position if provided, otherwise the last answer
      setNewAnswerIndex(typeof position === "number" ? position : element.answers.edges.length);
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const moveAnswer = async (input: { answerId: string; fromPosition: number; toPosition: number }) => {
    const newEdges = moveEdge(element?.answers.edges || [], input.fromPosition, input.toPosition);

    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ActiveElementContent_MoveAnswer_Mutation>({
        variables: {
          input: {
            answerId: input.answerId,
            toPosition: input.toPosition,
          },
        },
        mutation: graphql`
          mutation ActiveElementContent_MoveAnswer_Mutation($input: MoveAnswerInput!) {
            moveAnswer(input: $input) {
              element {
                id
                answers {
                  edges {
                    node {
                      id
                      dbId
                      position
                      text
                    }
                  }
                }
              }
            }
          }
        `,
        optimisticResponse: {
          moveAnswer: {
            element: {
              id: element?.id || "",
              answers: {
                edges: newEdges,
              },
            },
          },
        },
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const changeUsePreviousAnswersElement = (input: { previousAnswersElementId: string }) => {
    return mutation<ActiveElementContent_ChangeUsePreviousAnswersElement_Mutation>({
      variables: {
        input: {
          elementId: element.id || "",
          ...input,
        },
      },
      mutation: graphql`
        mutation ActiveElementContent_ChangeUsePreviousAnswersElement_Mutation(
          $input: ChangeUsePreviousAnswersElementInput!
        ) {
          changeUsePreviousAnswersElement(input: $input) {
            element {
              text
              usePreviousAnswers
              ...ActiveElementContent_element
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  };

  const [text, setText] = useState<string>(element.text || "");
  const [showVisibilityRules, setShowVisibilityRules] = useState<boolean>(!!element.showIfCondition);
  const [label, setLabel] = useState<string>(element.label || "");
  const [type, setType] = useState<any>(element.characteristic?.importKey || element.type);
  const [usePreviousAnswers, setUsePreviousAnswers] = useState<boolean>(
    !!JSON.parse(element?.usePreviousAnswers)?.enabled
  );
  const [selectedPreviousQuestionId, setSelectedPreviousQuestionId] = useState<string | null | undefined>(
    JSON.parse(element.usePreviousAnswers)?.elementId
  );

  const [target, setTarget] = useState<number | undefined>(element.target || undefined);
  const [target2, setTarget2] = useState<number | undefined>(element.target2 || undefined);
  const [responseValidation, setResponseValidation] = useState<ResponseValidationType | undefined>(
    element.responseValidation || undefined
  );
  const [enableResponseValidation, setEnableResponseValidation] = useState<boolean>(
    (element.type === ELEMENT_TYPES.MULTI_SELECT && (element.target ?? 0) > 0) ||
      (element.type === ELEMENT_TYPES.NUMBER && element.target != null)
  );

  const [responseValidationForm, resetResponseValidationForm] = useResponseValidationForm(
    {
      onReset: ({ responseValidation, target, target2 }) => {
        setResponseValidation(undefined);
        setTarget(undefined);
        setTarget2(undefined);

        updateElement({
          responseValidation: null,
          target: null,
          target2: null,
        });
      },
    },
    [type]
  );
  const onResponseValidationValuesChange = useCallback(
    async (changedValues: ResponseValidationFormInput, allValues?: ResponseValidationFormInput) => {
      try {
        await responseValidationForm.validateFields([...responseValidationFormKeys]);
      } catch {}

      const validChangedValues = Object.entries(changedValues).filter(
        ([k]) => !responseValidationForm.getFieldError(k).length
      );

      validChangedValues.forEach(([k, v]) =>
        ({
          responseValidation: () => {
            setEnableResponseValidation(!!v);
            setResponseValidation(v as typeof responseValidation);
          },
          target: () => setTarget(v as typeof target),
          target2: () => setTarget2(v as typeof target2),
        }[k as keyof Exclude<typeof allValues, undefined>]())
      );

      if (validChangedValues.length)
        await updateElement(validChangedValues.reduce((acc, [k, v]) => ({ ...acc, [k]: v ?? null }), {}));
    },
    [responseValidationForm, updateElement]
  );
  useEffect(() => {
    if (element.characteristic) {
      const payload = Object.entries(element.characteristic.elementMeta)
        .filter(([k, v]) => v != null && element[k as keyof typeof element.characteristic.elementMeta] == null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
      responseValidationForm.setFieldsValue(payload);
      onResponseValidationValuesChange(payload);
    }
  }, [element, element.characteristic, onResponseValidationValuesChange, responseValidationForm]);

  const selectedCharacteristics = useMemo(() => {
    return screener.elements?.edges
      .filter(edge => edge?.node?.id !== element.id)
      .map(edge => edge?.node?.characteristic?.importKey as string);
  }, [screener.elements?.edges, element.id]);

  const parseCharacteristicElements = (
    characteristics:
      | ActiveElementContent_screener$data["study"]["tenant"]["characteristics"]
      | ActiveElementContent_screener$data["study"]["tenant"]["globalCharacteristics"],
    selectedCharacteristics: string[] | undefined
  ) => {
    return (
      characteristics?.edges
        .map(characteristic => {
          return {
            type: characteristic?.node?.importKey || "",
            label: characteristic?.node?.shortName || "",
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter(value => !selectedCharacteristics?.includes(value.type)) || []
    );
  };

  // We memoize to avoid unnecessary calculations on re-renders
  const memoizedParsedGlobalCharacteristics = useMemo(() => {
    return parseCharacteristicElements(screener.study.tenant.globalCharacteristics, selectedCharacteristics);
  }, [screener.study.tenant.globalCharacteristics, selectedCharacteristics]);

  const memoizedParsedTenantCharacteristics = useMemo(() => {
    return parseCharacteristicElements(screener.study.tenant.characteristics, selectedCharacteristics);
  }, [screener.study.tenant.characteristics, selectedCharacteristics]);

  const [globalElements, setGlobalElements] = useState(memoizedParsedGlobalCharacteristics);
  const [tenantElements, setTenantElements] = useState(memoizedParsedTenantCharacteristics);

  // clear label input when labels are not visible
  useEffect(() => {
    if (!showLabel) {
      setLabel("");
    }
  }, [showLabel]);

  function handleTypeChange(elementType: ScreenersElementTypeChoices) {
    setLoading(true);
    setSavingState(SAVING_STATES.SAVING);
    changeElementType({
      elementType: elementType,
    })
      .then(res => {
        setText(res.changeElementType?.element?.text || "");
        setGlobalElements(memoizedParsedGlobalCharacteristics);
        setTenantElements(memoizedParsedTenantCharacteristics);
        setSelectedPreviousQuestionId(undefined);
        setSavingState(SAVING_STATES.SAVED);
      })
      .catch(() => setSavingState(SAVING_STATES.ERROR))
      .finally(() => setLoading(false));
  }

  function handleUsePreviousAnswersElementChange(previousAnswersElementId: string) {
    setSavingState(SAVING_STATES.SAVING);
    changeUsePreviousAnswersElement({
      previousAnswersElementId: previousAnswersElementId,
    })
      .then((result: any) => {
        setSelectedPreviousQuestionId(previousAnswersElementId);
        setSavingState(SAVING_STATES.SAVED);
      })
      .catch(e => {
        notification.error({ message: e.errors[0].message });
        setSavingState(SAVING_STATES.ERROR);
      });
  }

  function handleUsePreviousAnswersChange(conditions: string[]) {
    setSavingState(SAVING_STATES.SAVING);
    updateElement({
      usePreviousAnswers: JSON.stringify({
        enabled: true,
        elementId: selectedPreviousQuestionId,
        conditions: conditions,
      }),
    });
  }

  // handle focusing on a new answer when adding one
  const [newAnswerIndex, setNewAnswerIndex] = useState<number | null>();
  const newAnswerRef = useRef<InputRef>(null);
  useEffect(() => newAnswerRef.current?.focus(), [newAnswerIndex]);

  const addAnswerBelow = async (newAnswerPosition: number) => addAnswer({ other: false, position: newAnswerPosition });

  const [showIfCondition, setShowIfCondition] = useState(element.showIfCondition || undefined);
  useUpdateEffect(() => {
    if (isValidFilterGroupGroup(showIfCondition)) updateElement({ showIfCondition });
  }, [showIfCondition]);

  const labelTooltip = (
    <Tooltip title="Labels are for your use only and will not be displayed to participants." placement="bottom">
      <span style={{ color: FADED_TEXT_COLOR }}>
        <QuestionCircleOutlined />
      </span>
    </Tooltip>
  );

  const questionOptionLabelStyle = { display: "flex", alignItems: "center" };
  const questionOptionSpanStyle = { flexGrow: 1, margin: "12px 12px 12px 0" };

  const elementAssetType = useMemo(
    () =>
      element.asset
        ? (getType(element.asset.url)?.split("/")[0] as "audio" | "image" | "video" | undefined) ?? null
        : null,
    [element.asset]
  );

  const [assetModalVisible, setAssetModalVisible] = useState(false);
  const [assetForm] = Form.useForm<{ url: string }>();

  useEffect(() => {
    if (!assetModalVisible) assetForm.resetFields();
  }, [assetForm, assetModalVisible]);

  const [commitCreateAsset, isCreateAssetInFlight] = useMutation<ActiveElementContent_CreateAsset_Mutation>(
    graphql`
      mutation ActiveElementContent_CreateAsset_Mutation($input: CreateAssetInput!) {
        createAsset(input: $input) {
          asset {
            id
          }
        }
      }
    `
  );

  const submitAsset = async () => {
    await assetForm.validateFields();
    commitCreateAsset({
      variables: { input: { elementId: element.id, url: assetForm.getFieldValue("url") } },
      onCompleted: () => {
        setAssetModalVisible(false);
        assetForm.resetFields();
        refetch();
      },
      onError: e => {
        throw e;
      },
    });
  };

  const [commitDeleteAsset] = useMutation<ActiveElementContent_DeleteAsset_Mutation>(
    graphql`
      mutation ActiveElementContent_DeleteAsset_Mutation($input: DeleteAssetInput!) {
        deleteAsset(input: $input) {
          ok
        }
      }
    `
  );
  const onDeleteAsset = () =>
    commitDeleteAsset({
      variables: { input: { assetId: element.asset!.id } },
      onCompleted: () => {
        refetch();
      },
    });

  const [allowedMimeTypes, dispatchAllowedMimeTypes] = useReducer(
    (state: string[], { type, value }: { type: "add" | "remove"; value: string }) =>
      ({
        add: () => [...state, value],
        remove: () => state.filter(x => x !== value),
      }[type]?.()),
    // element.targetTypes must be cast to itself to be writable (vs. readonly)
    // type-fest's Writable will not work
    (element.targetTypes as IterableElement<(typeof element)["targetTypes"]>[]) ?? []
  );
  const mimeTypeOptions = useMemo(
    () =>
      Object.entries(mimeTypes)
        .filter(
          (x): x is [string, MimeEntry & { extensions: readonly string[] }] =>
            !!x[1].extensions?.length && !allowedMimeTypes.includes(x[0])
        )
        .map(x => ({ label: formatMimeTypeExtensions(x[1]), value: x[0] })),
    [allowedMimeTypes]
  );
  const [mimeTypeQuery, setMimeTypeQuery] = useState("");
  useUpdateEffect(() => {
    updateElement({ targetTypes: allowedMimeTypes });
  }, [allowedMimeTypes, updateElement]);

  const { token } = useToken();

  return (
    <>
      <div className="label">Question</div>
      <div className="question">
        {!element.characteristic ? (
          <div>
            <Input.TextArea
              autoSize={{ minRows: 4 }}
              maxLength={4096}
              placeholder="Enter question text..."
              value={text}
              onChange={e => setText(e.target.value)}
              onBlur={e => updateElement({ text: e.target.value })}
              className="screener-element-question"
            />
            <p className="markdown-info">
              Question and answer text support Markdown. You can reference syntax{" "}
              <a href="https://commonmark.org/help/" rel="noopener noreferrer" target="_blank">
                here
              </a>
              .
            </p>
          </div>
        ) : (
          <Input
            disabled={!!element.characteristic}
            placeholder="Enter question..."
            value={text}
            onChange={e => setText(e.target.value)}
            onBlur={e => updateElement({ text: e.target.value })}
            className="screener-element-question"
          />
        )}
        <ButtonLabel
          icon="mdi:link"
          iconScale={1.5}
          labelStyle={{ fontWeight: 600 }}
          onClick={() => setAssetModalVisible(true)}
          size="middle"
          style={{ padding: 0 }}
          text={element.asset ? "Replace file" : "Embed file"}
          type="link"
        />
        {elementAssetType && (
          <AssetContainer type={elementAssetType}>
            {element.asset &&
              (elementAssetType === "audio" ? (
                <audio src={element.asset?.url} controls />
              ) : elementAssetType === "image" ? (
                <img src={element.asset?.url} alt="Attached file" />
              ) : elementAssetType === "video" ? (
                <video src={element.asset?.url} controls />
              ) : null)}
            <div className="asset-actions">
              <ButtonLabel
                icon="mdi:delete-outline"
                iconScale={elementAssetType === "image" ? 2 : 1.5}
                onClick={() => onDeleteAsset()}
                text="Delete"
                type="link"
              />
            </div>
          </AssetContainer>
        )}
        <AssetModal
          closable={false}
          confirmLoading={isCreateAssetInFlight}
          okText="Done"
          onOk={() => submitAsset()}
          onCancel={() => setAssetModalVisible(false)}
          title={element.asset ? "Replace file" : "Embed file"}
          open={assetModalVisible}
        >
          <Form
            form={assetForm}
            onKeyDown={e => {
              if (e.key === "Enter") submitAsset();
            }}
          >
            <Form.Item
              name="url"
              extra={
                <>
                  Images should be .apng, .gif, .jpeg, .jpg, .png, .svg, or .webp
                  <br />
                  Videos should be .mp4
                </>
              }
              rules={[
                { required: true, message: "Please include a link" },
                { type: "url", message: "Not a valid URL" },
                {
                  validator: (_, value) => {
                    return /^https:\/\/.*$/.test(decodeURI(value))
                      ? Promise.resolve()
                      : Promise.reject(new Error("Links must be start with https://"));
                  },
                },
                {
                  validator: (_, value) => {
                    return /^http(s?):[/.:@\w\s-]*\.(?:apng|flac|gif|jpeg|jpg|png|svg|webp|mp3|mp4)$/.test(
                      decodeURI(value)
                    )
                      ? Promise.resolve()
                      : Promise.reject(new Error("File type not supported"));
                  },
                },
              ]}
            >
              <Input placeholder="Link to an image or video" />
            </Form.Item>
          </Form>
        </AssetModal>
      </div>
      {showLabel && (
        <div className="label-options">
          <p>
            Label <span style={{ color: FADED_TEXT_COLOR }}>{labelTooltip} (optional)</span>
          </p>
          <div className="label-input-row">
            <Input
              placeholder="Enter label"
              value={label}
              className="label screener-element-label"
              onChange={e => setLabel(e.target.value)}
              onBlur={e => updateElement({ label: e.target.value })}
              maxLength={5}
            />
          </div>
        </div>
      )}
      <div style={{ marginInline: 0 - token.paddingMD }}>
        <Divider />
      </div>
      <div className="label">Answer type</div>
      <div className="metadata">
        <Select
          value={type}
          onSelect={(v: ScreenersElementTypeChoices, _: any) => {
            handleTypeChange(v);
            setType(v);
          }}
          dropdownMatchSelectWidth={false}
          listHeight={550}
          style={{ width: "100%", textTransform: "capitalize" }}
          dropdownStyle={{ textTransform: "capitalize" }}
        >
          <Select.OptGroup label="Custom">
            {ELEMENTS.map(o => (
              <Select.Option key={o.type} id={o.type}>
                {o.label}
              </Select.Option>
            ))}
          </Select.OptGroup>
          {tenantElements.length > 0 && (
            <Select.OptGroup label="Your Characteristics">
              {tenantElements.map(o => (
                <Select.Option key={o.type} id={o.type}>
                  {o.label}
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
          <Select.OptGroup label="Standard Characteristics">
            {globalElements.map(o => (
              <Select.Option key={o.type} id={o.type}>
                {o.label}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>
        {!!element.characteristic &&
          (element.answers.edges.length !== element.characteristic.answers.length ||
            !flattenEdges(element.answers).every(x =>
              element.characteristic?.answers.find(y => y.position === x.position && y.text === x.text)
            )) && (
            <Tooltip
              title={
                <>
                  Answers for this characteristic have changed. Click to replace with the latest answers.{" "}
                  <strong>Quotas and terminate settings will be reset.</strong>
                </>
              }
            >
              <Button
                onClick={() => {
                  handleTypeChange(type);
                  setType(type);
                }}
                style={{ aspectRatio: "1", display: "grid", placeContent: "center", padding: 4 }}
                type="text"
              >
                <Text type="secondary" style={{ display: "grid " }}>
                  <Icon icon="mdi:refresh" height="1.2em" />
                </Text>
              </Button>
            </Tooltip>
          )}
      </div>
      {usePreviousAnswers && (
        <UsePreviousAnswers
          screener={screener}
          element={element}
          selectedPreviousQuestionId={selectedPreviousQuestionId}
          handleUsePreviousAnswersElementChange={handleUsePreviousAnswersElementChange}
          handleUsePreviousAnswersChange={handleUsePreviousAnswersChange}
        />
      )}
      {((usePreviousAnswers && selectedPreviousQuestionId) || !usePreviousAnswers) &&
        (element.type === ELEMENT_TYPES.GRID_SINGLE_SELECT ? (
          <div className="answers">
            <GridQuestionEditor element={element} setSavingState={setSavingState} />
          </div>
        ) : (
          <div className="answers">
            {element.type !== ELEMENT_TYPES.COMMENT &&
              element.type !== ELEMENT_TYPES.NUMBER &&
              element.type !== ELEMENT_TYPES.VIDEO &&
              element.type !== ELEMENT_TYPES.AUDITION_VIDEO &&
              element.type !== ELEMENT_TYPES.DATE &&
              element.type !== ELEMENT_TYPES.FILES && (
                <div
                  className="label"
                  style={usePreviousAnswers ? { fontSize: "22px", color: GRAY_8, fontWeight: 400 } : {}}
                >
                  {QUAL_ELEMENTS.includes(element.type as ELEMENT_TYPES) ? (
                    "Answer"
                  ) : usePreviousAnswers ? (
                    <>
                      Possible answers
                      <Tooltip
                        title="When respondents answer with at least one option, the selected options will show to them in this question."
                        placement="rightBottom"
                      >
                        <span style={{ color: FADED_TEXT_COLOR, fontSize: "16px", marginLeft: "8px" }}>
                          <QuestionCircleOutlined />
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    "Answers"
                  )}
                </div>
              )}
            <div className="options">
              <DragDropContext
                onDragEnd={(result: DropResult) => {
                  if (result.destination?.index !== undefined) {
                    moveAnswer({
                      answerId: result.draggableId,
                      toPosition: result.destination.index,
                      fromPosition: result.source?.index,
                    });
                  }
                }}
              >
                <Droppable droppableId={`element-draggable-${element.id}`}>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {element.answers.edges.map(
                        (o, index) =>
                          o?.node && (
                            <Draggable
                              isDragDisabled={usePreviousAnswers}
                              key={o.node.id}
                              draggableId={o.node.id}
                              index={o.node.position}
                            >
                              {(provided: any, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  id={`screener-element-answer-${o.node?.position}`}
                                >
                                  {o.node && (
                                    <Option
                                      focusElementRef={index === newAnswerIndex ? newAnswerRef : null}
                                      answer={o.node}
                                      element={element}
                                      addAnswerBelow={() => addAnswerBelow(index + 1)}
                                      setSavingState={setSavingState}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {!element.characteristic &&
                !usePreviousAnswers &&
                ![
                  ...QUAL_ELEMENTS,
                  ELEMENT_TYPES.COMMENT,
                  ELEMENT_TYPES.NUMBER,
                  ELEMENT_TYPES.VIDEO,
                  ELEMENT_TYPES.AUDITION_VIDEO,
                  ELEMENT_TYPES.DATE,
                  ELEMENT_TYPES.FILES,
                ].includes(element.type as ELEMENT_TYPES) && <AddOption element={element} addAnswer={addAnswer} />}
              {enableResponseValidation && (
                <Form
                  form={responseValidationForm}
                  initialValues={{ responseValidation, target, target2 }}
                  onValuesChange={onResponseValidationValuesChange}
                >
                  <div className="label" style={{ marginTop: "10px" }}>
                    Response Validation
                  </div>
                  <div className="response-validation screener-element-must-select">
                    <ResponseValidationFormItems
                      elementAnswersLength={element.answers.edges.length}
                      elementType={element.type}
                      form={responseValidationForm}
                    />
                    <CloseOutlined
                      onClick={() => {
                        setEnableResponseValidation(false);
                        resetResponseValidationForm();
                      }}
                      style={{ color: GRAY_7, height: 30, display: "flex", alignItems: "center" }}
                    />
                  </div>
                </Form>
              )}
              {element.type === ELEMENT_TYPES.FILES && (
                <div style={{ margin: "16px 0 12px", display: "flex", flexDirection: "column", gap: 8 }}>
                  <div className="label">Answers</div>
                  <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                    <span>Allow only one file</span>
                    <Switch
                      size="small"
                      checked={!!element.target}
                      onChange={val => updateElement({ target: val ? 1 : null })}
                    />
                  </div>
                  <div style={{ display: "flex", gap: 8 }}>
                    <AutoComplete
                      allowClear
                      style={{ flexShrink: 0, width: INPUT_WIDTH_NARROW }}
                      filterOption={(inputValue, option) =>
                        inputValue && option && mimeTypeQuery
                          ? (["label", "value"] as (keyof typeof option)[]).some(x =>
                              option[x].toLowerCase().includes(inputValue.toLowerCase())
                            )
                          : true
                      }
                      onSearch={setMimeTypeQuery}
                      onSelect={(value: string) => {
                        dispatchAllowedMimeTypes({ type: "add", value });
                        setMimeTypeQuery("");
                      }}
                      options={mimeTypeOptions}
                      placeholder="Allowed file types"
                      value={mimeTypeQuery}
                    />
                    {allowedMimeTypes.length ? (
                      <ul style={{ display: "flex", flexWrap: "wrap", padding: "initial", margin: "5px 0 0" }}>
                        {allowedMimeTypes.map(x => (
                          <li style={{ listStyleType: "none", marginBottom: 4 }}>
                            <Tag
                              style={{ marginTop: 0 }}
                              closable
                              onClose={() => dispatchAllowedMimeTypes({ type: "remove", value: x })}
                            >
                              {formatMimeTypeExtensions(mimeTypes[x]!)}
                            </Tag>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span style={{ color: GRAY_7, fontSize: 12, margin: "auto 0" }}>
                        All types allowed. Search to restrict what file types are accepted
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      {showVisibilityRules && (
        <div>
          <div style={{ marginInline: 0 - token.paddingMD }}>
            <Divider />
          </div>
          <P style={{ marginBottom: token.marginXS }}>Only show this question if:</P>
          <Card size="small">
            <InputFilterGroupGroup
              element={element}
              onClear={() => {
                setShowVisibilityRules(false);
                setShowIfCondition(undefined);
                updateElement({ showIfCondition: null });
              }}
              screener={screener}
              setValue={setShowIfCondition}
              tenant={null}
              type="element"
              value={showIfCondition}
            />
          </Card>
        </div>
      )}
      <div style={{ marginInline: 0 - token.paddingMD }}>
        <Divider style={{ marginBottom: token.marginXS }} />
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {elementActions}
        <div>
          <Divider style={{ height: "100%" }} type="vertical" />
        </div>
        <Popover
          className="screener-element-question-options"
          getPopupContainer={trigger => trigger.parentElement!}
          placement="left"
          trigger="click"
          content={
            <>
              <label style={questionOptionLabelStyle}>
                <span style={questionOptionSpanStyle}>Randomize order of answers</span>
                <Switch size="small" checked={element.randomize} onChange={val => updateElement({ randomize: val })} />
              </label>
              <label style={questionOptionLabelStyle}>
                <span style={questionOptionSpanStyle}>
                  Show visibility rules{" "}
                  <Tooltip title="Only show this question if certain conditions are met">
                    <QuestionCircleOutlined style={{ color: FADED_TEXT_COLOR }} />
                  </Tooltip>
                </span>
                <Switch
                  size="small"
                  checked={showVisibilityRules}
                  onChange={x => {
                    setShowVisibilityRules(x);
                    if (!x) updateElement({ showIfCondition: null });
                    else
                      setShowIfCondition({
                        id: uniqueId(),
                        op: GroupOp.And,
                        filters: [
                          {
                            id: uniqueId("filterGroup"),
                            op: GroupOp.And,
                            filters: [
                              {
                                id: uniqueId("filter"),
                              },
                            ],
                          },
                        ],
                      });
                  }}
                />
              </label>
              {[ELEMENT_TYPES.MULTI_SELECT, ELEMENT_TYPES.NUMBER].includes(element.type as ELEMENT_TYPES) && (
                <label style={questionOptionLabelStyle}>
                  <span style={questionOptionSpanStyle}>
                    Show response validation{" "}
                    <Tooltip
                      title={
                        element.type === ELEMENT_TYPES.MULTI_SELECT
                          ? "Add a number of required responses"
                          : "Limit the range of accepted answers"
                      }
                    >
                      <QuestionCircleOutlined style={{ color: FADED_TEXT_COLOR }} />
                    </Tooltip>
                  </span>
                  <Switch
                    className="screener-element-question-options-response-validation"
                    size="small"
                    checked={enableResponseValidation}
                    onChange={v => {
                      setEnableResponseValidation(v);
                      if (!v) resetResponseValidationForm();
                    }}
                  />
                </label>
              )}
              {SELECTABLE_ELEMENT_TYPES.includes(element.type) && (
                <label style={questionOptionLabelStyle}>
                  <span style={questionOptionSpanStyle}>Use answers from previous question</span>
                  <Switch
                    className="screener-element-question-options-response-validation"
                    size="small"
                    checked={usePreviousAnswers}
                    onChange={v => {
                      setUsePreviousAnswers(v);
                      if (!v) {
                        updateElement({
                          usePreviousAnswers: JSON.stringify({
                            enabled: false,
                          }),
                        });
                      }
                    }}
                  />
                </label>
              )}
            </>
          }
        >
          <Button style={{ aspectRatio: "1", display: "grid", placeContent: "center" }} type="text">
            <Icon icon="mdi:dots-vertical" height="1.2em" />
          </Button>
        </Popover>
      </div>
    </>
  );
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  marginTop: "12px",
  scale: isDragging && "8px",
  ...draggableStyle,
});

const AssetContainer = styled.div<{
  type: "audio" | "image" | "video";
}>`
  align-self: start;
  position: ${props => (props.type === "audio" ? "initial" : "relative")};
  display: ${props => (props.type === "audio" ? "flex" : "initial")};
  cursor: initial;

  > audio,
  > img,
  > video {
    display: block;
    min-width: 120px;
    max-width: 100%;
  }

  > img,
  > video {
    min-height: 120px;
    max-height: ${PAGE_WIDTH_2XS};
  }

  &:hover .asset-actions {
    opacity: initial;
  }

  .asset-actions {
    position: ${props => (props.type === "audio" ? "initial" : "absolute")};
    inset: 0 0 ${props => (props.type === "image" ? 0 : "auto")};
    padding: ${props => (props.type === "video" ? "8px" : "initial")};
    padding-bottom: ${props => (props.type === "video" ? "32px" : "initial")};
    background: ${props =>
      props.type === "image"
        ? "rgba(0, 0, 0, 0.4)"
        : props.type === "video"
        ? "linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);"
        : "initial"};
    opacity: 0;
    transition: opacity 300ms;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: auto;
    }

    .hub-label {
      color: ${props => (props.type === "audio" ? "var(--ant-error-color)" : "white")};
      font-weight: 500;
      flex-direction: ${props => props.type === "image" && "column"};
      gap: ${props => props.type === "image" && 0};
    }
  }
`;

const AssetModal = styled(Modal)`
  .ant-modal-content > * {
    border: 0;
    padding: ${PAGE_PADDING};
  }

  .ant-modal-header {
    text-align: center;
  }

  .ant-modal-title {
    color: ${PORTAL_HEADER_COLOR};
    font-size: ${SUBTITLE_FONT_SIZE};
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-modal-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    > * {
      margin: 0 !important;
    }
  }
`;

export default createRefetchContainer(
  ActiveElementContent,
  {
    screener: graphql`
      fragment ActiveElementContent_screener on ScreenerNode {
        id
        elements {
          edges {
            node {
              id
              characteristic {
                importKey
              }
            }
          }
        }
        study {
          status
          tenant {
            characteristics {
              edges {
                node {
                  text
                  type
                  importKey
                  shortName
                }
              }
            }
            globalCharacteristics {
              edges {
                node {
                  text
                  type
                  importKey
                  shortName
                }
              }
            }
          }
        }
        ...InputFilterGroupGroup_screener
        ...UsePreviousAnswers_screener
      }
    `,
    element: graphql`
      fragment ActiveElementContent_element on ElementNode {
        id
        text
        asset {
          id
          url
        }
        type
        label
        target
        target2
        targetTypes
        responseValidation
        usePreviousAnswers
        screener {
          id
        }
        position
        characteristic {
          answers {
            id
            position
            text
          }
          elementMeta {
            randomize
            responseValidation
            target
            target2
            targetTypes
          }
          importKey
          shortName
          tenant {
            id
          }
        }
        answers {
          edges {
            node {
              id
              dbId
              position
              text
              ...Option_answer
            }
          }
        }
        rows {
          edges {
            node {
              id
              position
              text
              ...Row_row
            }
          }
        }
        randomize
        showIfCondition
        ...AddOption_element
        ...GridQuestionEditor_element
        ...InputFilterGroupGroup_element
        ...Option_element
        ...Row_element
        ...UsePreviousAnswers_element
      }
    `,
  },
  graphql`
    query ActiveElementContentRefetchQuery($elementId: String!) {
      viewer {
        element(elementId: $elementId) {
          ...ActiveElementContent_element
          ...InputFilterGroupGroup_element
        }
      }
    }
  `
);
