/**
 * @generated SignedSource<<d03a770e2be45f2e0b6b689e49f93261>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateGridAnswerInput = {
  answerId: string;
  clientMutationId?: string | null;
  goal?: number | null;
  rowId: string;
  terminate?: boolean | null;
};
export type GridQuestionEditor_updateGridAnswer_Mutation$variables = {
  input: UpdateGridAnswerInput;
};
export type GridQuestionEditor_updateGridAnswer_Mutation$data = {
  readonly updateGridAnswer: {
    readonly element: {
      readonly answers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly terminate: boolean;
          } | null;
        } | null>;
      };
      readonly gridAnswers: ReadonlyArray<{
        readonly answer: {
          readonly id: string;
        };
        readonly goal: number | null;
        readonly row: {
          readonly id: string;
        };
        readonly terminate: boolean | null;
      } | null> | null;
      readonly rows: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type GridQuestionEditor_updateGridAnswer_Mutation = {
  response: GridQuestionEditor_updateGridAnswer_Mutation$data;
  variables: GridQuestionEditor_updateGridAnswer_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "RowNode",
  "kind": "LinkedField",
  "name": "row",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AnswerNode",
  "kind": "LinkedField",
  "name": "answer",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goal",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RowNodeConnection",
  "kind": "LinkedField",
  "name": "rows",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RowNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "AnswerNodeConnection",
  "kind": "LinkedField",
  "name": "answers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GridQuestionEditor_updateGridAnswer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGridAnswerPayload",
        "kind": "LinkedField",
        "name": "updateGridAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GridAnswerNode",
                "kind": "LinkedField",
                "name": "gridAnswers",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GridQuestionEditor_updateGridAnswer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGridAnswerPayload",
        "kind": "LinkedField",
        "name": "updateGridAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GridAnswerNode",
                "kind": "LinkedField",
                "name": "gridAnswers",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23c50b925f8309a1214221a95a99ee05",
    "id": null,
    "metadata": {},
    "name": "GridQuestionEditor_updateGridAnswer_Mutation",
    "operationKind": "mutation",
    "text": "mutation GridQuestionEditor_updateGridAnswer_Mutation(\n  $input: UpdateGridAnswerInput!\n) {\n  updateGridAnswer(input: $input) {\n    element {\n      gridAnswers {\n        row {\n          id\n        }\n        answer {\n          id\n        }\n        goal\n        terminate\n        id\n      }\n      rows {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      answers {\n        edges {\n          node {\n            id\n            terminate\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc3046c185d0f5d931498a1489c1afe9";

export default node;
