/**
 * @generated SignedSource<<3e432d334362b27f08fa178bea702f9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FieldReportQuestionTable_element$data = {
  readonly gridAnswers: ReadonlyArray<{
    readonly answerId: string | null;
    readonly goal: number | null;
    readonly rowId: string | null;
    readonly terminate: boolean | null;
  } | null> | null;
  readonly labeledText: string | null;
  readonly options: ReadonlyArray<{
    readonly qualified: number | null;
    readonly qualifiedTotal: number | null;
    readonly quota: string | null;
    readonly terminate: boolean | null;
    readonly text: string | null;
    readonly total: number | null;
  } | null> | null;
  readonly rows: ReadonlyArray<{
    readonly dbId: string;
    readonly labeledText: string | null;
    readonly options: ReadonlyArray<{
      readonly dbId: string;
      readonly qualified: number | null;
      readonly qualifiedTotal: number | null;
      readonly text: string | null;
      readonly total: number | null;
    } | null> | null;
    readonly text: string | null;
  } | null> | null;
  readonly text: string | null;
  readonly type: string | null;
  readonly " $fragmentType": "FieldReportQuestionTable_element";
};
export type FieldReportQuestionTable_element$key = {
  readonly " $data"?: FieldReportQuestionTable_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"FieldReportQuestionTable_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labeledText",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qualified",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qualifiedTotal",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FieldReportQuestionTable_element",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportRowNode",
      "kind": "LinkedField",
      "name": "rows",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FieldReportRowOptionNode",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportOptionNode",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quota",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportGridAnswerNode",
      "kind": "LinkedField",
      "name": "gridAnswers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "goal",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FieldReportElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "b5b6430551aaf56eda3b8d2ecc94d3e8";

export default node;
