import { Card, Collapse } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo } from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES } from "../../../constants";
import { environment } from "../../../relay";
import { GRAY_6, PRIMARY_TEXT_COLOR } from "../../../style";
import { CharacteristicTableQuery } from "../../../__generated__/CharacteristicTableQuery.graphql";
import { CharacteristicTable_panel$data } from "../../../__generated__/CharacteristicTable_panel.graphql";
import { Loading } from "../../index";

const Panel = Collapse.Panel;

const CharacteristicRows = ({ panel }: { panel: CharacteristicTable_panel$data }) => {
  const sortedCharacteristics = [...(panel?.tenantCharacteristics || [])].sort((a, b) => {
    if (a?.title && b?.title) {
      return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
      return 0;
    }
  });

  return useMemo(() => {
    return (
      <Collapse expandIconPosition="end">
        {sortedCharacteristics.map(o => (
          <Panel header={o?.title as string} key={o?.key as string}>
            <QueryRenderer<CharacteristicTableQuery>
              environment={environment}
              query={graphql`
                query CharacteristicTableQuery($name: String) {
                  viewer {
                    panel {
                      characteristic(name: $name) {
                        name
                        totalCount
                        type
                        options {
                          name
                          count
                        }
                        rows {
                          name
                          options {
                            name
                            count
                          }
                        }
                      }
                    }
                  }
                }
              `}
              variables={{ name: o?.key }}
              render={({ error, props }) => {
                if (error) {
                  return <div className="text">Error.</div>;
                }
                const characteristic = props?.viewer?.panel?.characteristic;
                if (!characteristic) {
                  return null;
                }

                const totalPanelists = panel?.totalPanelists || 0;
                const unknownTotal = totalPanelists - (characteristic?.totalCount ?? 0);
                const numFormat = new Intl.NumberFormat();
                const percentFormat = new Intl.NumberFormat("en-US", {
                  style: "percent",
                  maximumFractionDigits: 0,
                });

                return characteristic.options ? (
                  <span>
                    <CharacteristicHeader className="char-header">
                      <div></div>
                      <div className="numbers-container">
                        <div className="number">Count</div>
                        <div className="number">%</div>
                      </div>
                    </CharacteristicHeader>
                    <Row className="total-row">
                      <div>Total</div>
                      <div className="numbers-container">
                        <div className="number">{numFormat.format(totalPanelists)}</div>
                        <div className="number" />
                      </div>
                    </Row>
                    {characteristic.type === ELEMENT_TYPES.GRID_SINGLE_SELECT
                      ? characteristic.rows?.map(row => (
                          <>
                            <Row key={row.name}>
                              <div className="row-title">{row?.name}</div>
                              <div />
                            </Row>
                            {row.options?.map((o, i) => (
                              <Row key={i}>
                                <div>{o?.name}</div>
                                <div className="numbers-container">
                                  <div className="number">{o?.count ? numFormat.format(o?.count ?? 0) : "-"}</div>
                                  <div className="number">
                                    {totalPanelists - unknownTotal === 0 || !o?.count
                                      ? "-"
                                      : percentFormat.format((o?.count || 0) / (totalPanelists - unknownTotal))}
                                  </div>
                                </div>
                              </Row>
                            ))}
                          </>
                        ))
                      : characteristic.options.map((o, i) => (
                          <Row key={i}>
                            <div>{o?.name}</div>
                            <div className="numbers-container">
                              <div className="number">{o?.count ? numFormat.format(o?.count ?? 0) : "-"}</div>
                              <div className="number">
                                {totalPanelists - unknownTotal === 0 || !o?.count
                                  ? "-"
                                  : percentFormat.format((o?.count || 0) / (totalPanelists - unknownTotal))}
                              </div>
                            </div>
                          </Row>
                        ))}

                    <Row>
                      <div>Unknown</div>
                      <div className="numbers-container">
                        <div className="number">{unknownTotal ? numFormat.format(unknownTotal) : "-"}</div>
                        <div className="number"></div>
                      </div>
                    </Row>
                  </span>
                ) : (
                  <div style={{ padding: 12 }}>
                    <Loading />
                  </div>
                );
              }}
            />
          </Panel>
        ))}
      </Collapse>
    );
  }, [panel, sortedCharacteristics]);
};

const CharacteristicTable = ({ panel }: { panel: CharacteristicTable_panel$data }) => {
  return (
    <Styled>
      <Card>
        <Header>Characteristics</Header>
        <CharacteristicRows panel={panel} />
      </Card>
    </Styled>
  );
};

const Header = styled.div`
  background-color: #fafafa;
  height: 39px;
  padding: 8px;
  font-weight: 500;
  border-top: 1px solid #f0f0f0;
`;

const Styled = styled.div`
  .ant-card {
    overflow: hidden;
  }
  .ant-card-body {
    padding: 0;
  }

  .ant-collapse {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-header {
    padding: 8px !important;
    font-weight: 500;
    background-color: white;
    color: ${PRIMARY_TEXT_COLOR} !important;
  }

  .ant-collapse-arrow {
    right: 24px;
  }

  .ant-collapse-item {
    border: none;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-collapse {
    border-top: 1px solid #f0f0f0;

    .total-row {
      border-bottom: 1px solid ${GRAY_6};
    }
  }
`;

const CharacteristicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 39px;
  padding: 8px 24px 8px 48px !important;
  font-weight: 500;
  background-color: #fafafa;

  .numbers-container {
    display: flex;

    .number {
      width: 85px;
      text-align: right;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 39px;
  padding: 8px 24px 8px 48px !important;

  :hover {
    background-color: #fafafa;
  }

  :not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }

  .numbers-container {
    display: flex;

    .number {
      width: 85px;
      text-align: right;
    }
  }

  .row-title {
    font-weight: 500;
  }
`;

export default createFragmentContainer(CharacteristicTable, {
  panel: graphql`
    fragment CharacteristicTable_panel on Panel {
      totalPanelists
      tenantCharacteristics {
        key
        title
      }
      health {
        totalParticipants {
          count
          month
          year
        }
      }
    }
  `,
});
