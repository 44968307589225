/**
 * @generated SignedSource<<d617fddd079ac6c9574d17b40a10882c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputFilter_element$data = {
  readonly position: number;
  readonly " $fragmentType": "InputFilter_element";
};
export type InputFilter_element$key = {
  readonly " $data"?: InputFilter_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilter_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilter_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "09b25761b060368b721cb74cef932da9";

export default node;
