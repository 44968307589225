import { App, Button, Checkbox, Col, Form, Input, message, Modal, Row, Typography } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import mixpanel from "mixpanel-browser";
import { useCallback, useEffect, useState } from "react";
import { PayloadError } from "relay-runtime";
import styled from "styled-components";
import { LoggedOutPage } from ".";
import {
  checkAuth,
  getJwt,
  getTenantContext,
  getUserContext,
  setJwt,
  trackEvent,
  useFlag,
  useMutation,
} from "../utils";
import { getApiBaseUrl } from "../utils/misc";
import type { RegistrationPageLoginMutation } from "../__generated__/RegistrationPageLoginMutation.graphql";
import type {
  RegistrationPageMutation,
  RegistrationPageMutation$data,
} from "../__generated__/RegistrationPageMutation.graphql";
import type { RegistrationPage_checkPasswordStrength_Mutation } from "../__generated__/RegistrationPage_checkPasswordStrength_Mutation.graphql";

const requestEmailVerificationCode = async (email: string) => {
  try {
    await axios.post(`${getApiBaseUrl()}/api/request-email-verification-code`, { email });
  } catch (err) {
    let errorMessage = "An error occurred while requesting an email code.";

    if (axios.isAxiosError(err)) {
      if (err.response?.data?.[0] === "Invalid work email address") {
        errorMessage = "Please use your work email, not a personal email address.";
      } else if (err.response?.status === 429) {
        // don't throw in this case so the verification modal will still pop up
        message.error("A code was recently sent to you. Please wait a moment and try again.");
        return;
      }
    }

    throw Error(errorMessage);
  }
};

type RegisterType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tenantName: string;
  emailVerificationCode: string;
};

export const RegistrationPage = () => {
  const { match, router } = useRouter();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const { first, last, email } = match.location.query;

  const [commitLogin] = useMutation<RegistrationPageLoginMutation>(graphql`
    mutation RegistrationPageLoginMutation($email: String!, $password: String!) {
      tokenAuth(email: $email, password: $password) {
        token
      }
    }
  `);

  const login = useCallback(
    (email: string, password: string) => {
      return new Promise((resolve, reject) => {
        commitLogin(
          {},
          {
            variables: { email: email.toLowerCase(), password },
            onCompleted: ({ tokenAuth }, errors) => {
              if (errors) {
                return reject(new Error(errors[0]?.message));
              }

              if (!tokenAuth?.token) {
                return reject(new Error("token not in response"));
              }

              setJwt(tokenAuth.token);
              resolve(tokenAuth);
            },
            onError: (response: any) => {
              reject(new Error(response.errors[0]?.message));
            },
          }
        );
      });
    },
    [commitLogin]
  );

  const [commitRegisterUser] = useMutation<RegistrationPageMutation>(graphql`
    mutation RegistrationPageMutation(
      $firstName: String!
      $lastName: String!
      $email: String!
      $password: String!
      $tenantName: String!
      $emailVerificationCode: String!
    ) {
      registerUser(
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          password: $password
          tenantName: $tenantName
          emailVerificationCode: $emailVerificationCode
        }
      ) {
        user {
          id
          dId
          email
          fullName
          isPanelist
          panelist {
            dId
            tenants {
              edges {
                node {
                  name
                }
              }
            }
          }
          profile {
            tenant {
              id
              dId
              name
              dId
              vpmAccountId
              name
            }
          }
          dId
          vpmUserId
        }
        tokenSet {
          accessToken
          refreshToken
        }
      }
    }
  `);

  const register = useCallback(
    async (values: RegisterType) => {
      const { firstName, lastName, email, password, tenantName, emailVerificationCode } = values;

      return new Promise<RegistrationPageMutation$data["registerUser"]>((resolve, reject) =>
        commitRegisterUser(
          {},
          {
            variables: { firstName, lastName, email, password, tenantName, emailVerificationCode },
            onCompleted: (
              response: RegistrationPageMutation$data,
              errors: readonly PayloadError[] | null | undefined
            ) => {
              if (errors?.length) {
                return reject(new Error(errors[0]?.message));
              }
              trackEvent("Account Signup", {
                ...getTenantContext(response.registerUser?.user.profile?.tenant),
                ...getUserContext(response.registerUser?.user),
              });
              return resolve(response.registerUser);
            },
            onError: (error: Error) => {
              return reject(error);
            },
          }
        )
      );
    },
    [commitRegisterUser]
  );

  const [commitCheckPasswordStrength] = useMutation<RegistrationPage_checkPasswordStrength_Mutation>(graphql`
    mutation RegistrationPage_checkPasswordStrength_Mutation($input: CheckPasswordStrengthInput!) {
      checkPasswordStrength(input: $input) {
        strong
        reason
      }
    }
  `);

  const checkPasswordStrength = useCallback(
    async (values: RegisterType) => {
      return new Promise<RegisterType>((resolve, reject) => {
        commitCheckPasswordStrength(
          {
            password: values.password,
            userInfo: values,
          },
          {
            onCompleted: result => {
              if (result.checkPasswordStrength?.strong) {
                resolve(values);
              } else {
                reject(new Error(`Please choose a stronger password. ${result.checkPasswordStrength?.reason}`));
              }
            },
            onError: () => reject(new Error("Error checking password strength.")),
          }
        );
      });
    },
    [commitCheckPasswordStrength]
  );

  useEffect(() => {
    // On mount we check for a token. If found we check the token is valid.
    // If valid, we forward the user to the app
    const checkUser = async () => {
      const { jwt: token } = getJwt();
      if (token === null) {
        return false;
      }

      const user = await checkAuth();
      if (!user.email) {
        return false;
      } else if (user.isTenant) {
        router.push("/projects");
      } else if (user.isPanelist) {
        router.push("/portal");
      }
    };
    checkUser();
  }, [router]);

  const submitForm = () => {
    form
      .validateFields()
      .then(values => {
        setLoading(true);
        return checkPasswordStrength(values);
      })
      .then(values => requestEmailVerificationCode(values.email))
      .then(() => {
        setEmailVerificationCode("");
        setShowVerifyEmailModal(true);
        setLoading(false);
      })
      .catch(err => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const submitRegistration = async (values: RegisterType) => {
    setLoading(true);
    try {
      const result = await register(values);

      // SSO tokens will be included in the response if enabled, otherwise we log in via integrated auth
      if (result?.tokenSet) setJwt(result.tokenSet.accessToken, result.tokenSet.refreshToken);
      else await login(values.email, values.password);

      trackEvent("Register User", {
        ...getUserContext(result?.user),
        "User email": result?.user?.email,
        ...getTenantContext(result?.user?.profile?.tenant),
      });
      mixpanel.alias(result?.user?.email as string);

      router.push("/projects?welcome");
    } catch (e: any) {
      if (e.errors) {
        message.error(e.errors[0]?.message);
      }
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { modal } = App.useApp();

  if (!useFlag("hub-signup-self")) router.replace("/login");

  return (
    <LoggedOutPage>
      <Modal open={showVerifyEmailModal} footer={null} closable={false} width={350}>
        <h2 style={{ textAlign: "center" }}>Verify your Email</h2>
        <p>
          We've sent a verification code to <strong>{formEmail}</strong>. The code will expire in 30 minutes.
        </p>
        <p style={{ textAlign: "center" }}>Enter your code below.</p>
        <div style={{ width: 90, margin: "auto", marginBottom: 20 }}>
          <Input
            autoFocus
            placeholder="XXXXXX"
            onChange={e => setEmailVerificationCode(e.target.value)}
            size="large"
            readOnly={loading}
            maxLength={6}
            style={{ textAlign: "center" }}
          />
        </div>
        <p>
          Didn't receive the email?{" "}
          <Typography.Link
            onClick={async () => {
              try {
                await requestEmailVerificationCode(formEmail);
              } catch {
                return;
              }

              modal.info({
                title: "New code sent",
                content: (
                  <>
                    <p>
                      A new verification code has been sent to <strong>{formEmail}</strong>.
                    </p>
                    <p>
                      If you don't see it in your inbox, check your spam folder. Any verification codes sent to you
                      previously have been deactivated.
                    </p>
                  </>
                ),
              });
            }}
          >
            Send a new code
          </Typography.Link>
        </p>
        <Row>
          <Col span={12}>
            {" "}
            <Button
              style={{ width: "100%" }}
              disabled={loading}
              type="link"
              onClick={() => setShowVerifyEmailModal(false)}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            {" "}
            <Button
              style={{ width: "100%" }}
              disabled={loading || emailVerificationCode.length < 6}
              type="primary"
              onClick={async () => await submitRegistration({ ...form.getFieldsValue(), emailVerificationCode })}
              htmlType="submit"
            >
              Verify
            </Button>
          </Col>
        </Row>
      </Modal>
      <Styled>
        <div className="title">Register to streamline your research operations and get recruiting!</div>
        <Form
          layout="vertical"
          form={form}
          validateTrigger="onSubmit"
          onFinish={() => submitForm()}
          initialValues={{ firstName: first, lastName: last, email: email }}
        >
          <Form.Item
            name="firstName"
            label={"First name"}
            rules={[
              {
                required: true,
                message: "Please provide your first name.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={"Last name"}
            rules={[
              {
                required: true,
                message: "Please provide your last name.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Work email"
            rules={[
              {
                type: "email",
                message: "Please provide a valid work email",
              },
              {
                required: true,
                message: "Please provide your work email",
              },
            ]}
          >
            <Input value={formEmail} onChange={e => setFormEmail(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="tenantName"
            label={"Organization name"}
            rules={[
              {
                required: true,
                message: "Please provide an organization name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={"Password"}
            rules={[
              {
                required: true,
                message: "Please provide a password",
              },
              {
                min: 8,
                message: "Passwords should be min. 8 characters",
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={"Confirm password"}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords that you entered do not match");
                },
              }),
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="permission"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject("Please accept our Terms of Service"),
              },
            ]}
          >
            <Checkbox>
              I agree to the{" "}
              <a href={"https://hubux.com/DIY_terms"} target="_blank" rel="noopener noreferrer">
                Terms of Service.
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item className="complete">
            <Button
              style={{ marginLeft: 12 }}
              loading={loading && !showVerifyEmailModal}
              type="primary"
              htmlType="submit"
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </Styled>
    </LoggedOutPage>
  );
};

const Styled = styled.div`
  .title {
    font-size: 16px;
    margin-bottom: 2rem;
  }

  .complete .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;
