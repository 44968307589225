import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";

import { RankElement_element$data } from "../../__generated__/RankElement_element.graphql";
import { RankElement_screener$data } from "../../__generated__/RankElement_screener.graphql";
import { CommonElementProps } from "./Element";
import { ElementBase } from "./index";

type Props = {
  element: RankElement_element$data;
  screener: RankElement_screener$data;
} & CommonElementProps;
const RankElement: React.FC<Props> = props => <ElementBase {...props} />;

export default createFragmentContainer(RankElement, {
  element: graphql`
    fragment RankElement_element on ElementNode {
      ...ElementBase_element
    }
  `,
  screener: graphql`
    fragment RankElement_screener on ScreenerNode {
      ...ElementBase_screener
    }
  `,
});
