/**
 * @generated SignedSource<<6abac35211da5c1e1e3722e697820429>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GivePointsToPanelistModal_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GivePointsModal_tenant">;
  readonly " $fragmentType": "GivePointsToPanelistModal_tenant";
};
export type GivePointsToPanelistModal_tenant$key = {
  readonly " $data"?: GivePointsToPanelistModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GivePointsToPanelistModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GivePointsToPanelistModal_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GivePointsModal_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "bafe607fc0a46c9bbec4e8bced2f7972";

export default node;
