/**
 * @generated SignedSource<<1f80e2aefff2fea9eeba099228b2148d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputFilterGroup_element$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilter_element">;
  readonly " $fragmentType": "InputFilterGroup_element";
};
export type InputFilterGroup_element$key = {
  readonly " $data"?: InputFilterGroup_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroup_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilterGroup_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilter_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "6d249a88f1a4d4aabb09fa06ff951798";

export default node;
