/**
 * @generated SignedSource<<d4aa33f69a5584d1cb119ab0c5d7c129>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationComposePage_user$data = {
  readonly dId: any | null;
  readonly email: string;
  readonly panelist: {
    readonly dId: any | null;
  } | null;
  readonly vpmUserId: number | null;
  readonly " $fragmentType": "CommunicationComposePage_user";
};
export type CommunicationComposePage_user$key = {
  readonly " $data"?: CommunicationComposePage_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationComposePage_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vpmUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "2ef137b7f0cb401dff7b2bfaf457ea86";

export default node;
