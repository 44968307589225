import { Helmet } from "react-helmet";

type Props = {
  panelistId: string;
  markup?: string | null;
};
function PortalCustomCode(props: Props) {
  const { js, makeMarkup } = createMarkup(props.markup || "", props.panelistId);

  return (
    <>
      <div dangerouslySetInnerHTML={makeMarkup()} />
      {js && (
        <Helmet>
          <script>{js}</script>
        </Helmet>
      )}
    </>
  );
}

function createMarkup(markup: string, panelistId: string) {
  let js;

  const re = /<\+script>([\s|\S]*)<\+\/script>/gi;

  // Browser replaces "<" tags with with \x3c, so we do a string replace to
  // trick the browser with <+script and <+/script
  markup = markup.replace(/\x3cscript/gi, "<+script");
  markup = markup.replace(/\x3c\/script/gi, "<+/script");

  markup = markup.replace(/%panelist.id%/gi, panelistId);

  const match = markup.match(re);
  if (match && match.length === 1) {
    js = match[0];
  }

  if (js !== undefined) {
    js = js.replace("<+script>", "");
    js = js.replace("<+/script>", "");
    markup = markup.replace(re, "");
  }

  return {
    js,
    makeMarkup() {
      return { __html: markup };
    },
  };
}

export default PortalCustomCode;
