/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "@iconify/react";
import { App, Button, Card, Checkbox, Divider, Input, Modal, Popover, theme } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { graphql } from "babel-plugin-relay/macro";
import type { FixedType } from "rc-table/lib/interface";
import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { createPaginationContainer, type RelayPaginationProp } from "react-relay";
import styled from "styled-components";
import type { IterableElement } from "type-fest";

import { kebabCase } from "lodash-es";
import {
  AlertVoxpopmeVideoAnalysis,
  Note,
  NoteIsEditingStateContext,
  PANELIST_NOTE_MARGIN_INNER,
  ParticipantsTableHeader,
  useNoteIsEditingState,
} from "..";
import {
  APPROVAL_STATUS,
  DEFAULT_PARTICIPANTS_FETCH_COUNT,
  ELEMENT_TYPES,
  NON_FILTERABLE_ELEMENT_TYPES,
  PROJECT_STATUSES,
  PROJECT_TYPES,
  RECRUIT_STATUSES,
} from "../../constants";
import {
  ScreenersElementTypeChoices,
  ScreenersRespondentMasterStatusChoices,
  ScreenersStudyTypeChoices,
} from "../../schema";
import {
  flattenEdges,
  getStudyContext,
  getTenantContext,
  trackEvent,
  useConfirmQuitEditing,
  useFlag,
} from "../../utils";
import { useComponentVisible } from "../../utils/hooks";
import { getApiBaseUrl } from "../../utils/misc";
import { Cells_NextStepCell_respondent$key } from "../../__generated__/Cells_NextStepCell_respondent.graphql";
import { ParticipantsTable_study$data } from "../../__generated__/ParticipantsTable_study.graphql";
import { ParticipantsTable_user$data } from "../../__generated__/ParticipantsTable_user.graphql";
import { ExportButton } from "../ExportButton";
import { QuestionIdentModal } from "../QuestionIdentModal";
import AntDTable from "../tables/AntDTable";
import {
  AnswerCell,
  EllipsisCell,
  GridAnswerCell,
  NameCell,
  NextStepCell,
  ReviewCell,
  SegmentCell,
  StatusCell,
  TextCell,
} from "../tables/Cells";
import RespondentResponses from "../tables/RespondentResponses";
import SelectAllPopoverContent from "../tables/SelectAllPopoverContent";
import useTableSelect from "../tables/useTableSelect";
import ParticipantBatchActions from "./ParticipantBatchActions";
import ParticipantColumnsQuery from "./ParticipantColumnsQuery";
import ParticipantFilterGroupQuery from "./ParticipantFilterGroupQuery";
import { createParticipantFilterGroupGroup } from "./ParticipantFilters";
import { BatchMutationType, participantBatchAction } from "./ParticipantsBatchMutations";
import { ParticipantTypesPicker, PARTICIPANT_TYPES } from "./ParticipantTypesPicker";

const { useToken } = theme;

export interface IElement {
  node: {
    dbId: string;
    position: number;
    rows: {
      edges: any[];
    };
    text: string;
    type: string;
    answers: {
      edges: [
        {
          node: {
            position: number;
            text: string;
            dbId: string;
          };
        }
      ];
    };
  };
}

export enum TabType {
  ALL = "ALL",
  SCREENER_COMPLETED = "SCREENER_COMPLETED",
  APPROVED = "APPROVED",
  SCHEDULED = "SCHEDULED",
  NEEDS_REWARDING = "NEEDS_REWARDING",
  COMPLETE = "COMPLETE",
  REJECTED = "REJECTED",
  OVER_QUOTA = "OVER_QUOTA",
}

type Participant = NonNullable<
  NonNullable<IterableElement<NonNullable<ParticipantsTable_study$data["participantsDetail"]>["edges"]>>["node"]
>;
const ParticipantsTable = ({
  study,
  user,
  relay,
}: {
  study: ParticipantsTable_study$data;
  user: ParticipantsTable_user$data;
  relay: RelayPaginationProp;
}) => {
  const { message, notification } = App.useApp();

  const [filterGroupGroup, setFilterGroupGroup] = useState(createParticipantFilterGroupGroup());
  const [responseModalOpen, setResponseModalOpen] = useState<boolean>(false);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const [responsesModalRowIndex, setResponsesModalRowIndex] = useState<number | null>(null);
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ALL);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [elementIds, setElementIds] = useState<string[] | null[]>([null]);
  const [columnsDrawerVisible, setColumnsDrawerVisible] = useState<boolean>(false);
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState<boolean>(false);
  const [participantsType, setParticipantsType] = useState(PARTICIPANT_TYPES.SHOW_TEST_PARTICIPANTS);
  const [finishedRounds, setFinishedRounds] = useState<string[]>(
    study.recruits.edges.filter(e => e!.node!.status === RECRUIT_STATUSES.FINISHED).map(e => e!.node!.id)
  );
  const [givePointsToParticipantId, setGivePointsToParticipantId] = useState("");

  const {
    loading,
    setLoading,
    pagedData,
    page,
    selectAllChecked,
    selectedIds,
    selectedCount,
    deselectedIds,
    countToSelect,
    selectRows,
    setPage,
    changePage,
    toggleSelectAll,
    load,
    selectNone,
  } = useTableSelect({
    relay,
    defaultFetchCount: DEFAULT_PARTICIPANTS_FETCH_COUNT,
  });
  const { Search } = Input;

  const refreshData = React.useCallback((): void => {
    // Called when filters or tab changed. Filters participants by approvalStatus based on Table Header filters
    setLoading(true);
    relay.refetchConnection(
      DEFAULT_PARTICIPANTS_FETCH_COUNT,
      (err: any) => {
        if (err) {
          if (typeof err === "object" && typeof err.then! === "function")
            err.then((x: any) => console.error(x, JSON.stringify(x)));
          else console.error(`Error with Relay refetch: ${err}`);
        }
        setLoading(false);
        setPage(1);
        selectNone();
      },
      {
        tab: selectedTab,
        filterComplex: filterGroupGroup ?? null,
        excludeNonTest: participantsType === PARTICIPANT_TYPES.TEST_ONLY,
        includeTest: [PARTICIPANT_TYPES.SHOW_TEST_PARTICIPANTS, PARTICIPANT_TYPES.TEST_ONLY].includes(participantsType),
        searchTerm: searchTerm,
        elementIds: elementIds,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGroupGroup, participantsType, selectedTab, searchTerm, elementIds, relay]);

  // refreshData when selected tab changes. useRef to avoid running this effect on mount
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      refreshData();
    } else {
      isMounted.current = true;
    }
  }, [selectedTab, refreshData]);

  useEffect(() => {
    // Updates table data when new data is fetched or page is changed
    load(study.participantsDetail);
  }, [study.participantsDetail, load]);

  // notify users if their last action completed a recruiting round's goal
  useEffect(() => {
    const newlyFinishedRounds = study.recruits.edges.filter(
      e => e!.node!.status === RECRUIT_STATUSES.FINISHED && !finishedRounds.includes(e!.node!.id)
    );

    if (newlyFinishedRounds.length > 0) {
      // don't spam notifications when marking a project complete
      if (study.status !== PROJECT_STATUSES.COMPLETE) {
        newlyFinishedRounds.forEach(e =>
          notification.info({
            message: `${e!.node!.name} just reached its recruitment goal`,
            description:
              "We stopped sending invitations to respondents, but those who received them will still be able to complete the screener",
          })
        );
      }

      setFinishedRounds([...finishedRounds, ...newlyFinishedRounds.map(e => e!.node!.id)]);
    }
  }, [study.recruits, study.status, finishedRounds, notification]);

  const setOnlyFiltersDrawerVisible = (visible: boolean) => {
    setColumnsDrawerVisible(false);
    setFiltersDrawerVisible(visible);
  };

  const setOnlyColumnsDrawerVisible = (visible: boolean) => {
    setFiltersDrawerVisible(false);
    setColumnsDrawerVisible(visible);
  };

  // Passed to FilterDropDown to call on filter changes
  const onFilter = (x: typeof filterGroupGroup) => {
    setFilterGroupGroup(x);
  };

  const onColumnSelection = (columns: CheckboxValueType[]) => {
    setColumnsDrawerVisible(false);
    if (columns.length === 0) {
      setElementIds([null]);
    } else {
      setElementIds(columns as string[]);
    }
  };

  const questionHeadersSerializer = (elements: any): any[] => {
    /*
     * Prepares the columns for the screener results section of the table
     */

    const returnArray: any = [];

    elements?.edges?.forEach((element: IElement) => {
      // Skip non-regular question types
      if (!NON_FILTERABLE_ELEMENT_TYPES.includes(element?.node?.type)) {
        if (element.node.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
          returnArray.push({
            title: <EllipsisCell value={element.node.text} />,
            key: element.node.dbId,
            width: 305,
            dataIndex: "node",
            render: (props: any) => GridAnswerCell(props, element, study.id, openReviewModal),
          });
        } else {
          returnArray.push({
            title: <EllipsisCell value={element.node.text} />,
            key: element.node.dbId,
            width: 200,
            dataIndex: "node",
            render: (props: any) => AnswerCell(props, element.node.dbId),
          });
        }
      }
    });

    returnArray.push({
      title: (
        <Button type="link" htmlType="button" onClick={() => setColumnsDrawerVisible(true)}>
          + Add columns
        </Button>
      ),
      key: "add_columns",
      width: 200,
      dataIndex: null,
    });

    return returnArray.flat();
  };

  const showCheckboxColumn =
    [TabType.SCREENER_COMPLETED, TabType.NEEDS_REWARDING, TabType.COMPLETE, TabType.REJECTED].includes(selectedTab) ||
    (study.type === PROJECT_TYPES.FOCUS_GROUP && [TabType.APPROVED, TabType.SCHEDULED].includes(selectedTab));

  const openReviewModal = (participant: Participant, rowIndex?: number) => {
    setSelectedParticipant(participant);
    setResponsesModalRowIndex(rowIndex || null);
    // !window.getSelection() detects if the user has selected text in the table when click-and-dragging
    setResponseModalOpen(!window?.getSelection()?.toString()?.length);
  };

  const staticColumns = () => {
    const columns: {
      title: string;
      key: string;
      width: number;
      dataIndex: string | string[];
      render: (...args: any[]) => JSX.Element | null;
      fixed?: FixedType;
    }[] = [
      {
        title: "Name",
        key: "name",
        width: showCheckboxColumn ? 118 : 150,
        dataIndex: ["node"],
        render: (participant: Participant) => {
          const onClick = () => {
            openReviewModal(participant);
          };
          return NameCell(participant.respondent.person, !!participant.respondent.recruit?.isTest, onClick);
        },
        fixed: "left",
      },
      {
        title: "Recruiting round",
        key: "recruit-round",
        width: 150,
        dataIndex: ["node", "respondent", "recruit", "name"],
        render: (name: any) => TextCell(name),
      },
      {
        title: "Status",
        key: "status",
        width: 150,
        dataIndex: "node",
        render: (props: any) => {
          return StatusCell(props, study.schedulingType);
        },
      },
      {
        title: "Next step",
        key: "next-step",
        width: 220,
        dataIndex: "node",
        render: (value: { respondent: Cells_NextStepCell_respondent$key }) => (
          <NextStepCell
            {...{ value, study, refreshData, selectedTab, openReviewModal, setGivePointsToParticipantId }}
          />
        ),
      },
      {
        title: "Participant ID",
        key: "id",
        width: 100,
        dataIndex: ["node", "respondent", "dId"],
        render: (id: any) => (
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: 160,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {id}
            </div>
            <CopyToClipboard text={id} onCopy={() => message.info("Participant ID copied to clipboard")}>
              <Icon icon="mdi:content-copy" width={20} height={20} style={{ cursor: "pointer" }} />
            </CopyToClipboard>
          </div>
        ),
      },
    ];

    return study.screener?.segments?.edges?.length
      ? columns.concat({
          title: "Segments",
          key: "segments",
          width: 200,
          dataIndex: ["node", "respondent", "segmentResponses"],
          render: (segments: any) => SegmentCell(segments),
        })
      : columns;
  };

  const columns = () => staticColumns().concat(questionHeadersSerializer(study.screener?.elements));

  const batchAction = async (mutationType: BatchMutationType, noShow?: boolean, rating?: number, slotId?: string) => {
    await participantBatchAction({
      studyId: study.id,
      filterComplex: filterGroupGroup,
      tab: selectedTab,
      excludeIds: deselectedIds,
      countToSelect,
      selectAllChecked,
      selectedIds,
      mutationType,
      noShow,
      rating,
      slotId,
    });

    if (mutationType === BatchMutationType.APPROVE)
      trackEvent("Respondents Approved", { ...getStudyContext(study), ...getTenantContext(study.tenant) });

    toggleSelectAll(false);
    selectRows([]);
    refreshData();
  };

  const panelistNoteIsEditingState = useNoteIsEditingState();
  const selectedPerson = selectedParticipant?.respondent.person;

  const confirmQuitEditing = useConfirmQuitEditing();

  const linkToVoxParticipantInterviewed = useFlag("hub-project-link-to-vpm-participant-interviewed");
  const linkToVoxParticipantResponded = useFlag("hub-project-link-to-vpm-participant-responded");

  const { token } = useToken();

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };

  return (
    <Styled>
      <ParticipantsTableHeader
        study={study}
        setSelectedTab={setSelectedTab}
        needsReviewCount={study.needsReviewCount || 0}
        approvedTabCount={study.approvedTabCount || 0}
        scheduledCount={study.scheduledCount || 0}
        allCount={study.allCount || 0}
        overquotaCount={study.overquotaCount || 0}
        needsPaymentCount={study.needsPaymentCount || 0}
        completeCount={study.completeCount || 0}
        rejectedCount={study.rejectedCount || 0}
      />
      <QuestionIdentModal
        visible={isVisible}
        immediateUrl={`${getApiBaseUrl()}/s/${study.dId}/responses`}
        onCloseChange={handleVisibleChange}
        relatedId={study.id}
      />
      <Card
        bodyStyle={{ padding: "0 0 16px" }}
        extra={
          <ExportButton
            export={study.export}
            immediate={(study.allCount || 0) < 80}
            immediateFilename={`${kebabCase(study.name!)}--responses`}
            immediateUrl={`${getApiBaseUrl()}/s/${study.dId}/responses`}
            relatedId={study.id}
          />
        }
        headStyle={{ paddingInline: 16 }}
        title={
          <div className="search-filter">
            <Search
              className="participant-search"
              placeholder="Search"
              onSearch={value => setSearchTerm(value)}
              allowClear
              loading={loading}
            />
            <ParticipantFilterGroupQuery
              onApplyFilters={onFilter}
              loading={loading}
              studyId={study.id}
              setFiltersDrawerVisible={setOnlyFiltersDrawerVisible}
              filtersDrawerVisible={filtersDrawerVisible}
              value={filterGroupGroup}
            />
            <ParticipantColumnsQuery
              onApplyColumns={onColumnSelection}
              loading={loading}
              studyId={study.id}
              setColumnsDrawerVisible={setOnlyColumnsDrawerVisible}
              columnsDrawerVisible={columnsDrawerVisible}
            />
            {(study.testRecruit.completedScreenerCount ?? 0) > 0 && (
              <ParticipantTypesPicker type={participantsType} setType={setParticipantsType} />
            )}
            <div style={{ flexGrow: 1, textAlign: "right" }}>
              <ParticipantBatchActions
                study={study}
                user={user}
                selectedTab={selectedTab}
                participantBatch={{
                  studyId: study.id,
                  filterComplex: filterGroupGroup,
                  tab: selectedTab,
                  excludeIds: deselectedIds,
                  countToSelect,
                  selectAllChecked,
                  selectedIds,
                  mutationType: BatchMutationType.GIVE_POINTS,
                }}
                participantsType={participantsType}
                batchAction={batchAction}
                selectedIds={selectedIds}
                selectedCount={
                  // prevent old UI state when switching tabs
                  loading ? 0 : selectedCount
                }
                givePointsToParticipantId={givePointsToParticipantId}
                setGivePointsToParticipantId={setGivePointsToParticipantId}
              />
            </div>
          </div>
        }
      >
        <AntDTable
          loading={loading}
          data={pagedData}
          columns={columns}
          onPageChange={changePage}
          totalRecords={study.participantsDetail?.totalCount || 0}
          page={page}
          defaultFetchCount={DEFAULT_PARTICIPANTS_FETCH_COUNT}
          applyLeftPadding={!showCheckboxColumn}
          rowSelection={
            showCheckboxColumn
              ? {
                  columnTitle: (
                    <Popover
                      content={
                        <SelectAllPopoverContent
                          totalCount={study.participantsDetail?.totalCount}
                          filteredCount={study.participantsDetail?.totalCount}
                          selectAllOnClick={() => {
                            toggleSelectAll(true);
                            setIsComponentVisible(false);
                          }}
                          selectNumberOnClick={(numberToSelect: number | undefined) => {
                            toggleSelectAll(true, numberToSelect);
                            setIsComponentVisible(false);
                          }}
                          componentRef={ref}
                        />
                      }
                      getPopupContainer={trigger => trigger.closest(".ant-table-wrapper")!}
                      placement="bottom"
                      title="How many participants do you want to select?"
                      destroyTooltipOnHide={true}
                      open={isComponentVisible}
                      overlayStyle={{ width: "350px" }}
                    >
                      <Checkbox
                        checked={selectAllChecked}
                        onChange={e =>
                          e.target.checked ? setIsComponentVisible(true) : toggleSelectAll(e.target.checked)
                        }
                      />
                    </Popover>
                  ),
                  preserveSelectedRowKeys: true,
                  selectedRowKeys: selectedIds,
                  onChange: (selectedRowKeys: React.Key[]) => selectRows(selectedRowKeys as string[]),
                  columnWidth: 50,
                }
              : undefined
          }
        />
      </Card>
      <StyledParticipantModal
        wrapClassName="video-modal"
        style={{ maxWidth: "800px", maxHeight: "60vh", padding: "0" }}
        bodyStyle={{ maxHeight: "60vh", overflow: "auto" }}
        width="90vw"
        open={responseModalOpen}
        onCancel={async () => {
          if (!panelistNoteIsEditingState[0] || (await confirmQuitEditing())) {
            panelistNoteIsEditingState[1](false);
            setResponseModalOpen(false);
            setResponsesModalRowIndex(null);
          }
        }}
        destroyOnClose
        footer={
          selectedParticipant?.respondent?.approvalStatus === APPROVAL_STATUS.NEEDS_REVIEW && (
            <ReviewCell
              respondentId={selectedParticipant.respondent.id}
              respondentDId={selectedParticipant.respondent.dId}
              panelistDId={selectedParticipant.respondent.person?.panelist?.dId}
              study={study}
              buttonSize="middle"
              refreshData={refreshData}
              setResponseModalOpen={setResponseModalOpen}
            />
          )
        }
      >
        {selectedParticipant && (
          <>
            {!study.tenant.hideParticipantEmails && selectedPerson && (
              <div className="respondent-details">
                <h2 className="name">
                  {selectedPerson.firstName} {/* eslint-disable-next-line eqeqeq */}
                  {selectedPerson.panelistDId == selectedPerson.panelist?.dId
                    ? selectedPerson.lastName
                    : !!selectedPerson.lastName && `${selectedPerson.lastName.charAt(0)}.`}
                </h2>
                {/* eslint-disable-next-line eqeqeq */}
                {!!selectedPerson.panelistDId == selectedPerson.panelist?.dId && (
                  <>
                    {selectedPerson.email && <p>{selectedPerson.email}</p>}
                    {selectedPerson.phoneNumber && <p>{selectedPerson.phoneNumber}</p>}
                  </>
                )}
              </div>
            )}
            {((linkToVoxParticipantResponded &&
              // Respondent has answered a video Element
              flattenEdges(selectedParticipant.respondent.responses).some(
                x =>
                  x.value &&
                  [ScreenersElementTypeChoices.Av, ScreenersElementTypeChoices.Vd].includes(
                    x.element.type as ScreenersElementTypeChoices
                  )
              )) ||
              (linkToVoxParticipantInterviewed &&
                // Study supports interviews
                [ScreenersStudyTypeChoices.Oo, ScreenersStudyTypeChoices.Ss].includes(
                  study.type as ScreenersStudyTypeChoices
                ) &&
                // Participant has been interviewed
                ([
                  ScreenersRespondentMasterStatusChoices.Iw,
                  ScreenersRespondentMasterStatusChoices.Rw,
                  ScreenersRespondentMasterStatusChoices.Rt,
                ].includes(selectedParticipant.respondent?.masterStatus as ScreenersRespondentMasterStatusChoices) ||
                  selectedParticipant.respondent?.scheduledSlots.some(x => x.interviewed)))) && (
              <AlertVoxpopmeVideoAnalysis
                respondent={selectedParticipant.respondent}
                study={study}
                style={{ marginBottom: token.marginMD }}
                user={user}
              />
            )}
            <NoteIsEditingStateContext.Provider value={panelistNoteIsEditingState}>
              <Note panelistKey={selectedPerson?.panelist ?? null} respondentKey={selectedParticipant.respondent} />
              <Divider style={{ marginTop: PANELIST_NOTE_MARGIN_INNER }} />
            </NoteIsEditingStateContext.Provider>
            <RespondentResponses
              respondent={selectedParticipant.respondent}
              studyId={study.id}
              scrollRowIndex={responsesModalRowIndex}
            />
          </>
        )}
      </StyledParticipantModal>
    </Styled>
  );
};

const Styled = styled.div`
  & > .ant-card {
    border-top-left-radius: 0;
    margin-top: -1px;

    .ant-card-head-title {
      overflow: visible;
    }
  }

  .ant-table {
    table,
    .ant-table-cell,
    .ant-table-header {
      border-radius: 0 !important;
    }
  }

  .search-filter {
    display: flex;
    align-items: center;
    gap: 8px;

    .participant-search {
      max-width: 300px;
    }
  }
`;

const StyledParticipantModal = styled(Modal)`
  .respondent-details {
    display: flex;
    align-items: baseline;
    gap: 12px;
    white-space: nowrap;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;
    }
  }
`;

export default createPaginationContainer(
  ParticipantsTable,
  {
    study: graphql`
      fragment ParticipantsTable_study on StudyNode
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 30 }
        cursor: { type: "String" }
        tab: { type: "String" }
        filterComplex: { type: "GenericScalar" }
        excludeNonTest: { type: "Boolean", defaultValue: false }
        includeTest: { type: "Boolean", defaultValue: true }
        searchTerm: { type: "String" }
        elementIds: { type: "[ID]" }
      ) {
        id
        incentiveType
        dId
        export {
          ...ExportButton_export
        }
        name
        type
        status
        defaultIncentive
        defaultIncentivePoints
        scheduled
        schedulingType
        scheduledBy
        allowAutoApproval
        allCount
        overquotaCount
        needsReviewCount
        approvedTabCount
        scheduledCount
        approvedCount
        needsPaymentCount
        completeCount
        rejectedCount
        ...AlertVoxpopmeVideoAnalysis_study
        ...BulkScheduleModal_study
        ...Cells_NextStepCell_study
        ...Cells_ReviewCell_study
        ...ConfirmExternalIncentiveModal_study
        ...EditExternalIncentiveEmailModal_study
        ...ParticipantBatchActions_study
        ...ParticipantPaymentModal_study
        ...ParticipantsTableHeader_study
        ...ScheduleModal_study
        ...SlotPicker_study
        availabilitySlots {
          edges {
            node {
              id
            }
          }
        }
        recruits {
          edges {
            node {
              id
              name
              status
            }
          }
        }
        testRecruit {
          completedScreenerCount
        }
        screener {
          elements(id_In: $elementIds) {
            edges {
              node {
                dbId
                position
                type
                text
                answers {
                  edges {
                    node {
                      position
                      text
                      dbId
                    }
                  }
                }
                rows {
                  edges {
                    node {
                      dbId
                      text
                      position
                    }
                  }
                }
              }
            }
          }
          segments {
            edges {
              node {
                id
              }
            }
          }
        }
        # These variables come from the parent query
        participantsDetail(
          first: $count
          after: $cursor
          filterComplex: $filterComplex
          excludeNonTest: $excludeNonTest
          includeTest: $includeTest
          tab: $tab
          searchTerm: $searchTerm
        ) @connection(key: "ParticipantsTable_participantsDetail", filters: ["filterComplex", "tab", "elementIds"]) {
          totalCount
          edges {
            node {
              id
              respondent {
                id
                dId
                rhAnswer
                status
                approvalStatus
                masterStatus
                responses {
                  edges {
                    node {
                      element {
                        type
                      }
                      value
                    }
                  }
                }
                screenerResults
                segmentResponses {
                  edges {
                    node {
                      segment {
                        text
                      }
                    }
                  }
                }
                scheduledSlots {
                  session {
                    name
                  }
                  date
                  start
                  end
                  interviewed
                }
                person {
                  firstName
                  lastName
                  email
                  phoneNumber
                  optIn
                  panelistDId
                  panelist {
                    dId
                    ...Note_panelist
                  }
                }
                recruit {
                  name
                  isTest
                  incentive
                  incentivePoints
                  incentivesClearDate
                }
                ...AlertVoxpopmeVideoAnalysis_respondent
                ...Cells_NextStepCell_respondent
                ...Note_respondent
                ...RespondentResponses_respondent
                ...ScheduleModal_respondent
              }
              rsvp
              noShow
              rating
              incentivized
              incentivizedType
              incentivizedAmount
              incentivizedOn
              incentivizedPointAmount
              imported
              bonusPoints {
                orderDate
                pointsAmount
              }
            }
          }
        }
        tenant {
          dId
          name
          vpmAccountId
          hideParticipantEmails
          customportal {
            hidePointsAndRedemption
          }
        }
      }
    `,
    user: graphql`
      fragment ParticipantsTable_user on UserNode {
        ...AlertVoxpopmeVideoAnalysis_user
        ...ParticipantBatchActions_user
      }
    `,
  },
  {
    direction: "forward",
    getConnectionFromProps(props: any) {
      return props?.study?.participantsDetail;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        studyId: fragmentVariables.studyId,
        filterComplex: fragmentVariables.filterComplex,
        tab: fragmentVariables.tab,
        excludeNonTest: fragmentVariables.excludeNonTest,
        includeTest: fragmentVariables.includeTest,
        searchTerm: fragmentVariables.searchTerm,
        elementIds: fragmentVariables.elementIds,
      };
    },
    // This is the pagination query that gets sent when relay.loadMore() is called. We don't reload the Study or
    // Screener data, just participants
    query: graphql`
      query ParticipantsTableQuery(
        $count: Int!
        $cursor: String
        $studyId: String!
        $filterComplex: GenericScalar
        $excludeNonTest: Boolean!
        $includeTest: Boolean!
        $tab: String
        $searchTerm: String
        $elementIds: [ID]
      ) {
        viewer {
          study(studyId: $studyId) {
            dId
            export {
              ...ExportButton_export
            }
            screener {
              elements(id_In: $elementIds) {
                edges {
                  node {
                    dbId
                    position
                    type
                    text
                    answers {
                      edges {
                        node {
                          position
                          text
                          dbId
                        }
                      }
                    }
                    rows {
                      edges {
                        node {
                          dbId
                          text
                          position
                        }
                      }
                    }
                  }
                }
              }
            }
            tenant {
              dId
              name
              vpmAccountId
            }
            ...AlertVoxpopmeVideoAnalysis_study
            ...BulkScheduleModal_study
            ...Cells_NextStepCell_study
            ...Cells_ReviewCell_study
            ...ConfirmExternalIncentiveModal_study
            ...EditExternalIncentiveEmailModal_study
            ...ParticipantBatchActions_study
            ...ParticipantPaymentModal_study
            ...ParticipantsTableHeader_study
            ...ScheduleModal_study
            ...SlotPicker_study
            participantsDetail(
              first: $count
              after: $cursor
              filterComplex: $filterComplex
              excludeNonTest: $excludeNonTest
              includeTest: $includeTest
              tab: $tab
              searchTerm: $searchTerm
            ) @connection(key: "ParticipantsTable_participantsDetail", filters: ["filterComplex", "tab"]) {
              totalCount
              edges {
                node {
                  id
                  respondent {
                    id
                    dId
                    rhAnswer
                    status
                    approvalStatus
                    masterStatus
                    responses {
                      edges {
                        node {
                          element {
                            type
                          }
                          value
                        }
                      }
                    }
                    screenerResults
                    segmentResponses {
                      edges {
                        node {
                          segment {
                            text
                          }
                        }
                      }
                    }
                    scheduledSlots {
                      session {
                        name
                      }
                      date
                      start
                      end
                      interviewed
                    }
                    person {
                      firstName
                      lastName
                      email
                      phoneNumber
                      optIn
                      panelistDId
                      panelist {
                        dId
                        ...Note_panelist
                      }
                    }
                    recruit {
                      name
                      isTest
                      incentive
                      incentivePoints
                      incentivesClearDate
                    }
                    ...AlertVoxpopmeVideoAnalysis_respondent
                    ...Note_respondent
                    ...RespondentResponses_respondent
                    ...ScheduleModal_respondent
                  }
                  rsvp
                  noShow
                  rating
                  incentivized
                  incentivizedType
                  incentivizedAmount
                  incentivizedOn
                  incentivizedPointAmount
                  imported
                  bonusPoints {
                    orderDate
                    pointsAmount
                  }
                }
              }
            }
          }
        }
      }
    `,
  }
);
