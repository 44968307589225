import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useRouter } from "found";
import type { CSSProperties } from "react";
import styled from "styled-components";

import { ImgThemed } from "..";
import { SECONDARY_TEXT_COLOR } from "../../style";

export const NoProjects = ({ style }: { style?: CSSProperties }) => {
  const { router } = useRouter();

  return (
    <StyledNoProjects style={style}>
      <ImgThemed aria-hidden src="welcome.svg" />
      <div className="title">Welcome to HubUX!</div>
      <div className="subtitle">Research operations just got easier.</div>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => {
          router.push(`/projects/create`);
        }}
      >
        Create my first project
      </Button>
    </StyledNoProjects>
  );
};

const StyledNoProjects = styled.div`
  margin-left: 0 !important;
  padding: 0 24px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 24px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 16px;
    color: ${SECONDARY_TEXT_COLOR};
  }
`;
