import { ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Button, InputNumber, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { CountryType, COUNTRY_LOOKUP } from "../../constants";
import { BORDER_RADIUS } from "../../style";
import { mutation, trackEvent, useConfirm } from "../../utils";
import { currencyFormatter } from "../../utils/misc";
import type { RewardCard_Mutation } from "../../__generated__/RewardCard_Mutation.graphql";
import type { RewardCard_panelist$data } from "../../__generated__/RewardCard_panelist.graphql";

export type RewardCardType = {
  name: string;
  sku: string;
  programId: string;
  minAmount: number;
  maxAmount: number;
  faceplateUrl: string;
};

const RewardCard = ({ panelist, rewardCard }: { panelist: RewardCard_panelist$data; rewardCard: RewardCardType }) => {
  const { message, modal } = App.useApp();
  const [points, setPoints] = useState<number | undefined>();
  const { t } = useTranslation();
  const country = panelist.person?.country as CountryType;
  const locale = country ? COUNTRY_LOOKUP[country].LOCALE : "en-US";
  const currency = country ? COUNTRY_LOOKUP[country].CURRENCY : "USD";
  const CURRENCY_TO_POINTS = panelist.panelistCurrencyToPointsRatio || 0;
  const fxFormatter = (value: number) => currencyFormatter(locale, currency).format(value);

  async function handleRedeemFlow() {
    if (points === undefined) {
      throw new Error("Points cannot be undefined.");
    }

    await mutation<RewardCard_Mutation>({
      variables: {
        input: {
          rewardCardSku: rewardCard.sku,
          programId: rewardCard.programId,
          points,
        },
      },
      mutation: graphql`
        mutation RewardCard_Mutation($input: RedeemRewardsInput!) {
          redeemRewards(input: $input) {
            panelist {
              rewardsBalance
              convertedRewardsBalance
              redeemedRewardsPoints
            }
          }
        }
      `,
      silenceDefaultError: true,
    });

    // Clear the previous input
    setPoints(undefined);
  }

  const confirmRedeem = useConfirm({
    icon: <ExclamationCircleOutlined />,
    title: t("portal.reward-card.redeem.title", "Redeem {{rewardCardName}}", {
      rewardCardName: rewardCard.name,
    }) as string,
    cancelText: t("portal.reward-card.redeem.cancel-button-text", "Cancel") as string,
    okText: t("portal.reward-card.redeem.redeem-button-text", "Redeem") as string,
  });
  const showRedeemConfirmation = async () => {
    if (points === undefined) return;

    if (
      !(await confirmRedeem({
        content: (
          <>
            <p>
              {t("portal.reward-card.redeem.description", "Redeem {{pointsInUsd}} card for {{points}} points.", {
                pointsInUsd: fxFormatter(points * CURRENCY_TO_POINTS),
                points,
              })}
            </p>
            <p>
              {t(
                "portal.reward-card.redeem.balance-preview",
                "Your balance after this redemption: {{points}} points.",
                {
                  points: (panelist.rewardsBalance ?? 0) - points,
                }
              )}
            </p>
          </>
        ),

        onOk: async confirmInstance => {
          confirmInstance.update({ cancelButtonProps: { disabled: true } });

          try {
            await handleRedeemFlow();
          } catch (e) {
            console.error(e);
            message.error(t("portal.reward-card.redeem.redemption-error", "Could not redeem rewards."));

            trackEvent("Portal: Points Redeem Fail", {
              "Panelist dID": panelist.dId,
              "Card ID": rewardCard.sku,
              "Card Name": rewardCard.name,
              "Points Amount": points,
              "Currency Amount": fxFormatter(points * CURRENCY_TO_POINTS),
            });

            return false;
          }
        },
      }))
    )
      return;

    modal.success({
      title: t("portal.reward-card.redeem.redemption-success-modal.title", "Card successfully redeemed"),
      content: t(
        "portal.reward-card.redeem.redemption-success-modal.body",
        "Your card is on the way! Check your email."
      ),
    });

    try {
      trackEvent("Portal: Points Redeem Success", {
        "Panelist dID": panelist.dId,
        "Card ID": rewardCard.sku,
        "Card Name": rewardCard.name,
        "Points Amount": points,
        "Currency Amount": fxFormatter(points * CURRENCY_TO_POINTS),
      });
    } catch {
      // Ignore Mixpanel error
    }
  };

  const arePointsValid =
    points &&
    Number.isInteger(points) &&
    points <= (panelist.rewardsBalance ?? 0) &&
    points * CURRENCY_TO_POINTS >= (rewardCard.minAmount as Number) &&
    points * CURRENCY_TO_POINTS <= (rewardCard.maxAmount as Number);

  return (
    <Styled>
      <img alt={rewardCard.name} className="card-image" src={rewardCard.faceplateUrl} />
      <div className="info">{rewardCard.name}</div>
      <div className="points-input">
        <InputNumber
          type="number"
          className="fill-width"
          placeholder={t("portal.reward-card.redeem.points-input-placeholder", "Enter points")}
          min={0}
          max={Math.min(panelist.rewardsBalance ?? 0, Number(rewardCard.maxAmount) / CURRENCY_TO_POINTS)}
          precision={0}
          value={points}
          onChange={x => setPoints(x ?? undefined)}
        />
      </div>
      <div className="redeem-button">
        <Tooltip
          className="fill-width"
          // Only enable tooltip when points input is blank or invalid
          trigger={arePointsValid ? undefined : "hover"}
          placement={window.innerWidth < 600 ? "left" : "bottom"}
          title={t(
            "portal.reward-card.redeem.button-tooltip",
            "Minimum redemption is {{minimumRedemptionPoints}} points or {{minimumRedemptionUsd}}.",
            {
              minimumRedemptionPoints: Math.ceil(Number(rewardCard.minAmount) / CURRENCY_TO_POINTS),
              minimumRedemptionUsd: fxFormatter(rewardCard.minAmount),
            }
          )}
        >
          <Button type="primary" onClick={showRedeemConfirmation} disabled={!arePointsValid} style={{ width: "100%" }}>
            {t("portal.reward-card.redeem.button-text", "Redeem {{cardOrValue}}", {
              cardOrValue: arePointsValid ? fxFormatter(points * CURRENCY_TO_POINTS) : "Card",
            })}
          </Button>
        </Tooltip>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  margin: 24px 12px;
  display: grid;
  grid-template-areas:
    "image image"
    "info info"
    "points button";
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 264px;

  .card-image {
    grid-area: image;
    width: 100%;
    border-radius: ${BORDER_RADIUS};
  }

  @media only screen and (max-width: 600px) {
    grid-template-areas:
      "image info"
      "image points"
      "image button";
    align-items: center;
  }

  .info {
    grid-area: info;
    font-weight: 500;
  }

  .fill-width {
    width: 100%;
  }

  .points-input {
    grid-area: points;
  }

  .redeem-button {
    grid-area: button;
  }
`;

export default createFragmentContainer(RewardCard, {
  panelist: graphql`
    fragment RewardCard_panelist on PanelistNode {
      dId
      rewardsBalance
      panelistCurrencyToPointsRatio
      person {
        country
      }
    }
  `,
});
