/**
 * @generated SignedSource<<40aa28718d34a753a8efbd62fa139ccb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelistCharacteristics_tenant$data = {
  readonly panel: {
    readonly id: string;
    readonly panelistLayout: ReadonlyArray<string>;
  } | null;
  readonly " $fragmentType": "PanelistCharacteristics_tenant";
};
export type PanelistCharacteristics_tenant$key = {
  readonly " $data"?: PanelistCharacteristics_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistCharacteristics_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelistCharacteristics_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelNode",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panelistLayout",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "7cc0216ace28fdae7b4f523bd23d531d";

export default node;
