/**
 * @generated SignedSource<<2f1a5386a53be1fbb921a5978bd3fb68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReminderInput_template$data = {
  readonly daysBefore: number;
  readonly enabled: boolean;
  readonly hoursBefore: number;
  readonly id: string;
  readonly inPersonMeeting: {
    readonly message: string;
    readonly subject: string;
  } | null;
  readonly minsBefore: number;
  readonly virtualMeeting: {
    readonly message: string;
    readonly subject: string;
  } | null;
  readonly " $fragmentType": "ReminderInput_template";
};
export type ReminderInput_template$key = {
  readonly " $data"?: ReminderInput_template$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReminderInput_template">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "subject",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReminderInput_template",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReminderTemplateVersionsNode",
      "kind": "LinkedField",
      "name": "virtualMeeting",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReminderTemplateVersionsNode",
      "kind": "LinkedField",
      "name": "inPersonMeeting",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "daysBefore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursBefore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minsBefore",
      "storageKey": null
    }
  ],
  "type": "RemindersTemplatesNode",
  "abstractKey": null
};
})();

(node as any).hash = "f8b796575596fb0105ad08b542f3dd1c";

export default node;
