import { find, findIndex, lowerCase, map, remove, upperFirst } from "lodash-es";
import { DelimiterCase } from "type-fest";

export const flattenEdges = <T>(
  x: T
): T extends { readonly edges: readonly ({ readonly node?: infer U | null } | null)[] | null } ? U[] : never =>
  // @ts-expect-error `infer` can't go in type args, so `T` is loosely defined in the body
  x?.edges
    // @ts-expect-error Same
    .filter((x): x is NonNullable<typeof x> => !!x)
    // @ts-expect-error Same
    .map(x => x.node)
    // @ts-expect-error Same
    .filter((x): x is NonNullable<typeof x> => !!x) ?? [];

export const insertAnswerEdge = (arr: any, toInsert: any) => {
  arr = map(arr, o => o);

  const otherIndex = findIndex(arr, (o: any) => o.node.other);

  if (otherIndex) {
    arr.splice(otherIndex, 0, toInsert);
  } else {
    arr.push(toInsert);
  }

  return arr;
};

export const moveEdge = (arr: any, from: number, to: number) => {
  arr = map(arr, o => o);

  const positions = map(arr, o => o.node.position);
  const fromElement = find(arr, o => o.node.position === from);
  const toIndex = findIndex(arr, (o: any) => o.node.position === to);

  remove(arr, (o: any) => fromElement.node.id === o.node.id);
  arr.splice(toIndex, 0, fromElement);

  return map(arr, (o: any, i: any) => {
    return {
      node: {
        dbId: o.node.dbId,
        id: o.node.id,
        position: positions[i],
        text: o.node.text,
      },
    };
  });
};

export const sentenceCase = <T extends string>(x: T) => upperFirst(lowerCase(x)) as Capitalize<DelimiterCase<T, " ">>;

export const splitOnce = <A extends string = string, B extends string = string>(x: string, by: string) =>
  x.split(new RegExp(`${by}(.*)`, "s")) as [A, B | undefined];
