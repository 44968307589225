/**
 * @generated SignedSource<<3c510e99e731fdaddc82775372395434>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitInstructions_recruit$data = {
  readonly incentive: string | null;
  readonly incentivePoints: number | null;
  readonly incentiveType: ScreenersRecruitIncentiveTypeChoices | null;
  readonly overNeedsReviewLimit: boolean | null;
  readonly status: ScreenersRecruitStatusChoices;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly underGoal: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_recruit" | "ScreenerLink_recruit">;
  readonly " $fragmentType": "RecruitInstructions_recruit";
};
export type RecruitInstructions_recruit$key = {
  readonly " $data"?: RecruitInstructions_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitInstructions_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitInstructions_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "underGoal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overNeedsReviewLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommunicationComposePage_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScreenerLink_recruit"
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "85596ecdad992b6a369516cb2533168f";

export default node;
