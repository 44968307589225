import { Progress } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES } from "../../constants";
import { GRAY_5, TERMINANTS_COLOR, UPPER_HEADER_COLOR } from "../../style";
import { truncate } from "../../utils/misc";
import { TerminantsOverviewTable_element$data } from "../../__generated__/TerminantsOverviewTable_element.graphql";

type Props = {
  element: TerminantsOverviewTable_element$data;
  highestTermNumber: number;
};

/*
 * In the dashboard, the vertical table that shows an element's options
 * and their individual quota status.
 */
const TerminantsOverviewTable: React.FC<Props> = ({ element, highestTermNumber }) => {
  const findGridAnswer = (rowId: string, answerId: string) =>
    element.gridAnswers?.find(gridAnswer => gridAnswer?.rowId === rowId && gridAnswer?.answerId === answerId);

  if (!element.text) return null;

  return (
    <StyledTerminantsOverviewTable>
      <div className="element">
        {element.label && <span className="label">{element.label}.</span>}
        <span className="text" title={element?.text}>
          {truncate(element?.text, 85)}
        </span>
        <div className="options-container">
          {element.type !== ELEMENT_TYPES.GRID_SINGLE_SELECT
            ? element.options?.map((o, i) => {
                // Only show options with terms
                if (!o?.terminate || !o?.text) return null;

                const total = o?.total || 0;
                const percent = (total / highestTermNumber) * 100;

                return (
                  <div className="option" key={i}>
                    <Progress percent={percent} showInfo={false} strokeColor={TERMINANTS_COLOR} />
                    <span className="text">{truncate(o?.text, 31)}</span>
                    <span className="total">{o?.total}</span>
                  </div>
                );
              })
            : element.rows?.map((row, r) =>
                row!.options?.map((option, o) => {
                  // validate required properties
                  if (!row?.dbId || !option?.dbId) {
                    return null;
                  }

                  // filter out non-terms
                  const gridAnswer = findGridAnswer(row.dbId, option.dbId);
                  if (!option.terminate && !gridAnswer?.terminate) {
                    return null;
                  }

                  const total = option?.total || 0;
                  const percent = (total / highestTermNumber) * 100;

                  return (
                    <div className="option" key={`${r},${o}`}>
                      <Progress percent={percent} showInfo={false} strokeColor={TERMINANTS_COLOR} />
                      <span className="text">
                        {truncate(row.text!, 31)}, {truncate(option.text!, 31)}
                      </span>
                      <span className="total">{option?.total}</span>
                    </div>
                  );
                })
              )}
        </div>
      </div>
    </StyledTerminantsOverviewTable>
  );
};

const StyledTerminantsOverviewTable = styled.div`
  .element {
    > .label,
    > .text {
      color: ${UPPER_HEADER_COLOR};
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.4;
      display: inline-block;
    }

    background: white;
    padding: 12px 16px;
    border-radius: 7px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
    font-size: 14px;

    .label {
      color: ${GRAY_5};
      padding-right: 2px;
      font-weight: normal;
    }
  }

  .options-container {
    margin-top: 7px;

    .option {
      .total {
        float: right;
      }

      .ant-progress {
        float: right;
        width: 100px;
        margin-left: 8px;

        .ant-progress-inner {
          background: transparent;
        }
      }

      & + .option {
        margin-top: 2px;
      }
    }
  }

  & + div {
    margin-top: 20px;
  }
`;

export default createFragmentContainer(TerminantsOverviewTable, {
  element: graphql`
    fragment TerminantsOverviewTable_element on FieldReportElementNode {
      text
      type
      label
      labeledText
      rows {
        dbId
        text
        options {
          dbId
          text
          terminate
          total
        }
      }
      options {
        text
        qualified
        qualifiedTotal
        total
        quota
        terminate
      }
      gridAnswers {
        rowId
        answerId
        terminate
      }
    }
  `,
});
