/**
 * @generated SignedSource<<d1f482f9438318a6d34a8210b6263f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaveTemplateModal_tenant$data = {
  readonly emailTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly message: string;
        readonly name: string;
        readonly replyTo: string;
        readonly subject: string;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "SaveTemplateModal_tenant";
};
export type SaveTemplateModal_tenant$key = {
  readonly " $data"?: SaveTemplateModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaveTemplateModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaveTemplateModal_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailTemplateConnection",
      "kind": "LinkedField",
      "name": "emailTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailTemplateNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "replyTo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "34926e512c3634788c4bfe9bb53ac2a4";

export default node;
