import { Card } from "antd";
import type { ReactNode } from "react";
import styled from "styled-components";

const StyledCard = ({ children, title }: { children: ReactNode; title: ReactNode }) => (
  <Styled>
    <Card bordered title={title} className="card">
      {children}
    </Card>
  </Styled>
);

const Styled = styled.section`
  .card {
    width: 100%;
    height: 100%;

    .ant-card-head {
      padding-left: 15px;
    }

    .ant-card-body {
      padding: 0 0 1rem 0;
      height: 300px;
      overflow: auto;
    }
  }
`;

export default StyledCard;
