/**
 * @generated SignedSource<<fd97cc4581b0aca3d825e62ab672b025>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelistModal_tenant$data = {
  readonly customportal: {
    readonly hidePointsAndRedemption: boolean;
  } | null;
  readonly panel: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"GivePointsToPanelistModal_tenant" | "PanelistCharacteristics_tenant">;
  readonly " $fragmentType": "PanelistModal_tenant";
};
export type PanelistModal_tenant$key = {
  readonly " $data"?: PanelistModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelistModal_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomPortalNode",
      "kind": "LinkedField",
      "name": "customportal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidePointsAndRedemption",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelNode",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GivePointsToPanelistModal_tenant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PanelistCharacteristics_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "a4bde6aa458841891822b53fb91a391d";

export default node;
