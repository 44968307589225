import baselineCalendarToday from "@iconify-icons/ic/baseline-calendar-today";
import baselineEventBusy from "@iconify-icons/ic/baseline-event-busy";
import outlineDisabledByDefault from "@iconify-icons/ic/outline-disabled-by-default";
import outlineDone from "@iconify-icons/ic/outline-done";
import outlineError from "@iconify-icons/ic/outline-error";
import outlineFactCheck from "@iconify-icons/ic/outline-fact-check";
import outlineGrade from "@iconify-icons/ic/outline-grade";
import outlineHourglassEmpty from "@iconify-icons/ic/outline-hourglass-empty";
import outlineHowToReg from "@iconify-icons/ic/outline-how-to-reg";
import outlineMarkEmailRead from "@iconify-icons/ic/outline-mark-email-read";
import outlineMarkEmailUnread from "@iconify-icons/ic/outline-mark-email-unread";
import outlineNotInterested from "@iconify-icons/ic/outline-not-interested";
import outlinePayments from "@iconify-icons/ic/outline-payments";
import outlineSchedule from "@iconify-icons/ic/outline-schedule";
import outlineTextSnippet from "@iconify-icons/ic/outline-text-snippet";

export const APPROVAL_STATUS = {
  APPROVED: "A",
  DENIED: "D",
  NEEDS_REVIEW: "NR",
};

export const enum RESPONDENT_MASTER_STATUSES {
  QUEUED = "Q",
  INVITED = "I",
  EMAIL_OPENED = "E",
  STARTED_SCREENER = "SS",
  TERMED = "T",
  OVER_QUOTA = "OQ",
  NEEDS_REVIEW = "NR",
  DECLINED = "D",
  APPROVED = "A",
  SCHEDULED = "S",
  INTERVIEWED = "IW",
  COMPLETED_SURVEY = "CS",
  NO_SHOW = "NS",
  RATED = "RT",
  REWARDED = "RW",
  FUTURE = "%future added value",
  EMPTY = "",
}

// String statuses displayed in tables against the participant / panelist for each master status
export const RESPONDENT_MASTER_STATUSES_MAP = {
  [RESPONDENT_MASTER_STATUSES.QUEUED]: "Queued",
  [RESPONDENT_MASTER_STATUSES.INVITED]: "Invited",
  [RESPONDENT_MASTER_STATUSES.EMAIL_OPENED]: "Email Opened",
  [RESPONDENT_MASTER_STATUSES.STARTED_SCREENER]: "Started Screener",
  [RESPONDENT_MASTER_STATUSES.TERMED]: "Termed",
  [RESPONDENT_MASTER_STATUSES.OVER_QUOTA]: "Over Quota",
  [RESPONDENT_MASTER_STATUSES.NEEDS_REVIEW]: "Needs Review",
  [RESPONDENT_MASTER_STATUSES.DECLINED]: "Declined",
  [RESPONDENT_MASTER_STATUSES.APPROVED]: "Approved",
  [RESPONDENT_MASTER_STATUSES.SCHEDULED]: "Scheduled",
  [RESPONDENT_MASTER_STATUSES.INTERVIEWED]: "Interviewed",
  [RESPONDENT_MASTER_STATUSES.COMPLETED_SURVEY]: "Completed Survey",
  [RESPONDENT_MASTER_STATUSES.NO_SHOW]: "No Show",
  [RESPONDENT_MASTER_STATUSES.REWARDED]: "Incentivized",
  [RESPONDENT_MASTER_STATUSES.RATED]: "Incentivized",
  [RESPONDENT_MASTER_STATUSES.FUTURE]: "Error",
  [RESPONDENT_MASTER_STATUSES.EMPTY]: "Error",
};

export const RESPONDENT_ICONS_MAP = {
  [RESPONDENT_MASTER_STATUSES.QUEUED]: outlineSchedule,
  [RESPONDENT_MASTER_STATUSES.INVITED]: outlineMarkEmailUnread,
  [RESPONDENT_MASTER_STATUSES.EMAIL_OPENED]: outlineMarkEmailRead,
  [RESPONDENT_MASTER_STATUSES.STARTED_SCREENER]: outlineTextSnippet,
  [RESPONDENT_MASTER_STATUSES.TERMED]: outlineNotInterested,
  [RESPONDENT_MASTER_STATUSES.OVER_QUOTA]: outlineHourglassEmpty,
  [RESPONDENT_MASTER_STATUSES.NEEDS_REVIEW]: outlineFactCheck,
  [RESPONDENT_MASTER_STATUSES.DECLINED]: outlineNotInterested,
  [RESPONDENT_MASTER_STATUSES.TERMED]: outlineDisabledByDefault,
  [RESPONDENT_MASTER_STATUSES.APPROVED]: outlineDone,
  [RESPONDENT_MASTER_STATUSES.SCHEDULED]: baselineCalendarToday,
  [RESPONDENT_MASTER_STATUSES.INTERVIEWED]: outlineHowToReg,
  [RESPONDENT_MASTER_STATUSES.COMPLETED_SURVEY]: outlineGrade,
  [RESPONDENT_MASTER_STATUSES.NO_SHOW]: baselineEventBusy,
  [RESPONDENT_MASTER_STATUSES.RATED]: outlinePayments,
  [RESPONDENT_MASTER_STATUSES.REWARDED]: outlinePayments,
  [RESPONDENT_MASTER_STATUSES.FUTURE]: outlineError,
  [RESPONDENT_MASTER_STATUSES.EMPTY]: outlineError,
};

export const ALL_COMPLETED_STATUSES = [
  RESPONDENT_MASTER_STATUSES.TERMED,
  RESPONDENT_MASTER_STATUSES.OVER_QUOTA,
  RESPONDENT_MASTER_STATUSES.DECLINED,
  RESPONDENT_MASTER_STATUSES.COMPLETED_SURVEY,
  RESPONDENT_MASTER_STATUSES.INTERVIEWED,
  RESPONDENT_MASTER_STATUSES.NO_SHOW,
  RESPONDENT_MASTER_STATUSES.RATED,
  RESPONDENT_MASTER_STATUSES.REWARDED,
];

export const SUCCESS_COMPLETED_STATUSES = [
  RESPONDENT_MASTER_STATUSES.COMPLETED_SURVEY,
  RESPONDENT_MASTER_STATUSES.INTERVIEWED,
  RESPONDENT_MASTER_STATUSES.RATED,
  RESPONDENT_MASTER_STATUSES.REWARDED,
];
