/**
 * @generated SignedSource<<e663613871957ec5fc9147189b77f62f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitDetails_recruit$data = {
  readonly dbId: string;
  readonly goal: number | null;
  readonly id: string;
  readonly incentive: string | null;
  readonly incentivePoints: number | null;
  readonly name: string | null;
  readonly recruitTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly status: ScreenersRecruitStatusChoices;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitForm_recruit" | "RecruitInstructions_recruit" | "RecruitKPIs_recruit" | "RecruitNameModal_recruit" | "RecruitPaymentModal_recruit" | "RecruitTitle_recruit" | "RecruitTypePicker_recruit" | "RecruitYourPanel_recruit">;
  readonly " $fragmentType": "RecruitDetails_recruit";
};
export type RecruitDetails_recruit$key = {
  readonly " $data"?: RecruitDetails_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitDetails_recruit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitDetails_recruit",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "recruitTransactions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecruitTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitInstructions_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitKPIs_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitNameModal_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitTitle_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitTypePicker_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitYourPanel_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitForm_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitPaymentModal_recruit"
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};
})();

(node as any).hash = "613b9cdc066783ca8bfcb3710b89c360";

export default node;
