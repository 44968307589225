import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Alert, Button, Collapse, Empty, Radio, Tooltip, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { PROJECT_INCENTIVE_TYPES, PROJECT_TYPES, SAVING_STATES } from "../../constants";
import { ScreenersReminderTemplateVersionMeetingTypeChoices } from "../../schema";
import { BORDER_RADIUS, FADED_TEXT_COLOR, PAGE_WIDTH_XL, PRIMARY_TEXT_COLOR } from "../../style";
import { mutation } from "../../utils";
import type { CommunicationRemindersPageMutation } from "../../__generated__/CommunicationRemindersPageMutation.graphql";
import type { CommunicationRemindersPage_CreateRemindersTemplate_Mutation } from "../../__generated__/CommunicationRemindersPage_CreateRemindersTemplate_Mutation.graphql";
import type { CommunicationRemindersPage_DeleteRemindersTemplate_Mutation } from "../../__generated__/CommunicationRemindersPage_DeleteRemindersTemplate_Mutation.graphql";
import type { CommunicationRemindersPage_study$data } from "../../__generated__/CommunicationRemindersPage_study.graphql";
import ProjectPageContent from "../ProjectPageContent";
import Saving, { SavingStateType } from "../Saving";

import InviteInput from "./InviteInput";
import ReminderInput from "./ReminderInput";

const env = runtimeEnv();

const { Panel } = Collapse;
const { Link: A, Text, Title } = Typography;

const CommunicationRemindersPage = ({ study }: { study: CommunicationRemindersPage_study$data }) => {
  const [meetingType, setMeetingType] = useState<ScreenersReminderTemplateVersionMeetingTypeChoices>(
    ScreenersReminderTemplateVersionMeetingTypeChoices.V
  );
  const [savingState, setSavingState] = useState<SavingStateType>("saved");
  const message = "Variables";

  const description = (
    <div>
      <p>You can include data about each recipient and the event using these variables:</p>
      {[PROJECT_TYPES.FOCUS_GROUP, PROJECT_TYPES.INTERVIEW].includes(study.type) && (
        <>
          <p>In the invite:</p>
          <ul>
            <li>%event.duration%</li>
            {meetingType === ScreenersReminderTemplateVersionMeetingTypeChoices.V ? (
              <li>%event.link%</li>
            ) : (
              <li>%event.location%</li>
            )}
            <li>%study.name%</li>
          </ul>
          <p>In the reminders:</p>
        </>
      )}
      <ul>
        <li>%participant.id%</li>
        <li>%participant.first_name%</li>
        <li>%participant.last_name%</li>
        <li>%participant.email%</li>
        <li>%event.date%</li>
        <li>%event.time%</li>
        <li>%event.duration%</li>
        <li>%event.hours_until_event%</li>
        {meetingType === ScreenersReminderTemplateVersionMeetingTypeChoices.V ? (
          <li>%event.link%</li>
        ) : (
          <li>%event.location%</li>
        )}
        <li>
          {study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH ? "%event.incentive%" : "%event.incentive_points%"}
        </li>
        <li>%study.name%</li>
      </ul>
    </div>
  );

  const enableButtonClick = async (event: any, templateId: string, enabled: boolean) => {
    event.stopPropagation();
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<CommunicationRemindersPageMutation>({
        variables: {
          input: {
            templateId,
            enabled,
          },
        },
        mutation: graphql`
          mutation CommunicationRemindersPageMutation($input: UpdateRemindersTemplateInput!) {
            updateRemindersTemplate(input: $input) {
              remindersTemplate {
                id
                enabled
              }
            }
          }
        `,
        optimisticResponse: {
          updateRemindersTemplate: {
            remindersTemplate: {
              id: templateId,
              enabled: enabled,
            },
          },
        },
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const addReminder = async (studyId: string) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<CommunicationRemindersPage_CreateRemindersTemplate_Mutation>({
        variables: {
          input: {
            studyId: studyId,
            daysBefore: 7,
          },
        },
        mutation: graphql`
          mutation CommunicationRemindersPage_CreateRemindersTemplate_Mutation($input: CreateRemindersTemplateInput!) {
            createRemindersTemplate(input: $input) {
              study {
                reminderTemplates {
                  edges {
                    node {
                      id
                      daysBefore
                      hoursBefore
                      minsBefore
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const deleteReminder = async (event: any, templateId: string) => {
    event.stopPropagation();
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<CommunicationRemindersPage_DeleteRemindersTemplate_Mutation>({
        variables: {
          input: {
            templateId: templateId,
          },
        },
        mutation: graphql`
          mutation CommunicationRemindersPage_DeleteRemindersTemplate_Mutation($input: DeleteRemindersTemplateInput!) {
            deleteRemindersTemplate(input: $input) {
              study {
                reminderTemplates {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const ReminderHeader = (template: {
    daysBefore: number;
    hoursBefore: number;
    minsBefore: number;
    id: string;
    enabled: boolean;
  }) => {
    const { daysBefore, hoursBefore, minsBefore, id: templateId, enabled } = template;

    let timeString = daysBefore ? (daysBefore === 1 ? `${daysBefore} day ` : `${daysBefore} days `) : "";
    timeString += hoursBefore ? (hoursBefore === 1 ? `${hoursBefore} hour ` : `${hoursBefore} hours `) : "";
    timeString += minsBefore ? (minsBefore === 1 ? `${minsBefore} min ` : `${minsBefore} mins `) : "";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={enabled ? {} : { color: FADED_TEXT_COLOR }}>
          <p style={{ margin: 0 }}>
            <span style={{ fontWeight: "bold" }}>{timeString}</span>
            before event{" "}
          </p>
        </span>
        <div>
          <Tooltip title="Delete reminder">
            <DeleteOutlined
              style={{ marginRight: "0.7rem", padding: "0.3rem" }}
              onClick={e => deleteReminder(e, templateId)}
            />
          </Tooltip>
          <Button
            onClick={e => enableButtonClick(e, templateId, !enabled)}
            type={enabled ? "default" : "primary"}
            size="small"
          >
            {enabled ? "Disable" : "Enable"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ProjectPageContent
      extra={<Saving savingState={savingState} style={{ position: "initial" }} type="text" />}
      style={{ padding: 0, maxHeight: "100%", overflowY: "auto" }}
      title={
        <>
          <Title level={4}>Reminders</Title>
          <Text type="secondary">
            Customize calendar invites and send automated reminders to participants before events. Emails are sent from{" "}
            {!!study.tenant.emailDomain ? (
              <code>
                {env.REACT_APP_HUBUX_EMAIL_LOCAL_TENANT ?? "support"}@{study.tenant.emailDomain}
              </code>
            ) : (
              <>
                <code>
                  {env.REACT_APP_HUBUX_EMAIL_LOCAL_DEFAULT ?? "hello"}@{env.REACT_APP_HUBUX_EMAIL_DOMAIN ?? "hubux.net"}
                </code>
                .{" "}
                <A
                  role="button"
                  onClick={() =>
                    window.Intercom?.(
                      "showNewMessage",
                      "Hi there! I'd like to learn more about using a custom email domain."
                    )
                  }
                >
                  Interested in a custom email domain?
                </A>
              </>
            )}
          </Text>
          {study.tenant.voxpopmeGlobal && (
            <>
              <br />
              <Text type="secondary">
                Communication customization is not available via the Voxpopme Global Panel at this time. For information
                on the default messaging for the Voxpopme Global Panel, contact{" "}
                <a href="mailto:support@voxpopme.com">support@voxpopme.com</a>
              </Text>
            </>
          )}
        </>
      }
    >
      <Styled>
        <>
          <div className="main">
            <div className="reminder-wrapper">
              {[PROJECT_TYPES.FOCUS_GROUP, PROJECT_TYPES.INTERVIEW].includes(study.type) && (
                <div className="meeting-type-wrapper">
                  <Radio.Group
                    options={[
                      { label: "Virtual meeting", value: ScreenersReminderTemplateVersionMeetingTypeChoices.V },
                      {
                        label: "In-person meeting",
                        value: ScreenersReminderTemplateVersionMeetingTypeChoices.P,
                      },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                    defaultValue={meetingType}
                    onChange={e => setMeetingType(e.target.value)}
                  />
                </div>
              )}
              <Collapse defaultActiveKey="cal-invite" className="collapse">
                {
                  <Panel
                    header={
                      <div style={{ fontWeight: 500 }}>
                        Calendar Invite
                        <Tooltip
                          title={
                            <>
                              Invites send from{" "}
                              <code>
                                {study.tenant.googleCalendarEmail ??
                                  `${env.REACT_APP_HUBUX_EMAIL_LOCAL_DEFAULT ?? "invites-dev"}@${
                                    env.REACT_APP_HUBUX_EMAIL_DOMAIN ?? "hubux.net"
                                  }`}
                              </code>{" "}
                              and include <strong>both the moderator and the participants</strong>
                            </>
                          }
                        >
                          <Icon
                            icon="mdi:help-circle-outline"
                            height="1.2em"
                            style={{ verticalAlign: "sub", marginLeft: 4 }}
                          />
                        </Tooltip>
                      </div>
                    }
                    key="cal-invite"
                  >
                    <InviteInput study={study} version={meetingType} setSavingState={setSavingState} />
                  </Panel>
                }
                {study.reminderTemplates.edges.length > 0 &&
                  study.reminderTemplates.edges.map(
                    (template, index) =>
                      template?.node && (
                        <Panel header={ReminderHeader(template.node)} key={template.node.id}>
                          <ReminderInput
                            version={meetingType}
                            template={template.node}
                            setSavingState={setSavingState}
                          />
                        </Panel>
                      )
                  )}
              </Collapse>

              {study.reminderTemplates.edges.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There are no reminders" />
              )}
              <div className="add-new-reminder-wrapper">
                <Tooltip title="Add new reminder">
                  <Button shape="circle" onClick={() => addReminder(study.id)} icon={<PlusOutlined />} />
                </Tooltip>
              </div>
            </div>
            <div className="alert-wrapper">
              <Alert message={message} description={description} />
            </div>
          </div>
        </>
      </Styled>
    </ProjectPageContent>
  );
};

const Styled = styled.div`
  padding: 24px 24px 48px;
  max-width: ${PAGE_WIDTH_XL};

  .main {
    display: flex;

    .reminder-wrapper {
      flex-grow: 1;
      margin-right: 1.5rem;

      .meeting-type-wrapper {
        padding-bottom: 36px;
      }

      .collapse {
        border-radius: ${BORDER_RADIUS};
      }

      .ant-collapse-header {
        color: ${PRIMARY_TEXT_COLOR};
        display: flex;
        align-items: center;
      }
    }

    .alert-wrapper {
      flex-basis: 50px;

      .ant-alert-message {
        color: ${PRIMARY_TEXT_COLOR};
      }

      ul {
        padding-left: 22px;
      }
    }
  }

  .add-new-reminder-wrapper {
    text-align: center;
    margin: 15px 0 15px;
  }
`;

export default createFragmentContainer(CommunicationRemindersPage, {
  study: graphql`
    fragment CommunicationRemindersPage_study on StudyNode {
      id
      tenant {
        emailDomain
        googleCalendarEmail
        voxpopmeGlobal
      }
      type
      incentiveType
      ...InviteInput_study
      reminderTemplates {
        edges {
          node {
            id
            enabled
            daysBefore
            hoursBefore
            minsBefore
            ...ReminderInput_template
          }
        }
      }
    }
  `,
});
