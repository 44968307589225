/**
 * @generated SignedSource<<1a8e5262860f439f67ef0eea7b9aa013>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersTagColorChoices = "BL" | "CN" | "GN" | "GY" | "OG" | "PL" | "RD" | "YW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InputFilterStudyFilter_tenant$data = {
  readonly studiesLaunched: ReadonlyArray<{
    readonly dId: any | null;
    readonly name: string | null;
  }>;
  readonly tags: ReadonlyArray<{
    readonly color: ScreenersTagColorChoices;
    readonly dId: any | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "InputFilterStudyFilter_tenant";
};
export type InputFilterStudyFilter_tenant$key = {
  readonly " $data"?: InputFilterStudyFilter_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterStudyFilter_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilterStudyFilter_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagNode",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "studiesLaunched",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};
})();

(node as any).hash = "d05696dec91c00192f9db2dc1cb87999";

export default node;
