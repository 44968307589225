/**
 * @generated SignedSource<<aade4ff8ca740252a99834710d705363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyScheduledByChoices = "P" | "T" | "%future added value";
export type ScreenersStudySchedulingTypeChoices = "CL" | "HX" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Cells_NextStepCell_study$data = {
  readonly allowAutoApproval: boolean;
  readonly scheduled: any | null;
  readonly scheduledBy: ScreenersStudyScheduledByChoices;
  readonly schedulingType: ScreenersStudySchedulingTypeChoices;
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"Cells_ApproveRejectedCell_study" | "Cells_PaymentCell_study" | "ScheduleCell_study" | "ScheduledSlotCell_study">;
  readonly " $fragmentType": "Cells_NextStepCell_study";
};
export type Cells_NextStepCell_study$key = {
  readonly " $data"?: Cells_NextStepCell_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"Cells_NextStepCell_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Cells_NextStepCell_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAutoApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedulingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledBy",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Cells_ApproveRejectedCell_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Cells_PaymentCell_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduleCell_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduledSlotCell_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "fd5b1bc7ab3cc7c9f476272e60c108be";

export default node;
