/**
 * @generated SignedSource<<287cbfbc8deb5f0fda874db92a12f371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuditionTextElement_element$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
  readonly " $fragmentType": "AuditionTextElement_element";
};
export type AuditionTextElement_element$key = {
  readonly " $data"?: AuditionTextElement_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuditionTextElement_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuditionTextElement_element",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "99b5e02b41680385a86fca09fde3fd55";

export default node;
