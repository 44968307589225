import { Input, Select } from "antd";

import { ButtonNot } from "./ButtonNot";
import { FilterOp, type PanelistFilter, type ParticipantFilter } from "./types";

export const InputFilterText = <T extends ParticipantFilter | PanelistFilter>({
  filter,
  setFilter,
}: {
  filter: T;
  setFilter: (x: T) => void;
}) => (
  <div className="form-filter-col">
    <div className="form-filter-row">
      <ButtonNot filter={filter} setFilter={setFilter} />
      <Select
        className="select-operator"
        defaultValue={FilterOp.Is}
        value={filter.op}
        onChange={op => setFilter({ ...filter, op })}
      >
        <Select.Option key="text_is" value={FilterOp.Is}>
          Is
        </Select.Option>
        <Select.Option key="text_contains" value={FilterOp.Contains}>
          Contains
        </Select.Option>
      </Select>
    </div>
    <Input
      type="text"
      className="input-values"
      placeholder="Text — Use comma for multiple values, e.g. a, b, c"
      value={"values" in filter ? filter.values?.join?.(",") : ""}
      onChange={(e: any) => setFilter({ ...filter, values: e.target.value.split(",") })}
    />
  </div>
);
