/**
 * @generated SignedSource<<ec3bb2c546506bd4fce8d9d49d629a41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommunicationComposePage_recruit$data = {
  readonly dbId: string;
  readonly id: string;
  readonly meta: any | null;
  readonly name: string | null;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentType": "CommunicationComposePage_recruit";
};
export type CommunicationComposePage_recruit$key = {
  readonly " $data"?: CommunicationComposePage_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationComposePage_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbId",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "a20657e2fcac9f79d7b9146fcc1a51b9";

export default node;
