import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import outlineAdd from "@iconify-icons/ic/outline-add";
import { Icon } from "@iconify/react";
import { useUpdateEffect } from "@react-hookz/web";
import { Button, Collapse, Form, FormInstance, FormListFieldData, Input, InputNumber, Switch, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames from "classnames";
import { useRouter } from "found";
import { omit, range } from "lodash-es";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";

import { EventsType, PROJECT_STATUSES, PROJECT_TYPES } from "../../constants";
import { INPUT_WIDTH_NUMBER } from "../../style";
import { mutation } from "../../utils";
import { exceptIndex, ignoreWheel } from "../../utils/misc";
import { useConfirm, useConfirmQuitEditing } from "../../utils/modal";
import { UpdateStudySessionsInput } from "../../__generated__/InputSlotDetails_UpdateSlot_Mutation.graphql";
import { SessionsForm_FocusGroupSlotEditor_slot$key } from "../../__generated__/SessionsForm_FocusGroupSlotEditor_slot.graphql";
import { SessionsForm_InterviewSlotEditor_slot$key } from "../../__generated__/SessionsForm_InterviewSlotEditor_slot.graphql";
import { SessionsForm_study$data, SessionsForm_study$key } from "../../__generated__/SessionsForm_study.graphql";
import { SessionsForm_updateStudySessions_Mutation } from "../../__generated__/SessionsForm_updateStudySessions_Mutation.graphql";
import { DetailsInput } from "../Configure/DetailsInput";
import { InputTitle } from "../Configure/InputTitle";
import { IconButton } from "../IconButton";
import { InputDate, InputDateRange } from "../Input";
import { SavingStateType } from "../Saving";

import { InputSlotDetails } from "./InputSlotDetails";

const { Panel } = Collapse;

type SessionsFormValues = {
  studyId: string;
  eventsType: EventsType;
  sessions: Session[];
};

type Session = {
  id?: string;
  name: string;
  duration?: number;
  availabilitySlots: AvailabilitySlot[];
};

type AvailabilitySlot = {
  id?: string;
  start?: any | null;
  range?: [any, any] | null;
  duration?: number | null;
  placesLimit?: number | null;
  interviewer?: string;
};

type FieldName = (string | number)[];

type FieldChangeHandler = (action?: "save" | "form-refresh") => () => void;

const createSession = (index: number): Session => ({ name: `Session ${index + 1}`, availabilitySlots: [{}] });

const SlotsEditor = ({
  sessionId,
  form,
  study,
  eventsType,
  setEventsType,
  sessionField,
  fieldName,
  handleFieldChange,
  addSession,
}: {
  sessionId?: string;
  form: FormInstance;
  study: SessionsForm_study$data;
  eventsType: EventsType;
  setEventsType: (type: EventsType) => void;
  sessionField: FormListFieldData;
  fieldName: FieldName;
  handleFieldChange: FieldChangeHandler;
  addSession: () => void;
}) => {
  const focusGroupIsActiveAndHasApprovedCount =
    study.status === PROJECT_STATUSES.LIVE && study.type === PROJECT_TYPES.FOCUS_GROUP && !!study.approvedCount;

  const moderatorEmailLabel = (
    <>
      Moderator email
      <Tooltip
        title={
          <>
            Adding a moderator reserves this time slot on their calendar, and{" "}
            <strong>includes the moderator's email in calendar invites sent to participants</strong>
          </>
        }
      >
        <Icon icon="mdi:help-circle-outline" height="1.2em" style={{ verticalAlign: "sub", marginLeft: 4 }} />
      </Tooltip>
    </>
  );

  return (
    <>
      {study.type === PROJECT_TYPES.FOCUS_GROUP && (
        <DetailsInput
          title="Duration"
          description={
            focusGroupIsActiveAndHasApprovedCount
              ? "Not editable after respondents have been approved"
              : "Interview duration in minutes"
          }
          inputs={
            <Form.Item
              name={[sessionField.name, "duration"]}
              style={{ marginBottom: 4 }}
              rules={[{ required: true, message: "Please provide a duration" }]}
            >
              <InputNumber
                size="large"
                type="number"
                style={{ width: INPUT_WIDTH_NUMBER }}
                min={1}
                onWheel={ignoreWheel}
                onBlur={handleFieldChange()}
                disabled={focusGroupIsActiveAndHasApprovedCount}
              />
            </Form.Item>
          }
        />
      )}
      <Form.List name={[sessionField.name, "availabilitySlots"]}>
        {(slotsFields, { add: addSlot, remove: removeSlot }) => {
          const baseFieldName = [...fieldName, sessionField.name, "availabilitySlots"];
          const inPerson = !!form.getFieldValue([...baseFieldName, 0, "inPerson"]);
          return (
            <>
              <div
                className={classNames("slots-grid", {
                  interview: study.type === PROJECT_TYPES.INTERVIEW,
                  "focus-group": study.type === PROJECT_TYPES.FOCUS_GROUP,
                })}
              >
                {study.type === PROJECT_TYPES.INTERVIEW ? (
                  /* interview column headers */
                  <>
                    <InputTitle text="Date" />
                    <InputTitle text="Hour range" />
                    <InputTitle text="Break in-between" />
                    <InputTitle text="Interview duration" />
                    <InputTitle text={moderatorEmailLabel} optional />
                    {!study.voxpopmeRecorderEnabled && (
                      <InputTitle text="In-person" style={{ textAlign: "center", whiteSpace: "nowrap" }} />
                    )}

                    <InputTitle text={inPerson ? "Meeting location" : "Meeting link"} />
                    <div>{/* empty header: details */}</div>
                    <div>{/* empty header: delete */}</div>
                    {study.voxpopmeRecorderEnabled && <div>{/* empty header: delete */}</div>}
                  </>
                ) : study.type === PROJECT_TYPES.FOCUS_GROUP ? (
                  /* focus group column headers */
                  <>
                    <InputTitle text="Event times" />
                    <InputTitle text="In-person" style={{ textAlign: "center", whiteSpace: "nowrap" }} />
                    <InputTitle text={inPerson ? "Meeting location" : "Meeting link"} />
                    <div>{/* empty header: details */}</div>
                    <InputTitle text="Max participants" optional style={{ whiteSpace: "nowrap" }} />
                    <InputTitle text={moderatorEmailLabel} />
                    <div>{/* empty header: delete */}</div>
                  </>
                ) : null}

                {slotsFields.map((slotField, key) => {
                  const dbSlot =
                    study.sessions?.edges
                      ?.find(e => e?.node?.id === sessionId)
                      ?.node?.availabilitySlots.edges.find(
                        e => e?.node?.id === form.getFieldValue([...baseFieldName, slotField.name, "id"])
                      )?.node ?? null;

                  return study.type === PROJECT_TYPES.INTERVIEW ? (
                    <InterviewSlotEditor
                      {...slotField}
                      {...{
                        form,
                        handleFieldChange,
                        fieldName: [...baseFieldName, key],
                        slotField,
                        dbSlot,
                        removeSlot,
                        voxpopmeRecorderEnabled: study.voxpopmeRecorderEnabled,
                      }}
                    />
                  ) : study.type === PROJECT_TYPES.FOCUS_GROUP ? (
                    <FocusGroupSlotEditor
                      {...slotField}
                      {...{
                        form,
                        handleFieldChange,
                        fieldName: [...baseFieldName, key],
                        slotField,
                        dbSlot,
                        removeSlot,
                        voxpopmeRecorderEnabled: study.voxpopmeRecorderEnabled,
                      }}
                    />
                  ) : null;
                })}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  style={{ padding: "0 4px 0 0" }}
                  type="link"
                  icon={outlineAdd}
                  onClick={() => {
                    addSlot();
                    handleFieldChange("form-refresh")();
                  }}
                >
                  Add {study.type === PROJECT_TYPES.INTERVIEW ? "date" : "event"}
                </IconButton>
                {eventsType !== "SESSIONS" ? (
                  <>
                    {" "}
                    or{" "}
                    <Tooltip title="Interview the same participant over multiple dates">
                      <Button
                        style={{ padding: "0 0 0 4px" }}
                        disabled={!study.canHaveMultipleSessions}
                        type="link"
                        onClick={() => addSession()}
                      >
                        Add session
                      </Button>
                    </Tooltip>
                  </>
                ) : eventsType === "SESSIONS" && form.getFieldsValue().sessions?.length === 1 ? (
                  <Tooltip title="Switch back to single events for participants">
                    <Button type="link" onClick={() => setEventsType("SINGLE")}>
                      Revert to dates
                    </Button>
                  </Tooltip>
                ) : null}
              </div>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

const InterviewSlotEditor = ({
  form,
  handleFieldChange,
  fieldName,
  slotField,
  dbSlot: _dbSlot,
  removeSlot,
  voxpopmeRecorderEnabled,
}: {
  form: FormInstance;
  handleFieldChange: FieldChangeHandler;
  fieldName: FieldName;
  slotField: FormListFieldData;
  dbSlot: SessionsForm_InterviewSlotEditor_slot$key | null;
  removeSlot: (index: number) => void;
  voxpopmeRecorderEnabled: boolean;
}) => {
  const confirm = useConfirm();

  const inPerson = !!form.getFieldValue([...fieldName, "inPerson"]);
  const dbSlot = useFragment(
    graphql`
      fragment SessionsForm_InterviewSlotEditor_slot on StudyAvailabilitySlotNode {
        countOfBookedSlots
        ...InputSlotDetails_slot
      }
    `,
    _dbSlot
  );

  return (
    <>
      <Form.Item name={[slotField.name, "id"]} hidden className="hidden"></Form.Item>
      <Form.Item name={[slotField.name, "start"]} rules={[{ required: true, message: "Required" }]}>
        <InputDate
          size="large"
          className="expand"
          placeholder="Select day"
          onChange={handleFieldChange()}
          disabledDate={date => date < moment().startOf("day")}
          disabled={!!dbSlot?.countOfBookedSlots}
        />
      </Form.Item>
      <Form.Item name={[slotField.name, "range"]} rules={[{ required: true, message: "Required" }]}>
        <InputDateRange
          size="large"
          className="expand"
          placeholder={["Start time", "End time"]}
          picker="time"
          format="h:mm A"
          minuteStep={5}
          onChange={handleFieldChange()}
          disabled={!!dbSlot?.countOfBookedSlots}
        />
      </Form.Item>
      <Form.Item name={[slotField.name, "availabilityBuffer"]}>
        <InputNumber
          size="large"
          className="expand"
          placeholder="Break in-between"
          min={0}
          onBlur={handleFieldChange()}
          disabled={!!dbSlot?.countOfBookedSlots}
        />
      </Form.Item>
      <Form.Item name={[slotField.name, "duration"]} rules={[{ required: true, message: "Required" }]}>
        <InputNumber
          size="large"
          className="expand"
          placeholder="Duration"
          min={1}
          onBlur={handleFieldChange()}
          disabled={!!dbSlot?.countOfBookedSlots}
        />
      </Form.Item>
      <Form.Item name={[slotField.name, "interviewer"]} rules={[{ type: "email", message: "Invalid email" }]}>
        <Input size="large" className="expand" placeholder="Moderator email" onBlur={handleFieldChange()} />
      </Form.Item>
      {!voxpopmeRecorderEnabled && (
        <Form.Item name={[slotField.name, "inPerson"]} valuePropName="checked" style={{ textAlign: "left" }}>
          <Switch onChange={handleFieldChange("form-refresh")} />
        </Form.Item>
      )}
      <Form.Item
        name={[slotField.name, "meetingLink"]}
        className={classNames({ hidden: inPerson })}
        rules={[
          { required: !inPerson, message: "Required" },
          { type: "url", message: "Please enter a URL starting with https://" },
        ]}
      >
        <Input size="large" className="expand" placeholder="Meeting link" onBlur={handleFieldChange()} />
      </Form.Item>

      <Form.Item
        name={[slotField.name, "meetingLocation"]}
        className={classNames({ hidden: !inPerson })}
        rules={[{ required: inPerson, message: "Required" }]}
      >
        <Input size="large" className="expand" placeholder="Meeting location" onBlur={handleFieldChange()} />
      </Form.Item>
      {voxpopmeRecorderEnabled && <div>{/* empty div: in person */}</div>}
      <Form.Item name={[slotField.name, "meetingDetails"]} hidden className="hidden"></Form.Item>
      <InputSlotDetails slot={dbSlot} style={{ width: "auto" }} />
      <Button
        style={{ padding: 4, marginTop: 4 }}
        type="text"
        onClick={async () => {
          if (
            !dbSlot?.countOfBookedSlots ||
            (await confirm({
              title: "Delete event?",
              content:
                "Are you sure you want to delete this event? Participants will be notified that this event is cancelled.",
              okText: "Delete and notify participants",
            }))
          ) {
            removeSlot(slotField.name);
            form.submit();
          }
        }}
      >
        <Icon icon="mdi:delete-outline" height="1.5em" />
      </Button>
    </>
  );
};

const FocusGroupSlotEditor = ({
  form,
  handleFieldChange,
  fieldName,
  slotField,
  dbSlot: _dbSlot,
  removeSlot,
  voxpopmeRecorderEnabled,
}: {
  form: FormInstance;
  handleFieldChange: FieldChangeHandler;
  fieldName: FieldName;
  slotField: FormListFieldData;
  dbSlot: SessionsForm_FocusGroupSlotEditor_slot$key | null;
  removeSlot: (index: number) => void;
  voxpopmeRecorderEnabled: boolean;
}) => {
  const inPerson = !!form.getFieldValue([...fieldName, "inPerson"]);
  const dbSlot = useFragment(
    graphql`
      fragment SessionsForm_FocusGroupSlotEditor_slot on StudyAvailabilitySlotNode {
        countOfBookedSlots
        ...InputSlotDetails_slot
      }
    `,
    _dbSlot
  );
  return (
    <>
      <Form.Item name={[slotField.name, "id"]} hidden className="hidden"></Form.Item>
      <Form.Item name={[slotField.name, "start"]} rules={[{ required: true, message: "Required" }]}>
        <InputDate
          size="large"
          placeholder="Select date"
          showTime
          format="M/D/YY h:mm A"
          minuteStep={15}
          onBlur={handleFieldChange()}
          disabledDate={date => date < moment().startOf("day")}
        />
      </Form.Item>
      {!voxpopmeRecorderEnabled && (
        <Form.Item name={[slotField.name, "inPerson"]} valuePropName="checked" style={{ textAlign: "center" }}>
          <Switch size="small" onChange={handleFieldChange("form-refresh")} />
        </Form.Item>
      )}
      <Form.Item
        name={[slotField.name, "meetingLink"]}
        className={classNames({ hidden: inPerson })}
        rules={[
          { required: !inPerson, message: "Required" },
          { type: "url", message: "Please enter a URL starting with https://" },
        ]}
      >
        <Input size="large" className="expand" placeholder="Meeting link" onBlur={handleFieldChange()} />
      </Form.Item>
      <Form.Item
        name={[slotField.name, "meetingLocation"]}
        className={classNames({ hidden: !inPerson })}
        rules={[{ required: inPerson, message: "Required" }]}
      >
        <Input size="large" className="expand" placeholder="Meeting location" onBlur={handleFieldChange()} />
      </Form.Item>
      {voxpopmeRecorderEnabled && <div>{/* empty div: in person */}</div>}
      <Form.Item name={[slotField.name, "meetingDetails"]} hidden className="hidden"></Form.Item>
      <InputSlotDetails slot={dbSlot} />
      <Form.Item name={[slotField.name, "placesLimit"]}>
        <InputNumber size="large" style={{ width: 145 }} placeholder="Max participants" onBlur={handleFieldChange()} />
      </Form.Item>
      <Form.Item name={[slotField.name, "interviewer"]} rules={[{ type: "email", message: "Invalid email" }]}>
        <Input size="large" className="expand" placeholder="Moderator email" onBlur={handleFieldChange()} />
      </Form.Item>
      <Button
        style={{ padding: 4, marginTop: 4 }}
        type="text"
        onClick={() => {
          removeSlot(slotField.name);
          form.submit();
        }}
      >
        <Icon icon="mdi:delete-outline" height="1.5em" />
      </Button>
    </>
  );
};

const saveStudySessionsMutation = async (input: UpdateStudySessionsInput) =>
  mutation<SessionsForm_updateStudySessions_Mutation>({
    variables: { input },
    mutation: graphql`
      mutation SessionsForm_updateStudySessions_Mutation($input: UpdateStudySessionsInput!) {
        updateStudySessions(input: $input) {
          study {
            ...SessionsForm_study
          }
        }
      }
    `,
  });

export const SessionsForm = ({
  study: _study,
  savingState,
  setSavingState,
}: {
  study: SessionsForm_study$key;
  savingState: SavingStateType;
  setSavingState: (savingState: SavingStateType) => void;
}) => {
  const study = useFragment(
    graphql`
      fragment SessionsForm_study on StudyNode {
        id
        type
        eventsType
        status
        approvedCount
        canHaveMultipleSessions
        sessions {
          edges {
            node {
              id
              name
              duration
              countOfBookedSlots
              availabilitySlots {
                edges {
                  node {
                    id
                    start
                    end
                    availabilityBuffer
                    duration
                    inPerson
                    meetingLink
                    meetingLocation
                    meetingDetails
                    placesLimit
                    interviewer {
                      email
                    }
                    ...SessionsForm_FocusGroupSlotEditor_slot
                    ...SessionsForm_InterviewSlotEditor_slot
                  }
                }
              }
            }
          }
        }
        voxpopmeRecorderEnabled
      }
    `,
    _study
  );

  const confirm = useConfirm();
  const confirmQuitEditing = useConfirmQuitEditing();

  const [form] = Form.useForm();

  const [eventsType, setEventsType] = useState(study.eventsType as EventsType);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const refreshForm = useCallback(() => {
    setHasUnsavedChanges(true);
    form.setFieldsValue({});
  }, [form, setHasUnsavedChanges]);

  const submitForm = useCallback(() => {
    setHasUnsavedChanges(true);
    form.submit();
  }, [form, setHasUnsavedChanges]);

  const handleFieldChange = useCallback<FieldChangeHandler>(
    (action: "save" | "form-refresh" = "save") => {
      switch (action) {
        case "form-refresh":
          return refreshForm;

        case "save":
        default:
          return submitForm;
      }
    },
    [refreshForm, submitForm]
  );

  // confirm unsaved changes on navigate
  const { router } = useRouter();

  const handleNavigate = useCallback(async (): Promise<boolean> => {
    if (hasUnsavedChanges && !(await confirmQuitEditing())) return false;

    setHasUnsavedChanges(false);
    return true;
  }, [confirmQuitEditing, hasUnsavedChanges]);

  useEffect(
    () =>
      router.addNavigationListener(
        location => {
          // required when beforeUnload is true
          if (hasUnsavedChanges && !location) return false;

          return handleNavigate();
        },
        { beforeUnload: true }
      ),
    [router, hasUnsavedChanges, handleNavigate]
  );

  const sanitizeForm = (formValues: SessionsFormValues): UpdateStudySessionsInput => ({
    studyId: study.id,
    eventsType,
    sessions:
      formValues.sessions
        ?.filter(s => s.name)
        .map(s => ({
          ...s,
          availabilitySlots:
            s.availabilitySlots
              ?.filter(s => s?.start)
              .map(a => {
                const { start, end, duration } =
                  study.type === PROJECT_TYPES.INTERVIEW
                    ? {
                        ...a,
                        start: a
                          .start!.clone()
                          .set({ hour: a.range![0].get("hour"), minute: a.range![0].get("minute") })
                          .startOf("minute"),
                        end: a
                          .start!.clone()
                          .set({ hour: a.range![1].get("hour"), minute: a.range![1].get("minute") })
                          .startOf("minute"),
                      }
                    : {
                        start: a.start!.clone().startOf("minute"),
                        end: a
                          .start!.clone()
                          .add(s.duration ?? 0, "minute")
                          .startOf("minute"),
                        duration: s.duration,
                      };

                // ensure end time has the correct date component if the range covers midnight UTC
                if (start > end) end.add(1, "day");

                return {
                  ...omit(a, "range"),
                  start,
                  end,
                  duration,
                };
              }) ?? [],
        })) ?? [],
  });

  const studyToFormValues = useCallback(
    (study: SessionsForm_study$data): SessionsFormValues => ({
      studyId: study.id,
      eventsType: study.eventsType as EventsType,
      sessions: study.sessions.edges.map<Session>(sessionEdge => ({
        id: sessionEdge?.node?.id ?? "",
        name: sessionEdge?.node?.name ?? "",
        duration: sessionEdge?.node?.duration ?? undefined,
        availabilitySlots: (sessionEdge?.node?.availabilitySlots?.edges ?? []).map(slotEdge => ({
          id: slotEdge?.node?.id ?? "",
          start: moment(slotEdge?.node?.start ?? ""),
          range: [moment(slotEdge?.node?.start ?? ""), moment(slotEdge?.node?.end ?? "")],
          availabilityBuffer: slotEdge?.node?.availabilityBuffer,
          duration: slotEdge?.node?.duration ?? sessionEdge?.node?.duration,
          interviewer: slotEdge?.node?.interviewer?.email,
          inPerson: slotEdge?.node?.inPerson ?? false,
          meetingLink: slotEdge?.node?.meetingLink ?? "",
          meetingLocation: slotEdge?.node?.meetingLocation ?? "",
          meetingDetails: slotEdge?.node?.meetingDetails ?? "",
          placesLimit: slotEdge?.node?.placesLimit,
        })),
      })),
    }),
    []
  );

  useUpdateEffect(() => form.setFieldsValue(studyToFormValues(study)), [form, study, studyToFormValues]);

  const initialValues: SessionsFormValues = useMemo(
    () =>
      study.sessions.edges.length
        ? studyToFormValues(study)
        : { studyId: study.id, eventsType: study.eventsType as EventsType, sessions: [createSession(0)] },
    [studyToFormValues, study]
  );

  const addSession = () => {
    const values = form.getFieldsValue() as SessionsFormValues;
    form.setFieldsValue({
      ...values,
      sessions: [...values.sessions, createSession(values.sessions.length)],
    });
    handleFieldChange("form-refresh")();
  };

  return (
    <StyledForm
      {...{ form, initialValues }}
      disabled={savingState === "saving"}
      onFinish={async (values: any) => {
        setHasUnsavedChanges(true);
        setSavingState("saving");
        try {
          await saveStudySessionsMutation(sanitizeForm(values));
          setHasUnsavedChanges(false);
          setSavingState("saved");
        } catch (e) {
          setSavingState("error");
          throw e;
        }
      }}
    >
      <Form.Item name="studyId" hidden></Form.Item>
      <Form.List name="sessions">
        {sessionsFields =>
          eventsType === "SESSIONS" ? (
            <>
              <Collapse defaultActiveKey={range(0, 100)}>
                {sessionsFields.map(sessionField => (
                  <Panel
                    key={sessionField.key}
                    header={
                      <div className="session-heading">
                        <span className="session-name">
                          {form.getFieldValue(["sessions", sessionField.name, "name"])?.trim() ||
                            `Session ${sessionField.name + 1}`}
                        </span>
                        <CopyOutlined
                          onClick={evt => {
                            evt.stopPropagation();
                            const values = form.getFieldsValue() as SessionsFormValues;
                            values.sessions.splice(sessionField.name + 1, 0, {
                              ...values.sessions[sessionField.name],
                              name: `${values.sessions[sessionField.name]!.name} (copy)`,
                              id: "",
                              availabilitySlots: values.sessions[sessionField.name]!.availabilitySlots.map(s => ({
                                ...s,
                                id: "",
                              })),
                            });
                            form.setFieldsValue(values);
                            submitForm();
                          }}
                        />
                        <DeleteOutlined
                          onClick={async evt => {
                            evt.stopPropagation();
                            if (
                              !study?.sessions?.edges?.[sessionField.name]?.node?.countOfBookedSlots ||
                              (await confirm({
                                title: "Delete session?",
                                content:
                                  "There are participants booked in this session. They will be notified of canceled events.",
                                okText: "Delete and notify participants",
                              }))
                            ) {
                              const values = form.getFieldsValue() as SessionsFormValues;
                              values.sessions = exceptIndex(values.sessions, sessionField.name);
                              form.setFieldsValue(values);
                              submitForm();
                            }
                          }}
                        />
                      </div>
                    }
                  >
                    <Form.Item name={[sessionField.name, "id"]} hidden></Form.Item>
                    <DetailsInput
                      title="Session name"
                      description="This will be visible to participants"
                      inputs={
                        <Form.Item name={[sessionField.name, "name"]} rules={[{ required: true, message: "Required" }]}>
                          <Input
                            size="large"
                            placeholder="Session name"
                            maxLength={100}
                            style={{ minWidth: 600, maxWidth: 900, width: "75%" }}
                            onBlur={handleFieldChange()}
                          />
                        </Form.Item>
                      }
                    />

                    <SlotsEditor
                      {...{
                        sessionId: form.getFieldValue(["sessions", sessionField.name, "id"]),
                        form,
                        handleFieldChange,
                        study,
                        eventsType,
                        setEventsType,
                        sessionField,
                        fieldName: ["sessions"],
                        addSession: () => addSession(),
                      }}
                    />
                  </Panel>
                ))}
              </Collapse>
              <IconButton
                type="link"
                icon={outlineAdd}
                onClick={() => addSession()}
                disabled={!study.canHaveMultipleSessions}
              >
                Add session
              </IconButton>
            </>
          ) : eventsType === "SINGLE" ? (
            <>
              {sessionsFields.map(sessionField => (
                <SlotsEditor
                  key={sessionField.key}
                  {...{
                    sessionId: form.getFieldValue(["sessions", sessionField.name, "id"]),
                    form,
                    handleFieldChange,
                    study,
                    eventsType,
                    setEventsType,
                    sessionField,
                    fieldName: ["sessions"],
                    // adding a first session just switches the EventsType
                    addSession: () => setEventsType("SESSIONS"),
                  }}
                />
              ))}
            </>
          ) : null
        }
      </Form.List>

      {/* without a submit button present enter presses don't submit the form */}
      <Button htmlType="submit" className="hidden" />
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  min-width: 1100px;

  .ant-collapse-header-text {
    width: 100%;

    .session-heading {
      display: flex;
      align-items: center;
      gap: 16px;
      white-space: nowrap;

      .session-name {
        flex: 1;
      }
    }
  }

  .slots-grid {
    display: grid;
    gap: 0 16px;

    &.interview {
      grid-template-columns: 140px 220px repeat(7, auto);
    }
    &.focus-group {
      grid-template-columns: 180px min-content auto min-content min-content auto min-content;
    }

    .expand {
      width: 100%;
    }
  }
  .hidden {
    display: none;
  }
`;
