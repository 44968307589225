import { useMountEffect } from "@react-hookz/web";
import { App, Button, Spin } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { useState } from "react";
import styled from "styled-components";
import { SECONDARY_TEXT_COLOR } from "../style";
import { mutation } from "../utils/relay";
import { SwitchProfilePage_switchProfile_Mutation } from "../__generated__/SwitchProfilePage_switchProfile_Mutation.graphql";

export const SwitchProfilePage = () => {
  const { notification } = App.useApp();

  const { router, match } = useRouter();

  const profileId = match.params.profileId!;

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const switchProfile = async (withDelay: boolean = false) => {
    try {
      setIsLoading(true);

      const redirectDelay = withDelay ? new Promise(resolve => setTimeout(resolve, 1000)) : undefined;

      const result = await mutation<SwitchProfilePage_switchProfile_Mutation>({
        variables: { input: { profileId } },
        mutation: graphql`
          mutation SwitchProfilePage_switchProfile_Mutation($input: SwitchProfileInput!) {
            switchProfile(input: $input) {
              viewer {
                user {
                  profile {
                    id
                    tenant {
                      name
                    }
                  }
                  ...StaffLayout_user
                }
                ...ProjectsPage_viewer
              }
            }
          }
        `,
      });

      // avoid a screen flash if redirect is too fast
      if (redirectDelay) await redirectDelay;

      router.push(match.location.query?.next ?? "/projects");

      const tenantName = result.switchProfile?.viewer.user?.profile?.tenant.name;
      notification.success({
        message: tenantName ? (
          <>
            Account switched to <em>{tenantName}</em>
          </>
        ) : (
          "Account switched"
        ),
      });

      setHasError(false);
    } catch (e) {
      setHasError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  useMountEffect(() => {
    setHasError(false);
    notification.destroy();
    switchProfile(true);
  });

  return (
    <StyledSwitchProfilePage>
      <Spin size="large" style={{ visibility: isLoading ? "visible" : "hidden" }} />
      <div className="title">Switching account...</div>
      {isLoading && <div className="subtitle">You will be redirected shortly</div>}
      {hasError && (
        <>
          <p>An error occurred when switching your account.</p>
          <div className="buttons">
            <Button size="large" type="primary" onClick={async () => switchProfile(false)} loading={isLoading}>
              Retry
            </Button>
            <Button size="large" onClick={() => router.push("/projects")} disabled={isLoading}>
              Take me home
            </Button>
          </div>
        </>
      )}
    </StyledSwitchProfilePage>
  );
};

const StyledSwitchProfilePage = styled.div`
  width: 500px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 16px 0;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 16px;
    color: ${SECONDARY_TEXT_COLOR};
  }
`;
