/**
 * @generated SignedSource<<56f451f809f737a19ac56ed990912258>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AlertVoxpopmeVideoAnalysis_respondent$data = {
  readonly dId: any | null;
  readonly id: string;
  readonly recruit: {
    readonly dbId: string;
    readonly id: string;
    readonly name: string | null;
    readonly type: ScreenersRecruitTypeChoices | null;
  } | null;
  readonly " $fragmentType": "AlertVoxpopmeVideoAnalysis_respondent";
};
export type AlertVoxpopmeVideoAnalysis_respondent$key = {
  readonly " $data"?: AlertVoxpopmeVideoAnalysis_respondent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlertVoxpopmeVideoAnalysis_respondent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlertVoxpopmeVideoAnalysis_respondent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitNode",
      "kind": "LinkedField",
      "name": "recruit",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RespondentNode",
  "abstractKey": null
};
})();

(node as any).hash = "fdc344041f423bb76b4154b33f16f633";

export default node;
