/**
 * @generated SignedSource<<cfd901325758bdc898514b30560a06e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersScreenerTerminateRespondentChoices = "ET" | "IT" | "%future added value";
export type UpdateScreenerInput = {
  clientMutationId?: string | null;
  description?: string | null;
  overquotaExitLink?: string | null;
  quotaExitLink?: string | null;
  studyId: string;
  terminateRespondent?: string | null;
  terminatedExitLink?: string | null;
  title?: string | null;
};
export type ProjectDetailsPage_UpdateScreener_Mutation$variables = {
  input: UpdateScreenerInput;
};
export type ProjectDetailsPage_UpdateScreener_Mutation$data = {
  readonly updateScreener: {
    readonly screener: {
      readonly overquotaExitLink: string | null;
      readonly quotaExitLink: string | null;
      readonly terminateRespondent: ScreenersScreenerTerminateRespondentChoices;
      readonly terminatedExitLink: string | null;
    } | null;
  } | null;
};
export type ProjectDetailsPage_UpdateScreener_Mutation = {
  response: ProjectDetailsPage_UpdateScreener_Mutation$data;
  variables: ProjectDetailsPage_UpdateScreener_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminateRespondent",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminatedExitLink",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quotaExitLink",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overquotaExitLink",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDetailsPage_UpdateScreener_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateScreenerPayload",
        "kind": "LinkedField",
        "name": "updateScreener",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenerNode",
            "kind": "LinkedField",
            "name": "screener",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDetailsPage_UpdateScreener_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateScreenerPayload",
        "kind": "LinkedField",
        "name": "updateScreener",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenerNode",
            "kind": "LinkedField",
            "name": "screener",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ccbcce0630c1f8bfad114ff8b426f32",
    "id": null,
    "metadata": {},
    "name": "ProjectDetailsPage_UpdateScreener_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectDetailsPage_UpdateScreener_Mutation(\n  $input: UpdateScreenerInput!\n) {\n  updateScreener(input: $input) {\n    screener {\n      terminateRespondent\n      terminatedExitLink\n      quotaExitLink\n      overquotaExitLink\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9d15d2d21ecb70ac2d97199727815005";

export default node;
