import { Form, FormInstance, Input, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { ELEMENT_TYPES } from "../../constants";
import { Answer } from "./index";

type Props = {
  form: FormInstance;
  editing: boolean;
  type: string;
  answers: Answer[];
  answerKey: string;
};
function PanelistCharacteristicCell({ form, editing, type, answers, answerKey }: Props) {
  const [selected, setSelected] = useState<Answer[]>(answers.filter(v => v.selected));

  if (!editing)
    return [
      "person",
      ELEMENT_TYPES.SINGLE_SELECT,
      ELEMENT_TYPES.OPENEND,
      ELEMENT_TYPES.DATE,
      ELEMENT_TYPES.NUMBER,
    ].includes(type) ? (
      <StyledTextItem>{formatAnswer(answers?.find(answer => answer.selected))}</StyledTextItem>
    ) : type === ELEMENT_TYPES.MULTI_SELECT ? (
      <StyledTextItem>
        {answers
          .filter(answer => answer.selected)
          .map((answer, index) => (
            <React.Fragment key={answer.text}>
              {
                // add comma separators
                index > 0 ? ", " : ""
              }
              {formatAnswer(answer)}
            </React.Fragment>
          ))}
      </StyledTextItem>
    ) : type === ELEMENT_TYPES.GRID_SINGLE_SELECT ? (
      <StyledTextItem>
        {answers.map(answer => (
          <div key={answer.row}>
            <span className="modal-grid-title">{`${answer.rowText}: `}</span>
            {answer.text}
          </div>
        ))}
      </StyledTextItem>
    ) : answers[0]?.text ? (
      //  handle displaying completedParticipation and pastInvites
      <StyledTextItem>{answers[0].text}</StyledTextItem>
    ) : null;

  if (type === ELEMENT_TYPES.SINGLE_SELECT) {
    let otherAnswerKey: string | null = null;
    let customAnswer: string | undefined;
    return (
      <div style={{ width: "100%" }}>
        <StyledFormItem name={answerKey}>
          <Select
            style={{ width: "100%" }}
            value={selected[0]?.key ?? ""}
            onChange={(v: any) => {
              const found = answers.find(o => o.key === v);
              if (found) {
                setSelected([found]);
              }
            }}
          >
            {answers.map((o: any) => {
              if (!otherAnswerKey && o.other && o.userSpecified) {
                otherAnswerKey = o.key;
                customAnswer = o.customAnswer;
                form.setFieldsValue({ [`${answerKey}_other`]: customAnswer });
              }
              return (
                <Select.Option key={o.key} value={o.key}>
                  {o.text}
                </Select.Option>
              );
            })}
          </Select>
        </StyledFormItem>
        {!!selected[0] && selected[0].key === otherAnswerKey && (
          <StyledFormItem name={`${answerKey}_other`} style={{ marginTop: 10 }}>
            <Input placeholder="Other" defaultValue={customAnswer} maxLength={512} />
          </StyledFormItem>
        )}
      </div>
    );
  } else if (type === ELEMENT_TYPES.MULTI_SELECT) {
    let otherAnswerKey: string | null = null;
    let customAnswer: string | undefined;
    return (
      <div style={{ width: "100%" }}>
        <StyledFormItem name={answerKey}>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            value={selected.map(o => o.key)}
            onChange={v => {
              setSelected(answers.filter(o => v.includes(o.key)));
            }}
          >
            {answers.map((o: any) => {
              if (!otherAnswerKey && o.other && o.userSpecified) {
                otherAnswerKey = o.key;
                customAnswer = o.customAnswer;
                form.setFieldsValue({ [`${answerKey}_other`]: customAnswer });
              }
              return (
                <Select.Option key={o.key} value={o.key}>
                  {o.text}
                </Select.Option>
              );
            })}
          </Select>
        </StyledFormItem>
        {selected?.some(s => s.key === otherAnswerKey) && (
          <StyledFormItem name={`${answerKey}_other`} style={{ marginTop: 10 }}>
            <Input placeholder="Other" defaultValue={customAnswer} maxLength={512} />
          </StyledFormItem>
        )}
      </div>
    );
  } else if (type === ELEMENT_TYPES.OPENEND || type === "person")
    return (
      <StyledFormItem name={answerKey}>
        <Input
          type={answerKey === "email" ? "email" : answerKey === "phone_number" ? "tel" : "text"}
          style={{ width: "100%" }}
          value={selected[0]?.key ?? ""}
          onChange={(v: any) => {
            const found = answers.find(o => o.key === v);
            if (found) {
              setSelected([found]);
            }
          }}
        />
      </StyledFormItem>
    );
  else if (type === ELEMENT_TYPES.DATE)
    return (
      <StyledFormItem
        name={answerKey}
        rules={[
          () => ({
            validator(_, value) {
              if (!value || moment(value, "YYYY-MM-DD").format("YYYY-MM-DD") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please enter a date in YYYY-MM-DD format"));
            },
          }),
        ]}
      >
        <Input
          style={{ width: "100%" }}
          value={selected[0]?.key ?? ""}
          onChange={(v: any) => {
            const found = answers.find(o => o.key === v);
            if (found) {
              setSelected([found]);
            }
          }}
        />
      </StyledFormItem>
    );
  else if (type === ELEMENT_TYPES.GRID_SINGLE_SELECT)
    return (
      <StyledTextItem>
        {answers.map(answer => (
          <div key={answer.row}>
            <span className="modal-grid-title">{`${answer.rowText}: `}</span>
            {answer.text}
          </div>
        ))}
      </StyledTextItem>
    );
  else return <div>{selected[0]?.text ?? ""}</div>;
}

function formatAnswer(answer: any) {
  return answer?.customAnswer ? (
    <>
      {answer.text}: <em>{answer.customAnswer}</em>
    </>
  ) : (
    answer?.text
  );
}

const StyledFormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0;
`;

const StyledTextItem = styled.div`
  padding: 6px 0;

  .modal-grid-title {
    font-weight: 500;
  }
`;

export default PanelistCharacteristicCell;
