export const GRAY_1 = "#FFFFFF";
export const GRAY_2 = "#FAFAFA";
export const GRAY_3 = "#F5F5F5"; // background
export const GRAY_4 = "#F0F0F0"; // border
export const GRAY_5 = "#D9D9D9";
export const GRAY_6 = "#BFBFBF";
export const GRAY_7 = "#8C8C8C"; // secondary caption text
export const GRAY_8 = "#595959"; // primary body text
export const GRAY_9 = "#A3A3A3";
export const GRAY_10 = "#2B2B2B";
export const GRAY_11 = "#AAAAAA";

export const LAYOUT_BG_COLOR = "#F6F8F9";
export const BORDER_COLOR = GRAY_4;
export const PRIMARY_TEXT_COLOR = GRAY_8;
export const SECONDARY_TEXT_COLOR = GRAY_7;
export const HEADER_PRIMARY_TEXT_COLOR = GRAY_1;
export const FADED_TEXT_COLOR = GRAY_9;
export const LINK_TEXT_COLOR = "#40a9ff";
export const LIGHT_BG = GRAY_1;
export const DIVIDER_COLOR = GRAY_4;
export const TABLE_HEADER_BG_COLOR = GRAY_2;

export const CHART_YELLOW = "#FFA600";
export const CHART_DARK_BLUE = "#003F5C";
export const CHART_LIGHT_BLUE = "#78909C";
export const CHART_RED = "#FF6361";
export const POLAR_GREEN = "#389E0D";
export const CHART_AQUA_MARINE = "#27C6BC";
export const CHART_ROYAL_BLUE = "#0B94D0";
export const CHART_LIGHT_RED = "#D14442";

export const BORDER = `1px solid ${BORDER_COLOR}`;
export const CONTAINER_BOX_SHADOW = "0px 4px 10px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.1)";
export const CONTAINER_BOX_SHADOW_LIGHT = "0px 4px 10px rgba(0, 0, 0, 0.0135), 0px 1px 2px rgba(0, 0, 0, 0.0333)";
export const BORDER_RADIUS = "6px";

export const PAGE_PADDING = "24px";
export const PAGE_WIDTH_XL = "1200px";
export const PAGE_WIDTH_LG = "1024px";
export const PAGE_WIDTH_MD = "768px";
export const PAGE_WIDTH_SM = "640px";
export const PAGE_WIDTH_XS = "480px";
export const PAGE_WIDTH_2XS = "320px";

export const INPUT_WIDTH = 420;
export const INPUT_WIDTH_NARROW = 240;
export const INPUT_WIDTH_NUMBER = 100;

export const HEADER_HEIGHT = 128;
export const HEADER_FONT_SIZE = "24px";
export const HEADER_FONT_WEIGHT = "700";
export const HEADER_WIDE_PX = "224";
export const HEADER_NARROW_PX = "64";

export const SUBTITLE_FONT_SIZE = "20px";

export const QUALIFIED_COLOR = "#9BDF6A";
export const IR_COLOR = "#828BA3";
export const DROPOUT_COLOR = "#FCB5B5";
export const OVERQUOTA_COLOR = "#A059E5";
export const TERMINANTS_COLOR = "#BBB6FC";

export const UPPER_HEADER_COLOR = "#B6BCC0";
export const PORTAL_HEADER_COLOR = "#1D1E23";

export const PROJECTS_COLUMN_HEADER_HEIGHT = 40;
