/**
 * @generated SignedSource<<f42c42112ff0ca79b4cb9537f0e427fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitPaymentModal_recruit$data = {
  readonly goal: number | null;
  readonly id: string;
  readonly incentive: string | null;
  readonly incentivePoints: number | null;
  readonly incentiveType: ScreenersRecruitIncentiveTypeChoices | null;
  readonly name: string | null;
  readonly recruitTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly status: ScreenersRecruitStatusChoices;
  readonly study: {
    readonly tenant: {
      readonly externalIncentivePerParticipantCostUsdCents: number;
      readonly stripePaymentMethodId: string | null;
      readonly stripePaymentMethodLast4: string | null;
    };
  };
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentType": "RecruitPaymentModal_recruit";
};
export type RecruitPaymentModal_recruit$key = {
  readonly " $data"?: RecruitPaymentModal_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitPaymentModal_recruit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitPaymentModal_recruit",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "recruitTransactions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecruitTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripePaymentMethodId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripePaymentMethodLast4",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalIncentivePerParticipantCostUsdCents",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};
})();

(node as any).hash = "771844234aae5c9858ef337dc9d3ca8a";

export default node;
