import { Icon } from "@iconify/react";
import { type ReactNode } from "react";
import styled from "styled-components";
import { BORDER_COLOR } from "../../style";

const PanelistRow = ({
  children,
  editing,
  questionText,
}: {
  children: ReactNode;
  editing?: boolean;
  questionText?: string;
}) => (
  <StyledPanelistRow>
    <div className="question">
      {!!editing && <Icon className="hub-drag-handle" icon="mdi:drag" />}
      <div style={{ flex: 1, fontWeight: "bold" }}>{questionText}</div>
    </div>
    <div className="answer">{children}</div>
  </StyledPanelistRow>
);

const StyledPanelistRow = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  min-height: 56px;
  border-bottom: 1px solid ${BORDER_COLOR};
  background-color: white;

  .hub-drag-handle {
    display: block;
    cursor: grab;
    flex-shrink: 0;

    &:active {
      cursor: grabbing;
    }
  }

  .question {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right: 1px solid ${BORDER_COLOR};
    margin-left: 12px;
    gap: 8px;
  }

  .answer {
    display: flex;
    padding: 8px;
    align-items: center;
  }
`;

export default PanelistRow;
