import { App, type ModalFuncProps } from "antd";
import type { ModalFunc } from "antd/es/modal/confirm";
import { useMemo } from "react";

type ConfirmPropsBase = Omit<ModalFuncProps, "onOk" | "onCancel"> & {
  [K in "onCancel" | "onOk"]?: (
    confirmInstance: ReturnType<ModalFunc>,
    ...args: Parameters<ModalFuncProps[K] & {}>
  ) => Promise<boolean | void>;
};

export type ConfirmProps<
  Title extends string | undefined = undefined,
  OkText extends string | undefined = undefined
> = ConfirmPropsBase &
  (Title extends string ? {} : Required<Pick<ConfirmPropsBase, "title">>) &
  (OkText extends string ? {} : Required<Pick<ConfirmPropsBase, "okText">>);

/**
 * Use a function that raises a confirmation modal.
 *
 * - Both the hook and the function accept all props of `Modal.confirm`.
 * - If both `title` and `okText` are passed to the hook, props are optional in the function.
 *
 * @example
 *  const confirm = useConfirm({
 *    title: "Continue?",
 *    okText: "Continue",
 *  })
 *
 *  <Button onClick={() => {
 *    if (await confirm()) continue()
 *  }}>Continue</Button>
 */
export const useConfirm = <
  Title extends string | undefined = undefined,
  OkText extends string | undefined = undefined
>({
  onCancel: onCancelDefault,
  onOk: onOkDefault,
  ...defaultProps
}: Omit<ConfirmPropsBase, "title" | "okText"> & { title?: Title; okText?: OkText } = {}) => {
  const { modal } = App.useApp();

  type Props = ConfirmProps<Title, OkText>;

  return useMemo(
    () =>
      (typeof defaultProps.okText === "string" && typeof defaultProps.title === "string"
        ? (
            { onCancel = onCancelDefault, onOk = onOkDefault, ...props }: Props = defaultProps as ConfirmProps<
              Title,
              OkText
            >
          ) =>
            new Promise<boolean>(resolve => {
              const confirmInstance = modal.confirm({
                centered: true,
                ...defaultProps,
                ...props,
                onOk: async (...args) => resolve(!!((await onOk?.(confirmInstance, ...args)) ?? true)),
                onCancel: async (...args) => resolve(!!(await onCancel?.(confirmInstance, ...args))),
              });
            })
        : ({ onCancel = onCancelDefault, onOk = onOkDefault, ...props }: Props) =>
            new Promise<boolean>(resolve => {
              const confirmInstance = modal.confirm({
                centered: true,
                ...defaultProps,
                ...props,
                onOk: async (...args) => resolve(!!((await onOk?.(confirmInstance, ...args)) ?? true)),
                onCancel: async (...args) => resolve(!!(await onCancel?.(confirmInstance, ...args))),
              });
            })) as OkText extends string
        ? Title extends string
          ? (props?: Props) => Promise<boolean>
          : (props: Props) => Promise<boolean>
        : (props: Props) => Promise<boolean>,
    [defaultProps, modal, onCancelDefault, onOkDefault]
  );
};

/**
 * @example
 *  const confirmQuitEditing = useConfirmQuitEditing()
 *
 *  <Button onClick={() => {
 *    if (await confirmQuitEditing()) close()
 *  }}>Cancel</Button>
 */
export const useConfirmQuitEditing = () =>
  useConfirm({
    title: "Quit editing?",
    content: <p>Changes you made so far will not be saved.</p>,
    okButtonProps: { danger: true },
    okText: "Quit editing",
  });
