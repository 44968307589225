import { ConfigProvider, Layout } from "antd";
import styled from "styled-components";
import { ThemeColor } from "../../antd";
import { HEADER_FONT_SIZE, HEADER_FONT_WEIGHT, HEADER_HEIGHT, PRIMARY_TEXT_COLOR } from "../../style";
import { useFlag } from "../../utils";

const PageHeading = ({
  pageTitle,
  headerActionButtons,
  headerSubMenu,
  showSubMenu = true,
}: {
  pageTitle: string | null;
  headerActionButtons?: JSX.Element;
  headerSubMenu?: JSX.Element;
  showSubMenu?: boolean;
}) => {
  const isVoxpopme = useFlag("platform-hubux-template");

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            // colorBgBase: ThemeColor.VoxGrape, // sidebar background
            // colorBgHeader: ThemeColor.VoxGrape,
            // colorBgBody: ThemeColor.VoxLavender,
          },
        },
      }}
    >
      <StyledHeader $isVoxpopme={isVoxpopme}>
        <div className="heading-wrapper">
          <h1 className="header-text">{pageTitle}</h1>
          {headerActionButtons}
        </div>
        {showSubMenu && <div className="submenu">{headerSubMenu}</div>}
      </StyledHeader>
    </ConfigProvider>
  );
};

const StyledHeader = styled(Layout.Header)<{ $isVoxpopme: boolean }>`
  // ProjectsColumnQuery's InfiniteScroll depends on header having an exact height, if height/padding/margin change,
  // test that InfiniteScroll is unaffected
  line-height: initial !important;
  height: ${HEADER_HEIGHT}px !important;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => (props.$isVoxpopme ? `border-bottom: 1px solid ${ThemeColor.VoxStroke};` : "")}

  position: sticky;
  top: 0;
  z-index: 1;

  .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .header-text {
    margin: 0;
    font-size: ${HEADER_FONT_SIZE};
    font-weight: ${HEADER_FONT_WEIGHT};
    color: ${PRIMARY_TEXT_COLOR};
  }

  .submenu {
    display: flex;
    bottom: 0;
  }
`;

export default PageHeading;
