/**
 * @generated SignedSource<<1f0d92de26bd89ef86a1150436870267>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateStudySessionsInput = {
  clientMutationId?: string | null;
  eventsType: string;
  sessions: ReadonlyArray<StudySessionInput | null>;
  studyId: string;
};
export type StudySessionInput = {
  availabilitySlots?: ReadonlyArray<AvailabilitySlotInput | null> | null;
  duration?: number | null;
  id?: string | null;
  name?: string | null;
};
export type AvailabilitySlotInput = {
  availabilityBuffer?: number | null;
  duration?: number | null;
  end?: any | null;
  id?: string | null;
  inPerson?: boolean | null;
  interviewer?: string | null;
  meetingDetails?: string | null;
  meetingLink?: string | null;
  meetingLocation?: string | null;
  placesLimit?: number | null;
  start?: any | null;
};
export type InputSlotDetails_UpdateSlot_Mutation$variables = {
  input: UpdateStudySessionsInput;
};
export type InputSlotDetails_UpdateSlot_Mutation$data = {
  readonly updateStudySessions: {
    readonly study: {
      readonly id: string;
      readonly sessions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly availabilitySlots: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly meetingDetails: string | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type InputSlotDetails_UpdateSlot_Mutation = {
  response: InputSlotDetails_UpdateSlot_Mutation$data;
  variables: InputSlotDetails_UpdateSlot_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "StudyAvailabilitySlotNodeConnection",
  "kind": "LinkedField",
  "name": "availabilitySlots",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyAvailabilitySlotNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyAvailabilitySlotNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "meetingDetails",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InputSlotDetails_UpdateSlot_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudySessionsPayload",
        "kind": "LinkedField",
        "name": "updateStudySessions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudySessionNodeConnection",
                "kind": "LinkedField",
                "name": "sessions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudySessionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudySessionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InputSlotDetails_UpdateSlot_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudySessionsPayload",
        "kind": "LinkedField",
        "name": "updateStudySessions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudySessionNodeConnection",
                "kind": "LinkedField",
                "name": "sessions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudySessionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudySessionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47f72692f9796a8938ce704a92cb938e",
    "id": null,
    "metadata": {},
    "name": "InputSlotDetails_UpdateSlot_Mutation",
    "operationKind": "mutation",
    "text": "mutation InputSlotDetails_UpdateSlot_Mutation(\n  $input: UpdateStudySessionsInput!\n) {\n  updateStudySessions(input: $input) {\n    study {\n      id\n      sessions {\n        edges {\n          node {\n            availabilitySlots {\n              edges {\n                node {\n                  id\n                  meetingDetails\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1c2e9e25164ea8e037f49246c223a86";

export default node;
