/**
 * @generated SignedSource<<6693e770b4d62efb6f522046bbe0f19e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InactiveElementContent_screener$data = {
  readonly elements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly answers: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly text: string | null;
            } | null;
          } | null>;
        };
        readonly id: string;
        readonly label: string | null;
        readonly rows: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly text: string;
            } | null;
          } | null>;
        };
        readonly text: string | null;
        readonly type: ScreenersElementTypeChoices;
      } | null;
    } | null>;
  } | null;
  readonly segments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly text: string | null;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "InactiveElementContent_screener";
};
export type InactiveElementContent_screener$key = {
  readonly " $data"?: InactiveElementContent_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"InactiveElementContent_screener">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InactiveElementContent_screener",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementNodeConnection",
      "kind": "LinkedField",
      "name": "elements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ElementNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RowNodeConnection",
                  "kind": "LinkedField",
                  "name": "rows",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RowNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RowNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AnswerNodeConnection",
                  "kind": "LinkedField",
                  "name": "answers",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AnswerNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AnswerNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SegmentNodeConnection",
      "kind": "LinkedField",
      "name": "segments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SegmentNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SegmentNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};
})();

(node as any).hash = "ef352182dd54de60abb8c54f1dcd0cce";

export default node;
