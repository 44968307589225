/**
 * @generated SignedSource<<aebd587abcf1e761352f37e280e330ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Option_element$data = {
  readonly characteristic: {
    readonly importKey: string;
    readonly tenant: {
      readonly id: string;
    } | null;
  } | null;
  readonly id: string;
  readonly type: ScreenersElementTypeChoices;
  readonly usePreviousAnswers: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"OptionTerminate_element">;
  readonly " $fragmentType": "Option_element";
};
export type Option_element$key = {
  readonly " $data"?: Option_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"Option_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Option_element",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usePreviousAnswers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNode",
      "kind": "LinkedField",
      "name": "characteristic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "importKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OptionTerminate_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "e0cf925771be48e4a9518181352f31af";

export default node;
