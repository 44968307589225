import { ThemeColor } from "../antd";

export const SmallLogo = ({
  negate = false,
  width = 52,
  height = 52,
}: {
  negate?: boolean;
  width?: number;
  height?: number;
}) => <LOGO_SMALL_VOXPOPME negate={negate} width={width} height={height} />;

const LOGO_SMALL_VOXPOPME = ({
  negate = false,
  width = 52,
  height = 52,
}: {
  negate: boolean;
  width?: number;
  height?: number;
}) => {
  const color = negate ? ThemeColor.VoxLavender : ThemeColor.VoxGrape;
  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.6707 44.0942C39.6707 43.9628 39.6375 43.8335 39.5743 43.7181C39.511 43.6026 39.4196 43.5048 39.3085 43.4335L36.3385 41.3671C36.2234 41.2721 36.0835 41.2115 35.935 41.1922C35.7866 41.173 35.6357 41.1959 35.4999 41.2583C35.364 41.3207 35.2487 41.4201 35.1674 41.5449C35.0861 41.6697 35.042 41.8148 35.0403 41.9635V41.9733V46.2124C35.0401 46.3596 35.0816 46.504 35.1599 46.6289C35.2382 46.7539 35.3502 46.8546 35.4832 46.9195C35.6163 46.9844 35.7649 47.0109 35.9124 46.996C36.0599 46.9812 36.2002 46.9255 36.3174 46.8354L39.3183 44.7481L39.3366 44.7354H39.3451C39.4468 44.662 39.5294 44.5655 39.586 44.454C39.6426 44.3425 39.6717 44.2191 39.6707 44.0942Z"
        fill={color}
      />
      <path
        d="M34.4581 40.4711C34.4581 40.3396 34.425 40.2103 34.3617 40.0948C34.2984 39.9794 34.207 39.8816 34.0958 39.8102L31.1259 37.7452C31.0107 37.6503 30.8709 37.5896 30.7224 37.5704C30.574 37.5511 30.4231 37.5741 30.2872 37.6365C30.1514 37.6989 30.0361 37.7983 29.9548 37.9231C29.8735 38.0479 29.8294 38.193 29.8277 38.3416V38.3515V42.5906C29.8275 42.7378 29.869 42.8821 29.9473 43.0071C30.0256 43.1321 30.1376 43.2328 30.2706 43.2977C30.4037 43.3625 30.5524 43.3891 30.6998 43.3742C30.8473 43.3594 30.9876 43.3037 31.1048 43.2136L34.1057 41.1262L34.1241 41.1136H34.1311C34.2333 41.0402 34.3162 40.9435 34.3731 40.8317C34.43 40.7199 34.4591 40.5963 34.4581 40.4711Z"
        fill={color}
      />
      <path
        d="M29.2568 36.8535C29.2568 36.722 29.2236 36.5927 29.1604 36.4773C29.0971 36.3619 29.0057 36.264 28.8946 36.1927L25.9246 34.1263C25.8094 34.0313 25.6696 33.9707 25.5211 33.9514C25.3727 33.9322 25.2218 33.9551 25.0859 34.0175C24.9501 34.0799 24.8348 34.1793 24.7535 34.3041C24.6722 34.4289 24.6281 34.574 24.6264 34.7227V34.7325V38.9758C24.6263 39.123 24.6677 39.2673 24.746 39.3923C24.8243 39.5173 24.9363 39.618 25.0693 39.6829C25.2024 39.7478 25.351 39.7743 25.4985 39.7594C25.646 39.7446 25.7863 39.689 25.9035 39.5988L28.9044 37.5115L28.9227 37.4989H28.9312C29.0335 37.425 29.1164 37.3278 29.1731 37.2155C29.2297 37.1032 29.2584 36.9791 29.2568 36.8535Z"
        fill={color}
      />
      <path
        d="M24.0485 33.2331C24.0485 33.1017 24.0153 32.9723 23.9521 32.8569C23.8888 32.7415 23.7974 32.6437 23.6862 32.5723L20.7163 30.5073C20.6011 30.4124 20.4613 30.3517 20.3128 30.3325C20.1644 30.3132 20.0135 30.3361 19.8777 30.3985C19.7418 30.4609 19.6265 30.5603 19.5452 30.6851C19.4638 30.8099 19.4198 30.9551 19.4181 31.1037V31.1121V35.3513C19.4179 35.4985 19.4594 35.6428 19.5377 35.7678C19.616 35.8927 19.728 35.9934 19.861 36.0583C19.9941 36.1232 20.1428 36.1497 20.2902 36.1349C20.4377 36.12 20.578 36.0644 20.6952 35.9743L23.6961 33.8869L23.7144 33.8743H23.7215C23.8235 33.801 23.9063 33.7046 23.9632 33.5931C24.0201 33.4815 24.0493 33.3581 24.0485 33.2331Z"
        fill={color}
      />
      <path
        d="M18.8444 29.6141C18.8444 29.4827 18.8113 29.3534 18.748 29.2379C18.6847 29.1225 18.5934 29.0247 18.4822 28.9533L15.5122 26.8883C15.3971 26.7934 15.2572 26.7327 15.1088 26.7135C14.9603 26.6942 14.8095 26.7171 14.6736 26.7795C14.5377 26.842 14.4225 26.9413 14.3411 27.0661C14.2598 27.191 14.2157 27.3361 14.2141 27.4847V27.4945V31.7337C14.2139 31.8809 14.2553 32.0252 14.3336 32.1502C14.4119 32.2752 14.5239 32.3758 14.657 32.4407C14.79 32.5056 14.9387 32.5322 15.0861 32.5173C15.2336 32.5025 15.3739 32.4468 15.4911 32.3567L18.4921 30.2693L18.5104 30.2567H18.5174C18.6196 30.1833 18.7026 30.0866 18.7594 29.9748C18.8163 29.863 18.8455 29.7394 18.8444 29.6141Z"
        fill={color}
      />
      <path
        d="M13.6304 25.9924C13.6304 25.861 13.5973 25.7316 13.534 25.6162C13.4707 25.5008 13.3793 25.4029 13.2682 25.3316L10.2982 23.2652C10.1831 23.1702 10.0431 23.1096 9.89472 23.0903C9.74629 23.0711 9.59542 23.094 9.45957 23.1564C9.32371 23.2188 9.20843 23.3182 9.12709 23.443C9.04576 23.5678 9.00169 23.7129 9 23.8616V23.87V28.1092C8.99985 28.2564 9.04126 28.4007 9.11956 28.5257C9.19786 28.6506 9.30991 28.7513 9.44294 28.8162C9.57597 28.8811 9.72466 28.9076 9.87212 28.8928C10.0196 28.8779 10.1599 28.8223 10.2771 28.7322L13.278 26.6448L13.2964 26.6322H13.3034C13.4051 26.559 13.4879 26.4628 13.5448 26.3515C13.6016 26.2403 13.631 26.1172 13.6304 25.9924Z"
        fill={color}
      />
      <path
        d="M39.6707 36.8661C39.6707 36.7347 39.6375 36.6053 39.5743 36.4899C39.511 36.3745 39.4196 36.2766 39.3085 36.2053L36.3385 34.1389C36.2234 34.0439 36.0835 33.9833 35.935 33.964C35.7866 33.9448 35.6357 33.9677 35.4999 34.0301C35.364 34.0925 35.2487 34.1919 35.1674 34.3167C35.0861 34.4415 35.042 34.5866 35.0403 34.7353V34.7451V38.9843C35.0401 39.1315 35.0816 39.2758 35.1599 39.4008C35.2382 39.5257 35.3502 39.6264 35.4832 39.6913C35.6163 39.7562 35.7649 39.7827 35.9124 39.7678C36.0599 39.753 36.2002 39.6974 36.3174 39.6073L39.3183 37.5199L39.3366 37.5073H39.3451C39.4468 37.4338 39.5294 37.3373 39.586 37.2258C39.6426 37.1143 39.6717 36.991 39.6707 36.8661Z"
        fill={color}
      />
      <path
        d="M34.4581 33.2429C34.4582 33.1114 34.4251 32.982 34.3618 32.8666C34.2986 32.7511 34.2071 32.6533 34.0958 32.5821L31.1259 30.5171C31.0107 30.4221 30.8709 30.3615 30.7224 30.3423C30.574 30.323 30.4231 30.3459 30.2872 30.4083C30.1514 30.4707 30.0361 30.5701 29.9548 30.6949C29.8735 30.8197 29.8294 30.9649 29.8277 31.1135V31.1219V35.3653C29.8275 35.5125 29.869 35.6568 29.9473 35.7817C30.0256 35.9067 30.1376 36.0074 30.2706 36.0723C30.4037 36.1372 30.5524 36.1637 30.6998 36.1489C30.8473 36.134 30.9876 36.0784 31.1048 35.9883L34.1057 33.9009L34.1241 33.8883H34.1311C34.2336 33.8146 34.3169 33.7175 34.3738 33.6052C34.4307 33.4928 34.4596 33.3686 34.4581 33.2429Z"
        fill={color}
      />
      <path
        d="M29.2569 29.6239C29.2569 29.4925 29.2237 29.3631 29.1604 29.2477C29.0971 29.1323 29.0058 29.0344 28.8946 28.9631L25.9247 26.8967C25.8095 26.8017 25.6696 26.7411 25.5212 26.7219C25.3728 26.7026 25.2219 26.7255 25.086 26.7879C24.9502 26.8503 24.8349 26.9497 24.7536 27.0745C24.6722 27.1993 24.6282 27.3445 24.6265 27.4931V27.5029V31.7421C24.6263 31.8893 24.6677 32.0336 24.746 32.1586C24.8243 32.2836 24.9364 32.3842 25.0694 32.4491C25.2024 32.514 25.3511 32.5405 25.4986 32.5257C25.646 32.5108 25.7863 32.4552 25.9035 32.3651L28.9045 30.2777L28.9228 30.2651H28.9312C29.0329 30.1916 29.1155 30.0951 29.1722 29.9836C29.2288 29.8721 29.2578 29.7488 29.2569 29.6239Z"
        fill={color}
      />
      <path
        d="M24.0486 26.0049C24.0486 25.8735 24.0154 25.7442 23.9521 25.6287C23.8888 25.5133 23.7975 25.4155 23.6863 25.3441L20.7164 23.2792C20.6012 23.1842 20.4613 23.1235 20.3129 23.1043C20.1645 23.085 20.0136 23.1079 19.8777 23.1704C19.7419 23.2328 19.6266 23.3321 19.5452 23.4569C19.4639 23.5817 19.4198 23.7269 19.4182 23.8755V23.8853V28.1245C19.418 28.2717 19.4594 28.416 19.5377 28.541C19.616 28.666 19.7281 28.7666 19.8611 28.8315C19.9941 28.8964 20.1428 28.923 20.2903 28.9081C20.4377 28.8932 20.578 28.8376 20.6952 28.7475L23.6962 26.6601L23.7145 26.6475H23.7216C23.8237 26.5741 23.9067 26.4774 23.9636 26.3656C24.0205 26.2539 24.0496 26.1302 24.0486 26.0049Z"
        fill={color}
      />
      <path
        d="M18.8444 22.386C18.8444 22.2545 18.8113 22.1252 18.748 22.0098C18.6847 21.8943 18.5934 21.7965 18.4822 21.7252L15.5122 19.6602C15.3971 19.5652 15.2572 19.5046 15.1088 19.4853C14.9603 19.4661 14.8095 19.489 14.6736 19.5514C14.5377 19.6138 14.4225 19.7132 14.3411 19.838C14.2598 19.9628 14.2157 20.1079 14.2141 20.2566V20.265V24.5055C14.2139 24.6527 14.2553 24.7971 14.3336 24.922C14.4119 25.047 14.5239 25.1477 14.657 25.2126C14.79 25.2775 14.9387 25.304 15.0861 25.2891C15.2336 25.2743 15.3739 25.2186 15.4911 25.1285L18.4921 23.0412L18.5104 23.0286H18.5174C18.6196 22.9551 18.7026 22.8584 18.7594 22.7467C18.8163 22.6349 18.8455 22.5112 18.8444 22.386Z"
        fill={color}
      />
      <path
        d="M39.6707 29.6197C39.6707 29.4883 39.6375 29.3589 39.5743 29.2435C39.511 29.1281 39.4196 29.0303 39.3085 28.9589L36.3385 26.8939C36.2234 26.799 36.0835 26.7383 35.935 26.7191C35.7866 26.6998 35.6357 26.7227 35.4999 26.7851C35.364 26.8475 35.2487 26.9469 35.1674 27.0717C35.0861 27.1965 35.042 27.3417 35.0403 27.4903V27.5001V31.7393C35.0401 31.8865 35.0816 32.0308 35.1599 32.1558C35.2382 32.2808 35.3502 32.3814 35.4832 32.4463C35.6163 32.5112 35.7649 32.5377 35.9124 32.5229C36.0599 32.508 36.2002 32.4524 36.3174 32.3623L39.3183 30.2749L39.3366 30.2623H39.3451C39.447 30.1887 39.5297 30.092 39.5863 29.9802C39.643 29.8684 39.6719 29.7448 39.6707 29.6197Z"
        fill={color}
      />
      <path
        d="M34.4581 25.9952C34.4582 25.8637 34.4251 25.7343 34.3618 25.6189C34.2986 25.5034 34.2071 25.4056 34.0958 25.3344L31.1259 23.2694C31.0107 23.1744 30.8709 23.1138 30.7224 23.0945C30.574 23.0753 30.4231 23.0982 30.2872 23.1606C30.1514 23.223 30.0361 23.3224 29.9548 23.4472C29.8735 23.572 29.8294 23.7171 29.8277 23.8658V23.8742V28.1133C29.8275 28.2605 29.869 28.4048 29.9473 28.5298C30.0256 28.6548 30.1376 28.7555 30.2706 28.8204C30.4037 28.8853 30.5524 28.9118 30.6998 28.8969C30.8473 28.8821 30.9876 28.8264 31.1048 28.7363L34.1057 26.6489L34.1241 26.6363H34.1311C34.2331 26.563 34.3159 26.4666 34.3728 26.3551C34.4297 26.2436 34.4589 26.1202 34.4581 25.9952Z"
        fill={color}
      />
      <path
        d="M39.6707 22.3902C39.6707 22.2587 39.6375 22.1294 39.5743 22.014C39.511 21.8986 39.4196 21.8007 39.3085 21.7294L36.3385 19.663C36.2234 19.568 36.0835 19.5074 35.935 19.4881C35.7866 19.4689 35.6357 19.4918 35.4999 19.5542C35.364 19.6166 35.2487 19.716 35.1674 19.8408C35.0861 19.9656 35.042 20.1107 35.0403 20.2594V20.2692V24.5084C35.0401 24.6556 35.0816 24.7999 35.1599 24.9248C35.2382 25.0498 35.3502 25.1505 35.4832 25.2154C35.6163 25.2803 35.7649 25.3068 35.9124 25.2919C36.0599 25.2771 36.2002 25.2215 36.3174 25.1314L39.3183 23.044L39.3366 23.0314H39.3451C39.4468 22.9579 39.5294 22.8614 39.586 22.7499C39.6426 22.6384 39.6717 22.5151 39.6707 22.3902Z"
        fill={color}
      />
      <path
        d="M34.4581 18.767C34.4582 18.6356 34.4251 18.5062 34.3618 18.3907C34.2986 18.2753 34.2071 18.1774 34.0958 18.1062L31.1259 16.0398C31.0107 15.9449 30.8709 15.8842 30.7224 15.865C30.574 15.8457 30.4231 15.8686 30.2872 15.931C30.1514 15.9934 30.0361 16.0928 29.9548 16.2176C29.8735 16.3424 29.8294 16.4876 29.8277 16.6362V16.6446V20.8922C29.8275 21.0394 29.869 21.1837 29.9473 21.3087C30.0256 21.4337 30.1376 21.5343 30.2706 21.5992C30.4037 21.6641 30.5524 21.6906 30.6998 21.6758C30.8473 21.6609 30.9876 21.6053 31.1048 21.5152L34.1057 19.4278L34.1241 19.4152H34.1311C34.234 19.3412 34.3175 19.2437 34.3744 19.1308C34.4313 19.018 34.4601 18.8932 34.4581 18.767Z"
        fill={color}
      />
      <path
        d="M39.6707 15.1397C39.6707 15.0082 39.6375 14.8789 39.5743 14.7635C39.511 14.648 39.4196 14.5502 39.3085 14.4789L36.3385 12.4139C36.2234 12.3189 36.0835 12.2583 35.935 12.239C35.7866 12.2198 35.6357 12.2427 35.4999 12.3051C35.364 12.3675 35.2487 12.4669 35.1674 12.5917C35.0861 12.7165 35.042 12.8616 35.0403 13.0103V13.0201V17.2592C35.0401 17.4064 35.0816 17.5507 35.1599 17.6757C35.2382 17.8007 35.3502 17.9014 35.4832 17.9662C35.6163 18.0311 35.7649 18.0577 35.9124 18.0428C36.0599 18.028 36.2002 17.9723 36.3174 17.8822L39.3183 15.7948L39.3366 15.7822H39.3451C39.447 15.7086 39.5297 15.6119 39.5863 15.5002C39.643 15.3884 39.6719 15.2648 39.6707 15.1397Z"
        fill={color}
      />
      <path
        d="M34.4581 11.5178C34.4581 11.3864 34.425 11.2571 34.3617 11.1417C34.2984 11.0262 34.207 10.9284 34.0958 10.8571L31.1259 8.79067C31.0107 8.69569 30.8709 8.63505 30.7224 8.6158C30.574 8.59654 30.4231 8.61944 30.2872 8.68185C30.1514 8.74426 30.0361 8.84364 29.9548 8.96844C29.8735 9.09325 29.8294 9.2384 29.8277 9.38706V9.39686V13.636C29.8275 13.7832 29.869 13.9275 29.9473 14.0525C30.0256 14.1775 30.1376 14.2782 30.2706 14.3431C30.4037 14.4079 30.5524 14.4345 30.6998 14.4196C30.8473 14.4048 30.9876 14.3491 31.1048 14.259L34.1057 12.1716L34.1241 12.159H34.1311C34.2331 12.0857 34.3159 11.9893 34.3728 11.8778C34.4297 11.7663 34.4589 11.6429 34.4581 11.5178Z"
        fill={color}
      />
      <path
        d="M39.6707 7.90867C39.6707 7.77724 39.6375 7.64792 39.5742 7.53249C39.5109 7.41706 39.4196 7.31921 39.3084 7.24788L36.3385 5.1815C36.2233 5.08652 36.0834 5.02588 35.935 5.00663C35.7866 4.98737 35.6357 5.01028 35.4998 5.07269C35.364 5.1351 35.2487 5.23448 35.1674 5.35928C35.086 5.48409 35.0419 5.62923 35.0403 5.77789V5.78769V10.0269C35.0401 10.1741 35.0815 10.3184 35.1598 10.4433C35.2381 10.5683 35.3502 10.669 35.4832 10.7339C35.6162 10.7988 35.7649 10.8253 35.9124 10.8105C36.0598 10.7956 36.2001 10.74 36.3173 10.6499L39.3183 8.56247L39.3366 8.54987H39.3451C39.4468 8.47641 39.5293 8.37992 39.586 8.2684C39.6426 8.15689 39.6716 8.03357 39.6707 7.90867Z"
        fill={color}
      />
    </svg>
  );
};
