import { useMountEffect, useUnmountEffect } from "@react-hookz/web";
import { Card, theme } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import moment from "moment";
import { useContext, useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { ConditionalKeys, IterableElement } from "type-fest";
import { ThemeColor } from "../../../antd";

import {
  CHART_AQUA_MARINE,
  CHART_DARK_BLUE,
  CHART_LIGHT_BLUE,
  CHART_LIGHT_RED,
  CHART_RED,
  CHART_ROYAL_BLUE,
  CHART_YELLOW,
  GRAY_7,
  GRAY_8,
  POLAR_GREEN,
} from "../../../style";
import { flattenEdges, TimeZoneContext } from "../../../utils";
import type { PanelReportChartsQuery as PanelReportChartsQueryType } from "../../../__generated__/PanelReportChartsQuery.graphql";
import { StatisticCard } from "./StatisticCard";

const { useToken } = theme;

export const PanelReportChartsQuery = graphql`
  query PanelReportChartsQuery($orderBy: String!, $since: DateTime!) {
    viewer {
      panel {
        healthSummary(orderBy: $orderBy, since: $since) {
          edges {
            node {
              datetime
              totalPanelists
              activePanelists
              reengagedPanelists
              inactivePanelists
              atRiskPanelists
              newPanelists
              subscribed
              unsubscribed
              blacklisted
            }
          }
        }
      }
    }
  }
`;

const PanelReportChartHeader = (
  title: string,
  legend: {
    name: string;
    color: string;
  }[]
) => (
  <div className="chart-header">
    <div className="chart-title">{title}</div>
    <div className="chart-legend">
      {legend.map(x => (
        <div className="legend-group" key={x.name}>
          <div className="legend-color">
            <span
              style={{
                backgroundColor: x.color,
                borderRadius: "50%",
                display: "inline-block",
                height: 15,
                width: 15,
              }}
            />
          </div>
          <div className="legend-name">{x.name}</div>
        </div>
      ))}
    </div>
  </div>
);

export const PanelReportCharts = ({
  className,
  queryReference,
}: {
  className?: Argument;
  queryReference: PreloadedQuery<PanelReportChartsQueryType>;
}) => {
  const { setIntervalsDisabled, shiftDate } = useContext(TimeZoneContext);
  useMountEffect(() => setIntervalsDisabled?.(true));
  useUnmountEffect(() => setIntervalsDisabled?.(false));

  const { panel } = usePreloadedQuery(PanelReportChartsQuery, queryReference).viewer;

  const data = useMemo<
    {
      datetime: string;
      totalPanelists: number;
      reengagedPanelists: number;
      activePanelists: number;
      inactivePanelists: number;
      atRiskPanelists: number;
      newPanelists: number;
      subscribed: number;
      unsubscribed: number;
      blacklisted: number;
    }[]
  >(() => {
    const nodes = flattenEdges(panel?.healthSummary)?.map(x => [x, shiftDate(moment(x.datetime as string))] as const);

    const nowYear = new Date().getFullYear();
    const showDatetimeFull = nodes.some(x => x[1].year() === nowYear);

    return nodes.map(([x, t]) => {
      const datetimeFull = t.format("MMM DD 'YY");

      return x.datetime
        ? {
            ...x,
            datetime: showDatetimeFull ? datetimeFull : t.format("MMM DD"),
            datetimeFull,
            unsubscribed: -x.unsubscribed,
            blacklisted: -x.blacklisted,
          }
        : x;
    });
  }, [panel?.healthSummary, shiftDate]);

  type DataKeyNumber = ConditionalKeys<IterableElement<typeof data>, number>;

  const calculateChange = (keys: DataKeyNumber[]) => {
    const presentValue = keys.reduce((acc, cur) => acc + (data.at(-1)?.[cur] ?? 0), 0);
    const pastValue = keys.reduce((acc, cur) => acc + (data[0]?.[cur] ?? 0), 0);
    return presentValue - pastValue;
  };

  const calculatePercentChange = (keys: DataKeyNumber[]) => {
    const presentValue = keys.reduce((acc, cur) => acc + (data.at(-1)?.[cur] ?? 0), 0);
    const pastValue = keys.reduce((acc, cur) => acc + (data[0]?.[cur] ?? 0), 0);
    return presentValue / pastValue - 1;
  };

  const StatisticHeader = (title: string, properties: DataKeyNumber[]) => {
    return (
      <>
        <span style={{ fontWeight: 600, fontSize: "14px" }}>{title}</span>{" "}
        <span
          style={{
            color: calculateChange(properties) >= 0 ? POLAR_GREEN : CHART_RED,
            fontWeight: 400,
            fontSize: 16,
          }}
        >{`${calculateChange(properties).toLocaleString(undefined, {
          signDisplay: "always",
        })} (${calculatePercentChange(properties).toLocaleString(undefined, {
          signDisplay: "auto",
          style: "percent",
        })})`}</span>
      </>
    );
  };

  const last = data.at(-1);
  const currentPanelistsEndOfPeriod = last ? last.activePanelists + last.newPanelists + last.atRiskPanelists : 0;

  const currentPanelistsStartOfPeriod = data[0]
    ? data[0].activePanelists + data[0].newPanelists + data[0].atRiskPanelists
    : 0;

  const { token } = useToken();

  return data[0] ? (
    <Styled className={classNames(className)}>
      <div className="report-row">
        <Card
          className="panelists-chart-card"
          title={PanelReportChartHeader("Total Panelists", [
            { name: "New", color: CHART_AQUA_MARINE },
            { name: "Active", color: CHART_YELLOW },
            { name: "Re-engaged", color: CHART_ROYAL_BLUE },
            { name: "Inactive", color: CHART_DARK_BLUE },
            { name: "At-Risk", color: CHART_LIGHT_RED },
          ])}
          bodyStyle={{ height: "90%", padding: 0 }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{
                top: token.paddingMD,
                right: token.paddingMD + token.sizeXXL + token.sizeXS,
                bottom: token.paddingMD,
                left: token.paddingMD,
              }}
            >
              <XAxis dataKey="datetime" axisLine={false} tickLine={false} angle={-45} tickMargin={20} height={60} />
              <YAxis axisLine={false} tickLine={false} tickFormatter={tick => tick.toLocaleString()} />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="atRiskPanelists"
                name="At Risk Panelists"
                stackId="1"
                stroke="none"
                fill={CHART_LIGHT_RED}
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="inactivePanelists"
                name="Inactive Panelists"
                stackId="1"
                stroke="none"
                fill={CHART_DARK_BLUE}
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="reengagedPanelists"
                name="Re-Engaged Panelists"
                stackId="1"
                stroke="none"
                fill={CHART_ROYAL_BLUE}
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="activePanelists"
                name="Active Panelists"
                stackId="1"
                stroke="none"
                fill={CHART_YELLOW}
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="newPanelists"
                name="New Panelists"
                stackId="1"
                stroke="none"
                fill={CHART_AQUA_MARINE}
                fillOpacity={1}
              />
              <Brush alwaysShowText dataKey="datetime" height={30} stroke={ThemeColor.HubPurpleBright} />
            </AreaChart>
          </ResponsiveContainer>
        </Card>
        <div className="statistics-cards-container">
          <StatisticCard
            title={StatisticHeader("Total Panelists", ["totalPanelists"])}
            value={data.at(-1)!.totalPanelists.toLocaleString()}
            postscript={`At start of period: ${data[0].totalPanelists.toLocaleString()}`}
            statisticColor={GRAY_8}
          />
          <StatisticCard
            title={StatisticHeader("Engaged Panelists", ["activePanelists", "newPanelists", "atRiskPanelists"])}
            value={currentPanelistsEndOfPeriod.toLocaleString()}
            postscript={`At start of period: ${currentPanelistsStartOfPeriod.toLocaleString()}`}
            statisticColor={GRAY_8}
          />
        </div>
      </div>
      <div className="report-row">
        <Card
          className="panelists-chart-card"
          title={PanelReportChartHeader("Panelists Gained/Lost", [
            { name: "Subscribed", color: CHART_LIGHT_BLUE },
            { name: "Unsubscribed", color: CHART_YELLOW },
            { name: "Blocklisted", color: CHART_RED },
          ])}
          bodyStyle={{ height: "90%" }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} stackOffset="sign">
              <XAxis dataKey="datetime" axisLine={false} tickLine={false} angle={-45} tickMargin={20} height={60} />
              <YAxis axisLine={false} tickLine={false} tickFormatter={tick => tick.toLocaleString()} />
              <Tooltip />
              <ReferenceLine y={0} stroke="#000" />
              <Bar
                dataKey="subscribed"
                name="Subscribed"
                stackId="1"
                stroke="none"
                fill={CHART_LIGHT_BLUE}
                fillOpacity={1}
              />
              <Bar
                dataKey="unsubscribed"
                name="Unsubscribed"
                stackId="1"
                stroke="none"
                fill={CHART_YELLOW}
                fillOpacity={1}
              />
              <Bar
                dataKey="blacklisted"
                name="Blocklisted"
                stackId="1"
                stroke="none"
                fill={CHART_RED}
                fillOpacity={1}
              />
              <Brush dataKey="datetime" height={30} stroke={ThemeColor.HubPurpleBright} />
            </BarChart>
          </ResponsiveContainer>
        </Card>
        <div className="statistics-cards-container">
          <StatisticCard
            title={`Total Subscribed`}
            value={data
              .map(o => o.subscribed)
              .reduce((prev, curr) => prev + curr)
              .toLocaleString()}
            statisticColor={CHART_LIGHT_BLUE}
          />
          <StatisticCard
            title={`Total Unsubscribed`}
            value={data
              .map(o => -o.unsubscribed)
              .reduce((prev, curr) => prev + curr)
              .toLocaleString()}
            statisticColor={CHART_YELLOW}
          />
          <StatisticCard
            title={`Total Blocklisted`}
            value={data
              .map(o => -o.blacklisted)
              .reduce((prev, curr) => prev + curr)
              .toLocaleString()}
            statisticColor={CHART_RED}
          />
        </div>
      </div>
    </Styled>
  ) : (
    <Styled className={classNames(className)}>
      Your community health report will appear here when data is available.
    </Styled>
  );
};

const Styled = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;

  .report-row {
    display: flex;
    gap: 18px;
    height: 49%;
  }

  .panelists-chart-card {
    flex: 1 1 0;
    height: 100%;

    .ant-card-head {
      border: 0;
    }

    .chart-header {
      display: flex;
      gap: 40px;
      .chart-title {
        font-size: 16px;
      }

      .chart-legend {
        display: flex;
        gap: 14px;
        color: ${GRAY_7};
        font-size: 12px;

        .legend-group {
          display: flex;
          gap: 8px;
          align-items: center;

          .legend-color {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .statistics-cards-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 100%;
    flex: 0 0 224px;
  }
`;
