/**
 * @generated SignedSource<<738665d4001a146c3e077095267da6f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateExportInput = {
  clientMutationId?: string | null;
  filters: any;
  id: string;
  isVpm?: boolean | null;
  qIdent?: string | null;
};
export type QuestionIdentModal_CreateExport_Mutation$variables = {
  input: CreateExportInput;
};
export type QuestionIdentModal_CreateExport_Mutation$data = {
  readonly createExport: {
    readonly export: {
      readonly to: {
        readonly email: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
    };
    readonly panel: {
      readonly export: {
        readonly id: string;
      } | null;
    } | null;
    readonly recruit: {
      readonly export: {
        readonly id: string;
      } | null;
    } | null;
    readonly study: {
      readonly export: {
        readonly id: string;
      } | null;
    } | null;
  };
};
export type QuestionIdentModal_CreateExport_Mutation = {
  response: QuestionIdentModal_CreateExport_Mutation$data;
  variables: QuestionIdentModal_CreateExport_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ExportNode",
  "kind": "LinkedField",
  "name": "export",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionIdentModal_CreateExport_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateExportPayload",
        "kind": "LinkedField",
        "name": "createExport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExportNode",
            "kind": "LinkedField",
            "name": "export",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExportButton_export"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "to",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelNode",
            "kind": "LinkedField",
            "name": "panel",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RecruitNode",
            "kind": "LinkedField",
            "name": "recruit",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionIdentModal_CreateExport_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateExportPayload",
        "kind": "LinkedField",
        "name": "createExport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExportNode",
            "kind": "LinkedField",
            "name": "export",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countCompleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sentOn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sentTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "to",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelNode",
            "kind": "LinkedField",
            "name": "panel",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RecruitNode",
            "kind": "LinkedField",
            "name": "recruit",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8412269fd922c34677adc59ca80724e8",
    "id": null,
    "metadata": {},
    "name": "QuestionIdentModal_CreateExport_Mutation",
    "operationKind": "mutation",
    "text": "mutation QuestionIdentModal_CreateExport_Mutation(\n  $input: CreateExportInput!\n) {\n  createExport(input: $input) {\n    export {\n      ...ExportButton_export\n      to {\n        email\n        id\n      }\n      id\n    }\n    panel {\n      export {\n        id\n      }\n      id\n    }\n    recruit {\n      export {\n        id\n      }\n      id\n    }\n    study {\n      export {\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment ExportButton_export on ExportNode {\n  id\n  countCompleted\n  countTotal\n  sentOn\n  sentTo\n  status\n  to {\n    email\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6c1d06a754e78208bac8d5f8e2f4491";

export default node;
