export const PROJECT_STATUSES = {
  DRAFT: "DR",
  LIVE: "LV",
  COMPLETE: "CM",
};

export const PROJECT_TYPES = {
  FOCUS_GROUP: "SS",
  INTERVIEW: "OO",
  SURVEY: "SY",
};

export type ProjectType = "OO" | "SS" | "SY";

export type IncentiveType =
  | typeof PROJECT_INCENTIVE_TYPES.CASH
  | typeof PROJECT_INCENTIVE_TYPES.POINTS
  | typeof PROJECT_INCENTIVE_TYPES.EXTERNAL;

export const PROJECT_INCENTIVE_TYPES = {
  CASH: "CA",
  POINTS: "PT",
  EXTERNAL: "EX",
};

export const studyTypeMap: any = {
  [PROJECT_TYPES.FOCUS_GROUP]: "Focus Group",
  [PROJECT_TYPES.INTERVIEW]: "Interview",
  [PROJECT_TYPES.SURVEY]: "Survey",
};

export const PORTAL_STUDY_STATUSES = {
  PENDING: "Pending",
  CONTINUE: "Continue",
  SCHEDULE_NOW: "Schedule Now",
  SCHEDULED: "Schedule",
  NOT_QUALIFIED: "Not qualified",
  APPLY: "Apply",
  NO_SHOW: "No Show",
};

export const TERMINATE_RESPONDENT = {
  END_OF_SCREENER: "ET",
  ASAP: "IT",
};

export type EventsType = "SESSIONS" | "SINGLE";

export const SCHEDULING_TYPE = {
  HUBUX: "HX",
  CALENDLY: "CL",
};

export const SCHEDULED_BY = {
  PARTICIPANT: "P",
  YOUR_TEAM: "T",
};
