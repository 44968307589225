import type ConfigProvider from "antd/es/config-provider";
import type { ConfigProviderProps } from "antd/es/config-provider";
import type { ThemeConfig } from "antd/es/config-provider/context";
import { merge } from "lodash-es";
import type { Simplify } from "type-fest";

export const enum ThemeColor {
  HubPurple = "#4C4FA6",
  HubPurpleBright = "#8C7BF7",
  VoxDodgerBlue = "#2D91E6",
  VoxDodgerBlueLighter = "#46A2F1",
  VoxDodgerBlueMedium = "#A9D0F2",
  VoxDodgerBlueSoft = "#D9EAF9",
  VoxGrape = "#341C4C",
  VoxGray = "#6C758E",
  VoxGrayHover = "#ECEFF5",
  VoxGrayHoverLighter = "#FAFAFA",
  VoxLavender = "#F4F6FD",
  VoxMystic = "#E4E7EF",
  VoxOrangePeel = "#FF9800",
  VoxPomegranate = "#F44336",
  VoxStroke = "#CCD2E0",
  VoxTealGreen = "#1BD9A6",
  VoxZircon = "#EFF5FF",
}

type GlobalConfig = Simplify<Parameters<(typeof ConfigProvider)["config"]>[0]>;
export const CONFIG_GLOBAL: GlobalConfig = {};

export const CONFIG_PROVIDER_PROPS: ConfigProviderProps = {};

const THEME_CONFIG_BASE = {
  components: {
    Card: {
      colorBgBase: "white",
    },
    Collapse: {
      colorFillAlter: "white",
    },
    Layout: {
      /**
       * Determines inline (horizontal) padding of Header.
       * To get the value, divide your intended padding by 1.5.
       *
       * See: https://github.com/ant-design/ant-design/blob/16e90b23014403c01f6fa4983a4907c14be2f067/components/layout/style/index.ts#L202
       */
      controlHeightLG: 19.2, // * 1.5 = 24
    },
    Tag: {
      borderRadiusSM: 6,
    },
  },
  token: {
    borderRadiusLG: 6,
    colorBgBase: "#FFFFFF",
    colorTextBase: "#000000",
    fontFamily: "'Roboto', 'Franklin Gothic Medium', Tahoma, sans-serif",
  },
} as const satisfies ThemeConfig;

export const THEME_CONFIG_VOXPOPME = merge({}, THEME_CONFIG_BASE, {
  components: {
    Button: {
      borderRadiusSM: 25,
      borderRadius: 25,
      borderRadiusLG: 25,
      colorPrimary: ThemeColor.VoxDodgerBlue,
      colorPrimaryHover: ThemeColor.VoxDodgerBlueLighter,
      controlItemBgHover: ThemeColor.VoxDodgerBlueLighter,
    },
    Layout: {
      colorBgBase: ThemeColor.VoxGrape,
      colorBgHeader: "white",
      colorBgBody: ThemeColor.VoxLavender,
    },
    Modal: {
      colorPrimary: ThemeColor.VoxDodgerBlue,
    },
    Switch: {
      colorPrimary: ThemeColor.VoxDodgerBlue,
      colorPrimaryHover: ThemeColor.VoxDodgerBlueLighter,
    },
  },
  token: {
    colorBorder: ThemeColor.VoxStroke,
    colorBorderSecondary: ThemeColor.VoxStroke,
    colorError: ThemeColor.VoxPomegranate,
    colorInfo: ThemeColor.VoxDodgerBlue,
    colorInfoBg: ThemeColor.VoxDodgerBlueSoft,
    colorInfoBorder: ThemeColor.VoxDodgerBlueMedium,
    colorPrimary: ThemeColor.VoxDodgerBlue,
    colorSuccess: ThemeColor.VoxTealGreen,
    colorWarning: ThemeColor.VoxOrangePeel,
  },
} as const satisfies ThemeConfig);
