import { uniqBy } from "lodash-es";
import moment from "moment";
import { Entries } from "type-fest";

import { FUTURE_PROOF } from "../../../constants";
import {
  CharacteristicAnswerNode,
  CharacteristicNode,
  CharacteristicResponseNode,
  CharacteristicRowNode,
  Maybe,
  ScreenersCharacteristicTypeChoices,
} from "../../../schema";
import { Identity } from "../../../types";
import { splitOnce } from "../../../utils";

type UpdatePanelistValue = number | string | string[] | undefined;
export type UpdatePanelistValues = Record<string, UpdatePanelistValue>;

export const createGridDefaultValue = (characteristicId: string, values: UpdatePanelistValues): typeof values =>
  Object.fromEntries(
    Object.entries(values)
      .map(([k, v]) => [splitOnce(k, "::"), v] as const)
      .filter(([[id]]) => id === characteristicId)
      .map(([[, rowId], v]) => [rowId, v] as const)
  );

export const createUpdatePanelistInitialValues = <
  T extends readonly {
    readonly characteristic: Pick<CharacteristicNode, "id" | "type">;
    readonly characteristicResponses: readonly (Pick<
      Partial<CharacteristicResponseNode>,
      "customAnswer" | "textValue"
    > & {
      readonly answer?: Maybe<Pick<Partial<CharacteristicAnswerNode>, "id">>;
      readonly row?: Maybe<Pick<Partial<CharacteristicRowNode>, "id">>;
    })[];
  }[]
>(
  x: T
) =>
  Object.fromEntries(
    uniqBy(
      x
        .map(
          ({ characteristic: { id: k, ...characteristic }, characteristicResponses }) =>
            ((
              {
                [ScreenersCharacteristicTypeChoices.Dt]: () => {
                  const x = characteristicResponses[0];
                  return [[k, x?.textValue ? moment(x.textValue) : undefined]];
                },
                [ScreenersCharacteristicTypeChoices.Gs]: () =>
                  characteristicResponses
                    .filter((x): x is Identity<typeof x & { row: NonNullable<(typeof x)["row"]> }> => !!x.row?.id)
                    .map(x => [
                      [`${k}::${x.row.id}`, x?.answer?.id] as [string, string | undefined],
                      ...(x?.customAnswer ? [[`${k}_other`, x.customAnswer]] : []),
                    ])
                    .flat(),
                [ScreenersCharacteristicTypeChoices.Ms]: () => {
                  const other = characteristicResponses.find(x => x.customAnswer);

                  return [
                    [k, characteristicResponses.filter(x => x.textValue !== other?.textValue).map(x => x?.answer?.id)],
                    ...(other ? [[`${k}_other`, other.customAnswer]] : []),
                  ];
                },
                [ScreenersCharacteristicTypeChoices.Nm]: () => {
                  const x = characteristicResponses[0];
                  return [[k, x?.textValue ? Number(x.textValue) : undefined]];
                },
                [ScreenersCharacteristicTypeChoices.Oe]: () => [
                  [k, characteristicResponses[0]?.textValue ?? undefined],
                ],
                [ScreenersCharacteristicTypeChoices.Ss]: () => {
                  const x = characteristicResponses[0];
                  return [[k, x?.answer?.id], ...(x?.customAnswer ? [[`${k}_other`, x.customAnswer]] : [])];
                },
                [FUTURE_PROOF]: () => [],
              } as Record<CharacteristicNode["type"], () => [string, UpdatePanelistValue | UpdatePanelistValue[]][]>
            )[characteristic.type]?.() ?? [])
        )
        .flat() as Entries<UpdatePanelistValues>,
      0
    )
  );
