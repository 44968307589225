import { MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import styled from "styled-components";
import { GRAY_8 } from "../style";

type propsType = {
  menu: MenuProps["items"];
};

export const OverflowButton = (props: propsType) => {
  const items = props.menu;
  return (
    <StyledDropdown menu={{ items }}>
      <Button
        size="large"
        className="overflow-button"
        icon={
          <MoreOutlined
            style={{
              cursor: "pointer",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          />
        }
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .overflow-button {
    margin-left: 1rem;
    border-color: ${GRAY_8};
    &:hover,
    &:focus {
      color: ${GRAY_8};
    }
  }
`;
