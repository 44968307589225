/**
 * @generated SignedSource<<bb6b0aa9a1bbb5e307165bf2eb84e03b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RankElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "RankElement_screener";
};
export type RankElement_screener$key = {
  readonly " $data"?: RankElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"RankElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RankElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "87ea88ef5c4e515cc6d041a194d17404";

export default node;
