/**
 * @generated SignedSource<<1646f32c6e59d8e8cd94a216d1c84543>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CheckPasswordStrengthInput = {
  clientMutationId?: string | null;
  password: string;
  userInfo?: any | null;
};
export type RegistrationPage_checkPasswordStrength_Mutation$variables = {
  input: CheckPasswordStrengthInput;
};
export type RegistrationPage_checkPasswordStrength_Mutation$data = {
  readonly checkPasswordStrength: {
    readonly reason: string | null;
    readonly strong: boolean | null;
  } | null;
};
export type RegistrationPage_checkPasswordStrength_Mutation = {
  response: RegistrationPage_checkPasswordStrength_Mutation$data;
  variables: RegistrationPage_checkPasswordStrength_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CheckPasswordStrengthPayload",
    "kind": "LinkedField",
    "name": "checkPasswordStrength",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "strong",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationPage_checkPasswordStrength_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationPage_checkPasswordStrength_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e9b1ce41a584082b2c2ad66c7e34e76",
    "id": null,
    "metadata": {},
    "name": "RegistrationPage_checkPasswordStrength_Mutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationPage_checkPasswordStrength_Mutation(\n  $input: CheckPasswordStrengthInput!\n) {\n  checkPasswordStrength(input: $input) {\n    strong\n    reason\n  }\n}\n"
  }
};
})();

(node as any).hash = "7df3ffc6fe8991dd93905ba64555a670";

export default node;
