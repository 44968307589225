import outlineAdd from "@iconify-icons/ic/outline-add";
import { App, FormInstance, InputRef } from "antd";
import { arrayMoveImmutable } from "array-move";
import { uniqueId } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { CHARACTERISTIC_ELEMENT_TYPES } from "../../../constants";
import type { CharacteristicAnswer } from "../../../types";
import { cleanDataTransferText, exceptIndex } from "../../../utils/misc";
import { IconButton } from "../../IconButton";
import { EditCharacteristicSelectAnswer } from "./EditCharacteristicSelectAnswer";

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  marginTop: "12px",
  scale: isDragging && "8px",
  ...draggableStyle,
});

export const EditCharacteristicSelect = ({
  form,
  characteristicType,
  answers,
  setAnswers,
}: {
  form: FormInstance;
  characteristicType: CHARACTERISTIC_ELEMENT_TYPES;
  answers: CharacteristicAnswer[];
  setAnswers: (answers: CharacteristicAnswer[]) => void;
}) => {
  const { message } = App.useApp();

  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const focusRef = useRef<InputRef>(null);

  useEffect(() => focusRef.current?.focus(), [focusIndex]);

  const handleChange = useCallback(
    (answers: CharacteristicAnswer[]) => {
      setAnswers(answers);
      form.setFieldsValue({ answers });
    },
    [form, setAnswers]
  );

  // on enter press, create a new element after the current one and set focus to it
  const handleEnter = useCallback(
    (answerIndex: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      setAnswers([
        ...answers.slice(0, answerIndex + 1),
        { id: uniqueId("answer_"), text: "" },
        ...answers.slice(answerIndex + 1),
      ]);
      setFocusIndex(answerIndex + 1);
    },
    [answers, setAnswers]
  );

  // on paste, do the default behavior if it's not multiline, otherwise paste multiple and select the element after the
  // last pasted one
  const handlePaste = useCallback(
    (answerIndex: number) => (e: React.ClipboardEvent<HTMLInputElement>) => {
      const newAnswers = cleanDataTransferText(e.clipboardData);
      if (newAnswers.length > 1) {
        e.preventDefault();
        setAnswers([
          ...answers.slice(0, answerIndex),
          ...newAnswers.map(text => ({ id: uniqueId("answer_"), text })),
          ...answers.slice(answerIndex),
        ]);
        message.info(`Added ${newAnswers.length} rows`);
        setFocusIndex(answerIndex + newAnswers.length);
      }
    },
    [answers, setAnswers]
  );

  return (
    <>
      <DragDropContext
        onDragEnd={(result: DropResult) =>
          setAnswers(arrayMoveImmutable(answers, result.source.index, result.destination!.index))
        }
      >
        <Droppable droppableId="characteristic-draggable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {answers.map((answer, answerIndex) => (
                <Draggable key={answer.id} draggableId={answer.id} index={answerIndex}>
                  {(provided: any, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      id={`characteristic-answer-${answerIndex}`}
                    >
                      <EditCharacteristicSelectAnswer
                        characteristicType={characteristicType}
                        answer={answer}
                        onUpdate={answer => handleChange(answers.map((a, i) => (i !== answerIndex ? a : answer)))}
                        onDelete={() => handleChange(exceptIndex(answers, answerIndex))}
                        onPressEnter={handleEnter(answerIndex)}
                        onPaste={handlePaste(answerIndex)}
                        focusRef={answerIndex === focusIndex ? focusRef : null}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <IconButton
        icon={outlineAdd}
        type="link"
        onClick={() => handleChange([...answers, { id: uniqueId("answer_"), text: "" }])}
      >
        Add an option
      </IconButton>
      {
        // only allow one "other" option
        !answers.some(answer => answer.other) && (
          <IconButton
            icon={outlineAdd}
            type="link"
            onClick={() =>
              handleChange([...answers, { id: uniqueId("answer_"), text: "Other", other: true, userSpecified: false }])
            }
          >
            Add "other"
          </IconButton>
        )
      }
    </>
  );
};
