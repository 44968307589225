import moment from "moment-timezone";

export type CountryType = "US" | "CA" | "AU" | "GB" | "DE" | "FR" | "ES";

export const COUNTRY_LOOKUP = {
  US: {
    CURRENCY: "USD",
    LOCALE: "en-US",
    NAME: "United States",
  },
  CA: {
    CURRENCY: "CAD",
    LOCALE: "en-CA",
    NAME: "Canada",
  },
  GB: {
    CURRENCY: "GBP",
    LOCALE: "en-GB",
    NAME: "United Kingdom",
  },
  FR: {
    CURRENCY: "EUR",
    LOCALE: "fr-FR",
    NAME: "France",
  },
  DE: {
    CURRENCY: "EUR",
    LOCALE: "de-DE",
    NAME: "Germany",
  },
  ES: {
    CURRENCY: "EUR",
    LOCALE: "es-ES",
    NAME: "Spain",
  },
  AU: {
    CURRENCY: "AUD",
    LOCALE: "en-AU",
    NAME: "Australia",
  },
};

export const TIME_ZONES = moment.tz
  .names()
  .map(x => moment.tz.zone(x))
  .filter((x): x is NonNullable<typeof x> => !!x);
