import outlineViewColumn from "@iconify-icons/ic/outline-view-column";
import { Icon } from "@iconify/react";
import { Button, Checkbox, Drawer, Row, Tooltip, Typography } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { DrawerHeader, DRAWER_HEADER_PARENT_CLASS_NAME } from "..";
import { GRAY_1, GRAY_2 } from "../../style";
import type { ParticipantColumns_viewer$data } from "../../__generated__/ParticipantColumns_viewer.graphql";

const { Text } = Typography;

type Props = {
  viewer: ParticipantColumns_viewer$data;
  onApplyColumns: (columns: CheckboxValueType[]) => void;
  loading: boolean;
  setColumnsDrawerVisible: (visible: boolean) => void;
  columnsDrawerVisible: boolean;
};
function ParticipantColumns({ viewer, onApplyColumns, loading, setColumnsDrawerVisible, columnsDrawerVisible }: Props) {
  const [selectedColumns, setSelectedColumns] = useState<CheckboxValueType[]>([]);
  const [openingState, setOpeningState] = useState<CheckboxValueType[]>([]);

  const onCloseDrawer = () => {
    setColumnsDrawerVisible(false);
    setSelectedColumns(openingState);
  };

  return (
    <Styled>
      <div className="column-wrapper">
        <Tooltip title="Columns">
          <Button
            className="column-button"
            onClick={() => {
              if (columnsDrawerVisible) {
                onCloseDrawer();
              } else {
                setColumnsDrawerVisible(true);
              }
            }}
          >
            <Icon icon={outlineViewColumn} height={20} />
          </Button>
        </Tooltip>
        <StyledDrawer
          className={DRAWER_HEADER_PARENT_CLASS_NAME}
          closable={false}
          title={<DrawerHeader onClose={() => setColumnsDrawerVisible(false)} title="Show / hide columns" />}
          placement="right"
          open={columnsDrawerVisible}
          afterOpenChange={visible => {
            if (visible) {
              setOpeningState(selectedColumns);
            }
          }}
          onClose={onCloseDrawer}
          mask={false}
          width="30%"
        >
          <div className="column-title">Select columns to show on the table</div>
          <Checkbox.Group
            className="checkbox-container"
            value={selectedColumns}
            onChange={checkedValues => setSelectedColumns(checkedValues)}
          >
            {viewer.study?.screener?.elements?.edges.map(edge => {
              const element = edge?.node;
              return element ? (
                <Row className="checkbox-row" key={element.dbId}>
                  <span className="checkbox-row-content">
                    <Checkbox value={element.dbId}>
                      <Text ellipsis={true}>{`${element.label ? `${element.label}.` : ""} ${
                        element.text || "Question with no text"
                      }`}</Text>
                    </Checkbox>
                  </span>
                </Row>
              ) : null;
            })}
          </Checkbox.Group>
          <div className="column-drawer-buttons">
            <Button className="column-button" type="link" size="large" onClick={onCloseDrawer}>
              Cancel
            </Button>
            <Button
              className="column-button"
              type="primary"
              size="large"
              loading={loading}
              onClick={() => onApplyColumns(selectedColumns)}
            >
              Apply
            </Button>
          </div>
        </StyledDrawer>
      </div>
    </Styled>
  );
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px;

    .column-title {
      margin: 0 24px 18px 24px;
    }

    .checkbox-container {
      width: 100%;
      margin-bottom: 6rem;
      display: block;
      .checkbox-row {
        height: 36px;
        width: 100%;

        :nth-child(odd) {
          background-color: ${GRAY_2};
        }

        .checkbox-row-content {
          margin: 0 24px;
          align-self: center;
        }
      }
    }

    .column-drawer-buttons {
      width: 100%;
      justify-content: center;
      position: absolute;
      bottom 0;
      left: 0;
      padding-top: 2rem;
      background-color: ${GRAY_1};

      .column-button {
        width: 40%;
        margin: 0 5% 24px 5%;
      }
    }
  }
`;

const Styled = styled.div`
  display: flex;

  .column-wrapper {
    width: 100%;

    .column-title {
      display: flex;
      justify-content: space-between;
    }

    .column-button {
      padding: 4px 6px;
    }
  }
`;

export default createFragmentContainer(ParticipantColumns, {
  viewer: graphql`
    fragment ParticipantColumns_viewer on Viewer @argumentDefinitions(studyId: { type: "String" }) {
      user {
        profile {
          tenant {
            name
          }
        }
      }
      study(studyId: $studyId) {
        screener {
          elements {
            edges {
              node {
                dbId
                position
                type
                text
                label
              }
            }
          }
        }
      }
    }
  `,
});
