import { Icon } from "@iconify/react";
import { Drawer, Dropdown, MenuProps, Tabs, TabsProps } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import React, { createContext, useEffect, useState, type Dispatch, type SetStateAction } from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import styled from "styled-components";

import { ButtonLabel, Label, LoadingPage } from "..";
import { ThemeColor } from "../../antd";
import { EmailTemplateContent } from "../../constants";
import { environment } from "../../relay";
import { PAGE_PADDING, PORTAL_HEADER_COLOR, SUBTITLE_FONT_SIZE } from "../../style";
import { useFlag } from "../../utils";
import { CommunicationComposePage_study$data } from "../../__generated__/CommunicationComposePage_study.graphql";
import { CommunicationComposePage_user$data } from "../../__generated__/CommunicationComposePage_user.graphql";
import { CommunicationPage_study$data } from "../../__generated__/CommunicationPage_study.graphql";
import ProjectPageContent from "../ProjectPageContent";
import CommunicationComposePage, { COMPOSE_PAGE_WIDTH } from "./CommunicationComposePage";
import { SelectTemplateModal } from "./Templates/SelectTemplateModal";

type OnEmailSent = (() => void) | null;
type SetOnEmailSent = Dispatch<SetStateAction<OnEmailSent>>;
export const CommunicationPageOnEmailSentContext = createContext<SetOnEmailSent | null>(null);

enum TabKey {
  HISTORY = "history",
  AUTOMATED_EMAILS = "automated-emails",
}

enum CreateKey {
  EMAIL = "email",
  EMAIL_FROM_TEMPLATE = "email-from-template",
}

const useSelected = () => {
  const { match } = useRouter();
  const [selected, setSelected] = useState<TabKey>(TabKey.HISTORY);

  useEffect(() => {
    const selected = match.location.pathname.split("/");
    setSelected(selected.at(-1)! as TabKey);
  }, [match.location.pathname]);

  return selected;
};

type Props = {
  study: CommunicationPage_study$data;
};
const CommunicationPage: React.FC<Props> = ({ children, study }) => {
  const { router } = useRouter();
  const selected = useSelected();
  const [composeVisible, setComposeVisible] = useState(false);
  const [chooseTemplateModalVisible, setChooseTemplateModalVisible] = useState(false);
  const [onEmailSent, setOnEmailSent] = useState<OnEmailSent>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateContent | null>(null);

  const handleTemplateSelected = (emailTemplate: EmailTemplateContent) => {
    setSelectedTemplate(emailTemplate);
    setComposeVisible(true);
  };

  const tabs: TabsProps["items"] = [
    {
      key: TabKey.HISTORY,
      label: <Label icon="mdi:history" iconScale={1.67} text="History" />,
    },
    {
      key: TabKey.AUTOMATED_EMAILS,
      label: <Label icon="mdi:clipboard-clock-outline" iconScale={1.67} text="Automated Emails" />,
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: CreateKey.EMAIL,
      label: "New email",
      onClick: () => {
        setSelectedTemplate(null);
        setComposeVisible(true);
      },
    },
    {
      key: CreateKey.EMAIL_FROM_TEMPLATE,
      label: "New email from template",
      onClick: () => {
        setChooseTemplateModalVisible(true);
      },
    },
  ];

  const isVoxpopme = useFlag("platform-hubux-template");

  return (
    <StyledProjectPageContent $isVoxpopme={isVoxpopme} style={{ padding: 0, position: "absolute", inset: 0 }}>
      <SelectTemplateModal
        tenant={study.tenant}
        visible={chooseTemplateModalVisible}
        setVisible={setChooseTemplateModalVisible}
        title="New email from template"
        onCancel={() => setSelectedTemplate(null)}
        selectTemplateButtonText="Next"
        onTemplateSelected={handleTemplateSelected}
      />
      <header>
        <Tabs
          activeKey={selected}
          onChange={key => router.push(`/projects/${study.id}/communications/${key}`)}
          items={tabs}
        />
        <Dropdown menu={{ items }}>
          <ButtonLabel icon="mdi:plus" text="New" type="primary" />
        </Dropdown>
      </header>
      <CommunicationPageOnEmailSentContext.Provider value={setOnEmailSent}>
        <div style={{ flex: 1, overflowY: "hidden" }}>{children}</div>
      </CommunicationPageOnEmailSentContext.Provider>
      <StyledDrawer
        closeIcon={<Icon height="1.2em" icon="mdi:page-last" style={{ display: "block" }} />}
        onClose={() => setComposeVisible(false)}
        placement="right"
        title="New email"
        open={composeVisible}
        mask={false}
        width={`calc(${COMPOSE_PAGE_WIDTH} + ${PAGE_PADDING} * 2)`}
        style={{ left: "auto" }}
      >
        <QueryRenderer
          environment={environment}
          variables={{ studyId: study.id }}
          query={graphql`
            query CommunicationPage_CommunicationComposePage_Query($studyId: String!, $count: Int, $search: String) {
              viewer {
                study(studyId: $studyId) {
                  ...CommunicationComposePage_study
                }
                user {
                  ...CommunicationComposePage_user
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as CommunicationComposePage_study$data) &&
              (props?.viewer?.user as CommunicationComposePage_user$data) ? (
              <CommunicationComposePage
                recruit={null}
                study={props.viewer.study}
                user={props.viewer.user}
                {...props}
                initialMessage={selectedTemplate}
                onEmailSent={() => {
                  setComposeVisible(false);
                  onEmailSent?.();
                }}
              />
            ) : (
              <LoadingPage />
            );
          }}
        />
      </StyledDrawer>
    </StyledProjectPageContent>
  );
};

const StyledProjectPageContent = styled(ProjectPageContent)<{ $isVoxpopme: boolean }>`
  padding-top: 0 !important;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  > header {
    position: sticky;
    top: 0;
    padding: 16px 24px;
    background-color: ${props => (props.$isVoxpopme ? ThemeColor.VoxLavender : ThemeColor.VoxGrayHoverLighter)};
    ${props => (props.$isVoxpopme ? `border-bottom: 1px solid ${ThemeColor.VoxStroke};` : "")}
    z-index: 1;
    display: flex;
    align-items: end;
    gap: 32px;

    .ant-tabs {
      flex: auto;
      margin-bottom: -16px;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
      ::before {
        border: 0;
      }
    }

    .ant-tabs-tab {
      font-size: 12px;
      padding: 8px 0;
    }
    .ant-tabs-tab-active {
      font-weight: 500;
    }

    button {
      font-weight: 500;
      align-self: center;
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body > * {
    padding: ${PAGE_PADDING};
  }
  .ant-drawer-header {
    border-bottom: 0;
  }
  .ant-drawer-header-title {
    color: ${PORTAL_HEADER_COLOR};
    font-size: ${SUBTITLE_FONT_SIZE};
    * {
      color: inherit;
      font-size: inherit;
    }
  }
`;

export default createFragmentContainer(CommunicationPage, {
  study: graphql`
    fragment CommunicationPage_study on StudyNode {
      id
      tenant {
        ...SelectTemplateModal_tenant
      }
    }
  `,
});
