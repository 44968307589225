/**
 * @generated SignedSource<<4295ffb1ff39cb07d21ac8aeb278c4ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortalCard_portalCard$data = {
  readonly description: string | null;
  readonly id: string;
  readonly links: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly linkText: string | null;
        readonly linkUrl: string | null;
        readonly title: string | null;
      } | null;
    } | null>;
  };
  readonly title: string | null;
  readonly " $fragmentType": "PortalCard_portalCard";
};
export type PortalCard_portalCard$key = {
  readonly " $data"?: PortalCard_portalCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalCard_portalCard">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalCard_portalCard",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PortalCardLinkNodeConnection",
      "kind": "LinkedField",
      "name": "links",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PortalCardLinkNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PortalCardLinkNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PortalCardNode",
  "abstractKey": null
};
})();

(node as any).hash = "138975411581dcc3c5134bac47bdd36f";

export default node;
