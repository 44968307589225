/**
 * @generated SignedSource<<452b532dc266b1497e3ce4857e5a81b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwitchProfileInput = {
  clientMutationId?: string | null;
  profileId: string;
};
export type SwitchProfilePage_switchProfile_Mutation$variables = {
  input: SwitchProfileInput;
};
export type SwitchProfilePage_switchProfile_Mutation$data = {
  readonly switchProfile: {
    readonly viewer: {
      readonly user: {
        readonly profile: {
          readonly id: string;
          readonly tenant: {
            readonly name: string;
          };
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"StaffLayout_user">;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectsPage_viewer">;
    };
  } | null;
};
export type SwitchProfilePage_switchProfile_Mutation = {
  response: SwitchProfilePage_switchProfile_Mutation$data;
  variables: SwitchProfilePage_switchProfile_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitchProfilePage_switchProfile_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SwitchProfilePayload",
        "kind": "LinkedField",
        "name": "switchProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StaffLayout_user"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectsPage_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwitchProfilePage_switchProfile_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SwitchProfilePayload",
        "kind": "LinkedField",
        "name": "switchProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enablePanelView",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hideBillingPage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TagNode",
                            "kind": "LinkedField",
                            "name": "tags",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelistNode",
                    "kind": "LinkedField",
                    "name": "panelist",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNodeConnection",
                        "kind": "LinkedField",
                        "name": "tenants",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vpmUserId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "studiesCopy",
                "args": null,
                "concreteType": "StudyNodeConnection",
                "kind": "LinkedField",
                "name": "studies",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e7c038f60c18cfc93df3523c2eb285a",
    "id": null,
    "metadata": {},
    "name": "SwitchProfilePage_switchProfile_Mutation",
    "operationKind": "mutation",
    "text": "mutation SwitchProfilePage_switchProfile_Mutation(\n  $input: SwitchProfileInput!\n) {\n  switchProfile(input: $input) {\n    viewer {\n      user {\n        profile {\n          id\n          tenant {\n            name\n            id\n          }\n        }\n        ...StaffLayout_user\n        id\n      }\n      ...ProjectsPage_viewer\n    }\n  }\n}\n\nfragment InputTagsSelect_tenant on TenantNode {\n  tags {\n    dId\n    ...TagTag_tag\n    id\n  }\n}\n\nfragment ProjectsPage_viewer on Viewer {\n  studiesCopy: studies {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  user {\n    profile {\n      tenant {\n        id\n        ...InputTagsSelect_tenant\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment StaffLayout_user on UserNode {\n  dId\n  email\n  fullName\n  panelist {\n    dId\n    tenants {\n      edges {\n        node {\n          dId\n          name\n          id\n        }\n      }\n    }\n    id\n  }\n  profile {\n    tenant {\n      dId\n      enablePanelView\n      hideBillingPage\n      name\n      id\n    }\n    id\n  }\n  vpmUserId\n}\n\nfragment TagTag_tag on TagNode {\n  color\n  name\n}\n"
  }
};
})();

(node as any).hash = "ecf7a016e8da72bc1070634b1040bcf3";

export default node;
