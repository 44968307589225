import { useUpdateEffect } from "@react-hookz/web";
import { Form, InputNumber, Tooltip, type FormInstance } from "antd";
import moment, { type Moment, type MomentSetObject } from "moment";
import type { RangeValue } from "rc-picker/lib/interface";
import { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import DatePicker from "../DatePicker";

import { TimeZoneContext } from "../../utils";
import { ignoreWheel } from "../../utils/misc";
import { InputDate } from "./InputDate";

const applyDay = (time: Moment, day: Moment) =>
  time.set(
    (["date", "month", "year"] as const).reduce((acc: MomentSetObject, cur) => ({ ...acc, [cur]: day.get(cur) }), {})
  );

export const DayTimeRange = ({
  onChange,
  value,
  index,
  form,
  disabled = false,
}: {
  onChange: (value: Moment | null) => void;
  value?: { start: Moment; duration: number | null; availabilityBuffer: number | null; end: Moment };
  index: number;
  form: FormInstance;
  disabled?: boolean;
}) => {
  const { shiftDate, timeZoneOffsetDifference, unshiftDate } = useContext(TimeZoneContext);

  const [day, setDay] = useState(value?.start ? moment(value.start) : null);

  const [duration, setDuration] = useState(value?.duration ?? null);
  const [availabilityBuffer, setAvailabilityBuffer] = useState(value?.availabilityBuffer ?? null);

  const [_timeRange, _setTimeRange] = useState<NonNullable<RangeValue<Moment>>>([
    value?.start ? moment(value.start) : null,
    value?.end ? moment(value.end) : null,
  ]);
  const timeRange = useMemo<typeof _timeRange>(
    () => _timeRange.map(x => (x ? shiftDate(x) : null)) as typeof _timeRange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_timeRange, shiftDate, timeZoneOffsetDifference]
  );
  const setTimeRange = (x: typeof _timeRange) =>
    _setTimeRange(x.every(x => x) ? (x.map(x => unshiftDate(x as NonNullable<typeof x>)) as typeof x) : [null, null]);

  useUpdateEffect(() => {
    const [start, end] = _timeRange;

    if (day && duration && end && start)
      onChange({
        ...form.getFieldValue("availabilitySlots")[index],
        availabilityBuffer,
        duration,
        end: applyDay(end, day),
        start: applyDay(start, day),
      });
  }, [_timeRange, availabilityBuffer, day, duration]);

  return (
    <StyledDayTimeRange>
      <Tooltip title="The day the interview is to take place">
        <InputDate
          size="large"
          style={{ width: 151 }}
          format="M/D/YY"
          placeholder="Select day"
          value={day}
          allowClear={false}
          onChange={v => setDay(v)}
          disabledDate={date => date < moment().startOf("day")}
          disabled={disabled}
        />
      </Tooltip>
      <Tooltip title="Interview duration in minutes">
        <Form.Item validateStatus={day && (duration ?? 0) === 0 ? "error" : "success"} style={{ marginBottom: 0 }}>
          <InputNumber
            size="large"
            style={{ width: 150 }}
            placeholder="Interview duration"
            defaultValue={duration ?? ""}
            onBlur={e => setDuration(+e.target.value)}
            disabled={disabled}
            onScroll={ignoreWheel}
            min={1}
          />
        </Form.Item>
      </Tooltip>
      <Tooltip title="Minutes gap between interviews">
        <InputNumber
          size="large"
          style={{ width: 150 }}
          placeholder="Break in-between"
          defaultValue={form.getFieldValue(["availabilitySlots", index, "availabilityBuffer"])}
          onBlur={e => setAvailabilityBuffer(+e.target.value ?? null)}
          disabled={disabled}
          onScroll={ignoreWheel}
          min={0}
        />
      </Tooltip>
      <Tooltip title="Set the hours when you will be available for the interviews">
        <DatePicker.RangePicker
          size="large"
          style={{ width: 241 }}
          picker="time"
          placeholder={["Start time", "End time"]}
          format={"h:mm A"}
          minuteStep={15}
          value={timeRange}
          allowClear={false}
          onChange={v => setTimeRange(v === null ? [null, null] : v)}
          disabled={disabled}
        />
      </Tooltip>
    </StyledDayTimeRange>
  );
};

const StyledDayTimeRange = styled.div`
  display: flex;
  gap: 12px;
`;
