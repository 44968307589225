/**
 * @generated SignedSource<<c35d76de51248a9458af7cd287c2f028>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWorkspaceSettingsInput = {
  clientMutationId?: string | null;
  defaultSurveysToUseApi?: boolean | null;
  enableThreatDetection?: boolean | null;
  fakeWidgetEnabled?: boolean | null;
  headerMarkup?: string | null;
  headerMarkupEnabled?: boolean | null;
  requirePhoneNumber?: boolean | null;
};
export type WorkspaceSettings_UpdateSettings_Mutation$variables = {
  input: UpdateWorkspaceSettingsInput;
};
export type WorkspaceSettings_UpdateSettings_Mutation$data = {
  readonly updateWorkspaceSettings: {
    readonly tenant: {
      readonly customportal: {
        readonly headerMarkup: string;
        readonly headerMarkupEnabled: boolean;
      } | null;
      readonly enableThreatDetection: boolean;
      readonly requirePhoneNumber: boolean;
    } | null;
  } | null;
};
export type WorkspaceSettings_UpdateSettings_Mutation = {
  response: WorkspaceSettings_UpdateSettings_Mutation$data;
  variables: WorkspaceSettings_UpdateSettings_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enableThreatDetection",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requirePhoneNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headerMarkup",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headerMarkupEnabled",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkspaceSettings_UpdateSettings_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkspaceSettingsPayload",
        "kind": "LinkedField",
        "name": "updateWorkspaceSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomPortalNode",
                "kind": "LinkedField",
                "name": "customportal",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkspaceSettings_UpdateSettings_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkspaceSettingsPayload",
        "kind": "LinkedField",
        "name": "updateWorkspaceSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomPortalNode",
                "kind": "LinkedField",
                "name": "customportal",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d30669cda559ae8981c3493c8d8c93d",
    "id": null,
    "metadata": {},
    "name": "WorkspaceSettings_UpdateSettings_Mutation",
    "operationKind": "mutation",
    "text": "mutation WorkspaceSettings_UpdateSettings_Mutation(\n  $input: UpdateWorkspaceSettingsInput!\n) {\n  updateWorkspaceSettings(input: $input) {\n    tenant {\n      enableThreatDetection\n      requirePhoneNumber\n      customportal {\n        headerMarkup\n        headerMarkupEnabled\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "49dcf72efb214157bcb746b2b8ba78aa";

export default node;
