/**
 * @generated SignedSource<<7ede927c36a105ca16b9b4e411b149f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_CommunicationRemindersPage_Query$variables = {
  studyId: string;
};
export type RouteConfig_CommunicationRemindersPage_Query$data = {
  readonly viewer: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"CommunicationRemindersPage_study">;
    } | null;
  };
};
export type RouteConfig_CommunicationRemindersPage_Query = {
  response: RouteConfig_CommunicationRemindersPage_Query$data;
  variables: RouteConfig_CommunicationRemindersPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "studyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "studyId",
    "variableName": "studyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "subject",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_CommunicationRemindersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommunicationRemindersPage_study"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RouteConfig_CommunicationRemindersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailDomain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "googleCalendarEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "voxpopmeGlobal",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incentiveType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inviteMeetingNameVirtual",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inviteMessageVirtual",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inviteMeetingNameInPerson",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inviteMessageInPerson",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RemindersTemplatesNodeConnection",
                "kind": "LinkedField",
                "name": "reminderTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RemindersTemplatesNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RemindersTemplatesNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "daysBefore",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hoursBefore",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minsBefore",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReminderTemplateVersionsNode",
                            "kind": "LinkedField",
                            "name": "virtualMeeting",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReminderTemplateVersionsNode",
                            "kind": "LinkedField",
                            "name": "inPersonMeeting",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d158584045e02441fc2b3aa808358b5",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_CommunicationRemindersPage_Query",
    "operationKind": "query",
    "text": "query RouteConfig_CommunicationRemindersPage_Query(\n  $studyId: String!\n) {\n  viewer {\n    study(studyId: $studyId) {\n      ...CommunicationRemindersPage_study\n      id\n    }\n  }\n}\n\nfragment CommunicationRemindersPage_study on StudyNode {\n  id\n  tenant {\n    emailDomain\n    googleCalendarEmail\n    voxpopmeGlobal\n    id\n  }\n  type\n  incentiveType\n  ...InviteInput_study\n  reminderTemplates {\n    edges {\n      node {\n        id\n        enabled\n        daysBefore\n        hoursBefore\n        minsBefore\n        ...ReminderInput_template\n      }\n    }\n  }\n}\n\nfragment InviteInput_study on StudyNode {\n  id\n  inviteMeetingNameVirtual\n  inviteMessageVirtual\n  inviteMeetingNameInPerson\n  inviteMessageInPerson\n}\n\nfragment ReminderInput_template on RemindersTemplatesNode {\n  id\n  enabled\n  virtualMeeting {\n    subject\n    message\n    id\n  }\n  inPersonMeeting {\n    subject\n    message\n    id\n  }\n  daysBefore\n  hoursBefore\n  minsBefore\n}\n"
  }
};
})();

(node as any).hash = "20550a0b66b2c681f3480bc143a04021";

export default node;
