import React from "react";
import styled from "styled-components";

import { ImgThemed } from "..";
import { HEADER_HEIGHT, HEADER_WIDE_PX } from "../../style";
import IntercomLink from "../IntercomLink";
import PageHeading from "../Layouts/PageHeading";

const PanelSetupPage: React.FC<{}> = () => {
  return (
    <>
      <PageHeading pageTitle={"Community"} />
      <Content>
        <div className="page">
          <ImgThemed alt="Magnifying glass" className="header-image" src="magnifying-glass.svg" />
          <h1>There's nothing here</h1>
          <p>
            You don't have a panel registered. If you want to create a panel, please{" "}
            <IntercomLink text="chat with us" />.
          </p>
        </div>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  // added to line up table w/ vertical navbar
  margin-left: 0 !important;

  .page {
    width: calc(100vw - ${HEADER_WIDE_PX}px);
    max-height: 100%;
    overflow: auto;
    text-align: center;
    margin: auto;

    .header-image {
      width: 85px;
      margin-bottom: 20px;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0;
  }

  .ant-menu-submenu > .ant-menu {
    background-color: #f8f8f8;
  }
`;

export default PanelSetupPage;
