/**
 * @generated SignedSource<<7b156ff7ae018bf221b8e68375c86017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudyEmailCard_card$data = {
  readonly created: any;
  readonly message: string;
  readonly recipients: ReadonlyArray<string | null> | null;
  readonly subject: string;
  readonly " $fragmentType": "StudyEmailCard_card";
};
export type StudyEmailCard_card$key = {
  readonly " $data"?: StudyEmailCard_card$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudyEmailCard_card">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudyEmailCard_card",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipients",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    }
  ],
  "type": "StudyEmailNode",
  "abstractKey": null
};

(node as any).hash = "b390dfc3551c5ff186ab5efe9fca2b36";

export default node;
