/**
 * @generated SignedSource<<ff4240705859bac1bde34a3c54e2503b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NumberElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "NumberElement_screener";
};
export type NumberElement_screener$key = {
  readonly " $data"?: NumberElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"NumberElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NumberElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "a85fd367f175d5048d083841a857d02a";

export default node;
