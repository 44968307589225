import axios from "axios";
import useRouter from "found/useRouter";
import React, { useEffect } from "react";

import { getAuthorizationHeader } from "../../utils";
import { getApiBaseUrl } from "../../utils/misc";
import LoadingPage from "../LoadingPage";

// This component is loaded when the Google OAuth process redirects back to us following a request for
// calendar access. The route will have a 'code' query param if successful and an 'error' param if not
const CalendarAuthFinish: React.FC = () => {
  const { match, router } = useRouter();
  useEffect(() => {
    // URL will contain an error parameter if there was an error in the auth process e.g. user denied access
    if (match.location.query.error) {
      console.error(match.location.query.error);
      router.push("auth?cal-auth-success=false");
    }
    const code = match.location.query.code;
    if (code) {
      const url = `${getApiBaseUrl()}/calendar-auth/auth-code`;
      axios
        .request({
          method: "post",
          url,
          headers: getAuthorizationHeader(),
          data: {
            code: code,
          },
        })
        .then(response => {
          // redirect to workspace settings with success message

          router.replace("/workspace/settings?cal-auth-success=true");
        })
        .catch(err => {
          console.error(err);
          router.replace("/workspace/settings?cal-auth-success=false");
        });
    } else {
      router.replace("/workspace/settings?cal-auth-success=false");
    }
  }, [match, router]);

  return <LoadingPage />;
};

export default CalendarAuthFinish;
