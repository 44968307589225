/**
 * @generated SignedSource<<bc6bf011ce39aece135135f9dd6e6730>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseGridSingleSelect_characteristicResponses$data = ReadonlyArray<{
  readonly answer: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly row: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "InputCharacteristicResponseGridSingleSelect_characteristicResponses";
}>;
export type InputCharacteristicResponseGridSingleSelect_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponseGridSingleSelect_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseGridSingleSelect_characteristicResponses">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponseGridSingleSelect_characteristicResponses",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicAnswerNode",
      "kind": "LinkedField",
      "name": "answer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicRowNode",
      "kind": "LinkedField",
      "name": "row",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};
})();

(node as any).hash = "25374b76e892d0b17efe7adab16cb5a6";

export default node;
