import { ArrowLeftOutlined, StarFilled } from "@ant-design/icons";
import { Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { CountryType, COUNTRY_LOOKUP } from "../../constants";
import { GRAY_10, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from "../../style";
import { mutation } from "../../utils";
import { currencyFormatter } from "../../utils/misc";
import { PortalRedeem_Country_Mutation } from "../../__generated__/PortalRedeem_Country_Mutation.graphql";
import { PortalRedeem_panelist$data } from "../../__generated__/PortalRedeem_panelist.graphql";
import Loading from "../Loading";
import { PortalPageLayout } from "./PortalPageLayout";
import RewardCard, { RewardCardType } from "./RewardCard";

type Props = {
  panelist: PortalRedeem_panelist$data;
};
function PortalRedeem({ panelist }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  if (!panelist.person?.country || !panelist.tenantForIncentives) return null;
  const country = panelist.person.country as CountryType;
  const locale = country ? COUNTRY_LOOKUP[country].LOCALE : "en-US";
  const currency = country ? COUNTRY_LOOKUP[country].CURRENCY : "USD";
  const blockedCurrencies = JSON.parse(panelist.tenantForIncentives.blockedCurrencies as string) as string[];

  async function changeCountryMutation(newCountry: string) {
    await mutation<PortalRedeem_Country_Mutation>({
      variables: {
        input: {
          country: newCountry,
        },
      },
      mutation: graphql`
        mutation PortalRedeem_Country_Mutation($input: UpdatePersonInput!) {
          updatePerson(input: $input) {
            panelist {
              id
              convertedRewardsBalance
              panelistCurrencyToPointsRatio
              tenantForIncentives {
                rewardCards {
                  sku
                  programId
                  maxAmount
                  minAmount
                  name
                  faceplateUrl
                }
              }
            }
            person {
              id
              country
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  }

  async function handleChangeCountry(newCountry: string) {
    setLoading(true);
    try {
      await changeCountryMutation(newCountry);
    } catch (e) {
      // mutation handles error
    } finally {
      setLoading(false);
    }
  }

  return (
    <PortalPageLayout style={{ backgroundColor: "#fff" }}>
      <Styled>
        <Link to="/portal">
          <ArrowLeftOutlined /> {t("portal.redeem.back-to-participant-portal", "Back to Participant Portal")}
        </Link>
        <div className="heading">
          <div className="balance-details">
            <div>
              <div>{t("portal.redeem.current-points-balance", "Current Points Balance")}</div>
              <div className="current-balance-details">
                <StarFilled style={{ color: "#FADB14", marginRight: "0.5rem" }} />
                <span className="points">
                  {t("portal.redeem.curent-user-points", "{{points}} points", { points: panelist.rewardsBalance })}
                </span>
              </div>
              <div>
                {t("portal.redeem.current-user-points-usd", "Equal to")}{" "}
                <strong>{currencyFormatter(locale, currency).format(panelist.convertedRewardsBalance || 0)}</strong>
              </div>
            </div>
            <div className="total">
              <div>{t("portal.redeem.total-points-redeemed-label", "Total Points Redeemed")}</div>
              <div className="points">
                {t("portal.redeem.total-points-redeemed-value", "{{points}} PTS", {
                  points: panelist.redeemedRewardsPoints,
                })}
              </div>
            </div>
          </div>
          <div className="country-picker">
            <div className="label">{t("portal.redeem.select-your-country", "Select your country")}</div>
            <Select className="country-select" defaultValue={country} onChange={handleChangeCountry} loading={loading}>
              {Object.entries(COUNTRY_LOOKUP).map(([key, value]) =>
                !blockedCurrencies?.includes(value.CURRENCY) ? (
                  <Select.Option key={key}>{value.NAME}</Select.Option>
                ) : null
              )}
            </Select>
          </div>
        </div>
        <div className="featured">{t("portal.redeem.gift-cards-catalog", "Gift cards catalog")}</div>
        {loading ? (
          <div className="loading-spinner">
            <Loading size="large" />
          </div>
        ) : (
          <div className="gift-cards">
            {panelist.tenantForIncentives?.rewardCards?.map(
              card => card?.sku && <RewardCard key={card.sku} panelist={panelist} rewardCard={card as RewardCardType} />
            )}
          </div>
        )}
      </Styled>
    </PortalPageLayout>
  );
}

const Styled = styled.div`
  padding: 24px;
  max-width: 1192px;
  margin: auto;

  .heading {
    margin-top: 32px;

    .balance-details {
      display: flex;
      justify-content: space-between;

      .current-balance-details {
        font-size: 42px;
        font-weight: 500;
        line-height: 54px;

        .points {
          color: ${PRIMARY_TEXT_COLOR};
        }
      }

      .total {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;

        .points {
          color: ${SECONDARY_TEXT_COLOR};
          text-align: right;
        }
      }
    }

    @media only screen and (max-width: 798px) {
      .balance-details {
        .current-balance-details {
          font-size: 36px;
          line-height: 42px;
        }

        .total {
          margin-top: 2px;
        }
      }
    }

    @media only screen and (max-width: 776px) {
      .balance-details {
        display: block;

        .total {
          margin-top: 12px;
          .points {
            text-align: left;
          }
        }
      }
    }

    @media only screen and (max-width: 420px) {
      .balance-details {
        display: block;

        .current-balance-details {
          font-size: 24px;
          line-height: 36px;
        }

        .total {
          margin-top: 12px;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;

          .points {
            text-align: left;
          }
        }
      }
    }

    .country-picker {
      margin-top: 40px;

      .label {
        font-size: 12px;
        font-weight: 500;
        color: ${GRAY_10};
      }

      .country-select {
        margin-top: 8px;
        width: 170px;
      }
    }
  }

  .featured {
    margin-top: 34px;
    font-size: 22px;
  }

  @media only screen and (max-width: 420px) {
    .featured {
      margin-top: 22px;
      font-size: 18px;
    }
  }

  .gift-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -24px;
  }

  .loading-spinner {
    margin-top: 33%;
  }
`;

export default createFragmentContainer(PortalRedeem, {
  panelist: graphql`
    fragment PortalRedeem_panelist on PanelistNode {
      rewardsBalance
      convertedRewardsBalance
      redeemedRewardsPoints
      tenantForIncentives {
        blockedCurrencies
        rewardCards {
          sku
          programId
          maxAmount
          minAmount
          name
          faceplateUrl
        }
      }
      person {
        country
      }
      ...RewardCard_panelist
    }
  `,
});
