/**
 * @generated SignedSource<<368346a867cc2cd41b0e00d942774558>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputFilterGroupGroup_element$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroup_element">;
  readonly " $fragmentType": "InputFilterGroupGroup_element";
};
export type InputFilterGroupGroup_element$key = {
  readonly " $data"?: InputFilterGroupGroup_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilterGroupGroup_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilterGroup_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "512d7a07b7bfa54bf0f887004e2848a8";

export default node;
