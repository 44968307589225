import { theme } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import { useFlag } from "../../utils";
import type { ProjectParticipantsPage_study$data } from "../../__generated__/ProjectParticipantsPage_study.graphql";
import type { ProjectParticipantsPage_user$data } from "../../__generated__/ProjectParticipantsPage_user.graphql";
import { AlertVoxpopmeVideoAnalysis } from "../Project";
import ProjectPageContent from "../ProjectPageContent";

import ParticipantsTable from "./ParticipantsTable";

const { useToken } = theme;

const ProjectParticipantsPage = ({
  study,
  user,
}: {
  study: ProjectParticipantsPage_study$data;
  user: ProjectParticipantsPage_user$data;
}) => {
  const linkToVox = useFlag("hub-project-link-to-vpm-participants");

  const { token } = useToken();

  return (
    <ProjectPageContent style={{ padding: 24 }}>
      {!!linkToVox && (
        <AlertVoxpopmeVideoAnalysis
          respondent={null}
          study={study}
          style={{ marginBottom: token.marginMD }}
          user={user}
        />
      )}
      <ParticipantsTable study={study} user={user} />
    </ProjectPageContent>
  );
};

export default createFragmentContainer(ProjectParticipantsPage, {
  study: graphql`
    fragment ProjectParticipantsPage_study on StudyNode {
      ...AlertVoxpopmeVideoAnalysis_study
      ...ParticipantsTable_study @arguments(count: $count, elementIds: $elementIds)
      ...ParticipantsTableHeader_study
      ...ParticipantPaymentModal_study
      ...ScheduleModal_study
      ...BulkScheduleModal_study
      ...SlotPicker_study
    }
  `,
  user: graphql`
    fragment ProjectParticipantsPage_user on UserNode {
      ...AlertVoxpopmeVideoAnalysis_user
      ...ParticipantsTable_user
    }
  `,
});
