/**
 * @generated SignedSource<<4f3dfa90657f5f00167ca19d1002b753>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateStudyInput = {
  allowAutoApproval?: boolean | null;
  availabilityBuffer?: string | null;
  availabilitySlots?: ReadonlyArray<AvailabilitySlotInput | null> | null;
  claimLink?: string | null;
  clientMutationId?: string | null;
  defaultIncentive?: string | null;
  defaultIncentivePoints?: number | null;
  duration?: string | null;
  incentiveType?: string | null;
  inviteMeetingName?: string | null;
  inviteMeetingType?: string | null;
  inviteMessage?: string | null;
  inviteMessageCustomized?: boolean | null;
  languageCode?: string | null;
  launchPanelEmailSend?: boolean | null;
  meetingLink?: string | null;
  name?: string | null;
  namePublic?: string | null;
  panelFilters?: any | null;
  privacyPolicy?: string | null;
  rewardDescription?: string | null;
  scheduled?: any | null;
  scheduledBy?: string | null;
  schedulingLink?: string | null;
  schedulingType?: string | null;
  studyId: string;
  studySessions?: ReadonlyArray<StudySessionInput | null> | null;
  tags?: ReadonlyArray<TagCreateOrAttachInput> | null;
  type?: string | null;
  usesApiToMarkComplete?: boolean | null;
  voxpopmeProjectId?: number | null;
  voxpopmeRecorderEnabled?: boolean | null;
};
export type AvailabilitySlotInput = {
  availabilityBuffer?: number | null;
  duration?: number | null;
  end?: any | null;
  id?: string | null;
  inPerson?: boolean | null;
  interviewer?: string | null;
  meetingDetails?: string | null;
  meetingLink?: string | null;
  meetingLocation?: string | null;
  placesLimit?: number | null;
  start?: any | null;
};
export type StudySessionInput = {
  availabilitySlots?: ReadonlyArray<AvailabilitySlotInput | null> | null;
  duration?: number | null;
  id?: string | null;
  name?: string | null;
};
export type TagCreateOrAttachInput = {
  color?: string | null;
  id?: string | null;
  name?: string | null;
};
export type InviteInputMutation$variables = {
  input: UpdateStudyInput;
};
export type InviteInputMutation$data = {
  readonly updateStudy: {
    readonly study: {
      readonly id: string;
      readonly inviteMeetingNameInPerson: string;
      readonly inviteMeetingNameVirtual: string;
      readonly inviteMessageInPerson: string;
      readonly inviteMessageVirtual: string;
    } | null;
  } | null;
};
export type InviteInputMutation = {
  response: InviteInputMutation$data;
  variables: InviteInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateStudyPayload",
    "kind": "LinkedField",
    "name": "updateStudy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StudyNode",
        "kind": "LinkedField",
        "name": "study",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteMeetingNameVirtual",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteMessageVirtual",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteMeetingNameInPerson",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteMessageInPerson",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteInputMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteInputMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6f0b6faf9ddfa11c400c9543abd3f645",
    "id": null,
    "metadata": {},
    "name": "InviteInputMutation",
    "operationKind": "mutation",
    "text": "mutation InviteInputMutation(\n  $input: UpdateStudyInput!\n) {\n  updateStudy(input: $input) {\n    study {\n      id\n      inviteMeetingNameVirtual\n      inviteMessageVirtual\n      inviteMeetingNameInPerson\n      inviteMessageInPerson\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d695e7f18d160c6f1841edb88ee34e8";

export default node;
