import { Card, Divider, Radio, theme, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { uniqueId } from "lodash-es";
import { useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { IterableElement } from "type-fest";

import { ButtonLabel } from "..";
import { ThemeColor } from "../../antd";
import type { ScreenersFilterTypeChoices } from "../../schema";
import type { InputFilterGroup_element$key } from "../../__generated__/InputFilterGroup_element.graphql";
import type { InputFilterGroup_screener$key } from "../../__generated__/InputFilterGroup_screener.graphql";
import type { InputFilterGroup_tenant$key } from "../../__generated__/InputFilterGroup_tenant.graphql";

import { ButtonNot } from "./ButtonNot";
import { InputFilter } from "./InputFilter";
import {
  GroupOp,
  type ElementFilterGroupGroup,
  type PanelistFilterGroupGroup,
  type ParticipantFilterGroupGroup,
  type SegmentFilterGroupGroup,
} from "./types";

const { useToken } = theme;
const { Text } = Typography;

export const InputFilterGroup = <
  T extends IterableElement<
    (
      | ElementFilterGroupGroup
      | PanelistFilterGroupGroup
      | ParticipantFilterGroupGroup
      | SegmentFilterGroupGroup
    )["filters"]
  >
>({
  element: elementKey,
  onDelete,
  screener: screenerKey,
  setValue,
  tenant: tenantKey,
  type: _type,
  value,
}: {
  setValue: (v: T) => void;
  onDelete?: () => void;
  screener: InputFilterGroup_screener$key | null;
  tenant: InputFilterGroup_tenant$key | null;
  value: T;
} & (
  | {
      element?: null;
      type: ScreenersFilterTypeChoices | "segment";
    }
  | {
      element: InputFilterGroup_element$key;
      type: "element";
    }
)) => {
  type Filter = IterableElement<T["filters"]>;

  const element = useFragment(
    graphql`
      fragment InputFilterGroup_element on ElementNode {
        id
        ...InputFilter_element
      }
    `,
    _type === "element" ? elementKey : null
  );
  const screener = useFragment(
    graphql`
      fragment InputFilterGroup_screener on ScreenerNode {
        id
        ...InputFilter_screener
      }
    `,
    screenerKey
  );
  const tenant = useFragment(
    graphql`
      fragment InputFilterGroup_tenant on TenantNode {
        id
        ...InputFilter_tenant
      }
    `,
    tenantKey
  );

  const [filterGroup, _setFilterGroup] = useState<T>(value);
  const setFilterGroup = (x: typeof filterGroup) => {
    _setFilterGroup(x);
    setValue(x);
  };

  const removeFilter = (id: string | undefined) => {
    const filters = (filterGroup.filters as Filter[]).filter(o => o.id !== id);

    if (filters.length) {
      const x: typeof filterGroup = { ...filterGroup, filters };
      setFilterGroup(x);
      setValue(x);
    } else onDelete?.(); // remove group when no filters left
  };

  const { token } = useToken();

  return (
    <StyledCard
      $token={token}
      actions={[
        <ButtonLabel
          icon="mdi:plus"
          labelStyle={{ justifyContent: "start" }}
          onClick={() =>
            setFilterGroup({
              ...filterGroup,
              filters: [...filterGroup.filters, { id: uniqueId("filter") }],
            })
          }
          style={{ paddingInline: token.paddingSM, width: "100%" }}
          text="Add filter"
          type="link"
        />,
      ]}
      extra={
        !!onDelete && (
          <ButtonLabel
            onClick={() => onDelete()}
            icon="mdi:delete-outline"
            size="small"
            text="Delete group"
            type="link"
          />
        )
      }
      size="small"
      title={
        <>
          <ButtonNot filter={filterGroup} setFilter={setFilterGroup} size="small" />
          <Radio.Group
            buttonStyle="solid"
            onChange={e =>
              setFilterGroup({
                ...filterGroup,
                op: e.target.value,
              })
            }
            optionType="button"
            options={[GroupOp.And, GroupOp.Or]}
            size="small"
            value={filterGroup.op}
          />
        </>
      }
    >
      {filterGroup.filters.map((x, i) => (
        <div key={`container-${x.id}`}>
          {!!i && (
            <div style={{ marginInline: 0 - token.paddingSM, textTransform: "capitalize" }}>
              <Divider style={{ marginBottom: token.marginXS / 2 }}>
                <Text type="secondary">{filterGroup.op?.toLowerCase()}</Text>
              </Divider>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "end", marginBottom: token.marginXS }}>
            <ButtonLabel
              disabled={!onDelete || filterGroup.filters.length < 2}
              icon="mdi:delete-outline"
              onClick={() => removeFilter(x.id)}
              size="small"
              type="text"
            />
          </div>
          {/* @ts-expect-error Type of `element` in combination with `_type` loses fidelity due to useFragment call */}
          <InputFilter
            element={element}
            key={x.id}
            onChange={value =>
              setFilterGroup({
                ...filterGroup,
                filters: filterGroup.filters.map(y => (y.id === x.id ? value : y)),
              })
            }
            screener={screener}
            tenant={tenant}
            type={_type}
            value={x}
          />
        </div>
      ))}
    </StyledCard>
  );
};

const StyledCard = styled(Card)<{ $token: ReturnType<typeof useToken>["token"] }>`
  & > .ant-card-head {
    .ant-card-head-title {
      display: flex;
      gap: ${props => props.$token.marginXS}px;
    }

    .ant-card-extra {
      display: flex;
    }
  }

  & > .ant-card-body {
    border-top: 1px solid ${props => props.$token.colorBorder};
  }

  & > .ant-card-actions {
    &:hover {
      background-color: ${ThemeColor.VoxGrayHoverLighter};
    }

    & > li {
      margin: 0;

      & > span {
        display: flex;
      }
    }
  }
`;
