/**
 * @generated SignedSource<<46ddd49a83fdb3e3ed40e5cc214b7a4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputFilterGroup_tenant$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilter_tenant">;
  readonly " $fragmentType": "InputFilterGroup_tenant";
};
export type InputFilterGroup_tenant$key = {
  readonly " $data"?: InputFilterGroup_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroup_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilterGroup_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilter_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "ca8a0a13caab00c3b44b371755ef8579";

export default node;
