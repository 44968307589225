import { Icon } from "@iconify/react";
import { isEqualSimple } from "@react-hookz/deep-equal";
import { useUpdateEffect } from "@react-hookz/web";
import { Button } from "antd";
import classNames, { type Argument } from "classnames";
import { Fragment, useState, type CSSProperties } from "react";
import styled from "styled-components";

import { ButtonLabel } from "..";
import { MakeOptional } from "../../schema";
import { exceptIndex } from "../../utils/misc";

import { InputFilterStudyFilter, isValidFilter } from "./InputFilterStudyFilter";
import { type FilterStudy, type FilterStudyFilter } from "./types";

export const InputFilterStudy = ({
  className,
  onChange,
  style,
  value,
  tenant: tenantKey,
}: {
  className?: Argument;
  onChange: (x: FilterStudy) => void;
  style?: CSSProperties;
  value: MakeOptional<FilterStudy, "filters">;
} & Pick<Parameters<typeof InputFilterStudyFilter>[0], "tenant">) => {
  const [filters, setFilters] = useState<(FilterStudyFilter | undefined)[]>(value?.filters ?? []);

  useUpdateEffect(() => {
    if (!isEqualSimple(value?.filters, filters)) setFilters(value?.filters ?? []);
  }, [value]);
  useUpdateEffect(() => {
    if (filters.every(isValidFilter) && !isEqualSimple(filters, value?.filters)) onChange({ ...value, filters });
  }, [filters]);

  return (
    <StyledRoot className={classNames("hub-filter", className)} style={style}>
      {filters.map((x, i) => {
        return (
          <Fragment key={x?.id ?? `${i}`}>
            <Button
              className="hub-filter-action-remove"
              onClick={() => setFilters(exceptIndex(filters, i))}
              type="text"
            >
              <Icon height="1.2em" icon="mdi:close" />
            </Button>
            <InputFilterStudyFilter
              className="hub-filter-participant-filter"
              onChange={x => setFilters([...filters.slice(0, i), x, ...filters.slice(i + 1)])}
              value={x}
              tenant={tenantKey}
            />
          </Fragment>
        );
      })}
      <ButtonLabel
        className="hub-filter-action-add"
        icon="mdi:plus"
        onClick={() => setFilters([...filters, undefined])}
        text="Add a property"
        type="link"
      />
    </StyledRoot>
  );
};

const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;

  .hub-filter-action-remove {
    margin: 0 -4px;
    padding: 4px;
    display: flex;
    align-items: center;
  }

  .hub-filter-action-add {
    grid-column: span 2;
    margin: 0 -4px;
    padding: 4px;
  }
`;
