import React from "react";
import styled from "styled-components";

const Title: React.FC<{}> = ({ children }) => {
  return <Styled>{children}</Styled>;
};

const Styled = styled.div`
  color: #aaaaaa;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
`;

export default Title;
