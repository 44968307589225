/**
 * @generated SignedSource<<02f5499a1c137a096ca7a6bbb4ac12c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiChoiceElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "MultiChoiceElement_screener";
};
export type MultiChoiceElement_screener$key = {
  readonly " $data"?: MultiChoiceElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiChoiceElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultiChoiceElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "41007e4e9bb6cdcaf72a1a92585353c8";

export default node;
