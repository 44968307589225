/**
 * @generated SignedSource<<c889af191de2b07b226014aaa2650996>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InputFilter_tenant$data = {
  readonly characteristics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly answers: ReadonlyArray<{
          readonly dbId: string;
          readonly text: string;
        }>;
        readonly dbId: string;
        readonly rows: ReadonlyArray<{
          readonly dbId: string;
          readonly text: string;
        }>;
        readonly shortName: string;
        readonly type: ScreenersCharacteristicTypeChoices;
      } | null;
    } | null>;
  };
  readonly globalCharacteristics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly answers: ReadonlyArray<{
          readonly dbId: string;
          readonly text: string;
        }>;
        readonly dbId: string;
        readonly rows: ReadonlyArray<{
          readonly dbId: string;
          readonly text: string;
        }>;
        readonly shortName: string;
        readonly type: ScreenersCharacteristicTypeChoices;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterStudyFilter_tenant">;
  readonly " $fragmentType": "InputFilter_tenant";
};
export type InputFilter_tenant$key = {
  readonly " $data"?: InputFilter_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilter_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CharacteristicNode",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CharacteristicAnswerNode",
        "kind": "LinkedField",
        "name": "answers",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CharacteristicRowNode",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilter_tenant",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "CharacteristicNodeConnection",
      "kind": "LinkedField",
      "name": "characteristics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "characteristics(first:1000)"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "TenantGlobalCharacteristicsConnection",
      "kind": "LinkedField",
      "name": "globalCharacteristics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantGlobalCharacteristicsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "globalCharacteristics(first:1000)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilterStudyFilter_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};
})();

(node as any).hash = "0a191165c00fad7d31578298567da809";

export default node;
