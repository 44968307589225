/**
 * @generated SignedSource<<38207069c423d3d65b2096cdbfd4672f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponses_characteristic$data = {
  readonly type: ScreenersCharacteristicTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseDate_characteristic" | "InputCharacteristicResponseGridSingleSelect_characteristic" | "InputCharacteristicResponseMultiSelect_characteristic" | "InputCharacteristicResponseNumber_characteristic" | "InputCharacteristicResponseOpenEnd_characteristic" | "InputCharacteristicResponseSingleSelect_characteristic">;
  readonly " $fragmentType": "InputCharacteristicResponses_characteristic";
};
export type InputCharacteristicResponses_characteristic$key = {
  readonly " $data"?: InputCharacteristicResponses_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponses_characteristic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputCharacteristicResponses_characteristic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseDate_characteristic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseGridSingleSelect_characteristic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseMultiSelect_characteristic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseNumber_characteristic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseOpenEnd_characteristic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseSingleSelect_characteristic"
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};

(node as any).hash = "2dcacf11df86dbd66aed2e66c09449cc";

export default node;
