import type { FilterBase } from "./filter/base";

export const enum GroupOp {
  And = "AND",
  Or = "OR",
}

type FilterGroupBase<T> = {
  id: string;
  op: GroupOp;
  negate?: boolean;
  filters: T[];
};

export type FilterGroup<T extends FilterBase> = {
  id: string;
  op: GroupOp;
  negate?: boolean;
  filters: T[];
};

export type FilterGroupGroup<T extends FilterBase> = FilterGroupBase<FilterGroup<T>>;
