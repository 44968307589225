/**
 * @generated SignedSource<<87a411e512afe15d9dea8bb07f7e24b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateProject_viewer$data = {
  readonly user: {
    readonly profile: {
      readonly tenant: {
        readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_tenant">;
      };
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_viewer">;
  readonly " $fragmentType": "CreateProject_viewer";
};
export type CreateProject_viewer$key = {
  readonly " $data"?: CreateProject_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateProject_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateProject_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectDetailsPage_tenant"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "5424b76bede07cf48e4aedfebd255293";

export default node;
