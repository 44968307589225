/**
 * @generated SignedSource<<99922ad3663b80edbcf9f9ab3bdf0ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortalProfileComplete_panelist$data = {
  readonly customPanelistPortal: {
    readonly contactEmail: string | null;
    readonly customColor: string | null;
    readonly fadeBgBottom: boolean;
    readonly faviconUrl: string | null;
    readonly footerImageUrl: string | null;
    readonly headerMarkup: string;
    readonly headerMarkupEnabled: boolean;
    readonly heroAssetUrl: string | null;
    readonly heroBackgroundUrl: string | null;
    readonly hidePanelistProfile: boolean;
    readonly pageTitle: string | null;
    readonly privacyPolicyUrl: string | null;
    readonly termsLabel: string;
    readonly termsUrl: string | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "PortalProfileComplete_panelist";
};
export type PortalProfileComplete_panelist$key = {
  readonly " $data"?: PortalProfileComplete_panelist$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalProfileComplete_panelist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalProfileComplete_panelist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomPortalNode",
      "kind": "LinkedField",
      "name": "customPanelistPortal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fadeBgBottom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "faviconUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "footerImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headerMarkup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headerMarkupEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "heroAssetUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "heroBackgroundUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidePanelistProfile",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyPolicyUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "termsLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "termsUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "PanelistNode",
  "abstractKey": null
};

(node as any).hash = "c655cb2541058d4c0b33f72c893fdca8";

export default node;
