import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import styled from "styled-components";
import { DetailsInput } from "../Configure/DetailsInput";
import { FormInputs } from "./SlotsPage";

interface Props {
  form: FormInstance;
  saveInput: (name: FormInputs, value: any) => void;
}
const CalendlyForm: React.FC<Props> = ({ form, saveInput }) => {
  return (
    <Styled>
      <div className="constrained-container">
        <DetailsInput
          title="Calendly scheduling link"
          description={"Copy this from your Calendly account and paste here"}
          inputs={
            <Form.Item
              name="schedulingLink"
              style={{ marginBottom: 4 }}
              validateTrigger="onBlur"
              rules={[
                {
                  type: "url",
                  message: "Please enter a URL starting with https://",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="e.g. https://calendly.com/hubux/interviews"
                onBlur={e =>
                  form.validateFields().then(() => {
                    saveInput("schedulingLink", e.target.value);
                  })
                }
                style={{ width: 500 }}
              />
            </Form.Item>
          }
        />
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  text-align: center;

  .constrained-container {
    display: inline-flex;
    width: 100%;
  }
`;

export default CalendlyForm;
