/**
 * @generated SignedSource<<a27bba2adf5a3db308d01b065d6d5c41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ResponseValidationType = "AL" | "AM" | "BE" | "EX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseNumber_characteristic$data = {
  readonly elementMeta: {
    readonly responseValidation: ResponseValidationType | null;
    readonly target: number | null;
    readonly target2: number | null;
  };
  readonly id: string;
  readonly " $fragmentType": "InputCharacteristicResponseNumber_characteristic";
};
export type InputCharacteristicResponseNumber_characteristic$key = {
  readonly " $data"?: InputCharacteristicResponseNumber_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseNumber_characteristic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputCharacteristicResponseNumber_characteristic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicElementMetaNode",
      "kind": "LinkedField",
      "name": "elementMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "responseValidation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "target",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "target2",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};

(node as any).hash = "78000d865ab37fa302a398fce3da965c";

export default node;
