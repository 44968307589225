/**
 * @generated SignedSource<<111c81763136f867d2812a2ea77882c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SingleChoiceElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "SingleChoiceElement_screener";
};
export type SingleChoiceElement_screener$key = {
  readonly " $data"?: SingleChoiceElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"SingleChoiceElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SingleChoiceElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "a8331cd36aef2c18f4089085afd8ff7a";

export default node;
