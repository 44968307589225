import { LoadingOutlined } from "@ant-design/icons";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { createPaginationContainer, RelayPaginationProp } from "react-relay";
import styled from "styled-components";
import { HEADER_HEIGHT, PROJECTS_COLUMN_HEADER_HEIGHT } from "../../style";
import { ProjectsColumnPagination_viewer$data } from "../../__generated__/ProjectsColumnPagination_viewer.graphql";
import ProjectCard from "./ProjectCard";

function ProjectsColumnPagination({
  viewer,
  relay,
  setCount,
}: {
  filterTagDids: string[];
  orderBy: string;
  projectStatus: string;
  relay: RelayPaginationProp;
  searchTerm: string;
  setCount?: (count: number | undefined) => void;
  viewer: ProjectsColumnPagination_viewer$data;
}) {
  // used to determine height of projectCardsDiv
  const [projectCardsHeight, setProjectCardsHeight] = useState(0);
  const projectCardsRef = useRef<HTMLDivElement>(null);
  const refHeight = projectCardsRef?.current?.offsetHeight;

  // set projectCardsHeight when projectCards height changes
  useEffect(() => {
    if (projectCardsRef.current) {
      setProjectCardsHeight(projectCardsRef.current.offsetHeight);
    }
  }, [refHeight]);

  // The InfiniteScroll relies on it having an exact height, which reaches to the bottom of the viewport.
  // If projectCards is smaller than the InfiniteScroll height, load more
  useEffect(() => {
    const projectCardsTooSmall =
      window.innerHeight - (HEADER_HEIGHT + PROJECTS_COLUMN_HEADER_HEIGHT) > projectCardsHeight;
    if (relay.hasMore() && projectCardsTooSmall) {
      relay.loadMore(5);
    }
  }, [projectCardsHeight, relay]);

  useEffect(() => {
    setCount?.(viewer?.studies?.edges?.length);
  }, [setCount, viewer?.studies?.edges?.length]);

  return (
    <Styled>
      {viewer?.studies?.edges && (
        <InfiniteScroll
          height={`calc(100vh - ${HEADER_HEIGHT + PROJECTS_COLUMN_HEADER_HEIGHT + 64}px)`}
          next={() => {
            return relay.loadMore(5);
          }}
          hasMore={relay.hasMore()}
          loader={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          dataLength={viewer?.studies?.edges?.length || 0}
        >
          <div ref={projectCardsRef}>
            {viewer.studies.edges.map(
              edge => edge?.node && <ProjectCard key={edge.node.dId as string} study={edge.node} />
            )}
          </div>
        </InfiniteScroll>
      )}
    </Styled>
  );
}

const Styled = styled.div`
  .infinite-scroll-component {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;

export default createPaginationContainer(
  ProjectsColumnPagination,
  {
    viewer: graphql`
      fragment ProjectsColumnPagination_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 7 }
        cursor: { type: "String" }
        filterTagDids: { type: "[ID!]" }
        orderBy: { type: "String", defaultValue: "-modified" }
        searchTerm: { type: "String" }
        status: { type: "ScreenersStudyStatusChoices" }
      ) {
        studies(
          after: $cursor
          first: $count
          orderBy: $orderBy
          status: $status
          searchTerm: $searchTerm
          tags_In: $filterTagDids
        ) @connection(key: "Studies_studies") {
          edges {
            node {
              id
              dId
              ...ProjectCard_study
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.studies;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
        orderBy: prevVars.orderBy,
        searchTerm: prevVars.searchTerm,
        status: prevVars.status,
        filterTagDids: prevVars.filterTagDids,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: props.orderBy,
        status: props.projectStatus,
        searchTerm: props.searchTerm,
        filterTagDids: props.filterTagDids.length ? props.filterTagDids : null,
      };
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query ProjectsColumnPaginationQuery(
        $count: Int!
        $cursor: String
        $orderBy: String
        $searchTerm: String
        $status: ScreenersStudyStatusChoices
        $filterTagDids: [ID!]
      ) {
        viewer {
          ...ProjectsColumnPagination_viewer
            @arguments(
              count: $count
              cursor: $cursor
              orderBy: $orderBy
              searchTerm: $searchTerm
              status: $status
              filterTagDids: $filterTagDids
            )
        }
      }
    `,
  }
);
