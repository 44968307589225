import outlineArchive from "@iconify-icons/ic/outline-archive";
import outlineDone from "@iconify-icons/ic/outline-done";
import outlineRestore from "@iconify-icons/ic/outline-restore";
import { App, Divider, Form, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { pick } from "lodash-es";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { AutomatedEmail, AUTOMATED_EMAIL_TYPE, EmailTemplateContent, PROJECT_TYPES } from "../../constants";
import { GRAY_5 } from "../../style";
import { flattenEdges, mutation } from "../../utils";
import { IncentiveEmailSettings_study$data } from "../../__generated__/IncentiveEmailSettings_study.graphql";
import { AutomatedEmailInputs } from "../Communication/AutomatedEmails/AutomatedEmailInputs";
import { getAutomatedEmailMeta } from "../Communication/AutomatedEmails/types";
import { SaveTemplateModal } from "../Communication/Templates/SaveTemplateModal";
import { SelectTemplateModal } from "../Communication/Templates/SelectTemplateModal";
import { IconButton } from "../IconButton";

const { Title } = Typography;

type Props = {
  study: IncentiveEmailSettings_study$data;
};
const _IncentiveEmailSettings = ({ study }: Props) => {
  const { notification } = App.useApp();
  const { router } = useRouter();
  const [form] = Form.useForm();
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);

  const handleSave = async () => {
    try {
      const input = { studyId: study.id, type: AUTOMATED_EMAIL_TYPE.REWARDED, ...form.getFieldsValue() };

      await mutation({
        variables: { input },
        mutation: graphql`
          mutation IncentiveEmailSettings_saveAutomatedEmail_Mutation($input: SaveAutomatedEmailInput!) {
            saveAutomatedEmail(input: $input) {
              study {
                ...AutomatedEmailsPage_study
              }
            }
          }
        `,
      });
      notification.success({
        message: "Successfully saved automated email",
        description: input.enabled ? (
          <p>
            Respondents will be notified when they are assigned a status of{" "}
            <em>{getAutomatedEmailMeta(AUTOMATED_EMAIL_TYPE.REWARDED)!.description}</em>.
          </p>
        ) : (
          <p>
            You can continue to make edits as needed, but respondents won't receive this automated email until it's set
            to Enabled.
          </p>
        ),
      });
      doRedirect();
    } catch (ex) {
      notification.error({ message: "Error saving automated email" });
      throw ex;
    }
  };

  const doRedirect = () => {
    if (study.type === PROJECT_TYPES.SURVEY) {
      router.push(`/projects/${study.id}/screener`);
    } else {
      // TODO: Change to Slots view
      router.push(`/projects/${study.id}/slots`);
    }
  };

  const handleTemplateSelected = (emailTemplate: EmailTemplateContent) => {
    const enabled = !!form.getFieldValue("enabled");
    form.resetFields();
    form.setFieldsValue({
      enabled,
      ...emailTemplate,
    });
  };

  const defaults: AutomatedEmail = {
    studyId: study.id,
    type: AUTOMATED_EMAIL_TYPE.REWARDED,
    enabled: true,
    replyTo: "",
    subject: "",
    message: "",
    ctaLabel: null,
    ctaUrl: null,
    ...study.externalIncentive?.automatedEmail,
    assets: study.externalIncentive?.automatedEmail?.assets
      ? flattenEdges(study.externalIncentive.automatedEmail.assets)
      : [],
  };

  return (
    <Styled>
      <SelectTemplateModal
        tenant={study.tenant}
        visible={showSelectTemplateModal}
        setVisible={setShowSelectTemplateModal}
        title="Select a template"
        onTemplateSelected={handleTemplateSelected}
      />
      <SaveTemplateModal
        emailTemplate={pick(form.getFieldsValue(), ["replyTo", "subject", "message"]) as EmailTemplateContent}
        tenant={study.tenant}
        visible={showSaveTemplateModal}
        setVisible={setShowSaveTemplateModal}
      />
      <Title level={2} className="incentive-email-setup-title">
        Compose incentive email
      </Title>
      <p>This email will be sent to participants when you incentivize them.</p>
      <Form form={form} initialValues={defaults} onFinish={handleSave}>
        {!!study.tenant.emailTemplates?.edges.length && (
          <>
            <IconButton icon={outlineArchive} type="ghost" onClick={() => setShowSelectTemplateModal(true)}>
              Use a template
            </IconButton>
            <Divider />
          </>
        )}

        <AutomatedEmailInputs
          form={form}
          automatedEmail={defaults}
          showEnabled={false}
          defaultCtaUrl={study.externalIncentive?.claimLink ?? undefined}
        />

        <div className="automated-email-buttons">
          <IconButton icon={outlineRestore} type="link" onClick={() => form.resetFields()}>
            Restore to default
          </IconButton>
          <IconButton
            className="save-as-template"
            icon={outlineArchive}
            type="link"
            onClick={() => setShowSaveTemplateModal(true)}
          >
            Save as template
          </IconButton>
        </div>

        <div className="automated-email-primary-buttons">
          <IconButton type="ghost" onClick={doRedirect} style={{ backgroundColor: "white" }}>
            Do this later
          </IconButton>
          <IconButton icon={outlineDone} type="primary" htmlType="submit">
            Save changes
          </IconButton>
        </div>
      </Form>
    </Styled>
  );
};

const Styled = styled.div`
  .incentive-email-setup-title {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }

  .automated-email-buttons {
    display: flex;
    gap: 10px;

    .save-as-template {
      border: 1px solid ${GRAY_5};
    }
  }

  .automated-email-primary-buttons {
    position: sticky;
    padding: 20px;
    background-color: white;
    bottom: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;

export const IncentiveEmailSettings = createFragmentContainer(_IncentiveEmailSettings, {
  study: graphql`
    fragment IncentiveEmailSettings_study on StudyNode {
      id
      type
      incentiveType
      externalIncentive {
        claimLink
        automatedEmail {
          replyTo
          subject
          message
          ctaLabel
          ctaUrl
          assets {
            edges {
              node {
                id
                name
                sizeBytes
                url
              }
            }
          }
        }
      }
      tenant {
        emailTemplates {
          edges {
            node {
              id
            }
          }
        }
        ...SaveTemplateModal_tenant
        ...SelectTemplateModal_tenant
      }
      ...AutomatedEmailEditor_study
    }
  `,
});
