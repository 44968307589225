import { Table, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { graphql } from "babel-plugin-relay/macro";
import { round } from "lodash-es";
import moment, { Moment } from "moment";
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { createPaginationContainer, RelayPaginationProp } from "react-relay";
import styled from "styled-components";

import { PROJECT_INCENTIVE_TYPES, PROJECT_STATUSES, recruitTypeMap, RECRUIT_STATUSES } from "../../constants";
import { ScreenersRecruitTypeChoices, type RecruitNode } from "../../schema";
import { GRAY_4, GRAY_8 } from "../../style";
import { TimeZoneContext } from "../../utils";
import { usdFormatter } from "../../utils/misc";
import { BillingTable_tenant$data } from "../../__generated__/BillingTable_tenant.graphql";
import { BillingTable_viewer$data } from "../../__generated__/BillingTable_viewer.graphql";

type ReportRow = {
  rowType: "your-panel" | "panelist-bonus" | "study" | "recruit" | "recruit-gap" | "bonus-points";
  id: string;
  name: ReactNode | undefined;
  projectManager?: string;
  roundType?: NonNullable<RecruitNode["type"]>;
  dateFinished: "LV" | "S" | Date | undefined;
  incentivizedParticipants: number | undefined;
  incentive?: number;
  fee?: number;
  costPerParticipant?: number;
  paid?: number;
  refund?: number;
  totalSpending: number | undefined;
  hasParticipantsWithoutRounds?: boolean;
};

const PAGE_SIZE = 10;

const BillingTable = ({
  viewer,
  tenant,
  relay,
}: {
  dateRange: [Moment, Moment] | undefined;
  viewer: BillingTable_viewer$data;
  tenant: BillingTable_tenant$data;
  relay: RelayPaginationProp;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const yourPanel = useMemo(() => getYourPanel(tenant), [tenant]);
  const data = useMemo(() => [...yourPanel, ...flattenResults(viewer, tenant)], [yourPanel, viewer, tenant]);

  const loadNextPage = () => {
    setIsLoading(true);
    relay.loadMore(PAGE_SIZE, () => setIsLoading(false));
  };

  // if we have enough data, ensure the full vertical space is filled (otherwise infinite scroll breaks because
  // scrolling can't trigger)
  useEffect(() => {
    if (
      tableRef.current &&
      tableRef.current.offsetTop + tableRef.current.offsetHeight < window.innerHeight * 1.1 &&
      relay.hasMore()
    ) {
      loadNextPage();
    }
  });

  const formatUsd = (value?: number): ReactNode => (typeof value === "number" ? usdFormatter.format(value) : null);

  const fixedColumnWidth = 120;

  // add columns for prepay tenants
  const prepayColumns: ColumnType<any>[] | null = tenant.requireStudyPayment
    ? [
        { title: "Paid", dataIndex: "paid", align: "right", width: fixedColumnWidth, render: formatUsd },
        { title: "Refund", dataIndex: "refund", align: "right", width: fixedColumnWidth, render: formatUsd },
      ]
    : null;

  const { shiftDate, timeZoneOffsetDifference } = useContext(TimeZoneContext);

  const columns = useMemo(() => {
    const x: ColumnType<any>[] = [
      {
        title: "Project",
        dataIndex: "name",
      },
      {
        title: "Project Manager",
        dataIndex: "projectManager",
        align: "right",
        width: fixedColumnWidth,
      },
      {
        title: "Round",
        dataIndex: "roundType",
        width: fixedColumnWidth,
        render: (roundType: string, record: any) =>
          (record.rowType === "study" && roundType === PROJECT_STATUSES.LIVE) ||
          (record.rowType === "recruit" && roundType === RECRUIT_STATUSES.STARTED) ? (
            <span className="active">LIVE</span>
          ) : record.rowType === "recruit" && roundType ? (
            recruitTypeMap[roundType as ScreenersRecruitTypeChoices]
          ) : null,
      },
      {
        title: "Date Finished",
        dataIndex: "dateFinished",
        align: "right",
        width: fixedColumnWidth,
        render: (dateFinishedOrStatus?: Date | "LV" | "S") =>
          dateFinishedOrStatus ? (
            typeof dateFinishedOrStatus === "string" &&
            [PROJECT_STATUSES.LIVE, RECRUIT_STATUSES.STARTED].includes(dateFinishedOrStatus) ? (
              <span className="active">LIVE</span>
            ) : (
              shiftDate(moment(dateFinishedOrStatus)).format("MMM D, YYYY")
            )
          ) : null,
      },
      {
        title: "Incentivized Participants",
        dataIndex: "incentivizedParticipants",
        align: "right",
        width: fixedColumnWidth,
      },
      {
        title: "Base Cost",
        dataIndex: "incentive",
        align: "right",
        width: fixedColumnWidth,
        render: formatUsd,
      },
      {
        title: "Fee",
        dataIndex: "fee",
        align: "right",
        width: fixedColumnWidth,
        render: formatUsd,
      },
      {
        title: "Cost Per Participant",
        dataIndex: "costPerParticipant",
        align: "right",
        width: fixedColumnWidth,
        render: formatUsd,
      },
      // add prepay columns if needed
      ...(prepayColumns ?? []),
      {
        title: "Total Spending",
        dataIndex: "totalSpending",
        align: "right",
        width: fixedColumnWidth,
        render: (value, record) =>
          record.hasParticipantsWithoutRounds ? (
            <Tooltip title='"Total Spending" is based on participants paid in recruiting rounds. Incentives to participants who were not members of recruiting rounds are not included in this total.'>
              {formatUsd(value)}*
            </Tooltip>
          ) : (
            formatUsd(value)
          ),
      },
    ];

    return x;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepayColumns, shiftDate, timeZoneOffsetDifference]);

  return (
    <InfiniteScroll dataLength={data.length} next={loadNextPage} hasMore={relay.hasMore()} loader={null}>
      <StyledTable
        ref={tableRef}
        className="hub-billing-table-inner"
        columns={columns}
        dataSource={data}
        rowKey="id"
        rowClassName={(record: any) => record.rowType}
        pagination={false}
        loading={isLoading}
      />
    </InfiniteScroll>
  );
};
const StyledTable = styled(Table)`
  thead th {
    color: ${GRAY_8};
    font-weight: 500;
  }

  tr.study,
  tr.your-panel {
    background-color: ${GRAY_4};
    font-weight: 500;
  }

  tr.recruit,
  tr.recruit-gap {
    font-size: small;
    font-weight: 300;
  }

  .active {
    color: var(--ant-success-color);
    font-weight: 500;
  }
`;

const getYourPanel = (tenant: BillingTable_tenant$data) => [
  ...(!!tenant.panelistBonuses?.edges?.length
    ? [
        // "Your panel" heading
        {
          rowType: "your-panel",
          id: "your-panel",
          name: "Your panel",
          dateFinished: undefined,
          incentivizedParticipants: tenant.panelistBonuses.edges.length,
          totalSpending: tenant.panelistBonuses.edges.reduce(
            (acc, panelistBonusOrder) =>
              acc +
              parseFloat(panelistBonusOrder!.node!.givePointsPayment!.pointsCost) +
              parseFloat(panelistBonusOrder!.node!.givePointsPayment!.pointsFees),
            0
          ),
        } as ReportRow,

        // individual panelist bonuses
        ...tenant.panelistBonuses.edges.map<ReportRow>(panelistBonusOrder => ({
          rowType: "panelist-bonus",
          id: panelistBonusOrder!.node!.id,
          name: `${panelistBonusOrder!.node!.panelist!.person!.firstName} ${
            panelistBonusOrder!.node!.panelist!.person!.lastName![0]
          }.`,
          dateFinished: panelistBonusOrder!.node!.created,
          incentivizedParticipants: 1,
          incentive: parseFloat(panelistBonusOrder!.node!.givePointsPayment!.pointsCost),
          fee: parseFloat(panelistBonusOrder!.node!.givePointsPayment!.pointsFees),
          totalSpending:
            parseFloat(panelistBonusOrder!.node!.givePointsPayment!.pointsCost) +
            parseFloat(panelistBonusOrder!.node!.givePointsPayment!.pointsFees),
        })),
      ]
    : []),
];

const flattenResults = (viewer: BillingTable_viewer$data, tenant: BillingTable_tenant$data): ReportRow[] =>
  viewer.studies!.edges.flatMap<ReportRow>(studyEdge => {
    // in older studies, participation didn't track rounds; here we add a row per study if the incentivized count for
    // the study exceeds the sum of all the rows
    const rewardedInRoundsCount = studyEdge!.node!.recruits.edges.reduce(
      (acc, cur) => acc + cur!.node!.rewardedCount!,
      0
    );

    const hasParticipantsWithoutRounds = studyEdge!.node!.rewardedCount! > rewardedInRoundsCount;

    return [
      // add a study row
      {
        rowType: "study",
        id: studyEdge!.node!.id,
        name: studyEdge!.node!.name ?? "<Unnamed project>",
        projectManager: studyEdge!.node!.owner!.fullName!,
        dateFinished:
          studyEdge!.node!.status === PROJECT_STATUSES.LIVE ? PROJECT_STATUSES.LIVE : studyEdge!.node!.completedOn,
        incentivizedParticipants: studyEdge!.node!.rewardedCount ?? undefined,
        paid: Number(studyEdge!.node!.totalDeposits),
        refund: studyEdge!.node!.recruits.edges.reduce(
          (acc, cur) => acc + (cur!.node!.status === RECRUIT_STATUSES.FINISHED ? Number(cur!.node!.totalRefunds) : 0),
          0
        ),
        totalSpending:
          studyEdge!.node!.recruits.edges.reduce(
            (acc, cur) => acc + (cur!.node!.status === RECRUIT_STATUSES.FINISHED ? Number(cur!.node!.totalPayouts) : 0),
            0
          ) +
          studyEdge!.node!.givePointsPayments.edges.reduce(
            (acc, cur) => acc + parseFloat(cur!.node!.pointsCost) + parseFloat(cur!.node!.pointsFees),
            0
          ),
        hasParticipantsWithoutRounds,
      },

      // add a row for incentivized participants with no round on legacy studies
      ...((hasParticipantsWithoutRounds
        ? [
            {
              rowType: "recruit-gap",
              id: `${studyEdge!.node!.id}-gap`,
              name: <em>(no round)</em>,
              incentivizedParticipants: studyEdge!.node!.rewardedCount! - rewardedInRoundsCount,
            },
          ]
        : []) as ReportRow[]),

      // add rows for each recruit
      ...studyEdge!.node!.recruits.edges.map<ReportRow>((recruitEdge, i) => {
        const baseCostPerParticipant =
          studyEdge!.node!.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL
            ? tenant.externalIncentivePerParticipantCostUsdCents / 100
            : Number(recruitEdge!.node!.incentive);

        return {
          rowType: "recruit",
          id: recruitEdge!.node!.id,
          name: recruitEdge!.node!.name ?? `Round ${i + 1}`,
          roundType: recruitEdge!.node!.type ?? undefined,
          dateFinished:
            recruitEdge!.node!.status === RECRUIT_STATUSES.STARTED
              ? RECRUIT_STATUSES.STARTED
              : recruitEdge!.node!.finishedOn,
          incentivizedParticipants: recruitEdge!.node!.rewardedCount ?? undefined,
          incentive: baseCostPerParticipant,
          fee: Number(recruitEdge!.node!.fee),
          costPerParticipant: baseCostPerParticipant + Number(recruitEdge!.node!.fee),
          paid: Number(recruitEdge!.node!.totalDeposits),
          refund:
            recruitEdge!.node!.status === RECRUIT_STATUSES.FINISHED
              ? Number(recruitEdge!.node!.totalRefunds)
              : undefined,
          totalSpending:
            recruitEdge!.node!.status === RECRUIT_STATUSES.FINISHED
              ? Number(recruitEdge!.node!.totalPayouts)
              : undefined,
        };
      }),

      // add rows for points bonuses
      ...studyEdge!.node!.givePointsPayments.edges.map<ReportRow>((givePointsPaymentEdge, i) => ({
        rowType: "bonus-points",
        id: givePointsPaymentEdge!.node!.id,
        name: givePointsPaymentEdge!.node!.description,
        dateFinished: givePointsPaymentEdge!.node!.created,
        incentive: parseFloat(givePointsPaymentEdge!.node!.pointsCost),
        incentivizedParticipants: givePointsPaymentEdge!.node!.participantCount,
        fee: parseFloat(givePointsPaymentEdge!.node!.pointsFees),
        costPerParticipant: round(
          parseFloat(givePointsPaymentEdge!.node!.pointsCost) + parseFloat(givePointsPaymentEdge!.node!.pointsFees),
          2
        ),
        totalSpending:
          (parseFloat(givePointsPaymentEdge!.node!.pointsCost) + parseFloat(givePointsPaymentEdge!.node!.pointsFees)) *
          givePointsPaymentEdge!.node!.participantCount,
      })),
    ];
  });

export default createPaginationContainer(
  BillingTable,
  {
    viewer: graphql`
      fragment BillingTable_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        range: { type: "String" }
      ) {
        studies(first: $count, after: $cursor, status_In: [LV, CM], completedOnRange: $range, orderBy: "-modified")
          @connection(key: "Studies_studies") {
          edges {
            node {
              id
              name
              incentiveType
              status
              owner {
                fullName
              }
              completedOn
              rewardedCount
              totalDeposits
              recruits {
                edges {
                  node {
                    id
                    name
                    type
                    status
                    finishedOn
                    rewardedCount
                    incentive
                    fee
                    totalDeposits
                    totalRefunds
                    totalPayouts
                  }
                }
              }
              givePointsPayments {
                edges {
                  node {
                    id
                    description
                    created
                    participantCount
                    points
                    pointsCost
                    pointsFees
                  }
                }
              }
            }
          }
        }
      }
    `,
    tenant: graphql`
      fragment BillingTable_tenant on TenantNode @argumentDefinitions(placedRange: { type: "[DateTime]" }) {
        requireStudyPayment
        externalIncentivePerParticipantCostUsdCents
        panelistBonuses(placedRange: $placedRange) {
          edges {
            node {
              id
              created
              incentivePoints
              givePointsPayment {
                pointsCost
                pointsFees
              }
              panelist {
                person {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getFragmentVariables: (prevVars, totalCount) => ({ ...prevVars, count: totalCount }),
    getVariables: (props, { count, cursor }, fragmentVariables) => ({
      count,
      cursor,
      range: JSON.stringify(props.dateRange),
    }),
    query: graphql`
      query BillingTablePaginationQuery($count: Int!, $cursor: String, $range: String) {
        viewer {
          ...BillingTable_viewer @arguments(count: $count, cursor: $cursor, range: $range)
        }
      }
    `,
  }
);
