/**
 * @generated SignedSource<<f58e4e0cf290ab4887973e92f2a67922>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OptionTerminate_answer$data = {
  readonly terminate: boolean;
  readonly terminateCondition: any | null;
  readonly " $fragmentType": "OptionTerminate_answer";
};
export type OptionTerminate_answer$key = {
  readonly " $data"?: OptionTerminate_answer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OptionTerminate_answer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OptionTerminate_answer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminateCondition",
      "storageKey": null
    }
  ],
  "type": "AnswerNode",
  "abstractKey": null
};

(node as any).hash = "8f9359f840a86f3b1ba12a7dd4259e94";

export default node;
