/**
 * @generated SignedSource<<5e1f0fc9fe35d45d920133e413f4e1a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseNumber_characteristicResponses$data = ReadonlyArray<{
  readonly id: string;
  readonly textValue: string;
  readonly " $fragmentType": "InputCharacteristicResponseNumber_characteristicResponses";
}>;
export type InputCharacteristicResponseNumber_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponseNumber_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseNumber_characteristicResponses">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponseNumber_characteristicResponses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textValue",
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};

(node as any).hash = "1b40670d161b6d042a589d535cd533ae";

export default node;
