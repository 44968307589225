import { ExportOutlined } from "@ant-design/icons";
import { App, Button, Card, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import moment, { Moment } from "moment";
import { useState } from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import styled from "styled-components";

import { InputDateRange, LoadingPage } from "..";
import { environment } from "../../relay";
import { HEADER_FONT_SIZE, HEADER_FONT_WEIGHT, PRIMARY_TEXT_COLOR } from "../../style";
import { getTenantContext, getUserContext, handleErrorWithMessage, trackEvent, useFlag } from "../../utils";
import { downloadInBackground, getApiBaseUrl, managePaymentMethod } from "../../utils/misc";
import type { BillingPage_user$data } from "../../__generated__/BillingPage_user.graphql";
import { notifyExport } from "../Notifications/Notification";

import BillingTable from "./BillingTable";

const { Text } = Typography;

const BillingPage = ({ user }: { user: BillingPage_user$data }) => {
  const { match } = useRouter();
  const { notification } = App.useApp();
  const [dateRange, setDateRange] = useState<[Moment, Moment] | undefined>();

  return (
    <Styled>
      <header>
        <h1>Billing</h1>
      </header>
      {useFlag("hub-recruit-prepay") && (
        <div className="hub-billing-payment">
          <Button type="primary" size="large" onClick={async () => await managePaymentMethod(match.location.pathname)}>
            {user.profile?.tenant?.stripePaymentMethodId ? "Manage Payment Method" : "Add Payment Method"}
          </Button>
          <p>
            <Text type="secondary">
              Add / edit a payment method for project costs. You will be redirected to our payment partner, Stripe.
            </Text>
          </p>
        </div>
      )}
      <Card
        bodyStyle={{ overflow: "hidden", padding: 0, zIndex: -1 }}
        extra={
          <>
            <InputDateRange
              onChange={(dates: any) =>
                setDateRange(
                  dates?.length === 2 ? [moment(dates[0]).startOf("day"), moment(dates[1]).endOf("day")] : undefined
                )
              }
              placeholder={["All time", "Today"]}
              renderExtraFooter={() => (
                <>
                  <CustomRangeButton
                    size="small"
                    onClick={() => setDateRange([moment().startOf("day"), moment().endOf("day")])}
                  >
                    Today
                  </CustomRangeButton>
                  <CustomRangeButton
                    size="small"
                    onClick={() => setDateRange([moment().startOf("month"), moment().endOf("month")])}
                  >
                    This Month
                  </CustomRangeButton>
                  <CustomRangeButton
                    size="small"
                    onClick={() =>
                      setDateRange([
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ])
                    }
                  >
                    Last Month
                  </CustomRangeButton>
                </>
              )}
              value={dateRange}
            />
            <Button
              icon={<ExportOutlined />}
              type="text"
              onClick={async () => {
                try {
                  await downloadInBackground(
                    `${getApiBaseUrl()}/billingSummary`,
                    `billingSummary-${user.profile!.tenant!.name.replace(/\W/g, "")}.csv`
                  );
                  notifyExport(notification);
                  trackEvent("Export Billing Summary", {
                    ...getUserContext(user as any),
                    ...getTenantContext(user.profile?.tenant as any),
                  });
                } catch (err) {
                  handleErrorWithMessage(err);
                }
              }}
              style={{ marginLeft: 8 }}
            >
              Export
            </Button>
          </>
        }
        headStyle={{ paddingLeft: 16, paddingRight: 8, position: "relative", zIndex: 1 }}
        title="Billing History"
      >
        <QueryRenderer
          environment={environment}
          query={graphql`
            query BillingPage_Query($range: String, $placedRange: [DateTime]) {
              viewer {
                ...BillingTable_viewer @arguments(range: $range)
                user {
                  profile {
                    tenant {
                      ...BillingTable_tenant @arguments(placedRange: $placedRange)
                    }
                  }
                }
              }
            }
          `}
          render={({ props, error }: any) =>
            error ? (
              <p>Error loading billing table</p>
            ) : props?.viewer?.user?.profile?.tenant ? (
              <BillingTable dateRange={dateRange} viewer={props.viewer} tenant={props.viewer.user.profile.tenant} />
            ) : (
              <LoadingPage />
            )
          }
          variables={{ range: JSON.stringify(dateRange), placedRange: dateRange }}
        />
      </Card>
    </Styled>
  );
};

const Styled = styled.div`
  padding: 32px 24px 48px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  & > header h1 {
    margin: 0;
    font-size: ${HEADER_FONT_SIZE};
    font-weight: ${HEADER_FONT_WEIGHT};
    color: ${PRIMARY_TEXT_COLOR};
  }

  .hub-billing-payment p {
    margin-block: 8px 0;
    max-width: 65ch;
  }

  .hub-billing-table-inner {
    table,
    .ant-table-cell,
    .ant-table-header {
      border-radius: 0 !important;
    }

    .ant-table-tbody > tr:last-child > td {
      border-bottom: 0 !important;
    }
  }
`;

const CustomRangeButton = styled(Button)`
  border: 1px solid blue;
  background-color: #f0f6ff;
  color: var(--ant-primary-color);
  margin-right: 6px;
`;

export default createFragmentContainer(BillingPage, {
  user: graphql`
    fragment BillingPage_user on UserNode {
      dId
      profile {
        tenant {
          name
          dId
          stripePaymentMethodId
          vpmAccountId
          name
        }
      }
      dId
      vpmUserId
      panelist {
        dId
      }
    }
  `,
});
