import * as FullStory from "@fullstory/browser";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { basicLogger } from "launchdarkly-js-client-sdk";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import momentTz from "moment-timezone";
import ReactDOM from "react-dom";

import { Root } from "./components/Root";
import { MIXPANEL_LOCAL_TESTING_TOKEN } from "./constants";
import "./i18n";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { CONTEXT_KEY_SHARED } from "./utils";
import { init as intercomInit, shutdown as intercomShutdown } from "./utils/intercom";
import { hasConsentedToCookies } from "./utils/misc";
import { mount as pendoMount } from "./utils/pendo";

// @ts-expect-error DefinitelyTyped definitions are written for CommonJS
momentDurationFormatSetup(moment);
// @ts-expect-error DefinitelyTyped definitions are written for CommonJS
momentDurationFormatSetup(momentTz);

const env = runtimeEnv();

// use prod token if it exists, otherwise use env var token, otherwise use local testing token from ./constants
const MIXPANEL_TOKEN =
  env.REACT_APP_ENVIRONMENT === "production" && env.REACT_APP_MIXPANEL_TOKEN_PRODUCTION
    ? env.REACT_APP_MIXPANEL_TOKEN_PRODUCTION
    : env.REACT_APP_MIXPANEL_TOKEN
    ? env.REACT_APP_MIXPANEL_TOKEN
    : MIXPANEL_LOCAL_TESTING_TOKEN;

mixpanel.init(MIXPANEL_TOKEN, {
  api_host: env?.REACT_APP_MIXPANEL_API_URL,
});

if (!env.REACT_APP_ANALYTICS_DISABLED) {
  pendoMount();
  intercomInit();

  if (hasConsentedToCookies()) {
    if (mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_in_tracking();
    }

    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
    });

    // We only run Full Story for app users, not portal users
    // When devMode is true, we do not record user sessions
    if (!window.location.origin.includes(env.REACT_APP_HUBUX_PORTAL_URL)) {
      FullStory.init({ orgId: "194ERH", devMode: env.REACT_APP_ENVIRONMENT !== "production" });
    }
  } else {
    if (mixpanel.has_opted_in_tracking()) {
      mixpanel.opt_out_tracking();
    }

    intercomShutdown();
  }
}

const render = async (Component: any) => {
  const { REACT_APP_LD_CLIENT_SIDE_ID: LD_CLIENT_SIDE_ID } = runtimeEnv();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_SIDE_ID,
    context: { email: null, name: undefined, key: CONTEXT_KEY_SHARED, kind: "user" },
    options: { logger: basicLogger({ level: "error" }) },
    reactOptions: { useCamelCaseFlagKeys: false },
  });

  ReactDOM.render(
    <LDProvider>
      <Component />
    </LDProvider>,
    document.getElementById("root")
  );
};

render(Root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
