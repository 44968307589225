import { useUpdateEffect } from "@react-hookz/web";
import { Input, Radio, Select, Space } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useFragment } from "react-relay";
import { splitOnce } from "../../../utils";

import type {
  InputCharacteristicResponseSingleSelect_characteristic$data,
  InputCharacteristicResponseSingleSelect_characteristic$key,
} from "../../../__generated__/InputCharacteristicResponseSingleSelect_characteristic.graphql";
import { InputCharacteristicResponseSingleSelect_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponseSingleSelect_characteristicResponses.graphql";

const Radios = ({ answers }: { answers: InputCharacteristicResponseSingleSelect_characteristic$data["answers"] }) => (
  <>
    {answers.map(x => (
      <Radio key={`radio-${x.id}`} value={x.id}>
        <ReactMarkdown className="hub-md-inline" linkTarget="blank">
          {x.text}
        </ReactMarkdown>
      </Radio>
    ))}
  </>
);

export const InputCharacteristicResponseSingleSelect = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  onChange,
  value: __value,
}: {
  characteristic: InputCharacteristicResponseSingleSelect_characteristic$key;
  characteristicResponses: InputCharacteristicResponseSingleSelect_characteristicResponses$key;
  onChange?: (value: string) => void;
  value?: string;
}) => {
  const characteristic = useFragment(
    graphql`
      fragment InputCharacteristicResponseSingleSelect_characteristic on CharacteristicNode {
        id
        answers {
          id
          text
          userSpecified
        }
      }
    `,
    characteristicKey
  );
  const [response] = useFragment(
    graphql`
      fragment InputCharacteristicResponseSingleSelect_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        id
        customAnswer
        answer {
          id
          userSpecified
        }
      }
    `,
    characteristicResponsesKey
  );

  const [_value, _customAnswer] = __value
    ? splitOnce(__value, "::")
    : [response?.answer?.id, response?.answer?.userSpecified ? response.customAnswer ?? undefined : undefined];

  const [customAnswer, setCustomAnswer] = useState(_customAnswer);
  useUpdateEffect(() => {
    if (_customAnswer !== customAnswer) setCustomAnswer(_customAnswer);
  }, [_customAnswer, setCustomAnswer]);

  const [value, setValue] = useState(_value);
  const applyValue = (x: string | undefined) => {
    if (!x) setValue(undefined);
    else if (characteristic.answers.find(y => y.id === x) && x !== value) setValue(x);
  };
  useUpdateEffect(() => {
    applyValue(_value);
  }, [_value, setValue]);

  useEffect(() => {
    if (value) {
      const newValue = customAnswer ? `${value}::${customAnswer}` : value;
      if (newValue !== __value) onChange?.(newValue);
    }
  }, [__value, customAnswer, onChange, value]);

  const answersContainImages = characteristic.answers.some(x => x.text.includes("!["));

  return (
    <>
      {answersContainImages || characteristic.answers.length <= 3 ? (
        <Radio.Group onChange={e => applyValue(e.target.value)} value={value}>
          {answersContainImages ? (
            <Space direction="vertical">
              <Radios answers={characteristic.answers} />
            </Space>
          ) : (
            <Radios answers={characteristic.answers} />
          )}
        </Radio.Group>
      ) : (
        <Select
          defaultValue={_value}
          getPopupContainer={trigger => trigger.parentElement}
          onChange={applyValue}
          value={value}
        >
          {characteristic.answers.map(x => (
            <Select.Option key={`option-${x.id}`} value={x.id}>
              <ReactMarkdown className="hub-md-inline" linkTarget="blank">
                {x.text}
              </ReactMarkdown>
            </Select.Option>
          ))}
        </Select>
      )}
      {!!characteristic.answers.find(x => x.id === value)?.userSpecified && (
        <Input
          placeholder="Other"
          defaultValue={customAnswer}
          maxLength={512}
          onChange={e => setCustomAnswer(e.target.value)}
        />
      )}
    </>
  );
};
