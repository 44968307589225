/**
 * @generated SignedSource<<0f8e99adf94f22adaf1bda28865d2444>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitInstructions_study$data = {
  readonly id: string;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly namePublic: string | null;
  readonly screener: {
    readonly title: string | null;
  } | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly requireStudyPayment: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_study">;
  readonly " $fragmentType": "RecruitInstructions_study";
};
export type RecruitInstructions_study$key = {
  readonly " $data"?: RecruitInstructions_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitInstructions_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitInstructions_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namePublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScreenerNode",
      "kind": "LinkedField",
      "name": "screener",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requireStudyPayment",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommunicationComposePage_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "f079c2ad2482348d8139e7d23d4a466c";

export default node;
