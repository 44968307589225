import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { environment } from "../../relay";
import { LoadingPage } from "../index";
import ProjectsColumnPagination from "./ProjectsColumnPagination";

const ProjectsColumnQuery = ({
  filterTagDids,
  orderBy,
  projectStatus,
  searchTerm,
  setCount,
}: {
  filterTagDids: string[];
  orderBy: string;
  projectStatus: string;
  searchTerm: string;
  setCount?: (count: number | undefined) => void;
}) => (
  <QueryRenderer
    environment={environment}
    query={graphql`
      query ProjectsColumnQuery_Query(
        $filterTagDids: [ID!]
        $projectStatus: ScreenersStudyStatusChoices!
        $orderBy: String!
        $searchTerm: String
      ) {
        viewer {
          ...ProjectsColumnPagination_viewer
            @arguments(
              filterTagDids: $filterTagDids
              orderBy: $orderBy
              searchTerm: $searchTerm
              status: $projectStatus
            )
        }
      }
    `}
    variables={{
      filterTagDids: filterTagDids.length ? filterTagDids : null,
      orderBy,
      projectStatus,
      searchTerm,
    }}
    render={({ error, props }: any) => {
      if (error) {
        return <div>Error getting draft studies</div>;
      } else if (props?.viewer) {
        return (
          <ProjectsColumnPagination
            filterTagDids={filterTagDids}
            orderBy={orderBy}
            projectStatus={projectStatus}
            searchTerm={searchTerm}
            setCount={setCount}
            viewer={props.viewer}
          />
        );
      }

      return (
        <div style={{ height: "30vh", width: "300px" }}>
          <LoadingPage />
        </div>
      );
    }}
  />
);

export default ProjectsColumnQuery;
