import React from "react";

type Props = {
  text: string;
};
const IntercomLink: React.FC<Props> = ({ text }: Props) => (
  <a href={document.location.href} onClick={e => e.preventDefault()} className="intercom-launcher">
    {text}
  </a>
);

export default IntercomLink;
