import { Button, Checkbox, Form, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { createRefetchContainer, RelayRefetchProp, useMutation } from "react-relay";
import styled from "styled-components";

import {
  Avatar,
  ButtonLabel,
  Moment,
  Note,
  NoteIsEditingStateContext,
  PANELIST_NOTE_MARGIN_INNER,
  useNoteIsEditingState,
} from "..";
import { DIVIDER_COLOR } from "../../style";
import {
  handleErrorWithMessage,
  handleSuccessWithMessage,
  mutation,
  onError,
  useConfirm,
  useConfirmQuitEditing,
} from "../../utils";
import type { PanelistModalMutation } from "../../__generated__/PanelistModalMutation.graphql";
import type { PanelistModalPanelMutation } from "../../__generated__/PanelistModalPanelMutation.graphql";
import type { PanelistModal_RemovePanelist_Mutation } from "../../__generated__/PanelistModal_RemovePanelist_Mutation.graphql";
import type { PanelistModal_tenant$data } from "../../__generated__/PanelistModal_tenant.graphql";
import type { PanelistModal_viewer$data } from "../../__generated__/PanelistModal_viewer.graphql";
import PanelistCharacteristics from "../tables/PanelistCharacteristics";

import { GivePointsToPanelistModal } from "./GivePointsToPanelistModal";

function PanelistModal({
  relay,
  panelistId,
  viewer,
  tenant,
  visible,
  onCancel,
}: {
  viewer: PanelistModal_viewer$data;
  tenant: PanelistModal_tenant$data;
  panelistId: string;
  visible: boolean;
  onCancel: (options?: { refetch?: boolean }) => void;
  relay: RelayRefetchProp;
}) {
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [showGivePointsModal, setShowGivePointsModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    relay.refetch({ panelistId });
  }, [panelistId, relay]);

  const finish = async (values: { [key: string]: string | string[] }, panelistLayout: readonly string[]) => {
    setSaving(true);
    try {
      await mutation<PanelistModalMutation>({
        variables: { input: { panelistId, data: values } },
        mutation: graphql`
          mutation PanelistModalMutation($input: UpdatePanelistInput!) {
            updatePanelist(input: $input) {
              panelist {
                id
                ...PanelistCharacteristics_panelist
              }
            }
          }
        `,
      });

      if (tenant.panel)
        await mutation<PanelistModalPanelMutation>({
          variables: {
            input: {
              panelId: tenant.panel.id,
              panelistLayout,
            },
          },
          mutation: graphql`
            mutation PanelistModalPanelMutation($input: UpdatePanelInput!) {
              updatePanel(input: $input) {
                panel {
                  id
                  tenant {
                    ...PanelistCharacteristics_tenant
                  }
                }
              }
            }
          `,
        });

      handleSuccessWithMessage("Panelist Saved");
      setSaving(false);
      setEditing(false);
      relay.refetch({ panelistId });
    } catch (err) {
      handleErrorWithMessage("Error Saving Panelist");
      setSaving(false);
      console.error(err);
    }
  };

  const cancelEdit = () => {
    setEditing(false);
    form.resetFields();
  };

  const noteIsEditingState = useNoteIsEditingState();

  const [commitRemovePanelist] = useMutation<PanelistModal_RemovePanelist_Mutation>(graphql`
    mutation PanelistModal_RemovePanelist_Mutation($input: RemovePanelistInput!) {
      removePanelist(input: $input) {
        panelist {
          id
        }
      }
    }
  `);

  const confirmDeletePanelist = useConfirm({
    title: `Delete ${viewer.panelist?.person?.firstName} as a panelist?`,
    okText: "Delete from your panel",
  });
  const confirmQuitEditing = useConfirmQuitEditing();

  return (
    viewer.panelist && (
      <StyledParticipantModal
        wrapClassName="video-modal"
        style={{ maxWidth: "1120px", height: "80vh" }}
        bodyStyle={{ padding: 0, height: "80vh" }}
        width="90vw"
        open={visible}
        onCancel={async () => {
          if (!noteIsEditingState[0] || (await confirmQuitEditing())) {
            noteIsEditingState[1](false);
            setEditing(false);
            onCancel();
          }
        }}
        maskClosable={false}
        footer={null}
        destroyOnClose
      >
        <GivePointsToPanelistModal
          panelist={viewer.panelist}
          tenant={tenant}
          visible={showGivePointsModal}
          setVisible={setShowGivePointsModal}
        />
        <Styled>
          <div className="info">
            <Avatar size={96} src={viewer.panelist.person?.avatarUrl ?? null} />
            <div className="name">
              {viewer.panelist.person?.firstName} {viewer.panelist.person?.lastName?.slice(0, 1)}
            </div>
            <ul>
              <li>
                <div className="label">Member since</div>
                <div className="text">
                  <Moment format="MMM D, YYYY">{viewer.panelist.joined as string}</Moment>
                </div>
              </li>
              <li>
                <div className="label">Panelist ID</div>
                <div className="text">{viewer.panelist.dId as string}</div>
              </li>
              <li>
                <div className="label">Last Rating</div>
                <div className="text">
                  <span role="img" aria-label="star-icon">
                    ⭐
                  </span>
                  <span style={{ marginLeft: 6 }}>
                    {viewer.panelist.panelistMembership?.latestRatedParticipant?.rating || "?"}
                  </span>
                </div>
              </li>
              <li>
                <div className="label">Points Balance</div>
                <div className="text">{viewer.panelist.rewardsBalance}</div>
              </li>
            </ul>

            <div className="actions">
              {!tenant.customportal?.hidePointsAndRedemption && (
                <Button onClick={() => setShowGivePointsModal(true)}>Give points</Button>
              )}
              <Button
                danger
                type="link"
                onClick={async () => {
                  let block = false;

                  if (
                    await confirmDeletePanelist({
                      content: (
                        <>
                          <p>
                            {viewer.panelist!.person?.firstName} won't receive new invites to your projects. They'll
                            need to be separately removed from any ongoing projects.
                          </p>
                          <Checkbox onChange={e => (block = e.target.checked)}>
                            Block {viewer.panelist?.person?.firstName} from rejoining your panel
                          </Checkbox>
                        </>
                      ),
                    })
                  )
                    commitRemovePanelist({
                      onCompleted: () => onCancel({ refetch: true }),
                      onError,
                      variables: { input: { block, panelistId: viewer.panelist!.id } },
                    });
                }}
              >
                Delete panelist
              </Button>
            </div>
          </div>
          <div className="main">
            <NoteIsEditingStateContext.Provider value={noteIsEditingState}>
              <Note className="note" panelistKey={viewer.panelist} panelistNoteHeading="Notes" />
            </NoteIsEditingStateContext.Provider>
            <div className="header">
              Characteristics
              {editing ? (
                <div className="characteristics-actions">
                  <ButtonLabel
                    type="link"
                    onClick={cancelEdit}
                    icon="mdi:cancel"
                    iconScale={1.5}
                    text="Cancel"
                    style={{ color: "var(--ant-error-color)" }}
                  />
                  <ButtonLabel
                    disabled={saving}
                    icon="mdi:check"
                    iconScale={1.5}
                    loading={saving}
                    onClick={() => form.submit()}
                    text="Save"
                    type="link"
                  />
                </div>
              ) : (
                <ButtonLabel
                  icon="mdi:pencil-outline"
                  iconScale={1.5}
                  onClick={() => setEditing(true)}
                  text="Edit"
                  type="link"
                />
              )}
            </div>
            <PanelistCharacteristics
              panelist={viewer.panelist}
              tenant={tenant}
              form={form}
              editing={editing}
              onFinish={finish}
              style={{ height: "auto" }}
            />
          </div>
        </Styled>
      </StyledParticipantModal>
    )
  );
}

const StyledParticipantModal = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;
    padding: 0;
  }
`;

const Styled = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  position: relative;

  .info {
    width: 30%;
    border-right: 1px solid ${DIVIDER_COLOR};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: sticky;
    top: 0;
    overflow-y: auto;

    & > * {
      flex-shrink: 0;
    }

    & > ul {
      display: flex;
      flex-direction: inherit;
      align-items: inherit;
      gap: inherit;
      padding: 0;
      text-align: center;

      li {
        list-style-type: none;
      }
    }

    .name {
      font-size: 28px;
      font-weight: 500;
    }

    .label {
      font-weight: 500;
      font-size: 16px;
    }

    .text {
      font-size: 12px;
      text-align: center;
      text-overflow: ellipsis;
      width: 170px;
      overflow: hidden;
      white-space: nowrap;
    }

    .actions {
      flex-grow: 1;
      display: flex;
      gap: 12px;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .main {
    width: 70%;
  }

  .note {
    padding: 16px 12px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: ${PANELIST_NOTE_MARGIN_INNER};
    font-size: 1rem;
    font-weight: 500;
    line-height: 48px;
    padding-left: 12px;
    border-bottom: 1px solid ${DIVIDER_COLOR};
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f8f9fa;

    .characteristics-actions {
      display: inherit;
      align-items: inherit;
    }

    button {
      font-weight: 500;
    }
  }
`;

export default createRefetchContainer(
  PanelistModal,
  {
    viewer: graphql`
      fragment PanelistModal_viewer on Viewer @argumentDefinitions(panelistId: { type: "String" }) {
        panelist(panelistId: $panelistId) {
          id
          dId
          panelistMembership {
            latestRatedParticipant {
              rating
            }
          }
          rewardsBalance
          person {
            avatarUrl
            firstName
            lastName
          }
          joined
          ...PanelistCharacteristics_panelist
          ...Note_panelist
          ...GivePointsToPanelistModal_panelist
        }
      }
    `,
    tenant: graphql`
      fragment PanelistModal_tenant on TenantNode {
        customportal {
          hidePointsAndRedemption
        }
        panel {
          id
        }
        ...GivePointsToPanelistModal_tenant
        ...PanelistCharacteristics_tenant
      }
    `,
  },
  graphql`
    query PanelistModalQuery($panelistId: String!) {
      viewer {
        ...PanelistModal_viewer @arguments(panelistId: $panelistId)
      }
    }
  `
);
