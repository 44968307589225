/**
 * @generated SignedSource<<ba4ab23b8f27f6adb6fd54879a2f6665>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkspaceSettings_user$data = {
  readonly profile: {
    readonly tenant: {
      readonly calendarConnected: boolean | null;
      readonly customportal: {
        readonly headerMarkup: string;
        readonly headerMarkupEnabled: boolean;
      } | null;
      readonly enableCustomPortal: boolean;
      readonly enableThreatDetection: boolean;
      readonly fakeWidgetEnabled: boolean;
      readonly googleCalendarEmail: string | null;
      readonly name: string;
      readonly requirePhoneNumber: boolean;
      readonly stripePaymentMethodId: string | null;
    };
  } | null;
  readonly " $fragmentType": "WorkspaceSettings_user";
};
export type WorkspaceSettings_user$key = {
  readonly " $data"?: WorkspaceSettings_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkspaceSettings_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkspaceSettings_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calendarConnected",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "googleCalendarEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enableThreatDetection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripePaymentMethodId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requirePhoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enableCustomPortal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomPortalNode",
              "kind": "LinkedField",
              "name": "customportal",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "headerMarkup",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "headerMarkupEnabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fakeWidgetEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "c5c3ca77c8c87ffed53b621cf44c10e5";

export default node;
