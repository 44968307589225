import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { HEADER_FONT_SIZE, HEADER_FONT_WEIGHT, PRIMARY_TEXT_COLOR } from "../../style";
import type { ProjectsPage_viewer$data } from "../../__generated__/ProjectsPage_viewer.graphql";
import { Projects } from "../index";

import { NoProjects } from "./NoProjects";

const ProjectsPage = ({ viewer }: { viewer: ProjectsPage_viewer$data }) => {
  const { router } = useRouter();

  return (
    <>
      <StyledHeader>
        <h1>Projects</h1>
        <Button
          className="new-project"
          size="large"
          type="primary"
          onClick={() => router.push(`/projects/create`)}
          icon={<PlusOutlined />}
        >
          New Project
        </Button>
      </StyledHeader>
      {!viewer.studiesCopy?.edges.length ? (
        <NoProjects style={{ flexGrow: 1 }} />
      ) : (
        <Projects tenantKey={viewer!.user!.profile!.tenant!} />
      )}
    </>
  );
};

const StyledHeader = styled.header`
  margin: 32px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  h1 {
    margin: 0;
    font-size: ${HEADER_FONT_SIZE};
    font-weight: ${HEADER_FONT_WEIGHT};
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

export default createFragmentContainer(ProjectsPage, {
  viewer: graphql`
    fragment ProjectsPage_viewer on Viewer {
      studiesCopy: studies {
        edges {
          node {
            id
          }
        }
      }
      user {
        profile {
          tenant {
            id
            ...InputTagsSelect_tenant
          }
        }
      }
    }
  `,
});
