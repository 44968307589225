export const EMAIL_ASSETS_MAX_SIZE_BYTES = 20000000;

export const enum AUTOMATED_EMAIL_TYPE {
  NEEDS_REVIEW = "NR",
  APPROVED = "A",
  DENIED = "D",
  TERMED = "T",
  OVER_QUOTA = "OQ",
  RATED = "RT",
  SCHEDULED = "S",
  REWARDED = "RW",
}

export const AUTOMATED_EMAIL_TYPE_TOOLTIP = {
  [AUTOMATED_EMAIL_TYPE.NEEDS_REVIEW]: "Respondents who are pending review.",
  [AUTOMATED_EMAIL_TYPE.APPROVED]: "Participants who approved the screener.",
  [AUTOMATED_EMAIL_TYPE.DENIED]: "Participants who were declined.",
  [AUTOMATED_EMAIL_TYPE.TERMED]: "Respondents who chose a term answer.",
  [AUTOMATED_EMAIL_TYPE.OVER_QUOTA]: "Respondents who choose an answer after the maximum was reached.",
  [AUTOMATED_EMAIL_TYPE.RATED]: "Participants who were rated.",
  [AUTOMATED_EMAIL_TYPE.SCHEDULED]: "Participants who scheduled for an interview.",
  [AUTOMATED_EMAIL_TYPE.REWARDED]: "Participants who were incentivized.",
};

export type EmailTemplateContent = {
  replyTo: string;
  subject: string;
  message: string;
};

export type EmailTemplate = {
  title: string;
} & EmailTemplateContent;

export type AutomatedEmailAsset = { id: string; name: string | null; sizeBytes: number | null; url: string };

export type AutomatedEmail = {
  studyId: string;
  type: string;
  enabled: boolean;
  ctaLabel: string | null;
  ctaUrl: string | null;
  assets: AutomatedEmailAsset[];
} & EmailTemplateContent;
