import { Loading3QuartersOutlined } from "@ant-design/icons";
import checkCircleOutline from "@iconify-icons/ic/baseline-check-circle-outline";
import errorOutline from "@iconify-icons/ic/baseline-error-outline";
import { InlineIcon } from "@iconify/react";
import { Spin, Tooltip } from "antd";
import classNames, { type Argument } from "classnames";
import React, { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "../antd";
import { GRAY_8, HEADER_HEIGHT } from "../style";
import { useFlag } from "../utils";

type SavingContentProps = {
  icon: React.ReactElement;
  text: string;
  tooltip?: string;
};
const SavingContent: React.FC<SavingContentProps> = ({ icon, text, tooltip = "" }) => {
  return (
    <Tooltip title={tooltip}>
      <Spin indicator={icon} />
      <span className="saving-text">{text}</span>
    </Tooltip>
  );
};

export type SavingStateType = "saving" | "saved" | "error";
type SavingType = "default" | "text"; // matches antd Button

const Saving = ({
  className,
  savingState,
  style,
  topOffset,
  type: $type = "default",
}: {
  className?: Argument;
  savingState: SavingStateType;
  style?: CSSProperties;
  topOffset?: number;
  type?: SavingType;
}) => {
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [prevSaveState, setPrevSaveState] = useState<SavingStateType>("saved");

  useEffect(() => {
    // This useEffect is to ensure the indicator does not appear on the page until the user
    // makes their first change
    if (prevSaveState !== savingState) {
      setPrevSaveState(savingState);
      setChangesMade(true);
    }
  }, [savingState, prevSaveState]);

  const contentLookup = {
    saving: {
      icon: <Loading3QuartersOutlined className="spinner-icon" spin />,
      text: "Saving changes",
    },
    saved: {
      icon: <InlineIcon style={{ fontSize: "1.5rem" }} color="var(--ant-success-color)" icon={checkCircleOutline} />,
      text: "All changes saved",
    },
    error: {
      icon: <InlineIcon style={{ fontSize: "1.5rem" }} color="var(--ant-error-color-active)" icon={errorOutline} />,
      text: "Changes couldn't be saved",
      tooltip: "Check your internet connection. Don't close this page.",
    },
  };

  const isVoxpopme = useFlag("platform-hubux-template");

  return changesMade ? (
    <StyledSaving
      $isVoxpopme={isVoxpopme}
      $type={$type}
      className={classNames(className)}
      style={style}
      topOffset={topOffset ?? 0}
    >
      <SavingContent {...contentLookup[savingState]} />
    </StyledSaving>
  ) : null;
};

const StyledSaving = styled.div<{
  $isVoxpopme: boolean;
  topOffset: number;
  $type: SavingType;
}>`
  padding: 3px 16px 1px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: ${props => HEADER_HEIGHT + props.topOffset + 8}px;
  right: 8px;
  z-index: 1;
  background-color: ${props => (props.$type === "text" ? "transparent" : "white")};
  border-radius: 6px;
  ${props => (props.$isVoxpopme && props.$type !== "text" ? `border: 1px solid ${ThemeColor.VoxStroke};` : "")}

  .spinner-icon {
    color: ${GRAY_8};
    font-size: 1.5rem;
  }

  .saving-text {
    padding-left: 0.6rem;
    font-weight: 500;
  }
`;

export default Saving;
