import { Button, Form, Modal, ModalProps, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { EmailTemplateContent } from "../../../constants";
import { SelectTemplateModal_tenant$data } from "../../../__generated__/SelectTemplateModal_tenant.graphql";
import { DetailsInput } from "../../Configure/DetailsInput";

type Props = {
  tenant: SelectTemplateModal_tenant$data;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string;
  onCancel?: () => void;
  selectTemplateButtonText?: string;
  onTemplateSelected: (emailTemplate: EmailTemplateContent) => void;
  modalProps?: ModalProps;
};
const _SelectTemplateModal: React.FC<Props> = ({
  tenant,
  visible,
  setVisible,
  title,
  onCancel,
  selectTemplateButtonText,
  onTemplateSelected,
  modalProps,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setSelectedTemplateId("");
    }
  }, [visible, form]);

  const handleCancel = () => {
    onCancel?.();
    setVisible(false);
  };

  const handleFinish = () => {
    const selectedTemplate = tenant.emailTemplates?.edges.find(e => e?.node?.id === selectedTemplateId)?.node;
    if (selectedTemplate) {
      onTemplateSelected({
        replyTo: selectedTemplate.replyTo,
        subject: selectedTemplate.subject,
        message: selectedTemplate.message,
      });
      setVisible(false);
    }
  };

  return (
    <StyledModal open={visible} title={title} footer={null} closable={false} onCancel={handleCancel} {...modalProps}>
      <Form form={form} onFinish={handleFinish}>
        <DetailsInput
          title="Template"
          inputs={
            <Form.Item name="template">
              <Select style={{ width: "100%" }} onChange={setSelectedTemplateId} placeholder="Select a template">
                {tenant.emailTemplates?.edges.map(e => (
                  <Select.Option key={e!.node!.id} value={e!.node!.id}>
                    {e!.node!.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
        <div className="select-template-buttons">
          <Button onClick={handleCancel} type="link">
            Cancel
          </Button>
          <Button onClick={handleFinish} disabled={!selectedTemplateId} type="primary">
            {selectTemplateButtonText ?? "Select template"}
          </Button>
        </div>
      </Form>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .select-template-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
`;

export const SelectTemplateModal = createFragmentContainer(_SelectTemplateModal, {
  tenant: graphql`
    fragment SelectTemplateModal_tenant on TenantNode {
      emailTemplates {
        edges {
          node {
            id
            name
            replyTo
            subject
            message
          }
        }
      }
    }
  `,
});
