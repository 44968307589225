import outlineArchive from "@iconify-icons/ic/outline-archive";
import { App, Button, Form, Input, Modal, ModalProps } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { EmailTemplateContent } from "../../../constants";
import { mutation } from "../../../utils";
import { SaveTemplateModal_tenant$data } from "../../../__generated__/SaveTemplateModal_tenant.graphql";
import { DetailsInput } from "../../Configure/DetailsInput";
import { IconButton } from "../../IconButton";

type Props = {
  emailTemplate: EmailTemplateContent;
  tenant: SaveTemplateModal_tenant$data;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  modalProps?: ModalProps;
};
const _SaveTemplateModal = ({ emailTemplate, tenant, visible, setVisible, modalProps }: Props) => {
  const { message, notification } = App.useApp();

  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const saveAsTemplate = async () => {
    try {
      setIsSaving(true);

      await form.validateFields();

      await mutation({
        silenceDefaultError: true,
        variables: {
          input: { ...emailTemplate, name },
        },
        mutation: graphql`
          mutation SaveTemplateModal_saveEmailTemplate_Mutation($input: SaveEmailTemplateInput!) {
            saveEmailTemplate(input: $input) {
              tenant {
                emailTemplates {
                  edges {
                    node {
                      name
                      replyTo
                      subject
                      message
                    }
                  }
                }
              }
            }
          }
        `,
      });

      notification.success({
        message: "Saved",
        description: (
          <>
            Email template <em>{name}</em> saved successfully.
          </>
        ),
      });

      setVisible(false);
    } catch (ex: any) {
      if (!ex.errorFields) {
        message.error("An error occurred while saving the email template");
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledModal
      open={visible}
      title="Save new template"
      footer={null}
      closable={false}
      onCancel={() => setVisible(false)}
      {...modalProps}
    >
      <Form form={form} onFinish={saveAsTemplate}>
        <DetailsInput
          title="Title"
          inputs={
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please provide a title",
                },
                {
                  validator: (_, value) =>
                    tenant.emailTemplates?.edges.some(e => e?.node?.name === value)
                      ? Promise.reject(`The template title "${value}" is already in use.`)
                      : Promise.resolve(),
                },
              ]}
            >
              <Input maxLength={150} placeholder="Title of the new template" onChange={e => setName(e.target.value)} />
            </Form.Item>
          }
        />
        <div className="save-template-buttons">
          <Button onClick={() => setVisible(false)} disabled={isSaving} type="link">
            Cancel
          </Button>
          <IconButton
            icon={outlineArchive}
            onClick={saveAsTemplate}
            loading={isSaving}
            disabled={!name.trim()}
            type="primary"
          >
            Save new template
          </IconButton>
        </div>
      </Form>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .save-template-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
`;

export const SaveTemplateModal = createFragmentContainer(_SaveTemplateModal, {
  tenant: graphql`
    fragment SaveTemplateModal_tenant on TenantNode {
      emailTemplates {
        edges {
          node {
            id
            name
            replyTo
            subject
            message
          }
        }
      }
    }
  `,
});
