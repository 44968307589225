/**
 * @generated SignedSource<<bf6d66a702c6688a0d2c57b229bf96a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponses_characteristicResponses$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseDate_characteristicResponses" | "InputCharacteristicResponseGridSingleSelect_characteristicResponses" | "InputCharacteristicResponseMultiSelect_characteristicResponses" | "InputCharacteristicResponseNumber_characteristicResponses" | "InputCharacteristicResponseOpenEnd_characteristicResponses" | "InputCharacteristicResponseSingleSelect_characteristicResponses">;
  readonly " $fragmentType": "InputCharacteristicResponses_characteristicResponses";
}>;
export type InputCharacteristicResponses_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponses_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponses_characteristicResponses">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponses_characteristicResponses",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseDate_characteristicResponses"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseGridSingleSelect_characteristicResponses"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseMultiSelect_characteristicResponses"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseNumber_characteristicResponses"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseOpenEnd_characteristicResponses"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponseSingleSelect_characteristicResponses"
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};

(node as any).hash = "ba1817446e76d8b5ee94d7ca79801eea";

export default node;
