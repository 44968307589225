/**
 * @generated SignedSource<<58606b662c33d0ff25485d904c6d5803>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectScreenerPage_screener$data = {
  readonly description: string | null;
  readonly elements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly dbId: string;
        readonly id: string;
        readonly label: string | null;
        readonly position: number;
        readonly text: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"Element_element">;
      } | null;
    } | null>;
  } | null;
  readonly id: string;
  readonly study: {
    readonly id: string;
    readonly namePublic: string | null;
    readonly status: ScreenersStudyStatusChoices;
    readonly tenant: {
      readonly enableThreatDetection: boolean;
    };
    readonly testRecruit: {
      readonly id: string;
      readonly screenerLink: string;
    };
  };
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Element_screener">;
  readonly " $fragmentType": "ProjectScreenerPage_screener";
};
export type ProjectScreenerPage_screener$key = {
  readonly " $data"?: ProjectScreenerPage_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectScreenerPage_screener">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectScreenerPage_screener",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namePublic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enableThreatDetection",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitNode",
          "kind": "LinkedField",
          "name": "testRecruit",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "screenerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementNodeConnection",
      "kind": "LinkedField",
      "name": "elements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ElementNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dbId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Element_element"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Element_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};
})();

(node as any).hash = "82a0c86f7c4f9861ce6c792a472c5182";

export default node;
