/**
 * @generated SignedSource<<032e5ff322f748db7a19387616877238>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseSingleSelect_characteristic$data = {
  readonly answers: ReadonlyArray<{
    readonly id: string;
    readonly text: string;
    readonly userSpecified: boolean;
  }>;
  readonly id: string;
  readonly " $fragmentType": "InputCharacteristicResponseSingleSelect_characteristic";
};
export type InputCharacteristicResponseSingleSelect_characteristic$key = {
  readonly " $data"?: InputCharacteristicResponseSingleSelect_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseSingleSelect_characteristic">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputCharacteristicResponseSingleSelect_characteristic",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicAnswerNode",
      "kind": "LinkedField",
      "name": "answers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userSpecified",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};
})();

(node as any).hash = "b4a4946bcb96b81d64fbc40c5f2bd165";

export default node;
