/**
 * @generated SignedSource<<80ffc02d8a516b23cc0c50140183a652>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ResponseValidationType = "AL" | "AM" | "BE" | "EX" | "%future added value";
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FormItemCharacteristicResponses_characteristic$data = {
  readonly elementMeta: {
    readonly responseValidation: ResponseValidationType | null;
    readonly target: number | null;
    readonly target2: number | null;
  };
  readonly id: string;
  readonly text: string | null;
  readonly type: ScreenersCharacteristicTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponses_characteristic">;
  readonly " $fragmentType": "FormItemCharacteristicResponses_characteristic";
};
export type FormItemCharacteristicResponses_characteristic$key = {
  readonly " $data"?: FormItemCharacteristicResponses_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormItemCharacteristicResponses_characteristic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormItemCharacteristicResponses_characteristic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicElementMetaNode",
      "kind": "LinkedField",
      "name": "elementMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "responseValidation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "target",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "target2",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponses_characteristic"
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};

(node as any).hash = "a4cd697e53a46df2199a7d16ef38dd53";

export default node;
