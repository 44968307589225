import { Form, Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { SAVING_STATES } from "../../constants";
import { ScreenersReminderTemplateVersionMeetingTypeChoices } from "../../schema";
import { PRIMARY_TEXT_COLOR } from "../../style";
import { mutation } from "../../utils";
import { InviteInputMutation } from "../../__generated__/InviteInputMutation.graphql";
import type { InviteInput_study$data } from "../../__generated__/InviteInput_study.graphql";
import { SavingStateType } from "../Saving";

const InviteInput = ({
  study,
  version,
  setSavingState,
}: {
  study: InviteInput_study$data;
  version: ScreenersReminderTemplateVersionMeetingTypeChoices;
  setSavingState: (savingState: SavingStateType) => void;
}) => {
  const [textChanged, setTextChanged] = useState(false);

  const updateInviteTemplate = async (input: {
    inviteMeetingName?: string;
    inviteMessage?: string;
    inviteMessageCustomized?: boolean;
  }) => {
    if (textChanged) {
      input.inviteMessageCustomized = true;
    }
    try {
      setSavingState(SAVING_STATES.SAVING);

      const inputWithType = {
        ...input,
        studyId: study.id || "",
        inviteMeetingType: version,
      };

      await mutation<InviteInputMutation>({
        variables: {
          input: inputWithType,
        },
        mutation: graphql`
          mutation InviteInputMutation($input: UpdateStudyInput!) {
            updateStudy(input: $input) {
              study {
                id
                inviteMeetingNameVirtual
                inviteMessageVirtual
                inviteMeetingNameInPerson
                inviteMessageInPerson
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const [form] = Form.useForm();

  // reset form values when toggling virtual/in-person
  useEffect(() => {
    form.resetFields();
  }, [form, version]);

  const checkValidity = (name: "inviteMeetingName" | "inviteMessage") => {
    const allTouched = form.isFieldsTouched(["inviteMeetingName", "inviteMessage"], true);
    if (allTouched) {
      form.validateFields();
    }
  };

  return (
    <StyledForm>
      <Form
        form={form}
        initialValues={{
          inviteMeetingName:
            version === ScreenersReminderTemplateVersionMeetingTypeChoices.V
              ? study.inviteMeetingNameVirtual
              : study.inviteMeetingNameInPerson,
          inviteMessage:
            version === ScreenersReminderTemplateVersionMeetingTypeChoices.V
              ? study.inviteMessageVirtual
              : study.inviteMessageInPerson,
        }}
        {...formItemLayout}
      >
        <Form.Item
          name="inviteMeetingName"
          label="Meeting name"
          rules={[
            {
              required: true,
              message: "Please provide a meeting name",
            },
          ]}
        >
          <Input
            placeholder="Enter the meeting name"
            onChange={() => checkValidity("inviteMeetingName")}
            onBlur={e => updateInviteTemplate({ inviteMeetingName: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          name="inviteMessage"
          label="Message"
          rules={[
            {
              required: true,
              message: "Please provide a message",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Enter the message you want to send to your participants"
            rows={10}
            onChange={() => {
              setTextChanged(true);
              checkValidity("inviteMessage");
            }}
            onBlur={e => updateInviteTemplate({ inviteMessage: e.target.value })}
          />
        </Form.Item>
      </Form>
    </StyledForm>
  );
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

const StyledForm = styled.div`
  label {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

export default createFragmentContainer(InviteInput, {
  study: graphql`
    fragment InviteInput_study on StudyNode {
      id
      inviteMeetingNameVirtual
      inviteMessageVirtual
      inviteMeetingNameInPerson
      inviteMessageInPerson
    }
  `,
});
