import { Typography } from "antd";
import classNames, { type Argument } from "classnames";
import type { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

import { ThemeColor, useAntdConfig } from "../antd";
import { useFlag } from "../utils";

const { Title } = Typography;

const ProjectPageContent = ({
  children,
  className,
  extra,
  style,
  title,
}: {
  className?: Argument;
  children: ReactNode;
  extra?: ReactNode;
  title?: ReactNode;
  style?: CSSProperties;
}) => {
  const configProviderProps = useAntdConfig();
  const isVoxpopme = useFlag("platform-hubux-template");

  return (
    <Root
      $isVoxpopme={isVoxpopme}
      className={classNames(className)}
      style={{ padding: "24px 24px 48px", width: "100%", ...style }}
    >
      {!!(extra || title) && (
        <header style={{ backgroundColor: configProviderProps.theme.components?.Layout?.colorBgBody }}>
          {!!title && (
            <div className="hub-header-title">
              {["number", "string"].includes(typeof title) ? <Title level={4}>{title}</Title> : title}
            </div>
          )}
          {!!extra && <div className="hub-header-extra">{extra}</div>}
        </header>
      )}
      {children}
    </Root>
  );
};

const Root = styled.div<{ $isVoxpopme: boolean }>`
  & > header {
    padding: 24px 24px 8px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    ${props => (props.$isVoxpopme ? `border-bottom: 1px solid ${ThemeColor.VoxStroke};` : "")}

    & > .hub-header-extra {
      display: flex;
      gap: inherit;
    }

    .hub-header-title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 500;
        margin: 0;
      }
    }
  }

  .hub-extra {
    display: flex;
  }
`;

export default ProjectPageContent;
