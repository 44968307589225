import { ScreamingSnakeCase } from "type-fest";
import type { SavingStateType } from "../components/Saving";

type antOnRowReturn = {
  onClick?: (event: any) => void;
  onDoubleClick?: (event: any) => void;
  onContextMenu?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: (event: any) => void;
  style?: {};
};

export type antOnRowType = (rowIndex: any) => antOnRowReturn;

export const DEFAULT_PANELISTS_FETCH_COUNT = 10;
export const DEFAULT_PARTICIPANTS_FETCH_COUNT = 30;

// 15 minute limit as the notifications can be expensive
export const NOTIFICATIONS_POLLING_INTERVAL_SECONDS = 15 * 60;

export const DATE_FORMAT = "YYYY-MM-DD";
export type BetweenDates = [moment.Moment | null, moment.Moment | null];

export const BREAKPOINTS = {
  DESKTOP: 1280,
  LAPTOP: 1024,
  TABLET_LANDSCAPE: 768,
  TABLET_PORTRAIT: 500,
};

export const MIXPANEL_LOCAL_TESTING_TOKEN = "4b034833b229dc60e42ff6fb077b8fbf";

export const OPERATOR_DESCRIPTIONS: { [opName: string]: string } = {
  "=": "Equals",
  AFTER: "Is After",
  AND: "And",
  BEFORE: "Is Before",
  BETWEEN: "Is Between",
  CONTAINS: "Contains",
  EQUALS: "Equals",
  GREATER_THEN_EQUALS: "Is Greater Than or Equal To",
  GREATER_THEN: "Is Greater Than",
  IS_NOT: "Is Not",
  IS: "Is",
  LESS_THEN_EQUALS: "Is Less Than or Equal To",
  LESS_THEN: "Is Less Than",
  NOT_BETWEEN: "Is Not Between",
  NOT_CONTAINS: "Does Not Contain",
  NOT_EQUALS: "Is Not Equal To",
  OR: "Or",
};

export const VIDEO_PREFIX = "HUBUX_VID_";

export const SAVING_STATES: Record<ScreamingSnakeCase<SavingStateType>, SavingStateType> = {
  SAVING: "saving",
  SAVED: "saved",
  ERROR: "error",
} as const;

export const enum Interval {
  RealTime = 1000,
  RealTimeExpensive = 5000,
}

export * from "./element";
export * from "./email";
export * from "./locale";
export * from "./person";
export * from "./recruit";
export * from "./respondent";
export * from "./study";
export * from "./tag";
export * from "./util";
