import { Modal } from "antd";

import type { DispatchState } from "../types";

export const ModalPrepayDisabled = ({ open, setOpen }: { open: boolean; setOpen: DispatchState<boolean> }) => (
  <Modal
    centered
    title="Payments not available"
    okText="Contact us"
    onCancel={() => setOpen(false)}
    onOk={() => {
      window.Intercom?.(
        "showNewMessage",
        "Hi there! My account is set to pre-pay, and I'd like to upgrade to an invoiced plan."
      );
      setOpen(false);
    }}
    open={open}
  >
    Payments aren't available in this region. Get in touch to upgrade to an invoiced plan.
  </Modal>
);
