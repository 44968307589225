import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useRef } from "react";
import styled from "styled-components";
import { BORDER_RADIUS, CONTAINER_BOX_SHADOW, PORTAL_HEADER_COLOR } from "../../style";
import PortalCustomCode from "./PortalCustomCode";
import { getHeroAssetType } from "./utils";

const env = runtimeEnv();

function PortalBanner({
  customBackgroundUrl,
  isBgFaded,
  customHeroAssetUrl,
  ...props
}: {
  panelistId: string;
  markup?: string | null;
  markupEnabled?: boolean | null;
  customBackgroundUrl?: string | null;
  customHeroAssetUrl?: string;
  isBgFaded?: boolean;
}) {
  const videoEl = useRef(null);
  const customHeroAssetType = getHeroAssetType(customHeroAssetUrl);

  const getDefaultVideoSrc = (type: string) => {
    const width = window.innerWidth;
    if (type === "mp4") {
      if (width >= 640) {
        return `${env.REACT_APP_S3_URL}participant-portal-desktop.m4v`;
      }
      return `${env.REACT_APP_S3_URL}participant-portal-mobile.m4v`;
    } else {
      return `${env.REACT_APP_S3_URL}participant-portal.webm`;
    }
  };

  // Shows and hides video controls on mouseover
  const showVideoControls = (show: boolean) => {
    if (show) {
      if (videoEl.current) {
        // @ts-ignore
        videoEl.current.controls = true;
      }
    } else {
      if (videoEl.current) {
        // @ts-ignore
        videoEl.current.controls = false;
      }
    }
  };

  return !props.markupEnabled ? (
    <StyledBanner
      customBackgroundUrl={customBackgroundUrl}
      customHeroAssetType={customHeroAssetType}
      isBgFaded={isBgFaded}
    >
      {customHeroAssetType === "image" ? (
        <img className="portal-image" src={customHeroAssetUrl} alt="" />
      ) : (
        <video
          className="portal-video"
          ref={videoEl}
          onMouseOver={() => showVideoControls(true)}
          onMouseLeave={() => showVideoControls(false)}
        >
          {/* if customHeroAssetType is mp4, hide the webm source */}
          {customHeroAssetType !== "mp4" && <source src={getDefaultVideoSrc("webm")} type="video/webm" />}
          <source
            src={customHeroAssetType === "mp4" ? customHeroAssetUrl : getDefaultVideoSrc("mp4")}
            type="video/mp4"
          />
        </video>
      )}
    </StyledBanner>
  ) : (
    <PortalCustomCode markup={props.markup} panelistId={props.panelistId} />
  );
}

const StyledBanner = styled.div<{
  customBackgroundUrl?: String | null;
  customHeroAssetType?: String | null;
  isBgFaded?: Boolean;
}>`
  position: relative;
  height: ${props => (props?.customHeroAssetType === "image" ? "auto" : "calc(0.25 * 100vw)")};
  max-height: ${props => (props?.customHeroAssetType === "image" ? `none` : "500px")};
  background-image: ${props => (props?.customBackgroundUrl ? `url(${props?.customBackgroundUrl})` : "none")};
  background-size: cover;
  background-color: ${PORTAL_HEADER_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;

  .portal-image {
    width: 36%;
    margin: 33px 0;
    z-index: 10;
    aspect-ratio: 16/9;
  }

  .portal-video {
    height: 90%;
    border-radius: ${BORDER_RADIUS};
    box-shadow: ${CONTAINER_BOX_SHADOW};
    outline: none;
  }

  // fade bottom of bg to black
  ${props =>
    props.isBgFaded
      ? `:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 100%;
        height: 33%;
        background-image: linear-gradient(
          rgba(0, 0, 0, 0.0) 0%,
          rgba(0, 0, 0, 1.0) 100%
        )
    }`
      : null}

  @media (max-width: 767px) {
    display: none;
  }
`;

export default PortalBanner;
