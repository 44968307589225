/**
 * @generated SignedSource<<133fc8ea91a4cb09219b89d7933c8099>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginPage_customPortal$data = {
  readonly contactEmail: string | null;
  readonly customColor: string | null;
  readonly customLandingHostname: string | null;
  readonly faviconUrl: string | null;
  readonly footerImageUrl: string | null;
  readonly logoImageUrl: string | null;
  readonly pageTitle: string | null;
  readonly tenant: {
    readonly oidcProviders: ReadonlyArray<{
      readonly authUrl: string | null;
      readonly clientId: string;
      readonly env: string | null;
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly " $fragmentType": "LoginPage_customPortal";
};
export type LoginPage_customPortal$key = {
  readonly " $data"?: LoginPage_customPortal$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoginPage_customPortal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoginPage_customPortal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "footerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "faviconUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customLandingHostname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OIDCProviderNode",
          "kind": "LinkedField",
          "name": "oidcProviders",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "env",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "authUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomPortalNode",
  "abstractKey": null
};

(node as any).hash = "a4a2fbc7aff49dde08dc75d9e5aa7ec2";

export default node;
