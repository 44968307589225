import { DeleteOutlined } from "@ant-design/icons";
import outlineAdd from "@iconify-icons/ic/outline-add";
import { App, FormInstance, Input, InputRef, Table } from "antd";
import { uniqueId } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { GRAY_2 } from "../../../style";
import type { CharacteristicAnswer, CharacteristicRow } from "../../../types";
import { cleanDataTransferText, exceptIndex } from "../../../utils/misc";
import { IconButton } from "../../IconButton";

const ADD_ROW_KEY = "__ADD_ROW";

export const EditCharacteristicGrid = ({
  form,
  answers,
  setAnswers,
  rows,
  setRows,
}: {
  form: FormInstance;
  answers: CharacteristicAnswer[];
  setAnswers: (answers: CharacteristicAnswer[]) => void;
  rows: CharacteristicRow[];
  setRows: (answers: CharacteristicRow[]) => void;
}) => {
  const { message } = App.useApp();

  // handle selection of new answer or row inputs
  const [answerFocusIndex, setAnswerFocusIndex] = useState<number | null>(null);
  const answerFocusRef = useRef<InputRef>(null);

  useEffect(() => answerFocusRef.current?.focus(), [answerFocusIndex]);

  const [rowFocusIndex, setRowFocusIndex] = useState<number | null>(null);
  const rowFocusRef = useRef<InputRef>(null);

  useEffect(() => rowFocusRef.current?.focus(), [rowFocusIndex]);

  const handleChange = useCallback(
    (changes: { answers?: CharacteristicAnswer[]; rows?: CharacteristicRow[] }) => {
      if (changes.answers) setAnswers(changes.answers);
      if (changes.rows) setRows(changes.rows);
      form.setFieldsValue(changes);
    },
    [form, setAnswers, setRows]
  );

  // on enter press, create a new element after the current one and set focus to it
  const handleEnter = useCallback(
    <T extends { id: string; text: string }>(
        elements: T[],
        setElements: (elements: T[]) => void,
        setFocusIndex: (index: number) => void,
        index: number
      ) =>
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        setElements([...elements.slice(0, index + 1), { id: uniqueId(), text: "" } as T, ...elements.slice(index + 1)]);
        setFocusIndex(index + 1);
      },
    []
  );
  const handleAnswerEnter = useCallback(
    (index: number) =>
      handleEnter(answers, (answers: CharacteristicAnswer[]) => handleChange({ answers }), setAnswerFocusIndex, index),
    [handleChange, handleEnter, answers]
  );
  const handleRowEnter = useCallback(
    (index: number) =>
      handleEnter(rows, (rows: CharacteristicRow[]) => handleChange({ rows }), setRowFocusIndex, index),
    [handleChange, handleEnter, rows]
  );

  // on paste, do the default behavior if it's not multiline, otherwise paste multiple and select the element after the
  // last pasted one
  const handlePaste = useCallback(
    <T extends { id: string; text: string }>(
        elements: T[],
        setElements: (elements: T[]) => void,
        setFocusIndex: (index: number) => void,
        type: "answer" | "row",
        index: number
      ) =>
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        const newElements = cleanDataTransferText(e.clipboardData);
        if (newElements.length) {
          e.preventDefault();
          setElements([
            ...elements.slice(0, index),
            ...newElements.map(text => ({ id: uniqueId(), text } as T)),
            ...elements.slice(index),
          ]);
          message.info(`Added ${newElements.length} ${type}s`);
          setFocusIndex(index + newElements.length);
        }
      },
    [message]
  );
  const handleAnswerPaste = useCallback(
    (index: number) =>
      handlePaste(
        answers,
        (answers: CharacteristicAnswer[]) => handleChange({ answers }),
        setAnswerFocusIndex,
        "answer",
        index
      ),
    [answers, handleChange, handlePaste]
  );
  const handleRowPaste = useCallback(
    (index: number) =>
      handlePaste(rows, (rows: CharacteristicRow[]) => handleChange({ rows }), setRowFocusIndex, "row", index),
    [rows, handleChange, handlePaste]
  );

  return (
    <StyledEditCharacteristicGrid>
      <Table
        columns={useMemo(
          () => [
            // first column, inputs for row text and "add row" button in the last row
            {
              render: (_, row: CharacteristicRow & { key: string }, rowIndex) =>
                row.key !== ADD_ROW_KEY ? (
                  <div className="inputs-cell">
                    <Input
                      defaultValue={row.text}
                      maxLength={512}
                      onBlur={e =>
                        handleChange({
                          rows: rows.map((row, i) => (i !== rowIndex ? row : { ...row, text: e.target.value })),
                        })
                      }
                      onPressEnter={handleRowEnter(rowIndex)}
                      onPaste={handleRowPaste(rowIndex)}
                      ref={rowFocusIndex === rowIndex ? rowFocusRef : undefined}
                    />
                    <DeleteOutlined onClick={() => handleChange({ rows: exceptIndex(rows, rowIndex) })} />
                  </div>
                ) : (
                  <IconButton
                    icon={outlineAdd}
                    type="link"
                    onClick={() => handleChange({ rows: [...rows, { id: uniqueId("row_"), text: "" }] })}
                  >
                    Add row
                  </IconButton>
                ),
            },

            // answer columns
            ...(answers.map((answer, answerIndex) => ({
              title: (
                <div className="inputs-cell">
                  <Input
                    key={answer.id ?? answerIndex.toString()}
                    defaultValue={answer.text}
                    maxLength={512}
                    onBlur={e =>
                      handleChange({
                        answers: answers.map((answer, i) =>
                          i !== answerIndex ? answer : { ...answer, text: e.target.value }
                        ),
                      })
                    }
                    onPressEnter={handleAnswerEnter(answerIndex)}
                    onPaste={handleAnswerPaste(answerIndex)}
                    ref={answerFocusIndex === answerIndex ? answerFocusRef : undefined}
                  />
                  <DeleteOutlined onClick={() => handleChange({ answers: exceptIndex(answers, answerIndex) })} />
                </div>
              ),
              dataIndex: answer.id,
            })) ?? []),

            // "add column" button
            {
              title: (
                <IconButton
                  icon={outlineAdd}
                  type="link"
                  onClick={() => handleChange({ answers: [...answers, { id: uniqueId("answer_"), text: "" }] })}
                >
                  Add column
                </IconButton>
              ),
            },
          ],
          [
            answers,
            answerFocusIndex,
            handleAnswerEnter,
            handleAnswerPaste,
            rows,
            rowFocusIndex,
            handleRowEnter,
            handleRowPaste,
            handleChange,
          ]
        )}
        dataSource={useMemo(
          () => [
            // rows
            ...rows.map((row, i) => ({ ...row, key: row.id })),

            // "add row" button
            { key: ADD_ROW_KEY, id: ADD_ROW_KEY, text: "" },
          ],
          [rows]
        )}
        pagination={false}
        scroll={{ x: 220 * answers.length }}
      />
    </StyledEditCharacteristicGrid>
  );
};

const StyledEditCharacteristicGrid = styled.div`
  .inputs-cell {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  Table {
    th {
      background-color: ${GRAY_2};
      font-weight: normal;
    }
    tbody td:first-child {
      background-color: ${GRAY_2};
    }
  }
`;
