import {
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import outlineDone from "@iconify-icons/ic/outline-done";
import outlineFactCheck from "@iconify-icons/ic/outline-fact-check";
import outlineMarkEmailUnread from "@iconify-icons/ic/outline-mark-email-unread";
import { InlineIcon } from "@iconify/react";
import { App, Button, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import React, { useEffect, useRef, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ThemeColor } from "../../antd";

import { PROJECT_STATUSES, recruitStatusMap, recruitTypeMap, RECRUIT_STATUSES } from "../../constants";
import { FUTURE_PROOF } from "../../constants/util";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { BORDER_RADIUS, GRAY_3, SECONDARY_TEXT_COLOR } from "../../style";
import { mutation, useFlag } from "../../utils";
import type { RecruitPage_createRecruit_Mutation } from "../../__generated__/RecruitPage_createRecruit_Mutation.graphql";
import type { RecruitPage_study$data } from "../../__generated__/RecruitPage_study.graphql";
import ProjectPageContent from "../ProjectPageContent";

import RecruitDetailsQuery from "./RecruitDetailsQuery";

type Props = {
  study: RecruitPage_study$data;
};
const RecruitPage: React.FC<Props> = ({ study }) => {
  const { notification } = App.useApp();

  const [selectedId, setSelectedId] = useState<any>(() => {
    if (study!.recruits!.edges!.length > 0) {
      const edge = study?.recruits?.edges[0];
      if (edge) {
        return edge?.node?.id;
      }
    }
  });
  const selectedRoundRef = useRef<HTMLDivElement>(null);
  const [defaultName, setDefaultName] = useState<string>("Round 1");
  const [loading, setLoading] = useState<boolean>();
  const [paymentAddedMsgShown, setPaymentAddedMsgShown] = useState<boolean>(false);
  const { match } = useRouter();

  // Used to display success and failure notifications following add payment method actions
  useEffect(() => {
    if (!paymentAddedMsgShown) {
      if (match.location.query["update-payment-success"] === "true") {
        setPaymentAddedMsgShown(true);
        notification.success({
          message: "Payment Method Added Successfully",
          description: "You can now pay and start the recruiting round",
        });
      } else if (match.location.query["update-payment-success"] === "false") {
        setPaymentAddedMsgShown(true);
        notification.error({
          message: "Payment Method Not Added",
          description: "Please try again or contact us for assistance",
        });
      }
    }
  }, [match, notification, paymentAddedMsgShown]);

  useEffect(() => selectedRoundRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" }), [selectedId]);

  const createRecruit = (studyId: string) => {
    if (loading) {
      return;
    }

    setLoading(true);
    return mutation<RecruitPage_createRecruit_Mutation>({
      variables: { input: { studyId } },
      mutation: graphql`
        mutation RecruitPage_createRecruit_Mutation($input: CreateRecruitInput!) {
          createRecruit(input: $input) {
            recruitEdge {
              node {
                id
                status
                type
              }
            }
          }
        }
      `,
      configs: [
        {
          type: "RANGE_ADD",
          parentID: studyId,
          connectionInfo: [
            {
              key: "RecruitPage_recruits",
              rangeBehavior: "append",
            },
          ],
          edgeName: "recruitEdge",
        },
      ],
    })
      .then((response: any) => {
        select(response.createRecruit.recruitEdge?.node.id, study.recruits.edges.length);
      })
      .finally(() => setLoading(false));
  };

  const select = (recruitId: string | undefined, i: number) => {
    setDefaultName(`Round ${i + 1}`);
    setSelectedId(recruitId);
  };

  const deleteRecruit = (recruitId: string) => {
    const indexToDelete = study.recruits.edges.findIndex((edge: any) => edge.node.id === recruitId);
    return mutation({
      variables: { input: { recruitId: recruitId } },
      mutation: graphql`
        mutation RecruitPage_deleteRecruit_Mutation($input: DeleteRecruitInput!) {
          deleteRecruit(input: $input) {
            study {
              ...RecruitPage_study
            }
          }
        }
      `,
    }).then(() => {
      let indexToSelect = 0;
      if (study.recruits.edges.length === 0) {
        return select(undefined, indexToSelect);
      } else if (indexToDelete === 0) {
        indexToSelect = 1;
      } else {
        indexToSelect = indexToDelete - 1;
      }
      select(study?.recruits?.edges[indexToSelect]?.node?.id, indexToSelect);
    });
  };

  const recruitTypeIconsMap = {
    [ScreenersRecruitTypeChoices.Wl]: <LinkOutlined className="round-type-icon" />,
    [ScreenersRecruitTypeChoices.Hp]: <TeamOutlined className="round-type-icon" />,
    [ScreenersRecruitTypeChoices.Em]: <MailOutlined className="round-type-icon" />,
    [ScreenersRecruitTypeChoices.Yp]: <UserOutlined className="round-type-icon" />,
    [ScreenersRecruitTypeChoices.Ro]: <GlobalOutlined className="round-type-icon" />,
    [FUTURE_PROOF]: <QuestionCircleOutlined className="round-type-icon" />,
  };

  const isVoxpopme = useFlag("platform-hubux-template");

  return (
    <ProjectPageContent style={{ display: "flex", padding: 0, position: "absolute", inset: 0 }}>
      <RoundsContainer $isVoxpopme={isVoxpopme}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h3 style={{ marginBottom: 16 }}>Recruiting rounds</h3>
          {study.status !== PROJECT_STATUSES.COMPLETE && (
            <Button type="text" onClick={() => createRecruit(study.id)}>
              + Add new round
            </Button>
          )}
        </div>
        <p>Recruit participants using different sources, recruitment goals, and incentives.</p>
        {study.recruits.edges.map((o, i) => {
          if (!o?.node?.id) {
            return null;
          }

          const id = o.node.id;
          const name = o.node.name || `Round ${i + 1}`;

          return (
            <Round
              $isVoxpopme={isVoxpopme}
              key={i}
              selected={selectedId === id}
              onClick={() => select(id, i)}
              ref={selectedId === id ? selectedRoundRef : null}
            >
              <div className="round-card-header">
                <div>{name}</div>
                <div className="round-card-type-status">
                  <div className="round-card-type">
                    <Tooltip
                      title={o.node.type ? `Source: ${(recruitTypeMap as any)[o.node.type]}` : null}
                      placement="bottom"
                    >
                      {o.node.type ? recruitTypeIconsMap[o.node.type] : null}
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      color:
                        o.node.status === RECRUIT_STATUSES.STARTED
                          ? "var(--ant-primary-color)"
                          : o.node.status === RECRUIT_STATUSES.PAUSED
                          ? "var(--ant-warning-color)"
                          : "inherit",
                    }}
                    className="round-card-status"
                  >
                    {o.node.type === ScreenersRecruitTypeChoices.Wl && o.node.status === RECRUIT_STATUSES.STARTED
                      ? "Active"
                      : recruitStatusMap[o.node.status]}
                  </div>
                </div>
              </div>
              {o.node.status !== RECRUIT_STATUSES.NOT_STARTED && (
                <div className="round-card-details">
                  <div className="round-card-counts">
                    {o.node.type !== ScreenersRecruitTypeChoices.Wl && (
                      <Tooltip title={`${o.node.invitesSentCount?.toLocaleString()} invited`} placement="bottom">
                        <div className="round-card-count">
                          <InlineIcon
                            width="16"
                            color={SECONDARY_TEXT_COLOR}
                            icon={outlineMarkEmailUnread}
                            className="round-card-icon"
                          />
                          {o.node.invitesSentCount?.toLocaleString()}
                        </div>
                      </Tooltip>
                    )}
                    {!study.allowAutoApproval && (
                      <Tooltip
                        title={`${o.node.completedScreenerCount?.toLocaleString()} completed screener`}
                        placement="bottom"
                      >
                        <div className="round-card-count">
                          <InlineIcon
                            width="16"
                            color={SECONDARY_TEXT_COLOR}
                            icon={outlineFactCheck}
                            className="round-card-icon"
                          />
                          {o.node.completedScreenerCount?.toLocaleString()}
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip title={`${o.node.qualifiedCount?.toLocaleString()} qualified`} placement="bottom">
                      <div className="round-card-count">
                        <InlineIcon
                          width="16"
                          color={SECONDARY_TEXT_COLOR}
                          icon={outlineDone}
                          className="round-card-icon"
                        />
                        {o.node.qualifiedCount?.toLocaleString()}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </Round>
          );
        })}
      </RoundsContainer>
      <OverflowContainer>
        <Recruit>
          <RecruitDetailsQuery
            recruitId={selectedId}
            studyId={study.id}
            defaultName={defaultName}
            deleteRecruit={deleteRecruit}
          />
        </Recruit>
      </OverflowContainer>
    </ProjectPageContent>
  );
};

const sidebarWidthPx = 64;
const roundsContainerWidthPx = 400;

const RoundsContainer = styled.div<{ $isVoxpopme: boolean }>`
  width: ${roundsContainerWidthPx}px;
  padding: 24px 24px 48px;
  border-right: 1px solid ${props => (props.$isVoxpopme ? ThemeColor.VoxStroke : GRAY_3)};
  overflow-y: auto;
`;

const Round = styled.div<{ $isVoxpopme: boolean; selected: boolean }>`
  background: white;
  border: ${props =>
    props.selected
      ? "2px solid var(--ant-primary-color)"
      : `1px solid ${props.$isVoxpopme ? ThemeColor.VoxStroke : GRAY_3}`};
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 62px;
  margin-bottom: 12px;
  padding: ${({ selected }) => (selected ? "14px 19px" : "15px 20px")};

  .round-card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    .round-card-type-status {
      display: flex;

      .round-card-type {
        margin: 0 10px;
        color: ${SECONDARY_TEXT_COLOR};
      }

      .round-card-status {
        text-transform: uppercase;
        font-size: 14px;
      }
    }
  }

  .round-card-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: ${SECONDARY_TEXT_COLOR};

    .round-card-counts {
      display: flex;
      justify-content: space-between;

      .round-card-count {
        display: flex;
        align-items: center;
        margin-left: 8px;
        width: 60px;

        &:first-of-type {
          margin-left: 0;
        }

        .round-card-icon {
          margin-right: 4px;
        }

    }

    .round-type-icon {
      font-size: 16px;
    }
  }
`;

const OverflowContainer = styled.div`
  height: 100%;
  width: calc(100vw - ${sidebarWidthPx}px - ${roundsContainerWidthPx}px);
  overflow: auto;
`;

const Recruit = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 24px 24px 48px;

  .ant-input,
  .ant-picker,
  .ant-select-selector,
  .ant-input-number {
    background-color: #fafafa !important;
  }

  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

export default createFragmentContainer(RecruitPage, {
  study: graphql`
    fragment RecruitPage_study on StudyNode {
      id
      recruits(first: 100) @connection(key: "RecruitPage_recruits") {
        edges {
          node {
            id
            name
            type
            status
            invitesSentCount
            completedScreenerCount
            qualifiedCount
          }
        }
      }
      status
      allowAutoApproval
    }
  `,
});
