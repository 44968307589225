/**
 * @generated SignedSource<<1c81c869b90bbc2ea57fda9d8ec20ce9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyEventsTypeChoices = "SESSIONS" | "SINGLE" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SlotPicker_study$data = {
  readonly eventsType: ScreenersStudyEventsTypeChoices;
  readonly id: string;
  readonly sessions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly availabilitySlots: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly availablePlaces: number | null;
              readonly end: any;
              readonly id: string;
              readonly moderatorEmail: string | null;
              readonly start: any;
            } | null;
          } | null>;
        };
        readonly id: string;
        readonly name: string;
        readonly scheduledSlots: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly end: any;
              readonly id: string;
              readonly start: any;
              readonly studyAvailabilitySlot: {
                readonly availablePlaces: number | null;
                readonly moderatorEmail: string | null;
              } | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentType": "SlotPicker_study";
};
export type SlotPicker_study$key = {
  readonly " $data"?: SlotPicker_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlotPicker_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "moderatorEmail",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availablePlaces",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlotPicker_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventsType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudySessionNodeConnection",
      "kind": "LinkedField",
      "name": "sessions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudySessionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudySessionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StudyAvailabilitySlotNodeConnection",
                  "kind": "LinkedField",
                  "name": "availabilitySlots",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyAvailabilitySlotNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StudyAvailabilitySlotNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RespondentScheduledSlotNodeConnection",
                  "kind": "LinkedField",
                  "name": "scheduledSlots",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RespondentScheduledSlotNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RespondentScheduledSlotNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "StudyAvailabilitySlotNode",
                              "kind": "LinkedField",
                              "name": "studyAvailabilitySlot",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "0097b19c01a2a6b22e685d7d2893aa06";

export default node;
