import { Icon } from "@iconify/react";
import { useUnmountEffect } from "@react-hookz/web";
import { Menu } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash-es";
import { CSSProperties, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { ThemeColor } from "../antd";
import {
  CONTEXT_KEY_SHARED,
  deidentify,
  getJwt,
  getTenantContext,
  getUserContext,
  identify,
  trackEvent,
  useFlag,
} from "../utils";
import { maybeElement } from "../utils/misc";
import type { Authenticated_LDUser_user$key } from "../__generated__/Authenticated_LDUser_user.graphql";
import type { Authenticated_user$key } from "../__generated__/Authenticated_user.graphql";
import { LoadingPage } from "./index";
import PanelistLayout from "./Layouts/PanelistLayout";
import StaffLayout from "./Layouts/StaffLayout";

export const Authenticated = ({ user: userKey, children }: PropsWithChildren<{ user: Authenticated_user$key }>) => {
  const user = useFragment(
    graphql`
      fragment Authenticated_user on UserNode {
        vpmUserId
        dId
        email
        fullName
        isPanelist
        profile {
          id
          tenant {
            vpmAccountId
            dId
            name
            dId
            vpmAccountId
            name
          }
        }
        profiles {
          edges {
            node {
              id
              tenant {
                name
              }
            }
          }
        }
        panelist {
          dId
          tenants {
            edges {
              node {
                name
              }
            }
          }
          customPanelistPortal {
            hidePointsAndRedemption
          }
        }
        dId
        vpmUserId
        panelist {
          dId
        }

        ...Authenticated_LDUser_user
        ...PanelistLayout_user
        ...StaffLayout_user
      }
    `,
    userKey
  );

  const { match, router } = useRouter();

  useEffect(() => {
    const { jwt: token } = getJwt();

    if (token === null) {
      router.push("/logout");
    }
    // if user doesn't have a tenant, it must be a panelist
    if (!user?.profile?.tenant?.dId) {
      if (match.location.pathname.includes("/redeem")) {
        router.push("/redeem");
      } else {
        router.push("/portal");
      }
    }
    // we don't want to run this everytime the path changes so we do not include match
    // eslint-disable-next-line
  }, [router, user]);

  useEffect(() => identify(user as any), [user]);
  useUnmountEffect(deidentify);

  const activeProfileId = user.profile?.id ?? "";
  const isVoxpopme = useFlag("platform-hubux-template");

  const [userMenuOpen, setUserMenuOpen] = useState<boolean | undefined>();

  const menu = (
    <Menu
      style={{ borderRadius: 8 }}
      selectedKeys={maybeElement(!!activeProfileId, activeProfileId)}
      items={useMemo<ItemType[]>(() => {
        const menuItemStyle: CSSProperties = { marginBlock: 6 };
        return [
          // redemption
          ...(user.isPanelist && !user.panelist?.customPanelistPortal?.hidePointsAndRedemption
            ? [
                {
                  key: "redeem",
                  style: menuItemStyle,
                  onClick: () => router.push("/redeem"),
                  label: <Trans i18nKey="authenticated.redeem-points">Redeem points</Trans>,
                },
              ]
            : []),

          // panelist profile
          ...maybeElement<ItemType>(user.isPanelist, {
            key: "profile",
            style: menuItemStyle,
            onClick: () => router.push("/portal/profile"),
            label: <Trans i18nKey="authenticated.edit-profile">Edit profile</Trans>,
          }),

          // app user profiles
          ...(user.profiles.edges.length
            ? [
                ...sortBy(user.profiles.edges, e => e?.node?.tenant.name).map(e => {
                  const active = activeProfileId === e?.node?.id;

                  // style for the list item that wraps `label`
                  const rootStyle: CSSProperties = {
                    ...menuItemStyle,
                    width: 169,
                    overflow: "visible",
                    paddingRight: 5,
                    // block space for absolutely positioned settings icon
                    marginRight: 42,
                    // override settings for the active profile menu item
                    ...(active ? { cursor: "default" } : undefined),
                    ...(active && isVoxpopme ? { color: "white", backgroundColor: ThemeColor.VoxGrape } : undefined),
                  };

                  return {
                    key: e!.node!.id,
                    onClick: active
                      ? () => setUserMenuOpen(false)
                      : () => router.push(`/switch-profile/${e!.node!.id}`),
                    style: rootStyle,
                    label: (
                      <ProfileMenuItem>
                        <div className="tenant-name">{e!.node!.tenant.name}</div>
                        <div
                          className="settings-icon-container"
                          onClick={evt => {
                            // the settings icon is a descendant of the tenant; prevent click event on tenant (to prevent
                            // navigation to projects page) and manually close the menu
                            evt.stopPropagation();
                            setUserMenuOpen(false);

                            router.push(
                              active
                                ? "/workspace/settings"
                                : `/switch-profile/${e!.node!.id}?next=${encodeURIComponent("/workspace/settings")}`
                            );
                          }}
                        >
                          <div className="settings-icon-wrapper">
                            <Icon height="1.2em" icon="mdi:gear" className="settings-icon" />
                          </div>
                        </div>
                      </ProfileMenuItem>
                    ),
                  };
                }),
                { key: "divider-2", type: "divider" },
              ]
            : []),

          // log out
          {
            key: "log-out",
            style: menuItemStyle,
            icon: <Icon icon="mdi:logout" />,
            onClick: () => {
              trackEvent("Logging out", {
                ...getUserContext(user as any),
                ...getTenantContext(user.profile?.tenant as any),
              });
              router.push("/logout");
            },
            label: <Trans i18nKey="authenticated.log-out">Log out</Trans>,
          },
        ];
      }, [user, activeProfileId, isVoxpopme, router])}
    />
  );

  return (
    <LDUser user={user}>
      {match.location.pathname.includes("/portal") || match.location.pathname.includes("/redeem") ? (
        <PanelistLayout user={user} children={children} menu={menu} router={router} match={match} />
      ) : (
        <StaffLayout
          user={user}
          children={children}
          menu={menu}
          menuState={{ open: userMenuOpen, setOpen: setUserMenuOpen }}
          router={router}
          match={match}
        />
      )}
    </LDUser>
  );
};

const LDUser = ({ user: userKey, children }: PropsWithChildren<{ user: Authenticated_LDUser_user$key }>) => {
  const user = useFragment(
    graphql`
      fragment Authenticated_LDUser_user on UserNode {
        dId
        email
        fullName
        isPanelist
      }
    `,
    userKey
  );

  const [ldIdentifyCompleted, setLDIdentifyCompleted] = useState(false);

  const ldClient = useLDClient();

  useEffect(() => {
    // allow aboritng in the case of a re-render so state is only modified on the mounted component
    const controller = new AbortController();
    const host = window.location.host;

    (async () => {
      try {
        setLDIdentifyCompleted(false);
        await ldClient?.identify(
          user.isPanelist
            ? {
                email: null,
                host,
                key: CONTEXT_KEY_SHARED,
                kind: "user",
                name: undefined,
              }
            : {
                email: user.email,
                host,
                key: user.dId ?? CONTEXT_KEY_SHARED,
                kind: "user",
                name: user.fullName ?? undefined,
              }
        );
      } finally {
        if (!controller.signal.aborted) setLDIdentifyCompleted(true);
      }
    })();

    return () => controller.abort();
  }, [user, ldClient]);

  return ldIdentifyCompleted ? <>{children}</> : <LoadingPage />;
};

const ProfileMenuItem = styled.div`
  .tenant-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .settings-icon-container {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.88);
    position: absolute;
    width: 32px;
    top: 0;
    right: -37px; // right-aligned 32px icon + 5px padding
    bottom: 0;

    &:hover {
      .settings-icon-wrapper {
        background-color: rgba(0, 0, 0, 0.11);
      }
    }

    .settings-icon-wrapper {
      position: absolute;
      inset: 8px 4px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100%;
    }
  }
`;
