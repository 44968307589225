import { InputNumber } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ResponseValidationType } from "../../../schema";

import type { InputCharacteristicResponseNumber_characteristic$key } from "../../../__generated__/InputCharacteristicResponseNumber_characteristic.graphql";
import { InputCharacteristicResponseNumber_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponseNumber_characteristicResponses.graphql";

export const InputCharacteristicResponseNumber = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  onChange,
  value,
}: {
  characteristic: InputCharacteristicResponseNumber_characteristic$key;
  characteristicResponses: InputCharacteristicResponseNumber_characteristicResponses$key;
  onChange?: Parameters<typeof InputNumber>[0]["onChange"];
  value?: number;
}) => {
  const { elementMeta: meta } = useFragment(
    graphql`
      fragment InputCharacteristicResponseNumber_characteristic on CharacteristicNode {
        id
        elementMeta {
          responseValidation
          target
          target2
        }
      }
    `,
    characteristicKey
  );
  const [response] = useFragment(
    graphql`
      fragment InputCharacteristicResponseNumber_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        id
        textValue
      }
    `,
    characteristicResponsesKey
  );

  return (
    <InputNumber
      defaultValue={response?.textValue ? Number(response.textValue) : undefined}
      max={
        [ResponseValidationType.Am, ResponseValidationType.Ex].includes(
          meta.responseValidation as ResponseValidationType
        )
          ? meta.target ?? undefined
          : meta.responseValidation === ResponseValidationType.Be
          ? meta.target2 ?? undefined
          : undefined
      }
      min={
        [ResponseValidationType.Al, ResponseValidationType.Be, ResponseValidationType.Ex].includes(
          meta.responseValidation as ResponseValidationType
        )
          ? meta.target ?? undefined
          : undefined
      }
      onChange={onChange}
      value={value}
    />
  );
};
