/**
 * @generated SignedSource<<e96376c80eca332f5799a0bc31981fd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetails_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_tenant">;
  readonly " $fragmentType": "ProjectDetails_tenant";
};
export type ProjectDetails_tenant$key = {
  readonly " $data"?: ProjectDetails_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetails_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPage_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "c9a9037b944eb35a4d9ca5cc99cda52d";

export default node;
