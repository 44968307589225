import runtimeEnv from "@mars/heroku-js-runtime-env";
import { loadStripe } from "@stripe/stripe-js";
import { message } from "antd";
import axios from "axios";
import { startCase } from "lodash-es";
import React, { type ReactNode } from "react";
import { getCookieConsentValue } from "react-cookie-consent";

import { CharacteristicCell, EllipsisCell } from "../components/tables/Cells";
import {
  ELEMENT_TYPES,
  IncentiveType,
  INCENTIVE_FEE_PERCENT,
  MIN_INCENTIVE_FEE_USD,
  MIN_INCENTIVE_POINTS,
  MIN_INCENTIVE_USD,
  PROJECT_INCENTIVE_TYPES,
  USD_PER_POINT,
} from "../constants";
import type { RecruitNode } from "../schema";

import { getAuthorizationHeader } from "./auth";

const stripePromise = loadStripe(runtimeEnv().REACT_APP_STRIPE_PUBLIC_KEY);

export function truncate(value: string, length: number) {
  if (!value) return "";
  if (value.length > length) {
    return value.substr(0, length) + "...";
  } else {
    return value;
  }
}

export function calculateProjectCost(
  incentive: number,
  incentiveType: IncentiveType,
  goal: number,
  recruitType: RecruitNode["type"],
  externalIncentivePerParticipantCostUsdCents: number
): number {
  if (
    (incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL || incentive >= 0) &&
    incentiveType &&
    goal > 0 &&
    recruitType
  ) {
    return (
      calculateParticipantCost(incentive, incentiveType, recruitType, externalIncentivePerParticipantCostUsdCents) *
      goal
    );
  } else {
    return 0;
  }
}

export function calculateParticipantCost(
  incentive: number,
  incentiveType: IncentiveType,
  recruitType: RecruitNode["type"],
  externalIncentivePerParticipantCostUsdCents: number
): number {
  if ((incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL || incentive >= 0) && incentiveType && recruitType) {
    const incentiveUSD =
      incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
        ? incentive * USD_PER_POINT
        : incentiveType === PROJECT_INCENTIVE_TYPES.CASH
        ? incentive
        : externalIncentivePerParticipantCostUsdCents / 100;
    const minimumIncentiveUSD =
      incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
        ? MIN_INCENTIVE_POINTS[recruitType] * USD_PER_POINT
        : incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
        ? MIN_INCENTIVE_USD[recruitType]
        : 0;
    const incentiveFee = incentiveUSD * INCENTIVE_FEE_PERCENT[recruitType];
    const participantCost = incentiveUSD + incentiveFee;
    const minimumCost =
      Math.max(MIN_INCENTIVE_FEE_USD[recruitType], incentiveFee) + Math.max(minimumIncentiveUSD, incentiveUSD);

    return Math.max(participantCost, minimumCost);
  } else {
    return 0;
  }
}

export const flattenCharacteristics = (characteristics: any) => {
  // Prepare characteristic columns for the Panelist table
  const returnArray: any = [];

  characteristics.forEach((characteristic: any) => {
    const title = startCase(characteristic?.node?.shortName);
    if (characteristic?.node?.type === ELEMENT_TYPES.MULTI_SELECT) {
      characteristic?.node?.answers?.forEach((answer: any) => {
        returnArray.push({
          title: EllipsisCell({ value: `${title}: ${answer?.text}` }),
          key: characteristic?.node?.importKey,
          width: 150,
          dataIndex: ["node", "responses", "edges"],
          render: (edges: any[]) => {
            const matchingResponse = edges.find(
              edge =>
                edge?.node?.characteristic?.id === characteristic?.node?.id && edge?.node?.answer?.id === answer?.id
            );
            const cellContents: { value: string | null; node: ReactNode | null } =
              matchingResponse?.node.answer?.other &&
              matchingResponse.node.answer.userSpecified &&
              matchingResponse.node.customAnswer
                ? // selected "please specify" characteristics display as "X: (user text)"
                  {
                    value: `X: ${matchingResponse.node.customAnswer}`,
                    node: (
                      <>
                        X: <em>{matchingResponse.node.customAnswer}</em>
                      </>
                    ),
                  }
                : matchingResponse
                ? // selected non-"please specify" characteristics display as "X"
                  { value: "X", node: null }
                : // everything else gets no display
                  { value: null, node: null };
            return CharacteristicCell(cellContents.value, cellContents.node);
          },
        });
      });
    } else if (characteristic?.node?.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
      returnArray.push({
        title: EllipsisCell({ value: title }),
        key: characteristic?.node?.importKey,
        width: 150,
        dataIndex: ["node", "responses", "edges"],
        render: (edges: any[]) => {
          const matchingResponses = edges.filter(edge => edge?.node?.characteristic?.id === characteristic?.node?.id);
          const matchingResponseText = matchingResponses
            .map(matchingResponse => `${matchingResponse?.node?.row?.text}: ${matchingResponse?.node?.textValue}`)
            .join(" | ");
          return CharacteristicCell(matchingResponseText || null);
        },
      });
    } else {
      returnArray.push({
        title: EllipsisCell({ value: title }),
        key: characteristic?.node?.importKey,
        width: 150,
        dataIndex: ["node", "responses", "edges"],
        render: (edges: any[]) => {
          const matchingResponses = edges.filter(edge => edge?.node?.characteristic?.id === characteristic?.node?.id);
          // join results with " | "
          const cellContents = matchingResponses.reduce<{ value: string | null; node: ReactNode | null }>(
            (result, matchingResponse) =>
              matchingResponse?.node.answer?.other &&
              matchingResponse.node.answer.userSpecified &&
              matchingResponse.node.customAnswer
                ? // selected "please specify" characteristics display as "characteristic: (user text)"
                  {
                    value:
                      (result.value ? `${result.value} | ` : "") +
                      `${matchingResponse.node.textValue}: ${matchingResponse.node.customAnswer}`,
                    node: (
                      <>
                        {result.node && <>{result.node} | </>}
                        {matchingResponse.node.textValue}: <em>{matchingResponse.node.customAnswer}</em>
                      </>
                    ),
                  }
                : matchingResponse
                ? // selected non-"please specify" characteristics display just the characteristic name
                  {
                    value: (result.value ? `${result.value} | ` : "") + matchingResponse.node.textValue,
                    node: (
                      <>
                        {result.node && <>{result.node} | </>}
                        {matchingResponse.node.textValue}
                      </>
                    ),
                  }
                : // everything else carries on the display built in reduce()
                  result,
            // start with an empty object
            { value: null, node: null }
          );
          return CharacteristicCell(cellContents.value, cellContents.node);
        },
      });
    }
  });

  return returnArray;
};

export const getFaviconEl = () => {
  // Returns the favicon element so it can be changed for whitelabelled pages
  return document.getElementById("favicon") as HTMLLinkElement;
};

export function pluralize(count: number, pluralString: string, singularString: string) {
  return count !== 1 ? pluralString : singularString;
}

// Clamps a value between min and max
export function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(min, val), max);
}

export const numberFormatter = new Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 0 });

export const currencyFormatter = (locale: string, currency: string) =>
  new Intl.NumberFormat(locale, { style: "currency", currency });

export const usdFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

export const usdFormatterRounded = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

// If value is a whole number it returns the value converted to USD with no decimals
// else it returns the USD value with decimals
export class usdFormatterNeat {
  static format(value: number) {
    if (Number.isInteger(value)) {
      return usdFormatterRounded.format(value);
    } else {
      return usdFormatter.format(value);
    }
  }
}

// Takes data from a DataTransfer object (like clipboard contents) and splits by tabs/newlines and trims entries,
// rejecting any that resulted in empty strings
// e.g. "hello \n world \t ! \n   \n\t" returns ["hello", "world", "!"]
export function cleanDataTransferText(dataTransfer: DataTransfer): string[] {
  return dataTransfer
    .getData("text")
    .split(/[\n\t]/)
    .map(s => s.trim())
    .filter(s => s.length);
}

// Redirects user to Stripe to add a payment method
export const managePaymentMethod = async (originUrl: string) => {
  const stripe: any = await stripePromise;
  const response = await axios.request({
    method: "post",
    url: `${getApiBaseUrl()}/manage-payment-method?origin_url=${encodeURIComponent(originUrl)}`,
    headers: getAuthorizationHeader(),
  });

  const result = await stripe.redirectToCheckout({
    sessionId: response.data.id,
  });

  if (result.error) {
    message.error(result.error.message);
  }
};
export async function downloadInBackground(url: string, filename: string) {
  const result = await axios.request({ url, headers: getAuthorizationHeader(), responseType: "blob" });
  const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export async function downloadInBackgroundNoFile(url: string) {
  await axios.request({ url, headers: getAuthorizationHeader() });
}

export function hasConsentedToCookies(): boolean {
  // Returns true if the user has consented to cookies, false otherwise
  type cookieConsentValue = "true" | "false" | undefined;
  const cookieConsent = getCookieConsentValue() as cookieConsentValue;
  return cookieConsent === "true";
}

export function ignoreWheel(evt: React.WheelEvent<HTMLInputElement>) {
  evt.currentTarget.blur();
}

export function validateEmail(email: string) {
  const emailInput = document.createElement("input");
  emailInput.type = "email";
  emailInput.required = true;
  emailInput.value = email;

  return emailInput.checkValidity();
}

// returns a zero or one-element array, useful when spreading a conditional array element
// e.g. ["always", ...maybeElement(Math.random() < 0.5, "sometimes"), "always"]
export const maybeElement = <T,>(condition: boolean, element: T): T[] => (condition ? [element] : []);

// like array.join for ReactNodes
export const join = <T,>(items: T[], map: (item: T) => ReactNode, separator: ReactNode) =>
  items.reduce(
    (acc, cur, i) => (
      <>
        {acc}
        {i > 0 && separator}
        {map(cur)}
      </>
    ),
    <></>
  );

// returns the source array with the element at index excluded
export const exceptIndex = <T,>(elements: T[], index: number) => elements.filter((_, i) => i !== index);

export const getApiBaseUrl = () => runtimeEnv().REACT_APP_API_URL;
