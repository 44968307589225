import { graphql } from "babel-plugin-relay/macro";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import {
  PORTAL_STUDY_STATUSES,
  PROJECT_TYPES,
  RESPONDENT_MASTER_STATUSES,
  studyTypeMap,
  SUCCESS_COMPLETED_STATUSES,
} from "../../../constants";
import i18n from "../../../i18n";
import { maybeElement } from "../../../utils/misc";
import type { AvailableStudies_user$data } from "../../../__generated__/AvailableStudies_user.graphql";
import { PointsCell, StatusCell, TextCell } from "../PortalCells";
import PortalTable from "../PortalTable";

const availableSerializer = (user: AvailableStudies_user$data) =>
  user.panelist?.availableRecruits
    ?.filter(
      recruit =>
        // no custom portal, show all studies
        !user.panelist?.customPanelistPortal ||
        // custom portal, only show studies from that portal's tenant
        recruit.study.tenant.id === user.panelist?.customPanelistPortal.tenant.id
    )
    .map(({ study, ...recruit }) => {
      const respondent = study?.screener?.respondents?.edges[0]?.node;

      const studyType = studyTypeMap[study?.type as keyof typeof studyTypeMap].toLowerCase();

      const linkScreener = `${recruit.screenerLink || study?.screenerLink}?pl_id=${user?.panelist?.dId}`;

      const {
        link = "",
        statusType = "",
        tooltipText = "",
      }: {
        link?: string;
        statusType?: string;
        tooltipText?: string;
      } = !respondent
        ? // no respondent — go to screener
          {
            link: linkScreener,
            statusType: PORTAL_STUDY_STATUSES.APPLY,
            tooltipText: i18n.t(
              "portal.cards.available-studies.apply",
              "You have been specifically selected to apply for this {{studyType}}!",
              { studyType }
            ),
          }
        : (study.type === PROJECT_TYPES.INTERVIEW || study.type === PROJECT_TYPES.FOCUS_GROUP) &&
          respondent.approvalStatus === "A"
        ? // approved to schedule — go to booking
          {
            link: `${study.screenerLink}/schedule?r=${respondent.dId}`,
            statusType: PORTAL_STUDY_STATUSES.SCHEDULE_NOW,
            tooltipText: i18n.t(
              "portal.cards.available-studies.schedule-now",
              "You have been approved, schedule your {{studyType}} now!",
              { studyType }
            ),
          }
        : (
            respondent.completedOn
              ? // incomplete third-party survey — opening screener should open exit link to continue
                !SUCCESS_COMPLETED_STATUSES.includes(
                  (respondent.masterStatus as RESPONDENT_MASTER_STATUSES) || RESPONDENT_MASTER_STATUSES.EMPTY
                )
              : // incomplete local screener
                true
          )
        ? // incomplete third-party or local screener — link to screener
          {
            link: linkScreener,
            statusType: PORTAL_STUDY_STATUSES.CONTINUE,
            tooltipText: i18n.t(
              "portal.cards.available-studies.continue",
              "Complete your application to be considered for this opportunity"
            ),
          }
        : {};

      return {
        name: study.namePublic || study.screener?.title || "Research study",
        link,
        reward: {
          incentiveType: study.incentiveType,
          incentivePoints: recruit.incentivePoints,
          incentive: recruit.incentive,
        },
        status: {
          studyAvailabilitySlot: respondent?.scheduledSlots?.[0]?.studyAvailabilitySlot,
          statusType,
          tooltipText,
        },
      };
    }) ?? [];

const AvailableStudies = ({ user }: { user: AvailableStudies_user$data }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("portal.cards.available-studies.table-header.name", "Name"),
        accessor: (row: any): any => {
          return {
            text: row.name,
            width: "268px",
          };
        },
        Cell: TextCell,
      },
      ...maybeElement(!user.panelist?.customPanelistPortal?.hidePointsAndRedemption, {
        Header: t("portal.cards.available-studies.table-header.reward", "Reward"),
        accessor: "reward",
        Cell: PointsCell,
      }),
      {
        Header: "",
        id: "available-studies-status",
        accessor: (row: any) => {
          return {
            status: row.status,
            text: row.name,
            link: row.link,
            userdId: user?.dId,
            panelistdId: user?.panelist?.dId,
            tenantName: user.panelist?.customPanelistPortal?.tenant.name || "HubUX",
            tableName: t("portal.cards.available-studies.studies-to-apply-for", "Studies To Apply For"),
          };
        },
        Cell: StatusCell,
      },
    ],
    [user, t]
  );

  return (
    <PortalTable
      columns={columns}
      data={useMemo(() => availableSerializer(user), [user])}
      blankText={t("portal.cards.available-studies.no-available-surveys", "No available surveys at the moment")}
    />
  );
};

export default createFragmentContainer(AvailableStudies, {
  user: graphql`
    fragment AvailableStudies_user on UserNode {
      email
      id
      dId
      panelist {
        dId
        customPanelistPortal {
          hidePointsAndRedemption
          tenant {
            id
            name
          }
        }
        availableRecruits {
          incentive
          incentivePoints
          screenerLink
          study {
            namePublic
            screenerLink
            incentiveType
            type
            screener {
              title
              respondents {
                edges {
                  node {
                    dId
                    approvalStatus
                    status
                    masterStatus
                    startedOn
                    completedOn
                    scheduledSlots {
                      date
                      start
                      studyAvailabilitySlot {
                        dId
                        meetingLink
                        start
                      }
                    }
                  }
                }
              }
            }
            tenant {
              id
            }
          }
        }
      }
    }
  `,
});
