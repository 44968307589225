/**
 * @generated SignedSource<<1b9f04f117bf883741b65e49e533727c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FieldReportSegmentTable_segments$data = {
  readonly rows: ReadonlyArray<{
    readonly overQuota: number | null;
    readonly qualified: number | null;
    readonly quota: number | null;
    readonly terminants: number | null;
    readonly terminate: boolean | null;
    readonly text: string | null;
  } | null> | null;
  readonly " $fragmentType": "FieldReportSegmentTable_segments";
};
export type FieldReportSegmentTable_segments$key = {
  readonly " $data"?: FieldReportSegmentTable_segments$data;
  readonly " $fragmentSpreads": FragmentRefs<"FieldReportSegmentTable_segments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FieldReportSegmentTable_segments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportSegmentRowNode",
      "kind": "LinkedField",
      "name": "rows",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quota",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qualified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overQuota",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminants",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FieldReportSegmentNode",
  "abstractKey": null
};

(node as any).hash = "7fe2592d47bd724521df647fd5efa1e2";

export default node;
