/**
 * @generated SignedSource<<332f05922caf507195db7f0d093071fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelReport_panel$data = {
  readonly lastHealthRun: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"CharacteristicTable_panel" | "SummaryData_panel">;
  readonly " $fragmentType": "PanelReport_panel";
};
export type PanelReport_panel$key = {
  readonly " $data"?: PanelReport_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelReport_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelReport_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastHealthRun",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CharacteristicTable_panel"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SummaryData_panel"
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "a298c70432e9a77d026aabf68a60ec45";

export default node;
