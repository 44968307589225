/**
 * @generated SignedSource<<95dbd66d6e3367c5bc422eed2b4f83a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectFieldReport_study$data = {
  readonly dId: any | null;
  readonly fieldReport: {
    readonly elements: ReadonlyArray<{
      readonly type: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"FieldReportQuestionTable_element">;
    } | null> | null;
    readonly responseSummary: {
      readonly " $fragmentSpreads": FragmentRefs<"ResponseSummaryTable_responseSummary">;
    } | null;
    readonly segments: {
      readonly " $fragmentSpreads": FragmentRefs<"FieldReportSegmentTable_segments">;
    } | null;
  } | null;
  readonly tenant: {
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ExportFieldReportButton_study">;
  readonly " $fragmentType": "ProjectFieldReport_study";
};
export type ProjectFieldReport_study$key = {
  readonly " $data"?: ProjectFieldReport_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectFieldReport_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectFieldReport_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportNode",
      "kind": "LinkedField",
      "name": "fieldReport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResponseSummaryNode",
          "kind": "LinkedField",
          "name": "responseSummary",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ResponseSummaryTable_responseSummary"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FieldReportElementNode",
          "kind": "LinkedField",
          "name": "elements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FieldReportQuestionTable_element"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FieldReportSegmentNode",
          "kind": "LinkedField",
          "name": "segments",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FieldReportSegmentTable_segments"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportFieldReportButton_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "89e28786978baa9131d688daed636617";

export default node;
