/**
 * @generated SignedSource<<ef765f5c63da750a6248589b310f5e31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortalProfile_user$data = {
  readonly panelist: {
    readonly customPanelistPortal: {
      readonly contactEmail: string | null;
      readonly customColor: string | null;
      readonly fadeBgBottom: boolean;
      readonly faviconUrl: string | null;
      readonly footerImageUrl: string | null;
      readonly headerMarkup: string;
      readonly headerMarkupEnabled: boolean;
      readonly heroAssetUrl: string | null;
      readonly heroBackgroundUrl: string | null;
      readonly hidePanelistProfile: boolean;
      readonly pageTitle: string | null;
      readonly privacyPolicyUrl: string | null;
      readonly termsLabel: string;
      readonly termsUrl: string | null;
    } | null;
    readonly id: string;
    readonly person: {
      readonly avatarUrl: string | null;
      readonly firstName: string | null;
      readonly id: string;
      readonly lastName: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"FormPersonAvatar_person" | "FormPerson_person">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"FormPanelistResponses_panelist">;
  } | null;
  readonly " $fragmentType": "PortalProfile_user";
};
export type PortalProfile_user$key = {
  readonly " $data"?: PortalProfile_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalProfile_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalProfile_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customPanelistPortal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fadeBgBottom",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "faviconUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "footerImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerMarkup",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerMarkupEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heroAssetUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heroBackgroundUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePanelistProfile",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pageTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "privacyPolicyUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "termsLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "termsUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonNode",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatarUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormPerson_person"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormPersonAvatar_person"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FormPanelistResponses_panelist"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "6c5b82436535a9c28a4c301263e2c019";

export default node;
