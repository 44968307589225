import { createGlobalStyle } from "styled-components";

export const AntStyle = createGlobalStyle`
  .ant-btn,
  .ant-radio-group .ant-radio-button-wrapper,
  .ant-typography strong {
    font-weight: 500;
  }

  .ant-typography code {
    border-radius: 6px;
  }

  .CookieConsent button {
    border-radius: 25px !important;
    font-weight: 500;
    font-family: inherit;
  }
`;
