/**
 * @generated SignedSource<<3d47bc9d1a18f40990a73cf34d5f4085>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteInput_study$data = {
  readonly id: string;
  readonly inviteMeetingNameInPerson: string;
  readonly inviteMeetingNameVirtual: string;
  readonly inviteMessageInPerson: string;
  readonly inviteMessageVirtual: string;
  readonly " $fragmentType": "InviteInput_study";
};
export type InviteInput_study$key = {
  readonly " $data"?: InviteInput_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteInput_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteInput_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteMeetingNameVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteMessageVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteMeetingNameInPerson",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteMessageInPerson",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "509acdc3ac46ec560e36cfae0a4a7e6e";

export default node;
