import { Dropdown, Spin, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import moment from "moment";
import { Suspense, useEffect, useState } from "react";
import { useFragment, useQueryLoader } from "react-relay";
import styled from "styled-components";

import { ButtonLabel } from "../..";
import { PRIMARY_TEXT_COLOR } from "../../../style";
import type { PanelReportChartsQuery as PanelReportChartsQueryType } from "../../../__generated__/PanelReportChartsQuery.graphql";
import type { PanelReport_panel$key } from "../../../__generated__/PanelReport_panel.graphql";

import CharacteristicTable from "./CharacteristicTable";
import { PanelReportCharts, PanelReportChartsQuery } from "./PanelReportCharts";
import SummaryData from "./SummaryData";

const { Title } = Typography;

const formatPeriodDays = (x: number) =>
  x === Infinity ? "All time" : `Last ${moment.duration(x, "days").format("M [months]")}`;

export const PanelReport = ({ panel: panelKey }: { panel: PanelReport_panel$key }) => {
  const panel = useFragment(
    graphql`
      fragment PanelReport_panel on Panel {
        lastHealthRun
        ...CharacteristicTable_panel
        ...SummaryData_panel
      }
    `,
    panelKey
  );

  const [queryReference, loadQuery] = useQueryLoader<PanelReportChartsQueryType>(PanelReportChartsQuery);

  const [periodDays, setPeriodDays] = useState(30);

  useEffect(
    () =>
      loadQuery(
        {
          orderBy: "datetime",
          since: moment()
            .subtract(periodDays === Infinity ? 3650 : periodDays, "days")
            .toDate(),
        },
        {
          fetchPolicy: "network-only",
        }
      ),
    [loadQuery, periodDays]
  );

  return (
    <Styled>
      <div className="report-header">
        <Title level={4} style={{ fontWeight: 500 }}>
          Panel health
        </Title>
        <Dropdown
          disabled={!queryReference}
          menu={{
            items: [30, 90, 365, Infinity].map(x => ({
              key: x.toString(),
              label: formatPeriodDays(x),
            })),
            onClick: ({ key }) => setPeriodDays(Number(key)),
            selectable: true,
          }}
        >
          <ButtonLabel
            icon="mdi:chevron-down"
            labelStyle={{ flexDirection: "row-reverse" }}
            text={formatPeriodDays(periodDays)}
            type="text"
          />
        </Dropdown>
      </div>
      {queryReference ? (
        <Suspense fallback={<Spin size="large" />}>
          <PanelReportCharts className="hub-report-charts" queryReference={queryReference} />
        </Suspense>
      ) : (
        <Spin size="large" />
      )}
      <SummaryData panel={panel} />
      <CharacteristicTable panel={panel} />
    </Styled>
  );
};

const Styled = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  .report-header {
    margin: -24px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .full-height {
    height: 1000px;
  }

  .hub-report-charts {
    height: 1000px;
    width: 100%;
  }

  .heading-text {
    padding: 12px 0 12px 24px;
    h1 {
      font-size: 27px;
      color: #333a4e;
      margin: 0;
    }
  }

  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-cell {
      color: ${PRIMARY_TEXT_COLOR} !important;

      :not(:first-child) {
        text-align: right;
      }

      :first-child {
        padding-left: 16px;
      }

      :last-child {
        padding-right: 16px;
      }
    }
  }
`;
