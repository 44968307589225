/**
 * @generated SignedSource<<56e6c8a1d63254d09e2d58730e45e9a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersTagColorChoices = "BL" | "CN" | "GN" | "GY" | "OG" | "PL" | "RD" | "YW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InputTags_tenant$data = {
  readonly tags: ReadonlyArray<{
    readonly color: ScreenersTagColorChoices;
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "InputTags_tenant";
};
export type InputTags_tenant$key = {
  readonly " $data"?: InputTags_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputTags_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputTags_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagNode",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "a62c63e40f105f9d07301aa5ccc5cc0b";

export default node;
