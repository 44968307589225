/**
 * @generated SignedSource<<ee1744b959e70722c0fa037973e54d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitKPIs_recruit$data = {
  readonly completedScreenerCount: number | null;
  readonly goal: number | null;
  readonly invitesSentCount: number | null;
  readonly needsReviewCount: number | null;
  readonly openedScreenerCount: number | null;
  readonly progressTowardGoalCount: number | null;
  readonly qualifiedCount: number | null;
  readonly recruitPersonCount: number | null;
  readonly startedScreenerCount: number | null;
  readonly status: ScreenersRecruitStatusChoices;
  readonly totalPayouts: any | null;
  readonly totalSpend: any | null;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentType": "RecruitKPIs_recruit";
};
export type RecruitKPIs_recruit$key = {
  readonly " $data"?: RecruitKPIs_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitKPIs_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitKPIs_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invitesSentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recruitPersonCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openedScreenerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedScreenerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedScreenerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsReviewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qualifiedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressTowardGoalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPayouts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSpend",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "149481e07a2282f6442582f9df7bb418";

export default node;
