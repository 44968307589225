/**
 * @generated SignedSource<<24da2a4ee2711be967ae7a29f0dacee3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ProjectsColumnQuery_Query$variables = {
  filterTagDids?: ReadonlyArray<string> | null;
  orderBy: string;
  projectStatus: ScreenersStudyStatusChoices;
  searchTerm?: string | null;
};
export type ProjectsColumnQuery_Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectsColumnPagination_viewer">;
  };
};
export type ProjectsColumnQuery_Query = {
  response: ProjectsColumnQuery_Query$data;
  variables: ProjectsColumnQuery_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterTagDids"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectStatus"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v5 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v6 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "projectStatus"
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 7
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "tags_In",
    "variableName": "filterTagDids"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsColumnQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "filterTagDids",
                "variableName": "filterTagDids"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ProjectsColumnPagination_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectsColumnQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "StudyNodeConnection",
            "kind": "LinkedField",
            "name": "studies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagNode",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyAvailabilitySlotConnection",
                        "kind": "LinkedField",
                        "name": "availabilitySlots",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StudyAvailabilitySlotEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StudyAvailabilitySlotNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "end",
                                    "storageKey": null
                                  },
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FieldReportNode",
                        "kind": "LinkedField",
                        "name": "fieldReport",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ResponseSummaryNode",
                            "kind": "LinkedField",
                            "name": "responseSummary",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TotalQualedNode",
                                "kind": "LinkedField",
                                "name": "totalNeeded",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TotalQualedNode",
                                "kind": "LinkedField",
                                "name": "totalStarts",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "qualified",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TotalQualedNode",
                                "kind": "LinkedField",
                                "name": "rsvpAccepted",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [
              "orderBy",
              "status",
              "searchTerm",
              "tags_In"
            ],
            "handle": "connection",
            "key": "Studies_studies",
            "kind": "LinkedHandle",
            "name": "studies"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d33b3cb84f6baf279ebed3c9bbc19e7",
    "id": null,
    "metadata": {},
    "name": "ProjectsColumnQuery_Query",
    "operationKind": "query",
    "text": "query ProjectsColumnQuery_Query(\n  $filterTagDids: [ID!]\n  $projectStatus: ScreenersStudyStatusChoices!\n  $orderBy: String!\n  $searchTerm: String\n) {\n  viewer {\n    ...ProjectsColumnPagination_viewer_1u7K6K\n  }\n}\n\nfragment ProjectCard_study on StudyNode {\n  id\n  dId\n  name\n  tags {\n    id\n    ...TagTag_tag\n  }\n  status\n  type\n  availabilitySlots {\n    edges {\n      node {\n        start\n        end\n        id\n      }\n    }\n  }\n  scheduled\n  owner {\n    dId\n    fullName\n    email\n    id\n  }\n  fieldReport {\n    responseSummary {\n      totalNeeded {\n        total\n      }\n      totalStarts {\n        total\n        qualified\n      }\n      rsvpAccepted {\n        total\n      }\n    }\n  }\n}\n\nfragment ProjectsColumnPagination_viewer_1u7K6K on Viewer {\n  studies(first: 7, orderBy: $orderBy, status: $projectStatus, searchTerm: $searchTerm, tags_In: $filterTagDids) {\n    edges {\n      node {\n        id\n        dId\n        ...ProjectCard_study\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TagTag_tag on TagNode {\n  color\n  name\n}\n"
  }
};
})();

(node as any).hash = "6a0be1f32552dc54f7cc9b73cb62ba21";

export default node;
