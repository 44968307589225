import { isEqualSimple } from "@react-hookz/deep-equal";
import { useSessionStorageValue, useUpdateEffect } from "@react-hookz/web";
import { Alert, Form } from "antd";
import { FormInstance } from "antd/es";
import { arrayMoveImmutable } from "array-move";
import { graphql } from "babel-plugin-relay/macro";
import { uniq } from "lodash-es";
import { useEffect, useState, type CSSProperties } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES, PersonFields, PERSON_FIELDS } from "../../constants";
import { WritableDeep } from "../../types";
import type { PanelistCharacteristics_panelist$data } from "../../__generated__/PanelistCharacteristics_panelist.graphql";
import type { PanelistCharacteristics_tenant$data } from "../../__generated__/PanelistCharacteristics_tenant.graphql";
import type { Answer } from "../Panel";
import PanelistCharacteristicCell from "../Panel/PanelistCharacteristicCell";
import PastParticipationCell from "../Panel/PastParticipationCell";
import PanelistRow from "./PanelistModalRow";

const PanelistCharacteristics = ({
  editing,
  panelist,
  tenant: { panel },
  form,
  onFinish,
  style,
}: {
  panelist: PanelistCharacteristics_panelist$data;
  tenant: PanelistCharacteristics_tenant$data;
  editing: boolean;
  form: FormInstance;
  onFinish: (values: any, layout: readonly string[]) => void;
  style?: CSSProperties;
}) => {
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(makeFormValues(panelist));
  }, [panelist, form]);

  const responseInitValues = panelist?.selectedResponses.reduce((initValuesObj, currentValue) => {
    // find out if initially this field had any data in it
    const selectedAnswers = currentValue.answers.filter(answer => answer.selected);
    if (currentValue.type === ELEMENT_TYPES.MULTI_SELECT) {
      initValuesObj[currentValue.key] = selectedAnswers.length ? selectedAnswers.map(answer => answer.key) : [];
    } else if (selectedAnswers.length && currentValue.type === ELEMENT_TYPES.SINGLE_SELECT) {
      initValuesObj[currentValue.key] = selectedAnswers[0]?.key;
    } else if (
      selectedAnswers.length &&
      currentValue.type in [ELEMENT_TYPES.OPENEND, ELEMENT_TYPES.DATE, ELEMENT_TYPES.NUMBER]
    ) {
      initValuesObj[currentValue.key] = selectedAnswers[0]?.text;
    }
    return initValuesObj;
  }, {} as any);

  const personInitValues = {
    first_name: panelist?.person?.firstName,
    last_name: panelist?.person?.lastName,
    email: panelist?.person?.email,
    phone_number: panelist?.person?.phoneNumber,
  };

  const initValues = {
    ...responseInitValues,
    ...personInitValues,
  };

  const personFieldSortOrder: PersonFields = {
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
    phoneNumber: "phone_number",
  };

  type LayoutScope = "custom" | "person" | "selectedResponses";
  const createPanelistLayout = () =>
    uniq([
      ...(panel?.panelistLayout ?? []),
      ...Object.keys(personFieldSortOrder).map(x => `person::${x}`),
      "custom::studyParticipants",
      ...panelist.selectedResponses.map(x => `selectedResponses::${x.key}`),
    ] as `${LayoutScope}::${string}`[]);

  const [layout, setLayout] = useState(createPanelistLayout());
  const [layoutTouched, setLayoutTouched] = useState(false);

  const resetLayout = () => {
    const _layout = createPanelistLayout();
    if (!isEqualSimple(_layout, layout)) setLayout(_layout);
    setLayoutTouched(false);
  };
  useUpdateEffect(resetLayout, [panel?.panelistLayout]);
  useUpdateEffect(() => {
    if (!editing) resetLayout();
  }, [editing]);

  const [layoutTouchedDismissed, setLayoutTouchedDismissed] = useSessionStorageValue(
    "hub-panelist-characteristics-layout-touched-dismissed",
    false
  );

  return (
    <Styled style={style}>
      {editing && layoutTouched && !layoutTouchedDismissed && (
        <div className="hub-alert-order-touched">
          <Alert
            closable
            message="Changes to the order of characteristics will apply to all panelists, for all members of your team."
            onClose={() => setLayoutTouchedDismissed(true)}
            type="info"
          />
        </div>
      )}
      <Form form={form} onFinish={values => onFinish(values, layout)} initialValues={initValues}>
        <DragDropContext
          onDragEnd={result => {
            if (result.destination?.index !== undefined) {
              setLayout(arrayMoveImmutable(layout, result.source.index, result.destination.index));
              setLayoutTouched(true);
            }
          }}
        >
          <Droppable droppableId="panelist-layout">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {/* creates rows for person fields */}
                {layout.map((x, i) => (
                  <Draggable isDragDisabled={!editing} key={x} draggableId={x} index={i}>
                    {(provided, snapshot) => {
                      const [scope, ..._key] = x.split("::") as [LayoutScope, string[]];
                      const key = _key.join("::");

                      return (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {scope === "custom" ? (
                            key === "studyParticipants" ? (
                              <PanelistRow editing={editing} questionText="Past Participation" key={key}>
                                <PastParticipationCell
                                  studies={
                                    panelist.studyParticipants?.map(node => {
                                      return {
                                        name: node?.study.name ?? "",
                                        startedOn: node?.respondent?.startedOn,
                                        status: node?.respondent?.masterStatus ?? "",
                                        tagKeys: (node?.study.tags as any[]) ?? [],
                                      };
                                    }) ?? null
                                  }
                                />
                              </PanelistRow>
                            ) : null
                          ) : scope === "person" ? (
                            <PanelistRow
                              editing={editing}
                              questionText={PERSON_FIELDS[key as keyof PersonFields] || ""}
                              key={key}
                            >
                              <PanelistCharacteristicCell
                                form={form}
                                editing={editing}
                                type={"person"}
                                answers={[
                                  {
                                    key: personFieldSortOrder[key as keyof PersonFields],
                                    text: panelist.person ? panelist?.person[key as keyof PersonFields] || "" : "",
                                    selected: true,
                                  },
                                ]}
                                answerKey={personFieldSortOrder[key as keyof PersonFields]}
                              />
                            </PanelistRow>
                          ) : scope === "selectedResponses" ? (
                            (() => {
                              const response = panelist.selectedResponses.find(x => x.key === key);
                              return response ? (
                                <PanelistRow editing={editing} questionText={response.text ?? ""}>
                                  <PanelistCharacteristicCell
                                    form={form}
                                    editing={editing}
                                    type={response.type}
                                    answers={response.answers as WritableDeep<Answer[]>}
                                    answerKey={response.key}
                                  />
                                </PanelistRow>
                              ) : null;
                            })()
                          ) : null}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form>
    </Styled>
  );
};

const makeFormValues = (panelist: any) =>
  (panelist.selectedResponses as any).reduce((a: any, v: any) => {
    const answerIds = v.answers.filter((o: any) => o.selected).map((o: any) => o.key);
    switch (v.type) {
      case ELEMENT_TYPES.SINGLE_SELECT:
        if (answerIds.length > 0) {
          return {
            ...a,
            [v.key]: answerIds[0],
          };
        } else {
          return a;
        }
      case ELEMENT_TYPES.MULTI_SELECT:
        if (answerIds.length > 0) {
          return {
            ...a,
            [v.key]: answerIds,
          };
        }
        return answerIds;
      default:
        return a;
    }
  }, {});

const Styled = styled.div`
  height: 86%;
  overflow: auto;

  .ant-table-thead > tr > th {
    font-weight: bold;
  }

  .hub-alert-order-touched {
    background-color: white;
    padding: 8px;
  }
`;

export default createFragmentContainer(PanelistCharacteristics, {
  panelist: graphql`
    fragment PanelistCharacteristics_panelist on PanelistNode {
      person {
        email
        phoneNumber
        firstName
        lastName
      }
      id
      studyParticipants {
        study {
          name
          tags {
            ...TagTag_tag
          }
        }
        respondent {
          masterStatus
          startedOn
        }
      }
      selectedResponses {
        key
        text
        type
        answers {
          key
          text
          row
          rowText
          selected
          other
          userSpecified
          customAnswer
        }
      }
    }
  `,
  tenant: graphql`
    fragment PanelistCharacteristics_tenant on TenantNode {
      panel {
        id
        panelistLayout
      }
    }
  `,
});
