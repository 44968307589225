/**
 * @generated SignedSource<<3243335386fbc0490892a3da889c445b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RegistrationPageMutation$variables = {
  email: string;
  emailVerificationCode: string;
  firstName: string;
  lastName: string;
  password: string;
  tenantName: string;
};
export type RegistrationPageMutation$data = {
  readonly registerUser: {
    readonly tokenSet: {
      readonly accessToken: string;
      readonly refreshToken: string;
    } | null;
    readonly user: {
      readonly dId: any | null;
      readonly email: string;
      readonly fullName: string | null;
      readonly id: string;
      readonly isPanelist: boolean;
      readonly panelist: {
        readonly dId: any | null;
        readonly tenants: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly name: string;
            } | null;
          } | null>;
        } | null;
      } | null;
      readonly profile: {
        readonly tenant: {
          readonly dId: any | null;
          readonly id: string;
          readonly name: string;
          readonly vpmAccountId: number | null;
        };
      } | null;
      readonly vpmUserId: number | null;
    };
  } | null;
};
export type RegistrationPageMutation = {
  response: RegistrationPageMutation$data;
  variables: RegistrationPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "emailVerificationCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantName"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "emailVerificationCode",
        "variableName": "emailVerificationCode"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "tenantName",
        "variableName": "tenantName"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPanelist",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantNode",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vpmAccountId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vpmUserId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "TokenSet",
  "kind": "LinkedField",
  "name": "tokenSet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refreshToken",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "RegisterUserPayload",
        "kind": "LinkedField",
        "name": "registerUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNodeConnection",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RegistrationPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "RegisterUserPayload",
        "kind": "LinkedField",
        "name": "registerUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNodeConnection",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a924b3ed866a7cc52edf3ee8d5aae17f",
    "id": null,
    "metadata": {},
    "name": "RegistrationPageMutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationPageMutation(\n  $firstName: String!\n  $lastName: String!\n  $email: String!\n  $password: String!\n  $tenantName: String!\n  $emailVerificationCode: String!\n) {\n  registerUser(input: {firstName: $firstName, lastName: $lastName, email: $email, password: $password, tenantName: $tenantName, emailVerificationCode: $emailVerificationCode}) {\n    user {\n      id\n      dId\n      email\n      fullName\n      isPanelist\n      panelist {\n        dId\n        tenants {\n          edges {\n            node {\n              name\n              id\n            }\n          }\n        }\n        id\n      }\n      profile {\n        tenant {\n          id\n          dId\n          name\n          vpmAccountId\n        }\n        id\n      }\n      vpmUserId\n    }\n    tokenSet {\n      accessToken\n      refreshToken\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1516be7fc6238d8e2312360d1a73d3da";

export default node;
