import { useUpdateEffect } from "@react-hookz/web";
import { Form, type FormInstance } from "antd";
import type { NamePath } from "antd/lib/form/interface";
import { useCallback, useMemo } from "react";
import type { TupleToUnion } from "type-fest";

import type { ResponseValidation } from "../types";

export const responseValidationFormKeys = ["responseValidation", "target", "target2"] as const;

export type ResponseValidationFormInput = Pick<ResponseValidation, TupleToUnion<typeof responseValidationFormKeys>>;

export type ResponseValidationFormProps<T> = {
  form?: FormInstance<T>;
  onReset?: (input: { responseValidation: string | null; target: number | null; target2: number | null }) => any;
} & (T extends ResponseValidationFormInput ? { formRootPath?: undefined } : { formRootPath: NamePath });

export const useResponseValidationForm = <T = ResponseValidationFormInput>(
  { form: _form, formRootPath, onReset }: ResponseValidationFormProps<T>,
  resetDeps: readonly any[]
) => {
  const [form] = Form.useForm(_form);

  const fieldPaths = useMemo(
    () => (formRootPath ? responseValidationFormKeys.map(x => [formRootPath, x].flat()) : responseValidationFormKeys),
    [formRootPath]
  );

  const reset = useCallback(() => {
    // @ts-expect-error resetFields don't like tuples
    form.resetFields(fieldPaths);

    onReset?.({
      responseValidation: null,
      target: null,
      target2: null,
    });
  }, [fieldPaths, form, onReset]);
  useUpdateEffect(() => reset(), resetDeps);

  return [form, reset] as const;
};
