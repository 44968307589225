import outlineArchive from "@iconify-icons/ic/outline-archive";
import { useUpdateEffect } from "@react-hookz/web";
import { App, Button, Divider, Form, Input, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { EmailTemplateContent } from "../../constants";
import { GRAY_5 } from "../../style";
import { mutation, useConfirm } from "../../utils";
import type { EditInvitationEmailModal_recruit$data } from "../../__generated__/EditInvitationEmailModal_recruit.graphql";
import type { EditInvitationEmailModal_tenant$data } from "../../__generated__/EditInvitationEmailModal_tenant.graphql";
import { EmailInputs } from "../Communication/EmailInputs";
import { SaveTemplateModal } from "../Communication/Templates/SaveTemplateModal";
import { SelectTemplateModal } from "../Communication/Templates/SelectTemplateModal";
import { DetailsInput } from "../Configure/DetailsInput";
import { IconButton } from "../IconButton";

type Props = {
  tenant: EditInvitationEmailModal_tenant$data;
  recruit: EditInvitationEmailModal_recruit$data;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};
const _EditInvitationEmailModal: React.FC<Props> = ({ tenant, recruit, visible, setVisible }) => {
  const { notification } = App.useApp();

  const [form] = Form.useForm();
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useUpdateEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [recruit, visible, form]);

  const saveMeta = async (meta: any) =>
    mutation({
      variables: { input: { recruitId: recruit.id, meta } },
      mutation: graphql`
        mutation EditInvitationEmailModal_updateRecruit_Mutation($input: UpdateRecruitInput!) {
          updateRecruit(input: $input) {
            recruit {
              meta
            }
          }
        }
      `,
    });

  const handleTemplateSelected = (emailTemplate: EmailTemplateContent) => form.setFieldsValue(emailTemplate);

  const handleSaveTemplate = async () => {
    try {
      await form.validateFields();
      setShowSaveAsTemplateModal(true);
    } catch {}
  };

  const confirmUseDefault = useConfirm({
    title: "Revert to default",
    content: "Are you sure you want to discard your customized invitation and use the default?",
    okText: "Use default invitation",
  });
  const handleUseDefault = async () => {
    if (!(await confirmUseDefault())) return;

    setIsSaving(true);
    try {
      await saveMeta({ customized: false, replyTo: "", subject: "", message: "", ctaLabel: "" });
      notification.success({
        message: "Reverted invitation to default",
        description: "We'll use your default template when inviting recruits.",
      });
      setVisible(false);
    } finally {
      setIsSaving(false);
    }
  };

  const handleOk = async () => {
    try {
      setIsSaving(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      await saveMeta({ customized: true, ...values });
      notification.success({
        message: "Saved successfully",
        description: "We'll use your customized invitation when inviting recruits.",
      });
      setVisible(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledModal
      centered
      open={visible}
      title="Edit the invitation email"
      closable={false}
      footer={null}
      onCancel={() => setVisible(false)}
      onOk={handleOk}
      okText="Done"
      okButtonProps={{ loading: isSaving }}
      width="80%"
    >
      <SelectTemplateModal
        tenant={tenant}
        visible={showSelectTemplateModal}
        setVisible={setShowSelectTemplateModal}
        title="Select a template"
        onTemplateSelected={handleTemplateSelected}
      />
      <SaveTemplateModal
        emailTemplate={{
          replyTo: form.getFieldValue("replyTo") ?? "",
          subject: form.getFieldValue("subject"),
          message: form.getFieldValue("message"),
        }}
        tenant={tenant}
        visible={showSaveAsTemplateModal}
        setVisible={setShowSaveAsTemplateModal}
      />
      {!!tenant.emailTemplates?.edges.length && (
        <>
          <IconButton icon={outlineArchive} type="ghost" onClick={() => setShowSelectTemplateModal(true)}>
            Use a template
          </IconButton>
          <Divider />
        </>
      )}
      <Form form={form} initialValues={recruit.meta} onFinish={handleOk}>
        <EmailInputs
          replyToPlaceholder="Enter the email"
          minMaxMessageRows={[3, 5]}
          supportedMessageVariables={["%recipient.first_name%", "%recipient.incentive_amount%", "%study.name%"]}
        />
        <DetailsInput
          title="Survey button label"
          optional
          inputs={
            <Form.Item name="ctaLabel">
              <Input maxLength={50} placeholder="Enter the button label" />
            </Form.Item>
          }
          description='This is the text of the survey link, for example "Take me to the survey!"'
        />
        <div className="edit-invitation-buttons">
          <Button onClick={() => setVisible(false)} type="link">
            Cancel
          </Button>
          {recruit.meta?.customized && (
            <Button onClick={handleUseDefault} type="link">
              Revert to default
            </Button>
          )}
          <Button onClick={handleSaveTemplate} className="save-as-template" type="link">
            Save as template
          </Button>
          <Button onClick={handleOk} type="primary">
            Save
          </Button>
        </div>
      </Form>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .edit-invitation-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;

    .save-as-template {
      border: 1px solid ${GRAY_5};
    }
  }
`;

export const EditInvitationEmailModal = createFragmentContainer(_EditInvitationEmailModal, {
  tenant: graphql`
    fragment EditInvitationEmailModal_tenant on TenantNode {
      emailTemplates {
        edges {
          node {
            id
          }
        }
      }
      ...SelectTemplateModal_tenant
      ...SaveTemplateModal_tenant
    }
  `,
  recruit: graphql`
    fragment EditInvitationEmailModal_recruit on RecruitNode {
      id
      meta
    }
  `,
});
