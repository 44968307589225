/**
 * @generated SignedSource<<0ad2ea9098f253e0e83ba3503b677ae1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectCard_study$data = {
  readonly availabilitySlots: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly end: any;
        readonly start: any;
      } | null;
    } | null>;
  };
  readonly dId: any | null;
  readonly fieldReport: {
    readonly responseSummary: {
      readonly rsvpAccepted: {
        readonly total: number | null;
      } | null;
      readonly totalNeeded: {
        readonly total: number | null;
      } | null;
      readonly totalStarts: {
        readonly qualified: number | null;
        readonly total: number | null;
      } | null;
    } | null;
  } | null;
  readonly id: string;
  readonly name: string | null;
  readonly owner: {
    readonly dId: any | null;
    readonly email: string;
    readonly fullName: string | null;
  };
  readonly scheduled: any | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tags: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"TagTag_tag">;
  }>;
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentType": "ProjectCard_study";
};
export type ProjectCard_study$key = {
  readonly " $data"?: ProjectCard_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectCard_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCard_study",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TagNode",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TagTag_tag"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyAvailabilitySlotConnection",
      "kind": "LinkedField",
      "name": "availabilitySlots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyAvailabilitySlotEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyAvailabilitySlotNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "end",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportNode",
      "kind": "LinkedField",
      "name": "fieldReport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResponseSummaryNode",
          "kind": "LinkedField",
          "name": "responseSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TotalQualedNode",
              "kind": "LinkedField",
              "name": "totalNeeded",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TotalQualedNode",
              "kind": "LinkedField",
              "name": "totalStarts",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "qualified",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TotalQualedNode",
              "kind": "LinkedField",
              "name": "rsvpAccepted",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "eb112942ace028c1868b829d1871b6d2";

export default node;
