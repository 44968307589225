/**
 * @generated SignedSource<<1dab691801d66091316cba8b8a577f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateScreenerInput = {
  clientMutationId?: string | null;
  description?: string | null;
  overquotaExitLink?: string | null;
  quotaExitLink?: string | null;
  studyId: string;
  terminateRespondent?: string | null;
  terminatedExitLink?: string | null;
  title?: string | null;
};
export type ProjectScreenerPage_UpdateScreener_Mutation$variables = {
  input: UpdateScreenerInput;
};
export type ProjectScreenerPage_UpdateScreener_Mutation$data = {
  readonly updateScreener: {
    readonly screener: {
      readonly description: string | null;
      readonly id: string;
      readonly title: string | null;
    } | null;
  } | null;
};
export type ProjectScreenerPage_UpdateScreener_Mutation = {
  response: ProjectScreenerPage_UpdateScreener_Mutation$data;
  variables: ProjectScreenerPage_UpdateScreener_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateScreenerPayload",
    "kind": "LinkedField",
    "name": "updateScreener",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScreenerNode",
        "kind": "LinkedField",
        "name": "screener",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectScreenerPage_UpdateScreener_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectScreenerPage_UpdateScreener_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "971386defb2f53831af4ef030d4528cd",
    "id": null,
    "metadata": {},
    "name": "ProjectScreenerPage_UpdateScreener_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectScreenerPage_UpdateScreener_Mutation(\n  $input: UpdateScreenerInput!\n) {\n  updateScreener(input: $input) {\n    screener {\n      id\n      title\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19be761dc699c31c8375a3a411db9c70";

export default node;
