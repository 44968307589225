/**
 * @generated SignedSource<<5012d8842b2080a43b590216ca0fb143>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ParticipantBatchActions_user$data = {
  readonly profile: {
    readonly tenant: {
      readonly incentivesOrderFulfillmentVerificationDays: number | null;
      readonly requireStudyPayment: boolean;
    };
  } | null;
  readonly " $fragmentType": "ParticipantBatchActions_user";
};
export type ParticipantBatchActions_user$key = {
  readonly " $data"?: ParticipantBatchActions_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantBatchActions_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ParticipantBatchActions_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requireStudyPayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "incentivesOrderFulfillmentVerificationDays",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "deef5fcdd0731511007c267fda5fe154";

export default node;
