import { useUpdateEffect } from "@react-hookz/web";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { createFragmentContainer, useLazyLoadQuery } from "react-relay";
import { DEFAULT_PANELISTS_FETCH_COUNT } from "../../constants";
import { PanelRecruitFilterEnum, ScreenersRecruitStatusChoices } from "../../schema";
import { RecruitYourPanel_Query } from "../../__generated__/RecruitYourPanel_Query.graphql";
import { RecruitYourPanel_recruit$data } from "../../__generated__/RecruitYourPanel_recruit.graphql";
import { RecruitYourPanel_study$data } from "../../__generated__/RecruitYourPanel_study.graphql";
import RecruitMatch from "./RecruitMatch";

export const MATCH = "MATCHED_PANEL";
export const RECRUIT = "RECRUITED_PANEL";

function RecruitYourPanel({
  study,
  recruit,
}: {
  study: RecruitYourPanel_study$data;
  recruit: RecruitYourPanel_recruit$data;
}) {
  const [recruitFilter, setRecruitFilter] = useState(
    recruit.status === ScreenersRecruitStatusChoices.S
      ? PanelRecruitFilterEnum.RecruitedPanel
      : PanelRecruitFilterEnum.MatchedPanel
  );
  const data = useLazyLoadQuery<RecruitYourPanel_Query>(
    graphql`
      query RecruitYourPanel_Query(
        $count: Int!
        $cursor: String
        $panelFilters: GenericScalar
        $panelRecruitFilter: PanelRecruitFilterEnum
        $panelSettings: String
        $recruitId: String!
        $searchTerm: String
        $studyId: String!
      ) {
        viewer {
          study(studyId: $studyId) {
            ...RecruitMatch_study
          }
          recruit(recruitId: $recruitId) {
            status
            ...RecruitMatch_recruit
              @arguments(
                count: $count
                cursor: $cursor
                panelFilters: $panelFilters
                panelRecruitFilter: $panelRecruitFilter
                panelSettings: $panelSettings
                searchTerm: $searchTerm
              )
          }
          ...RecruitMatch_viewer
        }
      }
    `,
    {
      studyId: study.id,
      recruitId: recruit.id,
      count: DEFAULT_PANELISTS_FETCH_COUNT,
      panelRecruitFilter: recruitFilter,
    },
    { fetchPolicy: "network-only" }
  );
  useUpdateEffect(() => {
    setRecruitFilter(
      recruit.status === ScreenersRecruitStatusChoices.Ns
        ? PanelRecruitFilterEnum.MatchedPanel
        : PanelRecruitFilterEnum.RecruitedPanel
    );
  }, [recruit.status]);

  return (
    data.viewer?.study &&
    data.viewer?.recruit && (
      <RecruitMatch
        recruit={data.viewer?.recruit}
        recruitFilter={recruitFilter}
        setRecruitFilter={setRecruitFilter}
        study={data.viewer?.study}
        viewer={data.viewer}
      />
    )
  );
}

export default createFragmentContainer(RecruitYourPanel, {
  study: graphql`
    fragment RecruitYourPanel_study on StudyNode {
      id
    }
  `,
  recruit: graphql`
    fragment RecruitYourPanel_recruit on RecruitNode {
      id
      status
    }
  `,
});
