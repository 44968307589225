import { Button, Card } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Moment as MomentType } from "moment";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { Moment } from "..";
import { PAGE_WIDTH_MD } from "../../style";
import { validateEmail } from "../../utils/misc";
import { StudyEmailCard_card$data } from "../../__generated__/StudyEmailCard_card.graphql";

type Props = {
  card: StudyEmailCard_card$data;
};
const StudyEmailCard: React.FC<Props> = ({ card }) => {
  const [participantExpanded, setParticipantExpanded] = useState<boolean>(false);

  return (
    <Styled
      size="small"
      title={
        <div className="card-header">
          {card.subject} &mdash; Email sent on <Moment format="MMM D YYYY, h:mm A">{card.created as MomentType}</Moment>
        </div>
      }
    >
      <div className="section">
        <span className="heading">To:</span>
        <div>
          <span className="text">
            {card.recipients?.length ?? 0} Participant(s)
            {!participantExpanded ? (
              <Button type="link" onClick={() => setParticipantExpanded(true)}>
                (click to show)
              </Button>
            ) : (
              <Button type="link" onClick={() => setParticipantExpanded(false)}>
                (click to hide)
              </Button>
            )}
            {participantExpanded && card.recipients?.length && (
              <div>
                {card.recipients
                  .filter((x): x is string => !!x)
                  .map((v, i) => (
                    <p key={i}>{validateEmail(v) ? <a href={`mailto:${v}`}>{v}</a> : <em>(email hidden)</em>}</p>
                  ))}
              </div>
            )}
          </span>
        </div>
      </div>
      <div className="section">
        <span className="heading">Message:</span>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: card.message.replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled(Card)`
  margin-bottom: 24px;
  max-width: ${PAGE_WIDTH_MD};

  .card-header {
    font-weight: 700;
  }

  .heading {
    font-weight: 500;
    padding-bottom: 8px;
  }

  .section {
    margin-bottom: 12px;
  }

  button {
    margin-left: 4px;
    padding: 0;
    font-size: 12px;
  }

  .text {
  }

  p {
    margin-bottom: 0;
  }
`;

export default createFragmentContainer(StudyEmailCard, {
  card: graphql`
    fragment StudyEmailCard_card on StudyEmailNode {
      recipients
      subject
      message
      created
    }
  `,
});
