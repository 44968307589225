import { Select } from "antd";
import moment from "moment";
import { InputDate } from "../Input";

import { ButtonNot } from "./ButtonNot";
import { FilterOp, type FilterSubtypeResponse, type PanelistFilter, type ParticipantFilter } from "./types";

export const InputFilterResponseValueDate = <T extends ParticipantFilter | PanelistFilter>({
  filter,
  setFilter,
}: {
  filter: T & { subtype: FilterSubtypeResponse.ValueDate };
  setFilter: (x: T) => void;
}) => (
  <div className="form-filter-row">
    <ButtonNot filter={filter} setFilter={setFilter} />
    <Select
      className="select-operator"
      defaultValue={FilterOp.Before}
      value={filter.op}
      onChange={op => setFilter({ ...filter, op })}
    >
      <Select.Option key="date_after" value={FilterOp.After}>
        After
      </Select.Option>
      <Select.Option key="date_before" value={FilterOp.Before}>
        Before
      </Select.Option>
    </Select>
    <InputDate
      className="input-values"
      format="YYYY-MM-DD"
      value={filter.values[0] ? moment(filter.values[0], "YYYY-MM-DD") : undefined}
      onChange={(e: any) => setFilter({ ...filter, values: [e.format("YYYY-MM-DD")] })}
    />
  </div>
);
