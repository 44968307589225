/**
 * @generated SignedSource<<e45b842d46e19d4a08e9048800b87359>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "CommentElement_screener";
};
export type CommentElement_screener$key = {
  readonly " $data"?: CommentElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "69661afc64e04546516e2c8cf4f86b23";

export default node;
