import { StarFilled } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { CountryType, COUNTRY_LOOKUP } from "../../constants";
import { currencyFormatter } from "../../utils/misc";

type Props = {
  balance: number;
  convertedRewardsBalance: number;
  style: any;
  country: CountryType | undefined;
};
const Rewards: React.FC<Props> = ({ balance, convertedRewardsBalance, style, country }) => {
  const { t } = useTranslation();
  const locale = country ? COUNTRY_LOOKUP[country].LOCALE : "en-US";
  const currency = country ? COUNTRY_LOOKUP[country].CURRENCY : "USD";

  return (
    <div style={{ display: "flex", ...style }}>
      <div style={{ marginRight: 14 }}>{t("portal.rewards.title", "Rewards")}</div>
      <div>
        <StarFilled style={{ color: "#FADB14", marginRight: "0.5rem" }} />
        {t("portal.rewards.balance", "{{points}} points | {{usdValue}}", {
          points: balance,
          usdValue: currencyFormatter(locale, currency).format(convertedRewardsBalance),
        })}
      </div>
    </div>
  );
};

export default Rewards;
