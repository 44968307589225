import { Alert } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";
import { numberFormatter, pluralize } from "../../utils/misc";
import { GivePointsToParticipantsModal_tenant$data } from "../../__generated__/GivePointsToParticipantsModal_tenant.graphql";
import { ParticipantFilterGroupGroup } from "../Filters";
import { CostsSummary, GivePointsModal } from "./GivePointsModal";
import {
  getCostsSummaryForAllParticipants,
  getCostsSummaryForParticipants,
  givePointsToAllParticipants,
  givePointsToParticipants,
} from "./GivePointsMutations";
import { TabType } from "./ParticipantsTable";
import { PARTICIPANT_TYPES } from "./ParticipantTypesPicker";

const _GivePointsToParticipantsModal = ({
  tenant,
  selectAllChecked,
  studyId,
  filterComplex,
  excludeIds,
  tab,
  countToSelect,
  participantsType,
  selectedIds,
  visible,
  setVisible,
  onClose,
}: {
  tenant: GivePointsToParticipantsModal_tenant$data;
  selectAllChecked: boolean;
  studyId: string;
  filterComplex: ParticipantFilterGroupGroup;
  excludeIds: string[] | null;
  tab: TabType;
  countToSelect: number | null;
  participantsType: PARTICIPANT_TYPES;
  selectedIds: string[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onClose?: () => void;
}) => (
  <GivePointsModal
    config={{
      title: `Give bonus incentive to ${pluralize(
        selectedIds.length,
        `${numberFormatter.format(selectedIds.length)} participants`,
        "participant"
      )}`,
      header: (
        <>
          <Alert
            type="info"
            message="These points don't count for the incentive you set for the round."
            style={{ marginBottom: 16 }}
          />
          <p>
            How many extra points do you want to give {selectedIds.length === 1 ? "this participant" : "each of them"}?
          </p>
        </>
      ),
      getSuccessNotification: (costsSummary: CostsSummary, points: number) => ({
        title: "Points given",
        message:
          costsSummary.nonTestParticipantCount > 1
            ? `${numberFormatter.format(points)} points were sent to ${numberFormatter.format(
                costsSummary.nonTestParticipantCount
              )} participants`
            : costsSummary.nonTestParticipantCount === 1
            ? `${numberFormatter.format(points)} points were sent to the participant`
            : costsSummary.testParticipantCount > 0
            ? `The demonstration email will be sent to ${pluralize(
                costsSummary.testParticipantCount,
                "test participants",
                "the test participant"
              )}`
            : undefined,
      }),
    }}
    computeCostsSummary={(points: number) =>
      selectAllChecked
        ? getCostsSummaryForAllParticipants(
            studyId,
            filterComplex,
            excludeIds,
            tab,
            countToSelect,
            participantsType,
            points
          )
        : getCostsSummaryForParticipants(selectedIds, points)
    }
    givePoints={(costsSummary: CostsSummary, points: number) =>
      selectAllChecked
        ? givePointsToAllParticipants(
            costsSummary,
            studyId,
            filterComplex,
            excludeIds,
            tab,
            countToSelect,
            participantsType,
            selectedIds,
            points
          )
        : givePointsToParticipants(costsSummary, studyId, selectedIds, points)
    }
    {...{ tenant, visible, setVisible, onClose }}
  />
);

export const GivePointsToParticipantsModal = createFragmentContainer(_GivePointsToParticipantsModal, {
  tenant: graphql`
    fragment GivePointsToParticipantsModal_tenant on TenantNode {
      ...GivePointsModal_tenant
    }
  `,
});
