import { graphql } from "babel-plugin-relay/macro";
import type { ReactNode } from "react";
import { QueryRenderer } from "react-relay";

import { environment } from "../../relay";
import type { PanelFilterGroupQuery_Query } from "../../__generated__/PanelFilterGroupQuery_Query.graphql";
import PanelFilters, { createPanelistFilterGroupGroup } from "./PanelFilters";

const PanelFilterGroupQuery = ({
  children,
  disableExport = false,
  loading,
  onApplyFilters,
  onApplySearch,
  panelistsCount,
  value,
}: {
  children?: ReactNode;
  disableExport?: boolean;
  loading: boolean;
  onApplyFilters: Parameters<typeof PanelFilters>[0]["onApplyFilters"];
  onApplySearch: (searchValue: string) => void;
  panelistsCount: number;
  value?: Parameters<typeof PanelFilters>[0]["value"];
}) => (
  <QueryRenderer<PanelFilterGroupQuery_Query>
    variables={{}}
    query={graphql`
      query PanelFilterGroupQuery_Query {
        viewer {
          ...PanelFilters_viewer
        }
      }
    `}
    environment={environment}
    render={({ error, props }) => {
      if (error) {
        console.error(error);
        return null;
      }
      return (
        props?.viewer && (
          <PanelFilters
            disableExport={disableExport}
            loading={loading}
            onApplyFilters={onApplyFilters}
            onApplySearch={onApplySearch}
            panelistsCount={panelistsCount}
            value={value || createPanelistFilterGroupGroup()}
            viewer={props.viewer}
          >
            {children}
          </PanelFilters>
        )
      );
    }}
  />
);

export default PanelFilterGroupQuery;
