import CookieConsent from "react-cookie-consent";
import { GRAY_1 } from "../style";

type Props = {
  location: "top" | "bottom";
};
const CookieConsentBar = ({ location }: Props) => (
  <CookieConsent
    enableDeclineButton
    buttonText="Accept optional cookies"
    declineButtonText="Decline optional cookies"
    buttonStyle={{ backgroundColor: "var(--ant-success-color)", color: GRAY_1, margin: "15px 16px 16px -5px" }}
    location={location}
    onAccept={() => {
      window.location.reload();
    }}
  >
    This website uses cookies to enhance the user experience.
  </CookieConsent>
);

export default CookieConsentBar;
