/**
 * @generated SignedSource<<b23f95e4ae6d963bca352bd8c0c7cfff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_PortalRedeem_Query$variables = {};
export type RouteConfig_PortalRedeem_Query$data = {
  readonly viewer: {
    readonly user: {
      readonly isPanelist: boolean;
      readonly panelist: {
        readonly " $fragmentSpreads": FragmentRefs<"PortalRedeem_panelist">;
      } | null;
    } | null;
  };
};
export type RouteConfig_PortalRedeem_Query = {
  response: RouteConfig_PortalRedeem_Query$data;
  variables: RouteConfig_PortalRedeem_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPanelist",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_PortalRedeem_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PortalRedeem_panelist"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfig_PortalRedeem_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rewardsBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "convertedRewardsBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "redeemedRewardsPoints",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenantForIncentives",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "blockedCurrencies",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RewardCardObject",
                        "kind": "LinkedField",
                        "name": "rewardCards",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sku",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "programId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maxAmount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minAmount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "faceplateUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonNode",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "panelistCurrencyToPointsRatio",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c10b0ef5aff010bbc2f2a012cccc185",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_PortalRedeem_Query",
    "operationKind": "query",
    "text": "query RouteConfig_PortalRedeem_Query {\n  viewer {\n    user {\n      isPanelist\n      panelist {\n        ...PortalRedeem_panelist\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment PortalRedeem_panelist on PanelistNode {\n  rewardsBalance\n  convertedRewardsBalance\n  redeemedRewardsPoints\n  tenantForIncentives {\n    blockedCurrencies\n    rewardCards {\n      sku\n      programId\n      maxAmount\n      minAmount\n      name\n      faceplateUrl\n    }\n    id\n  }\n  person {\n    country\n    id\n  }\n  ...RewardCard_panelist\n}\n\nfragment RewardCard_panelist on PanelistNode {\n  dId\n  rewardsBalance\n  panelistCurrencyToPointsRatio\n  person {\n    country\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "222a75c7b9c828ab27136b0694b9bb55";

export default node;
