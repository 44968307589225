import { CloseOutlined, HolderOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { App, Button, Checkbox, Input, InputNumber, InputRef, Radio, Select, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useCallback } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ThemeColor } from "../../antd";
import { ELEMENT_TYPES, QUAL_ELEMENTS, SAVING_STATES } from "../../constants";
import { FADED_TEXT_COLOR } from "../../style";
import type { TerminateCondition } from "../../types";
import { mutation } from "../../utils";
import { cleanDataTransferText } from "../../utils/misc";
import { Option_answer$data } from "../../__generated__/Option_answer.graphql";
import { Option_DeleteAnswer_Mutation } from "../../__generated__/Option_DeleteAnswer_Mutation.graphql";
import { Option_element$data } from "../../__generated__/Option_element.graphql";
import { Option_insertAnswers_Mutation } from "../../__generated__/Option_insertAnswers_Mutation.graphql";
import { Option_UpdateAnswer_Mutation } from "../../__generated__/Option_UpdateAnswer_Mutation.graphql";
import { SavingStateType } from "../Saving";
import OptionTerminate from "./OptionTerminate";

const Option = ({
  answer,
  element,
  addAnswerBelow,
  focusElementRef,
  setSavingState,
}: {
  answer: Option_answer$data;
  element: Option_element$data;
  addAnswerBelow: () => Promise<void>;
  focusElementRef: React.RefObject<InputRef> | null;
  setSavingState: (savingState: SavingStateType) => void;
}) => {
  const { message } = App.useApp();

  const insertAnswers = async (answers: string[], position: number) => {
    setSavingState(SAVING_STATES.SAVING);

    try {
      await mutation<Option_insertAnswers_Mutation>({
        variables: {
          input: { elementId: element.id, answers, position },
        },
        mutation: graphql`
          mutation Option_insertAnswers_Mutation($input: InsertAnswersInput!) {
            insertAnswers(input: $input) {
              element {
                answers {
                  edges {
                    node {
                      text
                      position
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const updateAnswer = useCallback(
    async (input: {
      text?: string;
      terminate?: boolean;
      terminateCondition?: TerminateCondition | null;
      quota?: number | null;
      rating?: number | null;
      exclusive?: boolean | null;
      userSpecified?: boolean | null;
    }) => {
      try {
        setSavingState(SAVING_STATES.SAVING);
        await mutation<Option_UpdateAnswer_Mutation>({
          variables: {
            input: {
              answerId: answer.id || "",
              ...input,
            },
          },
          mutation: graphql`
            mutation Option_UpdateAnswer_Mutation($input: UpdateAnswerInput!) {
              updateAnswer(input: $input) {
                answer {
                  id
                  text
                  terminate
                  terminateCondition
                  quota
                  rating
                  exclusive
                  userSpecified
                }
              }
            }
          `,
          optimisticResponse: {
            updateAnswer: {
              answer: {
                id: answer.id,
                text: input.text || answer.text,
                terminate: input.terminate ?? answer.terminate,
                terminateCondition: undefined,
                quota: input.quota || answer.quota,
                rating: input.rating || answer.rating || null,
                exclusive: input.exclusive || answer.exclusive || false,
                userSpecified: input.userSpecified || answer.userSpecified || false,
              },
            },
          },
        });
        setSavingState(SAVING_STATES.SAVED);
      } catch {
        setSavingState(SAVING_STATES.ERROR);
      }
    },
    [
      answer.exclusive,
      answer.id,
      answer.quota,
      answer.rating,
      answer.terminate,
      answer.text,
      answer.userSpecified,
      setSavingState,
    ]
  );

  const deleteAnswer = async () => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<Option_DeleteAnswer_Mutation>({
        variables: {
          input: {
            answerId: answer.id || "",
          },
        },
        mutation: graphql`
          mutation Option_DeleteAnswer_Mutation($input: DeleteAnswerInput!) {
            deleteAnswer(input: $input) {
              element {
                id
                answers {
                  edges {
                    node {
                      ...Option_answer
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  if (element.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
    return (
      <StyledOption>
        {answer.other ? (
          <Input className="answer screener-element-other" value="Other" disabled style={{ width: 498 }} />
        ) : (
          <Input
            style={{ width: 498 }}
            className="answer screener-element-answer-value"
            placeholder="Enter answer choice..."
            defaultValue={answer.text || ""}
            onBlur={e => updateAnswer({ text: e.target.value })}
            maxLength={512}
          />
        )}
        <span
          style={{
            marginLeft: 12,
            marginRight: 4,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Value:
        </span>
        <InputNumber
          className="screener-element-answer-rating"
          defaultValue={answer.rating !== null ? answer.rating : undefined}
          min={1}
          onBlur={e =>
            updateAnswer({
              rating: e.target.value === "0" || !e.target.value ? null : parseInt(e.target.value, 10),
            })
          }
          type="number"
        />
        <CloseOutlined onClick={() => deleteAnswer()} />
        <HolderOutlined style={{ cursor: "grab" }} className="screener-element-answer-drag" />
      </StyledOption>
    );
  }

  if (
    element.type === ELEMENT_TYPES.NUMBER ||
    element.type === ELEMENT_TYPES.VIDEO ||
    element.type === ELEMENT_TYPES.AUDITION_VIDEO ||
    element.type === ELEMENT_TYPES.DATE ||
    element.type === ELEMENT_TYPES.FILES
  ) {
    return null;
  }

  const OTHERS_OPTIONS = [
    { value: "Other", exclusive: false, userSpecifiable: true },
    { value: "None of the above", exclusive: true, userSpecifiable: true },
    // exclude "All of the above" for single-select questions
    ...(![ELEMENT_TYPES.SINGLE_SELECT, ELEMENT_TYPES.DROPDOWN_SELECT].includes(element.type as ELEMENT_TYPES)
      ? ([{ value: "All of the above", exclusive: true, userSpecifiable: false }] as const)
      : ([] as const)),
  ] as const;

  const isCharacteristic = !!element.characteristic?.importKey;
  const usePreviousAnswers = !!JSON.parse(element?.usePreviousAnswers)?.enabled;

  const pleaseSpecifyCheckbox = !!(
    answer.userSpecified || // legacy support
    (answer.text && OTHERS_OPTIONS.find(x => x.userSpecifiable && x.value === answer.text))
  ) && (
    <>
      <label style={{ color: isCharacteristic ? FADED_TEXT_COLOR : undefined }}>
        <Checkbox
          checked={answer.userSpecified}
          disabled={isCharacteristic || usePreviousAnswers}
          onChange={evt => updateAnswer({ userSpecified: evt.target.checked })}
        />{" "}
        Ask respondent to specify
      </label>
    </>
  );

  return (
    <StyledOption>
      {QUAL_ELEMENTS.includes(element.type as ELEMENT_TYPES) ? (
        <Input.TextArea disabled />
      ) : (
        <>
          <HolderOutlined style={{ cursor: "grab" }} className="screener-element-answer-drag" />

          {element.type === ELEMENT_TYPES.MULTI_SELECT ? (
            <Checkbox disabled />
          ) : [ELEMENT_TYPES.SINGLE_SELECT, ELEMENT_TYPES.DROPDOWN_SELECT, ELEMENT_TYPES.GRID_SINGLE_SELECT].includes(
              element.type as ELEMENT_TYPES
            ) ? (
            <Radio disabled />
          ) : null}

          <div style={{ flexGrow: 1 }}>
            {answer.other &&
            [ELEMENT_TYPES.MULTI_SELECT, ELEMENT_TYPES.SINGLE_SELECT].includes(element.type as ELEMENT_TYPES) ? (
              <>
                <Select
                  className="answer screener-element-other other-select"
                  value={answer.text || ""}
                  disabled={isCharacteristic || usePreviousAnswers}
                  onChange={value => {
                    const x = OTHERS_OPTIONS.find(x => x.value === value);

                    if (x)
                      updateAnswer({
                        text: value,
                        exclusive: x.exclusive,
                        ...(!x.userSpecifiable ? { userSpecified: false } : {}),
                      });
                  }}
                >
                  {OTHERS_OPTIONS.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
                {pleaseSpecifyCheckbox}
              </>
            ) : (
              <Input
                ref={focusElementRef}
                disabled={isCharacteristic || usePreviousAnswers}
                className="answer screener-element-answer-value"
                placeholder="Enter answer choice"
                defaultValue={answer.text || ""}
                onPressEnter={addAnswerBelow}
                onPaste={async e => {
                  const newAnswers = cleanDataTransferText(e.clipboardData);
                  if (newAnswers.length > 1) {
                    e.preventDefault();
                    await insertAnswers(newAnswers, answer.position);
                    message.info(`Added ${newAnswers.length} answers`);
                  }
                }}
                onBlur={e => updateAnswer({ text: e.target.value })}
                style={{ width: "100%" }}
                maxLength={512}
              />
            )}
          </div>

          {element.type !== ELEMENT_TYPES.RANK && !answer.terminate && (
            <Tooltip title='Limit the number of participants who qualify when selecting this answer. Any participants over this limit will be marked "Over quota".'>
              <InputNumber
                className="qual screener-element-answer-goal"
                defaultValue={answer.quota !== null ? answer.quota : undefined}
                min={1}
                onBlur={e =>
                  updateAnswer({
                    quota: e.target.value === "0" || !e.target.value ? null : parseInt(e.target.value, 10),
                  })
                }
                placeholder="Max"
                type="number"
              />
            </Tooltip>
          )}

          <OptionTerminate
            className="hub-option-item-fixed"
            answer={answer}
            element={element}
            onChange={value => updateAnswer(value)}
          />

          {(!element.characteristic || element.characteristic.tenant) && (
            <>
              {element.type === ELEMENT_TYPES.MULTI_SELECT && (
                <Tooltip
                  placement="bottom"
                  title="Marking an option as exclusive means that if this answer is selected, the respondent will not be able to select any other answers"
                >
                  <Button
                    disabled={isCharacteristic}
                    onClick={() => updateAnswer({ exclusive: !answer.exclusive })}
                    size="small"
                    style={
                      isCharacteristic && answer.exclusive
                        ? { backgroundColor: ThemeColor.VoxStroke, color: "white" }
                        : undefined
                    }
                    type={answer.exclusive ? "primary" : "default"}
                  >
                    E
                  </Button>
                </Tooltip>
              )}

              {!isCharacteristic && !usePreviousAnswers && (
                <Button
                  className="hub-option-item-fixed"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="text"
                  onClick={() => deleteAnswer()}
                >
                  <Icon icon="mdi:close" height="1.2em" style={{ display: "block" }} />
                </Button>
              )}
            </>
          )}
        </>
      )}
    </StyledOption>
  );
};

const StyledOption = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 10px;
  align-items: baseline;

  input {
    &.answer {
      width: 323px;
    }
  }

  .ant-input-affix-wrapper {
    width: 323px;
    .ant-tag {
      margin-right: 0;
    }
  }

  .ant-radio-wrapper {
    margin: 0;
  }

  .ant-radio-group {
    margin-left: 8px;
  }

  .ant-radio-button-wrapper {
    padding: 0 4px;
  }

  .qual {
    width: 68px;
    top: -4px;
  }

  .anticon {
    margin-left: 8px;
    color: ${FADED_TEXT_COLOR};

    &.anticon-close-circle {
      cursor: pointer;
    }
  }

  .other-select {
    width: 100%;
    .anticon {
      margin-left: 0;
    }
  }

  .hub-option-item-fixed {
    align-self: start;
    height: 32px;
  }
`;

export default createFragmentContainer(Option, {
  answer: graphql`
    fragment Option_answer on AnswerNode {
      id
      text
      terminate
      quota
      other
      rating
      exclusive
      userSpecified
      position
      ...OptionTerminate_answer
    }
  `,
  element: graphql`
    fragment Option_element on ElementNode {
      id
      type
      usePreviousAnswers
      characteristic {
        importKey
        tenant {
          id
        }
      }
      ...OptionTerminate_element
    }
  `,
});
