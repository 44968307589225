/**
 * @generated SignedSource<<fe18771771329a08756142a551e661a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GivePointsToParticipantsModal_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GivePointsModal_tenant">;
  readonly " $fragmentType": "GivePointsToParticipantsModal_tenant";
};
export type GivePointsToParticipantsModal_tenant$key = {
  readonly " $data"?: GivePointsToParticipantsModal_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GivePointsToParticipantsModal_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GivePointsToParticipantsModal_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GivePointsModal_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "4fa090fcb40e048aebd13c3d7c065037";

export default node;
