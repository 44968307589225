import { Table } from "antd";
import Tag from "antd/es/tag";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import { FieldReportSegmentTable_segments$data } from "../../../__generated__/FieldReportSegmentTable_segments.graphql";

type Props = {
  segments: FieldReportSegmentTable_segments$data;
};
const FieldReportSegmentTable: React.FC<Props> = ({ segments }) => {
  const makeColumns = (e: any) => {
    return [
      {
        // title: "",
        dataIndex: "text",
      },
      {
        title: "Quota",
        width: 80,
        render: (o: any) => (o.terminate ? "N/A" : o.quota),
      },
      {
        title: "Terminate",
        width: 80,
        render: (o: any) => <Tag>{o.terminate ? "Yes" : "No"}</Tag>,
      },
      {
        title: "Total",
        width: 80,
        render: (o: any) => (o.terminate ? o.terminants : o.qualified + o.overQuota),
      },
      {
        title: "Qualified",
        width: 80,
        dataIndex: "qualified",
      },
      {
        title: "Qualified %",
        width: 100,
        render: (o: any) =>
          o.qualified + o.overQuota ? Math.round((o.qualified / (o.qualified + o.overQuota)) * 100) + " %" : 0 + " %",
      },
    ];
  };

  const makeDataSource = (segments: any) => segments.rows.map((row: any, i: any) => ({ key: i, ...row }));

  return (
    <Table
      // @ts-ignore
      columns={makeColumns(segments)}
      // @ts-ignore
      dataSource={makeDataSource(segments)}
      size="small"
      pagination={false}
      style={{ marginBottom: -1 }}
    />
  );
};

export default createFragmentContainer(FieldReportSegmentTable, {
  segments: graphql`
    fragment FieldReportSegmentTable_segments on FieldReportSegmentNode {
      rows {
        text
        quota
        terminate
        qualified
        overQuota
        terminants
      }
    }
  `,
});
