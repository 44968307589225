/**
 * @generated SignedSource<<95300643e1f8a6509974bab4440d43f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectSpend_study$data = {
  readonly totalPayouts: any | null;
  readonly totalSpend: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"SpendingDetails_study">;
  readonly " $fragmentType": "ProjectSpend_study";
};
export type ProjectSpend_study$key = {
  readonly " $data"?: ProjectSpend_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectSpend_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectSpend_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPayouts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSpend",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpendingDetails_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "508a679d4b18cc31dce1a78365bdb92e";

export default node;
