import { Icon, IconifyIcon } from "@iconify/react";
import { Button, Dropdown, MenuItemProps, Tooltip } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  icon: IconifyIcon;
  text: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  extendedActions?: { text: ReactNode; onClick: (info: any) => void; menuItemProps?: MenuItemProps }[];
};
export const BulkActionButton: React.FC<Props> = ({ icon, text, disabled, onClick, extendedActions }: Props) => {
  const items = extendedActions?.map((action, i) => ({
    key: i,
    onClick: action.onClick,
    label: action.text,
  }));

  const button = !extendedActions?.length ? (
    // single button (no extended actions)
    <Button className="button" type="primary" disabled={disabled} onClick={onClick as any}>
      <Icon className="icon" icon={icon} />
      {text}
    </Button>
  ) : (
    // primary button with extended actions
    <Dropdown.Button className="button" type="primary" disabled={disabled} onClick={onClick} menu={{ items }}>
      <Icon className="icon" icon={icon} />
      {text}
    </Dropdown.Button>
  );

  return (
    <StyledBulkActionButton>
      {disabled ? (
        <Tooltip title="To perform this action select participants in the table" placement="bottom">
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </StyledBulkActionButton>
  );
};

const StyledBulkActionButton = styled.div`
  .button {
    align-content: baseline;
    margin-right: 10px;

    .icon {
      margin-right: 4px;
      vertical-align: middle;
    }
  }
`;
