/**
 * @generated SignedSource<<aa45f8623ba338a93b0cb39db6eb6854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteSegmentInput = {
  clientMutationId?: string | null;
  segmentId: string;
};
export type Segment_DeleteSegment_Mutation$variables = {
  input: DeleteSegmentInput;
};
export type Segment_DeleteSegment_Mutation$data = {
  readonly deleteSegment: {
    readonly screener: {
      readonly segments: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"Segment_segment">;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type Segment_DeleteSegment_Mutation = {
  response: Segment_DeleteSegment_Mutation$data;
  variables: Segment_DeleteSegment_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Segment_DeleteSegment_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSegmentPayload",
        "kind": "LinkedField",
        "name": "deleteSegment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenerNode",
            "kind": "LinkedField",
            "name": "screener",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SegmentNodeConnection",
                "kind": "LinkedField",
                "name": "segments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SegmentNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Segment_segment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Segment_DeleteSegment_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSegmentPayload",
        "kind": "LinkedField",
        "name": "deleteSegment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenerNode",
            "kind": "LinkedField",
            "name": "screener",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SegmentNodeConnection",
                "kind": "LinkedField",
                "name": "segments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SegmentNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "condition",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "quota",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "terminate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce8b0be2f552b9e9ac9175ac2cbf97f2",
    "id": null,
    "metadata": {},
    "name": "Segment_DeleteSegment_Mutation",
    "operationKind": "mutation",
    "text": "mutation Segment_DeleteSegment_Mutation(\n  $input: DeleteSegmentInput!\n) {\n  deleteSegment(input: $input) {\n    screener {\n      segments {\n        edges {\n          node {\n            ...Segment_segment\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment Segment_segment on SegmentNode {\n  id\n  condition\n  text\n  quota\n  count\n  terminate\n}\n"
  }
};
})();

(node as any).hash = "ed0018d4fd327584966b30659a25ce8f";

export default node;
