import { Icon } from "@iconify/react";
import { Button } from "antd";
import classNames, { type Argument } from "classnames";
import type { CSSProperties, ReactChild, ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";

export const DRAWER_HEADER_PARENT_CLASS_NAME = "hub-drawer-header-parent";

const _DrawerHeader = ({
  actions,
  className,
  extraRow,
  onClose,
  style,
  tagName: El = "div",
  title,
}: {
  actions?: ReactNode;
  className?: Argument;
  extraRow?: ReactNode;
  onClose?: Parameters<typeof Button>[0]["onClick"];
  style?: CSSProperties;
  tagName?: keyof JSX.IntrinsicElements;
  title: ReactChild;
}) => (
  <El className={classNames("hub-drawer-header", className)} style={style}>
    <div className="hub-drawer-header-row">
      {!!onClose && (
        <Button className="hub-drawer-header-close" onClick={onClose} type="text">
          <Icon height="1.5em" icon="mdi:page-last" />
        </Button>
      )}
      <div className="hub-drawer-header-title">{title}</div>
      {!!actions && <div className="hub-drawer-header-actions">{actions}</div>}
    </div>
    {!!extraRow &&
      (() => {
        const El = typeof extraRow === "string" ? "p" : "div";
        return <El className="hub-drawer-header-row">{extraRow}</El>;
      })()}
    <GlobalStyle />
  </El>
);

const GlobalStyle = createGlobalStyle`
  .${DRAWER_HEADER_PARENT_CLASS_NAME} .ant-drawer-header {
    padding: 0;
  }
`;

export const DrawerHeader = styled(_DrawerHeader)`
  .hub-drawer-header-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .hub-drawer-header-close {
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -4px;
    padding: 4px;
  }

  .hub-drawer-header-row {
    margin: 0;
    padding: 16px 24px;

    &:first-child {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 8px;
      align-items: center;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
`;
