/**
 * @generated SignedSource<<2b7d8f0d5a54391af2e26ff4877413bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GridSingleSelectElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "GridSingleSelectElement_screener";
};
export type GridSingleSelectElement_screener$key = {
  readonly " $data"?: GridSingleSelectElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"GridSingleSelectElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GridSingleSelectElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "65ced2a7a3db070fbe020ac454b521e8";

export default node;
