import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Environment, Network, RecordSource, Store } from "relay-runtime";

import { getAuthorizationHeader, refreshOidcToken } from "./utils";

const HEADERS = {
  "Content-Type": "application/json",
};

const makeHeaders = () => ({
  ...HEADERS,
  ...getAuthorizationHeader(),
});

export const environment = new Environment({
  network: Network.create(async (req, variables) => {
    await refreshOidcToken();

    const res = await fetch(`${runtimeEnv().REACT_APP_API_URL}/graphql`, {
      method: "POST",
      headers: makeHeaders(),
      body: JSON.stringify({
        query: req.text,
        variables,
      }),
    });
    const result = await res.json();
    if (result?.error) throw result;

    return result;
  }),
  store: new Store(new RecordSource()),
});
