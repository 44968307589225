import { CloseOutlined, DownOutlined, HolderOutlined } from "@ant-design/icons";
import { Checkbox, Input, Radio } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES, QUAL_ELEMENTS, SAVING_STATES } from "../../constants";
import { FADED_TEXT_COLOR } from "../../style";
import { mutation } from "../../utils";
import { Row_DeleteRow_Mutation } from "../../__generated__/Row_DeleteRow_Mutation.graphql";
import { Row_element$data } from "../../__generated__/Row_element.graphql";
import { Row_row$data } from "../../__generated__/Row_row.graphql";
import { Row_UpdateRow_Mutation } from "../../__generated__/Row_UpdateRow_Mutation.graphql";
import { SavingStateType } from "../Saving";

type Props = {
  row: Row_row$data;
  element: Row_element$data;
  setSavingState: (savingState: SavingStateType) => void;
};
const Row: React.FC<Props> = ({ row, element, setSavingState }) => {
  const updateRow = async (input: { text?: string }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<Row_UpdateRow_Mutation>({
        variables: {
          input: {
            rowId: row.id || "",
            ...input,
          },
        },
        mutation: graphql`
          mutation Row_UpdateRow_Mutation($input: UpdateRowInput!) {
            updateRow(input: $input) {
              row {
                id
                text
              }
            }
          }
        `,
        optimisticResponse: {
          updateRow: {
            row: {
              id: row.id,
              text: input.text || row.text,
            },
          },
        },
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const deleteRow = async () => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<Row_DeleteRow_Mutation>({
        variables: {
          input: {
            rowId: row.id || "",
          },
        },
        mutation: graphql`
          mutation Row_DeleteRow_Mutation($input: DeleteRowInput!) {
            deleteRow(input: $input) {
              element {
                id
                rows {
                  edges {
                    node {
                      id
                      text
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  return (
    <StyledOption>
      {QUAL_ELEMENTS.includes(element.type as ELEMENT_TYPES) ? (
        <Input.TextArea disabled />
      ) : (
        <>
          {element.type === "SS" && <Radio disabled checked />}
          {element.type === "MS" && <Checkbox disabled checked />}
          {element.type === "DS" && <DownOutlined style={{ color: "#d9d9d9" }} />}
          <Input
            disabled={!!element.characteristic}
            className="answer"
            placeholder="Enter row..."
            defaultValue={row.text}
            onBlur={e => updateRow({ text: e.target.value })}
          />
          <CloseOutlined onClick={() => deleteRow()} />
          <HolderOutlined style={{ cursor: "grab" }} />
        </>
      )}
    </StyledOption>
  );
};

const StyledOption = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  input {
    &.answer {
      width: 498px;
    }
  }

  .ant-radio-wrapper {
    margin: 0;
  }

  .ant-radio-group {
    margin-left: 8px;
  }

  .ant-radio-button-wrapper {
    padding: 0 4px;
  }

  .ant-input-number,
  .qual {
    margin-left: 8px;
    width: 68px;
  }

  .anticon {
    margin-left: 4px;
    color: ${FADED_TEXT_COLOR};

    &.anticon-close-circle {
      cursor: pointer;
    }
  }
`;

export default createFragmentContainer(Row, {
  row: graphql`
    fragment Row_row on RowNode {
      id
      text
    }
  `,
  element: graphql`
    fragment Row_element on ElementNode {
      type
      characteristic {
        id
      }
    }
  `,
});
