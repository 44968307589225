import { Radio, theme, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { uniqueId } from "lodash-es";
import { useFragment } from "react-relay";
import styled from "styled-components";
import type { IterableElement } from "type-fest";

import { ButtonLabel } from "..";
import type { ScreenersFilterTypeChoices } from "../../schema";
import type { DispatchState } from "../../types";
import type { InputFilterGroupGroup_element$key } from "../../__generated__/InputFilterGroupGroup_element.graphql";
import type { InputFilterGroupGroup_screener$key } from "../../__generated__/InputFilterGroupGroup_screener.graphql";
import type { InputFilterGroupGroup_tenant$key } from "../../__generated__/InputFilterGroupGroup_tenant.graphql";

import { InputFilterGroup } from "./InputFilterGroup";
import {
  GroupOp,
  SegmentFilterGroupGroup,
  type ElementFilterGroupGroup,
  type PanelistFilterGroupGroup,
  type ParticipantFilterGroupGroup,
} from "./types";

const { Paragraph: P } = Typography;
const { useToken } = theme;

export const InputFilterGroupGroup = <
  T extends ScreenersFilterTypeChoices | "element" | "segment",
  FilterGroupGroup extends T extends "element"
    ? ElementFilterGroupGroup
    : T extends "segment"
    ? SegmentFilterGroupGroup
    : T extends ScreenersFilterTypeChoices.Pn
    ? PanelistFilterGroupGroup
    : ParticipantFilterGroupGroup
>({
  element: elementKey,
  onClear,
  screener: screenerKey,
  setValue,
  tenant: tenantKey,
  type: _type,
  value,
}: {
  onClear?: Parameters<typeof ButtonLabel>[0]["onClick"];
  screener: InputFilterGroupGroup_screener$key | null;
  setValue: DispatchState<FilterGroupGroup>;
  tenant: InputFilterGroupGroup_tenant$key | null;
  value: FilterGroupGroup;
} & (
  | {
      element?: null;
      type: ScreenersFilterTypeChoices | "segment";
    }
  | {
      element: InputFilterGroupGroup_element$key;
      type: "element";
    }
)) => {
  type FilterGroup = IterableElement<FilterGroupGroup["filters"]>;

  const element = useFragment(
    graphql`
      fragment InputFilterGroupGroup_element on ElementNode {
        id
        ...InputFilterGroup_element
      }
    `,
    _type === "element" ? elementKey : null
  );
  const screener = useFragment(
    graphql`
      fragment InputFilterGroupGroup_screener on ScreenerNode {
        id
        ...InputFilterGroup_screener
      }
    `,
    screenerKey
  );
  const tenant = useFragment(
    graphql`
      fragment InputFilterGroupGroup_tenant on TenantNode {
        id
        ...InputFilterGroup_tenant
      }
    `,
    tenantKey
  );

  const { token } = useToken();

  return (
    <Root>
      <div className="filter-title">
        <div className="filter-operator-radio">
          <Radio.Group
            value={value.op}
            onChange={e =>
              setValue({
                ...value,
                op: e.target.value,
              })
            }
            optionType="button"
            buttonStyle="solid"
            options={[GroupOp.And, GroupOp.Or]}
          />
        </div>
        <ButtonLabel
          disabled={!value.filters.length}
          onClick={
            // @ts-expect-error `ButtonLabel.onClick` is internally inferred as an intersection instead of a union
            e => onClear?.(e)
          }
          text="Delete all filters"
          type="link"
        />
      </div>
      {value.filters.map((x, i) => (
        <div key={x.id}>
          {!!i && (
            <P style={{ marginBlock: token.marginSM, textTransform: "capitalize" }} type="secondary">
              {value.op.toLowerCase()}
            </P>
          )}
          {/* @ts-expect-error Type of `element` in combination with `_type` loses fidelity due to useFragment call */}
          <InputFilterGroup
            element={element}
            key={x.id}
            onDelete={() =>
              setValue({
                ...value,
                filters: (value.filters as FilterGroup[]).filter(y => y.id !== x.id),
              })
            }
            screener={screener ?? null}
            setValue={x =>
              setValue({
                ...value,
                filters: value.filters.map(y => (y.id === x.id ? x : y)),
              })
            }
            tenant={tenant ?? null}
            type={_type}
            value={x}
          />
        </div>
      ))}
      <ButtonLabel
        icon="mdi:plus"
        onClick={() =>
          setValue({
            ...value,
            filters: [
              ...value.filters,
              {
                id: uniqueId("filterGroup"),
                op: GroupOp.And,
                filters: [],
              } as any,
            ],
          })
        }
        style={{ marginTop: token.marginMD, paddingInline: 0 }}
        text="Add filter group"
        type="link"
      />
    </Root>
  );
};

const Root = styled.div`
  .filter-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .filter-operator-radio {
      display: flex;
      gap: inherit;
    }
  }
`;
