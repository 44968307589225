import { Card, Table } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { each } from "lodash-es";
import moment, { Moment as MomentType } from "moment";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { Moment } from "../..";
import { GRAY_6, PRIMARY_TEXT_COLOR } from "../../../style";
import type { SummaryData_panel$data } from "../../../__generated__/SummaryData_panel.graphql";

const SummaryData = ({ panel }: { panel: SummaryData_panel$data }) => {
  function label(key: any) {
    const labels: any = {
      totalPanelists: "Total Panelists",
      totalParticipants: "Total Participants",
      screenersTaken: "Screeners Taken",
      qualified: "Qualified",
      terminated: "Terminated",
      overquota: "Over Quota",
      approved: "Approved",
      denied: "Denied",
      rsvpAccepted: "RSVP Accepted",
      rsvpDeclined: "RSVP Declined",
      incentivized: "Incentivized",
      noShow: "No Shows",
      medianRating: "Median Rating",
    };
    return labels[key];
  }

  function makeColumns(data: any) {
    const columns = data.map((o: any) => {
      if (o.month === null && o.year === null) {
        return {
          title: "Total",
          dataIndex: "total",
          width: 100,
        };
      }
      return {
        title: moment(`${o.month}-${o.year}`, "MM-YYYY").format("MMM `YY"),
        dataIndex: `${o.month}-${o.year}`,
        width: 100,
      };
    });
    return [
      {
        title: (
          <p style={{ margin: 0 }}>
            Health{" "}
            <span
              style={{
                marginLeft: 6,
                fontSize: 11,
                textTransform: "uppercase",
                color: GRAY_6,
              }}
            >
              last updated on{" "}
              <Moment style={{ fontSize: 11 }} format="M/D/YY h:mm A">
                {panel.lastHealthRun as MomentType}
              </Moment>
            </span>
          </p>
        ),
        dataIndex: "key",
      },
      ...columns.reverse(),
    ];
  }

  function makeData(data: any) {
    let serialized: any = [];
    const numberFormat = new Intl.NumberFormat();
    const usdFormat = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });

    each(data, (o: any, k: any) => {
      const row: any = {
        key: label(k),
      };
      each(o, (v: any) => {
        if (v.month === null && v.year === null) {
          if (k === "incentivized") {
            row.total = usdFormat.format(v.count.slice(1));
          } else {
            row.total = numberFormat.format(parseInt(v.count));
          }
        } else {
          if (k === "incentivized") {
            row[`${v.month}-${v.year}`] = usdFormat.format(parseInt(v.count.slice(1)));
          } else {
            row[`${v.month}-${v.year}`] = numberFormat.format(parseInt(v.count));
          }
        }
      });
      serialized = [row, ...serialized];
    });

    return serialized.reverse();
  }

  const columns = panel.health ? makeColumns(panel?.health?.screenersTaken) : [];
  const data = panel.health ? makeData(panel?.health) : [];

  return columns.length ? (
    <Styled>
      <Card>
        <Table columns={columns} dataSource={data} size="small" pagination={false} />
      </Card>
    </Styled>
  ) : null;
};

const Styled = styled.div`
  .ant-card-body {
    padding: 0 0 6px;
  }

  .heading-text {
    padding: 12px 0 12px 24px;
    h1 {
      font-size: 27px;
      color: #333a4e;
      margin: 0;
    }
  }
  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-cell {
      color: ${PRIMARY_TEXT_COLOR} !important;
      :not(:first-child) {
        text-align: right;
      }
      :first-child {
        padding-left: 48px;
      }
      :last-child {
        padding-right: 24px;
      }
    }
  }
`;

export default createFragmentContainer(SummaryData, {
  panel: graphql`
    fragment SummaryData_panel on Panel {
      lastHealthRun
      health {
        totalPanelists {
          count
          month
          year
        }
        totalParticipants {
          count
          month
          year
        }
        screenersTaken {
          count
          month
          year
        }
        qualified {
          count
          month
          year
        }
        terminated {
          count
          month
          year
        }
        overquota {
          count
          month
          year
        }
        approved {
          count
          month
          year
        }
        denied {
          count
          month
          year
        }
        rsvpAccepted {
          count
          month
          year
        }
        rsvpDeclined {
          count
          month
          year
        }
        incentivized {
          count
          month
          year
        }
        medianRating {
          count
          month
          year
        }
        noShow {
          count
          month
          year
        }
      }
    }
  `,
});
