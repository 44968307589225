import { CreditCardOutlined } from "@ant-design/icons";
import { Alert, App, Card, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ModalPrepayDisabled } from "..";
import { CONTAINER_BOX_SHADOW } from "../../style";
import type { DispatchState } from "../../types";
import { useFlag } from "../../utils";
import { managePaymentMethod, numberFormatter, pluralize, usdFormatter } from "../../utils/misc";
import type { GivePointsPaymentModal_tenant$data } from "../../__generated__/GivePointsPaymentModal_tenant.graphql";

import { CostsSummary } from "./GivePointsModal";

const _GivePointsPaymentModal = ({
  visible,
  setVisible,
  tenant,
  points,
  costsSummary,
  getSuccessNotification,
  onPointsGiven,
  givePoints,
}: {
  visible: boolean;
  setVisible: DispatchState<boolean>;
  tenant: GivePointsPaymentModal_tenant$data;
  points: number;
  costsSummary: CostsSummary;
  getSuccessNotification: (costsSummary: CostsSummary, points: number) => { title: string; message?: string };
  onPointsGiven: () => void;
  givePoints: (costsSummary: CostsSummary, points: number) => Promise<void>;
}) => {
  const { match } = useRouter();
  const { notification } = App.useApp();
  const [working, setWorking] = useState(false);

  const addPayment = async () => {
    setWorking(true);
    await managePaymentMethod(match.location.pathname);
    setWorking(false);
  };

  const handleOk = async () => {
    try {
      setWorking(true);
      await givePoints(costsSummary, points);
      setVisible(false);
      const successNotification = getSuccessNotification(costsSummary, points);
      notification.success({ message: successNotification.title, description: successNotification.message });
      onPointsGiven?.();
    } finally {
      setWorking(false);
    }
  };

  const paymentsEnabled = useFlag("hub-recruit-prepay");

  return !tenant.requireStudyPayment || (paymentsEnabled && tenant.stripePaymentMethodId) ? (
    <StyledModal
      centered
      title="Give bonus incentive"
      open={visible}
      cancelButtonProps={{ disabled: working }}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: working }}
      okText={costsSummary.nonTestParticipantCount > 0 ? "Pay" : undefined}
      onOk={handleOk}
    >
      <div className="modal-container">
        <div className="modal-cost-table">
          <table className="modal-table">
            <tbody>
              {costsSummary.nonTestParticipantCount > 0 && (
                <tr className="table-row">
                  <td>
                    {numberFormatter.format(points)} points
                    {costsSummary.nonTestParticipantCount > 1 &&
                      ` each for ${numberFormatter.format(costsSummary.nonTestParticipantCount)} participants`}
                  </td>
                  <td>{usdFormatter.format(costsSummary.pointsCostUsdCents / 100)}</td>
                </tr>
              )}
              {costsSummary.testParticipantCount > 0 && (
                <tr className="table-row">
                  <td>
                    0 points {costsSummary.testParticipantCount > 1 && "each"} for{" "}
                    {pluralize(
                      costsSummary.testParticipantCount,
                      `${numberFormatter.format(costsSummary.testParticipantCount)} test participants`,
                      "one test participant"
                    )}
                  </td>
                  <td>{usdFormatter.format(0)}</td>
                </tr>
              )}
              <tr className="table-row">
                <td>Fees</td>
                <td>{usdFormatter.format(costsSummary.feesUsdCents / 100)}</td>
              </tr>
              <tr className="table-row table-summary">
                <td>Total</td>
                <td>{usdFormatter.format((costsSummary.pointsCostUsdCents + costsSummary.feesUsdCents) / 100)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {tenant.requireStudyPayment ? (
          <div className="payment-container">
            <div className="payment-title">Pay using this card</div>
            <Card className="payment-card">
              <CreditCardOutlined className="card-icon" /> {`**** **** **** ${tenant.stripePaymentMethodLast4}`}
            </Card>
            <div className="payment-title">
              {(tenant.incentivesOrderFulfillmentVerificationDays ?? 0) > 0 ? (
                <>
                  For security reasons, it may take up to {tenant.incentivesOrderFulfillmentVerificationDays} days to
                  issue points.{" "}
                  <a
                    href="https://app.intercom.com/a/apps/bdqzfylg/articles/articles/5894232/show"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Why is this happening?
                  </a>
                </>
              ) : (
                <>Points will be sent immediately.</>
              )}
            </div>
          </div>
        ) : (
          <div className="payment-container">
            <div className="payment-title">It may take a few minutes for incentives to be sent.</div>
          </div>
        )}
        {costsSummary.testParticipantCount > 0 && (
          <Alert
            style={{ marginTop: 16 }}
            type="info"
            message={
              <>
                Test participants will receive a demonstration email but will not receive points, and you won't be
                charged for them.
              </>
            }
          />
        )}
      </div>
    </StyledModal>
  ) : paymentsEnabled ? (
    <StyledModal
      title="Add Payment Method"
      open={visible}
      width={350}
      centered
      okButtonProps={{ loading: working }}
      onOk={addPayment}
      okText="Add payment method"
      onCancel={() => setVisible(false)}
    >
      Before you can give points you'll need to add a payment method. You'll only need to add this once.
    </StyledModal>
  ) : (
    <ModalPrepayDisabled open={visible} setOpen={setVisible} />
  );
};

const StyledModal = styled(Modal)`
  .modal-table {
    width: 100%;

    .table-row {
      display: flex;
      justify-content: space-between;
    }

    .table-summary {
      font-weight: 500;
    }
  }

  .payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .payment-title {
      margin-top: 1rem;
      font-size: 12px;
      text-align: center;
    }

    .payment-card {
      margin-top: 0.5rem;
      width: 100%;
      box-shadow: ${CONTAINER_BOX_SHADOW};
      font-weight: 500;

      .card-icon {
        font-size: 20px;
        padding-right: 0.5rem;
      }
    }

    .add-payment-btn {
      margin-top: 0.5rem;
    }
  }
`;

export const GivePointsPaymentModal = createFragmentContainer(_GivePointsPaymentModal, {
  tenant: graphql`
    fragment GivePointsPaymentModal_tenant on TenantNode {
      requireStudyPayment
      incentivesOrderFulfillmentVerificationDays
      stripePaymentMethodId
      stripePaymentMethodLast4
    }
  `,
});
