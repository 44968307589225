/**
 * @generated SignedSource<<28ff7bcec0536fb9224eb16800cd5093>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SummaryData_panel$data = {
  readonly health: {
    readonly approved: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly denied: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly incentivized: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly medianRating: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly noShow: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly overquota: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly qualified: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly rsvpAccepted: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly rsvpDeclined: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly screenersTaken: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly terminated: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly totalPanelists: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
    readonly totalParticipants: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
  } | null;
  readonly lastHealthRun: any | null;
  readonly " $fragmentType": "SummaryData_panel";
};
export type SummaryData_panel$key = {
  readonly " $data"?: SummaryData_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"SummaryData_panel">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "month",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "year",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SummaryData_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastHealthRun",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelHealth",
      "kind": "LinkedField",
      "name": "health",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "totalPanelists",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "totalParticipants",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "screenersTaken",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "qualified",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "terminated",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "overquota",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "approved",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "denied",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "rsvpAccepted",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "rsvpDeclined",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "incentivized",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "medianRating",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "noShow",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "fc92ad99c196c862b9a646f6dc728461";

export default node;
