import { CreditCardOutlined } from "@ant-design/icons";
import { App, Card, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import React, { useState } from "react";
import { commitMutation, createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ModalPrepayDisabled } from "..";
import { environment } from "../../relay";
import { CONTAINER_BOX_SHADOW } from "../../style";
import { handleErrorWithMessage, useFlag } from "../../utils";
import { managePaymentMethod, pluralize, usdFormatter } from "../../utils/misc";

export interface RecruitsRequiringFundingType {
  [recruitId: string]: number;
}

type Props = {
  study: any;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  recruitsRequiringFunding: RecruitsRequiringFundingType | null;
  callback: (successString: string) => void | Promise<void>;
};
const ParticipantPaymentModal: React.FC<Props> = ({
  study,
  visible,
  setVisible,
  recruitsRequiringFunding,
  callback,
}) => {
  const { match } = useRouter();
  const { notification } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);

  const amountUsdCents = recruitsRequiringFunding
    ? Object.values(recruitsRequiringFunding).reduce((acc, val) => acc + val)
    : 0;
  const countOfUnderFundedRecruits = recruitsRequiringFunding ? Object.keys(recruitsRequiringFunding).length : 0;

  const addPayment = async () => {
    setLoading(true);
    await managePaymentMethod(match.location.pathname);
    setLoading(false);
  };

  const payMutation = (recruitId: string, amountUsdCents: number) => {
    return new Promise((resolve, reject) => {
      const handleError = (err: any) => {
        handleErrorWithMessage(err, "Payment failed. Please retry or contact us.");
        reject(err);
      };
      commitMutation(environment, {
        mutation: graphql`
          mutation ParticipantPaymentModal_FundRecruit_Mutation($input: FundRecruitInput!) {
            fundRecruit(input: $input) {
              recruitTransaction {
                id
                transactionType
                value
                recruit {
                  id
                }
              }
            }
          }
        `,
        variables: { input: { recruitId, amountUsdCents } },
        onCompleted: (response, errors) => {
          if (errors) return handleError(errors);
          resolve(response);
        },
        onError: handleError,
      });
    });
  };

  const makePayment = async () => {
    setLoading(true);

    try {
      if (recruitsRequiringFunding)
        await Promise.all(
          Object.entries(recruitsRequiringFunding).map(async ([k, v]) => {
            await payMutation(k, v);
            notification.success({ message: `Recruiting round funded ${usdFormatter.format(v / 100)}` });
          })
        );
      await callback("Participants incentivized");
    } catch (e) {
      // Errors are handled in the mutations. Above try is to ensure callback only runs
      // if all payMutations are successful
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  return useFlag("hub-recruit-prepay") ? (
    study.tenant.stripePaymentMethodId ? (
      <StyledModal
        title="Insufficient funds"
        open={visible}
        width={350}
        centered={true}
        okButtonProps={{ disabled: !study.tenant.stripePaymentMethodId, loading: loading }}
        onOk={makePayment}
        okText="Pay and Incentivize"
        onCancel={() => setVisible(false)}
      >
        <div className="modal-container">
          <p>Some rounds have insufficient funds to incentivize the specified participants.</p>
          <p>
            {`${countOfUnderFundedRecruits} ${pluralize(
              countOfUnderFundedRecruits,
              "recruiting rounds are",
              "recruiting round is"
            )}`}{" "}
            underfunded by a total of {usdFormatter.format(amountUsdCents / 100)}. Continue to fund your account and
            incentivize the participants.
          </p>
          <div className="payment-container">
            <div className="payment-title">Pay using this card</div>
            <Card className="payment-card">
              <CreditCardOutlined className="card-icon" /> {`**** **** **** ${study.tenant.stripePaymentMethodLast4}`}
            </Card>
          </div>
        </div>
      </StyledModal>
    ) : (
      <Modal
        title="Add Payment Method"
        open={visible}
        width={350}
        centered={true}
        okButtonProps={{ loading: loading }}
        onOk={addPayment}
        okText="Add payment method"
        onCancel={() => setVisible(false)}
      >
        We don't seem to have a payment method on file for you. Please add one to continue.
      </Modal>
    )
  ) : (
    <ModalPrepayDisabled open={visible} setOpen={setVisible} />
  );
};

const StyledModal = styled(Modal)`
  .payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .payment-title {
      margin-top: 1rem;
      font-size: 12px;
      text-align: center;
    }

    .payment-card {
      margin-top: 0.5rem;
      width: 100%;
      box-shadow: ${CONTAINER_BOX_SHADOW};
      font-weight: 500;

      .card-icon {
        font-size: 20px;
        padding-right: 0.5rem;
      }
    }

    .add-payment-btn {
      margin-top: 0.5rem;
    }
  }
`;

export default createFragmentContainer(ParticipantPaymentModal, {
  study: graphql`
    fragment ParticipantPaymentModal_study on StudyNode {
      tenant {
        stripePaymentMethodId
        stripePaymentMethodLast4
      }
    }
  `,
});
