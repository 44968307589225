import { QuestionCircleOutlined } from "@ant-design/icons";
import { Progress, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ThemeColor } from "../../antd";
import { RECRUIT_STATUSES } from "../../constants";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { GRAY_7 } from "../../style";
import { useFlag } from "../../utils";
import { clamp, pluralize, usdFormatter } from "../../utils/misc";
import { RecruitKPIs_recruit$data } from "../../__generated__/RecruitKPIs_recruit.graphql";
import { RecruitKPIs_study$data } from "../../__generated__/RecruitKPIs_study.graphql";

type Props = {
  study: RecruitKPIs_study$data;
  recruit: RecruitKPIs_recruit$data;
};
function RecruitKPIs({ study, recruit }: Props) {
  const series: { label: string; value: number | string | null; percent?: number; strokeColor: string }[] = [
    { label: "Link Opened", value: recruit.openedScreenerCount, strokeColor: "var(--ant-info-color)" },
    { label: "Started Screener", value: recruit.startedScreenerCount, strokeColor: "var(--ant-warning-color)" },
    { label: "Completed Screener", value: recruit.completedScreenerCount, strokeColor: "var(--ant-success-color)" },
  ];

  // show "needs review" if auto-approve is off
  if (!study.allowAutoApproval) {
    series.push({
      label: "Needs Review",
      value: recruit.needsReviewCount,
      strokeColor: "var(--ant-error-color-active)",
    });
  }

  // show spending info for pre-pay tenants
  if (useFlag("hub-recruit-prepay") && study.tenant.requireStudyPayment) {
    const totalPayouts = recruit.totalPayouts as number;
    const totalSpend = recruit.totalSpend as number;
    series.push({
      label: "Round Spending",
      value: usdFormatter.format(totalPayouts),
      percent: (100.0 * totalPayouts) / totalSpend,
      strokeColor: "var(--ant-primary-color)",
    });
  }

  function getInvitesTooltipTitle() {
    if (!recruit.recruitPersonCount || typeof recruit.invitesSentCount !== "number") {
      return "";
    } else if (recruit.invitesSentCount >= recruit.recruitPersonCount) {
      return "All invites have been sent";
    } else if (recruit.type === ScreenersRecruitTypeChoices.Hp) {
      return `Invites are sent progressively. The number of invites sent is based on the expected incidence rate for this panel. So far, ${
        recruit.invitesSentCount
      } ${pluralize(recruit.invitesSentCount, "have", "has")} been sent`;
    } else {
      return `Invites are sent progressively; so far, ${recruit.invitesSentCount} out of ${
        recruit.recruitPersonCount
      } ${pluralize(recruit.invitesSentCount, "have", "has")} been sent`;
    }
  }

  // HubUX Panel should display the round goal in the Invites Sent widget, not the count of RecruitPersons
  const emailsGoal = recruit.type !== ScreenersRecruitTypeChoices.Hp ? recruit.recruitPersonCount! : recruit.goal!;
  const emailProgressText =
    recruit.type !== ScreenersRecruitTypeChoices.Hp
      ? `${recruit.invitesSentCount} / ${emailsGoal}`
      : String(recruit.invitesSentCount);
  const goalProgressText = `${recruit.progressTowardGoalCount} / ${recruit.goal}`;

  const getLabelFontSizeEm = (value: string) => clamp(7.5 / value.length, 0.5, 1.0);
  // AntD progress bar labels are fixed width and break for wider text like "$1,234.56"; this attempts to dynamically
  // size the column
  const seriesLabelsWidthEm = Math.max(...series.map(s => String(s.value).length)) * 0.55;

  if (recruit.status === RECRUIT_STATUSES.NOT_STARTED) {
    return null;
  }

  return (
    <KPIs
      emailProgressFontSizeEm={getLabelFontSizeEm(emailProgressText)}
      goalProgressFontSizeEm={getLabelFontSizeEm(goalProgressText)}
      seriesLabelsWidthEm={Math.max(2.0, seriesLabelsWidthEm)}
    >
      {recruit.type !== ScreenersRecruitTypeChoices.Wl &&
        typeof recruit.invitesSentCount === "number" &&
        emailsGoal > 0 && (
          <div style={{ textAlign: "center" }}>
            <Progress
              className="email-progress"
              format={() => emailProgressText}
              percent={(100 * recruit.invitesSentCount) / emailsGoal}
              status={"normal"}
              strokeColor={ThemeColor.HubPurpleBright}
              type="circle"
            />
            <h4>
              Invites sent{" "}
              <Tooltip placement="bottom" title={getInvitesTooltipTitle()}>
                <QuestionCircleOutlined style={{ color: GRAY_7 }} />
              </Tooltip>
            </h4>
          </div>
        )}

      <div className="status-counts">
        {series.map((_series, i) => (
          <div className="status-progress" key={i}>
            <div className="label">{_series.label}</div>
            <div className="value">
              <Progress
                format={() => _series.value}
                key={i}
                percent={
                  _series.percent ?? (100.0 * (_series.value as number)) / (recruit.goal || recruit.invitesSentCount!)
                }
                status={"normal"}
                strokeColor={_series.strokeColor}
              />
            </div>
          </div>
        ))}
      </div>

      <div style={{ textAlign: "center" }}>
        <Progress
          className="goal-progress"
          format={() => goalProgressText}
          percent={(100.0 * recruit.progressTowardGoalCount!) / recruit.goal!}
          status={"normal"}
          strokeColor={recruit.progressTowardGoalCount! < recruit.goal! ? ThemeColor.HubPurpleBright : "#E78569"}
          type="circle"
        />
        <h4>Recruitment goal progress</h4>
      </div>
    </KPIs>
  );
}

const KPIs = styled.div<{
  emailProgressFontSizeEm: number;
  goalProgressFontSizeEm: number;
  seriesLabelsWidthEm: number;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  .email-progress {
    .ant-progress-text {
      font-size: ${props => `${props.emailProgressFontSizeEm}em`};
    }
  }

  .goal-progress {
    .ant-progress-text {
      font-size: ${props => `${props.goalProgressFontSizeEm}em`};
    }
  }

  .status-counts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .status-progress {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      gap: 10px;

      .label {
        width: 136px;
        text-align: right;
      }

      .value {
        flex-grow: 1;
      }

      // override AntD's widths if the label text would overflow
      .ant-progress-outer {
        margin-right: ${props => `calc(-${props.seriesLabelsWidthEm}em - 8px)`};
        padding-right: ${props => `calc(${props.seriesLabelsWidthEm}em + 8px)`};
      }

      .ant-progress-text {
        width: ${props => `${props.seriesLabelsWidthEm}em`};
      }
    }
  }
`;

export default createFragmentContainer(RecruitKPIs, {
  study: graphql`
    fragment RecruitKPIs_study on StudyNode {
      allowAutoApproval
      tenant {
        requireStudyPayment
      }
    }
  `,
  recruit: graphql`
    fragment RecruitKPIs_recruit on RecruitNode {
      type
      status
      goal
      invitesSentCount
      recruitPersonCount
      openedScreenerCount
      startedScreenerCount
      completedScreenerCount
      needsReviewCount
      qualifiedCount
      progressTowardGoalCount
      totalPayouts
      totalSpend
    }
  `,
});
