import outlineArchive from "@iconify-icons/ic/outline-archive";
import outlineRestore from "@iconify-icons/ic/outline-restore";
import { App, Button, Divider, Form, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { pick } from "lodash-es";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { AUTOMATED_EMAIL_TYPE, EmailTemplateContent } from "../../constants";
import { GRAY_5 } from "../../style";
import { flattenEdges, mutation } from "../../utils";
import { EditExternalIncentiveEmailModal_study$data } from "../../__generated__/EditExternalIncentiveEmailModal_study.graphql";
import { AutomatedEmailInputs } from "../Communication/AutomatedEmails/AutomatedEmailInputs";
import { getAutomatedEmailMeta } from "../Communication/AutomatedEmails/types";
import { SaveTemplateModal } from "../Communication/Templates/SaveTemplateModal";
import { SelectTemplateModal } from "../Communication/Templates/SelectTemplateModal";
import { IconButton } from "../IconButton";

type Props = {
  study: EditExternalIncentiveEmailModal_study$data;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};
export const _EditExternalIncentiveEmailModal = ({ study, visible, setVisible }: Props) => {
  const { notification } = App.useApp();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);

  const handleTemplateSelected = (emailTemplate: EmailTemplateContent) => {
    const enabled = !!form.getFieldValue("enabled");
    form.resetFields();
    form.setFieldsValue({
      enabled,
      ...emailTemplate,
    });
  };

  const handleOk = async () => {
    try {
      setIsLoading(true);
      await form.validateFields();
      const input = {
        studyId: study.id,
        type: AUTOMATED_EMAIL_TYPE.REWARDED,
        enabled: true,
        replyTo: "",
        ...form.getFieldsValue(),
      };

      if (input.assets.edges) {
        input.assets = [];
      }

      await mutation({
        variables: { input },
        mutation: graphql`
          mutation EditExternalIncentiveEmailModal_saveAutomatedEmail_Mutation($input: SaveAutomatedEmailInput!) {
            saveAutomatedEmail(input: $input) {
              study {
                ...AutomatedEmailsPage_study
              }
            }
          }
        `,
      });
      notification.success({
        message: "Successfully saved automated email",
        description: input.enabled ? (
          <p>
            Respondents will be notified when they are assigned a status of{" "}
            <em>{getAutomatedEmailMeta(AUTOMATED_EMAIL_TYPE.REWARDED)!.description}</em>.
          </p>
        ) : (
          <p>
            You can continue to make edits as needed, but respondents won't receive this automated email until it's set
            to Enabled.
          </p>
        ),
      });
      setVisible(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledEditExternalIncentiveEmailModal
      centered
      width="80%"
      open={visible}
      closable={false}
      maskClosable={!isLoading}
      footer={null}
      onCancel={() => setVisible(false)}
      zIndex={1001}
      title="Edit email to incentivize participants"
    >
      <SelectTemplateModal
        tenant={study.tenant}
        visible={showSelectTemplateModal}
        setVisible={setShowSelectTemplateModal}
        title="Select a template"
        onTemplateSelected={handleTemplateSelected}
        modalProps={{ zIndex: 1002 }}
      />
      <SaveTemplateModal
        emailTemplate={pick(form.getFieldsValue(), ["replyTo", "subject", "message"]) as EmailTemplateContent}
        tenant={study.tenant}
        visible={showSaveTemplateModal}
        setVisible={setShowSaveTemplateModal}
        modalProps={{ zIndex: 1002 }}
      />
      <Form form={form} initialValues={{ enabled: true, replyTo: "", ...study.externalIncentive?.automatedEmail }}>
        <p>To incentivize participants, you need to create an email with the link to the reward.</p>

        {!!study.tenant.emailTemplates?.edges.length && (
          <>
            <IconButton icon={outlineArchive} type="ghost" onClick={() => setShowSelectTemplateModal(true)}>
              Use a template
            </IconButton>
            <Divider />
          </>
        )}

        <AutomatedEmailInputs
          form={form}
          automatedEmail={{
            studyId: study.id,
            type: AUTOMATED_EMAIL_TYPE.REWARDED,
            enabled: true,
            ctaLabel: null,
            ctaUrl: null,
            replyTo: "",
            subject: "",
            message: "",
            ...study.externalIncentive?.automatedEmail,
            assets: study.externalIncentive?.automatedEmail?.assets
              ? flattenEdges(study.externalIncentive.automatedEmail.assets)
              : [],
          }}
          showEnabled={false}
          defaultCtaUrl={study.externalIncentive?.claimLink ?? undefined}
        />
      </Form>

      <div className="edit-external-incentive-email-buttons">
        <IconButton icon={outlineRestore} type="link" onClick={() => form.resetFields()}>
          Restore to default
        </IconButton>
        <IconButton
          className="save-as-template"
          icon={outlineArchive}
          type="link"
          onClick={() => setShowSaveTemplateModal(true)}
        >
          Save as template
        </IconButton>
      </div>

      <div className="edit-external-incentive-email-primary-buttons">
        <Button type="link" disabled={isLoading} onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button type="primary" loading={isLoading} onClick={handleOk}>
          Save
        </Button>
      </div>
    </StyledEditExternalIncentiveEmailModal>
  );
};

const StyledEditExternalIncentiveEmailModal = styled(Modal)`
  max-width: 1200px;

  .edit-external-incentive-email-buttons {
    display: flex;
    gap: 10px;

    .save-as-template {
      border: 1px solid ${GRAY_5};
    }
  }

  .edit-external-incentive-email-primary-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

export const EditExternalIncentiveEmailModal = createFragmentContainer(_EditExternalIncentiveEmailModal, {
  study: graphql`
    fragment EditExternalIncentiveEmailModal_study on StudyNode {
      id
      externalIncentive {
        claimLink
        automatedEmail {
          replyTo
          subject
          message
          ctaLabel
          ctaUrl
          assets {
            edges {
              node {
                id
                name
                sizeBytes
                url
              }
            }
          }
        }
      }
      tenant {
        emailTemplates {
          edges {
            node {
              id
            }
          }
        }
        ...SaveTemplateModal_tenant
        ...SelectTemplateModal_tenant
      }
    }
  `,
});
