/**
 * @generated SignedSource<<834cae0c14e704ba04f6b07b636dfb58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteCharacteristicInput = {
  characteristicId: string;
  clientMutationId?: string | null;
};
export type mutations_deleteCharacteristic_Mutation$variables = {
  input: DeleteCharacteristicInput;
};
export type mutations_deleteCharacteristic_Mutation$data = {
  readonly deleteCharacteristic: {
    readonly tenant: {
      readonly characteristics: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type mutations_deleteCharacteristic_Mutation = {
  response: mutations_deleteCharacteristic_Mutation$data;
  variables: mutations_deleteCharacteristic_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CharacteristicNodeConnection",
  "kind": "LinkedField",
  "name": "characteristics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_deleteCharacteristic_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCharacteristicPayload",
        "kind": "LinkedField",
        "name": "deleteCharacteristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutations_deleteCharacteristic_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCharacteristicPayload",
        "kind": "LinkedField",
        "name": "deleteCharacteristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d8dce9a86b241466e4213e6e3f66c85",
    "id": null,
    "metadata": {},
    "name": "mutations_deleteCharacteristic_Mutation",
    "operationKind": "mutation",
    "text": "mutation mutations_deleteCharacteristic_Mutation(\n  $input: DeleteCharacteristicInput!\n) {\n  deleteCharacteristic(input: $input) {\n    tenant {\n      characteristics {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b4e8a571a56fd7564ee2a43504d5e3e";

export default node;
