/**
 * @generated SignedSource<<fed6301367db0a7b92fdbfcf0369738c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type RateAllParticipantsInput = {
  clientMutationId?: string | null;
  countToSelect?: number | null;
  excludeIds?: ReadonlyArray<string | null> | null;
  filterComplex?: any | null;
  noShow?: boolean | null;
  rating?: number | null;
  studyId: string;
  tab?: string | null;
};
export type ParticipantsBatchMutations_RateAllParticipants_Mutation$variables = {
  input: RateAllParticipantsInput;
};
export type ParticipantsBatchMutations_RateAllParticipants_Mutation$data = {
  readonly rateAllParticipants: {
    readonly study: {
      readonly id: string;
      readonly needsPaymentCount: number | null;
      readonly recruits: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly status: ScreenersRecruitStatusChoices;
          } | null;
        } | null>;
      };
      readonly rejectedCount: number | null;
    } | null;
  } | null;
};
export type ParticipantsBatchMutations_RateAllParticipants_Mutation = {
  response: ParticipantsBatchMutations_RateAllParticipants_Mutation$data;
  variables: ParticipantsBatchMutations_RateAllParticipants_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsPaymentCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectedCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ParticipantsBatchMutations_RateAllParticipants_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RateAllParticipantsPayload",
        "kind": "LinkedField",
        "name": "rateAllParticipants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ParticipantsBatchMutations_RateAllParticipants_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RateAllParticipantsPayload",
        "kind": "LinkedField",
        "name": "rateAllParticipants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21fd3fe8d8923025447811752cb84c72",
    "id": null,
    "metadata": {},
    "name": "ParticipantsBatchMutations_RateAllParticipants_Mutation",
    "operationKind": "mutation",
    "text": "mutation ParticipantsBatchMutations_RateAllParticipants_Mutation(\n  $input: RateAllParticipantsInput!\n) {\n  rateAllParticipants(input: $input) {\n    study {\n      id\n      needsPaymentCount\n      rejectedCount\n      recruits {\n        edges {\n          node {\n            status\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd5abf4ce2ca15e4b7785c6194944114";

export default node;
