/**
 * @generated SignedSource<<0a2ce3c10ce23f97a464412b4a28b822>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScreenerLink_recruit$data = {
  readonly screenerLink: string;
  readonly " $fragmentType": "ScreenerLink_recruit";
};
export type ScreenerLink_recruit$key = {
  readonly " $data"?: ScreenerLink_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScreenerLink_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScreenerLink_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "screenerLink",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "5d5d1e3ce4c2f927f96d35839582189d";

export default node;
