/**
 * @generated SignedSource<<e2c6a12ed2c5c5cd1325d3ef8cd2af2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ParticipantBatchActions_study$data = {
  readonly id: string;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly customportal: {
      readonly hidePointsAndRedemption: boolean;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"GivePointsToParticipantsModal_tenant">;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"BulkScheduleModal_study" | "ConfirmExternalIncentiveModal_study" | "EditExternalIncentiveEmailModal_study" | "ParticipantPaymentModal_study" | "SlotPicker_study">;
  readonly " $fragmentType": "ParticipantBatchActions_study";
};
export type ParticipantBatchActions_study$key = {
  readonly " $data"?: ParticipantBatchActions_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantBatchActions_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ParticipantBatchActions_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BulkScheduleModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmExternalIncentiveModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditExternalIncentiveEmailModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantPaymentModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SlotPicker_study"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GivePointsToParticipantsModal_tenant"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customportal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePointsAndRedemption",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "a6f8b3ae5cd92d7cea84fa6cd64d6e82";

export default node;
