/**
 * @generated SignedSource<<61a4d957ef03085afa399155c6b6b6e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateRemindersTemplateInput = {
  clientMutationId?: string | null;
  daysBefore?: number | null;
  enabled?: boolean | null;
  hoursBefore?: number | null;
  message?: string | null;
  minsBefore?: number | null;
  subject?: string | null;
  templateId: string;
  version?: string | null;
};
export type ReminderInputMutation$variables = {
  input: UpdateRemindersTemplateInput;
};
export type ReminderInputMutation$data = {
  readonly updateRemindersTemplate: {
    readonly remindersTemplate: {
      readonly daysBefore: number;
      readonly hoursBefore: number;
      readonly id: string;
      readonly inPersonMeeting: {
        readonly message: string;
        readonly subject: string;
      } | null;
      readonly minsBefore: number;
      readonly virtualMeeting: {
        readonly message: string;
        readonly subject: string;
      } | null;
    } | null;
  } | null;
};
export type ReminderInputMutation = {
  response: ReminderInputMutation$data;
  variables: ReminderInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBefore",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hoursBefore",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minsBefore",
  "storageKey": null
},
v9 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReminderInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRemindersTemplatePayload",
        "kind": "LinkedField",
        "name": "updateRemindersTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemindersTemplatesNode",
            "kind": "LinkedField",
            "name": "remindersTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReminderTemplateVersionsNode",
                "kind": "LinkedField",
                "name": "virtualMeeting",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReminderTemplateVersionsNode",
                "kind": "LinkedField",
                "name": "inPersonMeeting",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReminderInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRemindersTemplatePayload",
        "kind": "LinkedField",
        "name": "updateRemindersTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RemindersTemplatesNode",
            "kind": "LinkedField",
            "name": "remindersTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReminderTemplateVersionsNode",
                "kind": "LinkedField",
                "name": "virtualMeeting",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReminderTemplateVersionsNode",
                "kind": "LinkedField",
                "name": "inPersonMeeting",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0e3f0996765a2691878af1d46f5b31c",
    "id": null,
    "metadata": {},
    "name": "ReminderInputMutation",
    "operationKind": "mutation",
    "text": "mutation ReminderInputMutation(\n  $input: UpdateRemindersTemplateInput!\n) {\n  updateRemindersTemplate(input: $input) {\n    remindersTemplate {\n      id\n      virtualMeeting {\n        subject\n        message\n        id\n      }\n      inPersonMeeting {\n        subject\n        message\n        id\n      }\n      daysBefore\n      hoursBefore\n      minsBefore\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e5bde2d17d5d00585a5de7e902e0d2c";

export default node;
