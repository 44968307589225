import baselineAccessAlarm from "@iconify-icons/ic/baseline-access-alarm";
import baselineBorderAll from "@iconify-icons/ic/baseline-border-all";
import calendarToday from "@iconify-icons/ic/baseline-calendar-today";
import checkCircle from "@iconify-icons/ic/baseline-check-circle";
import baselineContentPaste from "@iconify-icons/ic/baseline-content-paste";
import baselineInsertChartOutlined from "@iconify-icons/ic/baseline-insert-chart-outlined";
import baselineMailOutline from "@iconify-icons/ic/baseline-mail-outline";
import outlinePeopleAlt from "@iconify-icons/ic/outline-people-alt";
import roundListAlt from "@iconify-icons/ic/round-list-alt";
import { InlineIcon } from "@iconify/react";
import { App, Button, MenuProps, Table, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import React, { useCallback, useMemo, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import type { PartialDeep, SetRequired } from "type-fest";

import { ButtonLabel } from "..";
import { PROJECT_STATUSES, PROJECT_TYPES, SCHEDULING_TYPE } from "../../constants";
import { StudyAvailabilitySlotEdge, StudyAvailabilitySlotNode, StudyNode, TenantNode } from "../../schema";
import { GRAY_6, GRAY_8, HEADER_HEIGHT } from "../../style";
import {
  flattenEdges,
  getRecruitContext,
  getStudyContext,
  getTenantContext,
  mutation,
  trackEvent,
  useConfirm,
  type ConfirmProps,
} from "../../utils";
import { usdFormatter } from "../../utils/misc";
import { ProjectPage_CancelStudy_Mutation } from "../../__generated__/ProjectPage_CancelStudy_Mutation.graphql";
import { ProjectPage_CompleteStudy_Mutation } from "../../__generated__/ProjectPage_CompleteStudy_Mutation.graphql";
import { ProjectPage_DuplicateStudy_Mutation } from "../../__generated__/ProjectPage_DuplicateStudy_Mutation.graphql";
import { ProjectPage_LaunchStudy_Mutation } from "../../__generated__/ProjectPage_LaunchStudy_Mutation.graphql";
import { ProjectPage_ReopenStudy_Mutation } from "../../__generated__/ProjectPage_ReopenStudy_Mutation.graphql";
import { ProjectPage_study$data } from "../../__generated__/ProjectPage_study.graphql";
import PageHeading from "../Layouts/PageHeading";
import { NotificationLink } from "../Notifications/Notification";
import { OverflowButton } from "../OverflowBtn";
import SpendingDetails from "../Overview/SpendingDetails";
import SubMenuLink from "../SubMenuLink";
import { TagTag } from "../Tag";

const ProjectPage: React.FC<{
  study: ProjectPage_study$data;
}> = ({ study, children }) => {
  const { message, modal, notification } = App.useApp();

  const [launching, setLaunching] = useState<boolean>(false);
  const [showCostDetail, setShowCostDetail] = useState(false);

  const { router, match } = useRouter();

  const cancelStudy = useCallback(
    () =>
      mutation<ProjectPage_CancelStudy_Mutation>({
        variables: {
          input: {
            studyId: study?.id || "",
          },
        },
        mutation: graphql`
          mutation ProjectPage_CancelStudy_Mutation($input: CancelStudyInput!) {
            cancelStudy(input: $input) {
              success
            }
          }
        `,
      }).then(() => {
        trackEvent("Study Cancelled", {
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
          ...getRecruitContext(study.recruit as any),
        });
        router.push("/projects");
      }),
    [router, study]
  );

  const duplicateStudy = useCallback(
    () =>
      mutation<ProjectPage_DuplicateStudy_Mutation>({
        variables: {
          input: {
            studyId: study.id || "",
          },
        },
        mutation: graphql`
          mutation ProjectPage_DuplicateStudy_Mutation($input: DuplicateStudyInput!) {
            duplicateStudy(input: $input) {
              study {
                id
              }
            }
          }
        `,
      }).then((response: any) => {
        notification.success({
          message: "Project duplicated",
          description: (
            <>
              Duplicated from{" "}
              <NotificationLink router={router} to={`/projects/${study.id}/overview`}>
                {study.name}
              </NotificationLink>
            </>
          ),
        });
        trackEvent("Study Duplicated", {
          ...getRecruitContext(study.recruit as any),
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
        });
        router.push(`/projects/` + response.duplicateStudy.study.id + `/screener`);
      }),
    [notification, router, study]
  );

  const doCompleteStudy = async () => {
    return mutation<ProjectPage_CompleteStudy_Mutation>({
      variables: {
        input: {
          studyId: study.id || "",
        },
      },
      mutation: graphql`
        mutation ProjectPage_CompleteStudy_Mutation($input: CompleteStudyInput!) {
          completeStudy(input: $input) {
            study {
              id
              status
              totalDeposits
              totalPayouts
              totalRefundsDue
              totalRefunds
              totalSpend
              recruits {
                edges {
                  node {
                    status
                    totalDeposits
                    totalPayouts
                    totalRefundsDue
                    totalRefunds
                  }
                }
              }
            }
          }
        }
      `,
    }).then(
      result => {
        const refunded = parseFloat((result as any)?.completeStudy?.study?.totalRefunds);

        if (study.tenant.requireStudyPayment && !isNaN(refunded) && refunded > 0) {
          notification.success({
            message: "Refund request successful!",
            description: (
              <>
                <p>
                  Your refund request totaling <strong>{usdFormatter.format(refunded)}</strong> has posted.
                </p>
                <p>
                  Refunds may appear as separate transactions for each recruiting round with unused balance. Refunds
                  will appear on your card in 5 to 10 business days.
                </p>
              </>
            ),
            duration: 25,
          });
        }

        trackEvent("Study Completed", {
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
          ...getRecruitContext(study.recruit as any),
        });
      },
      err => {
        if (err.errors?.[0].message?.includes("unpaid participants")) showUnpaidParticipantsWarning();
        else message.error("Error completing study");
      }
    );
  };

  const showUnpaidParticipantsWarning = () => {
    modal.warning({
      title: "Cannot complete project",
      content: (
        <>
          <p>
            This project can't be marked complete yet. Please make sure you've reviewed, rated, and incentivized all
            participants.
          </p>
          {(study.tenant.incentivesOrderFulfillmentVerificationDays ?? 0) > 0 && (
            <p>
              For security reasons, it may take up to {study.tenant.incentivesOrderFulfillmentVerificationDays} days to
              issue incentives to participants.{" "}
              <a
                href="https://app.intercom.com/a/apps/bdqzfylg/articles/articles/5894232/show"
                target="_blank"
                rel="noreferrer"
              >
                Why is this happening?
              </a>
            </p>
          )}
        </>
      ),
      onOk: () => {
        router.push(`/projects/${study.id}/respondents/participants`);
      },
    });
  };

  const completeStudy = async () => {
    // Prevent marking a study complete if there are still unpaid participants
    if (study.needsReviewCount || study.needsPaymentCount) {
      showUnpaidParticipantsWarning();
      return;
    }

    if (
      await confirm({
        title: `Complete Project`,
        okText: "Complete",
        okType: "primary",
        cancelText: "Cancel",
        centered: true,
        maskClosable: true,
        ...(study.tenant.requireStudyPayment
          ? (() => {
              const asUsd = (val: any): string => {
                const floatVal = Number.parseFloat(val);
                return !isNaN(floatVal) ? usdFormatter.format(floatVal) : "—";
              };

              return {
                content: (
                  <>
                    <p>
                      Completing this project will mark all the recruiting rounds as completed, we'll stop sending
                      invitations, and your project link will be deactivated. You'll still be able to see the project
                      reports.
                    </p>
                    <Table
                      columns={[
                        { dataIndex: "label" },
                        { title: "Recruiting Round Costs", dataIndex: "totalDeposits", render: asUsd },
                        { title: "Refund Amount", dataIndex: "totalRefundsDue", render: asUsd },
                        { title: "Total Costs", dataIndex: "totalPayouts", render: asUsd },
                      ]}
                      dataSource={[{ ...study, label: "Total" }]}
                      bordered
                      pagination={false}
                    />
                    <p style={{ margin: 16 }}>
                      <a
                        href="https://intercom.help/hubux/en/articles/5803248-what-if-a-round-doesn-t-reach-the-goal"
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={e => {
                          e.preventDefault();
                          window.Intercom?.("showArticle", 5803248);
                        }}
                      >
                        What if a round doesn't reach the recruitment goal?
                      </a>
                      &emsp;
                      <a
                        href="https://intercom.help/hubux/en/articles/5803254-where-will-i-get-the-refund"
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={e => {
                          e.preventDefault();
                          window.Intercom?.("showArticle", 5803254);
                        }}
                      >
                        Where will I get the refund?
                      </a>
                    </p>
                    <Button type="link" onClick={() => setShowCostDetail(true)}>
                      Show cost details
                    </Button>
                  </>
                ),
                icon: null,
                width: 800,
              };
            })()
          : {
              content: (
                <>
                  <p>Marking a project complete will:</p>
                  <ul>
                    <li>Stop all recruiting rounds</li>
                    <li>Prevent new recruiting rounds</li>
                    <li>Stop respondents from accessing the project's screener</li>
                  </ul>
                </>
              ),
            }),
      })
    )
      doCompleteStudy();
  };

  const reopenStudy = useCallback(async () => {
    if (
      !(await new Promise(resolve =>
        modal.info({
          title: "Open project again",
          content: "You will be able to start recruiting again.",
          okCancel: true,
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        })
      ))
    ) {
      return;
    }

    await mutation<ProjectPage_ReopenStudy_Mutation>({
      variables: { input: { studyId: study.id } },
      mutation: graphql`
        mutation ProjectPage_ReopenStudy_Mutation($input: ReopenStudyInput!) {
          reopenStudy(input: $input) {
            study {
              status
            }
          }
        }
      `,
    });

    notification.info({ message: "Project reopened" });

    router.push(`/projects/${study.id}/recruit`);
  }, [modal, notification, router, study.id]);

  const confirm = useConfirm();
  const items = useMemo<MenuProps["items"] & {}>(
    () => [
      {
        key: "project-settings",
        onClick: () => router.push(`/projects/${study.id}/settings`),
        label: "Project details",
      },
      ...(study.status === PROJECT_STATUSES.DRAFT
        ? [
            {
              key: "set-active",
              onClick: () => showLaunchModal({ confirm, study, launching, setLaunching, router }),
              label: (<span style={{ color: "var(--ant-primary-color)", fontWeight: 500 }}>Set active</span>) as any,
            },
            {
              key: "delete",
              onClick: cancelStudy,
              label: (
                <span style={{ color: "var(--ant-error-color-active)", fontWeight: 500 }}>Delete draft</span>
              ) as any,
            },
          ]
        : [
            {
              key: "set-active",
              onClick: duplicateStudy,
              label: "Duplicate",
            },
          ]),
      ...(study.status === PROJECT_STATUSES.COMPLETE
        ? [
            {
              key: "reopen",
              onClick: reopenStudy,
              label: "Reopen project",
            },
          ]
        : []),
    ],
    [cancelStudy, confirm, duplicateStudy, launching, reopenStudy, router, study]
  );

  return (
    <ProjectPageWrapper>
      <PageHeading
        pageTitle={study?.name}
        headerActionButtons={
          <div className="hub-header-actions">
            {study.status === PROJECT_STATUSES.DRAFT ? (
              <StyledActiveText>
                <div className="yellow-ring" />
                <span className="active-text">Draft</span>
              </StyledActiveText>
            ) : study.status === PROJECT_STATUSES.LIVE ? (
              <StyledActiveText>
                <div className="green-dot" />
                <span className="active-text">Active</span>
              </StyledActiveText>
            ) : (
              <StyledActiveText>
                <InlineIcon width="16" color="var(--ant-primary-color)" icon={checkCircle} />
                <span style={{ marginLeft: "10px" }} className="active-text">
                  Complete
                </span>
              </StyledActiveText>
            )}
            {!!study.tags.length && (
              <ul className="hub-project-page-tags">
                {study.tags.map(x => (
                  <TagTag key={x.id} tagKey={x} />
                ))}
              </ul>
            )}
            <HeaderButtons>
              {study.status === PROJECT_STATUSES.LIVE && (
                <Tooltip title="Complete Study">
                  <ButtonLabel
                    className="complete--button"
                    icon="mdi:check-circle-outline"
                    iconScale={1.5}
                    onClick={completeStudy}
                    style={{ marginLeft: -4 }}
                    text="Complete project"
                    type="primary"
                  />
                </Tooltip>
              )}
              <OverflowButton menu={items} />
            </HeaderButtons>
          </div>
        }
        showSubMenu={!match.location.pathname.includes("settings")}
        headerSubMenu={
          <>
            <div style={{ flex: "auto", display: "flex" }}>
              {[PROJECT_TYPES.INTERVIEW, PROJECT_TYPES.FOCUS_GROUP].includes(study.type) && (
                <SubMenuLink
                  className="nav-slots"
                  active={match.location.pathname.includes("slots")}
                  to={`/projects/${study.id}/slots`}
                  icon={<InlineIcon width={18} icon={calendarToday} />}
                  text="Booking"
                />
              )}
              <SubMenuLink
                className="nav-screener"
                active={match.location.pathname.includes("screener")}
                to={`/projects/${study.id}/screener`}
                icon={<InlineIcon width={18} icon={roundListAlt} />}
                text="Screener"
              />
              <SubMenuLink
                className="nav-segments"
                active={match.location.pathname.includes("segments")}
                to={`/projects/${study.id}/segments`}
                icon={<InlineIcon width={18} icon={baselineBorderAll} />}
                text="Segments"
              />
              <SubMenuLink
                className="nav-recruit"
                active={match.location.pathname.includes("recruit")}
                to={`/projects/${study.id}/recruit`}
                icon={<InlineIcon width={18} icon={baselineContentPaste} />}
                text="Recruit"
              />
              <SubMenuLink
                className="nav-participants"
                active={match.location.pathname.includes("participants")}
                to={`/projects/${study.id}/respondents/participants`}
                icon={<InlineIcon width={18} icon={outlinePeopleAlt} />}
                text="Participants"
              />
            </div>
            {(study.type === PROJECT_TYPES.INTERVIEW || study.type === PROJECT_TYPES.FOCUS_GROUP) && (
              <SubMenuLink
                className="nav-reminders"
                active={match.location.pathname.includes("reminders")}
                to={`/projects/${study.id}/reminders`}
                icon={<InlineIcon width={18} icon={baselineAccessAlarm} />}
                tooltip="Reminders"
              />
            )}
            <SubMenuLink
              className="nav-communication"
              active={match.location.pathname.includes("communications")}
              to={`/projects/${study.id}/communications/history`}
              icon={<InlineIcon width={18} icon={baselineMailOutline} />}
              tooltip="Communication"
              tooltipPlacement={study.status === PROJECT_STATUSES.DRAFT ? "bottomLeft" : undefined}
            />
            {study.status !== PROJECT_STATUSES.DRAFT && (
              <SubMenuLink
                className="nav-overview"
                active={match.location.pathname.includes("overview")}
                to={`/projects/${study.id}/overview`}
                icon={<InlineIcon width={18} icon={baselineInsertChartOutlined} />}
                tooltip="Overview"
                tooltipPlacement="bottomLeft"
              />
            )}
          </>
        }
      />
      <Content>
        <div>{children}</div>
      </Content>
      <SpendingDetails visible={showCostDetail} onClose={() => setShowCostDetail(false)} study={study} />
    </ProjectPageWrapper>
  );
};

const ProjectPageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .hub-header-actions {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .hub-project-page-tags {
    padding: 0;
    margin: 0;
    flex: 1;
    display: inline-flex;
    flex-wrap: wrap;
    row-gap: 8px;

    > * {
      flex-shrink: 0;
    }
  }
`;

const Content = styled.div`
  position: relative;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  > div {
    display: flex;
    justify-content: center;
    max-height: 100%;
    overflow: auto;

    .ant-badge-count {
      background: white;
      box-shadow: 0 0 0 1px #d9d9d9 inset;
      color: #999;
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: 0;
    }

    .ant-menu-submenu > .ant-menu {
      background-color: #f8f8f8;
    }
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  // adds margin for Header Link / button elements
  button {
    height: 40px;
    font-weight: 500;
    border: 1px solid ${GRAY_6};
    color: ${GRAY_8};
    :hover {
      color: ${GRAY_8};
      border: 1px solid ${GRAY_6};
      background-color: rgba(0, 0, 0, 0.04);
    }
    :not(:last-child) {
      margin-right: 14px;
    }
  }
  .cancel--button {
    color: var(--ant-error-color-active);
    border: 1px solid var(--ant-error-color-active);
    :hover {
      color: var(--ant-error-color-active);
      border: 1px solid var(--ant-error-color-active);
      background-color: rgba(207, 19, 34, 0.04);
    }
  }

  .complete--button {
    color: white;
    border: none;
    background-color: var(--ant-primary-color);
    :hover {
      color: white;
      border: none;
      background-color: #0741a8;
    }
  }
`;

const StyledActiveText = styled.div`
  display: flex;
  align-items: center;

  .active-text {
    color: ${GRAY_8};
    font-weight: 500;
  }
  .green-dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #7cb305;
    margin-right: 10px;
  }
  .yellow-ring {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid var(--ant-warning-color);
    margin-right: 10px;
  }
`;

const launch = async ({
  study,
  setLaunching,
  router,
}: {
  study: Parameters<typeof launchStudy>[0]["study"];
  setLaunching?: (value: boolean) => void;
  router: any;
}) => {
  try {
    setLaunching && setLaunching(true);
    return launchStudy({ study, router });
  } finally {
    setLaunching && setLaunching(false);
  }
};

const launchStudy = ({
  study,
  router,
}: {
  study: SetRequired<
    Pick<StudyNode, "id" | "dId" | "type" | "name" | "status">,
    "id" | "dId" | "type" | "name" | "status"
  > & {
    readonly tenant: SetRequired<PartialDeep<TenantNode>, "name">;
  };
  router: any;
}) =>
  mutation<ProjectPage_LaunchStudy_Mutation>({
    variables: {
      input: {
        studyId: study.id || "",
      },
    },
    mutation: graphql`
      mutation ProjectPage_LaunchStudy_Mutation($input: LaunchStudyInput!) {
        launchStudy(input: $input) {
          study {
            dId
            id
            name
            status
            type
            screener {
              elementTypeCounts {
                elementType
                count
              }
            }
            tenant {
              dId
              vpmAccountId
              name
            }
          }
        }
      }
    `,
  }).then(result => {
    if (result.launchStudy?.study?.tenant)
      trackEvent("Set Project to Live", {
        ...getStudyContext(result.launchStudy.study),
        ...getTenantContext(result.launchStudy.study.tenant),
        "Question Types": result.launchStudy.study.screener?.elementTypeCounts,
      });

    router && router.push(`/projects/${study.id}/overview`);
  });

export const showLaunchModal = async ({
  confirm,
  study,
  launching,
  setLaunching,
  router,
  onSuccess,
}: {
  confirm: (props: ConfirmProps) => Promise<boolean>;
  study: SetRequired<
    Pick<StudyNode, "name" | "schedulingLink" | "schedulingType" | "type">,
    "name" | "schedulingLink" | "schedulingType" | "type"
  > & {
    readonly availabilitySlots: {
      readonly edges: readonly (
        | (PartialDeep<StudyAvailabilitySlotEdge> & {
            readonly node?: SetRequired<PartialDeep<StudyAvailabilitySlotNode>, "duration"> | null | undefined;
          })
        | null
      )[];
    };
  } & Parameters<typeof launch>[0]["study"];
  launching?: boolean;
  setLaunching?: (value: boolean) => void;
  router?: any;
  onSuccess?: any;
}) => {
  let okButtonProps = {
    disabled: launching,
    loading: launching,
  };
  let errors: Array<string> = [];

  // errors for all projects
  if (!study.name) {
    errors.push("The project is missing a name");
  }

  if (study.type === PROJECT_TYPES.INTERVIEW || study.type === PROJECT_TYPES.FOCUS_GROUP) {
    if (study.schedulingType === SCHEDULING_TYPE.HUBUX) {
      if (flattenEdges(study.availabilitySlots).some(x => (x?.duration ?? null) === null)) {
        errors.push("All availability slots must have a duration specified");
      }
    } else if (study.schedulingType === SCHEDULING_TYPE.CALENDLY) {
      if (!study.schedulingLink) {
        errors.push("The project is missing a Calendly scheduling link");
      }
    }
  }

  let title = "Some items remain...";
  let content = (
    <>
      <p>Please correct the following before going active:</p>
      <ul style={{ paddingLeft: 18 }}>
        {errors.map(e => (
          <li>{e}</li>
        ))}
      </ul>
    </>
  );
  let okText = "Ok";
  let cancelButtonProps: any = { style: { visibility: "hidden" } };

  if (errors.length === 0) {
    title = "Ready to go live?";
    content = (
      <p>Once active, your study will start screening for participants based on your recruiting preferences.</p>
    );
    cancelButtonProps = {};
  }

  if (
    !(await confirm({
      title,
      content,
      okText,
      okButtonProps,
      cancelButtonProps,
    })) ||
    errors.length
  )
    return;

  await launch({ study, setLaunching, router });
  onSuccess?.();
};

export default createFragmentContainer(ProjectPage, {
  study: graphql`
    fragment ProjectPage_study on StudyNode {
      id
      dId
      name
      tags {
        id
        ...TagTag_tag
      }
      needsReviewCount
      needsPaymentCount
      status
      screenerLink
      defaultIncentive
      incentiveType
      defaultIncentivePoints
      scheduled
      type
      totalDeposits
      totalPayouts
      totalRefundsDue
      schedulingType
      schedulingLink
      meetingLink
      availabilityBuffer
      availabilitySlots {
        edges {
          node {
            id
            duration
          }
        }
      }
      recruit {
        type
      }
      tenant {
        requireStudyPayment
        incentivesOrderFulfillmentVerificationDays
        name
        dId
        vpmAccountId
        name
      }
      ...SpendingDetails_study
      dId
      name
      type
      status
    }
  `,
});
