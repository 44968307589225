import outlineArchive from "@iconify-icons/ic/outline-archive";
import outlineSend from "@iconify-icons/ic/outline-send";
import { useUpdateEffect } from "@react-hookz/web";
import { Alert, App, Form, FormInstance, Input, Popconfirm, Radio } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { omit } from "lodash-es";
import React, { useMemo, useState } from "react";
import { commitMutation, createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { EmailTemplateContent } from "../../constants";
import { environment } from "../../relay";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { GRAY_5 } from "../../style";
import {
  getRecruitContext,
  getStudyContext,
  getTenantContext,
  getUserContext,
  handleErrorWithMessage,
  trackEvent,
} from "../../utils";
import { pluralize } from "../../utils/misc";
import {
  CommunicationComposePageSaveRecruitEmailMutation,
  UpdateRecruitInput,
} from "../../__generated__/CommunicationComposePageSaveRecruitEmailMutation.graphql";
import {
  CommunicationComposePageSendStudyEmailMutation,
  CommunicationComposePageSendStudyEmailMutation$data,
  SendStudyEmailInput,
} from "../../__generated__/CommunicationComposePageSendStudyEmailMutation.graphql";
import { CommunicationComposePage_recruit$data } from "../../__generated__/CommunicationComposePage_recruit.graphql";
import { CommunicationComposePage_study$data } from "../../__generated__/CommunicationComposePage_study.graphql";
import { CommunicationComposePage_user$data } from "../../__generated__/CommunicationComposePage_user.graphql";
import { IconButton } from "../IconButton";
import { FormItemCSV } from "../index";
import { NotificationLink } from "../Notifications/Notification";

import FormItemParticipants from "./FormItemParticipants";
import { SaveTemplateModal } from "./Templates/SaveTemplateModal";

type SendTo = "ALL_PARTICIPANTS" | "SPECIFIED_PARTICIPANTS" | "CUSTOM_LIST";

const CommunicationComposePage = React.forwardRef<
  FormInstance,
  {
    study: CommunicationComposePage_study$data;
    user: CommunicationComposePage_user$data;
    recruit?: CommunicationComposePage_recruit$data;
    initialMessage?: EmailTemplateContent;
    onEmailSent?: () => void;
  }
>(({ study, user, recruit, initialMessage, onEmailSent }, formRef) => {
  const { message: antdMessage, notification } = App.useApp();
  const { router } = useRouter();
  const [form] = Form.useForm();

  useUpdateEffect(() => {
    form.resetFields();
    if (!(initialMessage?.message && initialMessage?.subject)) setButtonDisabled(false);
  }, [form, initialMessage]);

  const helpMessages = {
    ALL_PARTICIPANTS: "This message will be sent to all participants (qualified).",
    SPECIFIED_PARTICIPANTS: "This message will be sent to the specified participants.",
    CUSTOM_LIST: (
      <div>
        <p style={{ marginBottom: 0 }}>
          This message will be sent to the emails in the specified csv. We currently support a max. of 1000 email
          addresses per message.
        </p>
      </div>
    ),
  };

  const [sendTo, setSendTo] = useState<SendTo>(!recruit ? "ALL_PARTICIPANTS" : "CUSTOM_LIST");
  const [replyTo, setReplyTo] = useState<string>(
    recruit?.meta?.replyTo || study?.tenant?.googleCalendarEmail || user?.email || ""
  );
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [replyToValid, setReplyToValid] = useState(!!replyTo);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(!(initialMessage?.message && initialMessage?.subject));
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);

  const recruitEmails = useMemo<string[]>(
    () => (recruit?.type === ScreenersRecruitTypeChoices.Em ? recruit.meta?.emails ?? [] : []),
    [recruit?.meta?.emails, recruit?.type]
  );

  const checkValidity = (name: "subject" | "message" | "replyTo" | "to") => {
    if (name === "replyTo") {
      form
        .validateFields(["replyTo"])
        .then(() => setReplyToValid(true))
        .catch(err => setReplyToValid(!!replyTo && !err.errorFields.some((x: any) => x.name.includes("replyTo"))));
    }

    const allTouched = form.isFieldsTouched(["subject", "message"], true);
    if (allTouched) {
      form
        .validateFields()
        .then(value => {
          if (value) {
            setButtonDisabled(false);
          }
        })
        .catch(err => {
          console.error("error", err);
          setButtonDisabled(true);
        });
    } else {
      setButtonDisabled(true);
    }
  };

  const description = (
    <div style={{ width: !recruit ? 230 : 170 }}>
      <p>You can include data about each recipient using these variables:</p>
      <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
        {sendTo === "CUSTOM_LIST" ? (
          <>
            <li>%study.screener_link%</li>
            <li>%study.name%</li>
          </>
        ) : (
          <>
            <li>%respondent.id%</li>
            <li>%respondent.first_name%</li>
            <li>%respondent.last_name%</li>
            <li>%respondent.email%</li>
            <li>%study.name%</li>
          </>
        )}
      </ul>
    </div>
  );

  function saveRecruitEmail() {
    if (!recruit?.id) {
      return;
    }

    try {
      const meta = form.getFieldsValue();
      updateRecruit({
        recruitId: recruit.id,
        selectedEmails: meta.emails,
        meta: {
          ...omit(meta, "emails"),
        },
      });
    } catch {
      antdMessage.error("An error occurred while saving your settings.");
      return;
    }

    trackEvent("Recruit Email Saved", {
      ...getRecruitContext(recruit),
      ...getStudyContext(study),
      ...getTenantContext(study.tenant as any),
      ...getUserContext(user as any),
    });
  }

  const sendEmail = (e: any) => {
    if (recruit?.id) {
      return;
    }

    form
      .validateFields()
      .then((values: any) =>
        sendStudyEmail({
          studyId: study.id,
          message: values.message,
          subject: values.subject,
          sendTo: values.sendTo,
          to: values.to,
          emails: values.emails,
          replyTo: values.replyTo,
        })
      )
      .then(res =>
        typeof res.sendStudyEmail?.response?.recipientCount === "number"
          ? notification.success({
              message: "Email sent",
              description: (
                <>
                  The email was sent to {res.sendStudyEmail.response.recipientCount.toLocaleString()}{" "}
                  {pluralize(res.sendStudyEmail.response.recipientCount, "participants", "participant")}. You can see it
                  in{" "}
                  <NotificationLink router={router} to={`/projects/${study.id}/communications/history`}>
                    History
                  </NotificationLink>
                  .
                </>
              ),
            })
          : Promise.resolve()
      )
      .then(() => {
        form.setFieldsValue({
          message: "",
          subject: "",
          to: [],
          emails: [],
          replyTo: user?.email || "",
        });
      })
      .catch(err => handleErrorWithMessage(err, "An error occurred when sending. Please try again later."))
      .then(() => {
        trackEvent("Batch Email Sent", {
          ...getRecruitContext(recruit),
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
          ...getUserContext(user as any),
          "Sent to": sendTo,
        });
        onEmailSent?.();
      });
  };

  let initialValues = {
    sendTo,
    replyTo: initialMessage?.replyTo || replyTo || "",
    subject: initialMessage?.subject ?? "",
    message: initialMessage?.message ?? "",
  };

  if (recruit?.id) {
    initialValues = {
      ...initialValues,
      ...(recruit?.meta as object),
    };
  }

  const handleSaveAsTemplateClick = () => {
    setShowSaveAsTemplateModal(true);
  };

  return (
    <Styled>
      <SaveTemplateModal
        visible={showSaveAsTemplateModal}
        setVisible={setShowSaveAsTemplateModal}
        emailTemplate={{
          replyTo: form.getFieldValue("replyTo"),
          subject: form.getFieldValue("subject"),
          message: form.getFieldValue("message"),
        }}
        tenant={study.tenant}
      />
      <Form ref={formRef} form={form} initialValues={initialValues}>
        {!recruit && (
          <Form.Item
            {...formItemLayout}
            style={{ marginBottom: 12 }}
            name="sendTo"
            label="Send To"
            help={helpMessages[sendTo]}
          >
            <Radio.Group
              onChange={e => {
                setSendTo(e.target.value);
                saveRecruitEmail();
              }}
            >
              <Radio value="ALL_PARTICIPANTS">All Participants</Radio>
              <Radio value="SPECIFIED_PARTICIPANTS">Specified Participants</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {sendTo === "SPECIFIED_PARTICIPANTS" && (
          <FormItemParticipants
            formItemLayout={formItemLayout}
            name="to"
            label="To"
            study={study}
            checkValidity={checkValidity}
          />
        )}
        {sendTo === "CUSTOM_LIST" && (
          <FormItemCSV
            formItemLayout={formItemLayout}
            name="emails"
            label="Emails"
            toEmails={recruitEmails}
            checkValidity={name => {
              checkValidity(name);
              saveRecruitEmail();
            }}
          />
        )}
        <Form.Item
          {...formItemLayout}
          style={{ marginBottom: 12 }}
          name="replyTo"
          label="Reply To"
          initialValue={replyTo}
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
          help={
            replyToValid
              ? !!(study?.tenant?.emailDomain && replyToValid) &&
                (replyTo.includes(study.tenant.emailDomain) ? (
                  "Emails will send directly from this address"
                ) : (
                  <span>
                    Emails will send from <b>support@{study.tenant.emailDomain}</b> with <b>{replyTo}</b> as the "reply
                    to" address.
                  </span>
                ))
              : "Please enter a valid email address"
          }
        >
          <Input
            placeholder="Enter the email address replies should be sent to"
            onChange={e => {
              setReplyTo(e.target.value);
              checkValidity("replyTo");
            }}
            onBlur={saveRecruitEmail}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: "Please provide a subject",
            },
          ]}
        >
          <Input
            placeholder="Enter the subject of your message"
            onChange={e => {
              setSubject(e.target.value);
              checkValidity("subject");
            }}
            onBlur={saveRecruitEmail}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="message"
          label="Message"
          rules={[
            {
              required: true,
              message: "Please provide a message",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Enter the message you want to send to your participants"
            rows={10}
            onChange={e => {
              setMessage(e.target.value);
              checkValidity("message");
            }}
            onBlur={saveRecruitEmail}
          />
        </Form.Item>
        {!recruit && (
          <Form.Item>
            <div className="button-wrapper">
              <Popconfirm
                disabled={buttonDisabled}
                title="Are you sure you wish to send this email?"
                placement="right"
                okText="Yes"
                cancelText="No"
                onConfirm={sendEmail}
              >
                <IconButton
                  icon={outlineSend}
                  type="primary"
                  disabled={buttonDisabled}
                  htmlType="submit"
                  style={{ marginRight: 24 }}
                >
                  Send
                </IconButton>
              </Popconfirm>
              <IconButton
                icon={outlineArchive}
                className="save-as-template"
                type="ghost"
                onClick={handleSaveAsTemplateClick}
                disabled={!replyTo.trim() && !subject.trim() && !message.trim()}
              >
                Save as template
              </IconButton>
            </div>
          </Form.Item>
        )}
      </Form>
      <div className="alert-wrapper">
        <Alert message="Variables" description={description} />
      </div>
    </Styled>
  );
});

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

export const COMPOSE_PAGE_WIDTH = "900px";

const Styled = styled.div`
  display: flex;
  width: ${COMPOSE_PAGE_WIDTH};

  .ant-form {
    flex-grow: 1;
  }
  .alert-wrapper {
    flex-basis: 50px;

    ul {
      padding-left: 22px;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    gap: 24px;

    .save-as-template {
      border: 1px solid ${GRAY_5};
    }
  }

  .csv-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .csv-label {
    margin-top: 1px;
    color: var(--ant-primary-color);
    cursor: pointer;
  }
`;

const mutation = graphql`
  mutation CommunicationComposePageSendStudyEmailMutation($input: SendStudyEmailInput!) {
    sendStudyEmail(input: $input) {
      response {
        sent
        recipientCount
      }
    }
  }
`;

function sendStudyEmail(input: SendStudyEmailInput): Promise<CommunicationComposePageSendStudyEmailMutation$data> {
  const variables = {
    input,
  };
  return new Promise((resolve, reject) => {
    commitMutation<CommunicationComposePageSendStudyEmailMutation>(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => resolve(response),
      onError: err => reject(err),
    });
  });
}

function updateRecruit(input: UpdateRecruitInput) {
  const variables = {
    input,
  };
  return new Promise((resolve, reject) => {
    commitMutation<CommunicationComposePageSaveRecruitEmailMutation>(environment, {
      mutation: graphql`
        mutation CommunicationComposePageSaveRecruitEmailMutation($input: UpdateRecruitInput!) {
          updateRecruit(input: $input) {
            recruit {
              id
              type
              meta
            }
          }
        }
      `,
      variables,
    });
  });
}

export default createFragmentContainer(CommunicationComposePage, {
  study: graphql`
    fragment CommunicationComposePage_study on StudyNode {
      dId
      id
      tenant {
        name
        emailDomain
        googleCalendarEmail
        dId
        vpmAccountId
        name

        ...SaveTemplateModal_tenant
      }
      status
      type
      dId
      name
      type
      status
      ...FormItemParticipants_study @arguments(count: $count, search: $search)
    }
  `,
  user: graphql`
    fragment CommunicationComposePage_user on UserNode {
      email
      dId
      vpmUserId
      panelist {
        dId
      }
    }
  `,
  recruit: graphql`
    fragment CommunicationComposePage_recruit on RecruitNode {
      id
      meta
      name
      type
      dbId
      type
      name
    }
  `,
});
