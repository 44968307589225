import { InputNumber, Typography, type FormInstance } from "antd";
import { useCallback, useEffect, useRef, useState, type ReactNode } from "react";
import styled from "styled-components";

import { USD_PER_POINT } from "../../constants";
import { GRAY_7 } from "../../style";
import { ignoreWheel, numberFormatter, pluralize, usdFormatter } from "../../utils/misc";

const { Text } = Typography;

export const InputPoints = ({
  title,
  showCaptions,
  form,
  optional,
  pointsFieldName,
  inputClassName,
  onChange,
  defaultPoints,
  stringMode = false,
}: {
  title?: ReactNode;
  showCaptions: boolean;
  form: FormInstance;
  optional?: boolean;
  pointsFieldName: string;
  inputClassName?: string;
  defaultPoints?: number;
} & (
  | {
      onChange?: (points: number | null) => void;
      stringMode?: false;
    }
  | {
      onChange?: (points: string) => void;
      stringMode: true;
    }
)) => {
  const [points, setPoints] = useState<number | undefined>(defaultPoints);
  const pointsInputRef = useRef<HTMLInputElement>(null);
  const [usd, setUsd] = useState<number | undefined>(
    typeof defaultPoints === "number" ? defaultPoints * USD_PER_POINT : undefined
  );

  const updateValues = useCallback(
    (points: number | null) => {
      const _points = stringMode ? String(points) : points;

      setPoints(points ?? undefined);
      setUsd((points ?? 0) * USD_PER_POINT);
      form.setFieldsValue({ [pointsFieldName]: _points });
      // @ts-expect-error can't narrow onChange via _points
      onChange?.(_points);
    },
    [form, pointsFieldName, onChange, setPoints, setUsd, stringMode]
  );

  // force an initial update to populate fields
  useEffect(() => updateValues(points ?? 0), [points, updateValues]);

  useEffect(() => {
    if (!pointsInputRef.current) {
      return;
    }

    // workaround for InputNumber failing to parse formatted values like "1,234 points"
    pointsInputRef.current.onkeyup = evt => {
      const pointsVal = parseInt(pointsInputRef.current?.value.replace(/[^\-0-9]/g, "") ?? "");
      if (!isNaN(pointsVal) && pointsVal >= 0) {
        updateValues(pointsVal);
      }
    };
  }, [pointsInputRef, updateValues]);

  const setPointsFromUsd = (value: number | null) => {
    const points = getPointsForUsd(value);
    if (typeof points === "number") updateValues(points);
  };

  return (
    <StyledPointsInput>
      {title && (
        <div className="title-wrapper">
          <div className="title">{title}</div>
          <div className={`optional ${optional && "right-margin"}`}>{optional && "(optional)"}</div>
        </div>
      )}
      <div className="input">
        <InputNumber
          className={inputClassName ? inputClassName : undefined}
          size="large"
          style={{ width: "100%" }}
          value={usd}
          formatter={(value, { input, userTyping }) =>
            userTyping ? input : typeof usd === "number" ? usdFormatter.format(usd) : ""
          }
          step={USD_PER_POINT}
          onChange={setPointsFromUsd}
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              // @ts-expect-error antd doesn't type event targets correctly
              setPointsFromUsd(e.target.getAttribute("aria-valuenow"));
            }
          }}
          onWheel={ignoreWheel}
          min={0}
        />
        {showCaptions && <div className="description">Reference amount</div>}
      </div>
      <div className="equals">=</div>
      <div className="input">
        <InputNumber
          ref={pointsInputRef}
          className={inputClassName ? inputClassName : undefined}
          size="large"
          style={{ width: "100%", margin: "0" }}
          value={points}
          formatter={(value, { input, userTyping }) =>
            userTyping
              ? input
              : `${numberFormatter.format(value ?? 0)} ${pluralize(Math.round(value ?? 0), "points", "point")}`
          }
          onChange={updateValues}
          onWheel={ignoreWheel}
          min={0}
        />
        {showCaptions && (
          <Text type="secondary" className="description">
            One point equals {usdFormatter.format(USD_PER_POINT)}
          </Text>
        )}
      </div>
    </StyledPointsInput>
  );
};

// get the points conversion for the provided USD (or nothing if not a multiple of USD_PER_POINT)
function getPointsForUsd(usd: number | null): number | void {
  if (usd === null) return 0;

  const usdCents = Math.round(usd * 100);
  const usdCentsPerPoint = Math.round(USD_PER_POINT * 100);

  if (usdCents % usdCentsPerPoint === 0) {
    return Math.round(usd / USD_PER_POINT);
  }
}

const StyledPointsInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 14px 1fr;

  .title-wrapper {
    display: flex;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 8px;

    .title {
      grid-column-start: 1;
      grid-column-end: 4;
      font-size: 14px;
      font-weight: 700;
    }
    .right-margin {
      margin-right: 4px;
    }
    .optional {
      font-size: 14px;
      color: ${GRAY_7};
      margin-left: 4px;
    }
  }

  .equals {
    text-align: center;
    margin-top: 8px;
    font-weight: 500;
  }

  .input {
    .ant-input-number {
      margin-bottom: 4px;
    }

    .description {
      color: ${GRAY_7};
      font-size: 11px;
      text-align: left;
    }
  }
`;
