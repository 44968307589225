import { Tooltip, type TooltipProps } from "antd";
import { Link } from "found";
import { useMemo } from "react";
import styled from "styled-components";

import { GRAY_8 } from "../style";

const SubMenuLink = ({
  active,
  className,
  icon,
  text,
  to,
  tooltip,
  tooltipPlacement = "bottom",
}: {
  active: boolean;
  to: string;
  icon: JSX.Element;
  className: string;
  tooltipPlacement?: TooltipProps["placement"];
} & ({ text: string; tooltip?: TooltipProps["title"] } | { tooltip: TooltipProps["title"]; text?: string })) => {
  const Inner = useMemo(
    () => (
      <SubMenuLinkWrapper active={active ? 1 : 0} to={to}>
        <div className={className}>
          {icon}
          {!!text && <span>{text}</span>}
        </div>
      </SubMenuLinkWrapper>
    ),
    [active, className, icon, text, to]
  );

  return tooltip ? (
    <Tooltip arrowPointAtCenter placement={tooltipPlacement} title={tooltip}>
      {/* Tooltip wrapper used to remove React.forwardRef errors*/}
      <TooltipWrapper>{Inner}</TooltipWrapper>
    </Tooltip>
  ) : (
    Inner
  );
};

const SubMenuLinkWrapper = styled(Link)<{ active?: 1 | 0 }>`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  font-weight: ${({ active }) => (active ? "500" : "400")};
  font-size: 12px;
  color: ${({ active }) => (active ? "var(--ant-primary-color)" : GRAY_8)};
  border-bottom: solid ${({ active }) => (active ? "var(--ant-primary-color) 2px" : "0px")};
  // Interim solution for making submenu semi-responsive
  @media only screen and (min-width: 840px) {
    font-size: 16px;
  }
  :hover {
    color: var(--ant-primary-color);
  }
  :not(:last-child) {
    margin-right: 26px;
  }

  span {
    margin-left: 4px;
  }
`;

const TooltipWrapper = styled.div`
  :not(:last-child) {
    margin-right: 26px;
  }
`;

export default SubMenuLink;
