import { Form, Input, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { SAVING_STATES } from "../../constants";
import { ScreenersReminderTemplateVersionMeetingTypeChoices } from "../../schema";
import { PRIMARY_TEXT_COLOR } from "../../style";
import { mutation } from "../../utils";
import { ReminderInputMutation } from "../../__generated__/ReminderInputMutation.graphql";
import { ReminderInput_template$data } from "../../__generated__/ReminderInput_template.graphql";
import { SavingStateType } from "../Saving";

const { Option } = Select;

type Props = {
  version: ScreenersReminderTemplateVersionMeetingTypeChoices;
  template: ReminderInput_template$data;
  setSavingState: (savingState: SavingStateType) => void;
};
const ReminderInput: React.FC<Props> = ({ version, template, setSavingState }) => {
  const updateRemindersTemplate = async (input: {
    daysBefore?: number;
    hoursBefore?: number;
    minsBefore?: number;
    version?: ScreenersReminderTemplateVersionMeetingTypeChoices;
    subject?: string;
    message?: string;
    enabled?: boolean;
  }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ReminderInputMutation>({
        variables: {
          input: {
            templateId: template.id || "",
            version,
            ...input,
          },
        },
        mutation: graphql`
          mutation ReminderInputMutation($input: UpdateRemindersTemplateInput!) {
            updateRemindersTemplate(input: $input) {
              remindersTemplate {
                id
                virtualMeeting {
                  subject
                  message
                }
                inPersonMeeting {
                  subject
                  message
                }
                daysBefore
                hoursBefore
                minsBefore
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const [form] = Form.useForm();

  // reset form values when toggling virtual/in-person
  useEffect(() => {
    form.resetFields();
  }, [form, version]);

  const checkValidity = (name: "subject" | "message") => {
    const allTouched = form.isFieldsTouched(["subject", "message"], true);
    if (allTouched) {
      form.validateFields();
    }
  };

  const CreateOptions: React.FC<{
    count: number;
    interval: number;
    value: number;
    id: string;
  }> = ({ count, interval, value, id }) => {
    const components = [];
    for (let i = 0; i <= count; i += interval) {
      components.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }
    return (
      <Select id={id} value={value} onChange={e => updateRemindersTemplate({ [id]: e })}>
        {components}
      </Select>
    );
  };

  return (
    <StyledForm>
      <Form
        form={form}
        initialValues={{
          subject:
            version === ScreenersReminderTemplateVersionMeetingTypeChoices.V
              ? template.virtualMeeting?.subject
              : template.inPersonMeeting?.subject,
          message:
            version === ScreenersReminderTemplateVersionMeetingTypeChoices.V
              ? template.virtualMeeting?.message
              : template.inPersonMeeting?.message,
          days: 1,
        }}
        {...formItemLayout}
      >
        <Form.Item label={"Delivery"} style={{ color: PRIMARY_TEXT_COLOR }}>
          <Form.Item style={formItemStyling}>
            <label>
              <CreateOptions count={14} interval={1} value={template.daysBefore} id="daysBefore" />
              Days
            </label>
          </Form.Item>
          <Form.Item style={formItemStyling}>
            <label>
              <CreateOptions count={23} interval={1} value={template.hoursBefore} id="hoursBefore" />
              Hours
            </label>
          </Form.Item>
          <Form.Item style={formItemStyling}>
            <label>
              <CreateOptions count={59} interval={5} value={template.minsBefore} id="minsBefore" />
              Minutes
            </label>
          </Form.Item>
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              height: "32px",
              marginLeft: "0.4rem",
            }}
          >
            before event
          </span>
        </Form.Item>
        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: "Please provide a subject",
            },
          ]}
        >
          <Input
            placeholder="Enter the subject of your message"
            onChange={() => checkValidity("subject")}
            onBlur={e => updateRemindersTemplate({ version, subject: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          rules={[
            {
              required: true,
              message: "Please provide a message",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Enter the message you want to send to your participants"
            rows={10}
            onChange={() => checkValidity("message")}
            onBlur={e => updateRemindersTemplate({ version, message: e.target.value })}
          />
        </Form.Item>
      </Form>
    </StyledForm>
  );
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

const formItemStyling = {
  display: "inline-block",
  width: "4rem",
  marginRight: "5px",
};

const StyledForm = styled.div`
  label {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

export default createFragmentContainer(ReminderInput, {
  template: graphql`
    fragment ReminderInput_template on RemindersTemplatesNode {
      id
      enabled
      virtualMeeting {
        subject
        message
      }
      inPersonMeeting {
        subject
        message
      }
      daysBefore
      hoursBefore
      minsBefore
    }
  `,
});
