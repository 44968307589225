import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { App, Button, Card, CardProps, Checkbox, Popover, Tabs } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { kebabCase } from "lodash-es";
import React, { useEffect, useState } from "react";
import { createPaginationContainer, RelayPaginationProp } from "react-relay";
import styled from "styled-components";

import { DEFAULT_PANELISTS_FETCH_COUNT, RECRUIT_STATUSES } from "../../constants";
import { PanelRecruitFilterEnum } from "../../schema";
import { DispatchState } from "../../types";
import { getRecruitContext, getStudyContext, getTenantContext, mutation, trackEvent } from "../../utils";
import { useComponentVisible } from "../../utils/hooks";
import { getApiBaseUrl } from "../../utils/misc";
import type { RecruitMatch_addAllStudyPanelRecruits_Mutation } from "../../__generated__/RecruitMatch_addAllStudyPanelRecruits_Mutation.graphql";
import type { RecruitMatch_addStudyPanelRecruits_Mutation } from "../../__generated__/RecruitMatch_addStudyPanelRecruits_Mutation.graphql";
import type { RecruitMatch_recruit$data } from "../../__generated__/RecruitMatch_recruit.graphql";
import type { RecruitMatch_removeAllStudyPanelRecruits_Mutation } from "../../__generated__/RecruitMatch_removeAllStudyPanelRecruits_Mutation.graphql";
import type { RecruitMatch_removeStudyPanelRecruits_Mutation } from "../../__generated__/RecruitMatch_removeStudyPanelRecruits_Mutation.graphql";
import type { RecruitMatch_study$data } from "../../__generated__/RecruitMatch_study.graphql";
import type { RecruitMatch_viewer$data } from "../../__generated__/RecruitMatch_viewer.graphql";
import { ExportButton } from "../ExportButton";
import { PanelistFilterGroupGroup } from "../Filters";
import PanelFilterGroupQuery from "../Panel/PanelFilterGroupQuery";
import { createPanelistFilterGroupGroup } from "../Panel/PanelFilters";
import SelectAllPopoverContent from "../tables/SelectAllPopoverContent";
import useTableSelect from "../tables/useTableSelect";
import PanelTable from "./PanelTable";

const RecruitMatch = ({
  recruit,
  recruitFilter,
  relay,
  setRecruitFilter,
  study,
  viewer,
}: {
  recruit: RecruitMatch_recruit$data;
  recruitFilter: PanelRecruitFilterEnum;
  relay: RelayPaginationProp;
  setRecruitFilter: DispatchState<typeof recruitFilter>;
  study: RecruitMatch_study$data;
  viewer: RecruitMatch_viewer$data;
}) => {
  const { notification } = App.useApp();

  const [appliedFilters, setAppliedFilters] = useState<PanelistFilterGroupGroup>(createPanelistFilterGroupGroup());
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [randomize, setRandomize] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    loading,
    setLoading,
    pagedData,
    page,
    selectAllChecked,
    selectedIds,
    filteredCount,
    selectedCount,
    deselectedIds,
    countToSelect,
    selectRows,
    setPage,
    changePage,
    toggleSelectAll,
    load,
  } = useTableSelect({
    relay,
    defaultFetchCount: DEFAULT_PANELISTS_FETCH_COUNT,
  });

  const switchRecruitView = (value: PanelRecruitFilterEnum) => {
    setRecruitFilter(value);
    toggleSelectAll(false);
    setLoading(true);
    relay.refetchConnection(
      DEFAULT_PANELISTS_FETCH_COUNT,
      err => {
        if (err) {
          return console.error(err);
        }
        setLoading(false);
        setPage(1);
        selectRows([]);
      },
      {
        panelFilters: value === PanelRecruitFilterEnum.MatchedPanel && appliedFilters,
        panelRecruitFilter: value,
      }
    );
  };

  useEffect(() => {
    load(recruit.table);
  }, [load, recruit.table]);

  useEffect(() => {
    selectAllChecked && randomize && addRecruit();
    // eslint-disable-next-line
  }, [randomize, selectAllChecked]);

  const refetch = (refetchVariables: {
    panelFilters: PanelistFilterGroupGroup;
    panelRecruitFilter: PanelRecruitFilterEnum | null;
    cursor?: string;
    recruitId: string;
    searchTerm?: string;
  }) => {
    setLoading(true);
    relay.refetchConnection(
      DEFAULT_PANELISTS_FETCH_COUNT,
      err => {
        if (err) {
          return console.error(err);
        }
        selectRows([]);
        setPage(1);
        setLoading(false);
      },
      {
        ...refetchVariables,
      }
    );
  };

  const applyFilters: Parameters<typeof PanelFilterGroupQuery>[0]["onApplyFilters"] = filters => {
    setAppliedFilters(filters);
    refetch({ panelFilters: filters, panelRecruitFilter: recruitFilter, recruitId: recruit.id, searchTerm });
  };

  const applySearch = (searchValue: string) => {
    setSearchTerm(searchValue);
    refetch({
      panelFilters: appliedFilters,
      panelRecruitFilter: recruitFilter === PanelRecruitFilterEnum.MatchedPanel ? recruitFilter : null,
      recruitId: recruit.id,
      searchTerm: searchValue,
    });
  };

  const addRecruit = () => {
    // Adds panelists to a recruit
    let addRecruitMutation;

    if (selectAllChecked) {
      addRecruitMutation = mutation<RecruitMatch_addAllStudyPanelRecruits_Mutation>({
        variables: {
          input: {
            recruitId: recruit.id,
            panelFilters: appliedFilters,
            excludeIds: deselectedIds,
            countToRecruit: countToSelect,
            searchTerm: searchTerm,
            randomize: randomize,
          },
        },
        mutation: graphql`
          mutation RecruitMatch_addAllStudyPanelRecruits_Mutation($input: AddAllStudyPanelRecruitsInput!) {
            addAllStudyPanelRecruits(input: $input) {
              study {
                id
              }
            }
          }
        `,
        silenceDefaultError: true,
      });
    } else {
      if (!selectedIds.length) {
        return;
      }
      addRecruitMutation = mutation<RecruitMatch_addStudyPanelRecruits_Mutation>({
        variables: { input: { recruitId: recruit.id, panelistIds: selectedIds as string[] } },
        mutation: graphql`
          mutation RecruitMatch_addStudyPanelRecruits_Mutation($input: AddStudyPanelRecruitsInput!) {
            addStudyPanelRecruits(input: $input) {
              study {
                id
              }
            }
          }
        `,
        silenceDefaultError: true,
      });
    }

    setLoading(true);
    addRecruitMutation
      .then(() => {
        toggleSelectAll(false);
        selectRows([]);
        setRandomize(false);
        refetch({ panelFilters: appliedFilters, panelRecruitFilter: recruitFilter, recruitId: recruit.id, searchTerm });
        notification.success({
          message: `${selectedCount} added to recruiting round.`,
          description: (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => switchRecruitView(PanelRecruitFilterEnum.RecruitedPanel)}
            >
              View updated list.
            </Button>
          ),
        });
      })
      .then(() => {
        trackEvent("Study Panel Recruits Added", {
          ...getRecruitContext(recruit),
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
          "Added Count": selectedCount,
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  const removeRecruit = () => {
    // Remove panelists from a recruit
    let removeRecruitMutation;

    if (selectAllChecked) {
      removeRecruitMutation = mutation<RecruitMatch_removeAllStudyPanelRecruits_Mutation>({
        variables: { input: { recruitId: recruit.id, excludeIds: deselectedIds } },
        mutation: graphql`
          mutation RecruitMatch_removeAllStudyPanelRecruits_Mutation($input: RemoveAllStudyPanelRecruitsInput!) {
            removeAllStudyPanelRecruits(input: $input) {
              study {
                id
              }
              errors {
                message
                data
              }
            }
          }
        `,
        silenceDefaultError: true,
      });
    } else {
      if (!selectedIds.length) {
        return;
      }
      removeRecruitMutation = mutation<RecruitMatch_removeStudyPanelRecruits_Mutation>({
        variables: { input: { recruitId: recruit.id, panelistIds: selectedIds as string[] } },
        mutation: graphql`
          mutation RecruitMatch_removeStudyPanelRecruits_Mutation($input: RemoveStudyPanelRecruitsInput!) {
            removeStudyPanelRecruits(input: $input) {
              study {
                id
              }
              errors {
                message
                data
              }
            }
          }
        `,
        silenceDefaultError: true,
      });
    }

    setLoading(true);
    removeRecruitMutation
      .then((res: any) => {
        toggleSelectAll(false);

        const errors = res.removeStudyPanelRecruits?.errors || res.removeAllStudyPanelRecruits?.errors;

        if (errors) {
          errors.forEach((o: any) => {
            if (o.message === "remove failed") {
              notification.error({
                message: "Some panelists were already recruited.",
                description: "We've reloaded the table with the latest data for you. Please try again.",
              });
            }
          });
          refetch({
            panelFilters: appliedFilters,
            panelRecruitFilter: recruitFilter,
            recruitId: recruit.id,
            searchTerm,
          });
        } else {
          selectRows([]);
          notification.success({ message: `${selectedCount} removed from recruiting round.` });
          refetch({
            panelFilters: appliedFilters,
            panelRecruitFilter: recruitFilter,
            recruitId: recruit.id,
            searchTerm,
          });
        }
      })
      .then(() => {
        trackEvent("Study Panel Recruits Removed", {
          ...getRecruitContext(recruit),
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
          "Removed Count": selectedCount,
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  const panelTable = (
    <PanelTable
      leftAlign
      loading={loading}
      data={pagedData}
      characteristics={[...(study.tenant.globalCharacteristics?.edges || []), ...study.tenant.characteristics.edges]}
      totalCount={recruit.table?.totalCount}
      page={page}
      onPageChange={changePage}
      rowSelection={
        recruit.status === RECRUIT_STATUSES.NOT_STARTED
          ? {
              columnTitle: (
                <Popover
                  content={
                    <SelectAllPopoverContent
                      allowRandomize={true}
                      totalCount={recruit?.table?.totalCount}
                      filteredCount={filteredCount}
                      selectAllOnClick={() => {
                        toggleSelectAll(true);
                        setIsComponentVisible(false);
                      }}
                      selectNumberOnClick={(numberToSelect: number | undefined, randomize: boolean) => {
                        toggleSelectAll(true, numberToSelect);
                        setRandomize(randomize);
                        setIsComponentVisible(false);
                      }}
                      componentRef={ref}
                    />
                  }
                  getPopupContainer={trigger => trigger.closest(".ant-table-wrapper")!}
                  placement="bottom"
                  title="How many panelists do you want to select?"
                  destroyTooltipOnHide={true}
                  open={isComponentVisible}
                  overlayStyle={{ width: "350px" }}
                >
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={e =>
                      e.target.checked && recruitFilter === PanelRecruitFilterEnum.MatchedPanel
                        ? setIsComponentVisible(true)
                        : toggleSelectAll(e.target.checked)
                    }
                  />
                </Popover>
              ),
              preserveSelectedRowKeys: true,
              selectedRowKeys: selectedIds,
              onChange: (selectedRowKeys: React.Key[]) => selectRows(selectedRowKeys as string[]),
            }
          : undefined
      }
      recruit={recruit}
    />
  );

  const cardProps: CardProps = {
    bodyStyle: {
      padding: "0 0 16px",
    },
    headStyle: {
      padding: "0 16px",
    },
  };

  return (
    <YourPanelBody>
      <Tabs
        activeKey={recruitFilter}
        items={[
          ...(recruit.status === RECRUIT_STATUSES.NOT_STARTED
            ? [
                {
                  children: (
                    <Card
                      {...cardProps}
                      title={
                        <div className="panel-settings-buttons">
                          <PanelFilterGroupQuery
                            disableExport={!(viewer.user?.isStaff || viewer.user?.profile?.tenant?.enablePanelExport)}
                            loading={loading}
                            onApplyFilters={applyFilters}
                            onApplySearch={applySearch}
                            panelistsCount={recruit.table?.totalCount || 0}
                            value={appliedFilters}
                          >
                            <Button
                              disabled={loading}
                              icon={<PlusCircleOutlined />}
                              type={selectedIds.length > 0 ? "primary" : "default"}
                              ghost={selectedIds.length > 0}
                              onClick={() => addRecruit()}
                            >
                              Add {selectedCount ? `(${selectedCount.toLocaleString()})` : " "} to Recruiting Round
                            </Button>
                          </PanelFilterGroupQuery>
                        </div>
                      }
                    >
                      {panelTable}
                    </Card>
                  ),
                  key: PanelRecruitFilterEnum.MatchedPanel,
                  label: `${
                    recruitFilter === PanelRecruitFilterEnum.MatchedPanel &&
                    !loading &&
                    filteredCount !== recruit.matched?.totalCount
                      ? `${filteredCount} / `
                      : ""
                  }${recruit.matched?.totalCount?.toLocaleString()} available in panel`,
                },
              ]
            : []),
          {
            children: (
              <Card
                {...cardProps}
                extra={
                  recruit.status === RECRUIT_STATUSES.NOT_STARTED && (
                    <div style={{ display: "inline-flex", gap: 12 }}>
                      <Button
                        disabled={loading}
                        icon={<MinusCircleOutlined />}
                        danger={selectedIds.length > 0}
                        ghost={selectedIds.length > 0}
                        onClick={() => removeRecruit()}
                      >
                        Remove {selectedCount ? `(${selectedCount.toLocaleString()})` : " "} from Recruiting Round
                      </Button>
                      <ExportButton
                        disabled={loading}
                        export={recruit.export}
                        filters={appliedFilters || {}}
                        immediate={(recruit.table?.totalCount || 0) < 80}
                        immediateFilename={`${kebabCase(study.name || "<Untitled>")}--${kebabCase(
                          recruit.name || "<Untitled>"
                        )}--panelists`}
                        immediateUrl={`${getApiBaseUrl()}/s/${study.dId}/panelistsRecruit/${recruit.dbId}`}
                        relatedId={recruit.id}
                      />
                    </div>
                  )
                }
              >
                {panelTable}
              </Card>
            ),
            key: PanelRecruitFilterEnum.RecruitedPanel,
            label:
              recruit.status === RECRUIT_STATUSES.NOT_STARTED
                ? `${recruit.recruited?.totalCount?.toLocaleString()} to be recruited`
                : `Recruiting ${recruit.recruited?.totalCount?.toLocaleString()}`,
          },
        ]}
        onChange={x => switchRecruitView(x as typeof recruitFilter)}
        size="large"
        tabBarStyle={{ fontWeight: 500 }}
        type="card"
      ></Tabs>
    </YourPanelBody>
  );
};

const YourPanelBody = styled.div`
  max-width: 100%;

  .panel-settings-buttons {
    display: flex;
    gap: 12px;
  }
`;

export default createPaginationContainer(
  RecruitMatch,
  {
    recruit: graphql`
      fragment RecruitMatch_recruit on RecruitNode
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        panelSettings: { type: "String" }
        panelRecruitFilter: { type: "PanelRecruitFilterEnum", defaultValue: MATCHED_PANEL }
        panelFilters: { type: "GenericScalar" }
        searchTerm: { type: "String" }
      ) {
        id
        dbId
        export {
          ...ExportButton_export
        }
        matched: eligiblePanelists(panelRecruitFilter: MATCHED_PANEL) {
          totalCount
        }
        name
        recruited: eligiblePanelists(panelRecruitFilter: RECRUITED_PANEL) {
          totalCount
        }
        status
        table: eligiblePanelists(
          first: $count
          after: $cursor
          panelSettings: $panelSettings
          panelRecruitFilter: $panelRecruitFilter
          panelFilters: $panelFilters
          searchTerm: $searchTerm
        )
          @connection(
            key: "RecruitMatch_table"
            filters: ["panelSettings", "panelFilters", "panelRecruitFilter", "searchTerm"]
          ) {
          totalCount
          edges {
            node {
              id
              dId
              completedStudiesCount
              person {
                firstName
                lastName
                email
                phoneNumber
                panelist {
                  id
                }
              }
              panelistMembership {
                latestCompleteScreenerName
                latestCompleteScreenerDate
                latestStudyName
                latestStudyDate
                latestRatedParticipant {
                  rating
                }
              }
              joined
              recruitedToStudy
              recruitStatus
              responses {
                edges {
                  node {
                    characteristic {
                      id
                    }
                    answer {
                      id
                    }
                    row {
                      id
                      text
                    }
                    textValue
                  }
                }
              }
            }
          }
        }
        type
      }
    `,
    study: graphql`
      fragment RecruitMatch_study on StudyNode {
        id
        dId
        name
        panelFilters
        status
        type
        tenant {
          enablePanelExport
          name
          characteristics {
            edges {
              node {
                id
                importKey
                shortName
                text
                type
                answers {
                  id
                  text
                }
                rows {
                  id
                  text
                }
              }
            }
          }
          globalCharacteristics {
            edges {
              node {
                id
                importKey
                shortName
                text
                type
                answers {
                  id
                  text
                }
                rows {
                  id
                  text
                }
              }
            }
          }
          dId
          vpmAccountId
          name
        }
        dId
        name
        type
        status
      }
    `,
    viewer: graphql`
      fragment RecruitMatch_viewer on Viewer {
        user {
          isStaff
          profile {
            tenant {
              enablePanelExport
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getConnectionFromProps(props: any) {
      return props?.recruit?.table;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        studyId: fragmentVariables.studyId,
        panelFilters: fragmentVariables.panelFilters,
        panelSettings: fragmentVariables.panelSettings,
        panelRecruitFilter: props.recruitFilter,
        recruitId: fragmentVariables.recruitId,
        searchTerm: fragmentVariables.searchTerm,
      };
    },
    query: graphql`
      query RecruitMatchQuery(
        $count: Int!
        $cursor: String
        $studyId: String!
        $panelSettings: String
        $panelRecruitFilter: PanelRecruitFilterEnum
        $panelFilters: GenericScalar
        $recruitId: String
        $searchTerm: String
      ) {
        viewer {
          recruit(recruitId: $recruitId) {
            id
            dbId
            export {
              ...ExportButton_export
            }
            matched: eligiblePanelists(panelRecruitFilter: MATCHED_PANEL) {
              totalCount
            }
            status
            recruited: eligiblePanelists(panelRecruitFilter: RECRUITED_PANEL) {
              totalCount
            }
            name
            table: eligiblePanelists(
              first: $count
              after: $cursor
              panelSettings: $panelSettings
              panelRecruitFilter: $panelRecruitFilter
              panelFilters: $panelFilters
              searchTerm: $searchTerm
            )
              @connection(
                key: "RecruitMatch_table"
                filters: ["panelSettings", "panelFilters", "panelRecruitFilter", "searchTerm"]
              ) {
              totalCount
              edges {
                node {
                  id
                  dId
                  completedStudiesCount
                  person {
                    firstName
                    lastName
                    email
                    phoneNumber
                    panelist {
                      id
                    }
                  }
                  panelistMembership {
                    latestCompleteScreenerName
                    latestCompleteScreenerDate
                    latestStudyName
                    latestStudyDate
                    latestRatedParticipant {
                      rating
                    }
                  }
                  joined
                  recruitedToStudy
                  recruitStatus
                  responses {
                    edges {
                      node {
                        characteristic {
                          id
                        }
                        answer {
                          id
                        }
                        row {
                          id
                          text
                        }
                        textValue
                      }
                    }
                  }
                }
              }
            }
            type
          }
          study(studyId: $studyId) {
            tenant {
              name
            }
          }
          ...RecruitMatch_viewer
        }
      }
    `,
  }
);
