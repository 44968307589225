/**
 * @generated SignedSource<<deb434806622727114ac8c7dc9d7c36e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type ResetScheduledSlotInput = {
  clientMutationId?: string | null;
  reschedule: boolean;
  respondentId: string;
  studyId: string;
};
export type ScheduledSlotCell_ResetSlot_Mutation$variables = {
  input: ResetScheduledSlotInput;
};
export type ScheduledSlotCell_ResetSlot_Mutation$data = {
  readonly resetScheduledSlot: {
    readonly respondent: {
      readonly id: string;
      readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
    } | null;
    readonly study: {
      readonly approvedTabCount: number | null;
      readonly needsReviewCount: number | null;
      readonly scheduledCount: number | null;
    } | null;
  } | null;
};
export type ScheduledSlotCell_ResetSlot_Mutation = {
  response: ScheduledSlotCell_ResetSlot_Mutation$data;
  variables: ScheduledSlotCell_ResetSlot_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RespondentNode",
  "kind": "LinkedField",
  "name": "respondent",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masterStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedTabCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsReviewCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduledSlotCell_ResetSlot_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResetScheduledSlotPayload",
        "kind": "LinkedField",
        "name": "resetScheduledSlot",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduledSlotCell_ResetSlot_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResetScheduledSlotPayload",
        "kind": "LinkedField",
        "name": "resetScheduledSlot",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ef0051337ffcffc7cc23750e3bb17c0",
    "id": null,
    "metadata": {},
    "name": "ScheduledSlotCell_ResetSlot_Mutation",
    "operationKind": "mutation",
    "text": "mutation ScheduledSlotCell_ResetSlot_Mutation(\n  $input: ResetScheduledSlotInput!\n) {\n  resetScheduledSlot(input: $input) {\n    respondent {\n      id\n      masterStatus\n    }\n    study {\n      approvedTabCount\n      scheduledCount\n      needsReviewCount\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c674ff02fd041f219b5dfadf69c4fbec";

export default node;
