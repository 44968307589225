/**
 * @generated SignedSource<<ab00d551ab8cbe4fc423af2bf31f78d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LaunchStudyInput = {
  clientMutationId?: string | null;
  studyId: string;
};
export type LaunchedPage_LaunchStudy_Mutation$variables = {
  input: LaunchStudyInput;
};
export type LaunchedPage_LaunchStudy_Mutation$data = {
  readonly launchStudy: {
    readonly study: {
      readonly name: string | null;
    } | null;
  } | null;
};
export type LaunchedPage_LaunchStudy_Mutation = {
  response: LaunchedPage_LaunchStudy_Mutation$data;
  variables: LaunchedPage_LaunchStudy_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LaunchedPage_LaunchStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LaunchStudyPayload",
        "kind": "LinkedField",
        "name": "launchStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LaunchedPage_LaunchStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LaunchStudyPayload",
        "kind": "LinkedField",
        "name": "launchStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffc8a9749191eb696fc4e00cfe7b6951",
    "id": null,
    "metadata": {},
    "name": "LaunchedPage_LaunchStudy_Mutation",
    "operationKind": "mutation",
    "text": "mutation LaunchedPage_LaunchStudy_Mutation(\n  $input: LaunchStudyInput!\n) {\n  launchStudy(input: $input) {\n    study {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f27f596e9fe875ef6dd37f0f34c8fcfa";

export default node;
