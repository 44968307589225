import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES } from "../../constants";
import { AddOption_element$data } from "../../__generated__/AddOption_element.graphql";

type Props = {
  element: AddOption_element$data;
  addAnswer: ({ other }: { other: boolean }) => Promise<void>;
};
const AddOption: React.FC<Props> = ({ element, addAnswer }) => (
  <StyledAddOption>
    <div>
      <span className="action screener-element-add-option" onClick={() => addAnswer({ other: false })}>
        <PlusOutlined /> Add an option
      </span>
      {element.type !== ELEMENT_TYPES.RANK &&
        element.answers.edges.filter((edge: any) => edge.node.other).length < 3 && (
          <>
            <span> or </span>
            <span className="action screener-element-add-other" onClick={() => addAnswer({ other: true })}>
              "Others"
            </span>{" "}
            <Tooltip
              title='"Others" options will always show at the bottom of the list, even if randomized'
              placement="bottom"
            >
              <QuestionCircleOutlined className="question-icon" />
            </Tooltip>
          </>
        )}
    </div>
  </StyledAddOption>
);

const StyledAddOption = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  .ant-radio-wrapper {
    margin: 0;
  }

  .action {
    color: var(--ant-primary-color);
    cursor: pointer;
  }

  .question-icon {
    cursor: default;
  }
`;

export default createFragmentContainer(AddOption, {
  element: graphql`
    fragment AddOption_element on ElementNode {
      id
      type
      answers {
        edges {
          node {
            id
            text
            terminate
            quota
            other
            position
          }
        }
      }
    }
  `,
});
