import { App } from "antd";
import { useRouter } from "found";
import { useEffect } from "react";
import { clearJwt } from "../utils";

export const LogoutPage = () => {
  const { router } = useRouter();
  const { notification } = App.useApp();

  useEffect(() => {
    notification.destroy();
    clearJwt();
    const logoutUrl = localStorage.getItem("logout_url");
    if (logoutUrl) {
      localStorage.removeItem("logout_url");
      window.location.href = logoutUrl;
    } else {
      router.push("/login");
    }
  }, [router, notification]);

  return <div />;
};
