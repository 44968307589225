/**
 * @generated SignedSource<<de34e9a771c56a8c3ad6f860182fe67b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExportFieldReportButton_study$data = {
  readonly dId: any | null;
  readonly name: string | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly dId: any | null;
    readonly name: string;
    readonly vpmAccountId: number | null;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentType": "ExportFieldReportButton_study";
};
export type ExportFieldReportButton_study$key = {
  readonly " $data"?: ExportFieldReportButton_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExportFieldReportButton_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExportFieldReportButton_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmAccountId",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "dce580f2fc7ca5cab2ab99ac3335c7cc";

export default node;
