import { Button, Checkbox } from "antd";
import styled from "styled-components";

type Props = Parameters<typeof Checkbox>[0];

const _CheckboxButton = ({
  size,
  buttonStyle,
  children,
  ...props
}: Omit<Props, "children"> & {
  children: Exclude<Props["children"], boolean | null | undefined>;
  size?: Parameters<typeof Button>[0]["size"];
  buttonStyle?: Parameters<typeof Button>[0]["style"];
}) => (
  <Checkbox {...props}>
    <Button size={size} style={buttonStyle} type={props.checked ? "primary" : "default"}>
      {children}
    </Button>
  </Checkbox>
);

export const CheckboxButton = styled(_CheckboxButton)`
  .ant-checkbox {
    display: none;

    + span {
      padding: 0;
    }
  }

  button {
    pointer-events: none;
  }

  :not(.ant-checkbox-wrapper-checked):hover button {
    color: var(--ant-primary-color);
  }

  &.ant-checkbox-wrapper-checked:hover button {
    background: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);
  }
`;
