/**
 * @generated SignedSource<<cdec22854a27df24f566facdc61a83a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_CreateProject_Query$variables = {};
export type RouteConfig_CreateProject_Query$data = {
  readonly viewer: {
    readonly languages: ReadonlyArray<{
      readonly code: string;
      readonly name: string;
    } | null>;
    readonly privacyPolicyOptions: ReadonlyArray<{
      readonly language: string;
      readonly languageCode: string;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"CreateProject_viewer">;
  };
};
export type RouteConfig_CreateProject_Query = {
  response: RouteConfig_CreateProject_Query$data;
  variables: RouteConfig_CreateProject_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "LanguageNode",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_CreateProject_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PrivacyPolicyNode",
            "kind": "LinkedField",
            "name": "privacyPolicyOptions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateProject_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfig_CreateProject_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PrivacyPolicyNode",
            "kind": "LinkedField",
            "name": "privacyPolicyOptions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultSurveysToUseApi",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allowExternalIncentive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "externalIncentivePerParticipantCostUsdCents",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vpmAccountId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "voxpopmeLiveDefaultProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "voxpopmeApiKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagNode",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vpmUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "studyCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d65d71b63c621b0d6a7f04ff5f55b058",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_CreateProject_Query",
    "operationKind": "query",
    "text": "query RouteConfig_CreateProject_Query {\n  viewer {\n    languages {\n      name\n      code\n    }\n    privacyPolicyOptions {\n      languageCode\n      language\n      id\n    }\n    ...CreateProject_viewer\n  }\n}\n\nfragment CreateProject_viewer on Viewer {\n  user {\n    profile {\n      tenant {\n        ...ProjectDetailsPage_tenant\n        id\n      }\n      id\n    }\n    id\n  }\n  ...ProjectDetailsPage_viewer\n}\n\nfragment InputTags_tenant on TenantNode {\n  tags {\n    id\n    color\n    name\n  }\n}\n\nfragment ProjectDetailsPage_tenant on TenantNode {\n  defaultSurveysToUseApi\n  allowExternalIncentive\n  externalIncentivePerParticipantCostUsdCents\n  vpmAccountId\n  voxpopmeLiveDefaultProjectId\n  voxpopmeApiKey\n  ...InputTags_tenant\n}\n\nfragment ProjectDetailsPage_viewer on Viewer {\n  studyCount\n  user {\n    dId\n    vpmUserId\n    panelist {\n      dId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "035bcfe7e8d630519162901f7861dcab";

export default node;
