/**
 * @generated SignedSource<<0049cc5b9119330e27d457836b7cef15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitForm_recruit$data = {
  readonly goal: number | null;
  readonly id: string;
  readonly incentive: string | null;
  readonly incentivePoints: number | null;
  readonly name: string | null;
  readonly remindersEnabled: boolean;
  readonly status: ScreenersRecruitStatusChoices;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentType": "RecruitForm_recruit";
};
export type RecruitForm_recruit$key = {
  readonly " $data"?: RecruitForm_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitForm_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitForm_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remindersEnabled",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "fe769533332d6983e3a8112da5ff1ced";

export default node;
