import Spin from "antd/lib/spin";
import React from "react";
import styled from "styled-components";

type Props = {
  positionTopThird?: boolean;
};

const LoadingPage: React.FC<Props> = ({ positionTopThird }) => (
  <StyledLoading positionTopThird={positionTopThird}>
    <Spin size="large" />
  </StyledLoading>
);

const StyledLoading = styled.div<{ positionTopThird?: boolean | undefined }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin {
    ${({ positionTopThird }) =>
      positionTopThird &&
      `
      position: absolute;
      top: 33%;
    `}
  }
`;

export default LoadingPage;
