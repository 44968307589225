import { App, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { uniq } from "lodash-es";
import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_7 } from "../../style";
import { mutation } from "../../utils";
import { validateEmail } from "../../utils/misc";

type Props = {
  studyId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};
export const ShareTestScreenerModal: React.FC<Props> = ({ studyId, visible, setVisible }: Props) => {
  const { message } = App.useApp();

  const [emails, setEmails] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);

  const handleSend = async () => {
    setIsSending(true);
    try {
      await sendEmails();
      message.success("Email(s) sent successfully");
      setVisible(false);
      setEmails([]);
    } catch {
      message.error("Error sending email(s). Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  const sendEmails = async () => {
    await mutation({
      variables: { input: { studyId, emails } },
      mutation: graphql`
        mutation ShareTestScreenerModal_sendTestParticipantInvites_Mutation($input: SendTestParticipantInvitesInput!) {
          sendTestParticipantInvites(input: $input) {
            response {
              sent
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  };

  return (
    <StyledModal
      title="Invite by email to test your project"
      closable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={handleSend}
      cancelButtonProps={{ disabled: isSending }}
      okButtonProps={{ disabled: !emails.length, loading: isSending }}
      okText="Send invitations"
    >
      <p>Send to:</p>
      <Select
        className="emails-input"
        mode="tags"
        onChange={value =>
          setEmails(
            // prevent duplicates
            uniq(
              value
                // eliminate surrounding whitespace and convert to lowercase
                .map(email => email.trim().toLowerCase())
                // remove invalid addresses
                .filter(validateEmail)
            )
          )
        }
        maxTagCount="responsive"
        placeholder="example: example@hubux.com"
        autoFocus
        value={emails}
        disabled={isSending}
      />
      <p className="help-text">
        These users who complete the screener will appear in the Participants tab as "Test Participant" and will count
        as normal participant for all actions. You won't be charged for test participants, as they won't receive any
        incentive.
      </p>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .emails-input {
    width: 100%;
  }

  .help-text {
    margin-top: 4px;
    margin-bottom: 14px;
    font-size: 12px;
    color: ${GRAY_7};
  }
`;
