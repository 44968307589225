import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Radio } from "antd";
import { Link, useRouter } from "found";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { ThemeColor } from "../antd";
import { BORDER_RADIUS, HEADER_PRIMARY_TEXT_COLOR } from "../style";
import { Logo } from "./Logo";

const env = runtimeEnv();

type Props = {
  title?: string;
  customLogoUrl?: string | null;
  customColor?: string | null;
  customFooterUrl?: string | null;
  contactEmail?: string | null;
};
const LoggedOutPage: React.FC<Props> = ({
  children,
  title,
  customLogoUrl,
  customColor,
  customFooterUrl,
  contactEmail,
}) => {
  const { match } = useRouter();
  const isWhitelabeled = !!customLogoUrl;
  const isHubuxPortal = env.REACT_APP_HUBUX_PORTAL_URL
    ? window.location.origin.includes(env.REACT_APP_HUBUX_PORTAL_URL)
    : false;
  const [loginType, setLoginType] = useState(isWhitelabeled || isHubuxPortal ? "Panelist" : "Business");
  const registerURL = loginType === "Business" ? "/app-register" : "/register";

  return (
    <Wrapper $customColor={customColor}>
      <div className="content">
        <div className="logo-container">
          <Logo width="194" height="56" customLogoUrl={customLogoUrl} negate />
        </div>
        {match.location.pathname.includes("/login") && !isWhitelabeled && (
          <div className="login-picker">
            <Radio.Group
              options={[
                { label: "Business", value: "Business" },
                { label: "Panelist", value: "Panelist" },
              ]}
              onChange={e => setLoginType(e.target.value)}
              value={loginType}
              optionType="button"
              buttonStyle="solid"
              size="large"
            />
          </div>
        )}
        <div className="login-form">
          {title && <p style={{ fontWeight: 500 }}>{title}</p>}
          {children}
        </div>
        <div className="footer-links">
          {!match.location.pathname.includes("/login") && (
            <div className="login-link">
              <Trans i18nKey="logged-out-page.login-cta">
                Have an account? <Link to="/login">Log in</Link> ·{" "}
                <a href={`mailto:${contactEmail || env.REACT_APP_CONTACT_EMAIL || "hello@hubux.com"}`}>Contact us</a>
              </Trans>
            </div>
          )}
          {match.location.pathname.includes("/register") && !isWhitelabeled && (
            <div className="login-link">
              Looking to run your own research studies?{" "}
              <a href="https://hubux.com" target="_blank" rel="noreferrer">
                https://hubux.com
              </a>
            </div>
          )}
          {match.location.pathname.includes("/login") && (
            <div className="register-link">
              <Trans i18nKey="logged-out-page.register-cta">
                Need an account? <Link to={registerURL}>Register here</Link> ·{" "}
                <a href={`mailto:${contactEmail || env.REACT_APP_CONTACT_EMAIL || "hello@hubux.com"}`}>Contact us</a>
              </Trans>
            </div>
          )}

          {customFooterUrl && (
            <div className="logo-container">
              <img className="footer-image" src={customFooterUrl} alt="" />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $customColor?: string | null }>`
  background-color: ${props => props.$customColor || ThemeColor.VoxGrape};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 5px;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    padding: 50px 0px;
    .logo-container {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      height: auto;
      width: 100%;
      max-width: 90vw;
    }
    .login-form {
      padding: 24px;
      background-color: white;
      border-radius: ${BORDER_RADIUS};
      width: 100%;
      .login-form-button {
        width: 100%;
      }
      .general-error {
        color: var(--ant-error-color);
      }
      .form-item-margin-small {
        margin-bottom: 12px;
      }
    }
    .login-link,
    .register-link {
      color: ${HEADER_PRIMARY_TEXT_COLOR};
      text-align: center;
      padding-top: 1rem;
    }
    .footer-image {
      margin-top: 25px;
      align-self: flex-start;
      width: 100%;
    }
  }
  .login-picker {
    margin-bottom: 32px;

    .ant-radio-button-wrapper:focus-within {
      box-shadow: unset !important;
    }
  }

  .footer-links {
    a {
      color: var(--ant-primary-color-hover);
    }
    a:hover {
      color: var(--ant-primary-4);
    }
  }
`;

export default LoggedOutPage;
