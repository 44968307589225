/**
 * @generated SignedSource<<5cad62ee0d16ed33e0ea9472128047f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersFilterTypeChoices = "PN" | "PT" | "%future added value";
export type SaveFilterInput = {
  clientMutationId?: string | null;
  filters: any;
  name: string;
  studyId?: string | null;
  type: string;
  userId?: string | null;
};
export type DrawerFilters_SaveFilter_Mutation$variables = {
  input: SaveFilterInput;
};
export type DrawerFilters_SaveFilter_Mutation$data = {
  readonly saveFilter: {
    readonly filter: {
      readonly filters: any;
      readonly id: string;
      readonly name: string;
      readonly study: {
        readonly id: string;
      } | null;
      readonly type: ScreenersFilterTypeChoices;
      readonly user: {
        readonly id: string;
      } | null;
    };
    readonly study: {
      readonly filters: ReadonlyArray<{
        readonly filters: any;
        readonly id: string;
        readonly name: string;
        readonly study: {
          readonly id: string;
        } | null;
        readonly type: ScreenersFilterTypeChoices;
        readonly user: {
          readonly id: string;
        } | null;
      }>;
      readonly id: string;
    } | null;
    readonly tenant: {
      readonly filters: ReadonlyArray<{
        readonly filters: any;
        readonly id: string;
        readonly name: string;
        readonly study: {
          readonly id: string;
        } | null;
        readonly type: ScreenersFilterTypeChoices;
        readonly user: {
          readonly id: string;
        } | null;
      }>;
      readonly id: string;
    };
  } | null;
};
export type DrawerFilters_SaveFilter_Mutation = {
  response: DrawerFilters_SaveFilter_Mutation$data;
  variables: DrawerFilters_SaveFilter_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "filters",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "StudyNode",
    "kind": "LinkedField",
    "name": "study",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
],
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "FilterNode",
    "kind": "LinkedField",
    "name": "filters",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveFilterPayload",
    "kind": "LinkedField",
    "name": "saveFilter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FilterNode",
        "kind": "LinkedField",
        "name": "filter",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantNode",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StudyNode",
        "kind": "LinkedField",
        "name": "study",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DrawerFilters_SaveFilter_Mutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DrawerFilters_SaveFilter_Mutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "d0c74bf8ecf2973456b644db81aef604",
    "id": null,
    "metadata": {},
    "name": "DrawerFilters_SaveFilter_Mutation",
    "operationKind": "mutation",
    "text": "mutation DrawerFilters_SaveFilter_Mutation(\n  $input: SaveFilterInput!\n) {\n  saveFilter(input: $input) {\n    filter {\n      id\n      filters\n      name\n      study {\n        id\n      }\n      type\n      user {\n        id\n      }\n    }\n    tenant {\n      id\n      filters {\n        id\n        filters\n        name\n        study {\n          id\n        }\n        type\n        user {\n          id\n        }\n      }\n    }\n    study {\n      id\n      filters {\n        id\n        filters\n        name\n        study {\n          id\n        }\n        type\n        user {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ce76d672c24cafc7b644760b9f36df3";

export default node;
