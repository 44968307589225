import { Icon } from "@iconify/react";
import { isEqualSimple } from "@react-hookz/deep-equal";
import { useWindowSize } from "@react-hookz/web";
import { Collapse, Radio, Space } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useFragment } from "react-relay";
import styled from "styled-components";

import { BORDER_COLOR, GRAY_2 } from "../../../style";
import type { InputCharacteristicResponseGridSingleSelect_characteristic$key } from "../../../__generated__/InputCharacteristicResponseGridSingleSelect_characteristic.graphql";
import type { InputCharacteristicResponseGridSingleSelect_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponseGridSingleSelect_characteristicResponses.graphql";

export const InputCharacteristicResponseGridSingleSelect = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  className,
  onChange,
  value: _value,
}: {
  characteristic: InputCharacteristicResponseGridSingleSelect_characteristic$key;
  characteristicResponses: InputCharacteristicResponseGridSingleSelect_characteristicResponses$key;
  className?: Argument;
  onChange?: (value: Record<string, string>) => void;
  value?: Record<string, string>;
}) => {
  const characteristic = useFragment(
    graphql`
      fragment InputCharacteristicResponseGridSingleSelect_characteristic on CharacteristicNode {
        id
        answers {
          id
          text
        }
        rows {
          id
          text
        }
      }
    `,
    characteristicKey
  );
  const responses = useFragment(
    graphql`
      fragment InputCharacteristicResponseGridSingleSelect_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        id
        answer {
          id
        }
        row {
          id
        }
      }
    `,
    characteristicResponsesKey
  );

  const defaultValue = Object.fromEntries(
    responses.map(x => [x.row?.id, x.answer?.id] as const).filter((x): x is [string, string] => !!x[1] && !!x[0])
  );

  const [value, _setValue] = useState(_value ?? defaultValue);
  const setValue = (rowId: string, e: Parameters<Parameters<(typeof Radio)["Group"]>[0]["onChange"] & {}>[0]) =>
    _setValue({ ...(value ?? {}), [rowId]: e.target.value });

  useEffect(() => {
    if (!isEqualSimple(value, _value) && Object.keys(value).length === characteristic.rows.length) onChange?.(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(_value), characteristic.rows.length, onChange, value]);

  const { width } = useWindowSize();
  const [activeCollapseKey, setActiveCollapseKey] = useState(characteristic.rows[0]?.id);

  if (!characteristic.rows[0]) return null;

  return width >= 800 && characteristic.answers.length <= 5 ? (
    <StyledGrid answersCount={characteristic.answers.length} className={classNames(className)}>
      <div className="hub-grid-header" />
      {characteristic.answers.map(x => (
        <ReactMarkdown className="hub-grid-header hub-grid-column-header hub-md-inline" key={x.id} linkTarget="blank">
          {x.text}
        </ReactMarkdown>
      ))}
      {characteristic.rows.map(row => (
        <>
          <ReactMarkdown className="hub-grid-header hub-grid-row-header hub-md-inline" key={row.id} linkTarget="blank">
            {row.text}
          </ReactMarkdown>
          {characteristic.answers.map(x => (
            <Radio.Group
              defaultValue={defaultValue[row.id] === x.id ? x.id : undefined}
              key={`${row.id}::${x.id}`}
              onChange={e => setValue(row.id, e)}
              value={value?.[row.id] === x.id ? x.id : undefined}
            >
              <Radio value={x.id} className="radio-btn" />
            </Radio.Group>
          ))}
        </>
      ))}
    </StyledGrid>
  ) : (
    <StyledCollapse
      accordion
      activeKey={activeCollapseKey}
      bordered={false}
      className={classNames(className)}
      defaultActiveKey={characteristic.rows[0].id}
      expandIconPosition="end"
      onChange={x => setActiveCollapseKey(Array.isArray(x) ? x[0] : x)}
    >
      {characteristic.rows.map(row => (
        <Collapse.Panel
          header={row.text}
          key={row.id}
          extra={value?.[row.id] ? <Icon icon="mdi:check-circle-outline" height="1.2em" /> : ""}
          showArrow={!value?.[row.id]}
        >
          <Radio.Group defaultValue={defaultValue[row.id]} key={row.id} onChange={e => setValue(row.id, e)}>
            <Space direction="vertical">
              {characteristic.answers.map(x => (
                <Radio key={`${row.id}::${x.id}`} value={x.id}>
                  <ReactMarkdown className="hub-md-inline" linkTarget="blank">
                    {x.text}
                  </ReactMarkdown>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Collapse.Panel>
      ))}
    </StyledCollapse>
  );
};

const StyledCollapse = styled(Collapse)`
  .ant-collapse-extra {
    color: var(--ant-success-color);
    margin-right: -26px;
    transform: translateY(4px);
  }
  .ant-radio-wrapper span:not(:first-child) {
    padding-left: 8px;
  }
`;

const StyledGrid = styled.div<{ answersCount: number }>`
  --hub-grid-border-width: 1px;
  --hub-grid-border: var(--hub-grid-border-width) solid ${BORDER_COLOR};

  display: grid;
  grid-template-columns: 2fr repeat(${({ answersCount: x }) => x}, 1fr);
  place-items: center;
  border: var(--hub-grid-border);
  border-radius: 6px;

  > * {
    width: 100%;
    padding: 8px;
  }

  .ant-radio-wrapper.radio-btn {
    margin-right: 0;
  }

  > *:not(.hub-grid-row-header) {
    text-align: center;
  }

  .hub-grid-header {
    background-color: ${GRAY_2};
    height: 100%;
    display: flex;
    align-items: center;
  }
  .hub-grid-column-header {
    border-left: var(--hub-grid-border);
    justify-content: center;
  }
  .hub-grid-row-header {
    border-top: var(--hub-grid-border);
  }
  .ant-radio-group {
    border: var(--hub-grid-border);
    border-width: var(--hub-grid-border-width) 0 0 var(--hub-grid-border-width);
  }
`;
