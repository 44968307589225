import { useUpdateEffect } from "@react-hookz/web";
import { useState } from "react";
import type { RequireAtLeastOne, SetRequired } from "type-fest";

import { useFlag } from "../utils";

/**
 * Show an image from `public/<hub|vox>`.
 *
 * If an svg is used, will attempt to fall back to `<filename>.png` on error.
 */
export const ImgThemed = ({
  src: _src,
  ...props
}: SetRequired<JSX.IntrinsicElements["img"], "src"> &
  RequireAtLeastOne<JSX.IntrinsicElements["img"], "alt" | "aria-hidden">) => {
  const isVoxpopme = useFlag("platform-hubux-template");

  const createSrc = (error = false) => {
    const [extension, ...filenameParts] = _src.split(".").reverse();

    const src =
      /svg/i.test(extension!) && error ? `${filenameParts.join(".")}.${extension!.replace(/svg/i, "png")}` : _src;

    return `${isVoxpopme ? "vox" : "hub"}/${src}`;
  };
  const [src, setSrc] = useState(createSrc);
  useUpdateEffect(() => setSrc(createSrc()), [isVoxpopme]);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} onError={() => createSrc(true)} src={src} />;
};
