import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { SetNonNullable } from "type-fest";
import { YourProfile_user$data } from "../../../__generated__/YourProfile_user.graphql";
import { TextCell } from "../PortalCells";
import PortalTable from "../PortalTable";

const profileSerializer = (panelist: YourProfile_user$data["panelist"]) =>
  panelist?.globalCharacteristics
    ?.filter(
      (x): x is SetNonNullable<NonNullable<typeof x>, keyof NonNullable<typeof x>> => !!x?.key && x.response != null
    )
    .map(x => ({
      name: (x.key.charAt(0).toUpperCase() + x.key.slice(1)).replaceAll("_", " "),
      value: x.response,
    }));

type Props = {
  user: YourProfile_user$data;
};
const YourProfile: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        id: "profile-name",
        accessor: (row: any) => {
          return { text: row.name };
        },
        Cell: TextCell,
      },
      {
        id: "profile-value",
        accessor: (row: any) => {
          return { text: row.value, width: "200px" };
        },
        Cell: TextCell,
      },
    ],
    []
  );

  return (
    <Styled>
      <PortalTable
        className="hub-your-profile-table"
        columns={columns}
        data={React.useMemo(() => (user?.panelist ? profileSerializer(user.panelist) : []), [user])}
        blankText={t("portal.cards.your-profile.no-profile-data", "No profile data yet")}
        hideHeader={true}
      />
      <Link to="/portal/profile">
        {({ href, onClick }) => (
          <Button href={href} onClick={onClick} role="link" style={{ margin: "4px 0" }} type="link">
            Edit profile
          </Button>
        )}
      </Link>
    </Styled>
  );
};

const Styled = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .hub-your-profile-table {
    flex-grow: 1;
    flex-shrink: 0;
  }

  & > a {
    align-self: start;
  }
`;

export default createFragmentContainer(YourProfile, {
  user: graphql`
    fragment YourProfile_user on UserNode {
      panelist {
        globalCharacteristics {
          key
          response
        }
      }
    }
  `,
});
