import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const appId = env.REACT_APP_INTERCOM_APP_ID;

const intercom = <T extends Record<string, U>, U extends any>(
  action: string,
  paramsOrEventName?: { [key: string]: any } | string,
  metadata?: T
) => {
  // The provided Intercom setup script registers it to the window
  (window as any).Intercom?.(action, paramsOrEventName, metadata);
};

function boot(additionalParams?: { [key: string]: any }) {
  intercom("boot", { ...additionalParams, app_id: appId, custom_launcher_selector: ".intercom-launcher" });
}

export function init() {
  require("../__vendor__/intercom.js");
}

export function identifyUser(email: string, name: string | null, userId: string) {
  boot({ email, name, user_id: userId });
}

export function update() {
  intercom("update");
}

export function shutdown() {
  intercom("shutdown");
}

export const trackEvent = <T extends Record<string, U>, U extends any>(eventName: string, metadata?: T) => {
  intercom("trackEvent", eventName, metadata);
};
