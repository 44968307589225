/**
 * @generated SignedSource<<f195b820308d5f72a62ef4af01d13d13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransactionType = "DP" | "PO" | "RF" | "%future added value";
export type FundRecruitInput = {
  amountUsdCents: number;
  clientMutationId?: string | null;
  recruitId: string;
};
export type ParticipantPaymentModal_FundRecruit_Mutation$variables = {
  input: FundRecruitInput;
};
export type ParticipantPaymentModal_FundRecruit_Mutation$data = {
  readonly fundRecruit: {
    readonly recruitTransaction: {
      readonly id: string;
      readonly recruit: {
        readonly id: string;
      };
      readonly transactionType: TransactionType;
      readonly value: any;
    } | null;
  } | null;
};
export type ParticipantPaymentModal_FundRecruit_Mutation = {
  response: ParticipantPaymentModal_FundRecruit_Mutation$data;
  variables: ParticipantPaymentModal_FundRecruit_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FundRecruitPayload",
    "kind": "LinkedField",
    "name": "fundRecruit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RecruitTransactionNode",
        "kind": "LinkedField",
        "name": "recruitTransaction",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transactionType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RecruitNode",
            "kind": "LinkedField",
            "name": "recruit",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ParticipantPaymentModal_FundRecruit_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ParticipantPaymentModal_FundRecruit_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "29b94c127fea2da189dd8a7fd69f80cd",
    "id": null,
    "metadata": {},
    "name": "ParticipantPaymentModal_FundRecruit_Mutation",
    "operationKind": "mutation",
    "text": "mutation ParticipantPaymentModal_FundRecruit_Mutation(\n  $input: FundRecruitInput!\n) {\n  fundRecruit(input: $input) {\n    recruitTransaction {\n      id\n      transactionType\n      value\n      recruit {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07bb05299d138fc9a3d92d5da4ac132d";

export default node;
