/**
 * @generated SignedSource<<fd6a39e7913d35e3c9d4a3fd7edfb3cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElementBase_element$data = {
  readonly characteristic: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly position: number;
  readonly screener: {
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActiveElementContent_element" | "InactiveElementContent_element">;
  readonly " $fragmentType": "ElementBase_element";
};
export type ElementBase_element$key = {
  readonly " $data"?: ElementBase_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementBase_element",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNode",
      "kind": "LinkedField",
      "name": "characteristic",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScreenerNode",
      "kind": "LinkedField",
      "name": "screener",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActiveElementContent_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InactiveElementContent_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "38afe8193e7566b81e06a774766a6421";

export default node;
