import { Table, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import { ResponseSummaryTable_responseSummary$data } from "../../../__generated__/ResponseSummaryTable_responseSummary.graphql";

type Props = {
  responseSummary: ResponseSummaryTable_responseSummary$data;
};
const ResponseSummaryTable: React.FC<Props> = ({ responseSummary }) => {
  const qualified = responseSummary?.totalStarts?.qualified || 0;
  const totalStarts = responseSummary?.totalStarts?.total || 0;
  const incidentRate = qualified === 0 && totalStarts === 0 ? 0 : Math.ceil((qualified / totalStarts) * 100);

  const columns = [
    {
      title: `Response Summary (IR ${incidentRate} %)`,
      dataIndex: ["name"],
      render: (v: any, o: any) => {
        return (
          <Tooltip title={o.help}>
            <span>{v}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Total",
      dataIndex: ["total"],
      width: 80,
    },
    {
      title: "Qualified",
      dataIndex: ["qualified"],
      width: 80,
    },
  ];

  const dataSource = [
    {
      key: "1",
      name: "Total Needed",
      total: responseSummary.totalNeeded?.total,
      qualified: responseSummary.totalNeeded?.qualified,
      help: "Total number of qualified participants for this study.",
    },
    {
      key: "3",
      name: "Total Start Survey",
      total: responseSummary.totalStarts?.total,
      qualified: responseSummary.totalStarts?.qualified,
      help: "Times your survey's first page is loaded.",
    },
    {
      key: "4",
      name: "Survey Termed",
      total: responseSummary.screenerTermed?.total,
      qualified: responseSummary.screenerTermed?.qualified,
      help: "Terminated because of a term point or over quota.",
    },
    {
      key: "5",
      name: "Survey Dropout",
      total: responseSummary.screenerDropouts?.total,
      qualified: responseSummary.screenerDropouts?.qualified,
      help: "Measures how hard the survey is to take (Survey Starts minus Termed minus Qualified).",
    },
    {
      key: "6",
      name: "Survey Qualified",
      total: responseSummary.screenerQualified?.total,
      qualified: responseSummary.screenerQualified?.qualified,
      help: "Participants who made it through the screening process & are willing to participate.",
    },
    {
      key: "7",
      name: "RSVP Accepted",
      total: responseSummary.rsvpAccepted?.total,
      qualified: responseSummary.rsvpAccepted?.qualified,
      help: "Have accepted the calendar invitation.",
    },
    {
      key: "8",
      name: "RSVP Declined",
      total: responseSummary.rsvpDeclined?.total,
      qualified: responseSummary.rsvpDeclined?.qualified,
      help: "Declined the calendar invitation.",
    },
    {
      key: "9",
      name: "RSVP Maybe",
      total: responseSummary.rsvpMaybe?.total,
      qualified: responseSummary.rsvpMaybe?.qualified,
      help: "Definitely might be there.",
    },
  ];
  return (
    <Table
      pagination={false}
      // @ts-ignore
      columns={columns}
      // @ts-ignore
      dataSource={dataSource}
      size="small"
      style={{ marginBottom: -1 }}
    />
  );
};

export default createFragmentContainer(ResponseSummaryTable, {
  responseSummary: graphql`
    fragment ResponseSummaryTable_responseSummary on ResponseSummaryNode {
      totalStarts {
        total
        qualified
      }
      screenerDropouts {
        total
        qualified
      }
      screenerTermed {
        total
        qualified
      }
      screenerQualified {
        total
        qualified
      }
      totalNeeded {
        total
        qualified
      }
      rsvpAccepted {
        total
        qualified
      }
      rsvpDeclined {
        total
        qualified
      }
      rsvpMaybe {
        total
        qualified
      }
    }
  `,
});
