/**
 * @generated SignedSource<<5417ba1ac65b9943c9b881bb25cd7036>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePersonInput = {
  avatarUrl?: string | null;
  clientMutationId?: string | null;
  country?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phoneNumber?: string | null;
};
export type FormPerson_UpdatePerson_Mutation$variables = {
  input: UpdatePersonInput;
};
export type FormPerson_UpdatePerson_Mutation$data = {
  readonly updatePerson: {
    readonly person: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"FormPerson_person">;
    };
  };
};
export type FormPerson_UpdatePerson_Mutation = {
  response: FormPerson_UpdatePerson_Mutation$data;
  variables: FormPerson_UpdatePerson_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormPerson_UpdatePerson_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonNode",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FormPerson_person"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormPerson_UpdatePerson_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonNode",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requirePhoneNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ebb0a9ad5e4284ab57e88fa0bc0802d",
    "id": null,
    "metadata": {},
    "name": "FormPerson_UpdatePerson_Mutation",
    "operationKind": "mutation",
    "text": "mutation FormPerson_UpdatePerson_Mutation(\n  $input: UpdatePersonInput!\n) {\n  updatePerson(input: $input) {\n    person {\n      id\n      ...FormPerson_person\n    }\n  }\n}\n\nfragment FormPerson_person on PersonNode {\n  id\n  email\n  firstName\n  lastName\n  phoneNumber\n  requirePhoneNumber\n}\n"
  }
};
})();

(node as any).hash = "cd12ad9c6379c16d535a5c12466123b4";

export default node;
