import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { initReactI18next } from "react-i18next";

// This list must manually be kept in sync with locales in i18next-parser.config.js
const locales = ["en", "es", "fr"];

const debugOptions: { [name: string]: any } = {};
if (process.env.NODE_ENV !== "production") {
  locales.push("test");

  debugOptions["debug"] = true;

  // To override the detected language, use debugOptions["lng"] = someTestLocale;
}

const initObj = {
  resources: locales.reduce<{ [name: string]: any }>((resources, locale) => {
    resources[locale] = { translation: require(`./locale/${locale}.json`) };
    return resources;
  }, {}),
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...debugOptions,
    ...initObj,
  });

moment.locale(i18next.language);

export default i18next;
