/**
 * @generated SignedSource<<faf5ca0b60e4c7e93144397a55c7e175>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseSingleSelect_characteristicResponses$data = ReadonlyArray<{
  readonly answer: {
    readonly id: string;
    readonly userSpecified: boolean;
  } | null;
  readonly customAnswer: string | null;
  readonly id: string;
  readonly " $fragmentType": "InputCharacteristicResponseSingleSelect_characteristicResponses";
}>;
export type InputCharacteristicResponseSingleSelect_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponseSingleSelect_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseSingleSelect_characteristicResponses">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponseSingleSelect_characteristicResponses",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAnswer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicAnswerNode",
      "kind": "LinkedField",
      "name": "answer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userSpecified",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};
})();

(node as any).hash = "8a07164928236e09fe0aa9049a0e470b";

export default node;
