import { Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { GRAY_6 } from "../../style";

type Props = {
  style?: any;
  inputStyle?: any;
  titleStyle?: any;
  descriptionStyle?: any;
  title: string;
  description?: string | React.ReactFragment;
  optional?: boolean;
  required?: boolean;
  tooltip?: string;
  children: React.ReactFragment;
};
export const InputWrapper: React.FC<Props> = ({
  style,
  inputStyle,
  titleStyle,
  descriptionStyle,
  description,
  title,
  optional,
  required,
  tooltip,
  children,
}) => (
  <StyledInput style={style}>
    <Tooltip title={tooltip}>
      <div className="title-wrapper">
        <span className="required">{required && "*"}</span>
        <div className="title" style={titleStyle}>
          {title}
        </div>
        <div className="optional">{optional && "Optional"}</div>
      </div>
      <div style={inputStyle}>{children}</div>
      <div className="description" style={descriptionStyle}>
        {description}
      </div>
    </Tooltip>
  </StyledInput>
);

const StyledInput = styled.div`
  margin-bottom: 24px;

  .title-wrapper {
    display: flex;

    .required {
      font-size: 14px;
      color: var(--ant-error-color);
      margin-right: 4px;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      color: #000000;
    }

    .optional {
      font-size: 11px;
      color: ${GRAY_6};
    }
  }

  .description {
    font-size: 11px;
    color: #b3b3b3;
  }
`;
