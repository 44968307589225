import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useMountEffect } from "@react-hookz/web";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import mixpanel from "mixpanel-browser";
import { SetRequired } from "type-fest";

import type { PanelistNode, TenantNode, UserNode } from "../schema";
import { ScreenersStudySchedulingTypeChoices } from "../schema";

import {
  identifyUser as intercomIdentifyUser,
  shutdown as intercomShutdown,
  trackEvent as intercomTrackEvent,
} from "./intercom";
import { identifyUser as pendoIdentifyUser, trackEvent as pendoTrackEvent } from "./pendo";
import { flattenEdges } from "./transform";

const env = runtimeEnv();

type EventMeta = Record<string, unknown>;

export const getRecruitContext = <T extends { dbId: string; type: string | null; name: string | null }>(
  recruit: T | undefined
): EventMeta | undefined =>
  recruit
    ? {
        "Recruit dID": recruit.dbId,
        "Recruit Type": recruit.type,
        "Recruit Name": recruit.name,
      }
    : undefined;

export const getRespondentContext = <T extends { dId: string; panelistDId?: string }>(
  respondent: T | undefined
): EventMeta | undefined =>
  respondent
    ? {
        "Respondent dID": respondent.dId,
        ...(respondent.panelistDId && { "Panelist dID": respondent.panelistDId }),
      }
    : undefined;

export const getStudyContext = <
  T extends {
    dId: string;
    type: string;
    name: string | null;
    status: string;
    schedulingType?: string;
  }
>(
  study: T | undefined
): EventMeta | undefined =>
  study
    ? {
        "Study dID": study.dId,
        "Study Type": study.type,
        "Study Name": study.name,
        "Study Status": study.status,
        ...(study.schedulingType && {
          "Scheduling Type":
            study.schedulingType === ScreenersStudySchedulingTypeChoices.Hx
              ? "HubUX"
              : study.schedulingType === ScreenersStudySchedulingTypeChoices.Cl
              ? "Calendly"
              : study.schedulingType,
        }),
      }
    : undefined;

export const getTenantContext = <T extends { dId: string; vpmAccountId: number | null; name: string }>(
  tenant: T | undefined
): EventMeta | undefined =>
  tenant
    ? {
        "Tenant dID": tenant.dId,
        "Voxpopme Account ID": tenant.vpmAccountId,
        "Tenant Name": tenant.name,
      }
    : undefined;

export const getUserContext = <
  T extends { dId: string; vpmUserId: number | null; panelist?: P },
  P extends { dId: string } | null
>(
  user: T | undefined
): EventMeta | undefined =>
  user
    ? {
        "User dID": user.dId,
        "Voxpopme User ID": user.vpmUserId,
        ...(user.panelist && {
          "Panelist dID": user.panelist?.dId,
        }),
      }
    : undefined;

export const identify = ({
  dId,
  email,
  vpmUserId,
  fullName,
  panelist,
  profile,
}: SetRequired<Partial<UserNode>, "dId" | "email" | "fullName" | "vpmUserId"> & {
  panelist:
    | (SetRequired<Partial<PanelistNode>, "dId"> & {
        dId: string;
        tenants: {
          edges: ({
            node: SetRequired<Partial<TenantNode>, "dId" | "name">;
          } | null)[];
        } | null;
      })
    | null;
  profile:
    | {
        tenant: SetRequired<Partial<TenantNode>, "dId" | "name" | "enablePanelView" | "vpmAccountId"> | null;
      }
    | null
    | undefined;
}) => {
  if (!env.REACT_APP_ANALYTICS_DISABLED)
    try {
      const name = fullName || "Unknown name";

      LogRocket.identify(dId, {
        name,
        email,

        // User traits: https://docs.logrocket.com/v1.0/reference/user-traits
        host: window.location.host,
        panelistId: panelist?.dId ?? false,
        panelistTenantsIds: flattenEdges(panelist?.tenants).reduce((acc, cur) => `${acc}${cur.dId}`, "") || false,
        panelistTenantsNames: flattenEdges(panelist?.tenants).reduce((acc, cur) => `${acc}${cur.name}`, "") || false,
        tenantId: profile?.tenant?.dId ?? false,
        tenantName: profile?.tenant?.name ?? false,
        userId: dId,
      });

      if (panelist) {
        // This is legacy — Why are we using panelist.dId instead of user.dId?
        mixpanel.identify(panelist.dId);
        return;
      }

      // handle app user identification
      intercomIdentifyUser(email, name, dId);
      pendoIdentifyUser({
        vpmUserId,
        email,
        name,
        vpmAccountId: profile?.tenant?.vpmAccountId ?? null,
        tenantName: profile?.tenant?.name,
      });
    } catch {}
};

export const deidentify = () => {
  intercomShutdown();
  pendoIdentifyUser({ vpmUserId: null, vpmAccountId: null });
  mixpanel.reset();
};

export const trackEvent = (event: string, meta?: EventMeta) => {
  if (env.REACT_APP_ANALYTICS_DISABLED) return;

  mixpanel.track(event, meta);
  intercomTrackEvent(event, meta);
  pendoTrackEvent(event, meta);
};

export const useLogrocketInit = () =>
  useMountEffect(() => {
    if (!env.REACT_APP_ANALYTICS_DISABLED)
      try {
        LogRocket.init(env.REACT_APP_LOGROCKET_APP_ID!);
        setupLogRocketReact(LogRocket);
      } catch {}
  });
