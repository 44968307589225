/**
 * @generated SignedSource<<7001b858855e755ac3ec68495d19c9c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetGivePointsToParticipantsCostsInput = {
  clientMutationId?: string | null;
  participantIds?: ReadonlyArray<string | null> | null;
  points: number;
};
export type GivePointsMutations_getGivePointsToParticipantsCosts_Mutation$variables = {
  input: GetGivePointsToParticipantsCostsInput;
};
export type GivePointsMutations_getGivePointsToParticipantsCosts_Mutation$data = {
  readonly getGivePointsToParticipantsCosts: {
    readonly costsSummary: {
      readonly feesUsdCents: number;
      readonly nonTestParticipantCount: number;
      readonly pointsCostUsdCents: number;
      readonly testParticipantCount: number;
    };
  } | null;
};
export type GivePointsMutations_getGivePointsToParticipantsCosts_Mutation = {
  response: GivePointsMutations_getGivePointsToParticipantsCosts_Mutation$data;
  variables: GivePointsMutations_getGivePointsToParticipantsCosts_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetGivePointsToParticipantsCostsPayload",
    "kind": "LinkedField",
    "name": "getGivePointsToParticipantsCosts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GivePointsCostsSummary",
        "kind": "LinkedField",
        "name": "costsSummary",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointsCostUsdCents",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feesUsdCents",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "testParticipantCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nonTestParticipantCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GivePointsMutations_getGivePointsToParticipantsCosts_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GivePointsMutations_getGivePointsToParticipantsCosts_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bfa0ab362dfe85b25fd78624fdd56692",
    "id": null,
    "metadata": {},
    "name": "GivePointsMutations_getGivePointsToParticipantsCosts_Mutation",
    "operationKind": "mutation",
    "text": "mutation GivePointsMutations_getGivePointsToParticipantsCosts_Mutation(\n  $input: GetGivePointsToParticipantsCostsInput!\n) {\n  getGivePointsToParticipantsCosts(input: $input) {\n    costsSummary {\n      pointsCostUsdCents\n      feesUsdCents\n      testParticipantCount\n      nonTestParticipantCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "baca093fe834bce56759a4128b91769a";

export default node;
