import outlineStar from "@iconify-icons/ic/outline-star";
import { IconifyIcon } from "@iconify/react";
import { AUTOMATED_EMAIL_TYPE, RESPONDENT_ICONS_MAP, RESPONDENT_MASTER_STATUSES_MAP } from "../../../constants";

type AutomatedEmailMetaSettings = {
  description: string;
  icon: IconifyIcon;
  replyToRequired: boolean;
  allowLink: boolean;
  additionalSupportedVariables: string[];
};

const DefaultAutomatedEmailMeta: Required<Omit<AutomatedEmailMetaSettings, "description" | "icon">> = {
  replyToRequired: false,
  allowLink: false,
  additionalSupportedVariables: [],
};

export const AutomatedEmailMeta: { [automatedEmailType: string]: Partial<AutomatedEmailMetaSettings> } = {
  [AUTOMATED_EMAIL_TYPE.REWARDED]: {
    replyToRequired: true,
    allowLink: true,
    additionalSupportedVariables: ["%respondent.incentive_amount%"],
  },
};

export const getAutomatedEmailMeta = (automatedEmailType: string): AutomatedEmailMetaSettings => ({
  // the "rated" status displays as "incentivized" by default, so we override it back to "rated"
  ...(automatedEmailType === AUTOMATED_EMAIL_TYPE.RATED
    ? { description: "Rated", icon: outlineStar }
    : // all other types
      {
        description: RESPONDENT_MASTER_STATUSES_MAP[automatedEmailType as AUTOMATED_EMAIL_TYPE],
        icon: RESPONDENT_ICONS_MAP[automatedEmailType as AUTOMATED_EMAIL_TYPE],
      }),

  ...DefaultAutomatedEmailMeta,

  ...AutomatedEmailMeta?.[automatedEmailType],
});
