/**
 * @generated SignedSource<<f9bbc8d4d9649c1a329ba3d3aad42054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationPage_CommunicationComposePage_Query$variables = {
  count?: number | null;
  search?: string | null;
  studyId: string;
};
export type CommunicationPage_CommunicationComposePage_Query$data = {
  readonly viewer: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_study">;
    } | null;
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_user">;
    } | null;
  };
};
export type CommunicationPage_CommunicationComposePage_Query = {
  response: CommunicationPage_CommunicationComposePage_Query$data;
  variables: CommunicationPage_CommunicationComposePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studyId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "studyId",
    "variableName": "studyId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunicationPage_CommunicationComposePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommunicationComposePage_study"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommunicationComposePage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommunicationPage_CommunicationComposePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailDomain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "googleCalendarEmail",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vpmAccountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmailTemplateConnection",
                    "kind": "LinkedField",
                    "name": "emailTemplates",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmailTemplateEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailTemplateNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "replyTo",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "subject",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "message",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "count"
                  },
                  {
                    "kind": "Variable",
                    "name": "respondent_Person_FirstName_Icontains",
                    "variableName": "search"
                  },
                  {
                    "kind": "Literal",
                    "name": "respondent_Person_OptIn",
                    "value": true
                  }
                ],
                "concreteType": "ParticipantNodeConnection",
                "kind": "LinkedField",
                "name": "participants",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParticipantNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParticipantNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RespondentNode",
                            "kind": "LinkedField",
                            "name": "respondent",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PersonNode",
                                "kind": "LinkedField",
                                "name": "person",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastName",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RecruitNode",
                                "kind": "LinkedField",
                                "name": "recruit",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isTest",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vpmUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3842d093d10a836cdac0c1536b685e5",
    "id": null,
    "metadata": {},
    "name": "CommunicationPage_CommunicationComposePage_Query",
    "operationKind": "query",
    "text": "query CommunicationPage_CommunicationComposePage_Query(\n  $studyId: String!\n  $count: Int\n  $search: String\n) {\n  viewer {\n    study(studyId: $studyId) {\n      ...CommunicationComposePage_study\n      id\n    }\n    user {\n      ...CommunicationComposePage_user\n      id\n    }\n  }\n}\n\nfragment CommunicationComposePage_study on StudyNode {\n  dId\n  id\n  tenant {\n    name\n    emailDomain\n    googleCalendarEmail\n    dId\n    vpmAccountId\n    ...SaveTemplateModal_tenant\n    id\n  }\n  status\n  type\n  name\n  ...FormItemParticipants_study_3DeKgW\n}\n\nfragment CommunicationComposePage_user on UserNode {\n  email\n  dId\n  vpmUserId\n  panelist {\n    dId\n    id\n  }\n}\n\nfragment FormItemParticipants_study_3DeKgW on StudyNode {\n  id\n  participants(first: $count, respondent_Person_FirstName_Icontains: $search, respondent_Person_OptIn: true) {\n    edges {\n      node {\n        respondent {\n          dId\n          person {\n            email\n            firstName\n            lastName\n            id\n          }\n          recruit {\n            isTest\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment SaveTemplateModal_tenant on TenantNode {\n  emailTemplates {\n    edges {\n      node {\n        id\n        name\n        replyTo\n        subject\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92904d8d3408f4dac987c27ff2223bf6";

export default node;
