import { isEqualSimple } from "@react-hookz/deep-equal";
import { usePreviousDistinct, useUpdateEffect } from "@react-hookz/web";
import { Form } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { ValueOf } from "type-fest";

import { ScreenersCharacteristicTypeChoices } from "../../../schema";
import { splitOnce, type useBoundForm } from "../../../utils";
import type { FormItemCharacteristicResponses_characteristic$key } from "../../../__generated__/FormItemCharacteristicResponses_characteristic.graphql";
import type { FormItemCharacteristicResponses_characteristicResponses$key } from "../../../__generated__/FormItemCharacteristicResponses_characteristicResponses.graphql";
import { InputCharacteristicResponses } from "../../Input/InputCharacteristicResponses/InputCharacteristicResponses";

import type { DispatchOnResetInterceptor } from "./FormPanelistResponses";
import type { UpdatePanelistValues } from "./utils";

export const FormItemCharacteristicResponses = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  dispatchOnResetInterceptor,
  gridDefaultValue,
  onValuesChange,
}: {
  characteristic: FormItemCharacteristicResponses_characteristic$key;
  characteristicResponses: FormItemCharacteristicResponses_characteristicResponses$key;
  dispatchOnResetInterceptor?: DispatchOnResetInterceptor;
  gridDefaultValue?: Record<string, ValueOf<UpdatePanelistValues>>;
  onValuesChange: ReturnType<typeof useBoundForm>["onValuesChange"];
}) => {
  const characteristic = useFragment(
    graphql`
      fragment FormItemCharacteristicResponses_characteristic on CharacteristicNode {
        id
        elementMeta {
          responseValidation
          target
          target2
        }
        text
        type
        ...InputCharacteristicResponses_characteristic
      }
    `,
    characteristicKey
  );
  const characteristicResponses = useFragment(
    graphql`
      fragment FormItemCharacteristicResponses_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        ...InputCharacteristicResponses_characteristicResponses
        answer {
          id
        }
        customAnswer
      }
    `,
    characteristicResponsesKey
  );

  const form = Form.useFormInstance();
  const [gridValue, setGridValue] = useState(gridDefaultValue);

  const characteristicIdPrevious = usePreviousDistinct(characteristic.id);
  useEffect(() => {
    dispatchOnResetInterceptor?.({
      action: "subscribe",
      interceptor: () => {
        if (!isEqualSimple(gridDefaultValue, gridValue)) setGridValue(gridDefaultValue);
      },
      key: characteristic.id,
    });

    return () => dispatchOnResetInterceptor?.({ action: "unsubscribe", key: characteristic.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characteristic.id, dispatchOnResetInterceptor, JSON.stringify(gridDefaultValue)]);
  useUpdateEffect(() => {
    if (characteristicIdPrevious)
      dispatchOnResetInterceptor?.({ action: "unsubscribe", key: characteristicIdPrevious });
  }, [characteristicIdPrevious]);

  const interceptUserSpecified = <T,>(x: T, { rowId }: { rowId?: string } = {}): T => {
    if (typeof x !== "string") return x;

    const [id, otherValue] = splitOnce(x, "::"); // user-specified answers will be in format <id>::<other>
    const otherKey = `${characteristic.id}${rowId ? `::${rowId}` : ""}_other`;

    if (otherValue) form.setFieldValue(otherKey, otherValue);
    else form.resetFields([otherKey]);

    return id as T;
  };

  return characteristic.type === ScreenersCharacteristicTypeChoices.Gs ? (
    <StyledInputCharacteristicResponses
      onChange={e => {
        if (
          e == null ||
          typeof e !== "object" ||
          Array.isArray(e) ||
          e instanceof Event ||
          Object.hasOwn(e, "isMoment")
        )
          return;

        // grid
        const changedValues = Object.fromEntries(
          Object.entries(e as Record<string, string>).map(([k, v]) => [
            `${characteristic.id}::${k}`,
            interceptUserSpecified(v, { rowId: k }),
          ])
        );
        onValuesChange(changedValues, { ...form.getFieldsValue(), ...changedValues });
        if (!isEqualSimple(e, gridValue)) setGridValue(e as Record<string, string>);
      }}
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      value={gridValue as Record<string, string>}
    />
  ) : (
    <Form.Item
      getValueFromEvent={e => {
        if ((e?.nativeEvent ?? e) instanceof Event && e.target != null) {
          const target = e.target as HTMLInputElement;
          return interceptUserSpecified(target.type === "checkbox" ? target.checked : target.value);
        }

        return Array.isArray(e) ? e.map(x => interceptUserSpecified(x)) : interceptUserSpecified(e);
      }}
      messageVariables={{ name: characteristic.text! }}
      name={characteristic.id}
    >
      <InputCharacteristicResponses characteristic={characteristic} characteristicResponses={characteristicResponses} />
    </Form.Item>
  );
};

const StyledInputCharacteristicResponses = styled(InputCharacteristicResponses)`
  margin-bottom: 24px;
`;
