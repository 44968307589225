import { message } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay";
import { environment } from "../relay";
import { authVerify_Query } from "../__generated__/authVerify_Query.graphql";
import { getApiBaseUrl } from "./misc";

const JWT_KEY = "h_jwt";
const REFRESH_TOKEN_KEY = "h_refreshToken";

export const checkAuth = async () => {
  try {
    const data = await fetchQuery<authVerify_Query>(
      environment,
      graphql`
        query authVerify_Query {
          viewer {
            user {
              email
              isPanelist
              profile {
                tenant {
                  id
                }
              }
            }
          }
        }
      `,
      {}
    ).toPromise();

    return {
      email: data?.viewer?.user?.email || null,
      isTenant: !!data?.viewer?.user?.profile?.tenant?.id,
      isPanelist: !!data?.viewer?.user?.isPanelist,
    };
  } catch {
    return { email: null, isTenant: false, isPanelist: false };
  }
};

export const setJwt = (jwt: string, refreshToken?: string) => {
  localStorage.setItem(JWT_KEY, jwt);
  if (refreshToken) localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  else localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const clearJwt = () => {
  localStorage.removeItem(JWT_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getJwt = (): { jwt: string | null; refreshToken: string | null } => ({
  jwt: localStorage.getItem(JWT_KEY),
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
});

export const getAuthorizationHeader = (): { Authorization?: string } => {
  const { jwt } = getJwt();
  return jwt ? { Authorization: `JWT ${jwt satisfies string}` } : {};
};

let tokenRefreshInFlight: Promise<void> | null = null;
export const refreshOidcToken = async () => {
  const { jwt, refreshToken } = getJwt();
  if (!jwt) return;

  const payload = JSON.parse(window.atob(jwt.split(".")[1]!));

  // if the token is expired or expires in the next ten minutes, refresh it
  const refreshBefore = Date.now() / 1000 + 10 * 60;

  if (
    !payload.iss || // JWT is not for OIDC (our private JWTs don't have issuer info, but OIDC ones will)
    payload.exp > refreshBefore // JWT is not in the refresh window
  )
    return;

  if (!tokenRefreshInFlight)
    // Start a new refresh
    tokenRefreshInFlight = (async () => {
      try {
        const { data } = await axios.post(
          `${getApiBaseUrl()}/api/refresh-oidc-token`,
          { refresh_token: refreshToken },
          {
            headers: {
              authorization: `JWT ${jwt satisfies string}`,
            },
          }
        );
        setJwt(data.access_token, data.refresh_token);
      } catch (e) {
        console.error(e);
        message.info("Your session has expired. Please log in again.");
        clearJwt();
      } finally {
        tokenRefreshInFlight = null;
      }
    })();

  await tokenRefreshInFlight;
};
