import { ExportOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";

import { graphql, useFragment } from "react-relay";
import { getStudyContext, getTenantContext, handleErrorWithMessage, trackEvent } from "../../utils";
import { downloadInBackground, getApiBaseUrl } from "../../utils/misc";
import { ExportSpendingDetailsButton_study$key } from "../../__generated__/ExportSpendingDetailsButton_study.graphql";

export const ExportSpendingDetailsButton = ({ study: studyKey }: { study: ExportSpendingDetailsButton_study$key }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const study = useFragment(
    graphql`
      fragment ExportSpendingDetailsButton_study on StudyNode {
        dId
        tenant {
          name
          dId
          vpmAccountId
          name
        }
        dId
        name
        type
        status
      }
    `,
    studyKey
  );

  return (
    <Button
      icon={<ExportOutlined />}
      style={{ marginLeft: 12 }}
      type="text"
      loading={loading}
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        try {
          await downloadInBackground(`${getApiBaseUrl()}/s/${study.dId}/costsReport`, "projectCosts.csv");
          trackEvent("Export Project Costs", {
            ...getStudyContext(study),
            ...getTenantContext(study.tenant as any),
          });
        } catch (err) {
          handleErrorWithMessage(err);
        } finally {
          setLoading(false);
        }
      }}
    >
      Export
    </Button>
  );
};
