import { Button, Modal, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ActionType } from "../../types";
import { ConfirmExternalIncentiveModal_study$data } from "../../__generated__/ConfirmExternalIncentiveModal_study.graphql";
import { EditExternalIncentiveEmailModal } from "./EditExternalIncentiveEmailModal";

type Props = {
  study: ConfirmExternalIncentiveModal_study$data;
  title: string;
  runMutation: () => Promise<void>;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};
const _ConfirmExternalIncentiveModal = ({ study, title, runMutation, visible, setVisible }: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showEditExternalIncentiveEmailModal, setShowEditExternalIncentiveEmailModal] = useState(false);

  const handleOk = async () => {
    try {
      setIsSaving(true);
      await runMutation();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledConfirmExternalIncentiveModal
      open={visible}
      title={title}
      footer={null}
      destroyOnClose={true}
      maskClosable={!isSaving}
      onCancel={() => setVisible(false)}
    >
      <EditExternalIncentiveEmailModal
        study={study}
        visible={showEditExternalIncentiveEmailModal}
        setVisible={setShowEditExternalIncentiveEmailModal}
      />
      <p>We will send them an email to let them know that they were incentivized.</p>
      <div className="confirm-external-reward-buttons">
        <Button type="link" disabled={isSaving} onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          type="default"
          disabled={isSaving}
          onClick={() => {
            setShowEditExternalIncentiveEmailModal(true);
          }}
        >
          Edit email
        </Button>
        <Tooltip
          trigger={[...(study.externalIncentive?.automatedEmail?.enabled ? [] : ["hover"])] as ActionType[]}
          title="Cannot issue an external incentive until an incentive email is created and enabled"
          placement="top"
        >
          <Button
            type="primary"
            disabled={!study.externalIncentive?.automatedEmail?.enabled}
            loading={isSaving}
            onClick={handleOk}
          >
            Incentivize
          </Button>
        </Tooltip>
      </div>
    </StyledConfirmExternalIncentiveModal>
  );
};

const StyledConfirmExternalIncentiveModal = styled(Modal)`
  .confirm-external-reward-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

export const ConfirmExternalIncentiveModal = createFragmentContainer(_ConfirmExternalIncentiveModal, {
  study: graphql`
    fragment ConfirmExternalIncentiveModal_study on StudyNode {
      externalIncentive {
        automatedEmail {
          enabled
        }
      }
      ...EditExternalIncentiveEmailModal_study
    }
  `,
});
