/**
 * @generated SignedSource<<081bfd5c10b690b7ac6042a49ef17e53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateRecruitInput = {
  clientMutationId?: string | null;
  meta: any;
  recruitId: string;
  selectedEmails?: ReadonlyArray<string> | null;
};
export type EditInvitationEmailModal_updateRecruit_Mutation$variables = {
  input: UpdateRecruitInput;
};
export type EditInvitationEmailModal_updateRecruit_Mutation$data = {
  readonly updateRecruit: {
    readonly recruit: {
      readonly meta: any | null;
    } | null;
  } | null;
};
export type EditInvitationEmailModal_updateRecruit_Mutation = {
  response: EditInvitationEmailModal_updateRecruit_Mutation$data;
  variables: EditInvitationEmailModal_updateRecruit_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meta",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInvitationEmailModal_updateRecruit_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRecruitPayload",
        "kind": "LinkedField",
        "name": "updateRecruit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RecruitNode",
            "kind": "LinkedField",
            "name": "recruit",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditInvitationEmailModal_updateRecruit_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRecruitPayload",
        "kind": "LinkedField",
        "name": "updateRecruit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RecruitNode",
            "kind": "LinkedField",
            "name": "recruit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "933a3ea390bb6f70bf27bc957b9950d4",
    "id": null,
    "metadata": {},
    "name": "EditInvitationEmailModal_updateRecruit_Mutation",
    "operationKind": "mutation",
    "text": "mutation EditInvitationEmailModal_updateRecruit_Mutation(\n  $input: UpdateRecruitInput!\n) {\n  updateRecruit(input: $input) {\n    recruit {\n      meta\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "390f34945827bed6d8c9b72e4b9fc968";

export default node;
