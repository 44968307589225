import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import { MultiChoiceElement_element$data } from "../../__generated__/MultiChoiceElement_element.graphql";
import { MultiChoiceElement_screener$data } from "../../__generated__/MultiChoiceElement_screener.graphql";
import { CommonElementProps } from "./Element";
import { ElementBase } from "./index";

type Props = {
  element: MultiChoiceElement_element$data;
  screener: MultiChoiceElement_screener$data;
} & CommonElementProps;
const MultiChoiceElement: React.FC<Props> = props => <ElementBase {...props} />;

export default createFragmentContainer(MultiChoiceElement, {
  element: graphql`
    fragment MultiChoiceElement_element on ElementNode {
      ...ElementBase_element
    }
  `,
  screener: graphql`
    fragment MultiChoiceElement_screener on ScreenerNode {
      ...ElementBase_screener
    }
  `,
});
