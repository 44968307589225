/**
 * @generated SignedSource<<9359bac8344e1c81c3030fd8b8caa8b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StaffLayout_user$data = {
  readonly dId: any | null;
  readonly email: string;
  readonly fullName: string | null;
  readonly panelist: {
    readonly dId: any | null;
    readonly tenants: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly dId: any | null;
          readonly name: string;
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly profile: {
    readonly tenant: {
      readonly dId: any | null;
      readonly enablePanelView: boolean;
      readonly hideBillingPage: boolean;
      readonly name: string;
    };
  } | null;
  readonly vpmUserId: number | null;
  readonly " $fragmentType": "StaffLayout_user";
};
export type StaffLayout_user$key = {
  readonly " $data"?: StaffLayout_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"StaffLayout_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaffLayout_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNodeConnection",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enablePanelView",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hideBillingPage",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vpmUserId",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "672b9b9d4cd1e6e8780846165a59d043";

export default node;
