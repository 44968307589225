/**
 * @generated SignedSource<<3b1c9cde6e0adbfcae084d843a950336>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PortalRegistrationPageLoginMutation$variables = {
  email: string;
  password: string;
};
export type PortalRegistrationPageLoginMutation$data = {
  readonly tokenAuth: {
    readonly token: string;
    readonly user: {
      readonly dId: any | null;
      readonly email: string;
      readonly fullName: string | null;
      readonly panelist: {
        readonly dId: any | null;
        readonly tenants: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly dId: any | null;
              readonly name: string;
            } | null;
          } | null>;
        } | null;
      } | null;
      readonly profile: {
        readonly tenant: {
          readonly dId: any | null;
          readonly enablePanelView: boolean;
          readonly id: string;
          readonly name: string;
        };
      } | null;
      readonly vpmUserId: number | null;
    } | null;
  } | null;
};
export type PortalRegistrationPageLoginMutation = {
  response: PortalRegistrationPageLoginMutation$data;
  variables: PortalRegistrationPageLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantNode",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enablePanelView",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vpmUserId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortalRegistrationPageLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ObtainJSONWebToken",
        "kind": "LinkedField",
        "name": "tokenAuth",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNodeConnection",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortalRegistrationPageLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ObtainJSONWebToken",
        "kind": "LinkedField",
        "name": "tokenAuth",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNodeConnection",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e216a99ff329067a7d281daa7e49b039",
    "id": null,
    "metadata": {},
    "name": "PortalRegistrationPageLoginMutation",
    "operationKind": "mutation",
    "text": "mutation PortalRegistrationPageLoginMutation(\n  $email: String!\n  $password: String!\n) {\n  tokenAuth(email: $email, password: $password) {\n    token\n    user {\n      dId\n      email\n      fullName\n      panelist {\n        dId\n        tenants {\n          edges {\n            node {\n              dId\n              name\n              id\n            }\n          }\n        }\n        id\n      }\n      profile {\n        tenant {\n          id\n          dId\n          name\n          enablePanelView\n        }\n        id\n      }\n      vpmUserId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0cdf1707c4e51f5b574abb10ad2ff97";

export default node;
