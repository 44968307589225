import { Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";

import {
  CHARACTERISTIC_ELEMENT_TYPES,
  ELEMENT_TYPES,
  NON_FILTERABLE_ELEMENT_TYPES,
  SELECTABLE_ELEMENT_TYPES,
} from "../../constants";
import { ScreenersCharacteristicTypeChoices, ScreenersFilterTypeChoices } from "../../schema";
import type { WritableDeep } from "../../types";
import { flattenEdges, sentenceCase } from "../../utils";
import type { InputFilter_element$key } from "../../__generated__/InputFilter_element.graphql";
import type { InputFilter_screener$key } from "../../__generated__/InputFilter_screener.graphql";
import type { InputFilter_tenant$key } from "../../__generated__/InputFilter_tenant.graphql";

import { ButtonNot } from "./ButtonNot";
import { InputFilterNumber } from "./InputFilterNumber";
import { InputFilterResponseId } from "./InputFilterResponseId";
import { InputFilterResponsePosition } from "./InputFilterResponsePosition";
import { InputFilterResponseValueDate } from "./InputFilterResponseValueDate";
import { InputFilterStudy } from "./InputFilterStudy";
import { InputFilterText } from "./InputFilterText";
import {
  ElementFilter,
  FilterOp,
  FilterStudy,
  FilterSubtypeResponse,
  FilterType,
  GroupOp,
  isFilterResponseId,
  isFilterResponsePosition,
  isFilterResponseValueDate,
  SegmentFilter,
  type NewFilter,
  type PanelistFilter,
  type ParticipantFilter,
} from "./types";

const formatShortName = (x?: string) => x && x.charAt(0).toUpperCase() + x.slice(1).replaceAll("_", " ");

const isKeyof = <T extends {}>(key: string | keyof T, x: T): key is keyof T => key in x;

/**
 * Property equality check (referential) that works for subtypes of `T` where `key` may not have an index signature
 */
const isEqual = <T extends ElementFilter | SegmentFilter | ParticipantFilter | PanelistFilter>(
  a: Partial<T>,
  b: Partial<T>,
  key: string
) => isKeyof(key, a) === isKeyof(key, b) && (!isKeyof(key, a) || a[key] === b[key]);

export const InputFilter = <T extends ElementFilter | SegmentFilter | ParticipantFilter | PanelistFilter>({
  element: elementKey,
  onChange,
  screener: screenerKey,
  tenant: tenantKey,
  type: _type,
  value,
}: {
  onChange: (v: T) => void;
  screener: InputFilter_screener$key | null;
  tenant: InputFilter_tenant$key | null;
  value: T;
} & (
  | {
      element?: null;
      type: ScreenersFilterTypeChoices | "segment";
    }
  | {
      element: InputFilter_element$key;
      type: "element";
    }
)) => {
  const element = useFragment(
    graphql`
      fragment InputFilter_element on ElementNode {
        position
      }
    `,
    _type === "element" ? elementKey : null
  );
  const screener = useFragment(
    graphql`
      fragment InputFilter_screener on ScreenerNode {
        id
        elements {
          edges {
            node {
              id
              dbId
              position
              type
              text
              label
              answers {
                edges {
                  node {
                    id
                    position
                    text
                    dbId
                  }
                }
              }
              rows {
                edges {
                  node {
                    id
                    dbId
                    text
                    position
                  }
                }
              }
            }
          }
        }
        segments {
          edges {
            node {
              id
              dbId
              text
            }
          }
        }
      }
    `,
    [ScreenersFilterTypeChoices.Pt, "segment", "element"].includes(_type) ? screenerKey : null
  );
  const elements = useMemo(
    () =>
      flattenEdges(screener?.elements).filter(
        x =>
          !NON_FILTERABLE_ELEMENT_TYPES.includes(x?.type ?? "") &&
          (_type !== "element" || x.position < element?.position!)
      ) ?? [],
    [_type, element?.position, screener?.elements]
  );
  const elementsGrid = useMemo(
    () => elements.filter(x => x.type === ELEMENT_TYPES.GRID_SINGLE_SELECT && x.rows.edges.some(x => !!x?.node?.text)),
    [elements]
  );
  const elementsRank = useMemo(
    () => elements.filter(x => x.type === ELEMENT_TYPES.RANK && x.answers.edges.some(x => !!x?.node?.text)),
    [elements]
  );
  const elementsOther = useMemo(
    () =>
      elements.filter(x => ![ELEMENT_TYPES.GRID_SINGLE_SELECT, ELEMENT_TYPES.RANK].includes(x.type as ELEMENT_TYPES)),
    [elements]
  );

  const tenant = useFragment(
    graphql`
      fragment InputFilter_tenant on TenantNode {
        characteristics(first: 1000) {
          edges {
            node {
              answers {
                dbId
                text
              }
              dbId
              rows {
                dbId
                text
              }
              shortName
              type
            }
          }
        }
        globalCharacteristics(first: 1000) {
          edges {
            node {
              answers {
                dbId
                text
              }
              dbId
              rows {
                dbId
                text
              }
              shortName
              type
            }
          }
        }
        ...InputFilterStudyFilter_tenant
      }
    `,
    _type === ScreenersFilterTypeChoices.Pn ? tenantKey : null
  );
  const characteristicsGlobal = useMemo(() => flattenEdges(tenant?.globalCharacteristics), [tenant]);
  const characteristicsGlobalGrid = useMemo(
    () =>
      characteristicsGlobal.filter(x => x.type === CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT && x.rows.length),
    [characteristicsGlobal]
  );
  const characteristicsGlobalOther = useMemo(
    () => characteristicsGlobal.filter(x => x.type !== CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT),
    [characteristicsGlobal]
  );
  const characteristicsTenant = useMemo(() => flattenEdges(tenant?.characteristics), [tenant]);
  const characteristicsTenantGrid = useMemo(
    () =>
      characteristicsTenant.filter(x => x.type === CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT && x.rows.length),
    [characteristicsTenant]
  );
  const characteristicsTenantOther = useMemo(
    () => characteristicsTenant.filter(x => x.type !== CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT),
    [characteristicsTenant]
  );
  const characteristicsAll = useMemo(
    () => [...characteristicsGlobal, ...characteristicsTenant],
    [characteristicsGlobal, characteristicsTenant]
  );

  const [filter, _setFilter] = useState<T>(value);
  const setFilter = (x: T) => {
    _setFilter(x);
    onChange(x);
  };
  const resetFilter = <F extends NewFilter<T>>(x: F) =>
    setFilter({
      ...x,
      id: filter.id,
      [x.type === FilterType.Study ? "filters" : "values"]: [],
      ...(x.type === filter.type && isEqual(x, filter, "subtype")
        ? // new filter supports same operators
          {
            negate: filter.negate,
            op: filter.op,
            ...((
              x.type === FilterType.Response && "subtype" in x && x.subtype === FilterSubtypeResponse.Id
                ? isEqual(x, filter, "characteristicId") || isEqual(x, filter, "elementId")
                : true
            )
              ? // new filter has same possible values
                "filters" in filter
                ? { filters: filter.filters }
                : { values: filter.values }
              : {}),
          }
        : {
            negate: false,
          }),
    } as unknown as T);

  return (
    <Styled>
      <Select
        className="select-id"
        defaultValue={
          filter.type === "PANELIST"
            ? "Panelist ID"
            : filter.type === "PARTICIPANT"
            ? filter.subtype === "DAYS_SINCE_LAST_SCREENER"
              ? "Days since last screener"
              : filter.subtype === "DAYS_SINCE_LAST_STUDY"
              ? "Days since last study"
              : undefined
            : filter.type === "PERSON"
            ? sentenceCase(filter.subtype)
            : filter.type === "RESPONSE"
            ? "characteristicId" in filter && !!filter.characteristicId
              ? "rowId" in filter && !!filter.rowId
                ? characteristicsAll
                    .find(x => x.dbId === filter.characteristicId)
                    ?.rows?.find(x => x.dbId === filter.rowId)?.text
                : formatShortName(characteristicsAll.find(x => x.dbId === filter.characteristicId)?.shortName)
              : "elementId" in filter && !!filter.elementId
              ? "rowId" in filter && !!filter.rowId
                ? flattenEdges(elements.find(x => x.dbId === filter.elementId)?.rows).find(x => x.dbId === filter.rowId)
                    ?.text
                : elements.find(x => x.dbId === filter.elementId)?.text
              : undefined
            : filter.type === "SEGMENT"
            ? "Segment"
            : filter.type === "STUDY"
            ? filter.subtype === "COMPLETED"
              ? "Past participation"
              : filter.subtype === "INVITED"
              ? "Past invitation"
              : undefined
            : undefined
        }
        getPopupContainer={trigger => trigger.parentElement!}
        onChange={(v: string) => resetFilter(JSON.parse(v) as NewFilter<T>)}
        optionFilterProp="children"
        placeholder="Please select"
        showSearch
      >
        {_type !== "segment" && !!screener?.segments?.edges?.length && (
          <Select.Option
            key={FilterType.Segment}
            value={JSON.stringify({
              type: FilterType.Segment,
              op: FilterOp.Is,
            } satisfies NewFilter<ParticipantFilter>)}
          >
            Segment
          </Select.Option>
        )}
        {!!elementsOther?.length && (
          <Select.OptGroup label={"Screener Questions"}>
            {elementsOther.map(({ dbId: elementId, type: typ, ...x }) => {
              const valueBase = {
                type: FilterType.Response,
                elementId,
                op: FilterOp.Is,
              } as const;

              const value: NewFilter<ParticipantFilter> | null = SELECTABLE_ELEMENT_TYPES.includes(typ)
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.Id,
                    rowId: "",
                  }
                : [ELEMENT_TYPES.AUDITION_TEXT, ELEMENT_TYPES.OPENEND].includes(typ as ELEMENT_TYPES)
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueText,
                  }
                : typ === ELEMENT_TYPES.DATE
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueDate,
                    op: FilterOp.Before,
                  }
                : typ === ELEMENT_TYPES.NUMBER
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueNumber,
                  }
                : null;

              return value === null ? null : (
                <Select.Option key={elementId} value={JSON.stringify(value)}>
                  {!!x.label && `${x.label} `}
                  {x.text || "<Empty>"}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        )}
        {elementsGrid.map(x => (
          <Select.OptGroup label={`${x.label ? `${x.label} ` : ""}${x.text}`}>
            {flattenEdges(x.rows).map(row => (
              <Select.Option
                key={row.dbId}
                value={JSON.stringify({
                  type: FilterType.Response,
                  subtype: FilterSubtypeResponse.Id,
                  elementId: x.dbId,
                  rowId: row.dbId,
                  op: FilterOp.Is,
                } satisfies NewFilter<ParticipantFilter>)}
              >
                {row.text}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
        {elementsRank.map(x => (
          <Select.OptGroup label={`${x.label ? `${x.label} ` : ""}${x.text}`}>
            {flattenEdges(x.answers).map(answer => (
              <Select.Option
                key={answer.dbId}
                value={JSON.stringify({
                  type: FilterType.Response,
                  subtype: FilterSubtypeResponse.Position,
                  elementId: x.dbId,
                  answerId: answer.dbId,
                  op: FilterOp.Is,
                } satisfies NewFilter<ParticipantFilter>)}
              >
                {answer.text}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
        {!!characteristicsTenantOther?.length && (
          <Select.OptGroup label={"Your characteristics"}>
            {characteristicsTenantOther.map(({ dbId: characteristicId, type: typ, ...x }) => {
              const valueBase = {
                type: FilterType.Response,
                characteristicId,
                op: FilterOp.Is,
              } as const;

              const value: NewFilter<Exclude<PanelistFilter, FilterStudy>> | null = (
                [ScreenersCharacteristicTypeChoices.Ms, ScreenersCharacteristicTypeChoices.Ss] as (typeof typ)[]
              ).includes(typ)
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.Id,
                    rowId: "",
                  }
                : typ === ScreenersCharacteristicTypeChoices.Oe
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueText,
                  }
                : typ === ScreenersCharacteristicTypeChoices.Dt
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueDate,
                    op: FilterOp.Before,
                  }
                : typ === ScreenersCharacteristicTypeChoices.Nm
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueNumber,
                  }
                : null;

              return value === null ? null : (
                <Select.Option key={characteristicId} value={JSON.stringify(value)}>
                  {formatShortName(x.shortName)}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        )}
        {_type === ScreenersFilterTypeChoices.Pn && (
          <Select.OptGroup label={"Panelist properties"}>
            <Select.Option
              key="type-panelist-id"
              value={JSON.stringify({
                type: FilterType.Panelist,
                subtype: "ID",
                op: FilterOp.Is,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Panelist ID
            </Select.Option>
            <Select.Option
              key="type-person-name-first"
              value={JSON.stringify({
                type: FilterType.Person,
                subtype: "FIRST_NAME",
                op: FilterOp.Is,
              } satisfies NewFilter<PanelistFilter>)}
            >
              First name
            </Select.Option>
            <Select.Option
              key="type-person-email"
              value={JSON.stringify({
                type: FilterType.Person,
                subtype: "EMAIL",
                op: FilterOp.Is,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Email
            </Select.Option>
            <Select.Option
              key="type-person-phone"
              value={JSON.stringify({
                type: FilterType.Person,
                subtype: "PHONE_NUMBER",
                op: FilterOp.Is,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Phone number
            </Select.Option>
            <Select.Option
              key="type-study-invited"
              value={JSON.stringify({
                type: FilterType.Study,
                subtype: "INVITED",
                op: GroupOp.And,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Past invitation
            </Select.Option>
            <Select.Option
              key="type-study-completed"
              value={JSON.stringify({
                type: FilterType.Study,
                subtype: "COMPLETED",
                op: GroupOp.And,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Past participation
            </Select.Option>
            <Select.Option
              key="type-participant-days-since-last-screener"
              value={JSON.stringify({
                type: FilterType.Participant,
                subtype: "DAYS_SINCE_LAST_SCREENER",
                op: FilterOp.GreaterThan,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Days since last screener
            </Select.Option>
            <Select.Option
              key="type-participant-days-since-last-study"
              value={JSON.stringify({
                type: FilterType.Participant,
                subtype: "DAYS_SINCE_LAST_STUDY",
                op: FilterOp.GreaterThan,
              } satisfies NewFilter<PanelistFilter>)}
            >
              Days since last study
            </Select.Option>
          </Select.OptGroup>
        )}
        {characteristicsTenantGrid.map(x => (
          <Select.OptGroup label={formatShortName(x.shortName)}>
            {x.rows.map(row => (
              <Select.Option
                key={row.dbId}
                value={JSON.stringify({
                  type: FilterType.Response,
                  subtype: FilterSubtypeResponse.Id,
                  characteristicId: x.dbId,
                  rowId: row.dbId,
                  op: FilterOp.Is,
                } satisfies NewFilter<PanelistFilter>)}
              >
                {row.text}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
        {!!characteristicsGlobalOther?.length && (
          <Select.OptGroup label={"Global characteristics"}>
            {characteristicsGlobalOther.map(({ dbId: characteristicId, type: typ, ...x }) => {
              const valueBase = {
                type: FilterType.Response,
                characteristicId,
                op: FilterOp.Is,
              } as const;

              const value: NewFilter<PanelistFilter> | null = (
                [ScreenersCharacteristicTypeChoices.Ms, ScreenersCharacteristicTypeChoices.Ss] as (typeof typ)[]
              ).includes(typ)
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.Id,
                    rowId: "",
                  }
                : typ === ScreenersCharacteristicTypeChoices.Oe
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueText,
                  }
                : typ === ScreenersCharacteristicTypeChoices.Dt
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueDate,
                    op: FilterOp.Before,
                  }
                : typ === ScreenersCharacteristicTypeChoices.Nm
                ? {
                    ...valueBase,
                    subtype: FilterSubtypeResponse.ValueNumber,
                  }
                : null;

              return value === null ? null : (
                <Select.Option key={characteristicId} value={JSON.stringify(value)}>
                  {formatShortName(x.shortName)}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        )}
        {characteristicsGlobalGrid.map(x => (
          <Select.OptGroup label={formatShortName(x.shortName)}>
            {x.rows.map(row => (
              <Select.Option
                key={row.dbId}
                value={JSON.stringify({
                  type: FilterType.Response,
                  subtype: FilterSubtypeResponse.Id,
                  characteristicId: x.dbId,
                  rowId: row.dbId,
                  op: FilterOp.Is,
                } satisfies NewFilter<PanelistFilter>)}
              >
                {row.text}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
      {/******** Terms and Values **********/}
      {[FilterType.Panelist, FilterType.Person].includes(filter.type) ? (
        <div className="form-filter-row">
          <InputFilterText filter={filter} setFilter={setFilter} />
        </div>
      ) : filter.type === FilterType.Participant ? (
        <div className="form-filter-row">
          <InputFilterNumber filter={filter} setFilter={setFilter} />
        </div>
      ) : filter.type === FilterType.Response ? (
        "characteristicId" in filter && !!filter.characteristicId ? (
          (() => {
            const characteristic = characteristicsAll.find(x => x?.dbId === filter.characteristicId);
            if (!characteristic) return null;

            switch (characteristic.type) {
              case ScreenersCharacteristicTypeChoices.Dt:
                if (isFilterResponseValueDate<"characteristic">(filter))
                  return <InputFilterResponseValueDate filter={filter} setFilter={setFilter} />;
                return null;
              case ScreenersCharacteristicTypeChoices.Nm:
                return <InputFilterNumber filter={filter} setFilter={setFilter} />;
              case ScreenersCharacteristicTypeChoices.Oe:
                return <InputFilterText filter={filter} setFilter={setFilter} />;
              default:
                if (isFilterResponseId<"characteristic">(filter))
                  return (
                    <InputFilterResponseId
                      answers={characteristic.answers as WritableDeep<typeof characteristic.answers>}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  );
                return null;
            }
          })()
        ) : "elementId" in filter && !!filter.elementId ? (
          (() => {
            const element = elements.find(x => x?.dbId === filter.elementId);
            if (!element) return null;

            switch (element.type) {
              case ELEMENT_TYPES.DATE:
                if (isFilterResponseValueDate<"element">(filter))
                  return <InputFilterResponseValueDate filter={filter} setFilter={setFilter} />;
                return null;
              case ELEMENT_TYPES.NUMBER:
                return <InputFilterNumber filter={filter} setFilter={setFilter} />;
              case ELEMENT_TYPES.AUDITION_TEXT:
              case ELEMENT_TYPES.OPENEND:
                return <InputFilterText filter={filter} setFilter={setFilter} />;
              case ELEMENT_TYPES.RANK:
                if (isFilterResponsePosition<"element">(filter))
                  return (
                    <InputFilterResponsePosition
                      answers={flattenEdges(element.answers)}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  );
                return null;
              default:
                if (isFilterResponseId<"element">(filter))
                  return (
                    <InputFilterResponseId
                      answers={flattenEdges(element.answers)}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  );
                return null;
            }
          })()
        ) : null
      ) : filter.type === FilterType.Segment ? (
        <div className="form-filter-row">
          <ButtonNot filter={filter} setFilter={setFilter} />
          <Select
            mode="multiple"
            className="input-values"
            value={filter.values}
            onChange={values => setFilter({ ...filter, values })}
            showSearch
            optionFilterProp="children"
          >
            {flattenEdges(screener?.segments).map(x => (
              <Select.Option key={x.dbId} value={x.dbId}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : filter.type === FilterType.Study ? (
        !!tenant && (
          <InputFilterStudy
            onChange={x => setFilter(x as T)}
            style={{ width: "100%" }}
            value={filter}
            tenant={tenant}
          />
        )
      ) : null}
    </Styled>
  );
};

const Styled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .select-id,
  .form-filter-col,
  .form-filter-row {
    width: 100%;
  }

  .form-filter-col {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .form-filter-row {
    display: flex;
    gap: 8px;

    .select-operator {
      width: 100px;
      flex-shrink: 0;
    }

    *:last-child {
      flex: 1;
      overflow: hidden;
    }
  }
`;
