import { Card, theme } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { chunk } from "lodash-es";
import { useEffect, useState } from "react";
import { createPaginationContainer, RelayPaginationProp } from "react-relay";
import styled from "styled-components";

import { DEFAULT_PANELISTS_FETCH_COUNT } from "../../constants";
import type { PanelistsReport_tenant$data } from "../../__generated__/PanelistsReport_tenant.graphql";
import type { PanelistsReport_viewer$data } from "../../__generated__/PanelistsReport_viewer.graphql";
import type { PanelistFilterGroupGroup } from "../Filters";
import PanelTable from "../Recruit/PanelTable";

import PanelFilterGroupQuery from "./PanelFilterGroupQuery";
import { createPanelistFilterGroupGroup } from "./PanelFilters";
import PanelistModal from "./PanelistModal";

const { useToken } = theme;

function PanelistsReport({
  viewer,
  tenant,
  relay,
}: {
  viewer: PanelistsReport_viewer$data;
  tenant: PanelistsReport_tenant$data;
  relay: RelayPaginationProp;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<PanelistFilterGroupGroup>(createPanelistFilterGroupGroup());
  const [applyingFilters, setApplyingFilters] = useState(false);
  const [selectedPanelist, setSelectedPanelist] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setData(chunk(viewer.user?.profile?.tenant?.panelists?.edges, DEFAULT_PANELISTS_FETCH_COUNT));
  }, [viewer.user]);

  const refetch = (refetchVariables: { panelFilters: typeof appliedFilters; searchTerm?: string; cursor?: string }) => {
    setApplyingFilters(true);
    relay.refetchConnection(
      DEFAULT_PANELISTS_FETCH_COUNT,
      err => {
        setApplyingFilters(false);
        if (err) {
          return console.error(err);
        }
      },
      refetchVariables
    );
  };

  const applyFilters: Parameters<typeof PanelFilterGroupQuery>[0]["onApplyFilters"] = filters => {
    setAppliedFilters(filters);
    setPage(1);
    refetch({ panelFilters: filters, searchTerm });
  };

  const applySearch = (searchValue: string) => {
    setSearchTerm(searchValue);
    setPage(1);
    refetch({ panelFilters: appliedFilters, searchTerm: searchValue });
  };

  const getFirstRecordIndex = (pageNum: number) => {
    return pageNum * DEFAULT_PANELISTS_FETCH_COUNT - DEFAULT_PANELISTS_FETCH_COUNT + 1;
  };

  /**
   * changePage checks if we already have the data required and update state (data) which updates the table.
   * If we don't already have the data, we send a relay request to get the next set of records, if more exist.
   *
   * @param page
   */
  const changePage = (page: number) => {
    const shouldFetch = (page: number): boolean =>
      relay.hasMore() &&
      viewer!.user!.profile!.tenant!.panelists!.edges.length <
        getFirstRecordIndex(page) + DEFAULT_PANELISTS_FETCH_COUNT - 1;

    if (shouldFetch(page)) {
      setLoading(true);
      relay.loadMore(DEFAULT_PANELISTS_FETCH_COUNT, (error: any) => {
        if (error) console.log(error);
        setPage(page);
        setLoading(false);
      });
    } else {
      setPage(page);
    }
  };

  const { token } = useToken();

  return (
    <StyledCard bodyStyle={{ padding: "0 0 16px" }} $token={token}>
      <div style={{ padding: 16 }}>
        <PanelFilterGroupQuery
          disableExport={!(viewer.user?.isStaff || viewer.user?.profile?.tenant?.enablePanelExport)}
          loading={applyingFilters}
          onApplyFilters={applyFilters}
          onApplySearch={applySearch}
          panelistsCount={viewer.user?.profile?.tenant?.panelists?.totalCount || 0}
        />
      </div>
      <PanelTable
        characteristics={[
          ...(viewer.user?.profile?.tenant?.globalCharacteristics?.edges || []),
          ...(viewer.user?.profile?.tenant?.characteristics?.edges || []),
        ]}
        data={data[page - 1]}
        leftAlign
        loading={loading}
        onPageChange={changePage}
        onRow={panelistEdge => {
          return {
            onClick: () => {
              setSelectedPanelist(panelistEdge.node);
              // !window.getSelection() detects if the user has selected text in the table when click-and-dragging
              setShowModal(!window?.getSelection()?.toString()?.length && true);
            },
          };
        }}
        page={page}
        totalCount={viewer?.user?.profile?.tenant?.panelists?.totalCount}
      />
      {!!selectedPanelist && (
        <PanelistModal
          viewer={viewer}
          tenant={tenant}
          panelistId={selectedPanelist.id}
          visible={showModal}
          onCancel={({ refetch: _refetch }: { refetch?: boolean } = {}) => {
            setShowModal(false);
            setSelectedPanelist("");
            if (_refetch)
              refetch({
                panelFilters: appliedFilters,
                searchTerm,
                cursor:
                  page - 1
                    ? viewer?.user?.profile?.tenant?.panelists?.edges?.[(page - 1) * DEFAULT_PANELISTS_FETCH_COUNT - 1]
                        ?.cursor
                    : undefined,
              });
          }}
        />
      )}
    </StyledCard>
  );
}

const StyledCard = styled(Card)<{ $token: ReturnType<typeof useToken>["token"] }>`
  .ant-card-head-title {
    overflow: visible;
  }

  .ant-table-header.ant-table-sticky-holder {
    top: -${props => props.$token.paddingLG}px !important;
  }
`;

export default createPaginationContainer(
  PanelistsReport,
  {
    viewer: graphql`
      fragment PanelistsReport_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        panelFilters: { type: "GenericScalar" }
        searchTerm: { type: "String" }
      ) {
        ...PanelistModal_viewer
        user {
          isStaff
          profile {
            tenant {
              enablePanelExport
              characteristics {
                edges {
                  node {
                    id
                    importKey
                    shortName
                    text
                    type
                    answers {
                      id
                      text
                    }
                    rows {
                      id
                      text
                    }
                  }
                }
              }
              globalCharacteristics {
                edges {
                  node {
                    id
                    importKey
                    shortName
                    text
                    type
                    answers {
                      id
                      text
                    }
                    rows {
                      id
                      text
                    }
                  }
                }
              }
              # These variables need to come from the parent query
              panelists(first: $count, after: $cursor, panelFilters: $panelFilters, searchTerm: $searchTerm)
                @connection(key: "PanelistsReport_panelists") {
                totalCount
                edges {
                  cursor
                  node {
                    id
                    dId
                    completedStudiesCount
                    person {
                      firstName
                      lastName
                      email
                      phoneNumber
                      panelist {
                        id
                      }
                    }
                    panelistMembership {
                      latestCompleteScreenerName
                      latestCompleteScreenerDate
                      latestStudyName
                      latestStudyDate
                      latestRatedParticipant {
                        rating
                      }
                    }
                    rewardsBalance
                    joined
                    responses {
                      edges {
                        node {
                          characteristic {
                            importKey
                            id
                            text
                          }
                          answer {
                            id
                            other
                            userSpecified
                          }
                          row {
                            id
                            text
                          }
                          textValue
                          customAnswer
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    tenant: graphql`
      fragment PanelistsReport_tenant on TenantNode {
        ...PanelistModal_tenant
      }
    `,
  },
  {
    direction: "forward",
    getConnectionFromProps(props: any) {
      return props?.viewer?.user?.profile?.tenant?.panelists;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        panelFilters: fragmentVariables.panelFilters,
        searchTerm: fragmentVariables.searchTerm,
      };
    },
    // This is the pagination query that gets sent when relay.loadMore() is called.
    query: graphql`
      query PanelistsReportQuery($count: Int!, $cursor: String, $panelFilters: GenericScalar, $searchTerm: String) {
        viewer {
          user {
            profile {
              tenant {
                panelists(first: $count, after: $cursor, panelFilters: $panelFilters, searchTerm: $searchTerm)
                  @connection(key: "PanelistsReport_panelists") {
                  totalCount
                  edges {
                    cursor
                    node {
                      id
                      dId
                      completedStudiesCount
                      person {
                        firstName
                        lastName
                        email
                        phoneNumber
                        panelist {
                          id
                        }
                      }
                      panelistMembership {
                        latestCompleteScreenerName
                        latestCompleteScreenerDate
                        latestStudyName
                        latestStudyDate
                        latestRatedParticipant {
                          rating
                        }
                      }
                      rewardsBalance
                      joined
                      responses {
                        edges {
                          node {
                            characteristic {
                              importKey
                              id
                              text
                            }
                            answer {
                              id
                              other
                              userSpecified
                            }
                            row {
                              id
                              text
                            }
                            textValue
                            customAnswer
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  }
);
