/**
 * @generated SignedSource<<10e1006f0da94a3b0aaf97c9a15b9979>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputFilterGroup_screener$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilter_screener">;
  readonly " $fragmentType": "InputFilterGroup_screener";
};
export type InputFilterGroup_screener$key = {
  readonly " $data"?: InputFilterGroup_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroup_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilterGroup_screener",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilter_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "efe40eb50c2603c7c201e69fc5014e72";

export default node;
