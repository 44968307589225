import { Card } from "antd";
import { ReactElement } from "react";
import styled from "styled-components";
import { GRAY_8 } from "../../../style";
type PropsType = {
  title: string | ReactElement;
  value: string;
  postscript?: string;
  statisticColor?: string;
};

export const StatisticCard = (props: PropsType) => {
  return (
    <StyledCard color={props.statisticColor}>
      <div className="title">{props.title}</div>
      <div className="value">{props.value}</div>
      {props.postscript && <div className="postscript">{props.postscript}</div>}
    </StyledCard>
  );
};

const StyledCard = styled(Card)<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .value {
    font-size: 44px;
    text-align: center;
    color: ${props => props.color};
  }

  .postscript {
    font-size: 12px;
    text-align: center;
    color: ${GRAY_8};
  }
`;
