/**
 * @generated SignedSource<<9355337406da726bbac9a4b0bbdeed3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitTypePicker_recruit$data = {
  readonly canHaveVoxpopmeGlobal: boolean | null;
  readonly goal: number | null;
  readonly id: string;
  readonly status: ScreenersRecruitStatusChoices;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentType": "RecruitTypePicker_recruit";
};
export type RecruitTypePicker_recruit$key = {
  readonly " $data"?: RecruitTypePicker_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitTypePicker_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitTypePicker_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canHaveVoxpopmeGlobal",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "8f4add5b27ce5709214e09f3b6d37118";

export default node;
