import baselinePermContactCalendar from "@iconify-icons/ic/baseline-perm-contact-calendar";
import roundPeopleAlt from "@iconify-icons/ic/round-people-alt";
import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import moment, { Moment as MomentType } from "moment";
import React from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { Moment, TagTag } from "..";
import { ThemeColor } from "../../antd";
import { PROJECT_STATUSES, PROJECT_TYPES, studyTypeMap } from "../../constants";
import {
  BORDER_RADIUS,
  GRAY_2,
  GRAY_3,
  GRAY_5,
  GRAY_8,
  LIGHT_BG,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../../style";
import { useFlag } from "../../utils";
// baselineDoneAll is the icon for the completed icon, which we will re-add eventually
// import baselineDoneAll from '@iconify-icons/ic/baseline-done-all';
import { ProjectCard_study$data } from "../../__generated__/ProjectCard_study.graphql";

type Props = {
  study: ProjectCard_study$data;
};

const ProjectCard: React.FC<Props> = ({ study }) => {
  const isVoxpopme = useFlag("platform-hubux-template");

  const totalNeeded = study.fieldReport?.responseSummary?.totalNeeded?.total || 0;
  const qualified = study.fieldReport?.responseSummary?.totalStarts?.qualified || 0;
  const scheduled = study?.fieldReport?.responseSummary?.rsvpAccepted?.total || 0;

  const parseAvailabilitySlots = (slots: any) => {
    const sorted = slots
      .map((o: any) => o.node)
      .reduce((a: any, o: any) => [...a, o.start, o.end], [])
      .sort((left: MomentType, right: MomentType) => moment(left).diff(moment(right)));
    return {
      start: sorted[0],
    };
  };

  const { start } = parseAvailabilitySlots(study.availabilitySlots.edges);

  const getStudyDate = () => {
    if (study.type === "SS" && study.scheduled) {
      return <Moment format="MMM D">{study.scheduled as MomentType}</Moment>;
    } else if (start) {
      return <Moment format="MMM D">{start}</Moment>;
    }
  };

  const studyDate = getStudyDate();
  const studyDId = study?.dId as string;
  const defaultLinkView = () => {
    if (study.status === PROJECT_STATUSES.DRAFT) {
      if (study.type === PROJECT_TYPES.SURVEY) {
        return "screener";
      } else {
        return "slots";
      }
    } else {
      return "overview";
    }
  };

  return (
    <StyledProjectCard $isVoxpopme={isVoxpopme}>
      <Link to={`/projects/${study.id}/${defaultLinkView()}`}>
        <div className="study">
          <div className="study--details">
            <span>
              {`HUB-${studyDId.slice(0, 5).toUpperCase()}`} • {studyTypeMap[study.type]}
              {studyDate && " • "}
              {studyDate}
            </span>
            <Tooltip title={study?.owner?.fullName || study?.owner?.email}>
              <span className="study--owner">
                <Icon icon="mdi:account" width="20" />{" "}
                {!!study?.owner?.fullName && (
                  <span>
                    {study.owner.fullName
                      ?.split(" ")
                      ?.map(x => x.charAt(0))
                      ?.join("")}
                  </span>
                )}
              </span>
            </Tooltip>
          </div>
          <div className="title">{study.name || "Unnamed"}</div>
          {study.status !== PROJECT_STATUSES.DRAFT && (
            <div className="study--stats">
              <div className="study--stats--stat">
                <StyledIcon height="18" icon={roundPeopleAlt} />
                {qualified} <span className="grey">&nbsp;/&nbsp;</span> <span className="green">{totalNeeded}</span>
              </div>
              {(studyTypeMap[study.type] === studyTypeMap.OO || studyTypeMap[study.type] === studyTypeMap.SS) && (
                <div className="study--stats--stat">
                  <StyledIcon height="18" icon={baselinePermContactCalendar} />
                  {scheduled}
                </div>
              )}
            </div>
          )}
        </div>
        {!!study.tags.length && (
          <ul className="hub-project-card-tags">
            {study.tags.map(x => (
              <TagTag key={x.id} tagKey={x} />
            ))}
          </ul>
        )}
      </Link>
    </StyledProjectCard>
  );
};

export default createFragmentContainer(ProjectCard, {
  study: graphql`
    fragment ProjectCard_study on StudyNode {
      id
      dId
      name
      tags {
        id
        ...TagTag_tag
      }
      status
      type
      availabilitySlots {
        edges {
          node {
            start
            end
          }
        }
      }
      scheduled
      owner {
        dId
        fullName
        email
      }
      fieldReport {
        responseSummary {
          totalNeeded {
            total
          }
          totalStarts {
            total
            qualified
          }
          rsvpAccepted {
            total
          }
        }
      }
    }
  `,
});

const StyledProjectCard = styled.div<{ $isVoxpopme: boolean }>`
  background-color: ${LIGHT_BG};
  border: 1px solid ${props => (props.$isVoxpopme ? ThemeColor.VoxStroke : GRAY_2)};
  border-radius: ${BORDER_RADIUS};
  letter-spacing: -0.2px;
  transition: box-shadow 0.3s;
  margin-bottom: 24px;

  :hover {
    background-color: ${GRAY_2};
  }

  > a {
    display: block;
    padding: 16px;
  }

  .grey {
    color: ${GRAY_8};
  }

  .green {
    color: var(--ant-success-color);
  }

  .title {
    width: 220px;
    font-size: 16px;
    padding: 2px 0px 4px 0px;
    line-height: 20px;
    font-weight: 500;
  }

  .study {
    color: ${props => (props.$isVoxpopme ? "black" : PRIMARY_TEXT_COLOR)};

    &--details {
      font-size: 13px;
      line-height: 20px;
      color: ${props => (props.$isVoxpopme ? ThemeColor.VoxGray : SECONDARY_TEXT_COLOR)};
      display: flex;
      justify-content: space-between;
    }
    &--stats {
      display: flex;
      font-size: 14px;
      color: var(--ant-info-color);
      margin-top: 8px;
      &--stat {
        display: flex;
        align-items: center;
        margin-right: 18px;
      }
    }
    &--avatar {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-top: 6px;
      background-color: ${GRAY_3};
    }
    &--owner {
      display: inline-flex;
      gap: 2px;
    }
  }

  .hub-project-card-tags {
    padding: 0;
    margin: 16px 0 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    > * {
      flex-shrink: 0;
    }
  }
`;

const StyledIcon = styled(Icon)`
  display: block;
  color: ${GRAY_5};
  margin-right: 6px;
`;
