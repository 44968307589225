import { SpinSize } from "antd/es/spin";
import Spin from "antd/lib/spin";
import React from "react";
import styled from "styled-components";

type Props = {
  size?: SpinSize;
};
const Loading: React.FC<Props> = ({ size }) => (
  <StyledLoading>
    <Spin size={size} />
  </StyledLoading>
);

const StyledLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Loading;
