import { App as AntApp, ConfigProvider } from "antd";
import { useRouter } from "found";
import React, { useEffect } from "react";

import { AntStyle, applyNotificationConfig, useAntdConfig } from "../antd";
import { TimeZoneContext, useTimeZoneContextValue } from "../utils";
import { update as intercomUpdate } from "../utils/intercom";

applyNotificationConfig();

const App: React.FC = ({ children }) => {
  // Update Intercom whenever the URL changes
  const { router } = useRouter();
  useEffect(
    () =>
      router.addNavigationListener(() => {
        intercomUpdate();
        // pass through nav events to subsequent listeners
        return null;
      }),
    [router]
  );

  const configProviderProps = useAntdConfig({ root: true });

  return (
    <ConfigProvider {...configProviderProps}>
      <AntApp>
        <TimeZoneContext.Provider value={useTimeZoneContextValue()}>{children}</TimeZoneContext.Provider>
      </AntApp>
      <AntStyle />
    </ConfigProvider>
  );
};

export default App;
