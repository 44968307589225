import { LinkOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import React, { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ImgThemed } from "..";
import { BORDER_RADIUS, CONTAINER_BOX_SHADOW, LIGHT_BG, LINK_TEXT_COLOR } from "../../style";
import { mutation } from "../../utils";
import type { LaunchedPage_LaunchStudy_Mutation } from "../../__generated__/LaunchedPage_LaunchStudy_Mutation.graphql";
import type { LaunchedPage_study$data } from "../../__generated__/LaunchedPage_study.graphql";

type Props = {
  study: LaunchedPage_study$data;
};
const LaunchedPage: React.FC<Props> = ({ study }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const launchStudy = () =>
    mutation<LaunchedPage_LaunchStudy_Mutation>({
      variables: {
        input: {
          studyId: study?.id || "",
        },
      },
      mutation: graphql`
        mutation LaunchedPage_LaunchStudy_Mutation($input: LaunchStudyInput!) {
          launchStudy(input: $input) {
            study {
              name
            }
          }
        }
      `,
    });

  const wrappedLaunchStudy = useCallback(launchStudy, [study?.id]);

  useEffect(() => {
    if (study.status !== "LV") {
      wrappedLaunchStudy();
    }
  }, [study.status, wrappedLaunchStudy]);

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  return (
    <StyledLaunchPage>
      <ImgThemed aria-hidden src="success.svg" />
      <div className="title">
        Great! <span className="study">{study.name}</span> is now launched and ready to start screening participants.
      </div>
      <div className="links">
        <div className="link">
          <div className="label">Screener Link:</div>
          <Input value={study.screenerLink || ""} />
          <Tooltip title={copied ? "Link copied" : "Click to copy"}>
            <CopyToClipboard onCopy={() => setCopied(true)} text={study.screenerLink || ""}>
              <LinkOutlined />
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
      <div className="actions">
        <Link to="/projects">
          <Button>Back to Projects</Button>
        </Link>
        <Link to={`/projects/${study.id}`}>
          <Button type="primary">Manage Project</Button>
        </Link>
      </div>
    </StyledLaunchPage>
  );
};

const StyledLaunchPage = styled.div`
  width: 800px;
  margin: auto;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 18px;
    .study {
      font-weight: bold;
    }
  }

  .links {
    margin-top: 24px;
    padding: 24px;
    width: 500px;
    background-color: ${LIGHT_BG};
    border-radius: ${BORDER_RADIUS};
    box-shadow: ${CONTAINER_BOX_SHADOW};

    .link {
      display: flex;
      align-items: center;

      .label {
        white-space: nowrap;
        margin-right: 12px;
      }

      .anticon {
        margin-left: 12px;
        font-size: 20px;
        color: ${LINK_TEXT_COLOR};
      }
    }
  }

  .actions {
    margin-top: 24px;
    .ant-btn-primary {
      margin-left: 12px;
    }
  }
`;

export default createFragmentContainer(LaunchedPage, {
  study: graphql`
    fragment LaunchedPage_study on StudyNode {
      id
      screenerLink
      name
      status
    }
  `,
});
