import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { Segment } from "..";
import { SAVING_STATES } from "../../constants";
import { GRAY_7, PAGE_WIDTH_LG } from "../../style";
import { mutation } from "../../utils";
import type { ProjectSegmentsPage_AddSegment_Mutation } from "../../__generated__/ProjectSegmentsPage_AddSegment_Mutation.graphql";
import type { ProjectSegmentsPage_screener$data } from "../../__generated__/ProjectSegmentsPage_screener.graphql";
import ProjectPageContent from "../ProjectPageContent";
import Saving, { SavingStateType } from "../Saving";

const { Text, Title } = Typography;

const ProjectSegmentsPage = ({ screener }: { screener: ProjectSegmentsPage_screener$data }) => {
  const [savingState, setSavingState] = useState<SavingStateType>("saved");

  const addSegment = async (screenerId: string) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ProjectSegmentsPage_AddSegment_Mutation>({
        variables: {
          input: {
            screenerId: screenerId || "",
          },
        },
        mutation: graphql`
          mutation ProjectSegmentsPage_AddSegment_Mutation($input: AddSegmentInput!) {
            addSegment(input: $input) {
              screener {
                segments {
                  edges {
                    node {
                      ...Segment_segment
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const add = () => {
    addSegment(screener.id);
  };

  return (
    <ProjectPageContent
      extra={
        <>
          <Saving savingState={savingState} style={{ position: "initial" }} type="text" />
        </>
      }
      style={{ padding: 0, maxHeight: "100%", overflowY: "auto" }}
      title={
        <>
          <Title level={4}>Segments, for your complex screeners</Title>
          <Text type="secondary">Segments are sets of rules for filtering and screening respondents.</Text>
        </>
      }
    >
      <Styled>
        {screener.segments.edges.map(
          o =>
            o?.node && <Segment key={o.node.id} segment={o.node} screener={screener} setSavingState={setSavingState} />
        )}
        <Button shape="circle" onClick={add} icon={<PlusOutlined />} />
      </Styled>
    </ProjectPageContent>
  );
};

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  width: ${PAGE_WIDTH_LG};
  max-width: 100%;
  padding: 24px 24px 48px;

  button {
    align-self: center;
  }

  .title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 15px;
    color: ${GRAY_7};
    margin-bottom: 48px;
  }
`;

export default createFragmentContainer(ProjectSegmentsPage, {
  screener: graphql`
    fragment ProjectSegmentsPage_screener on ScreenerNode {
      id
      segments {
        edges {
          node {
            id
            ...Segment_segment
          }
        }
      }
      ...Segment_screener
    }
  `,
});
