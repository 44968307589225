/**
 * @generated SignedSource<<549337293768275731b4bf1c5a4eecc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddStudyPanelRecruitsInput = {
  clientMutationId?: string | null;
  panelistIds: ReadonlyArray<string>;
  recruitId: string;
};
export type RecruitMatch_addStudyPanelRecruits_Mutation$variables = {
  input: AddStudyPanelRecruitsInput;
};
export type RecruitMatch_addStudyPanelRecruits_Mutation$data = {
  readonly addStudyPanelRecruits: {
    readonly study: {
      readonly id: string;
    } | null;
  } | null;
};
export type RecruitMatch_addStudyPanelRecruits_Mutation = {
  response: RecruitMatch_addStudyPanelRecruits_Mutation$data;
  variables: RecruitMatch_addStudyPanelRecruits_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddStudyPanelRecruitsPayload",
    "kind": "LinkedField",
    "name": "addStudyPanelRecruits",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StudyNode",
        "kind": "LinkedField",
        "name": "study",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecruitMatch_addStudyPanelRecruits_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecruitMatch_addStudyPanelRecruits_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0251c8c3d3243d3dcefca3d272dc45c3",
    "id": null,
    "metadata": {},
    "name": "RecruitMatch_addStudyPanelRecruits_Mutation",
    "operationKind": "mutation",
    "text": "mutation RecruitMatch_addStudyPanelRecruits_Mutation(\n  $input: AddStudyPanelRecruitsInput!\n) {\n  addStudyPanelRecruits(input: $input) {\n    study {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ca2eede055a8064ca1139bf7d808987";

export default node;
