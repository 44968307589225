import { Form, Input, Typography } from "antd";
import styled from "styled-components";
import { DetailsInput } from "../Configure/DetailsInput";

type Props = {
  replyToRequired?: boolean;
  replyToPlaceholder?: string;
  subjectPlaceholder?: string;
  messagePlaceholder?: string;
  minMaxMessageRows?: [number, number];
  supportedMessageVariables?: string[];
};
export const EmailInputs = (
  {
    replyToRequired = false,
    replyToPlaceholder,
    subjectPlaceholder,
    messagePlaceholder,
    minMaxMessageRows,
    supportedMessageVariables,
  }: Props = {
    supportedMessageVariables: [],
  }
) => (
  <StyledEmailInputs>
    <DetailsInput
      title="Reply to"
      inputs={
        <Form.Item
          name="replyTo"
          rules={[
            ...(replyToRequired ? [{ required: true, message: "Please provide a reply to address" }] : []),
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input type="email" maxLength={254} placeholder={replyToPlaceholder ?? "moderator@email.com"} />
        </Form.Item>
      }
    />
    <DetailsInput
      title="Subject"
      inputs={
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: "Please provide a subject",
            },
          ]}
        >
          <Input maxLength={150} placeholder={subjectPlaceholder ?? "Enter the subject of your message"} />
        </Form.Item>
      }
    />
    <DetailsInput
      title="Message"
      description={
        !!supportedMessageVariables?.length && (
          <>
            You can include data about each recipient using these variables:
            <br />
            <Typography.Text>
              {supportedMessageVariables.map((variable, i) => (
                <Typography.Text key={i} style={{ whiteSpace: "nowrap" }} code>
                  {variable}
                </Typography.Text>
              ))}
            </Typography.Text>
            <br />
            <a
              href="https://intercom.help/hubux/en/articles/5983209-how-to-use-variables-in-communications"
              target="_blank"
              rel="noreferrer noopener"
            >
              How to use variables?
            </a>
          </>
        )
      }
      inputs={
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: "Please provide a message",
            },
          ]}
        >
          <Input.TextArea
            placeholder={messagePlaceholder ?? "Enter the message you want to send to your respondents"}
            autoSize={{ minRows: minMaxMessageRows?.[0] ?? 4, maxRows: minMaxMessageRows?.[1] ?? 10 }}
          />
        </Form.Item>
      }
    />
  </StyledEmailInputs>
);

const StyledEmailInputs = styled.div`
  code {
    white-space: nowrap;
  }
`;
