import { Card, ConfigProvider, Tabs } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { ConfigProviderAccent, useAntdConfig } from "../../../antd";

import { HEADER_FONT_SIZE, PAGE_WIDTH_LG, PAGE_WIDTH_MD, PAGE_WIDTH_SM } from "../../../style";
import { sentenceCase } from "../../../utils";
import { getFaviconEl } from "../../../utils/misc";
import type { PortalProfile_user$key } from "../../../__generated__/PortalProfile_user.graphql";
import { PortalFooter } from "../PortalFooter";
import { PortalPageLayout } from "../PortalPageLayout";
import { useCustomPortalSanitized } from "../utils";

import { FormPanelistResponses } from "./FormPanelistResponses";
import { FormPerson } from "./FormPerson";
import { FormPersonAvatar } from "./FormPersonAvatar";

const enum TabKey {
  Person = "P",
  Characteristics = "C",
}

const enum TouchedScope {
  FormPanelistResponses,
  FormPerson,
  FormPersonAvatar,
}

const enum TouchedScopesAction {
  Set,
  Unset,
}

export const PortalProfile = ({ user: userKey }: { user: PortalProfile_user$key }) => {
  const { panelist } = useFragment(
    graphql`
      fragment PortalProfile_user on UserNode {
        panelist {
          id
          customPanelistPortal {
            contactEmail
            customColor
            fadeBgBottom
            faviconUrl
            footerImageUrl
            headerMarkup
            headerMarkupEnabled
            heroAssetUrl
            heroBackgroundUrl
            hidePanelistProfile
            pageTitle
            privacyPolicyUrl
            termsLabel
            termsUrl
          }
          person {
            id
            avatarUrl
            firstName
            lastName
            ...FormPerson_person
            ...FormPersonAvatar_person
          }
          ...FormPanelistResponses_panelist
        }
      }
    `,
    userKey
  );
  const customPortal = panelist?.customPanelistPortal;
  const { contactEmail } = useCustomPortalSanitized(customPortal as any);

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) faviconEl.href = customPortal?.faviconUrl;

    document.title = `Edit profile | ${customPortal?.pageTitle ?? "HubUX"}`;
  }, [customPortal]);

  const [tabKey, setTabKey] = useState(TabKey.Person);

  const [touchedScopes, dispatchTouchedScopes] = useReducer(
    (
      _state: Set<TouchedScope>,
      { action, scope }: { action: TouchedScopesAction; scope: TouchedScope }
    ): typeof _state => {
      const state = new Set(Array.from(_state));

      switch (action) {
        case TouchedScopesAction.Set:
          state.add(scope);
          break;
        case TouchedScopesAction.Unset:
          state.delete(scope);
      }

      return state;
    },
    new Set<TouchedScope>()
  );

  const { t } = useTranslation();

  const configProviderProps = useAntdConfig();

  return panelist?.person ? (
    <StyledPortalPageLayout
      customColor={customPortal?.customColor}
      footer={
        <PortalFooter
          privacyPolicyLink={customPortal?.privacyPolicyUrl}
          termsLabel={customPortal?.termsLabel}
          termsUrl={customPortal?.termsUrl}
          customFooterUrl={customPortal?.footerImageUrl}
          contactEmail={contactEmail}
        />
      }
    >
      <Card className="hub-portal-profile-sidebar">
        {!!(panelist.person.firstName || panelist.person.lastName) && (
          <h2 className="hub-portal-profile-section-heading">
            {panelist.person.firstName} {panelist.person.lastName}
          </h2>
        )}
        <FormPersonAvatar
          onReset={() =>
            dispatchTouchedScopes({ action: TouchedScopesAction.Unset, scope: TouchedScope.FormPersonAvatar })
          }
          onTouch={() =>
            dispatchTouchedScopes({ action: TouchedScopesAction.Set, scope: TouchedScope.FormPersonAvatar })
          }
          person={panelist.person}
        />
      </Card>
      <Card>
        <h2 className="hub-portal-profile-section-heading">
          {sentenceCase(`${t("dictionary.verb.edit")} ${t("dictionary.noun.profile")}`)}
        </h2>
        <ConfigProviderAccent>
          <Tabs activeKey={tabKey} onChange={key => setTabKey(key as TabKey)}>
            <Tabs.TabPane
              disabled={Array.from(touchedScopes).some(x => x !== TouchedScope.FormPerson)}
              key={TabKey.Person}
              tab={sentenceCase(t("dictionary.noun.personal-info"))}
            >
              <ConfigProvider {...configProviderProps}>
                <FormPerson
                  onReset={() =>
                    dispatchTouchedScopes({ action: TouchedScopesAction.Unset, scope: TouchedScope.FormPerson })
                  }
                  onTouch={() =>
                    dispatchTouchedScopes({ action: TouchedScopesAction.Set, scope: TouchedScope.FormPerson })
                  }
                  person={panelist.person}
                />
              </ConfigProvider>
            </Tabs.TabPane>
            <Tabs.TabPane
              disabled={Array.from(touchedScopes).some(x => x !== TouchedScope.FormPanelistResponses)}
              key={TabKey.Characteristics}
              tab={sentenceCase(t("dictionary.noun.characteristics"))}
            >
              <ConfigProvider {...configProviderProps}>
                <p>
                  {t("portal-profile-complete.subtitle")} <strong>{t("portal-profile-complete.optional")}</strong>
                </p>
                <FormPanelistResponses
                  onReset={() =>
                    dispatchTouchedScopes({
                      action: TouchedScopesAction.Unset,
                      scope: TouchedScope.FormPanelistResponses,
                    })
                  }
                  onTouch={() =>
                    dispatchTouchedScopes({
                      action: TouchedScopesAction.Set,
                      scope: TouchedScope.FormPanelistResponses,
                    })
                  }
                  panelist={panelist}
                />
              </ConfigProvider>
            </Tabs.TabPane>
          </Tabs>
        </ConfigProviderAccent>
      </Card>
    </StyledPortalPageLayout>
  ) : null;
};

const StyledPortalPageLayout = styled(PortalPageLayout)`
  --portal-profile-space: 16px;

  @media (min-width: ${PAGE_WIDTH_SM}) {
    --portal-profile-space: 24px;
  }

  .hub-layout-children {
    display: grid;
    grid-template-columns: 1fr;
    padding: 24px var(--portal-profile-space) calc(2 * var(--portal-profile-space));
    gap: 24px;
    max-width: ${PAGE_WIDTH_LG};

    @media (min-width: ${PAGE_WIDTH_MD}) {
      grid-template-columns: 192px 1fr;
      align-content: center;
      align-items: start;
      margin: 0 auto;
    }

    > .ant-card {
      overflow: hidden;

      > .ant-card-body {
        background-color: white;
        border-radius: 2px;
        padding: var(--portal-profile-space) var(--portal-profile-space) 24px;
      }
    }
  }

  .hub-portal-profile-section-heading {
    font-size: ${HEADER_FONT_SIZE};
    font-weight: 500;
    hyphens: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
  }
`;
