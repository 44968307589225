/**
 * @generated SignedSource<<21b0ea8f632ac849186c1531fc45da5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActiveElementContent_screener$data = {
  readonly elements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly characteristic: {
          readonly importKey: string;
        } | null;
        readonly id: string;
      } | null;
    } | null>;
  } | null;
  readonly id: string;
  readonly study: {
    readonly status: ScreenersStudyStatusChoices;
    readonly tenant: {
      readonly characteristics: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly importKey: string;
            readonly shortName: string;
            readonly text: string | null;
            readonly type: ScreenersCharacteristicTypeChoices;
          } | null;
        } | null>;
      };
      readonly globalCharacteristics: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly importKey: string;
            readonly shortName: string;
            readonly text: string | null;
            readonly type: ScreenersCharacteristicTypeChoices;
          } | null;
        } | null>;
      } | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_screener" | "UsePreviousAnswers_screener">;
  readonly " $fragmentType": "ActiveElementContent_screener";
};
export type ActiveElementContent_screener$key = {
  readonly " $data"?: ActiveElementContent_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveElementContent_screener">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importKey",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CharacteristicNode",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveElementContent_screener",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementNodeConnection",
      "kind": "LinkedField",
      "name": "elements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ElementNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CharacteristicNode",
                  "kind": "LinkedField",
                  "name": "characteristic",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicNodeConnection",
              "kind": "LinkedField",
              "name": "characteristics",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CharacteristicNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantGlobalCharacteristicsConnection",
              "kind": "LinkedField",
              "name": "globalCharacteristics",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TenantGlobalCharacteristicsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilterGroupGroup_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UsePreviousAnswers_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};
})();

(node as any).hash = "f0b3db56ae36a5eee76c5b6cf6aacb79";

export default node;
