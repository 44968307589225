/**
 * @generated SignedSource<<11e100ac8c3eda0591abeb689cd8ba35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditInvitationEmailModal_recruit$data = {
  readonly id: string;
  readonly meta: any | null;
  readonly " $fragmentType": "EditInvitationEmailModal_recruit";
};
export type EditInvitationEmailModal_recruit$key = {
  readonly " $data"?: EditInvitationEmailModal_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditInvitationEmailModal_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditInvitationEmailModal_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meta",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "9ea3c3ea28a27acb3e5eb94e97143c57";

export default node;
