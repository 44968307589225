import { isEqualSimple } from "@react-hookz/deep-equal";
import { useUpdateEffect } from "@react-hookz/web";
import { Checkbox, Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useFragment } from "react-relay";
import { splitOnce } from "../../../utils";

import type { InputCharacteristicResponseMultiSelect_characteristic$key } from "../../../__generated__/InputCharacteristicResponseMultiSelect_characteristic.graphql";
import { InputCharacteristicResponseMultiSelect_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponseMultiSelect_characteristicResponses.graphql";

export const InputCharacteristicResponseMultiSelect = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  onChange,
  value: __value,
}: {
  characteristic: InputCharacteristicResponseMultiSelect_characteristic$key;
  characteristicResponses: InputCharacteristicResponseMultiSelect_characteristicResponses$key;
  onChange?: (value: string[]) => void;
  value?: string[];
}) => {
  const characteristic = useFragment(
    graphql`
      fragment InputCharacteristicResponseMultiSelect_characteristic on CharacteristicNode {
        id
        answers {
          id
          text
          userSpecified
        }
      }
    `,
    characteristicKey
  );
  const responses = useFragment(
    graphql`
      fragment InputCharacteristicResponseMultiSelect_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        id
        customAnswer
        answer {
          id
          userSpecified
        }
      }
    `,
    characteristicResponsesKey
  );

  const [_value, _customAnswer] = __value
    ? __value
        .map(x => splitOnce(x, "::"))
        .reduce((acc, [v, w]) => [[...acc[0], v!], acc[1] || w], [[] as string[], undefined as string | undefined])
    : [[], responses.find(x => x.answer?.userSpecified)?.customAnswer ?? undefined];

  const [customAnswer, setCustomAnswer] = useState(_customAnswer);
  useUpdateEffect(() => {
    if (_customAnswer !== customAnswer) setCustomAnswer(_customAnswer);
  }, [_customAnswer, setCustomAnswer]);

  const [value, setValue] = useState(_value);
  useUpdateEffect(() => {
    if (!isEqualSimple(_value, value)) setValue(_value);
  }, [JSON.stringify(_value), setValue]);

  useEffect(() => {
    if (value) {
      const newValue = value.map(x =>
        characteristic.answers.find(y => y.id === x)?.userSpecified && customAnswer ? `${x}::${customAnswer}` : x
      );
      if (!isEqualSimple(newValue, __value)) onChange?.(newValue);
    }
  }, [__value, characteristic.answers, customAnswer, onChange, value]);

  return (
    <>
      <Checkbox.Group
        style={{ display: "block" }}
        defaultValue={_value}
        onChange={_value => {
          const answers = characteristic.answers.filter(x => _value.includes(x.id));
          if (answers && !isEqualSimple(_value, value)) setValue(_value as string[]);
        }}
        value={value}
      >
        {characteristic.answers.map(x => (
          <Checkbox style={{ display: "flex", margin: "2px 0", marginInlineStart: 0 }} key={x.id} value={x.id}>
            <ReactMarkdown className="hub-md-inline" linkTarget="blank">
              {x.text}
            </ReactMarkdown>
          </Checkbox>
        ))}
      </Checkbox.Group>
      {!!characteristic.answers.find(x => value.includes(x.id))?.userSpecified && (
        <Input
          placeholder="Other"
          defaultValue={customAnswer}
          maxLength={512}
          onChange={e => setCustomAnswer(e.target.value)}
        />
      )}
    </>
  );
};
