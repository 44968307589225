import { InboxOutlined } from "@ant-design/icons";
import { App, Form, Typography, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { parse } from "papaparse";
import React, { useState } from "react";

import { getApiBaseUrl, validateEmail } from "../../utils/misc";

const { Dragger } = Upload;

type Props = {
  formItemLayout: {
    labelCol: {
      span: number;
    };
    wrapperCol: {
      span: number;
    };
  };
  name: string;
  label: string;
  toEmails: string[];
  checkValidity: (name: "subject" | "message" | "replyTo" | "to") => void;
};
const FormItemCSV: React.FC<Props> = ({ formItemLayout, name, label, toEmails, checkValidity }) => {
  const [emails, setEmails] = useState<Array<string>>(toEmails);

  return (
    <Form.Item
      {...formItemLayout}
      style={{ marginBottom: 12 }}
      name={name}
      label={label}
      help={
        emails.length > 0 ? (
          `Sending to ${emails.length} email addresses.`
        ) : (
          <span>
            Example CSV File:{" "}
            <a href={getApiBaseUrl() + "/static/examples/emails.csv"} target="_blank" rel="noopener noreferrer">
              emails.csv
            </a>
          </span>
        )
      }
      rules={[
        {
          required: true,
          message: "Please upload a file with less than 1000 email addresses.",
        },
      ]}
    >
      <WrappedCSVReader
        onChange={(emails: any) => {
          checkValidity("to");
          setEmails(emails);
        }}
      />
    </Form.Item>
  );
};

const WrappedCSVReader = React.forwardRef<any, any>((props, ref) => {
  const { message, modal } = App.useApp();

  const handleFileLoaded = (data: any[]) => {
    const emailColumnName = data[0]?.hasOwnProperty("person__email") ? "person__email" : "email";

    const validAddresses = new Array<string>();
    const invalidAddresses = new Array<string>();

    for (const email of data.map(d => d[emailColumnName]?.trim().toLowerCase())) {
      if (validateEmail(email)) {
        if (!validAddresses.includes(email)) {
          validAddresses.push(email);
        }
      } else if (!invalidAddresses.includes(email)) {
        invalidAddresses.push(email);
      }
    }

    // Mailgun currently limits us to send 1000 emails per API call
    // TODO: Handle files with more than 1000 email addresses
    if (validAddresses.length > 1000) {
      message.error("Please upload a file with less than 1000 email addresses.");
    } else {
      if (invalidAddresses.length > 0) {
        modal.warning({
          title: `${invalidAddresses.length} invalid email address${invalidAddresses.length > 1 ? "es" : ""}`,
          content: (
            <>
              <p>The CSV contained invalid email addresses that will not be imported:</p>
              <ul>
                {invalidAddresses.map(email => (
                  <li key={email}>
                    <Typography.Text keyboard>{email}</Typography.Text>
                  </li>
                ))}
              </ul>

              {validAddresses.length > 0 ? (
                <p>
                  {validAddresses.length} valid email address{validAddresses.length > 1 ? "es were" : " was"} found and
                  will be imported.
                </p>
              ) : (
                <p>No valid email addresses were found.</p>
              )}
            </>
          ),
        });
      }
      props.onChange(validAddresses);
    }
  };

  const parseCsv = (file: RcFile) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const result = parse(fileReader.result as string, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
      });

      handleFileLoaded(result.data);
    };

    fileReader.readAsText(file);
  };

  const beforeUpload = (file: RcFile): boolean => {
    parseCsv(file);

    // prevent the default HTTP upload
    return false;
  };

  return (
    <Dragger multiple={false} showUploadList={false} beforeUpload={beforeUpload} accept=".csv,text/csv">
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">Accepts .csv files only</p>
    </Dragger>
  );
});

export default FormItemCSV;
