import { LinkOutlined } from "@ant-design/icons";
import {
  App,
  Button,
  Card,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { ReactNode, useMemo, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { commitMutation, createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { InputTags, StickToBottom } from "..";
import { ThemeColor } from "../../antd";
import {
  IncentiveType,
  MIN_INCENTIVE_POINTS,
  MIN_INCENTIVE_USD,
  ProjectType,
  PROJECT_INCENTIVE_TYPES,
  PROJECT_STATUSES,
  PROJECT_TYPES,
  SCHEDULED_BY,
  SCHEDULING_TYPE,
  TERMINATE_RESPONDENT,
} from "../../constants";
import { environment } from "../../relay";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { GRAY_10, GRAY_6, GRAY_8, PAGE_WIDTH_MD } from "../../style";
import {
  getStudyContext,
  getTenantContext,
  getUserContext,
  handleErrorWithMessage,
  mutation,
  onError,
  trackEvent,
  useFlag,
} from "../../utils";
import { ignoreWheel, usdFormatter, usdFormatterNeat } from "../../utils/misc";
import type {
  ProjectDetailsPage_CreateStudy_Mutation,
  ProjectDetailsPage_CreateStudy_Mutation$data,
} from "../../__generated__/ProjectDetailsPage_CreateStudy_Mutation.graphql";
import type { ProjectDetailsPage_study$data } from "../../__generated__/ProjectDetailsPage_study.graphql";
import type { ProjectDetailsPage_tenant$data } from "../../__generated__/ProjectDetailsPage_tenant.graphql";
import type {
  ProjectDetailsPage_UpdateScreener_Mutation,
  UpdateScreenerInput,
} from "../../__generated__/ProjectDetailsPage_UpdateScreener_Mutation.graphql";
import type {
  ProjectDetailsPage_UpdateStudy_Mutation,
  UpdateStudyInput,
} from "../../__generated__/ProjectDetailsPage_UpdateStudy_Mutation.graphql";
import type { ProjectDetailsPage_viewer$data } from "../../__generated__/ProjectDetailsPage_viewer.graphql";

import { ProjectTypePicker } from "../index";

import { DetailsInput } from "./DetailsInput";
import { InputPoints } from "./InputPoints";

const { Panel } = Collapse;
const { Link: A } = Typography;

const studyFields = [
  "name",
  "namePublic",
  "tags",
  "type",
  "incentiveType",
  "defaultIncentive",
  "defaultIncentivePoints",
  "rewardDescription",
  "claimLink",
  "privacyPolicy",
  "languageCode",
  "usesApiToMarkComplete",
  "allowAutoApproval",
  "scheduledBy",
  "voxpopmeRecorderEnabled",
];

const screenerFields = ["terminateRespondent", "terminatedExitLink", "quotaExitLink", "overquotaExitLink"];

const OUTBOUND_EXIT_LINKS_ID = "outbound-exit-links";

const InboundExitLink = ({ label, link }: { label: string; link: string }) => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      <dt>{label}</dt>
      <dd>
        <span style={{ marginRight: 4 }}>{link}</span>
        <Tooltip title={copied ? "Link copied" : "Click to copy"}>
          <CopyToClipboard
            onCopy={() => {
              setCopied(true);
              window.setTimeout(() => setCopied(false), 2000);
            }}
            text={link}
          >
            <LinkOutlined />
          </CopyToClipboard>
        </Tooltip>
      </dd>
    </>
  );
};

const ProjectDetailsPage = ({
  isSettingsPage,
  languages,
  privacyPolicyOptions,
  study,
  tenant,
  title,
  viewer,
}: {
  isSettingsPage: boolean;
  languages: any[];
  privacyPolicyOptions: any[] | null;
  study: ProjectDetailsPage_study$data | null;
  tenant: ProjectDetailsPage_tenant$data | null;
  title?: ReactNode;
  viewer: ProjectDetailsPage_viewer$data;
}) => {
  const { message, notification } = App.useApp();

  const [form] = Form.useForm<
    Pick<
      UpdateStudyInput,
      | "name"
      | "namePublic"
      | "tags"
      | "type"
      | "incentiveType"
      | "defaultIncentive"
      | "defaultIncentivePoints"
      | "rewardDescription"
      | "claimLink"
      | "usesApiToMarkComplete"
      | "privacyPolicy"
      | "languageCode"
      | "allowAutoApproval"
      | "scheduledBy"
      | "voxpopmeRecorderEnabled"
    > &
      Pick<UpdateScreenerInput, "terminateRespondent" | "terminatedExitLink" | "quotaExitLink" | "overquotaExitLink">
  >();
  const [advancedSettingsStatus, setAdvancedSettingsStatus] = useState<string | string[]>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [projectType, setProjectType] = useState<ProjectType | null>((study?.type as ProjectType) || null);
  const [incentiveType, setIncentiveType] = useState<IncentiveType>(
    study?.incentiveType ?? PROJECT_INCENTIVE_TYPES.CASH
  );
  const [usesApiToMarkComplete, setUsesApiToMarkComplete] = useState(
    study?.usesApiToMarkComplete ?? tenant?.defaultSurveysToUseApi ?? false
  );
  const [isRecorderAvailable, setisRecorderAvailable] = useState(study?.voxpopmeRecorderEnabled ?? false);
  const exitLinkBaseUrl = useMemo(() => {
    const domain = study?.tenant.surveyDomain || "https://data.hubux.com";
    const url = new URL(domain);
    return `https://${url.hostname}/s/${study?.dId || "<study.id>"}/r/<respondent_id>/finish`;
  }, [study?.tenant.surveyDomain, study?.dId]);

  const { router } = useRouter();

  const requireNamePublic = useFlag("hub-project-name-public-required");
  const hasVPMGlobalPanel = useFlag("hub-vpm-gloabl-panel");

  const updateStudy = (input: UpdateStudyInput) =>
    new Promise((resolve, reject) => {
      commitMutation<ProjectDetailsPage_UpdateStudy_Mutation>(environment, {
        mutation: graphql`
          mutation ProjectDetailsPage_UpdateStudy_Mutation($input: UpdateStudyInput!) {
            updateStudy(input: $input) {
              study {
                ...ProjectDetailsPage_study
              }
            }
          }
        `,
        variables: { input },
        onCompleted: (response, errors) => {
          if (!errors?.[0]) return resolve(response);

          const x = errors[0].message || "Could not update study. Please try again later.";
          handleErrorWithMessage(x, x, message);
          reject(x);
        },
        onError: e => {
          onError(e, message);
          reject(e);
        },
      });
    });

  const createStudy = () =>
    new Promise<ProjectDetailsPage_CreateStudy_Mutation$data>((resolve, reject) => {
      const handleError = (err: any) => {
        handleErrorWithMessage(err, "Could not create study. Please try again later.");
        reject(err);
      };
      commitMutation<ProjectDetailsPage_CreateStudy_Mutation>(environment, {
        variables: { input: {} },
        mutation: graphql`
          mutation ProjectDetailsPage_CreateStudy_Mutation($input: CreateStudyInput!) {
            createStudy(input: $input) {
              study {
                id
                dId
                name
                namePublic
                type
                schedulingType
                status
                tenant {
                  dId
                  vpmAccountId
                  name
                }
              }
            }
          }
        `,
        onCompleted: (response, errors) => {
          if (errors) return handleError(errors);
          resolve(response);
        },
        onError: err => handleError(err),
      });
    });

  const updateScreener = (input: UpdateScreenerInput) =>
    // Used for updating fields on the Screener model
    mutation<ProjectDetailsPage_UpdateScreener_Mutation>({
      variables: {
        input: {
          ...input,
        },
      },
      mutation: graphql`
        mutation ProjectDetailsPage_UpdateScreener_Mutation($input: UpdateScreenerInput!) {
          updateScreener(input: $input) {
            screener {
              terminateRespondent
              terminatedExitLink
              quotaExitLink
              overquotaExitLink
            }
          }
        }
      `,
    });

  const notifyCreateStudySuccess = () => {
    const isFirstProject = viewer.studyCount === 0;

    let description: string;
    switch (projectType) {
      case PROJECT_TYPES.INTERVIEW:
        description = isFirstProject
          ? "Create slots and build the screener to start recruiting participants!"
          : "Set up slots and build the screener to start recruiting";
        break;

      case PROJECT_TYPES.FOCUS_GROUP:
        description = isFirstProject
          ? "Create events and build the screener to start recruiting participants!"
          : "Set up events and build the screener to start recruiting";
        break;

      case PROJECT_TYPES.SURVEY:
      default:
        description = isFirstProject
          ? "Build the screener to start recruiting participants!"
          : "Build the screener to start recruiting";
        break;
    }

    notification.success({
      message: isFirstProject ? "Yay! You created your first project!" : "You created a project",
      description,
    });
  };

  const submitForm = async (values: any) => {
    setLoading(true);
    let studyId = study?.id;
    let created = false;

    try {
      // Create a study if one does not exist
      if (study === null) {
        const response = await createStudy();
        created = true;
        notifyCreateStudySuccess();
        const study = response!.createStudy!.study!;
        studyId = study.id;

        trackEvent("Study Created", {
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
          ...getUserContext(viewer.user as any),
        });
      }

      const updateStudyInput: any = {
        studyId: studyId!,
      };
      const updateScreenerInput: any = {
        studyId: studyId!,
      };

      for (const property in values) {
        if (studyFields.includes(property)) {
          updateStudyInput[property] = values[property];
        } else if (screenerFields.includes(property)) {
          updateScreenerInput[property] = values[property];
        }
      }

      updateStudyInput["voxpopmeProjectId"] = tenant?.voxpopmeLiveDefaultProjectId;

      await updateStudy(updateStudyInput);
      if (Object.keys(updateScreenerInput).length > 1) {
        await updateScreener(updateScreenerInput);
      }
      if (!created) {
        message.success("Changes saved successfully");
      }

      setLoading(false);
      if (isSettingsPage) {
        if (study?.status === PROJECT_STATUSES.DRAFT && projectType === PROJECT_TYPES.SURVEY) {
          // Catch instances where a user was on the slots view and changed from Interview/Focus Group
          // to Survey. We can't send them back to slots, because it doesn't exist for studies
          router.push(`/projects/${studyId}/screener`);
        } else {
          router.go(-1);
        }
      } else {
        if (values.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL) {
          router.push(`/projects/${studyId}/incentive-email-settings`);
        } else if (values.type === PROJECT_TYPES.SURVEY) {
          router.push(`/projects/${studyId}/screener`);
        } else {
          // TODO: Change to Slots view
          router.push(`/projects/${studyId}/slots`);
        }
      }
    } catch {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (isSettingsPage && study) {
      router.go(-1);
    } else {
      router.push(`/projects`);
    }
  };

  const incentiveTypeUnavailable =
    incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL &&
    study?.recruits.edges.some(e => e?.node?.type === ScreenersRecruitTypeChoices.Hp);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Styled>
      <div ref={containerRef} style={{ overflow: "hidden" }}>
        <Card
          bodyStyle={{ paddingBottom: 0 }}
          headStyle={{ border: 0, textAlign: "center" }}
          style={{ height: "100%", maxWidth: PAGE_WIDTH_MD, overflowY: "auto" }}
          title={title}
        >
          <div className="inner">
            <Form
              layout="vertical"
              form={form}
              validateTrigger="onSubmit"
              onFinish={submitForm}
              initialValues={{
                name: study?.name,
                namePublic: study?.namePublic,
                tags: study?.tags ?? [],
                type: study?.type,
                incentiveType: study?.incentiveType ?? PROJECT_INCENTIVE_TYPES.CASH,
                defaultIncentive: study?.defaultIncentive ?? undefined,
                defaultIncentivePoints: study?.defaultIncentivePoints,
                voxpopmeRecorderEnabled: (study?.voxpopmeRecorderEnabled && isRecorderAvailable) ?? false,
                usesApiToMarkComplete,
                privacyPolicy: study?.privacyPolicy.languageCode ?? privacyPolicyOptions![0].languageCode,
                languageCode: study?.languageCode?.toLowerCase() ?? "en",
                allowAutoApproval: study?.allowAutoApproval ?? false,
                terminateRespondent: study?.screener?.terminateRespondent ?? TERMINATE_RESPONDENT.END_OF_SCREENER,
                scheduledBy: study?.scheduledBy ?? SCHEDULED_BY.PARTICIPANT,
                terminatedExitLink: study?.screener?.terminatedExitLink,
                quotaExitLink: study?.screener?.quotaExitLink,
                overquotaExitLink: study?.screener?.overquotaExitLink,
                rewardDescription: study?.externalIncentive?.rewardDescription,
                claimLink: study?.externalIncentive?.claimLink,
              }}
            >
              <DetailsInput
                title="Project name"
                inputs={
                  <div className="hub-row">
                    <DetailsInput
                      collapseBottomMargin
                      title="Internal name"
                      titleStyle={{ fontWeight: 400 }}
                      description="This name is what you'll see in here and in data exports, and is not visible to participants"
                      inputs={
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please provide a name",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Input.TextArea
                            autoSize
                            size="large"
                            placeholder="Internal name"
                            onPressEnter={e => e.preventDefault()}
                          />
                        </Form.Item>
                      }
                    />
                    <DetailsInput
                      collapseBottomMargin
                      title="Public name"
                      titleStyle={{ fontWeight: 400 }}
                      optional={!requireNamePublic}
                      description="This name may be shown to participants in the subject line of screener invites, in the panelist portal, and is available to use in email templates"
                      inputs={
                        <Form.Item
                          name="namePublic"
                          rules={[
                            {
                              required: requireNamePublic,
                              message: "Please provide a name",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Input.TextArea
                            autoSize
                            placeholder="Public name"
                            size="large"
                            onPressEnter={e => e.preventDefault()}
                          />
                        </Form.Item>
                      }
                    />
                  </div>
                }
              />

              {!!tenant && (
                <DetailsInput
                  optional
                  title="Project Tags"
                  inputs={
                    <Form.Item name="tags">
                      <InputTags tagsKey={tenant} />
                    </Form.Item>
                  }
                />
              )}

              <Form.Item name="type">
                <ProjectTypePicker
                  disabled={study?.status === PROJECT_STATUSES.LIVE}
                  onChange={(v: any) => {
                    setProjectType(v);
                  }}
                />
              </Form.Item>

              {projectType && (
                <>
                  <Divider />

                  {projectType === PROJECT_TYPES.SURVEY && (
                    <DetailsInput
                      title="Survey link"
                      optional
                      inputs={
                        <Form.Item
                          name="quotaExitLink"
                          rules={[
                            {
                              type: "url",
                              message: "Please enter a URL starting with https:// or http://",
                            },
                          ]}
                          normalize={value => value.trim()}
                          style={{ marginBottom: 4 }}
                        >
                          <Input size="large" placeholder="Insert URL here in format https://domain.com/..." />
                        </Form.Item>
                      }
                      description="Insert the link to the third party survey. You can include %respondent.id% and %study.id% variables."
                    />
                  )}

                  <div className="incentive-container">
                    <DetailsInput
                      title="Incentive method"
                      description={<p>Payment method to participants</p>}
                      collapseBottomMargin
                      inputs={
                        <Form.Item
                          name="incentiveType"
                          className="medium-input"
                          rules={[
                            {
                              message: "Cannot use external incentives with a HubUX Panel recruiting round",
                              validator: () => (incentiveTypeUnavailable ? Promise.reject() : Promise.resolve()),
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            onChange={value => setIncentiveType(value)}
                            disabled={study ? study?.status !== PROJECT_STATUSES.DRAFT : false}
                            className="incentiveType-select"
                          >
                            <Select.Option value={PROJECT_INCENTIVE_TYPES.CASH}>Cash</Select.Option>
                            <Select.Option value={PROJECT_INCENTIVE_TYPES.POINTS}>Points</Select.Option>
                            {tenant?.allowExternalIncentive && (
                              <Select.Option value={PROJECT_INCENTIVE_TYPES.EXTERNAL}>External incentive</Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                      }
                    />

                    {incentiveType === PROJECT_INCENTIVE_TYPES.CASH ? (
                      <DetailsInput
                        title="Default incentive amount"
                        optional
                        description="USD incentive for participants"
                        collapseBottomMargin
                        inputs={
                          <Form.Item
                            name="defaultIncentive"
                            style={{ marginBottom: 0 }}
                            rules={[
                              {
                                validator(_, value) {
                                  // We're using HubUX Panel here as we don't know the recruit type at this stage
                                  if (
                                    typeof value === "string" ||
                                    (typeof value === "undefined" &&
                                      (!value ||
                                        Number(value) === 0 ||
                                        value >= MIN_INCENTIVE_USD[ScreenersRecruitTypeChoices.Hp]))
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      new Error(
                                        `${usdFormatterNeat.format(
                                          MIN_INCENTIVE_USD[ScreenersRecruitTypeChoices.Hp]
                                        )} minimum or 0`
                                      )
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            <InputNumber
                              className="medium-input"
                              size="large"
                              type="number"
                              min={0}
                              precision={2}
                              onWheel={ignoreWheel}
                              stringMode
                            />
                          </Form.Item>
                        }
                      />
                    ) : incentiveType === PROJECT_INCENTIVE_TYPES.POINTS ? (
                      <Form.Item
                        name="defaultIncentivePoints"
                        style={{ marginBottom: 0 }}
                        rules={[
                          {
                            validator(_, value) {
                              // We're using HubUX Panel here as we don't know the recruit type at this stage
                              if (!value || value >= MIN_INCENTIVE_POINTS[ScreenersRecruitTypeChoices.Hp]) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  new Error(`${MIN_INCENTIVE_POINTS[ScreenersRecruitTypeChoices.Hp]}pts minimum or 0`)
                                );
                              }
                            },
                          },
                          {
                            validator(_, value) {
                              if (!value || Number.isInteger(Number(value))) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(new Error("Points must be a whole number"));
                              }
                            },
                          },
                        ]}
                      >
                        <InputPoints
                          title="Incentive amount"
                          optional
                          showCaptions
                          form={form}
                          pointsFieldName="defaultIncentivePoints"
                          defaultPoints={study?.defaultIncentivePoints ?? undefined}
                        />
                      </Form.Item>
                    ) : incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL ? (
                      <DetailsInput
                        title="Fee per participant"
                        description="Fixed amount per approved participant"
                        collapseBottomMargin
                        inputs={
                          <Form.Item style={{ marginBottom: 0 }}>
                            <InputNumber
                              disabled
                              value={usdFormatter.format(tenant!.externalIncentivePerParticipantCostUsdCents / 100)}
                              className="medium-input"
                              size="large"
                            />
                          </Form.Item>
                        }
                      />
                    ) : null}
                  </div>
                  {[PROJECT_INCENTIVE_TYPES.CASH, PROJECT_INCENTIVE_TYPES.POINTS].includes(incentiveType) ? (
                    <p className="incentives-description">
                      Participants will be able to redeem the points in different methods like gift cards and Visa
                      cards. If you select "cash" participants will receive a virtual Visa card in their email once you
                      incentivize them.
                    </p>
                  ) : incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL ? (
                    <>
                      {hasVPMGlobalPanel && (
                        <p className="incentives-description warning">
                          *Note: Voxpopme Global recruiting rounds do not work with external incentives
                        </p>
                      )}
                      <div className="incentive-container">
                        <DetailsInput
                          title="Incentive name"
                          collapseBottomMargin
                          inputs={
                            <Form.Item
                              name="rewardDescription"
                              className="medium-input"
                              rules={[
                                {
                                  required: true,
                                  message: "Please provide a description",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                defaultValue={study?.externalIncentive?.rewardDescription ?? undefined}
                                placeholder="What kind of reward are you offering?"
                                maxLength={50}
                              />
                            </Form.Item>
                          }
                        />
                        <DetailsInput
                          title="Link to claim incentive"
                          optional
                          collapseBottomMargin
                          inputs={
                            <Form.Item
                              name="claimLink"
                              className="medium-input"
                              rules={[
                                {
                                  type: "url",
                                  message: "Please enter a URL starting with https:// or http://",
                                },
                              ]}
                              normalize={value => value.trim()}
                              validateTrigger={["onKeyUp", "onBlur"]}
                            >
                              <Input
                                size="large"
                                defaultValue={study?.externalIncentive?.claimLink ?? undefined}
                                placeholder="Link"
                                maxLength={200}
                              />
                            </Form.Item>
                          }
                        />
                      </div>
                      <p className="incentives-description">
                        When you incentivize participants, they'll receive an email, optionally with a link to claim the
                        incentive. You'll be asked to customize this email in the next step.
                      </p>
                    </>
                  ) : null}
                  {projectType !== PROJECT_TYPES.SURVEY && (
                    <div className="recorder-container">
                      <div className="form-conatiner">
                        <div className="icon-container">
                          <img alt="New Icon" className="new-icon" src="/vox/new.svg" />
                          <span className="new-label">New</span>
                          <span className="recorder-title">Voxpopme Recorder</span>
                        </div>
                        <Tooltip
                          title={
                            !tenant?.vpmAccountId
                              ? "To use this feature you must have a Voxpopme account."
                              : !tenant.voxpopmeLiveDefaultProjectId
                              ? "To use this feature you must have a Voxpopme project."
                              : !tenant?.vpmAccountId && !tenant.voxpopmeLiveDefaultProjectId
                              ? "To use this feature you must have a Voxpopme account and project."
                              : null
                          }
                        >
                          <Form.Item name="voxpopmeRecorderEnabled" valuePropName="checked">
                            <Switch
                              disabled={!tenant?.voxpopmeLiveDefaultProjectId || !tenant?.vpmAccountId}
                              onChange={val => setisRecorderAvailable(val)}
                            />
                          </Form.Item>
                        </Tooltip>
                      </div>
                      <p className="recorder-text">
                        Use Voxpopme Recorder to record interviews and focus groups. Interviews are auto-recorded and
                        imported into the Voxpopme platform for analysis.{" "}
                        <A
                          onClick={() =>
                            window.Intercom?.(
                              "showNewMessage",
                              "Hi there! I'd like to learn more about using Voxpopme Recorder."
                            )
                          }
                        >
                          Learn more
                        </A>
                      </p>
                    </div>
                  )}

                  <Collapse
                    ghost
                    expandIconPosition="end"
                    style={{ marginBottom: 32 }}
                    onChange={val => {
                      setAdvancedSettingsStatus(val);
                    }}
                  >
                    <Panel
                      className="advanced-settings-panel"
                      key="advanced"
                      header={
                        <div className="advanced-settings">
                          {advancedSettingsStatus.length ? "Hide" : "Show"} Advanced Configuration
                        </div>
                      }
                    >
                      {projectType === PROJECT_TYPES.SURVEY && (
                        <DetailsInput
                          title="Use return links or HubUX to mark participants complete"
                          description={
                            <>
                              <p>
                                If you are using a third party survey platform, you may want to use our{" "}
                                <a
                                  href="https://intercom.help/hubux/en/articles/5629043-how-to-configure-a-project"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  onClick={e => {
                                    e.preventDefault();
                                    window.Intercom?.("showArticle", 5629043);
                                  }}
                                >
                                  return links
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://intercom.help/hubux/en/articles/7035020-using-the-hubux-api"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  onClick={e => {
                                    e.preventDefault();
                                    window.Intercom?.("showArticle", 7035020);
                                  }}
                                >
                                  API
                                </a>{" "}
                                to notify us when participants have completed the survey. If you select this option you
                                will not be able to rate/incentivize participants until we receive a notification they
                                have completed the survey.
                              </p>
                              {usesApiToMarkComplete &&
                                (study ? (
                                  <section className="hub-incoming-exit-links">
                                    <h3>Return links:</h3>
                                    <p>
                                      Send participants to these URLs to immediately mark them complete. You can get{" "}
                                      <b>&lt;respondent_id&gt;</b> from{" "}
                                      <a
                                        href={`#${OUTBOUND_EXIT_LINKS_ID}`}
                                        onClick={e => {
                                          e.preventDefault();
                                          document.getElementById(OUTBOUND_EXIT_LINKS_ID)?.scrollIntoView({
                                            behavior: "smooth",
                                          });
                                        }}
                                      >
                                        end-of-survey redirects.
                                      </a>
                                    </p>
                                    <dl>
                                      <InboundExitLink label="Quota:" link={`${exitLinkBaseUrl}/q`} />
                                      <InboundExitLink label="Over quota:" link={`${exitLinkBaseUrl}/oq`} />
                                      <InboundExitLink label="Terminated:" link={`${exitLinkBaseUrl}/t`} />
                                    </dl>
                                  </section>
                                ) : (
                                  <p>
                                    Your return links will be available after your project is created, under{" "}
                                    <b>Project Details</b>.
                                  </p>
                                ))}
                            </>
                          }
                          inputStyle={{ marginBottom: 4 }}
                          inputs={
                            <Form.Item name="usesApiToMarkComplete" valuePropName="checked" style={{ marginBottom: 4 }}>
                              <Switch onChange={x => setUsesApiToMarkComplete(x)} />
                            </Form.Item>
                          }
                        />
                      )}
                      <DetailsInput
                        title="Privacy Policy Language"
                        description="We'll display the Privacy Policy in this language"
                        inputs={
                          <>
                            <Form.Item name="privacyPolicy" style={{ marginBottom: 4 }}>
                              <Select size="large">
                                {study
                                  ? study?.privacyPolicyOptions?.map(
                                      o =>
                                        o && (
                                          <Select.Option key={o.languageCode} value={o.languageCode}>
                                            {o.language}
                                          </Select.Option>
                                        )
                                    )
                                  : privacyPolicyOptions!.map(
                                      o =>
                                        o && (
                                          <Select.Option key={o.languageCode} value={o.languageCode}>
                                            {o.language}
                                          </Select.Option>
                                        )
                                    )}
                              </Select>
                            </Form.Item>
                          </>
                        }
                      />
                      <DetailsInput
                        title="Email Language"
                        description="Screener invitations and other communications about this project will be sent in this language"
                        inputs={
                          <>
                            <Form.Item name="languageCode" style={{ marginBottom: 4 }}>
                              <Select size="large">
                                {languages.map(lang => (
                                  <Select.Option key={lang.code} value={lang.code}>
                                    {lang.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        }
                      />
                      <DetailsInput
                        style={{ marginBottom: 24 }}
                        title="Participant approval"
                        inputStyle={{ marginBottom: 4 }}
                        inputs={
                          <Form.Item name="allowAutoApproval">
                            <Radio.Group>
                              <Radio value={false}>
                                <span className="bold">Approval required.</span>
                                <span className="sub-text">
                                  Each respondent needs to be reviewed before they're marked as a qualifying
                                  participant.
                                </span>
                              </Radio>
                              <Radio value={true}>
                                <span className="bold">Auto-approve.</span>
                                <span className="sub-text">
                                  If a respondent or panelist answers according to your criteria, they qualify right
                                  away.
                                </span>
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        }
                      />
                      <DetailsInput
                        style={{ marginBottom: 24 }}
                        title="Respondent Termination"
                        description={
                          <>
                            When would you like respondents terminated? Terminating at the end allows you to gather more
                            respondent information. When "Terminate as soon as applicable" is selected you will not be
                            able to view terminated respondents on the participants page.
                          </>
                        }
                        inputStyle={{ marginBottom: 4 }}
                        inputs={
                          <Form.Item name="terminateRespondent">
                            <Radio.Group>
                              <Radio value={TERMINATE_RESPONDENT.END_OF_SCREENER} style={radioStyle}>
                                Terminate at the end of the screener
                              </Radio>
                              <Radio value={TERMINATE_RESPONDENT.ASAP} style={radioStyle}>
                                Terminate as soon as applicable
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        }
                      />
                      {
                        /* TODO: This needs to be updated to support Interview projects too */
                        projectType === PROJECT_TYPES.FOCUS_GROUP && (
                          <DetailsInput
                            style={{ marginBottom: 24 }}
                            title="Schedule options"
                            description={
                              study?.schedulingType === SCHEDULING_TYPE.CALENDLY
                                ? "Projects using Calendly must be scheduled by the participant."
                                : "This setting cannot be changed once a project is active."
                            }
                            inputStyle={{ marginBottom: 4 }}
                            inputs={
                              <Form.Item name="scheduledBy">
                                <Radio.Group
                                  disabled={
                                    !!study &&
                                    (study.status !== PROJECT_STATUSES.DRAFT ||
                                      study.schedulingType === SCHEDULING_TYPE.CALENDLY)
                                  }
                                >
                                  <Radio value={SCHEDULED_BY.PARTICIPANT}>
                                    <span className="bold">Participants.</span>
                                    <span className="sub-text">They are able to select an available slot.</span>
                                  </Radio>
                                  <Radio value={SCHEDULED_BY.YOUR_TEAM}>
                                    <span className="bold">Your team.</span>
                                    <span className="sub-text">
                                      The participant can't select a slot; you will assign it to them.
                                    </span>
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            }
                          />
                        )
                      }
                      <DetailsInput
                        id={OUTBOUND_EXIT_LINKS_ID}
                        title="End of Survey Redirects"
                        optional
                        description="Insert URLs above to customize what respondents see after they've taken the screener. We support ID variables for respondent and study e.g. https://yourdomain.com?respondent=%respondent.id%&study=%study.id%"
                        inputStyle={{ marginBottom: 4 }}
                        inputs={
                          <>
                            <Form.Item
                              name="terminatedExitLink"
                              rules={[
                                {
                                  type: "url",
                                  message: "Please enter a URL starting with https:// or http://",
                                },
                              ]}
                              normalize={value => value.trim()}
                            >
                              <Input
                                placeholder="Insert URL here in format https://domain.com/..."
                                addonBefore="Terminated Participants:"
                                type="url"
                              />
                            </Form.Item>

                            {projectType !== PROJECT_TYPES.SURVEY && (
                              <Form.Item
                                name="quotaExitLink"
                                rules={[
                                  {
                                    type: "url",
                                    message: "Please enter a URL starting with https:// or http://",
                                  },
                                ]}
                                normalize={value => value.trim()}
                              >
                                <Input
                                  placeholder="Insert URL here in format https://domain.com/..."
                                  addonBefore="Qualified Participants:"
                                />
                              </Form.Item>
                            )}
                            <Form.Item
                              name="overquotaExitLink"
                              rules={[
                                {
                                  type: "url",
                                  message: "Please enter a URL starting with https:// or http://",
                                },
                              ]}
                              normalize={value => value.trim()}
                            >
                              <Input
                                placeholder="Insert URL here in format https://domain.com/..."
                                addonBefore="Overquota Participants:"
                              />
                            </Form.Item>
                          </>
                        }
                      />
                    </Panel>
                  </Collapse>
                </>
              )}
              <StickToBottom className="project-form-button-container" root={containerRef}>
                <Form.Item>
                  <Button className="project-form-button" onClick={handleCancel} size="large">
                    Cancel
                  </Button>
                  <Button
                    className="project-form-button"
                    disabled={!projectType}
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    type="primary"
                  >
                    {isSettingsPage ? "Save changes" : "Create project"}
                  </Button>
                </Form.Item>
              </StickToBottom>
            </Form>
          </div>
        </Card>
      </div>
    </Styled>
  );
};

const radioStyle = {
  display: "block",
  height: "20px",
  lineHeight: "20px",
};

const Styled = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: stretch;
  padding: 24px;

  .inner {
    .bold {
      font-weight: bold;
      color: ${GRAY_10};
    }

    .sub-text {
      color: ${GRAY_6};
    }

    .advanced-settings {
      color: var(--ant-primary-color);
      font-weight: 500;
    }
  }

  .medium-input {
    width: 100%;
    margin-bottom: 4px;
  }

  .incentive-container {
    display: flex;
    gap: 2rem;

    > * {
      flex: 1;
    }
  }

  .incentives-description {
    font-size: 11px;
    color: #b3b3b3;
    text-align: left;
    margin-bottom: 32px;
  }

  .project-form-button-container {
    display: flex;
    justify-content: end;
    background-color: white;
    padding: 16px;
    margin: 0 -24px;

    &.stuck {
      border-top: 1px solid ${ThemeColor.VoxStroke};
      margin-top: 24px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-control-input-content {
      display: flex;
      gap: 8px;
    }
    .project-form-button {
      font-weight: bold;
    }
  }

  .ant-collapse-header,
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-content {
    padding: 24px 0 !important;
  }

  .hub-incoming-exit-links {
    dl {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 8px;
    }
    dt {
      font-weight: bold;
    }
  }

  .hub-row {
    display: flex;
    gap: 16px;

    > * {
      flex: 1 1 0;
    }
  }

  .recorder-container {
    border: 1px solid ${ThemeColor.VoxStroke};
    border-radius: 6px;
    padding: 8px;
    margin: 16px 0;

    .form-conatiner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-form-item {
        margin-bottom: 0;
      }

      .new-label {
        margin-left: 6px;
        color: ${ThemeColor.VoxDodgerBlue};
      }

      .recorder-title {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .recorder-text {
    margin: 0px;
    margin-top: 4px;
    color: ${GRAY_8};
  }
`;

export default createFragmentContainer(ProjectDetailsPage, {
  study: graphql`
    fragment ProjectDetailsPage_study on StudyNode {
      id
      dId
      tenant {
        surveyDomain
      }
      type
      status
      name
      namePublic
      tags {
        id
        color
        name
      }
      defaultIncentive
      allowAutoApproval
      schedulingType
      scheduledBy
      privacyPolicy {
        language
        languageCode
      }
      privacyPolicyOptions {
        language
        languageCode
      }
      languageCode
      screener {
        terminateRespondent
        terminatedExitLink
        quotaExitLink
        overquotaExitLink
      }
      recruits {
        edges {
          node {
            type
          }
        }
      }
      incentiveType
      defaultIncentivePoints
      externalIncentive {
        rewardDescription
        claimLink
      }
      usesApiToMarkComplete
      voxpopmeRecorderEnabled
    }
  `,
  tenant: graphql`
    fragment ProjectDetailsPage_tenant on TenantNode {
      defaultSurveysToUseApi
      allowExternalIncentive
      externalIncentivePerParticipantCostUsdCents
      vpmAccountId
      voxpopmeLiveDefaultProjectId
      voxpopmeApiKey
      ...InputTags_tenant
    }
  `,
  viewer: graphql`
    fragment ProjectDetailsPage_viewer on Viewer {
      studyCount
      user {
        dId
        vpmUserId
        panelist {
          dId
        }
      }
    }
  `,
});
