/**
 * @generated SignedSource<<f3d98c3932096ed86596627800a7cec2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormPersonAvatar_person$data = {
  readonly avatarUrl: string | null;
  readonly firstName: string | null;
  readonly id: string;
  readonly lastName: string | null;
  readonly " $fragmentType": "FormPersonAvatar_person";
};
export type FormPersonAvatar_person$key = {
  readonly " $data"?: FormPersonAvatar_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormPersonAvatar_person">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormPersonAvatar_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "PersonNode",
  "abstractKey": null
};

(node as any).hash = "b3639cd53cdea031b7d00d5fdb30a41f";

export default node;
