import { Card } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { PRIMARY_TEXT_COLOR } from "../../../style";
import { ProjectFieldReport_study$data } from "../../../__generated__/ProjectFieldReport_study.graphql";
import { FieldReportQuestionTable, FieldReportSegmentTable, ResponseSummaryTable } from "../../index";
import { ExportFieldReportButton } from "./ExportFieldReportButton";

const ProjectFieldReport = ({ study }: { study: ProjectFieldReport_study$data }) => {
  return (
    <StyledProjectFieldReport>
      <header>
        <h2>Field Report</h2>
        <ExportFieldReportButton study={study} />
      </header>
      <TableContainer>
        <Card bordered bodyStyle={{ overflow: "hidden", padding: 0 }}>
          {study.fieldReport?.responseSummary && (
            <ResponseSummaryTable responseSummary={study.fieldReport.responseSummary} />
          )}
        </Card>
        {study.fieldReport?.elements && (
          <Card bordered bodyStyle={{ overflow: "hidden", padding: 0 }} title="Questions">
            {study.fieldReport.elements
              .filter(o => o?.type !== "CM")
              .map((o, i) => o && <FieldReportQuestionTable key={i} element={o} />)}
          </Card>
        )}
        {study.fieldReport?.segments && (
          <Card bordered bodyStyle={{ overflow: "hidden", padding: 0 }} title="Segments">
            <FieldReportSegmentTable segments={study.fieldReport.segments} />
          </Card>
        )}
      </TableContainer>
    </StyledProjectFieldReport>
  );
};

const StyledProjectFieldReport = styled.div`
  height: 100%;
  width: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 24px;
  }
`;

const TableContainer = styled.div`
  position: relative;
  height: calc(100% - 80px);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 48px;
  text-align: initial;

  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: ${PRIMARY_TEXT_COLOR};
    margin-left: 8px;
  }
`;

export default createFragmentContainer(ProjectFieldReport, {
  study: graphql`
    fragment ProjectFieldReport_study on StudyNode {
      dId
      tenant {
        name
      }
      fieldReport {
        responseSummary {
          ...ResponseSummaryTable_responseSummary
        }
        elements {
          type
          ...FieldReportQuestionTable_element
        }
        segments {
          ...FieldReportSegmentTable_segments
        }
      }
      ...ExportFieldReportButton_study
    }
  `,
});
