/**
 * @generated SignedSource<<46d7ab5579bcffac1517aeea4af5a396>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InactiveElementContent_element$data = {
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly text: string | null;
      } | null;
    } | null>;
  };
  readonly label: string | null;
  readonly position: number;
  readonly rows: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly text: string;
      } | null;
    } | null>;
  };
  readonly showIfCondition: any | null;
  readonly text: string | null;
  readonly type: ScreenersElementTypeChoices;
  readonly usePreviousAnswers: any | null;
  readonly " $fragmentType": "InactiveElementContent_element";
};
export type InactiveElementContent_element$key = {
  readonly " $data"?: InactiveElementContent_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"InactiveElementContent_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InactiveElementContent_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usePreviousAnswers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AnswerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RowNodeConnection",
      "kind": "LinkedField",
      "name": "rows",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RowNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showIfCondition",
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "69bf435fcae4b50984f68860269479c6";

export default node;
