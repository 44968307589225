/**
 * @generated SignedSource<<6ddc8c40151d96891c0f1ed1781039b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormItemCharacteristicResponses_characteristicResponses$data = ReadonlyArray<{
  readonly answer: {
    readonly id: string;
  } | null;
  readonly customAnswer: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponses_characteristicResponses">;
  readonly " $fragmentType": "FormItemCharacteristicResponses_characteristicResponses";
}>;
export type FormItemCharacteristicResponses_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: FormItemCharacteristicResponses_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormItemCharacteristicResponses_characteristicResponses">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FormItemCharacteristicResponses_characteristicResponses",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputCharacteristicResponses_characteristicResponses"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicAnswerNode",
      "kind": "LinkedField",
      "name": "answer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAnswer",
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};

(node as any).hash = "fabe768f9f28215162190606e58cbafe";

export default node;
