import { useUpdateEffect } from "@react-hookz/web";
import { App, Form, Input, Modal } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useState } from "react";
import { handleErrorWithMessage, onError, useMutation } from "../utils";
import { downloadInBackgroundNoFile } from "../utils/misc";
import { QuestionIdentModal_CreateExport_Mutation } from "../__generated__/QuestionIdentModal_CreateExport_Mutation.graphql";
import { notifyExport } from "./Notifications/Notification";

export const QuestionIdentModal = ({
  visible,
  immediateUrl,
  onCloseChange,
  filters = {},
  relatedId,
}: {
  visible: boolean;
  immediateUrl: string;
  onCloseChange: (visible: boolean) => void;
  filters?: Record<string, any>;
  relatedId: string; // gid
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requestIsInFlight, setRequestIsInFlight] = useState(false);

  useUpdateEffect(() => {
    if (!visible) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [visible]);

  const handleCancel = () => {
    onCloseChange(false);
    setIsModalVisible(false);
    return;
  };
  const handleFinish = async () => {
    if (immediateUrl) {
      setRequestIsInFlight(true);
      try {
        await downloadInBackgroundNoFile(`${immediateUrl!}?vpm=true&qIdent=${qIdent}`);
        notifyExport(notification);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          if (e.response.status === 403) commit();
          else handleErrorWithMessage(e, `Something went wrong (HTTP error code ${e.response.status})`, message);
        } else onError(e, message);
      } finally {
        setRequestIsInFlight(false);
      }
    } else commit();
    handleCancel();
    return;
  };

  const { notification, message } = App.useApp();

  const [_commit, commitIsInFlight] = useMutation<QuestionIdentModal_CreateExport_Mutation>(graphql`
    mutation QuestionIdentModal_CreateExport_Mutation($input: CreateExportInput!) {
      createExport(input: $input) {
        export {
          ...ExportButton_export
          to {
            email
          }
        }
        panel {
          export {
            id
          }
        }
        recruit {
          export {
            id
          }
        }
        study {
          export {
            id
          }
        }
      }
    }
  `);

  let qIdent = "";

  const commit = useCallback(
    () =>
      _commit(
        { filters, id: relatedId, isVpm: true, qIdent: qIdent },
        {
          onCompleted: res =>
            notification.success({
              message: `Your export is processing and will be sent to ${res.createExport.export.to.email} when complete.`,
            }),
        }
      ),
    [_commit, filters, notification, relatedId, qIdent]
  );

  return (
    <Modal
      centered
      title="VPM Question Ident"
      open={isModalVisible}
      onOk={handleFinish}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <Form>
        <Form.Item label="Question">
          <Input
            onChange={data => {
              console.log("Form Input", data.target.value);
              qIdent = data.target.value;
            }}
            onPressEnter={handleFinish}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
