/**
 * @generated SignedSource<<c91c5345beb1c3deee50d29b32845935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TerminantsOverviewTable_element$data = {
  readonly gridAnswers: ReadonlyArray<{
    readonly answerId: string | null;
    readonly rowId: string | null;
    readonly terminate: boolean | null;
  } | null> | null;
  readonly label: string | null;
  readonly labeledText: string | null;
  readonly options: ReadonlyArray<{
    readonly qualified: number | null;
    readonly qualifiedTotal: number | null;
    readonly quota: string | null;
    readonly terminate: boolean | null;
    readonly text: string | null;
    readonly total: number | null;
  } | null> | null;
  readonly rows: ReadonlyArray<{
    readonly dbId: string;
    readonly options: ReadonlyArray<{
      readonly dbId: string;
      readonly terminate: boolean | null;
      readonly text: string | null;
      readonly total: number | null;
    } | null> | null;
    readonly text: string | null;
  } | null> | null;
  readonly text: string | null;
  readonly type: string | null;
  readonly " $fragmentType": "TerminantsOverviewTable_element";
};
export type TerminantsOverviewTable_element$key = {
  readonly " $data"?: TerminantsOverviewTable_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminantsOverviewTable_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminantsOverviewTable_element",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labeledText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportRowNode",
      "kind": "LinkedField",
      "name": "rows",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FieldReportRowOptionNode",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportOptionNode",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qualified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qualifiedTotal",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quota",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportGridAnswerNode",
      "kind": "LinkedField",
      "name": "gridAnswers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerId",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FieldReportElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "5cea66e642d5f6cec6f28affac961fee";

export default node;
