import { Icon } from "@iconify/react";
import { App, Button, Card, Divider, Form, Input, Popover, Select, Switch, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { uniqueId } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { CHARACTERISTIC_ELEMENT_TYPES } from "../../../constants";
import { FADED_TEXT_COLOR, PRIMARY_TEXT_COLOR } from "../../../style";
import type { Characteristic, CharacteristicAnswer, CharacteristicRow } from "../../../types";
import { getTenantContext, getUserContext, trackEvent, useConfirm, useResponseValidationForm } from "../../../utils";
import { EditCharacteristicActive_tenant$data } from "../../../__generated__/EditCharacteristicActive_tenant.graphql";
import { EditCharacteristicActive_user$data } from "../../../__generated__/EditCharacteristicActive_user.graphql";
import { DetailsInput } from "../../Configure/DetailsInput";
import { ResponseValidationFormItems } from "../../Element";
import { SavingStateType } from "../../Saving";
import { EditCharacteristicGrid } from "./EditCharacteristicGrid";
import { EditCharacteristicOpenEnded } from "./EditCharacteristicOpenEnded";
import { EditCharacteristicSelect } from "./EditCharacteristicSelect";
import { deleteCharacteristic, saveCharacteristic } from "./mutations";
import { CHARACTERISTIC_ELEMENTS } from "./types";

export const _EditCharacteristicActive = ({
  user,
  tenant,
  characteristic,
  isNew,
  duplicate,
  setHasUnsavedChanges,
  onCancel,
  handleNavigate,
  setSavingState,
}: {
  user: EditCharacteristicActive_user$data;
  tenant: EditCharacteristicActive_tenant$data;
  characteristic: Characteristic;
  isNew: boolean;
  duplicate: (characteristic: Characteristic) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  onCancel: () => void;
  handleNavigate: () => Promise<boolean>;
  setSavingState: (savingState: SavingStateType) => void;
}) => {
  const { message, notification } = App.useApp();

  const [form] = Form.useForm<Characteristic>();

  const [selectedType, setSelectedType] = useState<CHARACTERISTIC_ELEMENT_TYPES>(
    characteristic.type as CHARACTERISTIC_ELEMENT_TYPES
  );
  const [answers, setAnswers] = useState<CharacteristicAnswer[]>(characteristic.answers ?? []);
  const [rows, setRows] = useState<CharacteristicRow[]>(characteristic.rows ?? []);
  const [showToPanelists, setShowToPanelists] = useState(characteristic.showToPanelists ?? false);

  const [enableResponseValidation, setEnableResponseValidation] = useState<boolean>(
    (selectedType === CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT && (characteristic.elementMeta?.target ?? 0) > 0) ||
      (selectedType === CHARACTERISTIC_ELEMENT_TYPES.NUMBER && characteristic.elementMeta?.target != null)
  );
  const [, resetResponseValidation] = useResponseValidationForm({ form, formRootPath: "elementMeta" }, [selectedType]);

  // helper method to ensure "position" is populated and "other" answers come at the end
  const handleSetAnswers = useCallback(
    (answers: CharacteristicAnswer[]) => {
      setAnswers(
        // ensure "other" answers stay at the end
        [...answers.filter(answer => !answer.other), ...answers.filter(answer => answer.other)]
          // set "position" based on order of answers
          .map((answer, position) => ({
            ...answer,
            position,
          }))
      );
      setHasUnsavedChanges(true);
    },
    [setAnswers, setHasUnsavedChanges]
  );

  const handleSetRows = useCallback(
    (rows: CharacteristicRow[]) => {
      setRows(rows);
      setHasUnsavedChanges(true);
    },
    [setRows, setHasUnsavedChanges]
  );

  // preserve answers on question type switch, if applicable
  const handleChangeType = useCallback(
    (newType: CHARACTERISTIC_ELEMENT_TYPES) => {
      if (newType === selectedType) return;

      const sharedAnswerTypes = [CHARACTERISTIC_ELEMENT_TYPES.SINGLE_SELECT, CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT];
      if (sharedAnswerTypes.includes(selectedType) && sharedAnswerTypes.includes(newType)) {
        // don't clear answers or rows
      } else {
        // add a starter blank answer when switching to single or multi-select (and a column for grids)
        if (sharedAnswerTypes.includes(newType)) {
          handleSetAnswers([{ id: uniqueId("answer_"), text: "" }]);
        } else if (newType === CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT) {
          handleSetAnswers([{ id: uniqueId("answer_"), text: "" }]);
          setRows([{ id: uniqueId("row_"), text: "" }]);
        }
      }

      resetResponseValidation();
      setHasUnsavedChanges(true);
      setSelectedType(newType);
    },
    [handleSetAnswers, resetResponseValidation, selectedType, setSelectedType, setHasUnsavedChanges]
  );

  // update form after setAnswers/setRows
  useEffect(() => form.setFieldsValue({ answers, rows }), [form, answers, rows]);

  const handleFinish = useCallback(async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    try {
      setSavingState("saving");
      await saveCharacteristic(form.getFieldsValue());
      setHasUnsavedChanges(false);
      onCancel();
      setSavingState("saved");

      trackEvent("Characteristic saved", {
        ...getUserContext(user as any),
        ...getTenantContext(tenant as any),
        "Characteristic Type": selectedType,
        "Characteristic Import Key": form.getFieldValue("importKey"),
      });
    } catch (err: any) {
      message.error(err.errors[0].message);
      setSavingState("error");
    }
  }, [form, setSavingState, setHasUnsavedChanges, onCancel, user, selectedType, tenant, message]);

  const confirmDelete = useConfirm({ title: "Delete characteristic?", okText: "Delete" });

  return (
    <Card className="characteristic-card expanded">
      <Form
        form={form}
        initialValues={characteristic}
        onFinish={handleFinish}
        onValuesChange={changedValues => {
          if (Object.hasOwn(changedValues, "showToPanelists")) setShowToPanelists(changedValues.showToPanelists);
        }}
        validateTrigger="onBlur"
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="answers">
          <Input />
        </Form.Item>
        <Form.Item hidden name="elementMeta">
          <Input />
        </Form.Item>
        <Form.Item hidden name="rows">
          <Input />
        </Form.Item>

        <DetailsInput
          title="Characteristic name"
          inputs={
            <Form.Item name="shortName" rules={[{ required: true, message: "Please provide a characteristic name" }]}>
              <Input
                maxLength={100}
                placeholder="Age, Occupation, Country, etc."
                onChange={() => setHasUnsavedChanges(true)}
              />
            </Form.Item>
          }
        />

        <DetailsInput
          title="Import key"
          optional
          inputs={
            <Form.Item name="importKey">
              <Input
                maxLength={512}
                placeholder="Optional unique identifier"
                onChange={() => setHasUnsavedChanges(true)}
              />
            </Form.Item>
          }
        />

        <DetailsInput
          title="Question"
          inputs={
            <Form.Item name="text" rules={[{ required: true, message: "Please provide question text" }]}>
              <Input.TextArea autoSize={{ minRows: 4 }} maxLength={512} onChange={() => setHasUnsavedChanges(true)} />
            </Form.Item>
          }
        />

        <Divider style={{ margin: "8px 0" }} />
        <DetailsInput
          title="Answer type"
          inputStyle={{ display: "flex", alignItems: "center", gap: 8 }}
          inputs={
            <>
              <Form.Item name="type" style={{ flex: 1, marginBottom: 0 }}>
                <Select value={selectedType} onChange={handleChangeType}>
                  {useMemo(
                    () =>
                      CHARACTERISTIC_ELEMENTS.map(element => (
                        <Select.Option key={element.type}>{element.label}</Select.Option>
                      )),
                    []
                  )}
                </Select>
              </Form.Item>
              {[CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT, CHARACTERISTIC_ELEMENT_TYPES.NUMBER].includes(
                selectedType
              ) && (
                <Popover
                  content={
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ flexGrow: 1, margin: "12px 12px 12px 0" }}>
                        Show response validation&nbsp;
                        <Tooltip
                          getPopupContainer={trigger => trigger.parentElement!}
                          title={
                            selectedType === CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT
                              ? "Add a number of required responses"
                              : "Limit the range of accepted answers"
                          }
                        >
                          <Icon
                            icon="mdi:help-circle-outline"
                            height="1.2em"
                            style={{ verticalAlign: "sub", color: FADED_TEXT_COLOR }}
                          />
                        </Tooltip>
                      </span>
                      <Switch
                        className="screener-element-question-options-response-validation"
                        size="small"
                        checked={enableResponseValidation}
                        onChange={v => {
                          setEnableResponseValidation(v);
                          if (!v) resetResponseValidation();
                        }}
                      />
                    </label>
                  }
                  getPopupContainer={trigger => trigger.parentElement!}
                  placement="left"
                  trigger="click"
                >
                  <Icon icon="mdi:dots-vertical" height="1.2em" style={{ cursor: "pointer" }} />
                </Popover>
              )}
            </>
          }
          style={{ marginBottom: 16, marginTop: 16 }}
        />
        {
          // date
          selectedType === CHARACTERISTIC_ELEMENT_TYPES.DATE ? null : selectedType === // grid
            CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT ? (
            <EditCharacteristicGrid
              form={form}
              answers={answers}
              setAnswers={handleSetAnswers}
              rows={rows}
              setRows={handleSetRows}
            />
          ) : // single or multi select
          [CHARACTERISTIC_ELEMENT_TYPES.SINGLE_SELECT, CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT].includes(
              selectedType as CHARACTERISTIC_ELEMENT_TYPES
            ) ? (
            <EditCharacteristicSelect
              form={form}
              characteristicType={selectedType}
              answers={answers}
              setAnswers={handleSetAnswers}
            />
          ) : // open-ended
          selectedType === CHARACTERISTIC_ELEMENT_TYPES.OPENEND ? (
            <EditCharacteristicOpenEnded />
          ) : (
            // future question type
            <></>
          )
        }
        {[CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT, CHARACTERISTIC_ELEMENT_TYPES.NUMBER].includes(selectedType) &&
          enableResponseValidation && (
            <DetailsInput
              style={{ marginTop: 16, marginBottom: 16 }}
              title="Response validation"
              inputs={
                <ResponseValidationFormItems
                  elementAnswersLength={answers.length}
                  elementType={selectedType}
                  form={form}
                  formRootPath="elementMeta"
                  onChange={() => setHasUnsavedChanges(true)}
                />
              }
            />
          )}
        <Divider style={{ margin: "8px 0" }} />
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Form.Item name="showToPanelists" style={{ marginBottom: 0, marginRight: 8 }}>
              <Switch
                checkedChildren={<Icon icon="mdi:eye" style={{ marginTop: 4 }} />}
                unCheckedChildren={<Icon icon="mdi:eye-off" style={{ marginTop: 4 }} />}
                checked={showToPanelists}
              />
            </Form.Item>
            <span style={{ color: PRIMARY_TEXT_COLOR }}>
              Answer any time&nbsp;
              <Tooltip
                getPopupContainer={trigger => trigger.parentElement!}
                title={
                  <>
                    <p style={{ fontWeight: showToPanelists ? "bold" : "initial", marginBottom: 4 }}>
                      When enabled, panelists logged into the portal can see & update their answers.
                    </p>
                    <p style={{ fontWeight: showToPanelists ? "initial" : "bold", marginBottom: 0 }}>
                      When disabled, panelists can only answer when asked during a survey.
                    </p>
                  </>
                }
              >
                <Icon
                  icon="mdi:help-circle-outline"
                  height="1.2em"
                  style={{ verticalAlign: "sub", color: FADED_TEXT_COLOR }}
                />
              </Tooltip>
            </span>
          </div>

          {!isNew && (
            <Button
              style={{ aspectRatio: "1", display: "grid", placeContent: "center" }}
              type="text"
              onClick={async () => (await handleNavigate()) && duplicate(form.getFieldsValue())}
            >
              <Icon icon="mdi:content-copy" height="1.2em" />
            </Button>
          )}
          <Button
            style={{ aspectRatio: "1", display: "grid", placeContent: "center" }}
            type="text"
            onClick={async () => {
              if (!(await confirmDelete())) return;

              // delete the characteristic on the server
              !isNew && (await deleteCharacteristic({ characteristicId: characteristic.id }));

              onCancel();
              notification.success({ message: "Characteristic deleted" });

              trackEvent("Characteristic deleted", {
                ...getUserContext(user as any),
                ...getTenantContext(tenant as any),
                "Characteristic Import Key": characteristic.importKey,
              });
            }}
          >
            <Icon icon="mdi:delete-outline" height="1.3em" />
          </Button>
          <Button onClick={async () => (await handleNavigate()) && onCancel()}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export const EditCharacteristicActive = createFragmentContainer(_EditCharacteristicActive, {
  user: graphql`
    fragment EditCharacteristicActive_user on UserNode {
      dId
      vpmUserId
      panelist {
        dId
      }
    }
  `,
  tenant: graphql`
    fragment EditCharacteristicActive_tenant on TenantNode {
      dId
      vpmAccountId
      name
    }
  `,
});
