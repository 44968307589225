/**
 * @generated SignedSource<<50e96081e3220a9d2627a89b0e0f30c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsPage_viewer$data = {
  readonly studiesCopy: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  } | null;
  readonly user: {
    readonly profile: {
      readonly tenant: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"InputTagsSelect_tenant">;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "ProjectsPage_viewer";
};
export type ProjectsPage_viewer$key = {
  readonly " $data"?: ProjectsPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsPage_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsPage_viewer",
  "selections": [
    {
      "alias": "studiesCopy",
      "args": null,
      "concreteType": "StudyNodeConnection",
      "kind": "LinkedField",
      "name": "studies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "InputTagsSelect_tenant"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "d30b07f5a4646bbba27e7c05383a2024";

export default node;
