/**
 * @generated SignedSource<<cb7c4928b37cc6fff6967d85e792406a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Note_respondent$data = {
  readonly id: string;
  readonly note: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly firstName: string;
    } | null;
    readonly text: string | null;
  } | null;
  readonly " $fragmentType": "Note_respondent";
};
export type Note_respondent$key = {
  readonly " $data"?: Note_respondent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Note_respondent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Note_respondent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteNode",
      "kind": "LinkedField",
      "name": "note",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "modifiedBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RespondentNode",
  "abstractKey": null
};
})();

(node as any).hash = "026acdaa6c0340230c1c3d48aac79a23";

export default node;
