/**
 * @generated SignedSource<<9afc58d9ff0d7689d6b9bbdf3e812639>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelistModalQuery$variables = {
  panelistId: string;
};
export type PanelistModalQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PanelistModal_viewer">;
  };
};
export type PanelistModalQuery = {
  response: PanelistModalQuery$data;
  variables: PanelistModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "panelistId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "panelistId",
    "variableName": "panelistId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PanelistModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "PanelistModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PanelistModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PanelistNode",
            "kind": "LinkedField",
            "name": "panelist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistMembershipNode",
                "kind": "LinkedField",
                "name": "panelistMembership",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParticipantNode",
                    "kind": "LinkedField",
                    "name": "latestRatedParticipant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rating",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rewardsBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonNode",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatarUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "joined",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParticipantNode",
                "kind": "LinkedField",
                "name": "studyParticipants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyNode",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagNode",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RespondentNode",
                    "kind": "LinkedField",
                    "name": "respondent",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "masterStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startedOn",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SelectedResponse",
                "kind": "LinkedField",
                "name": "selectedResponses",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SelectedResponseAnswer",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "row",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "selected",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "other",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userSpecified",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customAnswer",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NoteNode",
                "kind": "LinkedField",
                "name": "tenantNote",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "modifiedBy",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a05ef77f0f67500a003b583bc729dd8c",
    "id": null,
    "metadata": {},
    "name": "PanelistModalQuery",
    "operationKind": "query",
    "text": "query PanelistModalQuery(\n  $panelistId: String!\n) {\n  viewer {\n    ...PanelistModal_viewer_3dts7L\n  }\n}\n\nfragment GivePointsToPanelistModal_panelist on PanelistNode {\n  id\n  person {\n    firstName\n    lastName\n    id\n  }\n}\n\nfragment Note_panelist on PanelistNode {\n  id\n  tenantNote {\n    id\n    modified\n    modifiedBy {\n      firstName\n      id\n    }\n    text\n  }\n}\n\nfragment PanelistCharacteristics_panelist on PanelistNode {\n  person {\n    email\n    phoneNumber\n    firstName\n    lastName\n    id\n  }\n  id\n  studyParticipants {\n    study {\n      name\n      tags {\n        ...TagTag_tag\n        id\n      }\n      id\n    }\n    respondent {\n      masterStatus\n      startedOn\n      id\n    }\n    id\n  }\n  selectedResponses {\n    key\n    text\n    type\n    answers {\n      key\n      text\n      row\n      rowText\n      selected\n      other\n      userSpecified\n      customAnswer\n    }\n  }\n}\n\nfragment PanelistModal_viewer_3dts7L on Viewer {\n  panelist(panelistId: $panelistId) {\n    id\n    dId\n    panelistMembership {\n      latestRatedParticipant {\n        rating\n        id\n      }\n      id\n    }\n    rewardsBalance\n    person {\n      avatarUrl\n      firstName\n      lastName\n      id\n    }\n    joined\n    ...PanelistCharacteristics_panelist\n    ...Note_panelist\n    ...GivePointsToPanelistModal_panelist\n  }\n}\n\nfragment TagTag_tag on TagNode {\n  color\n  name\n}\n"
  }
};
})();

(node as any).hash = "b6ad2a86df4720106490eaf998b84e8f";

export default node;
