import { QuestionCircleOutlined } from "@ant-design/icons";
import outlineArchive from "@iconify-icons/ic/outline-archive";
import outlineDone from "@iconify-icons/ic/outline-done";
import outlineRestore from "@iconify-icons/ic/outline-restore";
import { useUpdateEffect } from "@react-hookz/web";
import { Alert, App, Card, Divider, Form, FormInstance, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { omit } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ThemeColor } from "../../../antd";

import {
  AutomatedEmail,
  AUTOMATED_EMAIL_TYPE,
  AUTOMATED_EMAIL_TYPE_TOOLTIP,
  EmailTemplateContent,
  TERMINATE_RESPONDENT,
} from "../../../constants";
import { FADED_TEXT_COLOR } from "../../../style";
import { mutation } from "../../../utils";
import { AutomatedEmailEditor_study$data } from "../../../__generated__/AutomatedEmailEditor_study.graphql";
import { IconButton } from "../../IconButton";
import { StickToBottom } from "../../StickToBottom";
import { SaveTemplateModal } from "../Templates/SaveTemplateModal";
import { SelectTemplateModal } from "../Templates/SelectTemplateModal";
import { AutomatedEmailInputs } from "./AutomatedEmailInputs";

import { getAutomatedEmailMeta } from "./types";
import { AutomatedEmailOnFormResetContext, type OnFormReset } from "./utils";

const _AutomatedEmailEditor = ({
  form,
  study,
  automatedEmail,
  setIsDirty,
}: {
  form: FormInstance;
  study: AutomatedEmailEditor_study$data;
  automatedEmail: AutomatedEmail;
  setIsDirty: (isDirty: boolean) => void;
}) => {
  const { notification } = App.useApp();

  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);

  const shouldShowCtaInputs = useCallback(() => !!automatedEmail.ctaLabel && !!automatedEmail.ctaUrl, [automatedEmail]);
  const [showCtaInputs, setShowCtaInputs] = useState(shouldShowCtaInputs);
  useUpdateEffect(() => setShowCtaInputs(shouldShowCtaInputs), [shouldShowCtaInputs]);

  const [onFormReset, setOnFormReset] = useState<OnFormReset>(null);

  const resetFormFields = useCallback(() => {
    form.resetFields();
    onFormReset?.();
  }, [form, onFormReset]);

  useEffect(() => {
    resetFormFields();
    form.setFieldsValue({ ...automatedEmail, assets: automatedEmail.assets.map(x => omit(x, "id")) });
  }, [form, automatedEmail, resetFormFields]);

  const save = async () => {
    try {
      const input = { studyId: study.id, type: automatedEmail.type, ...form.getFieldsValue() };

      await mutation({
        variables: { input },
        mutation: graphql`
          mutation AutomatedEmailEditor_saveAutomatedEmail_Mutation($input: SaveAutomatedEmailInput!) {
            saveAutomatedEmail(input: $input) {
              study {
                ...AutomatedEmailsPage_study
              }
            }
          }
        `,
      });
      setIsDirty(false);
      notification.success({
        message: "Successfully saved automated email",
        description: input.enabled ? (
          <p>
            Respondents will be notified when they are assigned a status of{" "}
            <em>{getAutomatedEmailMeta(automatedEmail.type as AUTOMATED_EMAIL_TYPE)!.description}</em>.
          </p>
        ) : (
          <p>
            You can continue to make edits as needed, but respondents won't receive this automated email until it's set
            to Enabled.
          </p>
        ),
      });
    } catch (ex) {
      notification.error({ message: "Error saving automated email" });
      throw ex;
    }
  };

  const handleTemplateSelected = (emailTemplate: EmailTemplateContent) => {
    const enabled = !!form.getFieldValue("enabled");
    resetFormFields();
    form.setFieldsValue({
      enabled,
      ...emailTemplate,
    });
    setIsDirty(true);
  };

  const handleSaveAsNewTemplate = async () => {
    try {
      await form.validateFields();
      setShowSaveAsTemplateModal(true);
    } catch {}
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} style={{ display: "grid", gridTemplateRows: "1fr", maxHeight: "100%" }}>
      <Card bodyStyle={{ paddingBottom: 0 }} style={{ overflowY: "auto" }}>
        <SelectTemplateModal
          tenant={study.tenant}
          visible={showSelectTemplateModal}
          setVisible={setShowSelectTemplateModal}
          title="Select a template"
          onTemplateSelected={handleTemplateSelected}
        />
        <SaveTemplateModal
          emailTemplate={{
            replyTo: form.getFieldValue("replyTo"),
            subject: form.getFieldValue("subject"),
            message: form.getFieldValue("message"),
          }}
          tenant={study.tenant}
          visible={showSaveAsTemplateModal}
          setVisible={setShowSaveAsTemplateModal}
        />
        <StyledForm
          form={form}
          initialValues={automatedEmail}
          layout="vertical"
          onFinish={save}
          onChange={() => setIsDirty(true)}
        >
          <>
            <h1 style={{ display: "inline-block" }}>
              {getAutomatedEmailMeta(automatedEmail.type as AUTOMATED_EMAIL_TYPE)!.description}
            </h1>{" "}
            <Tooltip title={AUTOMATED_EMAIL_TYPE_TOOLTIP[automatedEmail.type as AUTOMATED_EMAIL_TYPE]}>
              <span style={{ color: FADED_TEXT_COLOR }}>
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
            <p>We will send this email to the participants that have just received this status.</p>
          </>
          {automatedEmail.type === AUTOMATED_EMAIL_TYPE.NEEDS_REVIEW && study.allowAutoApproval && (
            <Alert
              type="warning"
              message="Note"
              description={
                <>
                  Auto-approve is selected in Project Details. Respondents who are automatically approved will never
                  have the "Needs Review" status and will not receive this email.
                </>
              }
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          {automatedEmail.type === AUTOMATED_EMAIL_TYPE.TERMED &&
            study.screener?.terminateRespondent === TERMINATE_RESPONDENT.ASAP && (
              <Alert
                type="warning"
                message="Note"
                description={
                  <>
                    "Terminate as soon as applicable" is selected in Project Details. Users who are immediately termed
                    are not asked for their email address and as a result will not receive this email.
                  </>
                }
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
          {!!study.tenant.emailTemplates?.edges.length && (
            <>
              <IconButton icon={outlineArchive} type="ghost" onClick={() => setShowSelectTemplateModal(true)}>
                Use a template
              </IconButton>
              <Divider />
            </>
          )}
          <AutomatedEmailOnFormResetContext.Provider value={setOnFormReset}>
            <AutomatedEmailInputs
              {...{ form, automatedEmail, setIsDirty, showCtaInputs, setShowCtaInputs }}
              defaultCtaUrl={
                automatedEmail.type === AUTOMATED_EMAIL_TYPE.REWARDED
                  ? study.externalIncentive?.claimLink ?? undefined
                  : undefined
              }
              showEnabled={true}
            />
          </AutomatedEmailOnFormResetContext.Provider>
          <div className="automated-email-buttons">
            <IconButton
              icon={outlineRestore}
              type="link"
              onClick={() => {
                resetFormFields();
                setIsDirty(false);
              }}
            >
              Restore to default
            </IconButton>
          </div>
          <StickToBottom className="automated-email-primary-buttons" root={containerRef}>
            <IconButton icon={outlineArchive} onClick={handleSaveAsNewTemplate}>
              Save as new template
            </IconButton>
            <IconButton icon={outlineDone} type="primary" htmlType="submit">
              Save changes
            </IconButton>
          </StickToBottom>
        </StyledForm>
      </Card>
    </div>
  );
};

const StyledForm = styled(Form)`
  .automated-email-buttons {
    margin: 16px 0;
    display: flex;
    gap: 10px;
  }

  .automated-email-primary-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: white;
    padding: 16px;
    margin: 0 -24px;
  }

  &.stuck {
    border-top: 1px solid ${ThemeColor.VoxStroke};
    margin-top: 24px;
  }
`;

export const AutomatedEmailEditor = createFragmentContainer(_AutomatedEmailEditor, {
  study: graphql`
    fragment AutomatedEmailEditor_study on StudyNode {
      id
      allowAutoApproval
      externalIncentive {
        claimLink
      }
      screener {
        terminateRespondent
      }
      tenant {
        emailTemplates {
          edges {
            node {
              id
              name
              replyTo
              subject
              message
            }
          }
        }
        ...SelectTemplateModal_tenant
        ...SaveTemplateModal_tenant
      }
    }
  `,
});
