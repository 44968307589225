import outlineScreenSearchDesktop from "@iconify-icons/ic/outline-screen-search-desktop";
import { Icon } from "@iconify/react";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";

export enum PARTICIPANT_TYPES {
  HIDE_TEST_PARTICIPANTS,
  SHOW_TEST_PARTICIPANTS,
  TEST_ONLY,
}

const PARTICIPANT_TYPE_DESCRIPTIONS = {
  [PARTICIPANT_TYPES.HIDE_TEST_PARTICIPANTS]: "Hide test participants",
  [PARTICIPANT_TYPES.SHOW_TEST_PARTICIPANTS]: "Show test participants",
  [PARTICIPANT_TYPES.TEST_ONLY]: "Only show test participants",
};

type Props = {
  type: PARTICIPANT_TYPES;
  setType: (type: PARTICIPANT_TYPES) => void;
};
export const ParticipantTypesPicker: React.FC<Props> = ({ type, setType }) => (
  <Styled>
    <Tooltip title="Test Participants">
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu selectedKeys={[String(type)]}>
            {[
              PARTICIPANT_TYPES.HIDE_TEST_PARTICIPANTS,
              PARTICIPANT_TYPES.SHOW_TEST_PARTICIPANTS,
              PARTICIPANT_TYPES.TEST_ONLY,
            ].map(t => (
              <Menu.Item key={String(t)} onClick={() => setType(t)}>
                {PARTICIPANT_TYPE_DESCRIPTIONS[t]}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button className="participants-button">
          <Icon
            icon={outlineScreenSearchDesktop}
            color={type !== PARTICIPANT_TYPES.HIDE_TEST_PARTICIPANTS ? "var(--ant-warning-color)" : undefined}
            height={20}
          />
        </Button>
      </Dropdown>
    </Tooltip>
  </Styled>
);

const Styled = styled.div`
  .participants-button {
    padding: 4px 6px;
  }
`;
