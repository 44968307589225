import { Empty } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useContext, useEffect } from "react";
import { createFragmentContainer, type QueryRendererProps } from "react-relay";
import { CommunicationHistoryPage_study$data } from "../../__generated__/CommunicationHistoryPage_study.graphql";
import { StudyEmailCard } from "../index";
import ProjectPageContent from "../ProjectPageContent";
import { CommunicationPageOnEmailSentContext } from "./CommunicationPage";

type Props = {
  retry: Parameters<QueryRendererProps<any>["render"]>[0]["retry"];
  study: CommunicationHistoryPage_study$data;
};
const CommunicationHistoryPage: React.FC<Props> = ({ retry, study }) => {
  const setOnEmailSent = useContext(CommunicationPageOnEmailSentContext);
  useEffect(() => setOnEmailSent?.(() => () => retry?.()), [retry, setOnEmailSent]);

  return (
    <ProjectPageContent style={{ overflowY: "auto", height: "100%" }}>
      {study.emails.edges.length > 0 ? (
        study.emails.edges.map(o => o?.node && <StudyEmailCard key={o.node.id} card={o.node} />).reverse()
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No emails were sent for this project." />
      )}
    </ProjectPageContent>
  );
};

export default createFragmentContainer(CommunicationHistoryPage, {
  study: graphql`
    fragment CommunicationHistoryPage_study on StudyNode {
      emails {
        edges {
          node {
            id
            ...StudyEmailCard_card
          }
        }
      }
    }
  `,
});
