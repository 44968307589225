import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { environment } from "../../relay";
import {
  GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation,
  GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation$data,
} from "../../__generated__/GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation.graphql";
import {
  GivePointsMutations_getGivePointsToParticipantsCosts_Mutation,
  GivePointsMutations_getGivePointsToParticipantsCosts_Mutation$data,
} from "../../__generated__/GivePointsMutations_getGivePointsToParticipantsCosts_Mutation.graphql";
import { GivePointsMutations_givePointsToAllParticipants_Mutation } from "../../__generated__/GivePointsMutations_givePointsToAllParticipants_Mutation.graphql";
import { GivePointsMutations_givePointsToParticipants_Mutation } from "../../__generated__/GivePointsMutations_givePointsToParticipants_Mutation.graphql";
import { ParticipantFilterGroupGroup } from "../Filters";

import { CostsSummary } from "./GivePointsModal";
import { TabType } from "./ParticipantsTable";
import { PARTICIPANT_TYPES } from "./ParticipantTypesPicker";

export const getCostsSummaryForAllParticipants = async (
  studyId: string,
  filterComplex: ParticipantFilterGroupGroup,
  excludeIds: string[] | null,
  tab: TabType,
  countToSelect: number | null,
  participantsType: PARTICIPANT_TYPES,
  points: number
): Promise<CostsSummary> => {
  const costsSummary = await new Promise<GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation$data>(
    (resolve, reject) =>
      commitMutation<GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation>(environment, {
        variables: {
          input: {
            studyId,
            filterComplex,
            excludeIds,
            tab,
            countToSelect,
            includeTest: participantsType !== PARTICIPANT_TYPES.HIDE_TEST_PARTICIPANTS,
            excludeNonTest: participantsType === PARTICIPANT_TYPES.TEST_ONLY,
            points,
          },
        },
        mutation: graphql`
          mutation GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation(
            $input: GetGivePointsToAllParticipantsCostsInput!
          ) {
            getGivePointsToAllParticipantsCosts(input: $input) {
              costsSummary {
                pointsCostUsdCents
                feesUsdCents
                testParticipantCount
                nonTestParticipantCount
              }
            }
          }
        `,
        onCompleted: resolve,
        onError: reject,
      })
  );

  return costsSummary.getGivePointsToAllParticipantsCosts!.costsSummary;
};

export const getCostsSummaryForParticipants = async (selectedIds: string[], points: number): Promise<CostsSummary> => {
  const costsSummary = await new Promise<GivePointsMutations_getGivePointsToParticipantsCosts_Mutation$data>(
    (resolve, reject) =>
      commitMutation<GivePointsMutations_getGivePointsToParticipantsCosts_Mutation>(environment, {
        variables: {
          input: {
            participantIds: selectedIds,
            points,
          },
        },
        mutation: graphql`
          mutation GivePointsMutations_getGivePointsToParticipantsCosts_Mutation(
            $input: GetGivePointsToParticipantsCostsInput!
          ) {
            getGivePointsToParticipantsCosts(input: $input) {
              costsSummary {
                pointsCostUsdCents
                feesUsdCents
                testParticipantCount
                nonTestParticipantCount
              }
            }
          }
        `,
        onCompleted: resolve,
        onError: reject,
      })
  );

  return costsSummary.getGivePointsToParticipantsCosts!.costsSummary;
};

export const givePointsToAllParticipants = async (
  costsSummary: CostsSummary,
  studyId: string,
  filterComplex: ParticipantFilterGroupGroup,
  excludeIds: string[] | null,
  tab: TabType,
  countToSelect: number | null,
  participantsType: PARTICIPANT_TYPES,
  selectedIds: string[],
  points: number
): Promise<void> =>
  new Promise((resolve, reject) =>
    commitMutation<GivePointsMutations_givePointsToAllParticipants_Mutation>(environment, {
      variables: {
        input: {
          studyId,
          filterComplex,
          excludeIds,
          tab,
          countToSelect,
          includeTest: participantsType !== PARTICIPANT_TYPES.HIDE_TEST_PARTICIPANTS,
          excludeNonTest: participantsType === PARTICIPANT_TYPES.TEST_ONLY,
          points,
          expectedCostUsdCents: costsSummary!.pointsCostUsdCents + costsSummary!.feesUsdCents,
        },
      },
      mutation: graphql`
        mutation GivePointsMutations_givePointsToAllParticipants_Mutation($input: GivePointsToAllParticipantsInput!) {
          givePointsToAllParticipants(input: $input) {
            summary {
              pointsCostUsdCents
              feesUsdCents
              testParticipantCount
              nonTestParticipantCount
            }
            study {
              participants {
                edges {
                  node {
                    bonusPoints {
                      orderDate
                      pointsAmount
                    }
                  }
                }
              }
            }
          }
        }
      `,
      onCompleted: () => resolve(),
      onError: reject,
    })
  );

export const givePointsToParticipants = async (
  costsSummary: CostsSummary,
  studyId: string,
  selectedIds: string[],
  points: number
): Promise<void> =>
  new Promise((resolve, reject) =>
    commitMutation<GivePointsMutations_givePointsToParticipants_Mutation>(environment, {
      variables: {
        input: {
          studyId,
          participantIds: selectedIds,
          expectedCostUsdCents: costsSummary!.pointsCostUsdCents + costsSummary!.feesUsdCents,
          points,
        },
      },
      mutation: graphql`
        mutation GivePointsMutations_givePointsToParticipants_Mutation($input: GivePointsToParticipantsInput!) {
          givePointsToParticipants(input: $input) {
            summary {
              pointsCostUsdCents
              feesUsdCents
              testParticipantCount
              nonTestParticipantCount
            }
            study {
              participants {
                edges {
                  node {
                    bonusPoints {
                      orderDate
                      pointsAmount
                    }
                  }
                }
              }
            }
          }
        }
      `,
      onCompleted: () => resolve(),
      onError: reject,
    })
  );
