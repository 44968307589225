/**
 * @generated SignedSource<<34c261cba8696da02f023b6d812ddf94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseOpenEnd_characteristicResponses$data = ReadonlyArray<{
  readonly id: string;
  readonly textValue: string;
  readonly " $fragmentType": "InputCharacteristicResponseOpenEnd_characteristicResponses";
}>;
export type InputCharacteristicResponseOpenEnd_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponseOpenEnd_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseOpenEnd_characteristicResponses">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponseOpenEnd_characteristicResponses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textValue",
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};

(node as any).hash = "90d411255b3e2b634af59bdd44e8c1bc";

export default node;
