/**
 * @generated SignedSource<<485315c888347f438cad85aed9f59fff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectParticipantsPage_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AlertVoxpopmeVideoAnalysis_study" | "BulkScheduleModal_study" | "ParticipantPaymentModal_study" | "ParticipantsTableHeader_study" | "ParticipantsTable_study" | "ScheduleModal_study" | "SlotPicker_study">;
  readonly " $fragmentType": "ProjectParticipantsPage_study";
};
export type ProjectParticipantsPage_study$key = {
  readonly " $data"?: ProjectParticipantsPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectParticipantsPage_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "elementIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectParticipantsPage_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AlertVoxpopmeVideoAnalysis_study"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "count",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "elementIds",
          "variableName": "elementIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ParticipantsTable_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantsTableHeader_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantPaymentModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduleModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BulkScheduleModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SlotPicker_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "92561990b22a0e3e0d6c1a239942588c";

export default node;
