import { App, Button, Form } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import styled from "styled-components";
import type { SetNonNullable } from "type-fest";

import { StickToBottom } from "../..";
import { ConfigProviderAccent } from "../../../antd";
import { BORDER_COLOR } from "../../../style";
import { sentenceCase, useBoundForm, useMutation } from "../../../utils";
import type {
  FormPanelistResponses_panelist$data,
  FormPanelistResponses_panelist$key,
} from "../../../__generated__/FormPanelistResponses_panelist.graphql";
import type { FormPanelistResponses_UpdatePanelist_Mutation } from "../../../__generated__/FormPanelistResponses_UpdatePanelist_Mutation.graphql";
import { DetailsInput } from "../../Configure/DetailsInput";

import { FormItemCharacteristicResponses } from "./FormItemCharacteristicResponses";
import { createGridDefaultValue, createUpdatePanelistInitialValues } from "./utils";

const createInitialValues = (selectedResponses: FormPanelistResponses_panelist$data["selectedResponses"]) =>
  createUpdatePanelistInitialValues(selectedResponses);

const useOnResetInterceptors = () =>
  useReducer(
    (
      state: Record<string, () => void>,
      {
        action,
        interceptor,
        key,
      }: { key: string } & (
        | { action: "subscribe"; interceptor: () => void }
        | { action: "unsubscribe"; interceptor?: undefined }
      )
    ): typeof state =>
      action === "subscribe"
        ? { ...state, [key]: interceptor }
        : Object.fromEntries(Object.entries(state).filter(([k]) => k !== key)),
    {}
  );

export type DispatchOnResetInterceptor = ReturnType<typeof useOnResetInterceptors>[1];

export const FormPanelistResponses = ({
  onReset: _onReset,
  onTouch: _onTouch,
  panelist: panelistKey,
}: {
  onReset: () => void;
  onTouch: () => void;
  panelist: FormPanelistResponses_panelist$key;
}) => {
  const { notification } = App.useApp();

  const { id: panelistId, selectedResponses: _selectedResponses } = useFragment(
    graphql`
      fragment FormPanelistResponses_panelist on PanelistNode {
        id
        selectedResponses {
          characteristic {
            id
            text
            type
            ...FormItemCharacteristicResponses_characteristic
          }
          characteristicResponses {
            answer {
              id
              userSpecified
            }
            customAnswer
            row {
              id
            }
            textValue
            ...FormItemCharacteristicResponses_characteristicResponses
          }
        }
      }
    `,
    panelistKey
  );
  const selectedResponses = _selectedResponses.filter(
    (x): x is typeof x & { readonly characteristic: SetNonNullable<(typeof x)["characteristic"], "text"> } =>
      !!x.characteristic.text
  );

  const [commit, isInFlight] = useMutation<FormPanelistResponses_UpdatePanelist_Mutation>(graphql`
    mutation FormPanelistResponses_UpdatePanelist_Mutation($input: UpdatePanelistInput!) {
      updatePanelist(input: $input) {
        panelist {
          id
          ...FormPanelistResponses_panelist
        }
      }
    }
  `);

  const [onResetInterceptors, dispatchOnResetInterceptor] = useOnResetInterceptors();

  const { form, initialValues, onFinish, onReset, onValuesChange, touched } = useBoundForm(selectedResponses, {
    createInitialValues,
    onFinish: changedValues =>
      commit(
        {
          panelistId,
          data: changedValues,
        },
        {
          onCompleted: () => notification.success({ message: "Answers updated" }),
        }
      ),
    onReset: () => {
      Object.values(onResetInterceptors).forEach(x => x());
      _onReset();
    },
    onTouch: _onTouch,
  });

  const [showStateField, setShowStateField] = useState(false);

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const countryCharacteristic = selectedResponses.find(
      response => response.characteristic.text === "Which country do you currently live in?"
    );

    if (countryCharacteristic) {
      const countryValue = changedValues[countryCharacteristic.characteristic.id];
      if (countryValue === "Q2hhcmFjdGVyaXN0aWNBbnN3ZXJOb2RlOjA3Y2I2YTNjLWNiYzYtNDRjNi04NGRlLTIzOWY3YTE2MjdhYg==") {
        setShowStateField(true); // Show the state field
      } else {
        setShowStateField(false); // Hide the state field
      }
    }
    onValuesChange(changedValues, allValues);
  };

  const { t } = useTranslation();

  return (
    <Root>
      <Form
        disabled={isInFlight}
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={handleValuesChange}
      >
        {selectedResponses.map(x => {
          if (x.characteristic.text === "What state do you reside in?" && !showStateField) {
            return null;
          } else {
            return (
              <DetailsInput
                collapseBottomMargin
                inputs={
                  <FormItemCharacteristicResponses
                    characteristic={x.characteristic}
                    characteristicResponses={x.characteristicResponses}
                    dispatchOnResetInterceptor={dispatchOnResetInterceptor}
                    gridDefaultValue={createGridDefaultValue(x.characteristic.id, initialValues)}
                    onValuesChange={onValuesChange}
                  />
                }
                key={x.characteristic.id}
                title={x.characteristic.text}
              />
            );
          }
        })}
        <StickToBottom className="hub-form-actions">
          <ConfigProviderAccent>
            <Button disabled={isInFlight || !touched} htmlType="reset">
              {sentenceCase(t("dictionary.verb.cancel"))}
            </Button>
            <Button disabled={isInFlight || !touched} htmlType="submit" loading={isInFlight} type="primary">
              {sentenceCase(t("dictionary.verb.save"))}
            </Button>
          </ConfigProviderAccent>
        </StickToBottom>
      </Form>
    </Root>
  );
};

const Root = styled.div`
  .hub-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin: 0;

    position: sticky;
    bottom: 0;
    transition: all 300ms;

    &.stuck {
      padding: 16px;
      margin: 0 -16px;
      background-color: white;
      border-top: 1px solid ${BORDER_COLOR};
    }
  }
`;
