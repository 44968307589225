import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, InputNumber, Progress, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { graphql } from "babel-plugin-relay/macro";
import { debounce, uniqueId } from "lodash-es";
import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";

import { SAVING_STATES } from "../../constants";
import { getStudyContext, getTenantContext, mutation, trackEvent } from "../../utils";
import { Segment_DeleteSegment_Mutation } from "../../__generated__/Segment_DeleteSegment_Mutation.graphql";
import type { Segment_screener$key } from "../../__generated__/Segment_screener.graphql";
import type { Segment_segment$key } from "../../__generated__/Segment_segment.graphql";
import type { Segment_UpdateSegment_Mutation } from "../../__generated__/Segment_UpdateSegment_Mutation.graphql";
import { GroupOp, InputFilterGroupGroup, isValidFilterGroupGroup } from "../Filters";
import { SavingStateType } from "../Saving";

const Segment = ({
  screener: screenerKey,
  segment: segmentKey,
  setSavingState,
}: {
  screener: Segment_screener$key;
  segment: Segment_segment$key;
  setSavingState: (savingState: SavingStateType) => void;
}) => {
  const screener = useFragment(
    graphql`
      fragment Segment_screener on ScreenerNode {
        study {
          dId
          name
          type
          status
          tenant {
            dId
            name
            vpmAccountId
          }
        }
        ...InputFilterGroupGroup_screener
      }
    `,
    screenerKey
  );
  const segment = useFragment(
    graphql`
      fragment Segment_segment on SegmentNode {
        id
        condition
        text
        quota
        count
        terminate
      }
    `,
    segmentKey
  );

  const [text, setText] = useState(segment.text || undefined);
  const [quota, setQuota] = useState(segment.quota || undefined);
  const [terminate, setTerminate] = useState(segment.terminate);

  const updateSegment = async (segmentId: string, input: { text?: string; condition?: any }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      const result = await mutation<Segment_UpdateSegment_Mutation>({
        variables: {
          input: {
            segmentId: segmentId || "",
            ...input,
          },
        },
        mutation: graphql`
          mutation Segment_UpdateSegment_Mutation($input: UpdateSegmentInput!) {
            updateSegment(input: $input) {
              segment {
                text
                ...Segment_segment
              }
            }
          }
        `,
      });
      if (!segmentId)
        trackEvent("Segment Created", {
          "Segment Name": result.updateSegment?.segment?.text,
          ...getStudyContext(screener.study),
          ...getTenantContext(screener.study.tenant),
        });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const deleteSegment = async (segmentId: string) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<Segment_DeleteSegment_Mutation>({
        variables: {
          input: {
            segmentId: segmentId || "",
          },
        },
        mutation: graphql`
          mutation Segment_DeleteSegment_Mutation($input: DeleteSegmentInput!) {
            deleteSegment(input: $input) {
              screener {
                segments {
                  edges {
                    node {
                      ...Segment_segment
                    }
                  }
                }
              }
            }
          }
        `,
      });
      trackEvent("Segment Deleted", {
        "Segment Name": segment.text,
        ...getStudyContext(screener.study),
        ...getTenantContext(screener.study.tenant),
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const func = debounce((o: any) => updateSegment(segment.id, o), 1000);
  const debounceSave = useCallback(func, [func]);

  const changeCondition = (condition: any) => {
    if (condition == null || isValidFilterGroupGroup(condition)) debounceSave({ condition });
  };

  const changeText = (e: any) => {
    setText(e.target.value);
    debounceSave({ text: e.target.value });
  };

  const changeQuota = (v: any) => {
    const quota = v === "" ? null : v;
    setQuota(quota);
    debounceSave({ quota });
  };

  const changeTerminate = (e: RadioChangeEvent) => {
    const terminate = e.target.value;
    setTerminate(terminate);

    const saveOptions: { terminate: boolean; quota?: null } = {
      terminate,
    };
    if (terminate) {
      setQuota(undefined);
      saveOptions.quota = null;
    }
    debounceSave(saveOptions);
  };

  const deleteElement = () => {
    deleteSegment(segment.id);
  };

  const percentage = () => {
    if (segment.count && segment.count > 0 && quota) {
      const val = (segment.count / quota) * 100;
      return Math.ceil(val);
    }
    return 0;
  };

  return (
    <Styled>
      <div className="inner">
        <Form layout="vertical">
          <Row>
            <Col span={8}>
              <Form.Item label="Name of segment">
                <Input placeholder="Enter name..." value={text} onChange={changeText} />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Quota">
                <div style={{ display: "flex" }}>
                  <Radio.Group value={terminate} onChange={changeTerminate}>
                    <Radio.Button value={false}>Quota</Radio.Button>
                    <Radio.Button value={true}>Term</Radio.Button>
                  </Radio.Group>
                  {!terminate ? (
                    <InputNumber
                      type="number"
                      style={{ width: 88, marginLeft: 6 }}
                      value={quota}
                      onChange={changeQuota}
                      placeholder="Limit"
                    />
                  ) : (
                    <Input style={{ width: 88, marginLeft: 6 }} disabled value="N/A" />
                  )}
                </div>
              </Form.Item>
            </Col>
            <Col span={7}>
              <div className="delete-btn">
                <Button type="link" icon={<DeleteOutlined />} onClick={deleteElement} />
              </div>
              <div className="counts">
                {quota === null || quota === undefined ? (
                  <div className="total">N/A</div>
                ) : (
                  <>
                    <div className="current">{segment.count}</div>
                    <div className="total">/{quota}</div>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Progress percent={!quota ? 0 : percentage()} showInfo={false} />
          <InputFilterGroupGroup
            onClear={() => changeCondition(null)}
            screener={screener}
            setValue={changeCondition}
            tenant={null}
            type="segment"
            value={
              // always fall back on defaults
              {
                id: uniqueId("filterGroupGroup"),
                op: GroupOp.And,
                filters: [{ id: uniqueId("filterGroup"), op: GroupOp.And, filters: [] } as any],
                ...(segment.condition ?? {}),
              }
            }
          />
        </Form>
      </div>
    </Styled>
  );
};

const Styled = styled(Card)`
  width: 100%;

  .inner {
    .delete-btn {
      display: flex;
      justify-content: flex-end;

      button {
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .counts {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .current {
        font-size: 22px;
        font-weight: 500;
      }

      .total {
        font-size: 22px;
      }
    }

    .ant-row.ant-form-item {
      margin-bottom: 4px;
    }
  }
`;

export default Segment;
