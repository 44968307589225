/**
 * @generated SignedSource<<25cb8d3ebf0af3d13cac3c2b86261cc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersPersonCountryChoices = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "US" | "%future added value";
export type UpdatePersonInput = {
  avatarUrl?: string | null;
  clientMutationId?: string | null;
  country?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phoneNumber?: string | null;
};
export type PortalRedeem_Country_Mutation$variables = {
  input: UpdatePersonInput;
};
export type PortalRedeem_Country_Mutation$data = {
  readonly updatePerson: {
    readonly panelist: {
      readonly convertedRewardsBalance: number | null;
      readonly id: string;
      readonly panelistCurrencyToPointsRatio: number | null;
      readonly tenantForIncentives: {
        readonly rewardCards: ReadonlyArray<{
          readonly faceplateUrl: string | null;
          readonly maxAmount: number | null;
          readonly minAmount: number | null;
          readonly name: string | null;
          readonly programId: string | null;
          readonly sku: string | null;
        } | null> | null;
      } | null;
    } | null;
    readonly person: {
      readonly country: ScreenersPersonCountryChoices;
      readonly id: string;
    };
  };
};
export type PortalRedeem_Country_Mutation = {
  response: PortalRedeem_Country_Mutation$data;
  variables: PortalRedeem_Country_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "convertedRewardsBalance",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelistCurrencyToPointsRatio",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "RewardCardObject",
  "kind": "LinkedField",
  "name": "rewardCards",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sku",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "programId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "faceplateUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonNode",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortalRedeem_Country_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelistNode",
            "kind": "LinkedField",
            "name": "panelist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenantForIncentives",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortalRedeem_Country_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelistNode",
            "kind": "LinkedField",
            "name": "panelist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenantForIncentives",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28731acb85748c38a243fe86c04f2be2",
    "id": null,
    "metadata": {},
    "name": "PortalRedeem_Country_Mutation",
    "operationKind": "mutation",
    "text": "mutation PortalRedeem_Country_Mutation(\n  $input: UpdatePersonInput!\n) {\n  updatePerson(input: $input) {\n    panelist {\n      id\n      convertedRewardsBalance\n      panelistCurrencyToPointsRatio\n      tenantForIncentives {\n        rewardCards {\n          sku\n          programId\n          maxAmount\n          minAmount\n          name\n          faceplateUrl\n        }\n        id\n      }\n    }\n    person {\n      id\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4238029aa4706b10745501d6f609d9f9";

export default node;
