import { Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import type { InputCharacteristicResponseOpenEnd_characteristic$key } from "../../../__generated__/InputCharacteristicResponseOpenEnd_characteristic.graphql";
import { InputCharacteristicResponseOpenEnd_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponseOpenEnd_characteristicResponses.graphql";

export const InputCharacteristicResponseOpenEnd = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  onChange,
  value,
}: {
  characteristic: InputCharacteristicResponseOpenEnd_characteristic$key;
  characteristicResponses: InputCharacteristicResponseOpenEnd_characteristicResponses$key;
  onChange?: Parameters<typeof Input.TextArea>[0]["onChange"];
  value?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _characteristic = useFragment(
    graphql`
      fragment InputCharacteristicResponseOpenEnd_characteristic on CharacteristicNode {
        id
      }
    `,
    characteristicKey
  );
  const [response] = useFragment(
    graphql`
      fragment InputCharacteristicResponseOpenEnd_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        id
        textValue
      }
    `,
    characteristicResponsesKey
  );

  return <Input.TextArea defaultValue={response?.textValue} onChange={onChange} value={value} />;
};
