/**
 * @generated SignedSource<<6ef523e2be10d8fd7c5ce8dcf52b4139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePanelInput = {
  clientMutationId?: string | null;
  panelId: string;
  panelistLayout: ReadonlyArray<string>;
};
export type PanelistModalPanelMutation$variables = {
  input: UpdatePanelInput;
};
export type PanelistModalPanelMutation$data = {
  readonly updatePanel: {
    readonly panel: {
      readonly id: string;
      readonly tenant: {
        readonly " $fragmentSpreads": FragmentRefs<"PanelistCharacteristics_tenant">;
      } | null;
    };
  } | null;
};
export type PanelistModalPanelMutation = {
  response: PanelistModalPanelMutation$data;
  variables: PanelistModalPanelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PanelistModalPanelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePanelPayload",
        "kind": "LinkedField",
        "name": "updatePanel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelNode",
            "kind": "LinkedField",
            "name": "panel",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PanelistCharacteristics_tenant"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PanelistModalPanelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePanelPayload",
        "kind": "LinkedField",
        "name": "updatePanel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelNode",
            "kind": "LinkedField",
            "name": "panel",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelNode",
                    "kind": "LinkedField",
                    "name": "panel",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "panelistLayout",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a088a5e13ae8d29faf4405672b313542",
    "id": null,
    "metadata": {},
    "name": "PanelistModalPanelMutation",
    "operationKind": "mutation",
    "text": "mutation PanelistModalPanelMutation(\n  $input: UpdatePanelInput!\n) {\n  updatePanel(input: $input) {\n    panel {\n      id\n      tenant {\n        ...PanelistCharacteristics_tenant\n        id\n      }\n    }\n  }\n}\n\nfragment PanelistCharacteristics_tenant on TenantNode {\n  panel {\n    id\n    panelistLayout\n  }\n}\n"
  }
};
})();

(node as any).hash = "44bd9345c246d3c776cdef800e313915";

export default node;
