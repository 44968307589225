/**
 * @generated SignedSource<<e9caa79f002048a7a5df18ecfe8ca0a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_PanelReport_Query$variables = {};
export type RouteConfig_PanelReport_Query$data = {
  readonly viewer: {
    readonly panel: {
      readonly " $fragmentSpreads": FragmentRefs<"PanelReport_panel">;
    } | null;
  };
};
export type RouteConfig_PanelReport_Query = {
  response: RouteConfig_PanelReport_Query$data;
  variables: RouteConfig_PanelReport_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "month",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "year",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_PanelReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Panel",
            "kind": "LinkedField",
            "name": "panel",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PanelReport_panel"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfig_PanelReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Panel",
            "kind": "LinkedField",
            "name": "panel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastHealthRun",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPanelists",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantPanelCharacteristic",
                "kind": "LinkedField",
                "name": "tenantCharacteristics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelHealth",
                "kind": "LinkedField",
                "name": "health",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "totalParticipants",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "totalPanelists",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "screenersTaken",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "qualified",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "terminated",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "overquota",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "approved",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "denied",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "rsvpAccepted",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "rsvpDeclined",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "incentivized",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "medianRating",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelHealthHistory",
                    "kind": "LinkedField",
                    "name": "noShow",
                    "plural": true,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3132d98632ec8ad88541f7b196282e5",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_PanelReport_Query",
    "operationKind": "query",
    "text": "query RouteConfig_PanelReport_Query {\n  viewer {\n    panel {\n      ...PanelReport_panel\n    }\n  }\n}\n\nfragment CharacteristicTable_panel on Panel {\n  totalPanelists\n  tenantCharacteristics {\n    key\n    title\n  }\n  health {\n    totalParticipants {\n      count\n      month\n      year\n    }\n  }\n}\n\nfragment PanelReport_panel on Panel {\n  lastHealthRun\n  ...CharacteristicTable_panel\n  ...SummaryData_panel\n}\n\nfragment SummaryData_panel on Panel {\n  lastHealthRun\n  health {\n    totalPanelists {\n      count\n      month\n      year\n    }\n    totalParticipants {\n      count\n      month\n      year\n    }\n    screenersTaken {\n      count\n      month\n      year\n    }\n    qualified {\n      count\n      month\n      year\n    }\n    terminated {\n      count\n      month\n      year\n    }\n    overquota {\n      count\n      month\n      year\n    }\n    approved {\n      count\n      month\n      year\n    }\n    denied {\n      count\n      month\n      year\n    }\n    rsvpAccepted {\n      count\n      month\n      year\n    }\n    rsvpDeclined {\n      count\n      month\n      year\n    }\n    incentivized {\n      count\n      month\n      year\n    }\n    medianRating {\n      count\n      month\n      year\n    }\n    noShow {\n      count\n      month\n      year\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "498da250e57eb2ed0598dce2120ba375";

export default node;
