import { Checkbox, Divider, Input, Radio } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { capitalize } from "lodash-es";
import { ReactNode } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { Label } from "..";
import { ELEMENT_TYPES, OPERATOR_DESCRIPTIONS } from "../../constants";
import { GRAY_7 } from "../../style";
import { InactiveElementContent_element$data } from "../../__generated__/InactiveElementContent_element.graphql";
import { InactiveElementContent_screener$data } from "../../__generated__/InactiveElementContent_screener.graphql";

const InactiveElementContent = ({
  screener,
  element,
  setActive,
  elementActions,
}: {
  screener: InactiveElementContent_screener$data;
  element: InactiveElementContent_element$data;
  setActive: () => void;
  elementActions?: JSX.Element;
}) => {
  const isValidLeafTerm = (term: any): boolean => term?.elementId && term?.op && term?.value;
  const isValidGroupTerm = (term: any): boolean => ["AND", "OR"].includes(term?.op) && term.terms?.some(isValidTerm);
  const isValidTerm = (term: any): boolean => isValidLeafTerm(term) || isValidGroupTerm(term);
  const usePreviousAnswers = !!JSON.parse(element?.usePreviousAnswers)?.enabled;

  const getValueSummary = (elementId: string, value: string): { elementString: string; valueString: string } => {
    const elementLookup = screener.elements?.edges.find(edge => edge?.node?.id === elementId)?.node;
    let elementString =
      (elementLookup ? `${elementLookup.label ? `${elementLookup.label}. ` : ""}` : "") +
      (elementLookup?.text ?? "<Untitled question>");

    let valueString = "<Untitled answer>";

    if (elementLookup?.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
      // grid questions store values as row_id|answer_id|is_selected_bool
      const parts = value.split("|");
      if (parts.length === 3) {
        // display grid questions as: "Question? Row, Column OP True/False"
        const rowLookup = elementLookup?.rows.edges.find(edge => edge?.node?.id === parts[0])?.node;
        elementString += ` ${rowLookup ? rowLookup.text : "<Untitled row>"}`;
        const answerLookup = elementLookup?.answers.edges.find(edge => edge?.node?.id === parts[1])?.node;
        elementString += `, ${answerLookup ? answerLookup.text : "<Untitled column>"}`;

        valueString = parts[2]?.length ? capitalize(parts[2]) : "<Untitled answer>";
      } else {
        elementString += " <Invalid selection>";
      }
    } else {
      // non-grid question
      const answerLookup = elementLookup?.answers.edges.find(edge => edge?.node?.id === value)?.node;
      valueString = answerLookup?.text ?? valueString;
    }

    return { elementString, valueString };
  };

  const getConditionSummary = (
    term: { op: string; terms: any[]; elementId: string; value: string },
    depth: number = 0
  ): ReactNode => {
    if (!isValidTerm(term)) {
      return null;
    }

    const { op, terms, elementId, value } = term;
    let elementString: string | null = null;
    let opString: string | null = OPERATOR_DESCRIPTIONS[op]?.toLowerCase() ?? op;
    let valueString: string | null = null;

    const validTerms = terms?.filter(isValidTerm);

    if (validTerms?.length) {
      const joinedTerms = validTerms.reduce(
        (acc: ReactNode, cur: any, i: number) => (
          <>
            {acc} {i > 0 ? op.toLowerCase() : null} {getConditionSummary(cur, depth + 1)}
          </>
        ),
        <></>
      );

      // wrap in parens if there's more than one term and we're at least one subgroup deep, like "( X or Y ) and Z"
      return validTerms.length > 1 && depth > 0 ? <>( {joinedTerms} )</> : joinedTerms;
    }

    switch (elementId) {
      case "SEGMENTS":
        elementString = "Segment";
        const segmentLookup = screener.segments.edges.find(edge => edge?.node?.id === value)?.node;
        valueString = segmentLookup?.text ?? "<Untitled segment>";
        break;

      default:
        ({ elementString, valueString } = getValueSummary(elementId, value));
        break;
    }

    return (
      <>
        <span style={{ fontWeight: "bold" }}>{elementString}</span> {opString}{" "}
        <span style={{ fontWeight: "bold" }}>{valueString}</span>
      </>
    );
  };

  const getConditionsSection = () => {
    // Only display conditions with an elementId and value
    if (!isValidGroupTerm(element.showIfCondition)) {
      return;
    }

    return (
      <>
        <Divider />
        <p>Only show this question if {getConditionSummary(element.showIfCondition)}</p>
      </>
    );
  };

  const getUsePreviousAnswersSection = () => {
    if (!usePreviousAnswers) {
      return;
    }

    const usePreviousAnswersParsed = JSON.parse(element.usePreviousAnswers);
    const feederQuestionId = usePreviousAnswersParsed?.elementId;
    const feederQuestion = screener.elements?.edges.find(edge => edge?.node?.id === feederQuestionId)?.node;

    let previousAnswerTexts = [];
    if (feederQuestion?.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
      previousAnswerTexts = usePreviousAnswersParsed?.conditions.map(
        (answerId: string) => feederQuestion.answers.edges.find(edge => edge?.node?.id === answerId)?.node?.text
      );
    } else {
      previousAnswerTexts = usePreviousAnswersParsed?.conditions;
    }

    return (
      <>
        <Divider />
        <p>
          Use answers from <span style={{ fontWeight: "bold" }}>{feederQuestion?.text || "<Unnamed Question>"}</span> if
          answers are{" "}
          <span style={{ fontWeight: "bold" }}>{previousAnswerTexts.join(", ") || "<No Options Selected>"}</span>
        </p>
      </>
    );
  };

  const renderAnswers = () =>
    element.answers.edges.map((a: any, i: number) => {
      if (!a?.node) {
        return null;
      }

      const answerText = a.node.text ?? "<Untitled answer>";

      switch (element.type) {
        case ELEMENT_TYPES.MULTI_SELECT:
          return (
            <AnswerRow key={i}>
              <Checkbox disabled />
              <div className="answer">{answerText}</div>
            </AnswerRow>
          );

        case ELEMENT_TYPES.SINGLE_SELECT:
        case ELEMENT_TYPES.GRID_SINGLE_SELECT:
        case ELEMENT_TYPES.DROPDOWN_SELECT:
          return (
            <AnswerRow key={i}>
              <Radio disabled />
              <div className="answer">{answerText}</div>
            </AnswerRow>
          );

        case ELEMENT_TYPES.OPENEND:
        case ELEMENT_TYPES.AUDITION_TEXT:
        case ELEMENT_TYPES.COMMENT:
        case ELEMENT_TYPES.NUMBER:
          return (
            <AnswerRow key={i}>
              <Input className="answer" disabled />
            </AnswerRow>
          );

        case ELEMENT_TYPES.VIDEO:
        case ELEMENT_TYPES.AUDITION_VIDEO:
          return (
            <AnswerRow key={i}>
              <Label icon="mdi:video-outline" text="Video" />
            </AnswerRow>
          );

        case ELEMENT_TYPES.DATE:
          return (
            <AnswerRow key={i}>
              <Input type="date" className="answer" disabled />
            </AnswerRow>
          );

        case ELEMENT_TYPES.FILES:
          return (
            <AnswerRow key={i}>
              <Label icon="mdi:file-outline" text="Files" />
            </AnswerRow>
          );

        default:
          return null;
      }
    });

  return (
    <div id={`screener-element-${element.position}`} onClick={setActive}>
      <QuestionTitleRow>
        <div className="label question-title">
          {element.label && `${element.label}. `}
          {element.text || "<Untitled question>"}
        </div>
        {elementActions}
      </QuestionTitleRow>

      {element.type !== ELEMENT_TYPES.GRID_SINGLE_SELECT
        ? renderAnswers()
        : element.rows?.edges?.map((row: any, i: number) => (
            <div key={i}>
              <p style={{ color: GRAY_7 }}>{row?.node?.text ?? "<Untitled row>"}</p>
              {renderAnswers()}
            </div>
          ))}

      {getUsePreviousAnswersSection()}
      {getConditionsSection()}
    </div>
  );
};

const QuestionTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;

  .question-title {
    flex-grow: 1;
  }
`;

const AnswerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${GRAY_7};
  margin-bottom: 10px;

  .answer {
    flex-grow: 1;
  }
`;

export default createFragmentContainer(InactiveElementContent, {
  screener: graphql`
    fragment InactiveElementContent_screener on ScreenerNode {
      elements {
        edges {
          node {
            id
            label
            text
            type
            rows {
              edges {
                node {
                  id
                  text
                }
              }
            }
            answers {
              edges {
                node {
                  id
                  text
                }
              }
            }
          }
        }
      }
      segments {
        edges {
          node {
            id
            text
          }
        }
      }
    }
  `,
  element: graphql`
    fragment InactiveElementContent_element on ElementNode {
      type
      text
      label
      position
      usePreviousAnswers
      answers {
        edges {
          node {
            text
          }
        }
      }
      rows {
        edges {
          node {
            text
          }
        }
      }
      showIfCondition
    }
  `,
});
