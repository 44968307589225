/**
 * @generated SignedSource<<30fa2d7f8521a750a37ebd058ec3d755>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AutomatedEmailInputs_assetUploadUrlQuery$variables = {
  fileName: string;
  fileType: string;
};
export type AutomatedEmailInputs_assetUploadUrlQuery$data = {
  readonly assetUploadUrl: string;
};
export type AutomatedEmailInputs_assetUploadUrlQuery = {
  response: AutomatedEmailInputs_assetUploadUrlQuery$data;
  variables: AutomatedEmailInputs_assetUploadUrlQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fileName",
        "variableName": "fileName"
      },
      {
        "kind": "Variable",
        "name": "fileType",
        "variableName": "fileType"
      }
    ],
    "kind": "ScalarField",
    "name": "assetUploadUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AutomatedEmailInputs_assetUploadUrlQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AutomatedEmailInputs_assetUploadUrlQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e0cb6eddfbfa5282caf83cb04581b4b5",
    "id": null,
    "metadata": {},
    "name": "AutomatedEmailInputs_assetUploadUrlQuery",
    "operationKind": "query",
    "text": "query AutomatedEmailInputs_assetUploadUrlQuery(\n  $fileName: String!\n  $fileType: String!\n) {\n  assetUploadUrl(fileName: $fileName, fileType: $fileType)\n}\n"
  }
};
})();

(node as any).hash = "fa8f23a3135cd8a5a5170036934dd8b3";

export default node;
