/**
 * @generated SignedSource<<480b836161f8a0429cfee8e03bfef63b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PortalRegistrationPageMutation$variables = {
  agreements: ReadonlyArray<string>;
  customLandingHostname: string;
  email: string;
  firstName: string;
  lastName: string;
  otpValue?: string | null;
  password: string;
};
export type PortalRegistrationPageMutation$data = {
  readonly registerPanelist: {
    readonly user: {
      readonly email: string;
      readonly id: string;
    } | null;
  } | null;
};
export type PortalRegistrationPageMutation = {
  response: PortalRegistrationPageMutation$data;
  variables: PortalRegistrationPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agreements"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customLandingHostname"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "otpValue"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "agreements",
            "variableName": "agreements"
          },
          {
            "kind": "Variable",
            "name": "customLandingHostname",
            "variableName": "customLandingHostname"
          },
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "firstName",
            "variableName": "firstName"
          },
          {
            "kind": "Variable",
            "name": "lastName",
            "variableName": "lastName"
          },
          {
            "kind": "Variable",
            "name": "otpValue",
            "variableName": "otpValue"
          },
          {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "RegisterPanelistPayload",
    "kind": "LinkedField",
    "name": "registerPanelist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortalRegistrationPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortalRegistrationPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03344d339ab93dca1789ef18d55ce9d5",
    "id": null,
    "metadata": {},
    "name": "PortalRegistrationPageMutation",
    "operationKind": "mutation",
    "text": "mutation PortalRegistrationPageMutation(\n  $agreements: [String!]!\n  $customLandingHostname: String!\n  $email: String!\n  $firstName: String!\n  $lastName: String!\n  $otpValue: String\n  $password: String!\n) {\n  registerPanelist(input: {agreements: $agreements, customLandingHostname: $customLandingHostname, email: $email, firstName: $firstName, lastName: $lastName, otpValue: $otpValue, password: $password}) {\n    user {\n      id\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfd6f838f59cecb79619846c18964aba";

export default node;
