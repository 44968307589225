/**
 * @generated SignedSource<<5951e12d1f04b58530420a59d6622747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersPersonCountryChoices = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PanelistLayout_user$data = {
  readonly email: string;
  readonly fullName: string | null;
  readonly panelist: {
    readonly convertedRewardsBalance: number | null;
    readonly customPanelistPortal: {
      readonly accentColor: string | null;
      readonly accentColorLighter: string | null;
      readonly customColor: string | null;
      readonly headerFontColor: string | null;
      readonly hidePointsAndRedemption: boolean;
      readonly logoImageUrl: string | null;
    } | null;
    readonly person: {
      readonly country: ScreenersPersonCountryChoices;
    } | null;
    readonly rewardsBalance: number | null;
    readonly totalIncentives: number | null;
  } | null;
  readonly profile: {
    readonly tenant: {
      readonly name: string;
    };
  } | null;
  readonly " $fragmentType": "PanelistLayout_user";
};
export type PanelistLayout_user$key = {
  readonly " $data"?: PanelistLayout_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistLayout_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelistLayout_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customPanelistPortal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accentColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accentColorLighter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerFontColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePointsAndRedemption",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalIncentives",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardsBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "convertedRewardsBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonNode",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "98c447941a02a48a9fa82a190f617e84";

export default node;
