/**
 * @generated SignedSource<<a419d9cdfd9ac79401c0af9587b0ba16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GridSingleSelectElement_element$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
  readonly " $fragmentType": "GridSingleSelectElement_element";
};
export type GridSingleSelectElement_element$key = {
  readonly " $data"?: GridSingleSelectElement_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"GridSingleSelectElement_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GridSingleSelectElement_element",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "8edd41916ccc32fdc73e6d99a72bb0e2";

export default node;
