import { Button } from "antd";
import { useRouter } from "found";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import type { RecordIndexable } from "../types";

const TestParticipantPage: React.FC = () => {
  const { match, router } = useRouter();
  const { t } = useTranslation();

  const descriptions: RecordIndexable<
    "default" | "register-redeem" | "opt-out" | "external-incentive",
    Record<"title" | "description", string>
  > = {
    default: {
      title: t("test-participant-page.default.title", "Thanks!"),
      description: t("test-participant-page.default.description", "Thank you for testing out the screener!"),
    },
    "register-redeem": {
      title: t("test-participant-page.register-redeem.title", "Redeem Points"),
      description: t(
        "test-participant-page.register-redeem.description",
        'This is a demonstration of the "Register to Redeem Points" link for test participants.'
      ),
    },
    "opt-out": {
      title: t("test-participant-page.opt-out.title", "Opt out"),
      description: t(
        "test-participant-page.opt-out.description",
        "This is a demonstration of the opt-out link for test participants."
      ),
    },
    "external-incentive": {
      title: t("test-participant-page.external-incentive.title", "External incentive"),
      description: t(
        "test-participant-page.external-incentive.description",
        "This is a demonstration of the external incentive link for test participants."
      ),
    },
  } as const;

  const description = descriptions[match.location.query.type || "default"] || descriptions.default;

  return (
    <StyledTestParticipantPage>
      <div>
        <Title>{description.title}</Title>
        <Description>{description.description}</Description>
        <Button size="large" onClick={() => router.push("/projects")}>
          {t("test-participant-page.home-button-text", "Take me home")}
        </Button>
      </div>
    </StyledTestParticipantPage>
  );
};

const StyledTestParticipantPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 44px;
`;

const Description = styled.div`
  font-size: 20px;
  margin-bottom: 24px;
`;

export default TestParticipantPage;
