/**
 * @generated SignedSource<<81d70a26c6683fac67ac18156ff1a2f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecruitNameModal_recruit$data = {
  readonly description: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "RecruitNameModal_recruit";
};
export type RecruitNameModal_recruit$key = {
  readonly " $data"?: RecruitNameModal_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitNameModal_recruit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitNameModal_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};

(node as any).hash = "e1737e35bac756e0780e2dcc21648683";

export default node;
