/**
 * @generated SignedSource<<c55c04546507433dd94e8b88852512e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditExternalIncentiveEmailModal_study$data = {
  readonly externalIncentive: {
    readonly automatedEmail: {
      readonly assets: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string | null;
            readonly sizeBytes: number | null;
            readonly url: string;
          } | null;
        } | null>;
      };
      readonly ctaLabel: string | null;
      readonly ctaUrl: string | null;
      readonly message: string;
      readonly replyTo: string;
      readonly subject: string;
    } | null;
    readonly claimLink: string | null;
  } | null;
  readonly id: string;
  readonly tenant: {
    readonly emailTemplates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SaveTemplateModal_tenant" | "SelectTemplateModal_tenant">;
  };
  readonly " $fragmentType": "EditExternalIncentiveEmailModal_study";
};
export type EditExternalIncentiveEmailModal_study$key = {
  readonly " $data"?: EditExternalIncentiveEmailModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditExternalIncentiveEmailModal_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditExternalIncentiveEmailModal_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalIncentiveNode",
      "kind": "LinkedField",
      "name": "externalIncentive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "claimLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomatedEmailNode",
          "kind": "LinkedField",
          "name": "automatedEmail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "replyTo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subject",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctaLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctaUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetNodeConnection",
              "kind": "LinkedField",
              "name": "assets",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sizeBytes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailTemplateConnection",
          "kind": "LinkedField",
          "name": "emailTemplates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailTemplateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EmailTemplateNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaveTemplateModal_tenant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SelectTemplateModal_tenant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "78f97788f3a8a15b7197c60d7e95fe65";

export default node;
