/**
 * @generated SignedSource<<020f28775616187b43cdd9f9975c5a71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ExportStatus = "Completed" | "Failed" | "InProgress" | "Sent" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExportButton_export$data = {
  readonly countCompleted: number;
  readonly countTotal: number;
  readonly id: string;
  readonly sentOn: any | null;
  readonly sentTo: string | null;
  readonly status: ExportStatus;
  readonly to: {
    readonly email: string;
  };
  readonly " $fragmentType": "ExportButton_export";
};
export type ExportButton_export$key = {
  readonly " $data"?: ExportButton_export$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExportButton_export",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "to",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExportNode",
  "abstractKey": null
};

(node as any).hash = "423bc0ebf7d8b7acc51fa9c8cbf646b3";

export default node;
