import { LeftOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useToggle } from "@react-hookz/web";
import { App, Button, Divider, Form, Input, InputRef } from "antd";
import { useWatch } from "antd/es/form/Form";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import classNames from "classnames";
import { useRouter } from "found";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { LoadingPage, LoggedOutPage } from ".";
import { checkAuth, getJwt, getTenantContext, getUserContext, setJwt, trackEvent, useMutation } from "../utils";
import { getApiBaseUrl, getFaviconEl } from "../utils/misc";
import type { LoginPageMutation, LoginPageMutation$data } from "../__generated__/LoginPageMutation.graphql";
import type {
  LoginPage_customPortal$data,
  LoginPage_customPortal$key,
} from "../__generated__/LoginPage_customPortal.graphql";

export const LoginPage = ({ customPortal: customPortalKey }: { customPortal?: LoginPage_customPortal$key }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const emailValue = useWatch("email", form);
  const [showPassword, toggleShowPassword] = useToggle();
  const { router, match } = useRouter();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mounting, setMounting] = useState<boolean>(true);
  const { t } = useTranslation();

  const customPortal = useFragment(
    graphql`
      fragment LoginPage_customPortal on CustomPortalNode {
        logoImageUrl
        footerImageUrl
        customColor
        faviconUrl
        pageTitle
        contactEmail
        customLandingHostname
        tenant {
          oidcProviders {
            id
            env
            name
            clientId
            authUrl
          }
        }
      }
    `,
    customPortalKey ?? null
  );

  const [commitLogin] = useMutation<LoginPageMutation>(graphql`
    mutation LoginPageMutation($email: String!, $password: String!) {
      tokenAuth(email: $email, password: $password) {
        token
        user {
          id
          dId
          email
          fullName
          isPanelist
          panelist {
            dId
            tenants {
              edges {
                node {
                  dId
                  name
                }
              }
            }
          }
          profile {
            tenant {
              id
              dId
              name
              enablePanelView
              dId
              vpmAccountId
              name
            }
          }
          dId
          vpmUserId
          panelist {
            dId
          }
        }
      }
    }
  `);

  const login = useCallback(
    (email: string, password: string) => {
      return new Promise<LoginPageMutation$data["tokenAuth"]>((resolve, reject) => {
        commitLogin(
          {},
          {
            variables: { email, password },
            onCompleted: ({ tokenAuth }: LoginPageMutation$data, errors) => {
              if (errors) {
                return reject(new Error(errors[0]?.message));
              }

              if (!tokenAuth?.token) {
                return reject(new Error("token not in response"));
              }

              setJwt(tokenAuth.token);

              resolve(tokenAuth);
            },
            onError: e => {
              reject(e);
            },
          }
        );
      });
    },
    [commitLogin]
  );

  const onFinish = ({ email, password }: { email: string; password: string }) => {
    setError(false);
    setLoading(true);

    login(email, password).then(
      tokenAuth => {
        const isTenantUser = tokenAuth?.user?.profile?.tenant?.id;
        const hasCSVDownloadParams = match?.location?.query?.d;

        if (isTenantUser && hasCSVDownloadParams) {
          router.push(`/csv-downloads?d=${match?.location?.query?.d}`);
        } else if (isTenantUser) {
          trackEvent("Webapp Log in", {
            ...getTenantContext(tokenAuth?.user?.profile?.tenant as any),
            ...getUserContext(tokenAuth?.user as any),
          });
          router.push("/projects");
        } else if (tokenAuth?.user?.isPanelist) {
          trackEvent("Portal Log in", {
            ...getTenantContext(tokenAuth?.user?.profile?.tenant as any),
            ...getUserContext(tokenAuth?.user as any),
          });
          router.push("/portal");
        }
      },
      err => {
        setError(true);
        setLoading(false);
        if (err.errors === "Locked out due to too many login failures") {
          message.error("Too many login attempts. Please contact us to unlock your account.");
        }

        if (err.message !== "Please enter valid credentials") {
          console.error(err);
        }
      }
    );
  };

  useEffect(() => {
    // On mount we check for a token. If found we check the token is valid.
    // If valid, we forward the user to the app
    const checkUser = async () => {
      const { jwt: token } = getJwt();

      if (token === null) {
        setMounting(false);
        return false;
      }

      const user = await checkAuth();
      if (!user.email) {
        setMounting(false);
      } else if (user.isTenant) {
        router.push("/projects");
      } else if (user.isPanelist) {
        router.push("/portal");
      }
    };
    checkUser();
  }, [router]);

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) {
      faviconEl.href = customPortal?.faviconUrl;
    }
    if (customPortal?.pageTitle) {
      document.title = customPortal?.pageTitle;
    }
  }, [customPortal]);

  useEffect(() => inputRef.current?.focus({ cursor: "all" }), [showPassword]);

  const handlePasswordToggle = async () => {
    if (!showPassword) {
      try {
        setLoading(true);

        await form.validateFields(["email"]);

        const result = await axios.get(`${getApiBaseUrl()}/api/get-login-url?e=${encodeURIComponent(emailValue)}`);
        const { login_url: loginUrl } = result.data;

        if (loginUrl) {
          window.location.href = loginUrl;
          // redirecting may be slow; maintain loading UI for a few seconds
          await new Promise(resolve => setTimeout(resolve, 3000));
          return;
        }

        toggleShowPassword();
      } catch (ex: any) {
        // ignore validation failures; otherwise show an error message
        if (!ex?.errorFields?.length) message.error("An error occurred; please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      form.setFieldValue("password", "");
      setError(false);
      toggleShowPassword();
    }
  };

  const getSsoLink = (oidcProvider: LoginPage_customPortal$data["tenant"]["oidcProviders"][number]) => {
    const env = runtimeEnv();
    const redirectUri = `${env.REACT_APP_FRONTEND_HOSTNAME}/sso-callback`;

    const path = `${oidcProvider.authUrl}/authorize`;
    const ssoUrl = new URL(path);

    if (oidcProvider.clientId) ssoUrl.searchParams.set("client_id", oidcProvider.clientId);
    ssoUrl.searchParams.set("response_type", "code");
    ssoUrl.searchParams.set("scope", "openid email profile offline_access");
    ssoUrl.searchParams.set("redirect_uri", redirectUri);
    ssoUrl.searchParams.set(
      "state",
      JSON.stringify({
        env: oidcProvider.env,
        // handle logout URLs if signing in via custom portal
        ...(customPortal?.customLandingHostname ? { logoutUrl: `${customPortal.customLandingHostname}/logout` } : {}),
      })
    );

    return ssoUrl.toString();
  };

  const getOidcProviderSection = () => {
    const oidcProviders = customPortal?.tenant?.oidcProviders;
    if (!oidcProviders?.length) return null;
    return (
      <>
        <Divider>or</Divider>
        {oidcProviders.map(oidcProvider => (
          <Form.Item key={oidcProvider.id}>
            <Button
              onClick={() => (window.location.href = getSsoLink(oidcProvider))}
              type="primary"
              htmlType="button"
              className="login-form-button"
            >
              Log in with {oidcProvider.name}
            </Button>
          </Form.Item>
        ))}
      </>
    );
  };

  return mounting ? (
    <LoadingPage />
  ) : (
    <LoggedOutPage
      customLogoUrl={customPortal?.logoImageUrl}
      customColor={customPortal?.customColor}
      customFooterUrl={customPortal?.footerImageUrl}
      contactEmail={customPortal?.contactEmail}
    >
      <Styled>
        <Form form={form} name="normal_login" initialValues={{ remember: true }} onFinish={onFinish}>
          <Form.Item
            hidden={showPassword}
            name="email"
            rules={[
              { required: true, message: t("login-page.email-required", "Please input your email") },
              { type: "email", message: t("login-page.email-invalid", "Please input a valid email") },
            ]}
            validateTrigger={["onBlur", "onSubmit"]}
          >
            <Input
              // workaround to prevent blurring the username input from triggering validation if custom SSO is enabled
              // and the login button is clicked
              autoFocus={!customPortal?.tenant.oidcProviders.length}
              ref={inputRef}
              disabled={loading}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t("login-page.email-placeholder", "Email")}
              autoComplete="username"
              onPressEnter={async evt => {
                evt.preventDefault();
                await handlePasswordToggle();
              }}
            />
          </Form.Item>

          <Form.Item hidden={!showPassword}>
            <Button
              style={{ width: "100%", textAlign: "left" }}
              type="text"
              icon={<LeftOutlined />}
              onClick={handlePasswordToggle}
            >
              {emailValue}
            </Button>
          </Form.Item>

          <Form.Item hidden={showPassword} className={classNames({ "centered-content": !showPassword })}>
            <Button disabled={!emailValue} loading={loading} onClick={handlePasswordToggle}>
              {t("login-page.next-button-text", "Next")}
            </Button>
          </Form.Item>

          <Form.Item
            hidden={!showPassword}
            name="password"
            rules={[{ required: true, message: t("login-page.password-required", "Please input your password") }]}
          >
            <Input
              ref={showPassword ? inputRef : undefined}
              readOnly={loading}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              autoComplete="current-password"
              placeholder={t("login-page.password-placeholder", "Password")}
            />
          </Form.Item>

          <Form.Item hidden={!showPassword} className="footer">
            {error && (
              <>
                <span className="general-error">{t("login-page.invalid-credentials", "Credentials incorrect")}</span>{" "}
                &ndash;{" "}
              </>
            )}
            <a className="login-form-forgot" href="/forgotpassword">
              {t("login-page.forgot-password", "Forgot password")}
            </a>
          </Form.Item>

          <Form.Item hidden={!showPassword} className={classNames({ "centered-content": showPassword })}>
            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
              {t("login-page.login-button-text", "Log in")}
            </Button>
          </Form.Item>
        </Form>
        {getOidcProviderSection()}
      </Styled>
    </LoggedOutPage>
  );
};

const Styled = styled.div`
  .centered-content {
    display: flex;
    justify-content: center;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .footer {
    height: 22px;
  }
`;
