import { useIntersectionObserver } from "@react-hookz/web";
import classNames, { type Argument } from "classnames";
import { ReactNode, useMemo, useRef } from "react";
import styled from "styled-components";

export const StickToBottom = ({
  children,
  className,
  root,
  tagName = "div",
}: {
  children?: ReactNode;
  className?: Argument;
  root?: (Parameters<typeof useIntersectionObserver>[1] & {})["root"];
  tagName?: keyof JSX.IntrinsicElements;
}) => {
  const ref = useRef<any>(null);

  const entry = useIntersectionObserver(ref, {
    root,
    rootMargin: "-1px",
    threshold: [1],
  });

  const stuck = useMemo(() => (entry?.intersectionRatio ?? 1) < 1, [entry?.intersectionRatio]);

  return (
    <Root as={tagName} className={classNames(className, { stuck })} ref={ref}>
      {children}
    </Root>
  );
};

const Root = styled.div`
  position: sticky;
  bottom: 0;
  transition: all 300ms;
`;
