/**
 * @generated SignedSource<<659bde2c75f68e8d7b9acd3281964659>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransactionType = "DP" | "PO" | "RF" | "%future added value";
export type PayForRecruitInput = {
  clientMutationId?: string | null;
  recruitId: string;
};
export type RecruitPaymentModal_PayForRecruit_Mutation$variables = {
  input: PayForRecruitInput;
};
export type RecruitPaymentModal_PayForRecruit_Mutation$data = {
  readonly payForRecruit: {
    readonly recruitTransaction: {
      readonly id: string;
      readonly recruit: {
        readonly id: string;
      };
      readonly transactionType: TransactionType;
      readonly value: any;
    } | null;
  } | null;
};
export type RecruitPaymentModal_PayForRecruit_Mutation = {
  response: RecruitPaymentModal_PayForRecruit_Mutation$data;
  variables: RecruitPaymentModal_PayForRecruit_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PayForRecruitPayload",
    "kind": "LinkedField",
    "name": "payForRecruit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RecruitTransactionNode",
        "kind": "LinkedField",
        "name": "recruitTransaction",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transactionType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RecruitNode",
            "kind": "LinkedField",
            "name": "recruit",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecruitPaymentModal_PayForRecruit_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecruitPaymentModal_PayForRecruit_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7b34d6ddda32d7c0c29e3e3e4d4c19c5",
    "id": null,
    "metadata": {},
    "name": "RecruitPaymentModal_PayForRecruit_Mutation",
    "operationKind": "mutation",
    "text": "mutation RecruitPaymentModal_PayForRecruit_Mutation(\n  $input: PayForRecruitInput!\n) {\n  payForRecruit(input: $input) {\n    recruitTransaction {\n      id\n      transactionType\n      value\n      recruit {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "538e8de0ae087b0b5f3d5071cf1ff27f";

export default node;
