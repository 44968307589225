import { Icon } from "@iconify/react";
import { isEqualSimple } from "@react-hookz/deep-equal";
import { usePrevious, useUpdateEffect } from "@react-hookz/web";
import { graphql } from "babel-plugin-relay/macro";
import { useReducer } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { IterableElement } from "type-fest";

import { InputTagsSelect_tenant$key } from "../../__generated__/InputTagsSelect_tenant.graphql";
import { TagTag } from "../Tag";

export const InputTagsSelect = ({
  onChange,
  tenantKey,
  value: _value = [],
}: {
  onChange?: (value: string[]) => void;
  tenantKey: InputTagsSelect_tenant$key;
  value?: string[];
}) => {
  const { tags } = useFragment(
    graphql`
      fragment InputTagsSelect_tenant on TenantNode {
        tags {
          dId
          ...TagTag_tag
        }
      }
    `,
    tenantKey
  );

  const [value, dispatchValue] = useReducer(
    (
      state: typeof _value,
      {
        action,
        dId,
      }:
        | { action: "select" | "deselect"; dId: IterableElement<typeof state> }
        | { action: "clear" | "reset"; dId?: undefined }
    ): typeof state => {
      switch (action) {
        case "clear":
          return [];
        case "deselect":
          return state.filter(x => x !== dId);
        case "reset":
          return _value;
        case "select":
          return [...state, dId];
      }
    },
    _value
  );
  useUpdateEffect(() => {
    if (!isEqualSimple(value, _value)) onChange?.(value);
  }, [value]);

  const _valuePrevious = usePrevious(_value);
  useUpdateEffect(() => {
    if (!isEqualSimple(_value, _valuePrevious) && !isEqualSimple(_value, value)) dispatchValue({ action: "reset" });
  }, [_value]);

  return tags.length ? (
    <StyledRoot className="hub-input-tags-select">
      {tags.map(x => {
        const { dId } = x;
        const selected = value.includes(dId);
        const onClick = () => dispatchValue({ action: selected ? "deselect" : "select", dId });
        return (
          <TagTag
            className={{ "hub-selected": selected }}
            closable={selected}
            closeIcon={<Icon icon="mdi:check" />}
            key={dId}
            onClick={onClick}
            onClose={onClick}
            tagKey={x}
          />
        );
      })}
    </StyledRoot>
  ) : null;
};

const StyledRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;

  > * {
    flex-shrink: 0;
  }

  > .hub-tag {
    cursor: pointer;

    &.hub-selected {
      background-color: currentColor;
      border-width: 0;

      * {
        color: white;
      }
    }
  }
`;
