import { ExportOutlined } from "@ant-design/icons";
import { Button } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useFragment } from "react-relay";
import {
  getAuthorizationHeader,
  getStudyContext,
  getTenantContext,
  handleErrorWithMessage,
  trackEvent,
} from "../../../utils";
import { getApiBaseUrl } from "../../../utils/misc";
import type { ExportFieldReportButton_study$key } from "../../../__generated__/ExportFieldReportButton_study.graphql";

export const ExportFieldReportButton = ({ study: studyKey }: { study: ExportFieldReportButton_study$key }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const study = useFragment(
    graphql`
      fragment ExportFieldReportButton_study on StudyNode {
        dId
        tenant {
          dId
          vpmAccountId
          name
        }
        dId
        name
        type
        status
      }
    `,
    studyKey
  );

  return (
    <Button
      icon={<ExportOutlined />}
      style={{ marginLeft: 12 }}
      type="text"
      loading={loading}
      disabled={loading}
      onClick={() => {
        setLoading(true);
        axios
          .request({
            url: `${getApiBaseUrl()}/s/${study.dId}/fieldReport`,
            headers: getAuthorizationHeader(),
            responseType: "blob",
          })
          .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "fieldReport.csv");
            document.body.appendChild(link);
            link.click();
            link.remove();
            trackEvent("Export Field Report", {
              ...getStudyContext(study),
              ...getTenantContext(study.tenant),
            });
          })
          .catch(err => handleErrorWithMessage(err))
          .finally(() => setLoading(false));
      }}
    >
      Export
    </Button>
  );
};
