import { isEqualSimple } from "@react-hookz/deep-equal";
import { useUpdateEffect } from "@react-hookz/web";
import { Input, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import { uniqueId } from "lodash-es";
import moment from "moment";
import { useMemo, useState, type CSSProperties } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import type { SetOptional } from "type-fest";

import { DatePicker, FilterOp, FilterStudyFilter, FilterStudyFilterType } from "..";
import { sentenceCase } from "../../utils";
import type { InputFilterStudyFilter_tenant$key } from "../../__generated__/InputFilterStudyFilter_tenant.graphql";
import { ButtonNot } from "./ButtonNot";

export type FilterStudyFilterIncomplete = SetOptional<FilterStudyFilter, "op" | "type">;

export const isValidFilter = (x: FilterStudyFilterIncomplete | undefined): x is FilterStudyFilter =>
  !!x && !!(x.op && x.type && x.values.length);

const createFilter = (): FilterStudyFilterIncomplete => ({
  id: uniqueId("filter"),
  negate: false,
  values: [],
});

export const InputFilterStudyFilter = ({
  className,
  onChange,
  style,
  value,
  tenant: tenantKey,
}: {
  className?: Argument;
  onChange: (x: FilterStudyFilter) => void;
  style?: CSSProperties;
  value?: FilterStudyFilterIncomplete;
  tenant: InputFilterStudyFilter_tenant$key;
}) => {
  const { tags: _tags, studiesLaunched } = useFragment(
    graphql`
      fragment InputFilterStudyFilter_tenant on TenantNode {
        tags {
          color
          dId
          id
          name
        }
        studiesLaunched {
          dId
          name
        }
      }
    `,
    tenantKey
  );
  const tags = useMemo(() => _tags ?? [], [_tags]);

  const [filter, setFilter] = useState(value || createFilter());
  useUpdateEffect(() => {
    if (!isEqualSimple(value, filter)) setFilter(value || createFilter());
  }, [value]);
  useUpdateEffect(() => {
    if (isValidFilter(filter) && !isEqualSimple(filter, value)) onChange(filter);
  }, [filter]);

  return (
    <StyledRoot className={classNames("hub-filter-participant-filter", className)} style={style}>
      <Select
        className="hub-filter-participant-filter-select-type"
        dropdownMatchSelectWidth={144}
        getPopupContainer={trigger => trigger.parentElement}
        onChange={(x: FilterStudyFilterType) => {
          setFilter({
            ...filter,
            type: x,
            ...(filter.op &&
            [x, filter.type]
              .filter((x): x is NonNullable<typeof x> => !!x)
              .every(x => [FilterStudyFilterType.Name, FilterStudyFilterType.Tags].includes(x))
              ? {
                  ...(filter.op === FilterOp.Is ? { values: [] } : {}),
                }
              : {
                  op: FilterOp.Is,
                  values: [],
                }),
          });
        }}
        placeholder="Select project property"
        value={filter.type ? (sentenceCase(filter.type) as FilterStudyFilterType) : undefined}
      >
        {[FilterStudyFilterType.Date, FilterStudyFilterType.Name, FilterStudyFilterType.Tags].map(x => {
          const noTags = x === FilterStudyFilterType.Tags && !tags.length;

          return (
            <Select.Option disabled={noTags} key={x} value={x}>
              {sentenceCase(x)} {noTags && "(none)"}
            </Select.Option>
          );
        })}
      </Select>
      {!!filter.type && (
        <>
          <ButtonNot filter={filter} setFilter={setFilter} />
          <Select
            disabled={!filter.type}
            onChange={x =>
              setFilter({
                ...filter,
                op: x,
                ...(filter.type === FilterStudyFilterType.Name ? { values: [] } : {}),
              })
            }
            placeholder="Operator"
            value={filter.op}
          >
            {!!filter.type &&
              (filter.type === FilterStudyFilterType.Date
                ? [FilterOp.Before, FilterOp.After, FilterOp.Is]
                : [FilterOp.Contains, FilterOp.Is]
              ).map(x => (
                <Select.Option key={x} value={x}>
                  {sentenceCase(x as `${typeof x}`)}
                </Select.Option>
              ))}
          </Select>
          {filter.type === FilterStudyFilterType.Date ? (
            <DatePicker
              className="hub-filter-participant-filter-values"
              format="YYYY-MM-DD"
              value={filter.values[0] ? moment(filter.values[0], "YYYY-MM-DD") : undefined}
              onChange={(e: any) => setFilter({ ...filter, values: [e.format("YYYY-MM-DD")] })}
            />
          ) : filter.type === FilterStudyFilterType.Name ? (
            filter.op === FilterOp.Is ? (
              <Select
                mode="multiple"
                className="input-values"
                value={filter.values}
                onChange={values => setFilter({ ...filter, values })}
                placeholder="Select a Project"
                showSearch
                optionFilterProp="children"
              >
                {studiesLaunched?.map(x => (
                  <Select.Option key={x.dId} value={x.dId}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            ) : filter.op === FilterOp.Contains ? (
              <Input
                type="text"
                className="input-values"
                placeholder={`Text — Use comma for multiple values, e.g. a, b, c`}
                value={filter.values?.join?.(",")}
                onChange={(e: any) => setFilter({ ...filter, values: e.target.value.split(",") })}
              />
            ) : null
          ) : filter.type === FilterStudyFilterType.Tags ? (
            <Select
              mode="multiple"
              className="input-values"
              value={filter.values}
              onChange={values => setFilter({ ...filter, values })}
              placeholder="Select a Tag"
              showSearch
              optionFilterProp="children"
            >
              {tags.map(x => (
                <Select.Option key={x.dId} value={x.dId}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          ) : null}
        </>
      )}
    </StyledRoot>
  );
};

const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: 100px auto 100px 1fr;
  gap: 10px;

  .hub-filter-participant-filter-select-type {
    &:only-child {
      grid-column: span 4;
    }

    .ant-select-item-option-content {
      text-transform: lowercase;

      &::before {
        content: "Project ";
        text-transform: capitalize;
      }
    }
  }
`;
