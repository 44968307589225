import { graphql } from "babel-plugin-relay/macro";
import { hotRouteConfig, makeRouteConfig, Redirect, RedirectException, Route, RouteRenderArgs } from "found";
import { uniqueId } from "lodash-es";
import { RelayEnvironmentProvider } from "react-relay";
import {
  App,
  Authenticated,
  AutomatedEmailsPage,
  CommunicationHistoryPage,
  CommunicationPage,
  CommunicationRemindersPage,
  CSVDownloadPage,
  DownloadPage,
  EmptyApp,
  ForgotPasswordPage,
  IncentiveEmailSettings,
  LaunchedPage,
  LoadingPage,
  LoginPage,
  LogoutPage,
  PasswordResetPage,
  ProjectOverviewPage,
  ProjectPage,
  ProjectParticipantsPage,
  ProjectScreenerPage,
  ProjectSegmentsPage,
  ProjectsPage,
  RegistrationPage,
} from ".";
import { DEFAULT_PARTICIPANTS_FETCH_COUNT } from "../constants";
import { environment } from "../relay";
import { getJwt } from "../utils";
import type { Authenticated_user$data } from "../__generated__/Authenticated_user.graphql";
import type { CommunicationHistoryPage_study$data } from "../__generated__/CommunicationHistoryPage_study.graphql";
import type { CommunicationRemindersPage_study$data } from "../__generated__/CommunicationRemindersPage_study.graphql";
import type { CreateProject_viewer$data } from "../__generated__/CreateProject_viewer.graphql";
import type { LaunchedPage_study$data } from "../__generated__/LaunchedPage_study.graphql";
import type { Portal_user$data } from "../__generated__/Portal_user.graphql";
import type { ProjectDetailsPage_study$data } from "../__generated__/ProjectDetailsPage_study.graphql";
import type { ProjectOverviewPage_study$data } from "../__generated__/ProjectOverviewPage_study.graphql";
import type { ProjectPage_study$data } from "../__generated__/ProjectPage_study.graphql";
import type { ProjectParticipantsPage_study$data } from "../__generated__/ProjectParticipantsPage_study.graphql";
import type { ProjectParticipantsPage_user$data } from "../__generated__/ProjectParticipantsPage_user.graphql";
import type { ProjectScreenerPage_screener$data } from "../__generated__/ProjectScreenerPage_screener.graphql";
import type { ProjectSegmentsPage_screener$data } from "../__generated__/ProjectSegmentsPage_screener.graphql";
import type { ProjectsPage_viewer$data } from "../__generated__/ProjectsPage_viewer.graphql";
import type { RecruitPage_study$data } from "../__generated__/RecruitPage_study.graphql";
import type { SlotsPage_study$data } from "../__generated__/SlotsPage_study.graphql";
import type { WorkspaceSettings_user$data } from "../__generated__/WorkspaceSettings_user.graphql";
import BillingPage from "./Billing/BillingPage";
import { PanelPage, PanelReport } from "./Panel";
import { CharacteristicsEditPage } from "./Panel/CharacteristicsEditPage";
import PanelistsReportWrapper from "./Panel/PanelistsReportWrapper";
import PanelSetupPage from "./Panel/PanelSetupPage";
import Portal from "./ParticipantPortal/Portal";
import { PortalProfile, PortalProfileComplete } from "./ParticipantPortal/PortalProfile";
import PortalRedeem from "./ParticipantPortal/PortalRedeem";
import { default as PortalRegistrationPage } from "./ParticipantPortal/PortalRegistrationPage";
import CreateProject from "./Project/CreateProject";
import ProjectDetails from "./Project/ProjectDetails";
import RecruitPage from "./Recruit/RecruitPage";
import SlotsPage from "./Slots/SlotsPage";
import { SsoCallbackPage } from "./SsoCallbackPage";
import { SwitchProfilePage } from "./SwitchProfilePage";
import TestParticipantPage from "./TestParticipantPage";
import CalendarAuthFinish from "./WorkspaceSettings/CalendarAuthFinish";
import ManagePaymentFinish from "./WorkspaceSettings/ManagePaymentFinish";
import WorkspaceSettings from "./WorkspaceSettings/WorkspaceSettings";

const withNonce = (params: any) => ({
  ...params,
  nonce: uniqueId(),
});

const routeConfig = makeRouteConfig(
  <Route path="/" Component={App}>
    <Route path="switch-profile/:profileId" Component={SwitchProfilePage} />
    <Route
      Component={Authenticated}
      query={graphql`
        query RouteConfigQuery {
          viewer {
            user {
              ...Authenticated_user
            }
          }
        }
      `}
      render={({ props, match }: any) => {
        const { jwt: token } = getJwt();

        if (token === null) {
          return match.router.push("/logout");
        }

        return (props?.viewer?.user as Authenticated_user$data) ? (
          <Authenticated user={props.viewer.user} {...props} />
        ) : (
          <EmptyApp />
        );
      }}
    >
      <Route path="csv-downloads" Component={CSVDownloadPage} />
      <Route path="downloads" Component={DownloadPage} />
      <Route
        path="billing"
        query={graphql`
          query RouteConfig_BillingPage_Query {
            viewer {
              user {
                profile {
                  tenant {
                    hideBillingPage
                  }
                }
                ...BillingPage_user
              }
            }
          }
        `}
        render={({ props, errors }: any) =>
          errors ? (
            <p>error</p>
          ) : props?.viewer?.user && !props?.viewer?.user?.profile?.tenant?.hideBillingPage ? (
            <BillingPage user={props.viewer.user} />
          ) : (
            <LoadingPage />
          )
        }
        Component={BillingPage}
      />
      <Route path="panel-setup" Component={PanelSetupPage} />
      <Route path="panel" Component={PanelPage}>
        <Route
          path="report"
          query={graphql`
            query RouteConfig_PanelReport_Query {
              viewer {
                panel {
                  ...PanelReport_panel
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return props?.viewer?.panel ? (
              <>
                <PanelReport panel={props.viewer.panel} />
              </>
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route path="panelists" Component={PanelistsReportWrapper} />
        <Route
          path="characteristics"
          query={graphql`
            query RouteConfig_CharacteristicsEditPage_Query {
              viewer {
                user {
                  profile {
                    tenant {
                      ...CharacteristicsEditPage_tenant
                    }
                  }
                  ...CharacteristicsEditPage_user
                }
              }
            }
          `}
          render={({ props }: any) =>
            props?.viewer?.user?.profile?.tenant ? (
              <CharacteristicsEditPage user={props.viewer.user} tenant={props.viewer.user.profile.tenant} />
            ) : (
              <LoadingPage positionTopThird />
            )
          }
        />
      </Route>
      <Route
        path="projects"
        Component={ProjectsPage}
        query={graphql`
          query RouteConfig_ProjectsPage_Query {
            viewer {
              ...ProjectsPage_viewer
            }
          }
        `}
        render={({ props, errors }: any) => {
          if (errors) {
            return <div className="text">Error.</div>;
          }

          return (props?.viewer as ProjectsPage_viewer$data) ? (
            <ProjectsPage viewer={props.viewer} />
          ) : (
            <LoadingPage positionTopThird />
          );
        }}
      />
      <Route
        path="projects/create"
        Component={CreateProject}
        query={graphql`
          query RouteConfig_CreateProject_Query {
            viewer {
              languages {
                name
                code
              }
              privacyPolicyOptions {
                languageCode
                language
              }
              ...CreateProject_viewer
            }
          }
        `}
        render={({ props, errors }: any) => {
          if (errors) {
            return <div className="text">Error.</div>;
          }

          return (props?.viewer as CreateProject_viewer$data) &&
            props.viewer.languages &&
            props.viewer.privacyPolicyOptions ? (
            <CreateProject
              viewer={props.viewer}
              languages={props.viewer.languages}
              privacyPolicyOptions={props.viewer.privacyPolicyOptions}
            />
          ) : (
            <LoadingPage positionTopThird />
          );
        }}
      />
      <Route
        path="projects/:studyId"
        Component={ProjectPage}
        query={graphql`
          query RouteConfig_ProjectPage_Query($studyId: String!) {
            viewer {
              study(studyId: $studyId) {
                ...ProjectPage_study
              }
            }
          }
        `}
        render={({ props, errors, match }: any) => {
          if (errors) {
            return <div className="text">Error.</div>;
          }

          return (props?.viewer?.study as ProjectPage_study$data) ? (
            <ProjectPage study={props?.viewer?.study} {...props} />
          ) : (
            <LoadingPage positionTopThird />
          );
        }}
      >
        <Route
          path="overview"
          query={graphql`
            query RouteConfig_ProjectOverviewPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...ProjectOverviewPage_study
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as ProjectOverviewPage_study$data) ? (
              <ProjectOverviewPage study={props.viewer.study} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="slots"
          query={graphql`
            query RouteConfig_SlotsPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...SlotsPage_study
                }
                languages {
                  name
                  code
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as SlotsPage_study$data) ? (
              <SlotsPage study={props.viewer.study} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="settings"
          query={graphql`
            query RouteConfig_ProjectDetails_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...ProjectDetails_study
                  tenant {
                    ...ProjectDetails_tenant
                  }
                }
                languages {
                  name
                  code
                }
                ...ProjectDetails_viewer
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }
            return (props?.viewer?.study as ProjectDetailsPage_study$data) && props?.viewer?.languages ? (
              <ProjectDetails
                study={props.viewer.study}
                tenant={props.viewer.study.tenant}
                languages={props.viewer.languages}
                {...props}
              />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="incentive-email-settings"
          query={graphql`
            query RouteConfig_IncentiveEmailSetup_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...IncentiveEmailSettings_study
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }
            return (props?.viewer?.study as ProjectDetailsPage_study$data) ? (
              <IncentiveEmailSettings study={props.viewer.study} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="screener"
          query={graphql`
            query RouteConfig_ProjectScreenerPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  screener {
                    ...ProjectScreenerPage_screener
                  }
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study?.screener as ProjectScreenerPage_screener$data) ? (
              <ProjectScreenerPage screener={props.viewer.study.screener} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="recruit"
          prepareVariables={(params: any) => {
            return {
              ...params,
            };
          }}
          query={graphql`
            query RouteConfig_RecruitPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...RecruitPage_study
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as RecruitPage_study$data) ? (
              <RecruitPage study={props.viewer.study} user={props.viewer.user} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="segments"
          query={graphql`
            query RouteConfig_ProjectSegmentsPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  screener {
                    ...ProjectSegmentsPage_screener
                  }
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study?.screener as ProjectSegmentsPage_screener$data) ? (
              <ProjectSegmentsPage screener={props.viewer.study.screener} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="communications/"
          Component={CommunicationPage}
          query={graphql`
            query RouteConfig_CommunicationPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...CommunicationPage_study
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as ProjectPage_study$data) ? (
              <CommunicationPage study={props.viewer.study} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        >
          <Route
            path="history"
            query={graphql`
              query RouteConfig_CommunicationHistoryPage_Query($studyId: String!) {
                viewer {
                  study(studyId: $studyId) {
                    ...CommunicationHistoryPage_study
                  }
                }
              }
            `}
            render={({ props, errors, retry }: any) => {
              if (errors) {
                return <div className="text">Error.</div>;
              }

              return (props?.viewer?.study as CommunicationHistoryPage_study$data) ? (
                <CommunicationHistoryPage retry={retry} study={props.viewer.study} {...props} />
              ) : (
                <LoadingPage />
              );
            }}
          />
          <Route
            path="automated-emails"
            query={graphql`
              query RouteConfig_CommunicationTemplatesPage_Query($studyId: String!) {
                viewer {
                  study(studyId: $studyId) {
                    ...AutomatedEmailsPage_study
                  }
                }
              }
            `}
            render={({ props, errors, retry }: any) => {
              if (errors) {
                return <div className="text">Error.</div>;
              }

              return (props?.viewer?.study as CommunicationHistoryPage_study$data) ? (
                <AutomatedEmailsPage study={props.viewer.study} {...props} />
              ) : (
                <LoadingPage />
              );
            }}
          />
        </Route>
        <Route
          path="reminders"
          prepareVariables={(params: any) => {
            return {
              ...params,
              count: 10,
              search: null,
            };
          }}
          query={graphql`
            query RouteConfig_CommunicationRemindersPage_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...CommunicationRemindersPage_study
                }
              }
            }
          `}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as CommunicationRemindersPage_study$data) ? (
              <CommunicationRemindersPage study={props.viewer.study} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
        <Route
          path="respondents/participants"
          query={graphql`
            query RouteConfig_ParticipantsPage_Query($studyId: String!, $count: Int!, $elementIds: [ID]) {
              viewer {
                study(studyId: $studyId) {
                  ...ProjectParticipantsPage_study
                  ...ParticipantPaymentModal_study
                  ...ScheduleModal_study
                  ...BulkScheduleModal_study
                  ...SlotPicker_study
                }
                user {
                  ...ProjectParticipantsPage_user
                }
              }
            }
          `}
          prepareVariables={(params: any) => {
            return {
              ...params,
              count: DEFAULT_PARTICIPANTS_FETCH_COUNT,
              elementIds: [null],
            };
          }}
          render={({ props, errors }: any) => {
            if (errors) {
              return <div className="text">Error.</div>;
            }

            return (props?.viewer?.study as ProjectParticipantsPage_study$data) &&
              (props?.viewer?.user as ProjectParticipantsPage_user$data) ? (
              <ProjectParticipantsPage study={props.viewer.study} user={props.viewer.user} {...props} />
            ) : (
              <LoadingPage positionTopThird />
            );
          }}
        />
      </Route>
      <Route
        path="launched/:studyId"
        Component={LaunchedPage}
        query={graphql`
          query RouteConfig_LaunchedPage_Query($studyId: String!) {
            viewer {
              study(studyId: $studyId) {
                ...LaunchedPage_study
              }
            }
          }
        `}
        render={({ props, errors }: any) => {
          if (errors) {
            return <div className="text">Error.</div>;
          }

          return (props?.viewer?.study as LaunchedPage_study$data) ? (
            <LaunchedPage study={props.viewer.study} {...props} />
          ) : (
            <LoadingPage positionTopThird />
          );
        }}
      />
      <Route
        path="redeem"
        query={graphql`
          query RouteConfig_PortalRedeem_Query {
            viewer {
              user {
                isPanelist
                panelist {
                  ...PortalRedeem_panelist
                }
              }
            }
          }
        `}
        render={({ props, match, errors }: any) => {
          if (errors) {
            return <div className="text">Error.</div>;
          }

          if (props?.viewer?.user as Portal_user$data) {
            if (props?.viewer?.user?.isPanelist) {
              return <PortalRedeem viewer={props.viewer} panelist={props.viewer.user.panelist} {...props} />;
            } else {
              // if user is not a panelist, we redirect to login
              return match.router.push("/login");
            }
          } else {
            return <LoadingPage />;
          }
        }}
      />
      <Route
        path="portal"
        Component={Portal}
        prepareVariables={withNonce}
        query={graphql`
          query RouteConfig_Portal_Query {
            viewer {
              user {
                isPanelist
                ...Portal_user
              }
            }
          }
        `}
        render={({ props, match, errors }: any) => {
          if (errors) {
            return <div className="text">Error.</div>;
          }

          if (props?.viewer?.user as Portal_user$data) {
            if (props?.viewer?.user?.isPanelist) {
              return <Portal user={props.viewer.user} {...props} />;
            } else {
              // if user is not a panelist, we redirect to login
              return match.router.push("/login");
            }
          } else {
            return <LoadingPage positionTopThird />;
          }
        }}
      />
      <Route
        path="portal/profile"
        Component={PortalProfile}
        prepareVariables={withNonce}
        query={graphql`
          query RouteConfig_PortalProfile_Query {
            viewer {
              user {
                isPanelist
                ...PortalProfile_user
              }
            }
          }
        `}
        render={({ props, match, errors }: any) => {
          if (errors) return <div className="text">Error.</div>;
          if (!props?.viewer?.user) return <LoadingPage positionTopThird />;
          if (!props.viewer.user.isPanelist) return match.router.push("/login");

          return <PortalProfile user={props.viewer.user} />;
        }}
      />
      <Route
        path="portal/profile/complete/:characteristicId"
        Component={PortalProfileComplete}
        prepareVariables={withNonce}
        query={graphql`
          query RouteConfig_PortalProfileComplete_Query($characteristicId: String!) {
            viewer {
              characteristic(characteristicId: $characteristicId) {
                ...PortalProfileComplete_characteristic
              }
              user {
                panelist {
                  ...PortalProfileComplete_panelist
                }
              }
            }
          }
        `}
        render={({ props: _props, match, errors }: RouteRenderArgs & { errors?: any }) => {
          if (errors) return <div className="text">Error.</div>;

          const props = _props as any;
          if (!props?.viewer?.user) return <LoadingPage positionTopThird />;
          if (!props.viewer.user.panelist) throw new RedirectException("/login", 401);

          return (
            <PortalProfileComplete
              characteristic={props.viewer.characteristic}
              characteristicNextId={match.location.query.next}
              from={match.location.query.from}
              panelist={props.viewer.user.panelist}
            />
          );
        }}
      />
      <Route
        path="workspace/settings"
        Component={WorkspaceSettings}
        query={graphql`
          query RouteConfig_WorkspaceSettings_Query {
            viewer {
              user {
                id
                ...WorkspaceSettings_user
              }
            }
          }
        `}
        render={({ props, match, errors }: any) => {
          if (errors) {
            return <div>Error</div>;
          }

          if (props?.viewer?.user as WorkspaceSettings_user$data) {
            return <WorkspaceSettings user={props.viewer.user} match={match} {...props} />;
          } else {
            return <LoadingPage positionTopThird />;
          }
        }}
      />
      <Route
        path="calendar/finish"
        Component={CalendarAuthFinish}
        render={() => {
          return <CalendarAuthFinish />;
        }}
      />
      <Route
        path="manage-payment/finish"
        Component={ManagePaymentFinish}
        render={() => {
          return <ManagePaymentFinish />;
        }}
      />
    </Route>

    {/* Unauthenticted routes */}

    <Route
      path="login"
      Component={LoginPage}
      query={graphql`
        query RouteConfig_Login_Query($customLandingHostname: String!) {
          viewer {
            customPortal(customLandingHostname: $customLandingHostname) {
              ...LoginPage_customPortal
            }
          }
        }
      `}
      prepareVariables={(params: any) => {
        return {
          ...params,
          customLandingHostname: window.location.origin,
        };
      }}
      render={({ Component, props, errors }: any) => {
        if (errors) {
          return <div className="text">Error.</div>;
        }

        if (Component && props?.viewer) {
          return <LoginPage customPortal={props?.viewer?.customPortal} />;
        } else {
          return <LoadingPage positionTopThird />;
        }
      }}
    />
    <Route
      path="sso-callback"
      Component={SsoCallbackPage}
      render={() => (
        <RelayEnvironmentProvider environment={environment}>
          <SsoCallbackPage />
        </RelayEnvironmentProvider>
      )}
    />
    <Route
      path="register"
      Component={PortalRegistrationPage}
      query={graphql`
        query RouteConfig_Register_Query($customLandingHostname: String!) {
          viewer {
            customPortal(customLandingHostname: $customLandingHostname) {
              ...PortalRegistrationPage_customPortal
            }
          }
        }
      `}
      prepareVariables={(params: any) => ({
        ...params,
        customLandingHostname: window.location.origin,
      })}
      render={({ Component, props, errors }: any) =>
        errors ? (
          <div className="text">Error.</div>
        ) : Component && props?.viewer ? (
          <PortalRegistrationPage customPortal={props?.viewer?.customPortal} />
        ) : (
          <LoadingPage positionTopThird />
        )
      }
    />
    <Route
      path="forgotpassword"
      Component={ForgotPasswordPage}
      query={graphql`
        query RouteConfig_ForgotPasswordPage_Query($customLandingHostname: String!) {
          viewer {
            customPortal(customLandingHostname: $customLandingHostname) {
              ...ForgotPasswordPage_customPortal
            }
          }
        }
      `}
      prepareVariables={(params: any) => {
        return {
          ...params,
          customLandingHostname: window.location.origin,
        };
      }}
      render={({ Component, props, errors }: any) => {
        if (errors) {
          return <div className="text">Error.</div>;
        }

        if (Component && props?.viewer) {
          return <ForgotPasswordPage customPortal={props?.viewer?.customPortal} />;
        } else {
          return <LoadingPage positionTopThird />;
        }
      }}
    />
    <Route
      path="password_reset/confirm"
      Component={ForgotPasswordPage}
      query={graphql`
        query RouteConfig_PasswordResetPage_Query($customLandingHostname: String!) {
          viewer {
            customPortal(customLandingHostname: $customLandingHostname) {
              ...PasswordResetPage_customPortal
            }
          }
        }
      `}
      prepareVariables={(params: any) => {
        return {
          ...params,
          customLandingHostname: window.location.origin,
        };
      }}
      render={({ Component, props, errors }: any) => {
        if (errors) {
          return <div className="text">Error.</div>;
        }

        if (Component && props?.viewer) {
          return <PasswordResetPage customPortal={props?.viewer?.customPortal} />;
        } else {
          return <LoadingPage positionTopThird />;
        }
      }}
    />
    <Route path="logout" Component={LogoutPage} />
    <Route
      path="app-register"
      Component={RegistrationPage}
      render={() => (
        <RelayEnvironmentProvider environment={environment}>
          <RegistrationPage />
        </RelayEnvironmentProvider>
      )}
    />
    <Route path="test-participant" Component={TestParticipantPage} />
    <Redirect from="/" to="/login" />
    <Redirect from="projects/:studyId" to="/projects/:studyId/overview" />
    <Redirect from="projects/:studyId/communications" to="/projects/:studyId/communications/history" />
  </Route>
);

export default process.env.NODE_ENV === "development" ? hotRouteConfig(routeConfig) : routeConfig;
