import { graphql } from "babel-plugin-relay/macro";
import { mutation } from "../../utils";
import { ParticipantsBatchMutations_approveAllRespondents_Mutation } from "../../__generated__/ParticipantsBatchMutations_approveAllRespondents_Mutation.graphql";
import { ParticipantsBatchMutations_approveRespondents_Mutation } from "../../__generated__/ParticipantsBatchMutations_approveRespondents_Mutation.graphql";
import { ParticipantsBatchMutations_DenyAllRespondents_Mutation } from "../../__generated__/ParticipantsBatchMutations_DenyAllRespondents_Mutation.graphql";
import { ParticipantsBatchMutations_DenyRespondents_Mutation } from "../../__generated__/ParticipantsBatchMutations_DenyRespondents_Mutation.graphql";
import { ParticipantsBatchMutations_RateAllParticipants_Mutation } from "../../__generated__/ParticipantsBatchMutations_RateAllParticipants_Mutation.graphql";
import { ParticipantsBatchMutations_RateParticipants_Mutation } from "../../__generated__/ParticipantsBatchMutations_RateParticipants_Mutation.graphql";
import { ParticipantsBatchMutations_RewardAllParticipants_Mutation } from "../../__generated__/ParticipantsBatchMutations_RewardAllParticipants_Mutation.graphql";
import { ParticipantsBatchMutations_RewardParticipants_Mutation } from "../../__generated__/ParticipantsBatchMutations_RewardParticipants_Mutation.graphql";
import { ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation } from "../../__generated__/ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation.graphql";
import { ParticipantsBatchMutations_ScheduleFocusGroupParticipants_Mutation } from "../../__generated__/ParticipantsBatchMutations_ScheduleFocusGroupParticipants_Mutation.graphql";
import type { ParticipantFilterGroupGroup } from "../Filters";
import { TabType } from "./ParticipantsTable";

export enum BatchMutationType {
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  SCHEDULE = "SCHEDULE",
  RATE = "RATE",
  REWARD = "REWARD",
  GIVE_POINTS = "GIVE_POINTS",
}

export interface IParticipantBatch {
  studyId: string;
  filterComplex: ParticipantFilterGroupGroup;
  tab: TabType;
  excludeIds: string[];
  countToSelect: number;
  selectAllChecked: boolean;
  selectedIds: string[];
  mutationType: BatchMutationType;
  slotId?: string;
  noShow?: boolean;
  rating?: number;
}
export const participantBatchAction = (props: IParticipantBatch) => {
  switch (props.mutationType) {
    case BatchMutationType.APPROVE:
      return approveMutation(props);
    case BatchMutationType.REJECT:
      return denyMutation(props);
    case BatchMutationType.SCHEDULE:
      return scheduleMutation(props);
    case BatchMutationType.RATE:
      return rateMutation(props);
    case BatchMutationType.REWARD:
      return rewardMutation(props);
    default:
      return null;
  }
};

const approveMutation = (props: IParticipantBatch) => {
  const { studyId, filterComplex, tab, excludeIds, countToSelect, selectAllChecked, selectedIds } = props;

  if (selectAllChecked) {
    return mutation<ParticipantsBatchMutations_approveAllRespondents_Mutation>({
      variables: {
        input: {
          studyId,
          countToSelect,
          excludeIds,
          tab,
          filterComplex,
        },
      },
      mutation: graphql`
        mutation ParticipantsBatchMutations_approveAllRespondents_Mutation($input: ApproveAllRespondentsInput!) {
          approveAllRespondents(input: $input) {
            study {
              id
              needsReviewCount
              needsPaymentCount
              approvedTabCount
              rejectedCount
              recruits {
                edges {
                  node {
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  } else {
    if (!selectedIds.length) {
      return null;
    }
    return mutation<ParticipantsBatchMutations_approveRespondents_Mutation>({
      variables: { input: { studyId, participantIds: selectedIds as string[] } },
      mutation: graphql`
        mutation ParticipantsBatchMutations_approveRespondents_Mutation($input: ApproveRespondentsInput!) {
          approveRespondents(input: $input) {
            study {
              id
              needsReviewCount
              needsPaymentCount
              approvedTabCount
              rejectedCount
              recruits {
                edges {
                  node {
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  }
};

const denyMutation = (props: IParticipantBatch) => {
  const { studyId, filterComplex, tab, excludeIds, countToSelect, selectAllChecked, selectedIds } = props;

  if (selectAllChecked) {
    return mutation<ParticipantsBatchMutations_DenyAllRespondents_Mutation>({
      variables: {
        input: {
          studyId,
          countToSelect,
          excludeIds,
          tab,
          filterComplex,
        },
      },
      mutation: graphql`
        mutation ParticipantsBatchMutations_DenyAllRespondents_Mutation($input: DenyAllRespondentsInput!) {
          denyAllRespondents(input: $input) {
            study {
              id
              needsReviewCount
              rejectedCount
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  } else {
    if (!selectedIds.length) {
      return null;
    }
    return mutation<ParticipantsBatchMutations_DenyRespondents_Mutation>({
      variables: { input: { studyId, participantIds: selectedIds as string[] } },
      mutation: graphql`
        mutation ParticipantsBatchMutations_DenyRespondents_Mutation($input: DenyRespondentsInput!) {
          denyRespondents(input: $input) {
            study {
              id
              needsReviewCount
              rejectedCount
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  }
};

const scheduleMutation = (props: IParticipantBatch) => {
  const { studyId, filterComplex, tab, excludeIds, countToSelect, selectAllChecked, selectedIds, slotId } = props;

  if (selectAllChecked) {
    return mutation<ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation>({
      variables: {
        input: {
          studyId,
          countToSelect,
          excludeIds,
          tab,
          filterComplex,
          slotId,
        },
      },
      mutation: graphql`
        mutation ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation(
          $input: ScheduleAllFocusGroupParticipantsInput!
        ) {
          scheduleAllFocusGroupParticipants(input: $input) {
            study {
              id
              needsPaymentCount
              completeCount
              rewardedCount
              goalPaidForCount
              totalIncentives
              totalFees
              totalPerParticipantCosts
              totalDeposits
              totalPayouts
              totalRefunds
              totalRefundsDue
              recruits {
                edges {
                  node {
                    name
                    rewardedCount
                    goalPaidForCount
                    incentive
                    fee
                    totalDeposits
                    totalPayouts
                    totalRefunds
                    totalRefundsDue
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  } else {
    if (!selectedIds.length) {
      return null;
    }
    return mutation<ParticipantsBatchMutations_ScheduleFocusGroupParticipants_Mutation>({
      variables: { input: { participantIds: selectedIds as string[], slotId } },
      mutation: graphql`
        mutation ParticipantsBatchMutations_ScheduleFocusGroupParticipants_Mutation(
          $input: ScheduleFocusGroupParticipantsInput!
        ) {
          scheduleFocusGroupParticipants(input: $input) {
            study {
              id
              needsPaymentCount
              completeCount
              rewardedCount
              goalPaidForCount
              totalIncentives
              totalFees
              totalPerParticipantCosts
              totalDeposits
              totalPayouts
              totalRefunds
              totalRefundsDue
              recruits {
                edges {
                  node {
                    name
                    rewardedCount
                    goalPaidForCount
                    incentive
                    fee
                    totalDeposits
                    totalPayouts
                    totalRefunds
                    totalRefundsDue
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  }
};

const rateMutation = (props: IParticipantBatch) => {
  const { studyId, filterComplex, tab, excludeIds, countToSelect, selectAllChecked, selectedIds, noShow, rating } =
    props;

  if (selectAllChecked) {
    return mutation<ParticipantsBatchMutations_RateAllParticipants_Mutation>({
      variables: {
        input: {
          studyId,
          countToSelect,
          excludeIds,
          tab,
          filterComplex,
          noShow,
          rating,
        },
      },
      mutation: graphql`
        mutation ParticipantsBatchMutations_RateAllParticipants_Mutation($input: RateAllParticipantsInput!) {
          rateAllParticipants(input: $input) {
            study {
              id
              needsPaymentCount
              rejectedCount
              recruits {
                edges {
                  node {
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  } else {
    if (!selectedIds.length) {
      return null;
    }
    return mutation<ParticipantsBatchMutations_RateParticipants_Mutation>({
      variables: {
        input: { studyId, participantIds: selectedIds as string[], noShow: noShow, rating: rating },
      },
      mutation: graphql`
        mutation ParticipantsBatchMutations_RateParticipants_Mutation($input: RateParticipantsInput!) {
          rateParticipants(input: $input) {
            study {
              id
              needsPaymentCount
              rejectedCount
              recruits {
                edges {
                  node {
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  }
};

const rewardMutation = (props: IParticipantBatch) => {
  const { studyId, filterComplex, tab, excludeIds, countToSelect, selectAllChecked, selectedIds } = props;

  if (selectAllChecked) {
    return mutation<ParticipantsBatchMutations_RewardAllParticipants_Mutation>({
      variables: {
        input: {
          studyId,
          countToSelect,
          excludeIds,
          tab,
          filterComplex,
        },
      },
      mutation: graphql`
        mutation ParticipantsBatchMutations_RewardAllParticipants_Mutation($input: RewardAllParticipantsInput!) {
          rewardAllParticipants(input: $input) {
            study {
              id
              needsPaymentCount
              completeCount
              rewardedCount
              goalPaidForCount
              totalIncentives
              totalFees
              totalPerParticipantCosts
              totalDeposits
              totalPayouts
              totalRefunds
              totalRefundsDue
              recruits {
                edges {
                  node {
                    name
                    rewardedCount
                    goalPaidForCount
                    incentive
                    fee
                    totalDeposits
                    totalPayouts
                    totalRefunds
                    totalRefundsDue
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  } else {
    if (!selectedIds.length) {
      return null;
    }
    return mutation<ParticipantsBatchMutations_RewardParticipants_Mutation>({
      variables: { input: { studyId, participantIds: selectedIds as string[] } },
      mutation: graphql`
        mutation ParticipantsBatchMutations_RewardParticipants_Mutation($input: RewardParticipantsInput!) {
          rewardParticipants(input: $input) {
            study {
              id
              needsPaymentCount
              completeCount
              rewardedCount
              goalPaidForCount
              totalIncentives
              totalFees
              totalPerParticipantCosts
              totalDeposits
              totalPayouts
              totalRefunds
              totalRefundsDue
              recruits {
                edges {
                  node {
                    name
                    rewardedCount
                    goalPaidForCount
                    incentive
                    fee
                    totalDeposits
                    totalPayouts
                    totalRefunds
                    totalRefundsDue
                    status
                  }
                }
              }
            }
          }
        }
      `,
      silenceDefaultError: true,
    });
  }
};
