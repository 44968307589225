import { Card, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES, SELECTABLE_ELEMENT_TYPES } from "../../constants";
import { UsePreviousAnswers_element$data } from "../../__generated__/UsePreviousAnswers_element.graphql";
import { UsePreviousAnswers_screener$data } from "../../__generated__/UsePreviousAnswers_screener.graphql";
import { DetailsInput } from "../Configure/DetailsInput";

const { Option } = Select;

type Props = {
  screener: UsePreviousAnswers_screener$data;
  element: UsePreviousAnswers_element$data;
  selectedPreviousQuestionId: string | null | undefined;
  handleUsePreviousAnswersElementChange: (elementId: string) => void;
  handleUsePreviousAnswersChange: (conditions: string[]) => void;
};
const UsePreviousAnswers: React.FC<Props> = ({
  screener,
  element,
  selectedPreviousQuestionId,
  handleUsePreviousAnswersElementChange,
  handleUsePreviousAnswersChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[] | null | undefined>(
    JSON.parse(element?.usePreviousAnswers)?.conditions || undefined
  );

  const getAnswerOptions = () => {
    if (!selectedPreviousQuestionId) {
      return [];
    }
    const selectedPreviousElement = screener.elements?.edges.find(
      edge => edge?.node?.id === selectedPreviousQuestionId
    );
    if (selectedPreviousElement?.node?.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
      // If it's a grid question we show the column text options
      const columns = selectedPreviousElement?.node?.answers?.edges?.map(edge => {
        return {
          id: edge?.node?.id || "",
          text: edge?.node?.text || "",
          position: edge?.node?.position || 0,
        };
      });
      return columns
        .sort((a, b) => a.position - b.position)
        .map(option => {
          return {
            id: option.id,
            text: option.text,
          };
        });
    } else {
      return [
        { id: "true", text: "True" },
        { id: "false", text: "False" },
      ];
    }
  };

  const handleAnswerOptionsChange = (values: any) => {
    /*
    values format:
    ["true", "false"] - Non-grid questions
    ["5245234324fdgfghd34234", "ssjfskjsfd423423sdfssf"] - Grid Questions (answerIds)
     */
    setSelectedOptions(values);
    handleUsePreviousAnswersChange(values);
  };

  return (
    <StyledPreviousAnswers>
      <Card>
        <div className="screener-upa-title">Use answers from previous question</div>
        <DetailsInput
          title="Show answers from"
          titleStyle={{ fontSize: "12px" }}
          style={{ marginTop: "1rem" }}
          inputs={
            <Select
              className="screener-upa-select"
              placeholder={"Select a question"}
              onChange={value => {
                handleUsePreviousAnswersElementChange(value);
                const selectedQuestionType = screener.elements?.edges.find(edge => edge?.node?.id === value)?.node
                  ?.type;
                if (selectedQuestionType !== ELEMENT_TYPES.GRID_SINGLE_SELECT) {
                  setSelectedOptions(["true"]);
                } else {
                  setSelectedOptions(undefined);
                }
              }}
              value={selectedPreviousQuestionId}
            >
              {screener.elements?.edges
                .filter(edge => edge?.node?.id !== element.id)
                .filter(edge => SELECTABLE_ELEMENT_TYPES.includes(edge?.node?.type || ""))
                .map(edge => (
                  <Option value={edge?.node?.id} key={edge?.node?.id}>
                    {edge?.node?.text || "<Untitled question>"}
                  </Option>
                ))}
            </Select>
          }
        />
        {selectedPreviousQuestionId && (
          <DetailsInput
            title="Only show answers that are"
            titleStyle={{ fontSize: "12px" }}
            inputs={
              <Select
                className={"screener-upa-select"}
                mode={"multiple"}
                placeholder="Select options"
                onChange={handleAnswerOptionsChange}
                value={selectedOptions}
                showArrow={true}
                filterOption={(input, option) =>
                  ((option?.children as any as string) ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {getAnswerOptions().map(option => (
                  <Option value={option.id} key={option.id}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            }
          />
        )}
      </Card>
    </StyledPreviousAnswers>
  );
};

const StyledPreviousAnswers = styled.div`
  margin-top: 12px;

  .screener-upa-title {
    font-size: 22px;
  }

  .screener-upa-select {
    width: 100%;
  }
`;

export default createFragmentContainer(UsePreviousAnswers, {
  screener: graphql`
    fragment UsePreviousAnswers_screener on ScreenerNode {
      elements {
        edges {
          node {
            id
            text
            type
            characteristic {
              importKey
            }
            answers {
              edges {
                node {
                  id
                  position
                  text
                }
              }
            }
          }
        }
      }
    }
  `,
  element: graphql`
    fragment UsePreviousAnswers_element on ElementNode {
      id
      usePreviousAnswers
    }
  `,
});
