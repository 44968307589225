import { Icon } from "@iconify/react";
import classNames, { type Argument } from "classnames";
import type { CSSProperties, ReactNode } from "react";

type IconName = `${"ic" | "mdi"}:${string}`;

/**
 * Component showing an icon and text.
 * Intended for use in tabs, menus, and buttons.
 *
 * @component
 * @example
 * return (
 *   <Label icon="mdi:plus" text="New" />
 * );
 */
export const Label = ({
  className,
  icon,
  iconScale = 1.2,
  loading = false,
  style,
  tagName: El = "span",
  text,
}: {
  className?: Argument;
  iconScale?: number;
  loading?: boolean;
  style?: CSSProperties;
  tagName?: keyof JSX.IntrinsicElements;
} & ({ icon?: IconName; text: ReactNode } | { icon: IconName; text?: ReactNode })) => (
  <El
    className={classNames("hub-label", className)}
    style={{ display: "flex", alignItems: "center", gap: 8, ...style }}
  >
    {!!icon && (
      <Icon
        className={classNames("hub-label-icon", { "hub-animate-loading": loading })}
        icon={loading ? "mdi:loading" : icon}
        height={`${iconScale}em`}
      />
    )}
    {!!text && <span className="hub-label-text">{text}</span>}
  </El>
);
