import { graphql } from "babel-plugin-relay/macro";
import { orderBy } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { RESPONDENT_MASTER_STATUSES } from "../../../constants";
import { maybeElement } from "../../../utils/misc";
import {
  ScreenersStudyIncentiveTypeChoices,
  YourHistory_user$data,
} from "../../../__generated__/YourHistory_user.graphql";
import { DateCell, PointsCell, TextCell } from "../PortalCells";
import PortalTable from "../PortalTable";

const historySerializer = (
  user: YourHistory_user$data
): {
  name: string;
  date: string;
  reward: {
    incentiveType?: ScreenersStudyIncentiveTypeChoices;
    incentivePoints?: number | null;
    incentive?: string | null;
  };
}[] =>
  orderBy(
    user?.panelist?.person?.respondents?.edges
      ?.map(respondent => {
        const study = respondent?.node?.screener?.study;
        const nonTenantStudies =
          user?.panelist?.customPanelistPortal &&
          study?.tenant?.id !== user?.panelist?.customPanelistPortal?.tenant?.id;
        const recruit = respondent?.node?.recruit;

        if (recruit?.isTest || nonTenantStudies) return [];

        const studyName =
          respondent?.node?.screener?.study?.namePublic ?? (respondent?.node?.screener?.title || "Unnamed Study");
        const bonuses =
          respondent?.node?.participant?.bonusPoints
            ?.filter((x): x is NonNullable<typeof x> => !!x)
            .map(bonus => ({
              name: `Bonus: ${studyName}`,
              date: bonus?.clearedOn ?? "Pending",
              reward: {
                incentiveType: "PT" as ScreenersStudyIncentiveTypeChoices,
                incentivePoints: bonus.pointsAmount,
              },
            })) ?? [];

        return [RESPONDENT_MASTER_STATUSES.REWARDED, RESPONDENT_MASTER_STATUSES.RATED].includes(
          (respondent?.node?.masterStatus || RESPONDENT_MASTER_STATUSES.EMPTY) as RESPONDENT_MASTER_STATUSES
        )
          ? [
              ...bonuses,
              {
                name: studyName,
                date: respondent?.node?.participant?.incentiveClearedOn ?? "Pending",
                reward: {
                  incentiveType: study?.incentiveType,
                  incentivePoints:
                    recruit && recruit.incentivePoints ? recruit.incentivePoints : study?.defaultIncentivePoints,
                  incentive: recruit && recruit.incentive ? recruit.incentive : study?.defaultIncentive,
                },
              },
            ]
          : bonuses;
      })
      .flat(),
    ["date"],
    ["desc"]
  );

type Props = {
  user: YourHistory_user$data;
};
const YourHistory: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t("portal.cards.your-history.table-header.study-name", "Study name"),
        accessor: (row: any) => {
          return { text: row.name, width: "180px" };
        },
        Cell: TextCell,
      },
      {
        Header: t("portal.cards.your-history.table-header.date", "Date"),
        accessor: "date",
        Cell: DateCell,
      },
      ...maybeElement(!user.panelist?.customPanelistPortal?.hidePointsAndRedemption, {
        Header: t("portal.cards.your-history.table-header.reward", "Reward"),
        accessor: "reward",
        Cell: PointsCell,
      }),
    ],
    [t, user]
  );

  return (
    <PortalTable
      columns={columns}
      data={React.useMemo(() => (user ? historySerializer(user) : []), [user])}
      blankText={t("portal.cards.your-history.no-past-studies", "No past studies")}
    />
  );
};

export default createFragmentContainer(YourHistory, {
  user: graphql`
    fragment YourHistory_user on UserNode {
      panelist {
        customPanelistPortal {
          hidePointsAndRedemption
          tenant {
            id
          }
        }
        person {
          respondents {
            edges {
              node {
                approvalStatus
                masterStatus
                completedOn
                status
                participant {
                  incentivized
                  incentiveClearedOn
                  noShow
                  rating
                  completedOn
                  bonusPoints {
                    clearedOn
                    pointsAmount
                  }
                }
                scheduledSlots {
                  start
                }
                screener {
                  title
                  study {
                    namePublic
                    type
                    defaultIncentive
                    defaultIncentivePoints
                    incentiveType
                    scheduled
                    status
                    tenant {
                      id
                    }
                  }
                }
                recruit {
                  isTest
                  incentive
                  incentivePoints
                }
              }
            }
          }
        }
      }
    }
  `,
});
