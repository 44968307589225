import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { PortalCard_portalCard$data } from "../../../__generated__/PortalCard_portalCard.graphql";
import { PortalCard_user$data } from "../../../__generated__/PortalCard_user.graphql";
import { LinkedTextCell, WrappedTextCell } from "../PortalCells";
import PortalTable from "../PortalTable";

type user = PortalCard_user$data;
type portalCard = PortalCard_portalCard$data;
const linkSerializer = (user: user, portalCard: portalCard) => {
  return portalCard.links.edges.map(link => {
    let linkUrl = link?.node?.linkUrl;
    if (linkUrl && user.panelist?.dId) {
      // Replace '%panelist.id%' string in URL with actual panelist id
      linkUrl = linkUrl.replace("%panelist.id%", user.panelist.dId as string);
    }

    return {
      title: link?.node?.title,
      link: linkUrl,
      linkText: link?.node?.linkText,
    };
  });
};

type Props = {
  user: PortalCard_user$data;
  portalCard: PortalCard_portalCard$data;
};
const PortalCard: React.FC<Props> = ({ user, portalCard }) => {
  const columns = React.useMemo(
    () => [
      {
        id: "portal-card-description",
        accessor: (row: any): any => {
          return {
            text: row.title,
            width: "370px",
          };
        },
        Cell: WrappedTextCell,
      },
      {
        id: "portal-card-link",
        accessor: (row: any): any => {
          return {
            width: "100%",
            text: row.linkText,
            link: row.link,
            userdId: user?.dId,
            panelistdId: user?.panelist?.dId,
            tenantName: user.panelist?.customPanelistPortal?.tenant.name || "HubUX",
            tableName: portalCard.title,
          };
        },
        Cell: LinkedTextCell,
      },
    ],
    [user, portalCard]
  );

  return (
    <Styled>
      <div className="portal-card-description">{portalCard.description}</div>
      {portalCard.links.edges.length > 0 && (
        <div className="portal-table-container">
          <PortalTable
            columns={columns}
            data={user ? linkSerializer(user, portalCard) : []}
            blankText={""}
            hideHeader={true}
          />
        </div>
      )}
    </Styled>
  );
};

const Styled = styled.div`
  .portal-card-description {
    margin: 15px;
    font-size: 12px;
  }

  .portal-table-container {
    margin-left: 8px;
  }

  .ant-typography {
    margin-bottom: 0;
  }
`;

export default createFragmentContainer(PortalCard, {
  user: graphql`
    fragment PortalCard_user on UserNode {
      email
      id
      dId
      panelist {
        dId
        customPanelistPortal {
          tenant {
            id
            name
          }
        }
      }
    }
  `,
  portalCard: graphql`
    fragment PortalCard_portalCard on PortalCardNode {
      id
      title
      description
      links {
        edges {
          node {
            id
            title
            linkText
            linkUrl
          }
        }
      }
    }
  `,
});
