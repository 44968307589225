/**
 * @generated SignedSource<<239719c679f4dc9f237006be6ce75385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitMatch_recruit$data = {
  readonly dbId: string;
  readonly export: {
    readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
  } | null;
  readonly id: string;
  readonly matched: {
    readonly totalCount: number | null;
  } | null;
  readonly name: string | null;
  readonly recruited: {
    readonly totalCount: number | null;
  } | null;
  readonly status: ScreenersRecruitStatusChoices;
  readonly table: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly completedStudiesCount: number | null;
        readonly dId: any | null;
        readonly id: string;
        readonly joined: string | null;
        readonly panelistMembership: {
          readonly latestCompleteScreenerDate: string | null;
          readonly latestCompleteScreenerName: string | null;
          readonly latestRatedParticipant: {
            readonly rating: number;
          } | null;
          readonly latestStudyDate: string | null;
          readonly latestStudyName: string | null;
        } | null;
        readonly person: {
          readonly email: string | null;
          readonly firstName: string | null;
          readonly lastName: string | null;
          readonly panelist: {
            readonly id: string;
          } | null;
          readonly phoneNumber: string | null;
        } | null;
        readonly recruitStatus: string | null;
        readonly recruitedToStudy: boolean | null;
        readonly responses: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly answer: {
                readonly id: string;
              } | null;
              readonly characteristic: {
                readonly id: string;
              };
              readonly row: {
                readonly id: string;
                readonly text: string;
              } | null;
              readonly textValue: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
    readonly totalCount: number | null;
  } | null;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentType": "RecruitMatch_recruit";
};
export type RecruitMatch_recruit$key = {
  readonly " $data"?: RecruitMatch_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitMatch_recruit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "panelFilters"
    },
    {
      "defaultValue": "MATCHED_PANEL",
      "kind": "LocalArgument",
      "name": "panelRecruitFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "panelSettings"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "table"
        ]
      }
    ]
  },
  "name": "RecruitMatch_recruit",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExportNode",
      "kind": "LinkedField",
      "name": "export",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExportButton_export"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "matched",
      "args": [
        {
          "kind": "Literal",
          "name": "panelRecruitFilter",
          "value": "MATCHED_PANEL"
        }
      ],
      "concreteType": "PanelistConnection",
      "kind": "LinkedField",
      "name": "eligiblePanelists",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "eligiblePanelists(panelRecruitFilter:\"MATCHED_PANEL\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "recruited",
      "args": [
        {
          "kind": "Literal",
          "name": "panelRecruitFilter",
          "value": "RECRUITED_PANEL"
        }
      ],
      "concreteType": "PanelistConnection",
      "kind": "LinkedField",
      "name": "eligiblePanelists",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "eligiblePanelists(panelRecruitFilter:\"RECRUITED_PANEL\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": "table",
      "args": [
        {
          "kind": "Variable",
          "name": "panelFilters",
          "variableName": "panelFilters"
        },
        {
          "kind": "Variable",
          "name": "panelRecruitFilter",
          "variableName": "panelRecruitFilter"
        },
        {
          "kind": "Variable",
          "name": "panelSettings",
          "variableName": "panelSettings"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "PanelistConnection",
      "kind": "LinkedField",
      "name": "__RecruitMatch_table_connection",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelistEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PanelistNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completedStudiesCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PersonNode",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phoneNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PanelistNode",
                      "kind": "LinkedField",
                      "name": "panelist",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PanelistMembershipNode",
                  "kind": "LinkedField",
                  "name": "panelistMembership",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latestCompleteScreenerName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latestCompleteScreenerDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latestStudyName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latestStudyDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ParticipantNode",
                      "kind": "LinkedField",
                      "name": "latestRatedParticipant",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rating",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "joined",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recruitedToStudy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recruitStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CharacteristicResponseNodeConnection",
                  "kind": "LinkedField",
                  "name": "responses",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CharacteristicResponseNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CharacteristicResponseNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CharacteristicNode",
                              "kind": "LinkedField",
                              "name": "characteristic",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CharacteristicAnswerNode",
                              "kind": "LinkedField",
                              "name": "answer",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CharacteristicRowNode",
                              "kind": "LinkedField",
                              "name": "row",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "text",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "textValue",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};
})();

(node as any).hash = "e92e53800dd83debe4aef12ffd428cd2";

export default node;
