import { Icon } from "@iconify/react";
import { useLocalStorageValue, useMountEffect, useUpdateEffect } from "@react-hookz/web";
import { Badge, Button, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { uniqueId } from "lodash-es";
import { useMemo, useState } from "react";
import { useRefetchableFragment } from "react-relay";
import styled from "styled-components";

import { DrawerFilters, GroupOp, type ParticipantFilterGroupGroup } from "..";
import { ScreenersFilterTypeChoices } from "../../schema";
import { GRAY_1 } from "../../style";
import type { ParticipantFiltersViewerRefetchQuery } from "../../__generated__/ParticipantFiltersViewerRefetchQuery.graphql";
import type { ParticipantFilters_viewer$key } from "../../__generated__/ParticipantFilters_viewer.graphql";

const createPinnedFilterKey = (studyId?: string) => (studyId ? `hub-filters-pinned-${studyId}` : "hub-filters-pinned");

export const createParticipantFilterGroupGroup = (): ParticipantFilterGroupGroup => ({
  id: uniqueId(),
  op: GroupOp.And,
  filters: [],
});

const ParticipantFilters = ({
  viewerKey,
  onApplyFilters,
  loading,
  value,
  setFiltersDrawerVisible,
  filtersDrawerVisible,
}: {
  viewerKey: ParticipantFilters_viewer$key;
  onApplyFilters: (x: ParticipantFilterGroupGroup) => void;
  loading: boolean;
  value: ParticipantFilterGroupGroup;
  setFiltersDrawerVisible: (x: boolean) => void;
  filtersDrawerVisible: boolean;
}) => {
  const [viewer, refetch] = useRefetchableFragment<ParticipantFiltersViewerRefetchQuery, typeof viewerKey>(
    graphql`
      fragment ParticipantFilters_viewer on Viewer
      @argumentDefinitions(studyId: { type: "String" })
      @refetchable(queryName: "ParticipantFiltersViewerRefetchQuery") {
        study(studyId: $studyId) {
          id
          filters {
            id
            name
            user {
              id
            }
            study {
              id
            }
            type
            filters
          }
        }
        user {
          id
          profile {
            tenant {
              id
              name
              filters {
                id
                name
                user {
                  id
                }
                study {
                  id
                }
                type
                filters
              }
            }
          }
        }
        ...DrawerFilters_viewer @arguments(studyId: $studyId)
      }
    `,
    viewerKey
  );

  const savedFilters = useMemo(
    () =>
      [...(viewer.study?.filters ?? []), ...(viewer.user?.profile?.tenant?.filters ?? [])].filter(x => x.type === "PT"),
    [viewer.study?.filters, viewer.user?.profile?.tenant?.filters]
  );

  const [filterGroupGroup, setFilterGroupGroup] = useState<ParticipantFilterGroupGroup>(value);
  const [filtersAppliedCount, setFiltersAppliedCount] = useState(0);
  const [loadedFilterId, setLoadedFilterId] = useState<string>();

  // track the active filter across reloads
  const pinnedFilterKey = useMemo(() => createPinnedFilterKey(viewer.study?.id), [viewer.study?.id]);
  const [pinnedFilter, setPinnedFilter, deletePinnedFilter] = useLocalStorageValue<ParticipantFilterGroupGroup | null>(
    pinnedFilterKey
  );

  const applyFilterGroupGroup = (x: ParticipantFilterGroupGroup) => {
    setFiltersAppliedCount(x.filters.length);
    setPinnedFilter(x);
    onApplyFilters(x);
  };

  useMountEffect(() => {
    if (pinnedFilter != null) {
      setFilterGroupGroup(pinnedFilter);
      applyFilterGroupGroup(pinnedFilter);
    }
  });

  useUpdateEffect(() => {
    if (loadedFilterId) {
      const x = savedFilters.find(x => x.id === loadedFilterId);

      if (x) {
        const filterGroupGroup = JSON.parse(x.filters);

        setFilterGroupGroup(filterGroupGroup);
        applyFilterGroupGroup(filterGroupGroup);
      }
    }
  }, [loadedFilterId]);

  return (
    <Styled>
      <Badge
        count={filtersAppliedCount}
        style={{
          backgroundColor: "var(--ant-primary-color)",
          color: GRAY_1,
          borderColor: "var(--ant-primary-color)",
        }}
      >
        <Tooltip title="Filters">
          <Button className="filter-button" onClick={() => setFiltersDrawerVisible(!filtersDrawerVisible)}>
            <Icon icon="mdi:filter-variant" height="1.5em" />
          </Button>
        </Tooltip>
      </Badge>
      <DrawerFilters
        filterGroupGroup={filterGroupGroup}
        loadedFilterId={loadedFilterId}
        loading={loading}
        onApply={() => applyFilterGroupGroup(filterGroupGroup)}
        onClear={() => {
          const x = createParticipantFilterGroupGroup();
          setFilterGroupGroup(x);
          setLoadedFilterId(undefined);
          applyFilterGroupGroup(x);
          deletePinnedFilter();
        }}
        onClose={() => setFiltersDrawerVisible(false)}
        onDelete={() => refetch({ studyId: viewer.study?.id })}
        open={filtersDrawerVisible}
        setFilterGroupGroup={setFilterGroupGroup}
        setLoadedFilterId={setLoadedFilterId}
        type={ScreenersFilterTypeChoices.Pt}
        viewer={viewer}
      />
    </Styled>
  );
};

const Styled = styled.div`
  z-index: 1;

  display: flex;
  flex-direction: row;
  gap: 12px;

  .participant-search {
    max-width: 300px;
    .ant-input {
      background-color: #fff !important;
    }
  }

  .filter-button {
    aspect-ratio: 1;
    display: grid;
    padding: 4px;
    place-content: center;
  }
`;

export default ParticipantFilters;
