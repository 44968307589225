/**
 * @generated SignedSource<<1c4a75dafe8164b681bd4912eeb861e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Segment_screener$data = {
  readonly study: {
    readonly dId: any | null;
    readonly name: string | null;
    readonly status: ScreenersStudyStatusChoices;
    readonly tenant: {
      readonly dId: any | null;
      readonly name: string;
      readonly vpmAccountId: number | null;
    };
    readonly type: ScreenersStudyTypeChoices;
  };
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_screener">;
  readonly " $fragmentType": "Segment_screener";
};
export type Segment_screener$key = {
  readonly " $data"?: Segment_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"Segment_screener">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Segment_screener",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vpmAccountId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilterGroupGroup_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};
})();

(node as any).hash = "a4759fd1159c4894d0890569fd4226ff";

export default node;
