/**
 * @generated SignedSource<<cc4cfeff5b1bc59e7f5c2ee1e3aa36fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateStudyInput = {
  allowAutoApproval?: boolean | null;
  availabilityBuffer?: string | null;
  availabilitySlots?: ReadonlyArray<AvailabilitySlotInput | null> | null;
  claimLink?: string | null;
  clientMutationId?: string | null;
  defaultIncentive?: string | null;
  defaultIncentivePoints?: number | null;
  duration?: string | null;
  incentiveType?: string | null;
  inviteMeetingName?: string | null;
  inviteMeetingType?: string | null;
  inviteMessage?: string | null;
  inviteMessageCustomized?: boolean | null;
  languageCode?: string | null;
  launchPanelEmailSend?: boolean | null;
  meetingLink?: string | null;
  name?: string | null;
  namePublic?: string | null;
  panelFilters?: any | null;
  privacyPolicy?: string | null;
  rewardDescription?: string | null;
  scheduled?: any | null;
  scheduledBy?: string | null;
  schedulingLink?: string | null;
  schedulingType?: string | null;
  studyId: string;
  studySessions?: ReadonlyArray<StudySessionInput | null> | null;
  tags?: ReadonlyArray<TagCreateOrAttachInput> | null;
  type?: string | null;
  usesApiToMarkComplete?: boolean | null;
  voxpopmeProjectId?: number | null;
  voxpopmeRecorderEnabled?: boolean | null;
};
export type AvailabilitySlotInput = {
  availabilityBuffer?: number | null;
  duration?: number | null;
  end?: any | null;
  id?: string | null;
  inPerson?: boolean | null;
  interviewer?: string | null;
  meetingDetails?: string | null;
  meetingLink?: string | null;
  meetingLocation?: string | null;
  placesLimit?: number | null;
  start?: any | null;
};
export type StudySessionInput = {
  availabilitySlots?: ReadonlyArray<AvailabilitySlotInput | null> | null;
  duration?: number | null;
  id?: string | null;
  name?: string | null;
};
export type TagCreateOrAttachInput = {
  color?: string | null;
  id?: string | null;
  name?: string | null;
};
export type ProjectDetailsPage_UpdateStudy_Mutation$variables = {
  input: UpdateStudyInput;
};
export type ProjectDetailsPage_UpdateStudy_Mutation$data = {
  readonly updateStudy: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_study">;
    } | null;
  } | null;
};
export type ProjectDetailsPage_UpdateStudy_Mutation = {
  response: ProjectDetailsPage_UpdateStudy_Mutation$data;
  variables: ProjectDetailsPage_UpdateStudy_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "language",
    "storageKey": null
  },
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDetailsPage_UpdateStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudyPayload",
        "kind": "LinkedField",
        "name": "updateStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectDetailsPage_study"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDetailsPage_UpdateStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStudyPayload",
        "kind": "LinkedField",
        "name": "updateStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "surveyDomain",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "namePublic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNode",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultIncentive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowAutoApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulingType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivacyPolicyNode",
                "kind": "LinkedField",
                "name": "privacyPolicy",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivacyPolicyNode",
                "kind": "LinkedField",
                "name": "privacyPolicyOptions",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "terminateRespondent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "terminatedExitLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quotaExitLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overquotaExitLink",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incentiveType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultIncentivePoints",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalIncentiveNode",
                "kind": "LinkedField",
                "name": "externalIncentive",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rewardDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "claimLink",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usesApiToMarkComplete",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voxpopmeRecorderEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b129b04d477b18c06dfd441474e81b6",
    "id": null,
    "metadata": {},
    "name": "ProjectDetailsPage_UpdateStudy_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectDetailsPage_UpdateStudy_Mutation(\n  $input: UpdateStudyInput!\n) {\n  updateStudy(input: $input) {\n    study {\n      ...ProjectDetailsPage_study\n      id\n    }\n  }\n}\n\nfragment ProjectDetailsPage_study on StudyNode {\n  id\n  dId\n  tenant {\n    surveyDomain\n    id\n  }\n  type\n  status\n  name\n  namePublic\n  tags {\n    id\n    color\n    name\n  }\n  defaultIncentive\n  allowAutoApproval\n  schedulingType\n  scheduledBy\n  privacyPolicy {\n    language\n    languageCode\n    id\n  }\n  privacyPolicyOptions {\n    language\n    languageCode\n    id\n  }\n  languageCode\n  screener {\n    terminateRespondent\n    terminatedExitLink\n    quotaExitLink\n    overquotaExitLink\n    id\n  }\n  recruits {\n    edges {\n      node {\n        type\n        id\n      }\n    }\n  }\n  incentiveType\n  defaultIncentivePoints\n  externalIncentive {\n    rewardDescription\n    claimLink\n    id\n  }\n  usesApiToMarkComplete\n  voxpopmeRecorderEnabled\n}\n"
  }
};
})();

(node as any).hash = "a190b1a16de635fafe21d95206b2e551";

export default node;
