/**
 * @generated SignedSource<<72442e09db0117c0cd84c6534ef12ece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_ProjectPage_Query$variables = {
  studyId: string;
};
export type RouteConfig_ProjectPage_Query$data = {
  readonly viewer: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectPage_study">;
    } | null;
  };
};
export type RouteConfig_ProjectPage_Query = {
  response: RouteConfig_ProjectPage_Query$data;
  variables: RouteConfig_ProjectPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "studyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "studyId",
    "variableName": "studyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDeposits",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPayouts",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRefundsDue",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goalPaidForCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRefunds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_ProjectPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectPage_study"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RouteConfig_ProjectPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNode",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "needsReviewCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "needsPaymentCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "screenerLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultIncentive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incentiveType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultIncentivePoints",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduled",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulingType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulingLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "meetingLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availabilityBuffer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyAvailabilitySlotConnection",
                "kind": "LinkedField",
                "name": "availabilitySlots",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyAvailabilitySlotEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyAvailabilitySlotNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNode",
                "kind": "LinkedField",
                "name": "recruit",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requireStudyPayment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "incentivesOrderFulfillmentVerificationDays",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vpmAccountId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalBaseCosts",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalFees",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPerParticipantCosts",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "participantBaseCost",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fee",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v10/*: any*/),
                          (v8/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34cae4445cb30b1b921013c75f783211",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_ProjectPage_Query",
    "operationKind": "query",
    "text": "query RouteConfig_ProjectPage_Query(\n  $studyId: String!\n) {\n  viewer {\n    study(studyId: $studyId) {\n      ...ProjectPage_study\n      id\n    }\n  }\n}\n\nfragment ExportSpendingDetailsButton_study on StudyNode {\n  dId\n  tenant {\n    name\n    dId\n    vpmAccountId\n    id\n  }\n  name\n  type\n  status\n}\n\nfragment ProjectPage_study on StudyNode {\n  id\n  dId\n  name\n  tags {\n    id\n    ...TagTag_tag\n  }\n  needsReviewCount\n  needsPaymentCount\n  status\n  screenerLink\n  defaultIncentive\n  incentiveType\n  defaultIncentivePoints\n  scheduled\n  type\n  totalDeposits\n  totalPayouts\n  totalRefundsDue\n  schedulingType\n  schedulingLink\n  meetingLink\n  availabilityBuffer\n  availabilitySlots {\n    edges {\n      node {\n        id\n        duration\n      }\n    }\n  }\n  recruit {\n    type\n    id\n  }\n  tenant {\n    requireStudyPayment\n    incentivesOrderFulfillmentVerificationDays\n    name\n    dId\n    vpmAccountId\n    id\n  }\n  ...SpendingDetails_study\n}\n\nfragment SpendingDetails_study on StudyNode {\n  dId\n  status\n  incentiveType\n  tenant {\n    name\n    id\n  }\n  goalPaidForCount\n  totalBaseCosts\n  totalFees\n  totalPerParticipantCosts\n  totalDeposits\n  totalPayouts\n  totalRefunds\n  totalRefundsDue\n  recruits {\n    edges {\n      node {\n        name\n        goalPaidForCount\n        participantBaseCost\n        fee\n        totalDeposits\n        totalPayouts\n        totalRefunds\n        totalRefundsDue\n        id\n      }\n    }\n  }\n  ...ExportSpendingDetailsButton_study\n}\n\nfragment TagTag_tag on TagNode {\n  color\n  name\n}\n"
  }
};
})();

(node as any).hash = "d480ff67ec67b970470f87ec5782eeea";

export default node;
