/**
 * @generated SignedSource<<5dbd05380167265982d1e65808ff03da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateNoteInput = {
  clientMutationId?: string | null;
  panelistId?: string | null;
  respondentId?: string | null;
  text?: string | null;
};
export type Note_UpdateNote_Mutation$variables = {
  input: UpdateNoteInput;
};
export type Note_UpdateNote_Mutation$data = {
  readonly updateNote: {
    readonly note: {
      readonly id: string;
      readonly panelist: {
        readonly " $fragmentSpreads": FragmentRefs<"Note_panelist">;
      } | null;
      readonly respondent: {
        readonly " $fragmentSpreads": FragmentRefs<"Note_respondent">;
      } | null;
    } | null;
  } | null;
};
export type Note_UpdateNote_Mutation = {
  response: Note_UpdateNote_Mutation$data;
  variables: Note_UpdateNote_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "modified",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "modifiedBy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Note_UpdateNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotePayload",
        "kind": "LinkedField",
        "name": "updateNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NoteNode",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Note_panelist"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RespondentNode",
                "kind": "LinkedField",
                "name": "respondent",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Note_respondent"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Note_UpdateNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotePayload",
        "kind": "LinkedField",
        "name": "updateNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NoteNode",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NoteNode",
                    "kind": "LinkedField",
                    "name": "tenantNote",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RespondentNode",
                "kind": "LinkedField",
                "name": "respondent",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NoteNode",
                    "kind": "LinkedField",
                    "name": "note",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1adb25cbd2dedcfccccc55e2425b3609",
    "id": null,
    "metadata": {},
    "name": "Note_UpdateNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation Note_UpdateNote_Mutation(\n  $input: UpdateNoteInput!\n) {\n  updateNote(input: $input) {\n    note {\n      id\n      panelist {\n        ...Note_panelist\n        id\n      }\n      respondent {\n        ...Note_respondent\n        id\n      }\n    }\n  }\n}\n\nfragment Note_panelist on PanelistNode {\n  id\n  tenantNote {\n    id\n    modified\n    modifiedBy {\n      firstName\n      id\n    }\n    text\n  }\n}\n\nfragment Note_respondent on RespondentNode {\n  id\n  note {\n    id\n    modified\n    modifiedBy {\n      firstName\n      id\n    }\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb733837536c772b342dbf755dc5b1ea";

export default node;
