/**
 * @generated SignedSource<<ee43bceaf351b9fc2bf8a6aab310b967>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetails_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_viewer">;
  readonly " $fragmentType": "ProjectDetails_viewer";
};
export type ProjectDetails_viewer$key = {
  readonly " $data"?: ProjectDetails_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetails_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "a7509cf5c432a1dfa0c5a9b7d0c3b2cb";

export default node;
