/**
 * @generated SignedSource<<1dd2956550017ebbe07b4f2af8d82514>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaveAutomatedEmailInput = {
  assets?: ReadonlyArray<AutomatedEmailAssetInput | null> | null;
  clientMutationId?: string | null;
  ctaLabel?: string | null;
  ctaUrl?: string | null;
  enabled?: boolean | null;
  message?: string | null;
  replyTo?: string | null;
  studyId: string;
  subject?: string | null;
  type: string;
};
export type AutomatedEmailAssetInput = {
  name: string;
  sizeBytes: number;
  url: string;
};
export type AutomatedEmailEditor_saveAutomatedEmail_Mutation$variables = {
  input: SaveAutomatedEmailInput;
};
export type AutomatedEmailEditor_saveAutomatedEmail_Mutation$data = {
  readonly saveAutomatedEmail: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"AutomatedEmailsPage_study">;
    } | null;
  } | null;
};
export type AutomatedEmailEditor_saveAutomatedEmail_Mutation = {
  response: AutomatedEmailEditor_saveAutomatedEmail_Mutation$data;
  variables: AutomatedEmailEditor_saveAutomatedEmail_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replyTo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AutomatedEmailEditor_saveAutomatedEmail_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveAutomatedEmailPayload",
        "kind": "LinkedField",
        "name": "saveAutomatedEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AutomatedEmailsPage_study"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AutomatedEmailEditor_saveAutomatedEmail_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveAutomatedEmailPayload",
        "kind": "LinkedField",
        "name": "saveAutomatedEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "voxpopmeGlobal",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmailTemplateConnection",
                    "kind": "LinkedField",
                    "name": "emailTemplates",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmailTemplateEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailTemplateNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutomatedEmailNodeConnection",
                "kind": "LinkedField",
                "name": "automatedEmails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AutomatedEmailNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AutomatedEmailNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ctaLabel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ctaUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetNodeConnection",
                            "kind": "LinkedField",
                            "name": "assets",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssetNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssetNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "sizeBytes",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowAutoApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalIncentiveNode",
                "kind": "LinkedField",
                "name": "externalIncentive",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "claimLink",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "terminateRespondent",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8545a4002501311317b7dce48a3f3357",
    "id": null,
    "metadata": {},
    "name": "AutomatedEmailEditor_saveAutomatedEmail_Mutation",
    "operationKind": "mutation",
    "text": "mutation AutomatedEmailEditor_saveAutomatedEmail_Mutation(\n  $input: SaveAutomatedEmailInput!\n) {\n  saveAutomatedEmail(input: $input) {\n    study {\n      ...AutomatedEmailsPage_study\n      id\n    }\n  }\n}\n\nfragment AutomatedEmailEditor_study on StudyNode {\n  id\n  allowAutoApproval\n  externalIncentive {\n    claimLink\n    id\n  }\n  screener {\n    terminateRespondent\n    id\n  }\n  tenant {\n    emailTemplates {\n      edges {\n        node {\n          id\n          name\n          replyTo\n          subject\n          message\n        }\n      }\n    }\n    ...SelectTemplateModal_tenant\n    ...SaveTemplateModal_tenant\n    id\n  }\n}\n\nfragment AutomatedEmailsPage_study on StudyNode {\n  id\n  type\n  tenant {\n    voxpopmeGlobal\n    id\n  }\n  automatedEmails {\n    edges {\n      node {\n        id\n        type\n        enabled\n        replyTo\n        subject\n        message\n        ctaLabel\n        ctaUrl\n        assets {\n          edges {\n            node {\n              id\n              name\n              sizeBytes\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n  ...AutomatedEmailEditor_study\n}\n\nfragment SaveTemplateModal_tenant on TenantNode {\n  emailTemplates {\n    edges {\n      node {\n        id\n        name\n        replyTo\n        subject\n        message\n      }\n    }\n  }\n}\n\nfragment SelectTemplateModal_tenant on TenantNode {\n  emailTemplates {\n    edges {\n      node {\n        id\n        name\n        replyTo\n        subject\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b4a2c811193785968620aa03065057f";

export default node;
