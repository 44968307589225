import { Breadcrumb } from "antd";
import classNames, { type Argument } from "classnames";
import type { CSSProperties, ReactFragment, ReactNode } from "react";
import styled from "styled-components";

export const PortalPageLayout = ({
  actions,
  breadcrumbs,
  children,
  className,
  customColor,
  footer,
  style,
}: {
  actions?: ReactFragment;
  breadcrumbs?: ReactFragment;
  children?: ReactNode;
  className?: Argument;
  customColor?: string | undefined | null;
  footer?: ReactFragment;
  style?: CSSProperties;
}) => (
  <StyledLayoutContent className={classNames(className)} style={style} breadcrumbs={!!breadcrumbs}>
    <div className="container">
      {breadcrumbs && <Breadcrumb className="breadcrumb">{breadcrumbs}</Breadcrumb>}
      {actions && <div>{actions}</div>}
      <div className="hub-layout-children">{children}</div>
      <Footer customColor={customColor}>{footer}</Footer>
    </div>
  </StyledLayoutContent>
);

const StyledLayoutContent = styled.div.attrs(
  ({ breadcrumbs, actions }: { breadcrumbs: boolean; actions: boolean }) => ({
    breadcrumbs,
    actions,
  })
)`
  .container {
    margin: auto;
    padding-top: 64px;
    padding-bottom: 36px;

    .breadcrumb {
      padding: 16px 0;
      display: inline-block;
    }

    .actions {
      float: right;
      margin: 9px 0;
    }
  }

  .hub-layout-children {
    min-height: calc(100vh - 145px);
  }
`;

const Footer = styled.div<{ customColor: string | undefined | null }>`
  float: right;
  margin: 12px 0 0 0;
  background-color: ${props => props?.customColor || "white"};
`;
