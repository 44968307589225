import type { FilterBase, FilterOp, FilterType } from "./base";

export const enum FilterSubtypeResponse {
  Id = "ID",
  Position = "POSITION",
  ValueDate = "VALUE_DATE",
  ValueNumber = "VALUE_NUMBER",
  ValueText = "VALUE_TEXT",
}

type FilterResponseBaseType = "characteristic" | "element";
export type FilterResponseBase<K extends FilterResponseBaseType> = FilterBase & {
  type: FilterType.Response;
} & {
  [key in `${K}Id`]: string;
};

export type FilterResponseIdExtra = {
  subtype: FilterSubtypeResponse.Id;
  rowId?: string | null;
  op: FilterOp.Is;
  values: string[];
};
export const isFilterResponseId = <K extends FilterResponseBaseType>(
  x: FilterResponseBase<K> & { subtype: FilterSubtypeResponse }
): x is FilterResponseBase<K> & FilterResponseIdExtra => x.subtype === FilterSubtypeResponse.Id;

export type FilterResponsePositionExtra = {
  subtype: FilterSubtypeResponse.Position;
  answerId: string;
  op: FilterOp.Is;
  values: number[];
};
export const isFilterResponsePosition = <K extends FilterResponseBaseType>(
  x: FilterResponseBase<K> & { subtype: FilterSubtypeResponse }
): x is FilterResponseBase<K> & FilterResponsePositionExtra => x.subtype === FilterSubtypeResponse.Position;

export type FilterResponseValueDateExtra = {
  subtype: FilterSubtypeResponse.ValueDate;
  op: FilterOp.After | FilterOp.Before | FilterOp.Between | FilterOp.Is;
  values: string[];
};
export const isFilterResponseValueDate = <K extends FilterResponseBaseType>(
  x: FilterResponseBase<K> & { subtype: FilterSubtypeResponse }
): x is FilterResponseBase<K> & FilterResponseValueDateExtra => x.subtype === FilterSubtypeResponse.ValueDate;

export type FilterResponseValueNumberExtra = {
  subtype: FilterSubtypeResponse.ValueNumber;
  op: FilterOp.GreaterThan | FilterOp.Is | FilterOp.LessThan;
  values: number[];
};
export const isFilterResponseValueNumber = <K extends FilterResponseBaseType>(
  x: FilterResponseBase<K> & { subtype: FilterSubtypeResponse }
): x is FilterResponseBase<K> & FilterResponseValueNumberExtra => x.subtype === FilterSubtypeResponse.ValueNumber;

export type FilterResponseValueTextExtra = {
  subtype: FilterSubtypeResponse.ValueText;
  op: FilterOp.Contains | FilterOp.Is;
  values: string[];
};
export const isFilterResponseValueText = <K extends FilterResponseBaseType>(
  x: FilterResponseBase<K> & { subtype: FilterSubtypeResponse }
): x is FilterResponseBase<K> & FilterResponseValueTextExtra => x.subtype === FilterSubtypeResponse.ValueText;

export type FilterElementResponse = FilterResponseBase<"element"> &
  (
    | FilterResponseIdExtra
    | FilterResponsePositionExtra
    | FilterResponseValueTextExtra
    | FilterResponseValueDateExtra
    | FilterResponseValueNumberExtra
  );
