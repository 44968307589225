/**
 * @generated SignedSource<<3920c7daea56744d7b6bff6e3c309ada>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_ProjectDetails_Query$variables = {
  studyId: string;
};
export type RouteConfig_ProjectDetails_Query$data = {
  readonly viewer: {
    readonly languages: ReadonlyArray<{
      readonly code: string;
      readonly name: string;
    } | null>;
    readonly study: {
      readonly tenant: {
        readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_tenant">;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_study">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_viewer">;
  };
};
export type RouteConfig_ProjectDetails_Query = {
  response: RouteConfig_ProjectDetails_Query$data;
  variables: RouteConfig_ProjectDetails_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "studyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "studyId",
    "variableName": "studyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LanguageNode",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TagNode",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "language",
    "storageKey": null
  },
  (v8/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_ProjectDetails_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectDetails_study"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProjectDetails_tenant"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectDetails_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RouteConfig_ProjectDetails_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "surveyDomain",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "defaultSurveysToUseApi",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowExternalIncentive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "externalIncentivePerParticipantCostUsdCents",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vpmAccountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "voxpopmeLiveDefaultProjectId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "voxpopmeApiKey",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "namePublic",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultIncentive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowAutoApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulingType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivacyPolicyNode",
                "kind": "LinkedField",
                "name": "privacyPolicy",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivacyPolicyNode",
                "kind": "LinkedField",
                "name": "privacyPolicyOptions",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "terminateRespondent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "terminatedExitLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quotaExitLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overquotaExitLink",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incentiveType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultIncentivePoints",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalIncentiveNode",
                "kind": "LinkedField",
                "name": "externalIncentive",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rewardDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "claimLink",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usesApiToMarkComplete",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voxpopmeRecorderEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "studyCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vpmUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f1e91af0292d1fa3d90cbc604b459a8",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_ProjectDetails_Query",
    "operationKind": "query",
    "text": "query RouteConfig_ProjectDetails_Query(\n  $studyId: String!\n) {\n  viewer {\n    study(studyId: $studyId) {\n      ...ProjectDetails_study\n      tenant {\n        ...ProjectDetails_tenant\n        id\n      }\n      id\n    }\n    languages {\n      name\n      code\n    }\n    ...ProjectDetails_viewer\n  }\n}\n\nfragment InputTags_tenant on TenantNode {\n  tags {\n    id\n    color\n    name\n  }\n}\n\nfragment ProjectDetailsPage_study on StudyNode {\n  id\n  dId\n  tenant {\n    surveyDomain\n    id\n  }\n  type\n  status\n  name\n  namePublic\n  tags {\n    id\n    color\n    name\n  }\n  defaultIncentive\n  allowAutoApproval\n  schedulingType\n  scheduledBy\n  privacyPolicy {\n    language\n    languageCode\n    id\n  }\n  privacyPolicyOptions {\n    language\n    languageCode\n    id\n  }\n  languageCode\n  screener {\n    terminateRespondent\n    terminatedExitLink\n    quotaExitLink\n    overquotaExitLink\n    id\n  }\n  recruits {\n    edges {\n      node {\n        type\n        id\n      }\n    }\n  }\n  incentiveType\n  defaultIncentivePoints\n  externalIncentive {\n    rewardDescription\n    claimLink\n    id\n  }\n  usesApiToMarkComplete\n  voxpopmeRecorderEnabled\n}\n\nfragment ProjectDetailsPage_tenant on TenantNode {\n  defaultSurveysToUseApi\n  allowExternalIncentive\n  externalIncentivePerParticipantCostUsdCents\n  vpmAccountId\n  voxpopmeLiveDefaultProjectId\n  voxpopmeApiKey\n  ...InputTags_tenant\n}\n\nfragment ProjectDetailsPage_viewer on Viewer {\n  studyCount\n  user {\n    dId\n    vpmUserId\n    panelist {\n      dId\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectDetails_study on StudyNode {\n  ...ProjectDetailsPage_study\n}\n\nfragment ProjectDetails_tenant on TenantNode {\n  ...ProjectDetailsPage_tenant\n}\n\nfragment ProjectDetails_viewer on Viewer {\n  ...ProjectDetailsPage_viewer\n}\n"
  }
};
})();

(node as any).hash = "e879faabd034b9ed6344da338c234762";

export default node;
