import { BrowserProtocol, queryMiddleware } from "farce";
import { createFarceRouter } from "found";
import { ErrorPage, RouteConfig } from "./index";

export const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: RouteConfig,

  renderError: ({ error }) => {
    console.error(error);
    return <div className="text">{error.status === 404 ? <ErrorPage code={404} /> : <ErrorPage code={500} />}</div>;
  },
});
