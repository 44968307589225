/**
 * @generated SignedSource<<e384c91971e9c3f2d75719a0020adb41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NumberElement_element$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
  readonly " $fragmentType": "NumberElement_element";
};
export type NumberElement_element$key = {
  readonly " $data"?: NumberElement_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"NumberElement_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NumberElement_element",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "018ba1984b297242bfb024cc8ef628d8";

export default node;
