/**
 * @generated SignedSource<<27a669dc5d87846d8965bef001b41d24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LaunchedPage_study$data = {
  readonly id: string;
  readonly name: string | null;
  readonly screenerLink: string | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly " $fragmentType": "LaunchedPage_study";
};
export type LaunchedPage_study$key = {
  readonly " $data"?: LaunchedPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"LaunchedPage_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LaunchedPage_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "screenerLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "1e3a07004552a6881d7a9716e092ebaf";

export default node;
