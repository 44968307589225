import moment from "moment";
import { useContext, useMemo } from "react";
import MomentComponent, { type MomentProps } from "react-moment";

import { TimeZoneContext } from "../utils";

export const Moment = ({ children, ...props }: MomentProps) => {
  const { shiftDate, timeZoneOffsetDifference } = useContext(TimeZoneContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => shiftDate(moment(children)), [children, shiftDate, timeZoneOffsetDifference]);

  return <MomentComponent {...props}>{value}</MomentComponent>;
};
