/**
 * @generated SignedSource<<ec3759e06e1ce75da4dd6e5b8ec579bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddPaymentMethodInput = {
  clientMutationId?: string | null;
  sessionId: string;
};
export type ManagePaymentFinishMutation$variables = {
  input: AddPaymentMethodInput;
};
export type ManagePaymentFinishMutation$data = {
  readonly addPaymentMethod: {
    readonly tenant: {
      readonly id: string;
      readonly stripePaymentMethodId: string | null;
    } | null;
  } | null;
};
export type ManagePaymentFinishMutation = {
  response: ManagePaymentFinishMutation$data;
  variables: ManagePaymentFinishMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddPaymentMethodPayload",
    "kind": "LinkedField",
    "name": "addPaymentMethod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantNode",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stripePaymentMethodId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagePaymentFinishMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManagePaymentFinishMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0b578df3c408c748fa8cb5aa72f5390f",
    "id": null,
    "metadata": {},
    "name": "ManagePaymentFinishMutation",
    "operationKind": "mutation",
    "text": "mutation ManagePaymentFinishMutation(\n  $input: AddPaymentMethodInput!\n) {\n  addPaymentMethod(input: $input) {\n    tenant {\n      id\n      stripePaymentMethodId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ecd20997fc926410bba1a1ab81e9c627";

export default node;
