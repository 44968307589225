/**
 * @generated SignedSource<<c3341e08f041b3cf5947c86c4ad44f11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateRemindersTemplateInput = {
  clientMutationId?: string | null;
  daysBefore?: number | null;
  hoursBefore?: number | null;
  minsBefore?: number | null;
  studyId: string;
};
export type CommunicationRemindersPage_CreateRemindersTemplate_Mutation$variables = {
  input: CreateRemindersTemplateInput;
};
export type CommunicationRemindersPage_CreateRemindersTemplate_Mutation$data = {
  readonly createRemindersTemplate: {
    readonly study: {
      readonly reminderTemplates: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly daysBefore: number;
            readonly hoursBefore: number;
            readonly id: string;
            readonly minsBefore: number;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type CommunicationRemindersPage_CreateRemindersTemplate_Mutation = {
  response: CommunicationRemindersPage_CreateRemindersTemplate_Mutation$data;
  variables: CommunicationRemindersPage_CreateRemindersTemplate_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RemindersTemplatesNodeConnection",
  "kind": "LinkedField",
  "name": "reminderTemplates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemindersTemplatesNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RemindersTemplatesNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "daysBefore",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hoursBefore",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minsBefore",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunicationRemindersPage_CreateRemindersTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRemindersTemplatePayload",
        "kind": "LinkedField",
        "name": "createRemindersTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunicationRemindersPage_CreateRemindersTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRemindersTemplatePayload",
        "kind": "LinkedField",
        "name": "createRemindersTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8437065dd0082c2e84a44380e48047b5",
    "id": null,
    "metadata": {},
    "name": "CommunicationRemindersPage_CreateRemindersTemplate_Mutation",
    "operationKind": "mutation",
    "text": "mutation CommunicationRemindersPage_CreateRemindersTemplate_Mutation(\n  $input: CreateRemindersTemplateInput!\n) {\n  createRemindersTemplate(input: $input) {\n    study {\n      reminderTemplates {\n        edges {\n          node {\n            id\n            daysBefore\n            hoursBefore\n            minsBefore\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd8e4f59392117ba6013a95c8390febe";

export default node;
