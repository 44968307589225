import runtimeEnv from "@mars/heroku-js-runtime-env";
import type { PartialDeep } from "type-fest";
import type { CustomPortalNode } from "../../schema";

const env = runtimeEnv();

export const getHeroAssetType = (assetUrl: string | null | undefined) => {
  const heroAssetSplit = assetUrl?.split(".") ?? null;
  const heroAssetType = heroAssetSplit?.at(-1)?.toLowerCase() ?? null;
  const isHeroAssetImg =
    heroAssetType === "png" || heroAssetType === "jpeg" || heroAssetType === "jpg" || heroAssetType === "svg";
  const isHeroAssetMp4 = heroAssetType === "mp4" || heroAssetType === "m4v";

  if (isHeroAssetImg) return "image";
  if (isHeroAssetMp4) return "mp4";
};

export const useCustomPortalSanitized = (customPortal?: PartialDeep<CustomPortalNode> | null) => ({
  heroAssetUrl: customPortal?.heroAssetUrl || "",
  contactEmail: customPortal?.contactEmail || env.REACT_APP_CONTACT_EMAIL || "hello@hubux.com",
});
