/**
 * @generated SignedSource<<b7428227d56a5cc36a31876cce7b6d38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GivePointsToParticipantsInput = {
  clientMutationId?: string | null;
  expectedCostUsdCents: number;
  participantIds?: ReadonlyArray<string | null> | null;
  points: number;
  studyId: string;
};
export type GivePointsMutations_givePointsToParticipants_Mutation$variables = {
  input: GivePointsToParticipantsInput;
};
export type GivePointsMutations_givePointsToParticipants_Mutation$data = {
  readonly givePointsToParticipants: {
    readonly study: {
      readonly participants: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly bonusPoints: ReadonlyArray<{
              readonly orderDate: any;
              readonly pointsAmount: number;
            } | null>;
          } | null;
        } | null>;
      } | null;
    };
    readonly summary: {
      readonly feesUsdCents: number;
      readonly nonTestParticipantCount: number;
      readonly pointsCostUsdCents: number;
      readonly testParticipantCount: number;
    };
  } | null;
};
export type GivePointsMutations_givePointsToParticipants_Mutation = {
  response: GivePointsMutations_givePointsToParticipants_Mutation$data;
  variables: GivePointsMutations_givePointsToParticipants_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "GivePointsCostsSummary",
  "kind": "LinkedField",
  "name": "summary",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsCostUsdCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesUsdCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testParticipantCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nonTestParticipantCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ParticipantBonusPointsNode",
  "kind": "LinkedField",
  "name": "bonusPoints",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GivePointsMutations_givePointsToParticipants_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GivePointsToParticipantsPayload",
        "kind": "LinkedField",
        "name": "givePointsToParticipants",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ParticipantNodeConnection",
                "kind": "LinkedField",
                "name": "participants",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParticipantNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParticipantNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GivePointsMutations_givePointsToParticipants_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GivePointsToParticipantsPayload",
        "kind": "LinkedField",
        "name": "givePointsToParticipants",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ParticipantNodeConnection",
                "kind": "LinkedField",
                "name": "participants",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParticipantNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParticipantNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bb802d4ac89bbb692a74cedfa7d55c8",
    "id": null,
    "metadata": {},
    "name": "GivePointsMutations_givePointsToParticipants_Mutation",
    "operationKind": "mutation",
    "text": "mutation GivePointsMutations_givePointsToParticipants_Mutation(\n  $input: GivePointsToParticipantsInput!\n) {\n  givePointsToParticipants(input: $input) {\n    summary {\n      pointsCostUsdCents\n      feesUsdCents\n      testParticipantCount\n      nonTestParticipantCount\n    }\n    study {\n      participants {\n        edges {\n          node {\n            bonusPoints {\n              orderDate\n              pointsAmount\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9277bc3680140c95b2025d8140a03f9d";

export default node;
