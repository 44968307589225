import runtimeEnv from "@mars/heroku-js-runtime-env";
import { mount as mountPendo } from "../__vendor__/pendo";

const PENDO_API_KEY_ENV_VAR = "REACT_APP_PENDO_API_KEY";

export const mount = () => {
  const { [PENDO_API_KEY_ENV_VAR]: apiKey } = runtimeEnv();
  if (apiKey) mountPendo(apiKey);
  else console.warn(`Pendo was not initialized. Ensure you have set ${PENDO_API_KEY_ENV_VAR}`);
};

type Pendo = {
  getVisitorId: () => string;
  identify: (visitorId: string, accountId: string) => void;
  initialize: ({
    visitor,
    account,
  }: {
    visitor: { id: string; email?: string; full_name?: string };
    account: { id: string; name?: string };
  }) => void;
  track: (event: string, meta?: Record<string, unknown>) => void;
};

export const identifyUser = ({
  vpmUserId,
  email,
  name,
  vpmAccountId,
  tenantName,
}: {
  vpmUserId: number | null;
  email?: string;
  name?: string;
  vpmAccountId: number | null;
  tenantName?: string;
}) => {
  const pendo: Pendo | undefined = (window as any).pendo;
  if (!pendo) return;

  const _vpmUserId = typeof vpmUserId === "number" ? String(vpmUserId) : "";
  const _vpmAccountId = typeof vpmAccountId === "number" ? String(vpmAccountId) : "";

  if (pendo.getVisitorId() !== _vpmUserId)
    pendo.initialize({
      visitor: { id: _vpmUserId, email, full_name: name },
      account: { id: _vpmAccountId, name: tenantName },
    });
  else pendo.identify(_vpmUserId, _vpmAccountId);
};

export const trackEvent = (event: string, meta?: Record<string, unknown>) => {
  const pendo: Pendo | undefined = (window as any).pendo;
  pendo?.track(event, meta);
};
