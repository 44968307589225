/**
 * @generated SignedSource<<aa2591c2a5ceb53aadd08a9ab5b7b623>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpenEndElement_element$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
  readonly " $fragmentType": "OpenEndElement_element";
};
export type OpenEndElement_element$key = {
  readonly " $data"?: OpenEndElement_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenEndElement_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenEndElement_element",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "c467be2f7d63bcacce5854f0c1da31b3";

export default node;
