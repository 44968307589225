import { isEqualSimple } from "@react-hookz/deep-equal";
import { usePrevious, useUpdateEffect } from "@react-hookz/web";
import { Button, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useMemo, useReducer, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import type { IterableElement, SetNonNullable, SetRequired } from "type-fest";

import { TAG_COLORS_ANTD } from "../../constants";
import type { TagCreateOrAttachInput } from "../../schema";
import type { InputTags_tenant$key } from "../../__generated__/InputTags_tenant.graphql";
import { Tag } from "../Tag";

export const InputTags = ({
  onChange,
  tagsKey,
  value: _value = [],
}: {
  onChange?: (x: typeof _value) => void;
  tagsKey: InputTags_tenant$key;
  value?: TagCreateOrAttachInput[];
}) => {
  const { tags: tagsOptions } = useFragment(
    graphql`
      fragment InputTags_tenant on TenantNode {
        tags {
          id
          color
          name
        }
      }
    `,
    tagsKey
  );

  const [tagsValue, dispatchTagsValue] = useReducer(
    (
      state: TagCreateOrAttachInput[],
      {
        action,
        value,
        i,
      }:
        | {
            action: "attach";
            i?: undefined;
            value: SetRequired<SetNonNullable<IterableElement<typeof _value>, "id">, "id">;
          }
        | { action: "clear" | "reset"; i?: undefined; value?: undefined }
        | {
            action: "create";
            i?: undefined;
            value: SetRequired<SetNonNullable<IterableElement<typeof _value>, "name">, "name">;
          }
        | { action: "remove"; i: number; value?: undefined }
    ): typeof state => {
      switch (action) {
        case "attach":
          return [...state, value];
        case "clear":
          return [];
        case "create":
          return [...state, value];
        case "remove":
          return [...state.slice(0, i), ...state.slice(i + 1)];
        case "reset":
          return _value;
      }
    },
    _value
  );
  useUpdateEffect(() => {
    if (!isEqualSimple(tagsValue, _value)) onChange?.(tagsValue);
  }, [tagsValue]);

  const _valuePrevious = usePrevious(_value);
  useUpdateEffect(() => {
    if (!isEqualSimple(_value, _valuePrevious) && !isEqualSimple(_value, tagsValue))
      dispatchTagsValue({ action: "reset" });
  }, [_value]);

  const [search, setSearch] = useState<string | undefined>();
  const searchTrimmed = useMemo(() => search?.trim(), [search]);

  const canCreateTag = useMemo(
    () =>
      !!searchTrimmed &&
      !tagsValue.find(x => x.name?.toLowerCase() === searchTrimmed.toLowerCase()) &&
      !tagsOptions.find(x => x.name?.toLowerCase() === searchTrimmed.toLowerCase()),
    [searchTrimmed, tagsOptions, tagsValue]
  );
  const createTag = useCallback(() => {
    if (!!searchTrimmed && canCreateTag) {
      dispatchTagsValue({ action: "create", value: { name: searchTrimmed } });
      setSearch(undefined);
    }
  }, [canCreateTag, searchTrimmed]);

  // MUTATIONS FOR DELETING + EDITING TAG

  // const [commitDeleteTag] = useMutation<InputTags_DeleteTag_Mutation>(graphql`
  //   mutation InputTags_DeleteTag_Mutation($input: DeleteTagInput!) {
  //     deleteTag(input: $input) {
  //       tenant {
  //         tags {
  //           id
  //         }
  //       }
  //     }
  //   }
  // `);

  // const [commitUpdateTag] = useMutation<InputTags_UpdateTag_Mutation>(graphql`
  //   mutation InputTags_UpdateTag_Mutation($input: UpdateTagInput!) {
  //     updateTag(input: $input) {
  //       tag {
  //         tenant {
  //           tags {
  //             id
  //             color
  //             name
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <StyledRoot className="hub-input-tags">
      <Select
        dropdownRender={origin => (
          <>
            {origin}
            {!!canCreateTag && (
              <Button
                className="hub-input-tags-create"
                onClick={createTag}
                onMouseDown={e => e.preventDefault()}
                type="text"
              >
                <span>Create:&nbsp;</span>
                <Tag label={searchTrimmed!} />
              </Button>
            )}
          </>
        )}
        filterOption={(inputValue, option) =>
          inputValue.toLowerCase() === tagsOptions.find(x => x.id === option?.value)?.name.toLowerCase()
        }
        getPopupContainer={trigger => trigger.parentElement!}
        mode="multiple"
        onClear={() => dispatchTagsValue({ action: "reset" })}
        onDeselect={(i: number) => dispatchTagsValue({ action: "remove", i })}
        onInputKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            createTag();
          }
        }}
        // @ts-expect-error Select thinks we will be selecting `number` because we pass an array of numbers to `value`
        onSelect={(x: string) => dispatchTagsValue({ action: "attach", value: tagsOptions.find(y => y.id === x)! })}
        onSearch={x => setSearch(x.substring(0, 30))}
        optionFilterProp="children"
        notFoundContent={searchTrimmed ? <div className="hub-hide-parent" /> : undefined}
        searchValue={search}
        tagRender={({ closable, label, value, onClose, ...props }) => {
          const { color } = tagsValue[value as number]!;

          return (
            <Tag
              className="hub-input-tags-tag-parent"
              color={color ? TAG_COLORS_ANTD[color as keyof typeof TAG_COLORS_ANTD] : undefined}
              closable={closable}
              label={typeof label === "number" ? tagsValue[label]!.name! : label!}
              onClose={onClose}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          );
        }}
        value={tagsValue.map((_, i) => i)}
      >
        {tagsOptions
          .filter(x => !tagsValue.find(y => y.id === x.id))
          .map(x => (
            <Select.Option key={x.id} value={x.id}>
              <Tag
                color={x.color ? TAG_COLORS_ANTD[x.color as keyof typeof TAG_COLORS_ANTD] : undefined}
                label={x.name}
                onMouseDown={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              {/* UI FOR DELETING + EDITING TAG */}
              {/* <Popover
                content={
                  <>
                    <Input
                      value={x.name}
                      // onChange={e => commitUpdateTag({ variables: { input: { id: x.id, name: e.target.value } } })}
                      onClick={e => e.stopPropagation()}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          e.preventDefault();

                          const name = e.currentTarget.value.trim();
                          if (name) commitUpdateTag({ variables: { input: { id: x.id, name } } });
                        }
                      }}
                    ></Input>
                    <Button
                      className="hub-input-tags-tag-delete"
                      danger
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        return commitDeleteTag({ variables: { input: { id: x.id } } });
                      }}
                    >
                      <Icon height="1.2em" icon="mdi:delete-outline" />
                    </Button>
                    <Radio.Group
                      className="hub-input-tags-tag-colors"
                      onChange={e => {
                        e.stopPropagation();
                        commitUpdateTag({ variables: { input: { id: x.id, color: e.target.value } } });
                      }}
                    >
                      {Object.entries(TAG_COLORS_NAMES).map(([k, v]) => (
                        <Radio.Button
                          className={classNames({ "hub-input-tags-tag-colors-selected": x.color === k })}
                          value={k}
                        >
                          <Tag color={TAG_COLORS_ANTD[k as keyof typeof TAG_COLORS_NAMES]} label="A"></Tag>{" "}
                          <span>{v}</span>
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </>
                }
                getPopupContainer={trigger => trigger.closest(".hub-input-tags")!}
                open={tagsOptions.find(y => y.id === x.id) ? undefined : false}
                placement="bottomLeft"
                trigger="click"
              >
                <Button
                  type="text"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Icon height="1.2em" icon="mdi:dots-horizontal" />
                </Button>
              </Popover> */}
            </Select.Option>
          ))}
      </Select>
    </StyledRoot>
  );
};

const StyledRoot = styled.div`
  .ant-select-selector {
    padding: 8px;
  }

  .ant-select-selection-overflow {
    row-gap: 8px;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-popover {
    z-index: 2000;
  }

  .ant-popover-inner-content {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;

    .hub-input-tags-tag-colors {
      grid-column: span 2;
    }
  }

  .hub-input-tags-tag-delete {
    padding: 4px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hub-input-tags-tag-colors {
    display: flex;
    flex-direction: column;

    .ant-radio-button-wrapper {
      border: 0;
      border-radius: 4px;
      height: auto;
      padding: 4px 8px;

      &::before {
        display: none;
      }
    }

    .hub-input-tags-tag-colors-selected {
      background-color: var(--ant-primary-1);
    }

    .ant-radio-button + span {
      display: flex;
    }

    .hub-tag {
      width: 30px;
      text-align: center;
    }

    .hub-tag-label {
      width: 100%;
    }
  }

  .hub-input-tags-tag-parent .hub-tag {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }

  .hub-input-tags-create {
    height: auto;
    text-align: left;
    width: 100%;
  }
`;
