/**
 * @generated SignedSource<<5d7fb51f61571a43106af3e3f40aa886>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveEmailTemplateInput = {
  clientMutationId?: string | null;
  message?: string | null;
  name: string;
  replyTo?: string | null;
  subject?: string | null;
};
export type SaveTemplateModal_saveEmailTemplate_Mutation$variables = {
  input: SaveEmailTemplateInput;
};
export type SaveTemplateModal_saveEmailTemplate_Mutation$data = {
  readonly saveEmailTemplate: {
    readonly tenant: {
      readonly emailTemplates: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly message: string;
            readonly name: string;
            readonly replyTo: string;
            readonly subject: string;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};
export type SaveTemplateModal_saveEmailTemplate_Mutation = {
  response: SaveTemplateModal_saveEmailTemplate_Mutation$data;
  variables: SaveTemplateModal_saveEmailTemplate_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replyTo",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveTemplateModal_saveEmailTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveEmailTemplatePayload",
        "kind": "LinkedField",
        "name": "saveEmailTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailTemplateConnection",
                "kind": "LinkedField",
                "name": "emailTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmailTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmailTemplateNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveTemplateModal_saveEmailTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveEmailTemplatePayload",
        "kind": "LinkedField",
        "name": "saveEmailTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailTemplateConnection",
                "kind": "LinkedField",
                "name": "emailTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmailTemplateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmailTemplateNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be18f2bbb0f14191b6778870be4b9ac0",
    "id": null,
    "metadata": {},
    "name": "SaveTemplateModal_saveEmailTemplate_Mutation",
    "operationKind": "mutation",
    "text": "mutation SaveTemplateModal_saveEmailTemplate_Mutation(\n  $input: SaveEmailTemplateInput!\n) {\n  saveEmailTemplate(input: $input) {\n    tenant {\n      emailTemplates {\n        edges {\n          node {\n            name\n            replyTo\n            subject\n            message\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a59a9f68354876bfd1464d673e2b948b";

export default node;
