import { App, Button, Form, Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { mapValues, omit, pick } from "lodash-es";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { SetRequired } from "type-fest";

import { StickToBottom } from "../..";
import { ConfigProviderAccent } from "../../../antd";
import { BORDER_COLOR, PAGE_WIDTH_XS } from "../../../style";
import { sentenceCase, useBoundForm, useMutation } from "../../../utils";
import type { FormPerson_person$data, FormPerson_person$key } from "../../../__generated__/FormPerson_person.graphql";
import type { FormPerson_UpdatePerson_Mutation } from "../../../__generated__/FormPerson_UpdatePerson_Mutation.graphql";
import { DetailsInput } from "../../Configure/DetailsInput";

type Values = {
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
} & (
  | {
      password: string;
      passwordConfirm: string;
    }
  | {
      password: undefined;
      passwordConfirm: undefined;
    }
);

const createInitialValues = (
  person: SetRequired<Partial<FormPerson_person$data>, "email" | "firstName" | "lastName" | "phoneNumber">
): Values => ({
  ...mapValues(pick(person, "email", "firstName", "lastName", "phoneNumber"), x => x ?? undefined),
  password: undefined,
  passwordConfirm: undefined,
});

export const FormPerson = ({
  onReset: _onReset,
  onTouch: _onTouch,
  person: personKey,
}: {
  onReset: () => void;
  onTouch: () => void;
  person: FormPerson_person$key;
}) => {
  const { notification } = App.useApp();

  const person = useFragment(
    graphql`
      fragment FormPerson_person on PersonNode {
        id
        email
        firstName
        lastName
        phoneNumber
        requirePhoneNumber
      }
    `,
    personKey
  );

  const [commit, isInFlight] = useMutation<FormPerson_UpdatePerson_Mutation>(graphql`
    mutation FormPerson_UpdatePerson_Mutation($input: UpdatePersonInput!) {
      updatePerson(input: $input) {
        person {
          id
          ...FormPerson_person
        }
      }
    }
  `);

  const { form, initialValues, onFinish, onReset, onValuesChange, touched } = useBoundForm(person, {
    createInitialValues,
    onFinish: changedValues =>
      commit(
        mapValues(omit(changedValues, "passwordConfirm"), x => (x === "" ? null : x)),
        {
          onCompleted: () => notification.success({ message: "Personal info updated" }),
        }
      ),
    onReset: _onReset,
    onTouch: _onTouch,
  });

  const [password, setPassword] = useState<Values["password"]>();

  const { t } = useTranslation();

  return (
    <Root>
      <Form
        disabled={isInFlight}
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={(changedValues: Partial<Values>, values) => {
          if (Object.hasOwn(changedValues, "password")) setPassword(changedValues.password);

          onValuesChange(changedValues, values);
        }}
      >
        <DetailsInput
          collapseBottomMargin
          inputs={
            <Form.Item
              messageVariables={{ name: t("dictionary.noun.name-given") }}
              name="firstName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input autoComplete="given-name" placeholder={sentenceCase(t("dictionary.noun.name-given"))} />
            </Form.Item>
          }
          title={sentenceCase(t("dictionary.noun.name-given"))}
        />
        <DetailsInput
          collapseBottomMargin
          inputs={
            <Form.Item
              messageVariables={{ name: t("dictionary.noun.name-family") }}
              name="lastName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input autoComplete="family-name" placeholder={sentenceCase(t("dictionary.noun.name-family"))} />
            </Form.Item>
          }
          title={sentenceCase(t("dictionary.noun.name-family"))}
        />
        <DetailsInput
          collapseBottomMargin
          inputs={
            <Form.Item
              messageVariables={{ name: t("dictionary.noun.password") }}
              name="password"
              rules={
                password
                  ? [
                      {
                        max: 64,
                        min: 8,
                        required: true,
                      },
                      {
                        validator: (_, x: string) =>
                          /^[0-9]+$/.test(x) ? Promise.reject("Cannot be all numbers") : Promise.resolve(),
                      },
                      {
                        validator: (_, value: string) =>
                          Object.values<string>(form.getFieldsValue(["firstName", "lastName", "email"]))
                            .map(x => x.split("@"))
                            .flat()
                            .some(x => value.toLowerCase().includes(x.toLowerCase()))
                            ? Promise.reject("Don't use personal info")
                            : Promise.resolve(),
                      },
                    ]
                  : undefined
              }
            >
              <Input
                autoComplete="new-password"
                placeholder={sentenceCase(`${t("dictionary.adjective.new")} ${t("dictionary.noun.password")}`)}
                type="password"
              />
            </Form.Item>
          }
          optional
          title={sentenceCase(`${t("dictionary.adjective.new")} ${t("dictionary.noun.password")}`)}
        />
        <DetailsInput
          collapseBottomMargin
          inputs={
            <Form.Item
              messageVariables={{ name: t("dictionary.noun.password") }}
              name="passwordConfirm"
              rules={[
                {
                  required: !!password,
                },
                {
                  validator: (_, x) =>
                    x === form.getFieldValue("password") ? Promise.resolve() : Promise.reject("Passwords must match"),
                },
              ]}
            >
              <Input
                autoComplete="new-password"
                placeholder={sentenceCase(`${t("dictionary.verb.confirm")} ${t("dictionary.noun.password")}`)}
                type="password"
              />
            </Form.Item>
          }
          optional={typeof password === "undefined"}
          title={sentenceCase(`${t("dictionary.verb.confirm")} ${t("dictionary.noun.password")}`)}
        />
        {/* <DetailsInput
          className="col-span-2"
          collapseBottomMargin
          inputs={
            <Form.Item
              messageVariables={{ name: t("dictionary.noun.email") }}
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input autoComplete="email" placeholder={sentenceCase(t("dictionary.noun.email"))} type="email" />
            </Form.Item>
          }
          title={sentenceCase(t("dictionary.noun.email"))}
        /> */}
        <DetailsInput
          className="col-span-2"
          description={`If outside the United States, include "+" and your country code`}
          inputs={
            <Form.Item
              className="mb-4px"
              messageVariables={{ label: t("dictionary.noun.phone") }}
              name="phoneNumber"
              rules={[
                {
                  required: person.requirePhoneNumber,
                },
                {
                  validator: (_, x) =>
                    (!person.requirePhoneNumber && !x) ||
                    /^\+(?:[0-9] ?){6,14}[0-9]$/.test(x) || // international phone number
                    /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(x) || // us local phone number (formatted)
                    /^[0-9]{10}$/.test(x) // us local phone number (unformatted)
                      ? Promise.resolve()
                      : Promise.reject("Not a valid phone number"),
                },
              ]}
            >
              <Input autoComplete="tel" placeholder={sentenceCase(t("dictionary.noun.phone"))} type="tel" />
            </Form.Item>
          }
          optional={!person.requirePhoneNumber}
          title={sentenceCase(t("dictionary.noun.phone"))}
        />
        <StickToBottom className="col-span-2 hub-form-actions">
          <ConfigProviderAccent>
            <Button disabled={isInFlight || !touched} htmlType="reset">
              {sentenceCase(t("dictionary.verb.cancel"))}
            </Button>
            <Button disabled={isInFlight || !touched} htmlType="submit" loading={isInFlight} type="primary">
              {sentenceCase(t("dictionary.verb.save"))}
            </Button>
          </ConfigProviderAccent>
        </StickToBottom>
      </Form>
    </Root>
  );
};

const Root = styled.div`
  & > form {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr;

    @media (min-width: ${PAGE_WIDTH_XS}) {
      grid-template:
        "name-first name-last" auto
        "password password-confirm" auto
        "email email" auto
        "phone phone" auto
        "actions actions" auto
        / 1fr 1fr;
    }
  }

  @media (min-width: ${PAGE_WIDTH_XS}) {
    .col-span-2 {
      grid-column: span 2;
    }
  }

  .mb-4px {
    margin-bottom: 0.25rem;
  }

  .hub-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin: 0;

    position: sticky;
    bottom: 0;
    transition: all 300ms;

    &.stuck {
      padding: 16px;
      margin: 0 -16px;
      background-color: white;
      border-top: 1px solid ${BORDER_COLOR};
    }
  }
`;
