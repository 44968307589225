import type {
  FilterBase,
  FilterGroupGroup,
  FilterOp,
  FilterResponseBase,
  FilterResponseIdExtra,
  FilterResponseValueDateExtra,
  FilterResponseValueNumberExtra,
  FilterResponseValueTextExtra,
  FilterType,
  GroupOp,
} from "./base";

type FilterCharacteristicResponse = FilterResponseBase<"characteristic"> &
  (
    | FilterResponseIdExtra
    | FilterResponseValueTextExtra
    | FilterResponseValueDateExtra
    | FilterResponseValueNumberExtra
  );

type FilterPanelist = FilterBase & {
  type: FilterType.Panelist;
  subtype: "ID";
  op: FilterOp.Contains | FilterOp.Is;
  values: string[];
};

type FilterParticipant = FilterBase & {
  type: FilterType.Participant;
  subtype: "DAYS_SINCE_LAST_SCREENER" | "DAYS_SINCE_LAST_STUDY";
  op: FilterOp.GreaterThan | FilterOp.LessThan;
  values: number[];
};

type FilterPerson = FilterBase & {
  type: FilterType.Person;
  subtype: "EMAIL" | "FIRST_NAME" | "LAST_NAME" | "PHONE_NUMBER";
  op: FilterOp.Contains | FilterOp.Is;
  values: string[];
};

export const enum FilterStudyFilterType {
  Date = "DATE",
  Name = "NAME",
  Tags = "TAGS",
}

type FilterStudyFilterDateExtra = {
  type: FilterStudyFilterType.Date;
  op: FilterOp.Before | FilterOp.After | FilterOp.Is;
  values: string[];
};

type FilterStudyFilterTextExtra = {
  type: FilterStudyFilterType.Name | FilterStudyFilterType.Tags;
  op: FilterOp.Contains | FilterOp.Is;
  values: string[];
};

export type FilterStudyFilter = FilterBase & (FilterStudyFilterDateExtra | FilterStudyFilterTextExtra);

export type FilterStudy = FilterBase & {
  type: FilterType.Study;
  subtype: "COMPLETED" | "INVITED";
  op: GroupOp.And;
  filters: FilterStudyFilter[];
};

export type PanelistFilter =
  | FilterCharacteristicResponse
  | FilterPanelist
  | FilterParticipant
  | FilterPerson
  | FilterStudy;

export type PanelistFilterGroupGroup = FilterGroupGroup<PanelistFilter>;
