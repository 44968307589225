import { graphql } from "babel-plugin-relay/macro";
import moment from "moment";
import { useFragment } from "react-relay";
import { DATE_FORMAT } from "../../../constants";

import type { InputCharacteristicResponseDate_characteristic$key } from "../../../__generated__/InputCharacteristicResponseDate_characteristic.graphql";
import { InputCharacteristicResponseDate_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponseDate_characteristicResponses.graphql";
import { InputDate } from "../InputDate";

export const InputCharacteristicResponseDate = ({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  onChange,
  value,
}: {
  characteristic: InputCharacteristicResponseDate_characteristic$key;
  characteristicResponses: InputCharacteristicResponseDate_characteristicResponses$key;
  onChange?: Parameters<typeof InputDate>[0]["onChange"];
  value?: Parameters<typeof InputDate>[0]["value"];
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _characteristic = useFragment(
    graphql`
      fragment InputCharacteristicResponseDate_characteristic on CharacteristicNode {
        id
      }
    `,
    characteristicKey
  );
  const [response] = useFragment(
    graphql`
      fragment InputCharacteristicResponseDate_characteristicResponses on CharacteristicResponseNode
      @relay(plural: true) {
        id
        textValue
      }
    `,
    characteristicResponsesKey
  );

  return (
    <InputDate
      defaultValue={response?.textValue ? moment(response.textValue) : undefined}
      format={DATE_FORMAT}
      onChange={onChange}
      value={value}
    />
  );
};
