/**
 * @generated SignedSource<<1e7f80359b4a88f71eb6fea2bb77ecab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseDate_characteristicResponses$data = ReadonlyArray<{
  readonly id: string;
  readonly textValue: string;
  readonly " $fragmentType": "InputCharacteristicResponseDate_characteristicResponses";
}>;
export type InputCharacteristicResponseDate_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponseDate_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseDate_characteristicResponses">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponseDate_characteristicResponses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textValue",
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};

(node as any).hash = "7f57d765f927eb3a1a11ba68b9b5f53d";

export default node;
