/**
 * @generated SignedSource<<44fb8f47f0bf44d5ba97e77955132560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateRemindersTemplateInput = {
  clientMutationId?: string | null;
  daysBefore?: number | null;
  enabled?: boolean | null;
  hoursBefore?: number | null;
  message?: string | null;
  minsBefore?: number | null;
  subject?: string | null;
  templateId: string;
  version?: string | null;
};
export type CommunicationRemindersPageMutation$variables = {
  input: UpdateRemindersTemplateInput;
};
export type CommunicationRemindersPageMutation$data = {
  readonly updateRemindersTemplate: {
    readonly remindersTemplate: {
      readonly enabled: boolean;
      readonly id: string;
    } | null;
  } | null;
};
export type CommunicationRemindersPageMutation = {
  response: CommunicationRemindersPageMutation$data;
  variables: CommunicationRemindersPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateRemindersTemplatePayload",
    "kind": "LinkedField",
    "name": "updateRemindersTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RemindersTemplatesNode",
        "kind": "LinkedField",
        "name": "remindersTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunicationRemindersPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunicationRemindersPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "11bc46787a601de4a32b65cde2a53d2c",
    "id": null,
    "metadata": {},
    "name": "CommunicationRemindersPageMutation",
    "operationKind": "mutation",
    "text": "mutation CommunicationRemindersPageMutation(\n  $input: UpdateRemindersTemplateInput!\n) {\n  updateRemindersTemplate(input: $input) {\n    remindersTemplate {\n      id\n      enabled\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c03344760b025b317141461ed50bcbb5";

export default node;
