import { App, message as antMessage } from "antd";
import type { MessageInstance } from "antd/es/message/interface";
import { useCallback } from "react";
import { useMutation as _useMutation } from "react-relay";
import {
  commitMutation,
  type DeclarativeMutationConfig,
  type GraphQLTaggedNode,
  type MutationParameters,
} from "relay-runtime";

import { environment } from "../relay";

export class GraphQLError extends Error {
  /**
   * Pass this an error from the graphql response's `errors` property
   */
  constructor(public payload: Record<string, any>) {
    super(payload.message);
  }
}

export const handleError = (err: any) => console.error(err);
export const handleErrorWithMessage = (err: any, message?: string, messageInstance: MessageInstance = antMessage) => {
  handleError(err);
  messageInstance.error(message || "An error occurred. Please try again later.");
};
export const handleSuccessWithMessage = (message?: string, messageInstance: MessageInstance = antMessage) => {
  messageInstance.success(message || "Saved.");
};

export const onError = (e: any, messageInstance: MessageInstance = antMessage) =>
  handleErrorWithMessage(e, e.errors?.[0]?.message ?? e.message, messageInstance);

export const mutation = <T extends MutationParameters>({
  variables,
  mutation,
  optimisticResponse,
  silenceDefaultError,
  configs,
}: {
  variables: T["variables"];
  mutation: GraphQLTaggedNode;
  optimisticResponse?: T["response"];
  silenceDefaultError?: boolean;
  configs?: DeclarativeMutationConfig[];
}) =>
  new Promise<T["response"]>((resolve, reject) => {
    commitMutation<T>(environment, {
      mutation,
      variables,
      // @ts-expect-error We're doing something wrong with optimisticResponse
      optimisticResponse,
      onCompleted: (response, errors) => {
        if (errors) {
          reject({ errors });
          return handleError(errors);
        }
        resolve(response);
      },
      onError: (err: any) => {
        if (!silenceDefaultError) {
          handleErrorWithMessage(err, "Save failed. Please try again later.");
        }
        reject(err);
      },
      configs,
    });
  });

/**
 * Wrapper around `react-relay`'s `useMutation` with default error handling and opinionated signature
 *
 * @example
 *    const [commit] = useMutation(...)
 *
 *    // with this `useMutation`
 *    commit({ ... })
 *
 *    // with `useMutation` from `react-relay`
 *    commit({
 *      onError,
 *      variables: { input: { ... } }
 *    })
 */
export const useMutation = <T extends MutationParameters & { variables: Record<string, any> }>(
  mutation: GraphQLTaggedNode
) => {
  const { message } = App.useApp();

  const [_commit, isInFlight] = _useMutation<T>(mutation);
  const commit = useCallback(
    (
      input: Parameters<typeof _commit>[0]["variables"]["input"],
      config: Omit<Parameters<typeof _commit>[0], "variables"> &
        Partial<Pick<Parameters<typeof _commit>[0], "variables">>
    ) =>
      _commit({
        onError: e => onError(e, message),
        ...config,
        variables: { input, ...config.variables },
      }),
    [_commit, message]
  );

  return [commit, isInFlight] as const;
};
