import outlineFilterList from "@iconify-icons/ic/outline-filter-list";
import { Icon } from "@iconify/react";
import { useLocalStorageValue, useMountEffect, useUpdateEffect } from "@react-hookz/web";
import { Badge, Button, Input, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { kebabCase, uniqueId } from "lodash-es";
import { useMemo, useState, type ReactNode } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import styled from "styled-components";

import { ScreenersFilterTypeChoices } from "../../schema";
import { GRAY_1 } from "../../style";
import { getApiBaseUrl } from "../../utils/misc";
import type { PanelFiltersViewerRefetchQuery } from "../../__generated__/PanelFiltersViewerRefetchQuery.graphql";
import type { PanelFilters_recruit$key } from "../../__generated__/PanelFilters_recruit.graphql";
import type { PanelFilters_viewer$key } from "../../__generated__/PanelFilters_viewer.graphql";
import { ExportButton } from "../ExportButton";
import { DrawerFilters, GroupOp, type PanelistFilterGroupGroup } from "../Filters";

const { Search } = Input;

export const createPanelistFilterGroupGroup = (): PanelistFilterGroupGroup => ({
  id: uniqueId(),
  op: GroupOp.And,
  filters: [],
});

const PanelFilters = ({
  children,
  disableExport,
  loading,
  onApplySearch,
  onApplyFilters,
  panelistsCount,
  recruit: recruitKey = null,
  value,
  viewer: viewerKey,
}: {
  children?: ReactNode;
  disableExport: boolean;
  loading: boolean;
  onApplyFilters: (o: PanelistFilterGroupGroup) => void;
  onApplySearch: (searchValue: string) => void;
  panelistsCount: number;
  recruit?: PanelFilters_recruit$key | null;
  value: PanelistFilterGroupGroup;
  viewer: PanelFilters_viewer$key;
}) => {
  const recruit = useFragment(
    graphql`
      fragment PanelFilters_recruit on RecruitNode {
        id
        dbId
        export {
          ...ExportButton_export
        }
        name
        study {
          dId
          name
        }
      }
    `,
    recruitKey
  );
  const [viewer, refetch] = useRefetchableFragment<PanelFiltersViewerRefetchQuery, typeof viewerKey>(
    graphql`
      fragment PanelFilters_viewer on Viewer @refetchable(queryName: "PanelFiltersViewerRefetchQuery") {
        user {
          id
          profile {
            tenant {
              id
              name
              filters {
                id
                name
                user {
                  id
                }
                study {
                  id
                }
                type
                filters
              }
              panel {
                id
                export {
                  ...ExportButton_export
                }
              }
            }
          }
        }
        ...DrawerFilters_viewer
      }
    `,
    viewerKey
  );

  const savedFilters = useMemo(
    () => viewer.user?.profile?.tenant?.filters?.filter(x => x.type === "PN") ?? [],
    [viewer.user?.profile?.tenant?.filters]
  );

  const [filterGroupGroup, setFilterGroupGroup] = useState<PanelistFilterGroupGroup>(value);
  const [filtersAppliedCount, setFiltersAppliedCount] = useState<number>(0);
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState<boolean>(false);
  const [loadedFilterId, setLoadedFilterId] = useState<string | undefined>(undefined);

  // track the active filter across reloads
  const [pinnedFilter, setPinnedFilter, deletePinnedFilter] = useLocalStorageValue<PanelistFilterGroupGroup | null>(
    "hub-filters-pinned-community"
  );

  const applyFilterGroupGroup = (x: PanelistFilterGroupGroup) => {
    setFiltersAppliedCount(x.filters.length);
    setPinnedFilter(x);
    onApplyFilters(x);
  };

  useMountEffect(() => {
    if (pinnedFilter != null) {
      setFilterGroupGroup(pinnedFilter);
      applyFilterGroupGroup(pinnedFilter);
    }
  });

  useUpdateEffect(() => {
    if (loadedFilterId) {
      const x = savedFilters.find(x => x.id === loadedFilterId);

      if (x) {
        const filterGroupGroup = JSON.parse(x.filters);

        setFilterGroupGroup(filterGroupGroup);
        applyFilterGroupGroup(filterGroupGroup);
      }
    }
  }, [loadedFilterId]);

  return (
    <Styled>
      <div className="filter-wrapper">
        <Search
          className="participant-search"
          placeholder="Search"
          onSearch={value => onApplySearch(value)}
          allowClear
          loading={loading}
          spellCheck={false}
        />
        <Badge
          count={filtersAppliedCount}
          style={{
            backgroundColor: "var(--ant-primary-color)",
            color: GRAY_1,
            borderColor: "var(--ant-primary-color)",
          }}
        >
          <Tooltip title="Filters">
            <Button className="filter-button" onClick={() => setFiltersDrawerVisible(prevState => !prevState)}>
              <Icon icon={outlineFilterList} height={20} />
            </Button>
          </Tooltip>
        </Badge>
        <DrawerFilters
          filterGroupGroup={filterGroupGroup}
          loadedFilterId={loadedFilterId}
          loading={loading}
          onApply={() => applyFilterGroupGroup(filterGroupGroup)}
          onClear={() => {
            const x = createPanelistFilterGroupGroup();
            setFilterGroupGroup(x);
            setLoadedFilterId(undefined);
            applyFilterGroupGroup(x);
            deletePinnedFilter();
          }}
          onClose={() => setFiltersDrawerVisible(false)}
          onDelete={() => refetch({})}
          open={filtersDrawerVisible}
          setFilterGroupGroup={setFilterGroupGroup}
          setLoadedFilterId={setLoadedFilterId}
          type={ScreenersFilterTypeChoices.Pn}
          viewer={viewer}
        />
        <div className="export-button">
          {children}
          {!disableExport && !!(recruit || viewer.user?.profile?.tenant?.panel) && (
            <ExportButton
              disabled={loading}
              export={(recruit || viewer.user!.profile!.tenant!.panel)!.export}
              filters={filterGroupGroup}
              immediate={panelistsCount < 80}
              immediateFilename={
                recruit
                  ? `${kebabCase(recruit.study.name || "<Untitled>")}--${kebabCase(
                      recruit.name || "<Untitled>"
                    )}--panelists`
                  : "panelists"
              }
              immediateUrl={
                recruit
                  ? `${getApiBaseUrl()}/s/${recruit.study.dId}/panelistsRecruit/${recruit.dbId}`
                  : `${getApiBaseUrl()}/download-panelists`
              }
              relatedId={recruit?.id || viewer.user!.profile!.tenant!.panel!.id}
            />
          )}
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  width: 100%;

  .filter-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .participant-search {
      max-width: 300px;
      .ant-input {
        background-color: #fff !important;
      }
    }

    .filter-button {
      padding: 4px 5px;
    }
  }

  .export-button {
    margin-left: auto;
    display: inline-flex;
    align-items: baseline;
    gap: inherit;
  }
`;

export default PanelFilters;
