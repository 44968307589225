import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import { VideoElement_element$data } from "../../__generated__/VideoElement_element.graphql";
import { VideoElement_screener$data } from "../../__generated__/VideoElement_screener.graphql";
import { CommonElementProps } from "./Element";
import { ElementBase } from "./index";

type Props = {
  element: VideoElement_element$data;
  screener: VideoElement_screener$data;
} & CommonElementProps;
const VideoElement: React.FC<Props> = props => <ElementBase {...props} />;

export default createFragmentContainer(VideoElement, {
  element: graphql`
    fragment VideoElement_element on ElementNode {
      ...ElementBase_element
    }
  `,
  screener: graphql`
    fragment VideoElement_screener on ScreenerNode {
      ...ElementBase_screener
    }
  `,
});
