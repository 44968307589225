import { Icon } from "@iconify/react";
import { useTimeoutEffect, useUpdateEffect } from "@react-hookz/web";
import { Form, FormInstance, Input, Switch, Tooltip, Upload, type UploadFile } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { pick } from "lodash-es";
import type { RcFile, UploadRequestError } from "rc-upload/lib/interface";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { fetchQuery } from "react-relay";
import styled from "styled-components";

import { EMAIL_ASSETS_MAX_SIZE_BYTES, type AutomatedEmail, type AutomatedEmailAsset } from "../../../constants";
import { environment } from "../../../relay";
import { handleErrorWithMessage } from "../../../utils";
import { AutomatedEmailInputs_assetUploadUrlQuery } from "../../../__generated__/AutomatedEmailInputs_assetUploadUrlQuery.graphql";
import { DetailsInput } from "../../Configure/DetailsInput";
import { EmailInputs } from "../EmailInputs";

import { getAutomatedEmailMeta } from "./types";
import { AutomatedEmailOnFormResetContext } from "./utils";

const isRcFile = (file: string | Blob | RcFile): file is RcFile => typeof file === "object" && "uid" in file;

const getAssetsFileList = (automatedEmail: AutomatedEmail): UploadFile[] =>
  automatedEmail.assets.map(x => ({
    name: x.name ?? x.id,
    size: x.sizeBytes ?? 0, // this should never be 0 because AutomatedEmailInputs always creates Assets with sizeBytes
    status: "done",
    uid: x.id,
    url: x.url,
  }));

export const AutomatedEmailInputs = ({
  form,
  automatedEmail,
  setIsDirty,
  showEnabled,
  defaultCtaUrl,
}: {
  form: FormInstance;
  automatedEmail: AutomatedEmail;
  setIsDirty?: (isDirty: boolean) => void;
  showEnabled: boolean;
  defaultCtaUrl?: string;
}) => {
  const [showCtaInputs, setShowCtaInputs] = useState(!!(automatedEmail.ctaLabel || defaultCtaUrl));
  const ctaInputsRef = useRef<HTMLDivElement>(null);
  useUpdateEffect(() => ctaInputsRef.current?.scrollIntoView({ behavior: "smooth" }), [showCtaInputs]);

  const [assetsFileList, setAssetsFileList] = useState<UploadFile[]>(getAssetsFileList(automatedEmail));
  useEffect(
    () =>
      setIsDirty?.(
        assetsFileList.length !== automatedEmail.assets.length ||
          assetsFileList.some(x => !automatedEmail.assets.find(y => y.url === x.url))
      ),
    [assetsFileList, automatedEmail.assets, setIsDirty]
  );
  useUpdateEffect(
    () => setAssetsFileList(getAssetsFileList(automatedEmail)),
    [automatedEmail.studyId, automatedEmail.type]
  );
  const setAssetFileUrl = (uid: string, url: string) => {
    const i = assetsFileList.findIndex(x => x.uid === uid);
    setAssetsFileList([
      ...assetsFileList.slice(0, i),
      {
        ...assetsFileList[i]!,
        url,
      },
      ...assetsFileList.slice(i + 1),
    ]);
  };

  const setOnFormReset = useContext(AutomatedEmailOnFormResetContext);
  useEffect(
    () => setOnFormReset?.(() => () => setAssetsFileList(getAssetsFileList(automatedEmail))),
    [automatedEmail, setOnFormReset]
  );

  const [assetsMaxSizeBytesExceeded, _setAssetsMaxSizeBytesExceeded] = useState(false);
  const [cancelAssetsMaxBytesExceededTimeout, resetAssetsMaxBytesExceededTimeout] = useTimeoutEffect(() => {
    if (assetsMaxSizeBytesExceeded) {
      _setAssetsMaxSizeBytesExceeded(false);
      form.validateFields(["assets"]);
    }
  }, 5000);
  const setAssetsMaxSizeBytesExceeded = (x: boolean) => {
    if (x) resetAssetsMaxBytesExceededTimeout();
    else cancelAssetsMaxBytesExceededTimeout();
    _setAssetsMaxSizeBytesExceeded(x);
    form.validateFields(["assets"]);
  };

  const [assetsInFlightCount, dispatchAssetsInFlightCount] = useReducer(
    (state: number, action: "decrement" | "increment") =>
      ({ decrement: () => state - 1, increment: () => state + 1 }[action]?.() ?? state),
    0
  );

  const getUploadUrl = async (fileName: string, fileType: string) => {
    try {
      const data = await fetchQuery<AutomatedEmailInputs_assetUploadUrlQuery>(
        environment,
        graphql`
          query AutomatedEmailInputs_assetUploadUrlQuery($fileName: String!, $fileType: String!) {
            assetUploadUrl(fileName: $fileName, fileType: $fileType)
          }
        `,
        { fileName, fileType },
        {
          fetchPolicy: "network-only",
        }
      ).toPromise();
      if (!data) throw new Error("Unable to resolve upload URL");
      return data.assetUploadUrl;
    } catch (e: any) {
      handleErrorWithMessage(e, e.errors?.[0]?.message ?? e.message);
      return null;
    }
  };

  return (
    <StyledAutomatedEmailInputs>
      {showEnabled ? (
        <DetailsInput
          title="Enabled"
          inputs={
            <Form.Item name="enabled" valuePropName="checked">
              <Switch onChange={() => setIsDirty?.(true)} />
            </Form.Item>
          }
        />
      ) : (
        <Form.Item name="enabled" hidden />
      )}
      <EmailInputs
        replyToRequired={getAutomatedEmailMeta(automatedEmail.type).replyToRequired}
        supportedMessageVariables={[
          "%respondent.id%",
          "%respondent.first_name%",
          "%respondent.last_name%",
          "%respondent.email%",
          "%study.name%",
          ...getAutomatedEmailMeta(automatedEmail.type).additionalSupportedVariables,
        ]}
      />
      {getAutomatedEmailMeta(automatedEmail.type).allowLink && (
        <>
          <div className="cta-toggle">
            <Tooltip title="This button will appear below the message above, and direct the user to their incentive.">
              <label>
                Add a button to claim the incentive
                <Switch
                  size="small"
                  style={{ marginLeft: 6 }}
                  checked={showCtaInputs}
                  onChange={val => {
                    setShowCtaInputs(val);
                    form.setFieldsValue(val ? { ctaUrl: defaultCtaUrl ?? null } : { ctaLabel: null, ctaUrl: null });
                    setIsDirty?.(true);
                  }}
                />
              </label>
            </Tooltip>
          </div>
          {showCtaInputs && (
            <div className="cta-inputs" ref={ctaInputsRef}>
              <DetailsInput
                title="Button label"
                inputs={
                  <Form.Item
                    name="ctaLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please provide a button label",
                      },
                    ]}
                  >
                    <Input maxLength={50} placeholder="i.e.: Click here to claim your reward" />
                  </Form.Item>
                }
              />
              <DetailsInput
                title="Link to claim incentive"
                inputs={
                  <Form.Item
                    name="ctaUrl"
                    rules={[
                      {
                        required: true,
                        message: "Please provide a link",
                      },
                      {
                        type: "url",
                        message: "Please enter a URL starting with https://",
                      },
                    ]}
                  >
                    <Input maxLength={200} placeholder="Link" />
                  </Form.Item>
                }
              />
            </div>
          )}
        </>
      )}
      <DetailsInput
        style={{ marginTop: 32 }}
        title="Attachments"
        optional
        inputs={
          <Form.Item
            name="assets"
            rules={
              assetsInFlightCount
                ? [{ validator: () => Promise.reject("Please wait for uploads to finish") }]
                : [
                    {
                      validator: (_, value: AutomatedEmailAsset[]) => {
                        const valueArray = Array.isArray(value)
                          ? value
                          : [
                              {
                                id: "",
                                name: null,
                                sizeBytes: null,
                                url: "",
                              },
                            ];
                        if (
                          assetsMaxSizeBytesExceeded ||
                          valueArray.reduce((acc, cur) => acc + cur.sizeBytes!, 0) > EMAIL_ASSETS_MAX_SIZE_BYTES
                        ) {
                          return Promise.reject("Total size of assets may not exceed 20 MB");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]
            }
            // @ts-expect-error getValueFromEvent expects handler with (...args: any[])
            getValueFromEvent={(info: Parameters<Parameters<typeof Upload>[0]["onChange"]>[0]) =>
              assetsFileList
                .filter(x => !!info.fileList.find(y => y.uid === x.uid))
                .map(x => ({ sizeBytes: x.size, ...pick(x, "name", "url") }))
            }
          >
            <Upload
              accept="*/*"
              action={async file => (await getUploadUrl(file.name, file.type)) as string}
              beforeUpload={(_, fileList) => {
                const exceeded = fileList.reduce((acc, cur) => acc + cur.size, 0) > EMAIL_ASSETS_MAX_SIZE_BYTES;
                setAssetsMaxSizeBytesExceeded(exceeded);
                return exceeded ? Upload.LIST_IGNORE : true;
              }}
              customRequest={async ({ action, file, onError, onProgress, onSuccess }) => {
                if (!isRcFile(file)) return; // should always be true
                dispatchAssetsInFlightCount("increment");

                try {
                  const res = await axios.put(action, file, {
                    headers: {
                      "Content-Type": typeof file === "string" ? "text/plain" : file.type,
                    },
                    onUploadProgress: progressEvent => {
                      onProgress?.({
                        ...progressEvent,
                        percent: (progressEvent.loaded / progressEvent.total) * 100,
                      });
                    },
                  });

                  setAssetFileUrl(file.uid, action.split("?")[0]!);
                  onSuccess?.(res.data, res.request);
                } catch (e) {
                  if (axios.isAxiosError(e))
                    onError?.(
                      {
                        ...e,
                        method: "PUT",
                        status: e.response?.status,
                        url: action,
                      },
                      e.request.data
                    );
                  else onError?.(e as UploadRequestError);
                } finally {
                  dispatchAssetsInFlightCount("decrement");
                }
              }}
              fileList={assetsFileList}
              listType="picture-card"
              method="put"
              multiple
              onChange={info =>
                setAssetsFileList(
                  info.fileList.map(x => ({ ...x, url: x.url ?? assetsFileList.find(y => y.uid === x.uid)?.url }))
                )
              }
            >
              <div>
                <Icon icon="mdi:plus" height="1.5em" />
                <div>Upload</div>
              </div>
            </Upload>
          </Form.Item>
        }
      />
    </StyledAutomatedEmailInputs>
  );
};

const StyledAutomatedEmailInputs = styled.div`
  .cta-toggle {
    display: flex;
    align-items: center;
  }

  .cta-inputs {
    display: flex;
    gap: 16px;

    > * {
      flex: 1;
    }
  }
`;
