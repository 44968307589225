import { Empty } from "antd";
import classNames, { type Argument } from "classnames";
import { useTable } from "react-table";
import styled from "styled-components";

import { DIVIDER_COLOR, TABLE_HEADER_BG_COLOR } from "../../style";

const PortalTable = ({
  className,
  columns,
  data,
  blankText,
  hideHeader = false,
}: {
  className?: Argument;
  columns: any;
  data: any;
  blankText: string;
  hideHeader?: boolean;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <Styled className={classNames("table-wrap", className)}>
      {rows.length ? (
        <table {...getTableProps()}>
          {!hideHeader && (
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps({
                        className: "collapse",
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps({ className: "collapse" })}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Empty description={blankText} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Styled>
  );
};

const Styled = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  @media only screen and (max-width: 600px) {
    overflow-x: scroll;
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    th {
      background-color: ${TABLE_HEADER_BG_COLOR};
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      border-bottom: 1px solid ${DIVIDER_COLOR};
      text-align: left;
    }

    th,
    td {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px;
      width: 1%;
      &.collapse {
        width: 0.0000001%;
      }

      :first-child {
        padding-left: 15px;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`;

export default PortalTable;
