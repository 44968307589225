/**
 * @generated SignedSource<<dd293674b52dc00612a8bccdda840a4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelistModal_viewer$data = {
  readonly panelist: {
    readonly dId: any | null;
    readonly id: string;
    readonly joined: string | null;
    readonly panelistMembership: {
      readonly latestRatedParticipant: {
        readonly rating: number;
      } | null;
    } | null;
    readonly person: {
      readonly avatarUrl: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly rewardsBalance: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"GivePointsToPanelistModal_panelist" | "Note_panelist" | "PanelistCharacteristics_panelist">;
  } | null;
  readonly " $fragmentType": "PanelistModal_viewer";
};
export type PanelistModal_viewer$key = {
  readonly " $data"?: PanelistModal_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistModal_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "panelistId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelistModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "panelistId",
          "variableName": "panelistId"
        }
      ],
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelistMembershipNode",
          "kind": "LinkedField",
          "name": "panelistMembership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ParticipantNode",
              "kind": "LinkedField",
              "name": "latestRatedParticipant",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rating",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardsBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonNode",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatarUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "joined",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelistCharacteristics_panelist"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Note_panelist"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GivePointsToPanelistModal_panelist"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "77ff804a7ab9d7688e110c90f49ef343";

export default node;
