/**
 * @generated SignedSource<<37b0e69231ddbf3abeef9c83a42f62ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRespondentApprovalStatusChoices = "A" | "D" | "NR" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type ScreenersRespondentStatusChoices = "OQ" | "Q" | "T" | "%future added value";
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AvailableStudies_user$data = {
  readonly dId: any | null;
  readonly email: string;
  readonly id: string;
  readonly panelist: {
    readonly availableRecruits: ReadonlyArray<{
      readonly incentive: string | null;
      readonly incentivePoints: number | null;
      readonly screenerLink: string;
      readonly study: {
        readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
        readonly namePublic: string | null;
        readonly screener: {
          readonly respondents: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly approvalStatus: ScreenersRespondentApprovalStatusChoices | null;
                readonly completedOn: any | null;
                readonly dId: any | null;
                readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
                readonly scheduledSlots: ReadonlyArray<{
                  readonly date: any;
                  readonly start: any;
                  readonly studyAvailabilitySlot: {
                    readonly dId: any | null;
                    readonly meetingLink: string | null;
                    readonly start: any;
                  } | null;
                }>;
                readonly startedOn: any | null;
                readonly status: ScreenersRespondentStatusChoices | null;
              } | null;
            } | null>;
          } | null;
          readonly title: string | null;
        } | null;
        readonly screenerLink: string | null;
        readonly tenant: {
          readonly id: string;
        };
        readonly type: ScreenersStudyTypeChoices;
      };
    }>;
    readonly customPanelistPortal: {
      readonly hidePointsAndRedemption: boolean;
      readonly tenant: {
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly dId: any | null;
  } | null;
  readonly " $fragmentType": "AvailableStudies_user";
};
export type AvailableStudies_user$key = {
  readonly " $data"?: AvailableStudies_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvailableStudies_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "screenerLink",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AvailableStudies_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customPanelistPortal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePointsAndRedemption",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitNode",
          "kind": "LinkedField",
          "name": "availableRecruits",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "incentive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "incentivePoints",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyNode",
              "kind": "LinkedField",
              "name": "study",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "namePublic",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incentiveType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ScreenerNode",
                  "kind": "LinkedField",
                  "name": "screener",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RespondentNodeConnection",
                      "kind": "LinkedField",
                      "name": "respondents",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RespondentNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "RespondentNode",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "approvalStatus",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "status",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "masterStatus",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "startedOn",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "completedOn",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "RespondentScheduledSlotNode",
                                  "kind": "LinkedField",
                                  "name": "scheduledSlots",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "date",
                                      "storageKey": null
                                    },
                                    (v3/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "StudyAvailabilitySlotNode",
                                      "kind": "LinkedField",
                                      "name": "studyAvailabilitySlot",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "meetingLink",
                                          "storageKey": null
                                        },
                                        (v3/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "kind": "LinkedField",
                  "name": "tenant",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "b45d03ca55b187d100fa02f83fb9f1f0";

export default node;
