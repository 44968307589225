import { Icon } from "@iconify/react";
import { Tag as AntdTag, type TagProps } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { SetRequired } from "type-fest";
import { TAG_COLORS_ANTD } from "../constants";
import type { TagTag_tag$key } from "../__generated__/TagTag_tag.graphql";

export type AntdTagColor =
  | "magenta"
  | "red"
  | "volcano"
  | "orange"
  | "gold"
  | "lime"
  | "green"
  | "cyan"
  | "blue"
  | "geekblue"
  | "purple";

export const Tag = ({
  className,
  closable = false,
  color,
  label,
  onClose,
  onMouseDown,
  ...props
}: SetRequired<Partial<Omit<TagProps, "className"> & CustomTagProps>, "label"> & {
  className?: Argument;
  color?: AntdTagColor;
}) => {
  return (
    <StyledTag
      className={classNames("hub-tag", className)}
      color={color}
      closable={closable}
      closeIcon={<Icon height="1.2em" icon="mdi:close" />}
      onClose={onClose}
      onMouseDown={onMouseDown}
      {...props}
    >
      <span className="hub-tag-label">{label}</span>
    </StyledTag>
  );
};

const StyledTag = styled(AntdTag)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  text-transform: uppercase;

  .ant-tag-close-icon {
    color: inherit;
    display: inline-flex;
    margin: 0;
  }
`;

export const TagTag = ({ tagKey, ...props }: Partial<Parameters<typeof Tag>[0]> & { tagKey: TagTag_tag$key }) => {
  const { color, name } = useFragment(
    graphql`
      fragment TagTag_tag on TagNode {
        color
        name
      }
    `,
    tagKey
  );

  return <Tag color={TAG_COLORS_ANTD[color]} label={name} {...props} />;
};
