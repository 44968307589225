/**
 * @generated SignedSource<<ddce29f229ef159d67b69a483b766e2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Cells_ApproveRejectedCell_respondent$data = {
  readonly id: string;
  readonly " $fragmentType": "Cells_ApproveRejectedCell_respondent";
};
export type Cells_ApproveRejectedCell_respondent$key = {
  readonly " $data"?: Cells_ApproveRejectedCell_respondent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Cells_ApproveRejectedCell_respondent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Cells_ApproveRejectedCell_respondent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "RespondentNode",
  "abstractKey": null
};

(node as any).hash = "0a1ecc7369fcb5fdf8159993a025ab16";

export default node;
