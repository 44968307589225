/**
 * @generated SignedSource<<4eb80fec647ef59e46908c895b442387>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyEventsTypeChoices = "SESSIONS" | "SINGLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InputSlotDetails_slot$data = {
  readonly _value: string | null;
  readonly id: string;
  readonly session: {
    readonly study: {
      readonly eventsType: ScreenersStudyEventsTypeChoices;
      readonly id: string;
      readonly sessions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly availabilitySlots: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly duration: number | null;
                  readonly end: any;
                  readonly id: string;
                  readonly inPerson: boolean;
                  readonly interviewer: {
                    readonly email: string;
                  } | null;
                  readonly meetingDetails: string | null;
                  readonly meetingLink: string | null;
                  readonly meetingLocation: string | null;
                  readonly placesLimit: number | null;
                  readonly start: any;
                } | null;
              } | null>;
            };
            readonly duration: number | null;
            readonly id: string;
            readonly name: string;
          } | null;
        } | null>;
      };
    };
  };
  readonly " $fragmentType": "InputSlotDetails_slot";
};
export type InputSlotDetails_slot$key = {
  readonly " $data"?: InputSlotDetails_slot$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputSlotDetails_slot">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputSlotDetails_slot",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "_value",
      "args": null,
      "kind": "ScalarField",
      "name": "meetingDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudySessionNode",
      "kind": "LinkedField",
      "name": "session",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyNode",
          "kind": "LinkedField",
          "name": "study",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventsType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StudySessionNodeConnection",
              "kind": "LinkedField",
              "name": "sessions",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StudySessionNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudySessionNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StudyAvailabilitySlotNodeConnection",
                          "kind": "LinkedField",
                          "name": "availabilitySlots",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "StudyAvailabilitySlotNodeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "StudyAvailabilitySlotNode",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "start",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "end",
                                      "storageKey": null
                                    },
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "InterviewerNode",
                                      "kind": "LinkedField",
                                      "name": "interviewer",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "email",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "inPerson",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "meetingDetails",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "meetingLink",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "meetingLocation",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "placesLimit",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyAvailabilitySlotNode",
  "abstractKey": null
};
})();

(node as any).hash = "396b60b0944134fba16efc3f8a873d2b";

export default node;
