import { HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Button, Card, Spin, theme, Tooltip } from "antd";
import { GlobalToken } from "antd/es/theme/interface";
import { graphql } from "babel-plugin-relay/macro";
import React, { useRef, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { PROJECT_STATUSES, SAVING_STATES } from "../../constants";
import { GRAY_10, GRAY_7 } from "../../style";
import { mutation, useConfirm } from "../../utils";
import type { ElementBase_AddElement_Mutation } from "../../__generated__/ElementBase_AddElement_Mutation.graphql";
import type { ElementBase_DeleteElement_Mutation } from "../../__generated__/ElementBase_DeleteElement_Mutation.graphql";
import type { ElementBase_DuplicateElement_Mutation } from "../../__generated__/ElementBase_DuplicateElement_Mutation.graphql";
import type { ElementBase_element$data } from "../../__generated__/ElementBase_element.graphql";
import type { ElementBase_screener$data } from "../../__generated__/ElementBase_screener.graphql";

import ActiveElementContent from "./ActiveElementContent";
import { CommonElementProps } from "./Element";
import InactiveElementContent from "./InactiveElementContent";

const { useToken } = theme;

type Props = {
  screener: ElementBase_screener$data;
  element: ElementBase_element$data;
} & CommonElementProps;
const ElementBase: React.FC<Props> = ({
  screener,
  element,
  dragHandleProps,
  isActive,
  setActive,
  showLabel,
  setSavingState,
  savingState,
}) => {
  const addElement = async ({ position }: { position: number }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ElementBase_AddElement_Mutation>({
        variables: {
          input: {
            screenerId: element.screener.id || "",
            position: position,
          },
        },
        mutation: graphql`
          mutation ElementBase_AddElement_Mutation($input: AddElementInput!) {
            addElement(input: $input) {
              screener {
                id
                elements {
                  edges {
                    node {
                      ...ElementBase_element
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  const deleteElement = async () => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ElementBase_DeleteElement_Mutation>({
        variables: {
          input: {
            elementId: element.id || "",
          },
        },
        mutation: graphql`
          mutation ElementBase_DeleteElement_Mutation($input: DeleteElementInput!) {
            deleteElement(input: $input) {
              screener {
                id
                elements {
                  edges {
                    node {
                      ...ActiveElementContent_element
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const duplicateElement = async () => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ElementBase_DuplicateElement_Mutation>({
        variables: {
          input: {
            elementId: element.id || "",
          },
        },
        mutation: graphql`
          mutation ElementBase_DuplicateElement_Mutation($input: DuplicateElementInput!) {
            duplicateElement(input: $input) {
              screener {
                id
                elements {
                  edges {
                    node {
                      ...ElementBase_element
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const confirm = useConfirm();

  const elementActions = (
    <>
      {!element.characteristic && (
        <Tooltip title="Duplicate question" mouseEnterDelay={1}>
          <Button
            id={`duplicate-element-${element.id}`}
            onClick={e => {
              e.stopPropagation();
              if (savingState === SAVING_STATES.SAVED) {
                duplicateElement()
                  .then(() => setActive(1))
                  .finally();
              }
            }}
            style={{ aspectRatio: "1", display: "grid", placeContent: "center" }}
            type="text"
          >
            <Icon icon="mdi:content-copy" height="1.2em" />
          </Button>
        </Tooltip>
      )}
      <Tooltip title="Delete question" mouseEnterDelay={1}>
        <Button
          id={`delete-element-${element.id}`}
          onClick={async e => {
            e.stopPropagation();

            if (screener.study.status === PROJECT_STATUSES.DRAFT) {
              setLoading(true);
              deleteElement().finally();
            } else if (
              await confirm({
                title: `Delete Question`,
                content: "Are you sure? This will also irrevocably delete any existing RESPONSES to this question.",
                okText: "Delete",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                maskClosable: true,
              })
            ) {
              setLoading(true);
              deleteElement().finally(() => setLoading(false));
            }
          }}
          style={{ aspectRatio: "1", display: "grid", placeContent: "center" }}
          type="text"
        >
          <Icon icon="mdi:delete-outline" height="1.2em" />
        </Button>
      </Tooltip>
    </>
  );

  const activeElementRef = useRef<HTMLDivElement>(null);

  const { token } = useToken();

  return (
    <React.Suspense fallback={<Spin spinning={loading} />}>
      <StyledElementBase $token={token} bordered {...dragHandleProps} className={!isActive ? "inactive" : undefined}>
        <HolderOutlined rotate={90} className="question-drag-icon" />
        <div ref={activeElementRef} style={{ display: isActive ? "block" : "none" }}>
          <ActiveElementContent
            screener={screener}
            element={element}
            setLoading={setLoading}
            elementActions={elementActions}
            showLabel={showLabel}
            setSavingState={setSavingState}
          />
        </div>
        {!isActive && (
          <InactiveElementContent
            screener={screener}
            element={element}
            setActive={() => setActive()}
            elementActions={elementActions}
          />
        )}
      </StyledElementBase>
      <div className="add-new-element-wrapper">
        <Tooltip title="Add new question">
          <Button
            shape="circle"
            onClick={() => {
              addElement({ position: element.position + 1 }).then(() => setActive(1));
            }}
            icon={<PlusOutlined />}
            className="screener-add-element"
          />
        </Tooltip>
      </div>
    </React.Suspense>
  );
};

const StyledElementBase = styled(Card)<{ $token: GlobalToken }>`
  position: relative;

  &.inactive {
    opacity: 0.5;
    transition: opacity 150ms;

    &:hover,
    &:active {
      opacity: 1;
    }
  }

  & > .ant-card-body {
    padding: 8px ${props => props.$token.paddingMD}px;
  }

  .question-drag-icon {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: ${GRAY_10};
  }

  .question {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;

    input,
    textarea {
      height: 36px;
      font-size: 15px;
    }

    .markdown-info {
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 12px;
      color: ${GRAY_7};

      a {
        color: var(--ant-primary-color);
      }
    }
  }

  .metadata {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .ant-select {
      flex: 1;
    }
  }

  .label-options {
    cursor: default;
    margin-top: 10px;

    .label-input-row {
      display: flex;
      align-items: center;
      gap: 10px;

      input {
        width: 100px;
      }
    }
  }

  .action {
    cursor: pointer;
  }

  .screener-element-copy {
    margin-right: 0.5rem;
  }

  .answers {
    margin: 12px 0;

    .heading {
      .text {
        height: 24px;
        font-weight: 500;
      }

      .setting-label {
        display: inline-block;
        height: 24px;
      }

      input {
        margin-left: 4px;
        width: 34px;
      }
    }
  }

  .response-validation {
    display: flex;
    margin-top: 10px;
    gap: 12px;

    .hub-response-validation-form-items {
      flex: 1;
    }
  }

  & + .add-new-element-wrapper {
    text-align: center;
    margin: 15px 0 15px;
  }
`;

export default createFragmentContainer(ElementBase, {
  screener: graphql`
    fragment ElementBase_screener on ScreenerNode {
      study {
        status
      }
      ...ActiveElementContent_screener
      ...InactiveElementContent_screener
    }
  `,
  element: graphql`
    fragment ElementBase_element on ElementNode {
      id
      characteristic {
        id
      }
      screener {
        id
      }
      position
      ...ActiveElementContent_element
      ...InactiveElementContent_element
    }
  `,
});
