import { App } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { useCallback, useEffect } from "react";
import { LoadingPage } from ".";
import { setJwt, useMutation } from "../utils";
import type {
  SsoCallbackPageMutation,
  SsoCallbackPageMutation$data,
} from "../__generated__/SsoCallbackPageMutation.graphql";

export const SsoCallbackPage = () => {
  const { message } = App.useApp();
  const { router } = useRouter();

  const [commitObtainSsoToken] = useMutation<SsoCallbackPageMutation>(graphql`
    mutation SsoCallbackPageMutation($input: ObtainSSOTokenInput!) {
      obtainSsoToken(input: $input) {
        tokenSet {
          accessToken
          refreshToken
        }
      }
    }
  `);

  const getTokens = useCallback(
    async (env: string, ssoCode: string) => {
      return new Promise<SsoCallbackPageMutation$data["obtainSsoToken"]["tokenSet"]>((resolve, reject) => {
        commitObtainSsoToken(
          { env, code: ssoCode, callbackUrl: `${window.location.origin}/sso-callback` },
          {
            onCompleted: ({ obtainSsoToken: response }: SsoCallbackPageMutation$data, errors) => {
              if (errors) {
                return reject(new Error(errors[0]?.message));
              }
              resolve(response.tokenSet);
            },
            onError: reject,
          }
        );
      });
    },
    [commitObtainSsoToken]
  );

  useEffect(() => {
    const handleError = (err?: any) => {
      if (err) console.error(err);
      message.error("Error signing in; please try again");
      router.push("/login");
    };

    const url = new URL(window.location.href);
    // "otp" is used by the Voxpopme callback page, "code" is used by other providers
    const code = url.searchParams.get("otp") ?? url.searchParams.get("code");
    const state = JSON.parse(url.searchParams.get("state")!);

    if (!code) {
      handleError();
      return;
    }

    getTokens(state.env, code)
      .then(tokens => {
        setJwt(tokens.accessToken, tokens.refreshToken);
        if (state.logoutUrl) {
          localStorage.setItem("logout_url", state.logoutUrl);
        }
        router.push("/login");
      })
      .catch(handleError);
  }, [getTokens, message, router]);

  return <LoadingPage />;
};
