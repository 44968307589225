/**
 * @generated SignedSource<<75ea848477df12102833d7b248a2e7a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PasswordResetPage_customPortal$data = {
  readonly contactEmail: string | null;
  readonly customColor: string | null;
  readonly faviconUrl: string | null;
  readonly footerImageUrl: string | null;
  readonly logoImageUrl: string | null;
  readonly pageTitle: string | null;
  readonly " $fragmentType": "PasswordResetPage_customPortal";
};
export type PasswordResetPage_customPortal$key = {
  readonly " $data"?: PasswordResetPage_customPortal$data;
  readonly " $fragmentSpreads": FragmentRefs<"PasswordResetPage_customPortal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PasswordResetPage_customPortal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "footerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "faviconUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactEmail",
      "storageKey": null
    }
  ],
  "type": "CustomPortalNode",
  "abstractKey": null
};

(node as any).hash = "00947ed58cc1c91bfa3240c8a3b29d79";

export default node;
