import type { NotificationInstance } from "antd/es/notification/interface";

import { PROJECT_TYPES, SCHEDULED_BY } from "../../constants";
import { pluralize } from "../../utils/misc";

export function notifyApproved(count: number, study: any, notification: NotificationInstance) {
  notification.success({
    message: `${pluralize(count, `${count.toLocaleString()} participants approved`, "Participant approved")}`,
    description: getDescription(study),
  });
}

function getDescription(study: any): string | null {
  if (study.type === PROJECT_TYPES.SURVEY) {
    return study.usesApiToMarkComplete
      ? // survey with third-party link
        "We'll send a link to the survey"
      : // survey without third-party link
        'Now you can rate them in the "Survey Completed" tab';
  }

  // TODO: support "YOUR_TEAM" scheduled participants in Interview projects
  return study.type === PROJECT_TYPES.INTERVIEW
    ? // self-scheduling interview participant
      "We'll send the link to schedule an interview"
    : study.scheduledBy === SCHEDULED_BY.PARTICIPANT
    ? // self-scheduling focus group participant
      "We'll send the link to choose a focus group"
    : // manually scheduled focus group participant
      "You can schedule participants on the Approved tab";
}
