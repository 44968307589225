/**
 * @generated SignedSource<<be0458754f46af3776b8f9f14f781b79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CharacteristicTable_panel$data = {
  readonly health: {
    readonly totalParticipants: ReadonlyArray<{
      readonly count: string | null;
      readonly month: number | null;
      readonly year: number | null;
    } | null> | null;
  } | null;
  readonly tenantCharacteristics: ReadonlyArray<{
    readonly key: string | null;
    readonly title: string | null;
  } | null> | null;
  readonly totalPanelists: number | null;
  readonly " $fragmentType": "CharacteristicTable_panel";
};
export type CharacteristicTable_panel$key = {
  readonly " $data"?: CharacteristicTable_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"CharacteristicTable_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CharacteristicTable_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPanelists",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantPanelCharacteristic",
      "kind": "LinkedField",
      "name": "tenantCharacteristics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelHealth",
      "kind": "LinkedField",
      "name": "health",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelHealthHistory",
          "kind": "LinkedField",
          "name": "totalParticipants",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "month",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "year",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "effa92c57d6a62d501371236cc2f8151";

export default node;
