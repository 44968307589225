/**
 * @generated SignedSource<<19dd1aaea91d76e7ff86d3ea0a64caaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePanelistInput = {
  clientMutationId?: string | null;
  data: any;
  panelistId: string;
};
export type PanelistModalMutation$variables = {
  input: UpdatePanelistInput;
};
export type PanelistModalMutation$data = {
  readonly updatePanelist: {
    readonly panelist: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"PanelistCharacteristics_panelist">;
    };
  };
};
export type PanelistModalMutation = {
  response: PanelistModalMutation$data;
  variables: PanelistModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PanelistModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePanelistPayload",
        "kind": "LinkedField",
        "name": "updatePanelist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelistNode",
            "kind": "LinkedField",
            "name": "panelist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PanelistCharacteristics_panelist"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PanelistModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePanelistPayload",
        "kind": "LinkedField",
        "name": "updatePanelist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelistNode",
            "kind": "LinkedField",
            "name": "panelist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonNode",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParticipantNode",
                "kind": "LinkedField",
                "name": "studyParticipants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyNode",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagNode",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RespondentNode",
                    "kind": "LinkedField",
                    "name": "respondent",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "masterStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startedOn",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SelectedResponse",
                "kind": "LinkedField",
                "name": "selectedResponses",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SelectedResponseAnswer",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "row",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "selected",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "other",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userSpecified",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customAnswer",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc092962ddbb43a140347eaa77289612",
    "id": null,
    "metadata": {},
    "name": "PanelistModalMutation",
    "operationKind": "mutation",
    "text": "mutation PanelistModalMutation(\n  $input: UpdatePanelistInput!\n) {\n  updatePanelist(input: $input) {\n    panelist {\n      id\n      ...PanelistCharacteristics_panelist\n    }\n  }\n}\n\nfragment PanelistCharacteristics_panelist on PanelistNode {\n  person {\n    email\n    phoneNumber\n    firstName\n    lastName\n    id\n  }\n  id\n  studyParticipants {\n    study {\n      name\n      tags {\n        ...TagTag_tag\n        id\n      }\n      id\n    }\n    respondent {\n      masterStatus\n      startedOn\n      id\n    }\n    id\n  }\n  selectedResponses {\n    key\n    text\n    type\n    answers {\n      key\n      text\n      row\n      rowText\n      selected\n      other\n      userSpecified\n      customAnswer\n    }\n  }\n}\n\nfragment TagTag_tag on TagNode {\n  color\n  name\n}\n"
  }
};
})();

(node as any).hash = "faa3a10381380373b669e1719a84f50e";

export default node;
