import { Icon } from "@iconify/react";
import moment from "moment";
import styled from "styled-components";
import { RESPONDENT_ICONS_MAP, RESPONDENT_MASTER_STATUSES, RESPONDENT_MASTER_STATUSES_MAP } from "../../constants";
import { SECONDARY_TEXT_COLOR } from "../../style";
import { TagTag } from "../Tag";

const PastParticipationCell = ({
  studies,
}: {
  studies:
    | {
        name: string;
        startedOn: string;
        status: string;
        tagKeys: Parameters<typeof TagTag>[0]["tagKey"][];
      }[]
    | null;
}) => {
  return (
    <StyledTextItem>
      {studies?.map((study, i) => {
        return (
          <li className="panelist-participation-status" key={`pastPCell-${i}`}>
            <div className="panelist-participation-status-title">
              <span className="panelist-participation-status-name">{study.name}</span>{" "}
              {study.tagKeys?.map(x => (
                <TagTag tagKey={x} />
              ))}
            </div>
            <div className="panelist-participation-status-info">
              {!!study.startedOn && <span>Started {moment(study.startedOn).format("YYYY-MM-DD, LT")}</span>}
              <span>
                <Icon
                  width="20"
                  color={SECONDARY_TEXT_COLOR}
                  icon={RESPONDENT_ICONS_MAP[study.status as RESPONDENT_MASTER_STATUSES]}
                />
                {RESPONDENT_MASTER_STATUSES_MAP[study.status as RESPONDENT_MASTER_STATUSES]}
              </span>
            </div>
          </li>
        );
      })}
    </StyledTextItem>
  );
};

const StyledTextItem = styled.ul`
  list-style-type: none;
  padding: 8px 0;
  margin: 0;

  .panelist-participation-status:not(:last-child) {
    margin-bottom: 16px;
  }

  .panelist-participation-status-title {
    font-weight: 500;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .panelist-participation-status-name {
    font-size: 16px;
    margin-right: 8px;
  }

  .panelist-participation-status-info {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    color: ${SECONDARY_TEXT_COLOR};

    span {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
`;

export default PastParticipationCell;
