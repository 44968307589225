import { graphql } from "babel-plugin-relay/macro";
import { mutation } from "../../../utils";
import { mutations_deleteCharacteristic_Mutation } from "../../../__generated__/mutations_deleteCharacteristic_Mutation.graphql";
import { mutations_saveCharacteristic_Mutation } from "../../../__generated__/mutations_saveCharacteristic_Mutation.graphql";

export const saveCharacteristic = async (input: {
  characteristicId?: string;
  type?: string;
  text?: string | null;
  shortName?: string;
  importKey?: string;
  answers?: any[];
  elementMeta?: any;
  rows?: any[];
  showToPanelists?: boolean;
}) =>
  mutation<mutations_saveCharacteristic_Mutation>({
    variables: { input },
    mutation: graphql`
      mutation mutations_saveCharacteristic_Mutation($input: SaveCharacteristicInput!) {
        saveCharacteristic(input: $input) {
          tenant {
            characteristics {
              edges {
                node {
                  id
                  type
                  shortName
                  importKey
                  text
                  elementMeta {
                    randomize
                    responseValidation
                    target
                    target2
                    targetTypes
                  }
                  answers {
                    id
                    text
                    other
                    userSpecified
                    position
                  }
                  rows {
                    id
                    text
                  }
                  showToPanelists
                }
              }
            }
          }
        }
      }
    `,
    silenceDefaultError: true,
  });

export const deleteCharacteristic = async (input: { characteristicId: string }) =>
  mutation<mutations_deleteCharacteristic_Mutation>({
    variables: { input },
    mutation: graphql`
      mutation mutations_deleteCharacteristic_Mutation($input: DeleteCharacteristicInput!) {
        deleteCharacteristic(input: $input) {
          tenant {
            characteristics {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `,
  });
