import type { NotificationInstance } from "antd/es/notification/interface";
import type { Router } from "found";
import { type ReactNode } from "react";
import styled from "styled-components";

export const notifyExport = (notification: NotificationInstance, exportType?: string) =>
  notification.success({
    message: "Data exported successfully",
    description: `You can share the .csv file wherever you want`,
  });

// export const notifyJsonExport = (notification: NotificationInstance) =>
//   notification.success({
//     message: "Data exported successfully",
//     description: "You can share the .json wherever you want",
//   });

export const NotificationLink = ({ router, to, children }: { router: Router; to: string; children: ReactNode }) => (
  <Span role="link" onClick={() => router.push(to)}>
    {children}
  </Span>
);

const Span = styled.span`
  color: var(--ant-primary-color);
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
