import { CheckboxButton } from "../CheckboxButton";

import type { FilterBase } from "./types";

export const ButtonNot = <T extends FilterBase>({
  filter,
  setFilter,
  size,
}: {
  filter: T;
  setFilter: (x: T) => void;
  size?: Parameters<typeof CheckboxButton>[0]["size"];
}) => (
  <CheckboxButton
    buttonStyle={{ borderRadius: size === "small" ? 4 : 6 }}
    checked={filter.negate}
    onChange={e => setFilter({ ...filter, negate: e.target.checked })}
    size={size}
  >
    NOT
  </CheckboxButton>
);
