/**
 * @generated SignedSource<<432b5737fe88b70510c69a9069cf72d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortalCard_user$data = {
  readonly dId: any | null;
  readonly email: string;
  readonly id: string;
  readonly panelist: {
    readonly customPanelistPortal: {
      readonly tenant: {
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly dId: any | null;
  } | null;
  readonly " $fragmentType": "PortalCard_user";
};
export type PortalCard_user$key = {
  readonly " $data"?: PortalCard_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalCard_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalCard_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customPanelistPortal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "f0dbfd9c50e4e5de1f4215e9315a7262";

export default node;
