import { ScreenersRecruitTypeChoices } from "../schema";
import { FUTURE_PROOF } from "./util";

export const recruitTypeMap = {
  [ScreenersRecruitTypeChoices.Wl]: "Survey Link",
  [ScreenersRecruitTypeChoices.Hp]: "HubUX Panel",
  [ScreenersRecruitTypeChoices.Em]: "List Import",
  [ScreenersRecruitTypeChoices.Yp]: "Community",
  [ScreenersRecruitTypeChoices.Ro]: "Voxpopme Global",
  [FUTURE_PROOF]: FUTURE_PROOF,
} as const;

export const RECRUIT_STATUSES = {
  STARTED: "S",
  NOT_STARTED: "NS",
  PAUSED: "P",
  FINISHED: "F",
};

export const recruitStatusMap = {
  [RECRUIT_STATUSES.STARTED]: "Recruiting",
  [RECRUIT_STATUSES.NOT_STARTED]: "Draft",
  [RECRUIT_STATUSES.PAUSED]: "Paused",
  [RECRUIT_STATUSES.FINISHED]: "Finished",
};

// This is used for calculating minimum incentive for studies
export const USD_PER_POINT = 0.05;

export const PANELIST_BONUS_POINTS_FEE_PERCENT = 0.15;

// Minimum incentive and incentive fees need to be updated in both the front and back-end
export const INCENTIVE_FEE_PERCENT = {
  [ScreenersRecruitTypeChoices.Hp]: 0.5,
  [ScreenersRecruitTypeChoices.Yp]: 0.15,
  [ScreenersRecruitTypeChoices.Wl]: 0.15,
  [ScreenersRecruitTypeChoices.Em]: 0.15,
  [ScreenersRecruitTypeChoices.Ro]: 0.15,
  [FUTURE_PROOF]: 0,
} as const;

// Minimum Cash incentive
export const MIN_INCENTIVE_USD = {
  [ScreenersRecruitTypeChoices.Hp]: 5,
  [ScreenersRecruitTypeChoices.Yp]: 5,
  [ScreenersRecruitTypeChoices.Wl]: 5,
  [ScreenersRecruitTypeChoices.Em]: 5,
  [ScreenersRecruitTypeChoices.Ro]: 5,
  [FUTURE_PROOF]: 0,
} as const;

// Minimum Points incentive
export const MIN_INCENTIVE_POINTS = {
  [ScreenersRecruitTypeChoices.Hp]: 0,
  [ScreenersRecruitTypeChoices.Yp]: 0,
  [ScreenersRecruitTypeChoices.Wl]: 0,
  [ScreenersRecruitTypeChoices.Em]: 0,
  [ScreenersRecruitTypeChoices.Ro]: 0,

  [FUTURE_PROOF]: 0,
} as const;

export const MIN_INCENTIVE_FEE_USD = {
  [ScreenersRecruitTypeChoices.Hp]: 5,
  [ScreenersRecruitTypeChoices.Yp]: 0,
  [ScreenersRecruitTypeChoices.Wl]: 0,
  [ScreenersRecruitTypeChoices.Em]: 0,
  [ScreenersRecruitTypeChoices.Ro]: 0,
  [FUTURE_PROOF]: 0,
} as const;
