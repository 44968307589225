import { App, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { GRAY_7 } from "../../style";
import { BulkScheduleModal_study$key } from "../../__generated__/BulkScheduleModal_study.graphql";
import { SlotPicker } from "./SlotPicker";

export const BulkScheduleModal = ({
  study: _study,
  selectedCount,
  showModal,
  setShowModal,
  handleSchedule,
}: {
  study: BulkScheduleModal_study$key;
  selectedCount: number;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  handleSchedule: (slotId: string) => Promise<void>;
}) => {
  const study = useFragment(
    graphql`
      fragment BulkScheduleModal_study on StudyNode {
        id
        availabilitySlots {
          edges {
            node {
              id
              moderatorEmail
              start
              end
              availablePlaces
            }
          }
        }
        ...SlotPicker_study
      }
    `,
    _study
  );

  const { message } = App.useApp();

  const [isWorking, setIsWorking] = useState(false);
  const [selectedSession, setSelectedSession] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedSlotId, setSelectedSlotId] = useState<string>("");

  useEffect(() => {
    if (showModal) {
      setSelectedSession("");
      setSelectedDate("");
      setSelectedSlotId("");
    }
  }, [showModal]);

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedDate("");
    setSelectedSlotId("");
  };

  const handleScheduleSlot = async () => {
    if (
      selectedCount >
      (study.availabilitySlots.edges.find(e => e?.node?.id === selectedSlotId)!.node?.availablePlaces ?? Infinity)
    ) {
      message.warning("Too many participants are selected for this event");
      return;
    }

    try {
      setIsWorking(true);
      await handleSchedule(selectedSlotId);
      setShowModal(false);
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <StyledModal
      width={600}
      open={showModal}
      okText="Assign"
      onOk={handleScheduleSlot}
      onCancel={handleModalCancel}
      cancelButtonProps={{ disabled: isWorking }}
      okButtonProps={{ loading: isWorking, disabled: !selectedDate || !selectedSlotId }}
    >
      <SlotPicker
        {...{
          study,
          selectedCount,
          selectedSession,
          setSelectedSession,
          selectedDate,
          setSelectedDate,
          selectedSlotId,
          setSelectedSlotId,
        }}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .label {
    font-weight: 500;
    margin-bottom: 4px;
  }

  .instructions {
    text-align: center;
    color: ${GRAY_7};
    margin: 20px 0 0 0;
  }

  .slots-placeholder {
    display: flex;
    height: 240px;
    justify-content: center;
    align-items: center;
  }

  .date-button {
    margin: 0 2px 4px 2px;
  }
`;
