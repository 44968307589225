/**
 * @generated SignedSource<<bdaf94b1bb9d9a6c50a85c9f88253005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRecruitIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitTitle_recruit$data = {
  readonly dbId: string;
  readonly description: string | null;
  readonly id: string;
  readonly incentive: string | null;
  readonly incentivePoints: number | null;
  readonly incentiveType: ScreenersRecruitIncentiveTypeChoices | null;
  readonly name: string | null;
  readonly recruitTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly status: ScreenersRecruitStatusChoices;
  readonly type: ScreenersRecruitTypeChoices | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditInvitationEmailModal_recruit" | "RecruitNameModal_recruit">;
  readonly " $fragmentType": "RecruitTitle_recruit";
};
export type RecruitTitle_recruit$key = {
  readonly " $data"?: RecruitTitle_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitTitle_recruit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitTitle_recruit",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "recruitTransactions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecruitTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecruitNameModal_recruit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditInvitationEmailModal_recruit"
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};
})();

(node as any).hash = "c3886b9474c37f0e51c23ab82462d0f7";

export default node;
