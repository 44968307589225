/**
 * @generated SignedSource<<de3d7786aa6337a0c783566852937cd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_BillingPage_Query$variables = {};
export type RouteConfig_BillingPage_Query$data = {
  readonly viewer: {
    readonly user: {
      readonly profile: {
        readonly tenant: {
          readonly hideBillingPage: boolean;
        };
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"BillingPage_user">;
    } | null;
  };
};
export type RouteConfig_BillingPage_Query = {
  response: RouteConfig_BillingPage_Query$data;
  variables: RouteConfig_BillingPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideBillingPage",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_BillingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BillingPage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfig_BillingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripePaymentMethodId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vpmAccountId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vpmUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d686cdd4f512d0dc0a21bb6d29e1ef7",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_BillingPage_Query",
    "operationKind": "query",
    "text": "query RouteConfig_BillingPage_Query {\n  viewer {\n    user {\n      profile {\n        tenant {\n          hideBillingPage\n          id\n        }\n        id\n      }\n      ...BillingPage_user\n      id\n    }\n  }\n}\n\nfragment BillingPage_user on UserNode {\n  dId\n  profile {\n    tenant {\n      name\n      dId\n      stripePaymentMethodId\n      vpmAccountId\n      id\n    }\n    id\n  }\n  vpmUserId\n  panelist {\n    dId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec0da2e26315c0104efc1c0155fba51d";

export default node;
