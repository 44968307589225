/**
 * @generated SignedSource<<4f359a68e6a71fc7e5acecf319d93ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Authenticated_LDUser_user$data = {
  readonly dId: any | null;
  readonly email: string;
  readonly fullName: string | null;
  readonly isPanelist: boolean;
  readonly " $fragmentType": "Authenticated_LDUser_user";
};
export type Authenticated_LDUser_user$key = {
  readonly " $data"?: Authenticated_LDUser_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Authenticated_LDUser_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Authenticated_LDUser_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPanelist",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "237bbba59aa4fe17d3240ca681b5c75d";

export default node;
