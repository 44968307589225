/**
 * @generated SignedSource<<e2156d53082b5ad2325853b954df937f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseDate_characteristic$data = {
  readonly id: string;
  readonly " $fragmentType": "InputCharacteristicResponseDate_characteristic";
};
export type InputCharacteristicResponseDate_characteristic$key = {
  readonly " $data"?: InputCharacteristicResponseDate_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseDate_characteristic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputCharacteristicResponseDate_characteristic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};

(node as any).hash = "89d5bfb02cd2af1fd0ea4b1e0bbb9640";

export default node;
