/**
 * @generated SignedSource<<89433c82543e2f4a3f18c0ea3fd49e14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersPersonCountryChoices = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortalRedeem_panelist$data = {
  readonly convertedRewardsBalance: number | null;
  readonly person: {
    readonly country: ScreenersPersonCountryChoices;
  } | null;
  readonly redeemedRewardsPoints: number | null;
  readonly rewardsBalance: number | null;
  readonly tenantForIncentives: {
    readonly blockedCurrencies: any | null;
    readonly rewardCards: ReadonlyArray<{
      readonly faceplateUrl: string | null;
      readonly maxAmount: number | null;
      readonly minAmount: number | null;
      readonly name: string | null;
      readonly programId: string | null;
      readonly sku: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RewardCard_panelist">;
  readonly " $fragmentType": "PortalRedeem_panelist";
};
export type PortalRedeem_panelist$key = {
  readonly " $data"?: PortalRedeem_panelist$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalRedeem_panelist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalRedeem_panelist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rewardsBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "convertedRewardsBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "redeemedRewardsPoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenantForIncentives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockedCurrencies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RewardCardObject",
          "kind": "LinkedField",
          "name": "rewardCards",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sku",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "faceplateUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonNode",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RewardCard_panelist"
    }
  ],
  "type": "PanelistNode",
  "abstractKey": null
};

(node as any).hash = "b3ff6e14acae0905aaad406c2bda8033";

export default node;
