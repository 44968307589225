import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { environment } from "../../relay";
import { PanelistsReportWrapperQuery } from "../../__generated__/PanelistsReportWrapperQuery.graphql";
import { LoadingPage } from "../index";
import PanelistsReport from "./PanelistsReport";

const PanelistsReportWrapper = () => {
  return (
    <QueryRenderer<PanelistsReportWrapperQuery>
      variables={{}}
      environment={environment}
      query={graphql`
        query PanelistsReportWrapperQuery {
          viewer {
            ...PanelistsReport_viewer
            user {
              profile {
                tenant {
                  characteristics {
                    edges {
                      node {
                        id
                        importKey
                        text
                        type
                        answers {
                          id
                          text
                        }
                      }
                    }
                  }
                  ...PanelistsReport_tenant
                }
              }
            }
          }
        }
      `}
      render={({ error, props }) => {
        if (error) {
          console.error(error);
          return;
        }

        if (props) {
          return (
            props?.viewer && (
              <>
                {props.viewer.user?.profile?.tenant && (
                  <PanelistsReport viewer={props.viewer} tenant={props.viewer.user?.profile?.tenant} />
                )}
              </>
            )
          );
        }
        return <LoadingPage positionTopThird />;
      }}
    />
  );
};

export default PanelistsReportWrapper;
