/**
 * @generated SignedSource<<dec2a9a2b56992989fbb3e7c5d5a1c12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Cells_ReviewCell_study$data = {
  readonly dId: any | null;
  readonly id: string;
  readonly name: string | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly dId: any | null;
    readonly name: string;
    readonly vpmAccountId: number | null;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentType": "Cells_ReviewCell_study";
};
export type Cells_ReviewCell_study$key = {
  readonly " $data"?: Cells_ReviewCell_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"Cells_ReviewCell_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Cells_ReviewCell_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmAccountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "2f471a5f46c1b8cd99cbdbdcaf25a553";

export default node;
