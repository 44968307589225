/**
 * @generated SignedSource<<33b912e7021481aeaac0dddeacfadb22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteRowInput = {
  clientMutationId?: string | null;
  rowId: string;
};
export type GridQuestionEditor_deleteRow_Mutation$variables = {
  input: DeleteRowInput;
};
export type GridQuestionEditor_deleteRow_Mutation$data = {
  readonly deleteRow: {
    readonly element: {
      readonly id: string;
      readonly rows: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly dbId: string;
            readonly id: string;
            readonly position: number;
            readonly text: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type GridQuestionEditor_deleteRow_Mutation = {
  response: GridQuestionEditor_deleteRow_Mutation$data;
  variables: GridQuestionEditor_deleteRow_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteRowPayload",
    "kind": "LinkedField",
    "name": "deleteRow",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ElementNode",
        "kind": "LinkedField",
        "name": "element",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RowNodeConnection",
            "kind": "LinkedField",
            "name": "rows",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RowNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RowNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dbId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GridQuestionEditor_deleteRow_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GridQuestionEditor_deleteRow_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2a4e52d45977117d1b0b4a12f78e5af8",
    "id": null,
    "metadata": {},
    "name": "GridQuestionEditor_deleteRow_Mutation",
    "operationKind": "mutation",
    "text": "mutation GridQuestionEditor_deleteRow_Mutation(\n  $input: DeleteRowInput!\n) {\n  deleteRow(input: $input) {\n    element {\n      id\n      rows {\n        edges {\n          node {\n            id\n            dbId\n            text\n            position\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81a6316d38c88f14fe234d49dfc6e977";

export default node;
