/**
 * @generated SignedSource<<b020d27c4d32d7c438d9adce3423897e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScheduleAllFocusGroupParticipantsInput = {
  clientMutationId?: string | null;
  countToSelect?: number | null;
  excludeIds?: ReadonlyArray<string | null> | null;
  filterComplex?: any | null;
  slotId?: string | null;
  studyId: string;
  tab?: string | null;
};
export type ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation$variables = {
  input: ScheduleAllFocusGroupParticipantsInput;
};
export type ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation$data = {
  readonly scheduleAllFocusGroupParticipants: {
    readonly study: {
      readonly completeCount: number | null;
      readonly goalPaidForCount: number | null;
      readonly id: string;
      readonly needsPaymentCount: number | null;
      readonly recruits: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly fee: any | null;
            readonly goalPaidForCount: number | null;
            readonly incentive: string | null;
            readonly name: string | null;
            readonly rewardedCount: number | null;
            readonly status: ScreenersRecruitStatusChoices;
            readonly totalDeposits: any | null;
            readonly totalPayouts: any | null;
            readonly totalRefunds: any | null;
            readonly totalRefundsDue: any | null;
          } | null;
        } | null>;
      };
      readonly rewardedCount: number | null;
      readonly totalDeposits: any | null;
      readonly totalFees: any | null;
      readonly totalIncentives: any | null;
      readonly totalPayouts: any | null;
      readonly totalPerParticipantCosts: any | null;
      readonly totalRefunds: any | null;
      readonly totalRefundsDue: any | null;
    } | null;
  } | null;
};
export type ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation = {
  response: ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation$data;
  variables: ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsPaymentCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completeCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rewardedCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goalPaidForCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalIncentives",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFees",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPerParticipantCosts",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDeposits",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPayouts",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRefunds",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRefundsDue",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "incentive",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleAllFocusGroupParticipantsPayload",
        "kind": "LinkedField",
        "name": "scheduleAllFocusGroupParticipants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleAllFocusGroupParticipantsPayload",
        "kind": "LinkedField",
        "name": "scheduleAllFocusGroupParticipants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v17/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "403c750721bafe20ce9ad7eecd6540a9",
    "id": null,
    "metadata": {},
    "name": "ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation",
    "operationKind": "mutation",
    "text": "mutation ParticipantsBatchMutations_ScheduleAllFocusGroupParticipants_Mutation(\n  $input: ScheduleAllFocusGroupParticipantsInput!\n) {\n  scheduleAllFocusGroupParticipants(input: $input) {\n    study {\n      id\n      needsPaymentCount\n      completeCount\n      rewardedCount\n      goalPaidForCount\n      totalIncentives\n      totalFees\n      totalPerParticipantCosts\n      totalDeposits\n      totalPayouts\n      totalRefunds\n      totalRefundsDue\n      recruits {\n        edges {\n          node {\n            name\n            rewardedCount\n            goalPaidForCount\n            incentive\n            fee\n            totalDeposits\n            totalPayouts\n            totalRefunds\n            totalRefundsDue\n            status\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee7585f81ee6dc95267b9cff4a00e592";

export default node;
