/**
 * @generated SignedSource<<5cbd3418f1aa726809e72a5617e0652c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PanelistsReport_viewer$data = {
  readonly user: {
    readonly isStaff: boolean;
    readonly profile: {
      readonly tenant: {
        readonly characteristics: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly answers: ReadonlyArray<{
                readonly id: string;
                readonly text: string;
              }>;
              readonly id: string;
              readonly importKey: string;
              readonly rows: ReadonlyArray<{
                readonly id: string;
                readonly text: string;
              }>;
              readonly shortName: string;
              readonly text: string | null;
              readonly type: ScreenersCharacteristicTypeChoices;
            } | null;
          } | null>;
        };
        readonly enablePanelExport: boolean;
        readonly globalCharacteristics: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly answers: ReadonlyArray<{
                readonly id: string;
                readonly text: string;
              }>;
              readonly id: string;
              readonly importKey: string;
              readonly rows: ReadonlyArray<{
                readonly id: string;
                readonly text: string;
              }>;
              readonly shortName: string;
              readonly text: string | null;
              readonly type: ScreenersCharacteristicTypeChoices;
            } | null;
          } | null>;
        } | null;
        readonly panelists: {
          readonly edges: ReadonlyArray<{
            readonly cursor: string;
            readonly node: {
              readonly completedStudiesCount: number | null;
              readonly dId: any | null;
              readonly id: string;
              readonly joined: string | null;
              readonly panelistMembership: {
                readonly latestCompleteScreenerDate: string | null;
                readonly latestCompleteScreenerName: string | null;
                readonly latestRatedParticipant: {
                  readonly rating: number;
                } | null;
                readonly latestStudyDate: string | null;
                readonly latestStudyName: string | null;
              } | null;
              readonly person: {
                readonly email: string | null;
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly panelist: {
                  readonly id: string;
                } | null;
                readonly phoneNumber: string | null;
              } | null;
              readonly responses: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly answer: {
                      readonly id: string;
                      readonly other: boolean;
                      readonly userSpecified: boolean;
                    } | null;
                    readonly characteristic: {
                      readonly id: string;
                      readonly importKey: string;
                      readonly text: string | null;
                    };
                    readonly customAnswer: string | null;
                    readonly row: {
                      readonly id: string;
                      readonly text: string;
                    } | null;
                    readonly textValue: string;
                  } | null;
                } | null>;
              };
              readonly rewardsBalance: number | null;
            } | null;
          } | null>;
          readonly totalCount: number | null;
        } | null;
      };
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistModal_viewer">;
  readonly " $fragmentType": "PanelistsReport_viewer";
};
export type PanelistsReport_viewer$key = {
  readonly " $data"?: PanelistsReport_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistsReport_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importKey",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CharacteristicNode",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CharacteristicAnswerNode",
        "kind": "LinkedField",
        "name": "answers",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CharacteristicRowNode",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "panelFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "user",
          "profile",
          "tenant",
          "panelists"
        ]
      }
    ]
  },
  "name": "PanelistsReport_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PanelistModal_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStaff",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enablePanelExport",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CharacteristicNodeConnection",
                  "kind": "LinkedField",
                  "name": "characteristics",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CharacteristicNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TenantGlobalCharacteristicsConnection",
                  "kind": "LinkedField",
                  "name": "globalCharacteristics",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TenantGlobalCharacteristicsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": "panelists",
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "panelFilters",
                      "variableName": "panelFilters"
                    },
                    {
                      "kind": "Variable",
                      "name": "searchTerm",
                      "variableName": "searchTerm"
                    }
                  ],
                  "concreteType": "PanelistConnection",
                  "kind": "LinkedField",
                  "name": "__PanelistsReport_panelists_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PanelistEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PanelistNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "dId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "completedStudiesCount",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PersonNode",
                              "kind": "LinkedField",
                              "name": "person",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "firstName",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "lastName",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "email",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "phoneNumber",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PanelistNode",
                                  "kind": "LinkedField",
                                  "name": "panelist",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PanelistMembershipNode",
                              "kind": "LinkedField",
                              "name": "panelistMembership",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "latestCompleteScreenerName",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "latestCompleteScreenerDate",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "latestStudyName",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "latestStudyDate",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ParticipantNode",
                                  "kind": "LinkedField",
                                  "name": "latestRatedParticipant",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "rating",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rewardsBalance",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "joined",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CharacteristicResponseNodeConnection",
                              "kind": "LinkedField",
                              "name": "responses",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CharacteristicResponseNodeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "CharacteristicResponseNode",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "CharacteristicNode",
                                          "kind": "LinkedField",
                                          "name": "characteristic",
                                          "plural": false,
                                          "selections": [
                                            (v1/*: any*/),
                                            (v0/*: any*/),
                                            (v2/*: any*/)
                                          ],
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "CharacteristicAnswerNode",
                                          "kind": "LinkedField",
                                          "name": "answer",
                                          "plural": false,
                                          "selections": [
                                            (v0/*: any*/),
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "other",
                                              "storageKey": null
                                            },
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "userSpecified",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "CharacteristicRowNode",
                                          "kind": "LinkedField",
                                          "name": "row",
                                          "plural": false,
                                          "selections": (v3/*: any*/),
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "textValue",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "customAnswer",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "b76eae796c0a78edc096b662e0daeb17";

export default node;
