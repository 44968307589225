/**
 * @generated SignedSource<<8a29280568eb3a254441a1607b640f48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyScheduledByChoices = "P" | "T" | "%future added value";
export type ScreenersStudySchedulingTypeChoices = "CL" | "HX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScheduleCell_study$data = {
  readonly id: string;
  readonly scheduledBy: ScreenersStudyScheduledByChoices;
  readonly schedulingType: ScreenersStudySchedulingTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleModal_study">;
  readonly " $fragmentType": "ScheduleCell_study";
};
export type ScheduleCell_study$key = {
  readonly " $data"?: ScheduleCell_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleCell_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleCell_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedulingType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduleModal_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "b52e94e52d2dc231bdd4615134b45ec5";

export default node;
