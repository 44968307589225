import { Button } from "antd";
import { sortBy } from "lodash-es";
import styled from "styled-components";

import { ButtonLabel } from "..";
import { GRAY_7 } from "../../style";
import { useConfirm } from "../../utils";

import type { Filter } from "./types";

export const FiltersLoadOptGroup = ({
  filters,
  label,
  onDelete,
  onSelect,
}: {
  filters: Filter[];
  label: string;
  onDelete: (x: Filter["id"]) => void;
  onSelect: (x: Filter["id"]) => void;
}) => {
  const confirmDeleteSavedFilter = useConfirm({
    title: "Delete saved filter?",
    okText: "Delete",
  });

  return filters.length ? (
    <StyledFiltersLoadOptGroupRoot className="hub-filters-load-opt-group">
      <h6>{label}</h6>
      <ul>
        {sortBy(filters, "name").map(x => (
          <li key={x.id}>
            <Button onClick={() => onSelect(x.id)} type="text">
              {x.name}
            </Button>
            <ButtonLabel
              icon="mdi:delete-outline"
              onClick={async e => {
                e.stopPropagation();
                if (
                  await confirmDeleteSavedFilter({
                    content: `Are you sure you want to delete "${x.name}"?`,
                  })
                )
                  onDelete(x.id);
              }}
              type="text"
            />
          </li>
        ))}
      </ul>
    </StyledFiltersLoadOptGroupRoot>
  ) : null;
};

const StyledFiltersLoadOptGroupRoot = styled.div`
  /* &:only-child h6 {
    display: none;
  } */

  h6 {
    color: ${GRAY_7};
    font-size: 12px;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  li {
    display: flex;

    > *:first-child {
      align-items: center;
      flex: 1;
      text-align: left;
    }

    svg {
      display: block;
    }
  }
`;
