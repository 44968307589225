import { Icon } from "@iconify/react";
import { useLocalStorageValue } from "@react-hookz/web";
import { Checkbox, Drawer, Input, Tabs } from "antd";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import type { SetNonNullable } from "type-fest";

import { ButtonLabel, DrawerHeader, DRAWER_HEADER_PARENT_CLASS_NAME } from "..";
import { PAGE_WIDTH_2XS } from "../../style";
import { useConfirm } from "../../utils";

import { FiltersLoadOptGroup } from "./FiltersLoadOptGroup";
import type { Filter } from "./types";

const enum TabKey {
  STUDY = "study",
  GLOBAL = "global",
}

export const FiltersLoad = ({
  filterId,
  filters,
  onDelete,
  onSelect: _onSelect,
}: {
  filterId?: Filter["id"];
  filters: Filter[];
  onDelete: (x: Filter["id"]) => void;
  onSelect: (x: Filter["id"]) => void;
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tabKey, setTabKey] = useState(TabKey.STUDY);
  const [search, setSearch] = useState<string>();

  type FilterGlobal = Filter & { study: null };
  type FilterStudy = SetNonNullable<Filter, "study">;
  type FilterMe<T extends FilterGlobal | FilterStudy> = SetNonNullable<T, "user">;
  type FilterTeam<T extends FilterGlobal | FilterStudy> = T & { user: null };

  const { filtersGlobalMe, filtersGlobalTeam, filtersStudyMe, filtersStudyTeam } = useMemo(
    () =>
      filters.reduce(
        (acc, cur) => {
          const k: keyof typeof acc = `filters${!!cur.study ? "Study" : "Global"}${!!cur.user ? "Me" : "Team"}`;

          return (!search || cur.name.toLowerCase().includes(search.toLowerCase())) &&
            // we need to check for duplicates--otherwise, the array is doubled for some reason
            !(acc[k] as Filter[]).some(x => x.id === cur.id)
            ? { ...acc, [k]: [...acc[k], cur] }
            : acc;
        },
        {
          filtersGlobalMe: [] as FilterMe<FilterGlobal>[],
          filtersGlobalTeam: [] as FilterTeam<FilterGlobal>[],
          filtersStudyMe: [] as FilterMe<FilterStudy>[],
          filtersStudyTeam: [] as FilterTeam<FilterStudy>[],
        }
      ),
    [filters, search]
  );

  const hasFiltersGlobal = useMemo(
    () => !!(filtersGlobalMe.length || filtersGlobalTeam.length),
    [filtersGlobalMe.length, filtersGlobalTeam.length]
  );
  const hasFiltersStudy = useMemo(
    () => !!(filtersStudyMe.length || filtersStudyTeam.length),
    [filtersStudyMe.length, filtersStudyTeam.length]
  );

  useEffect(() => {
    if (!hasFiltersGlobal) setTabKey(TabKey.STUDY);
    else if (!hasFiltersStudy) setTabKey(TabKey.GLOBAL);
  }, [hasFiltersGlobal, hasFiltersStudy]);

  const [skipWarnOnSelect, setSkipWarnOnSelect] = useLocalStorageValue<boolean>(
    // "participant" in this key is legacy
    "hub-participant-filters-load-skip-warn-on-select"
  );
  const confirmOverwrite = useConfirm({
    title: "Do you want to overwrite your current filters?",
    cancelText: "Keep filters",
    content: (
      <Checkbox
        onChange={e => {
          setSkipWarnOnSelect(e.target.checked);
        }}
      >
        Don't show again
      </Checkbox>
    ),
    okText: "Overwrite filters",
  });
  const onSelect = async (x: Parameters<typeof _onSelect>[0]) => {
    if (!skipWarnOnSelect && !!filterId && filterId !== x && (await confirmOverwrite())) setDrawerVisible(false);
    _onSelect(x);
  };

  return filters.length ? (
    <>
      <ButtonLabel
        icon="mdi:folder-arrow-down-outline"
        onClick={() => setDrawerVisible(true)}
        text="Load saved filters"
      />
      <StyledDrawer
        className={DRAWER_HEADER_PARENT_CLASS_NAME}
        closable={false}
        maskClosable={true}
        placement="right"
        title={<DrawerHeader onClose={() => setDrawerVisible(false)} title={<span>Filters saved</span>} />}
        open={drawerVisible}
      >
        <Input
          addonAfter={<Icon height="1.2em" icon="mdi:magnify" style={{ display: "block" }} />}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search"
          value={search}
        />
        {!!search ? (
          <div>
            <FiltersLoadOptGroup
              filters={[...filtersStudyTeam, ...filtersGlobalTeam]}
              label="My team"
              onDelete={onDelete}
              onSelect={onSelect}
            />
            <FiltersLoadOptGroup
              filters={[...filtersStudyMe, ...filtersGlobalMe]}
              label="Personal"
              onDelete={onDelete}
              onSelect={onSelect}
            />
          </div>
        ) : (
          <Tabs
            activeKey={tabKey}
            tabBarStyle={{
              display: hasFiltersGlobal !== hasFiltersStudy ? "none" : undefined,
            }}
            onChange={x => setTabKey(x as TabKey)}
          >
            <Tabs.TabPane key={TabKey.STUDY} tab="This project">
              <FiltersLoadOptGroup
                filters={[...filtersStudyTeam]}
                label="My team"
                onDelete={onDelete}
                onSelect={onSelect}
              />
              <FiltersLoadOptGroup
                filters={[...filtersStudyMe]}
                label="Personal"
                onDelete={onDelete}
                onSelect={onSelect}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key={TabKey.GLOBAL} tab="All projects">
              <FiltersLoadOptGroup
                filters={[...filtersGlobalTeam]}
                label="My team"
                onDelete={onDelete}
                onSelect={onSelect}
              />
              <FiltersLoadOptGroup
                filters={[...filtersGlobalMe]}
                label="Personal"
                onDelete={onDelete}
                onSelect={onSelect}
              />
            </Tabs.TabPane>
          </Tabs>
        )}
      </StyledDrawer>
    </>
  ) : null;
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    margin-bottom: 0 !important;
  }
  &.ant-drawer-open .ant-drawer-content-wrapper {
    min-width: ${PAGE_WIDTH_2XS} !important;
  }

  .ant-drawer-body > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
