/**
 * @generated SignedSource<<d4e6608f2b0478f389d5773870720b6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ScreenersFilterTypeChoices = "PN" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PanelFilters_viewer$data = {
  readonly user: {
    readonly id: string;
    readonly profile: {
      readonly tenant: {
        readonly filters: ReadonlyArray<{
          readonly filters: any;
          readonly id: string;
          readonly name: string;
          readonly study: {
            readonly id: string;
          } | null;
          readonly type: ScreenersFilterTypeChoices;
          readonly user: {
            readonly id: string;
          } | null;
        }>;
        readonly id: string;
        readonly name: string;
        readonly panel: {
          readonly export: {
            readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
          } | null;
          readonly id: string;
        } | null;
      };
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DrawerFilters_viewer">;
  readonly " $fragmentType": "PanelFilters_viewer";
};
export type PanelFilters_viewer$key = {
  readonly " $data"?: PanelFilters_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelFilters_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./PanelFiltersViewerRefetchQuery.graphql')
    }
  },
  "name": "PanelFilters_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FilterNode",
                  "kind": "LinkedField",
                  "name": "filters",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyNode",
                      "kind": "LinkedField",
                      "name": "study",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "filters",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PanelNode",
                  "kind": "LinkedField",
                  "name": "panel",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ExportNode",
                      "kind": "LinkedField",
                      "name": "export",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ExportButton_export"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DrawerFilters_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a358c67e3e38d8b5545c3fd5a5519c68";

export default node;
