import { InputNumber, Select } from "antd";

import { ButtonNot } from "./ButtonNot";
import { FilterOp, type PanelistFilter, type ParticipantFilter } from "./types";

export const InputFilterNumber = <T extends ParticipantFilter | PanelistFilter>({
  filter,
  setFilter,
}: {
  filter: T;
  setFilter: (x: T) => void;
}) => (
  <div className="form-filter-row">
    <ButtonNot filter={filter} setFilter={setFilter} />
    <Select
      className="select-operator"
      defaultValue={FilterOp.Is}
      value={
        filter.op === FilterOp.Is
          ? "="
          : filter.op === FilterOp.GreaterThan
          ? ">="
          : filter.op === FilterOp.LessThan
          ? "<="
          : undefined
      }
      onChange={op => setFilter({ ...filter, op })}
    >
      <Select.Option key="num_is" value={FilterOp.Is}>
        =
      </Select.Option>
      <Select.Option key="num_greater_than" value={FilterOp.GreaterThan}>
        &gt;
      </Select.Option>
      <Select.Option key="num_less_than" value={FilterOp.LessThan}>
        &lt;
      </Select.Option>
    </Select>
    <InputNumber
      type="number"
      className="input-values"
      placeholder="Number"
      value={"values" in filter ? filter.values?.[0] : undefined}
      onChange={(value: any) => setFilter({ ...filter, values: [value] })}
    />
  </div>
);
