import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgThemed, InputTagsSelect } from "..";
import { PROJECT_STATUSES } from "../../constants";
import { BORDER_RADIUS, PAGE_WIDTH_LG, PROJECTS_COLUMN_HEADER_HEIGHT } from "../../style";
import { InputTagsSelect_tenant$key } from "../../__generated__/InputTagsSelect_tenant.graphql";
import ProjectsColumnQuery from "./ProjectsColumnQuery";

const { Option } = Select;
const { Search } = Input;

const Projects = ({ tenantKey }: { tenantKey: InputTagsSelect_tenant$key }) => {
  const [orderBy, setOrderBy] = useState("-modified");
  const [searchTerm, setSearchTerm] = useState("");

  const [draftCount, setDraftCount] = useState<number | undefined>();
  const [activeCount, setActiveCount] = useState<number | undefined>();
  const [completedCount, setCompletedCount] = useState<number | undefined>();

  useEffect(() => {
    setDraftCount(undefined);
    setActiveCount(undefined);
    setCompletedCount(undefined);
  }, [searchTerm]);

  const [filterTagDids, setFilterTagIds] = useState<string[]>([]);

  return (
    <StyledProjectsContainer className="hub-layout-fill">
      <div className="hub-projects-actions">
        <Search
          className="hub-projects-search"
          placeholder="Search"
          onSearch={value => setSearchTerm(value)}
          allowClear
        />
        <Select
          defaultValue="Sort by: Date Modified"
          className="hub-projects-sort"
          onChange={value => setOrderBy(value)}
        >
          <Option value="name">Name</Option>
          <Option value="-modified">Date Modified</Option>
          <Option value="-created">Date Created</Option>
          <Option value="creator">Creator</Option>
        </Select>
      </div>
      <InputTagsSelect onChange={setFilterTagIds} tenantKey={tenantKey} value={filterTagDids} />
      {[draftCount, activeCount, completedCount].every(c => c === 0) ? (
        <div className="no-results">
          <ImgThemed alt="Magnifying glass" className="header-image" src="magnifying-glass.svg" />
          <h1>No results</h1>
          <p>
            There are no results for your search <strong>"{searchTerm}"</strong>. Please try again with another keyword.
          </p>
        </div>
      ) : (
        <div className="hub-projects-columns">
          <section className="hub-projects-column">
            <header>
              <h2>Draft</h2>
              <span> ({draftCount ?? 0})</span>
            </header>
            <ProjectsColumnQuery
              filterTagDids={filterTagDids}
              orderBy={orderBy}
              projectStatus={PROJECT_STATUSES.DRAFT}
              searchTerm={searchTerm}
              setCount={setDraftCount}
            />
          </section>
          <section className="hub-projects-column">
            <header>
              <h2>Active</h2>
              <span> ({activeCount ?? 0})</span>
            </header>
            <ProjectsColumnQuery
              filterTagDids={filterTagDids}
              orderBy={orderBy}
              projectStatus={PROJECT_STATUSES.LIVE}
              searchTerm={searchTerm}
              setCount={setActiveCount}
            />
          </section>
          <section className="hub-projects-column">
            <header>
              <h2>Completed</h2>
              <span> ({completedCount ?? 0})</span>
            </header>
            <ProjectsColumnQuery
              filterTagDids={filterTagDids}
              orderBy={orderBy}
              projectStatus={PROJECT_STATUSES.COMPLETE}
              searchTerm={searchTerm}
              setCount={setCompletedCount}
            />
          </section>
        </div>
      )}
    </StyledProjectsContainer>
  );
};

const StyledProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .no-results {
    text-align: center;
    margin: 200px 50px 0 0;

    .header-image {
      width: 85px;
      margin-bottom: 20px;
    }
  }

  .hub-projects-actions {
    margin-bottom: 24px;

    .hub-projects-search {
      width: 300px;
      margin-right: 1rem;
    }

    .hub-projects-sort {
      width: 190px;
    }
  }

  .hub-input-tags-select {
    margin-bottom: 2rem;
  }

  .hub-projects-columns {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: ${PAGE_WIDTH_LG};

    .hub-projects-column {
      border-radius: ${BORDER_RADIUS};
      margin-right: 24px;

      header {
        margin-bottom: 16px;
      }

      h2 {
        // ProjectsColumnQuery's InfiniteScroll depends on an exact height, if height/padding/margin are changed, need to
        // test InfiniteScroll is unaffected
        height: ${PROJECTS_COLUMN_HEADER_HEIGHT}px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        display: inline;
      }
    }
  }
`;

export default Projects;
