/**
 * @generated SignedSource<<fb923e1a1c55af6efcd5c1fff20dc540>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Cells_PaymentCell_study$data = {
  readonly defaultIncentive: string | null;
  readonly defaultIncentivePoints: number | null;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly customportal: {
      readonly hidePointsAndRedemption: boolean;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmExternalIncentiveModal_study" | "ParticipantPaymentModal_study">;
  readonly " $fragmentType": "Cells_PaymentCell_study";
};
export type Cells_PaymentCell_study$key = {
  readonly " $data"?: Cells_PaymentCell_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"Cells_PaymentCell_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Cells_PaymentCell_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customportal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePointsAndRedemption",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmExternalIncentiveModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantPaymentModal_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "334623e1b882e47136b291b4500afba0";

export default node;
