import { CloseOutlined, HolderOutlined } from "@ant-design/icons";
import { Checkbox, Input, InputRef, Radio, Select } from "antd";
import { Ref } from "react";
import styled from "styled-components";
import { CHARACTERISTIC_ELEMENT_TYPES } from "../../../constants";
import type { CharacteristicAnswer } from "../../../types";

export const EditCharacteristicSelectAnswer = ({
  characteristicType,
  answer,
  onUpdate,
  onDelete,
  onPressEnter,
  onPaste,
  focusRef,
}: {
  characteristicType: CHARACTERISTIC_ELEMENT_TYPES;
  answer: CharacteristicAnswer;
  onUpdate: (answer: CharacteristicAnswer) => void;
  onDelete: () => void;
  onPressEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  focusRef?: Ref<InputRef>;
}) => {
  const OTHERS_OPTIONS = [
    { value: "Other", exclusive: false, userSpecifiable: true },
    { value: "None of the above", exclusive: true, userSpecifiable: true },
    // exclude "All of the above" for single-select questions
    ...(characteristicType !== CHARACTERISTIC_ELEMENT_TYPES.SINGLE_SELECT
      ? ([{ value: "All of the above", exclusive: true, userSpecifiable: false }] as const)
      : ([] as const)),
  ] as const;

  return (
    <StyledAnswer>
      <HolderOutlined className="grabbable" />
      {characteristicType === CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT ? <Checkbox disabled /> : <Radio disabled />}
      {answer.other ? (
        <Select
          value={answer.text || ""}
          onChange={value => {
            const x = OTHERS_OPTIONS.find(x => x.value === value);

            if (x)
              onUpdate({
                ...answer,
                text: value,
                exclusive: x.exclusive,
                ...(!x.userSpecifiable ? { userSpecified: false } : {}),
              });
          }}
        >
          {OTHERS_OPTIONS.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.value}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Input
          defaultValue={answer.text}
          maxLength={512}
          onBlur={e => onUpdate({ ...answer, text: e.target.value })}
          onPressEnter={onPressEnter}
          onPaste={onPaste}
          ref={focusRef}
        />
      )}
      {answer.other && (
        <label className="user-specified">
          <Checkbox
            checked={answer.userSpecified}
            onChange={e => onUpdate({ ...answer, userSpecified: e.target.checked })}
          />{" "}
          Ask user to specify
        </label>
      )}
      <CloseOutlined className="clickable" onClick={onDelete} />
    </StyledAnswer>
  );
};

const StyledAnswer = styled.div`
  display: grid;
  /* textbox column should fill available width */
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  gap: 10px;

  .user-specified {
    grid-column: 3;
    grid-row: 2;
  }
`;
