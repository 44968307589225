/**
 * @generated SignedSource<<76f669676981b78c627403f3c9f1c0a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormPerson_person$data = {
  readonly email: string | null;
  readonly firstName: string | null;
  readonly id: string;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly requirePhoneNumber: boolean;
  readonly " $fragmentType": "FormPerson_person";
};
export type FormPerson_person$key = {
  readonly " $data"?: FormPerson_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormPerson_person">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormPerson_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requirePhoneNumber",
      "storageKey": null
    }
  ],
  "type": "PersonNode",
  "abstractKey": null
};

(node as any).hash = "65694d5b899673ca38a4ef515cefc22f";

export default node;
