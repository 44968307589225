/**
 * @generated SignedSource<<ce37211c3b00a73a51f71082ba7bc0b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ParticipantColumns_viewer$data = {
  readonly study: {
    readonly screener: {
      readonly elements: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly dbId: string;
            readonly label: string | null;
            readonly position: number;
            readonly text: string | null;
            readonly type: ScreenersElementTypeChoices;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
  readonly user: {
    readonly profile: {
      readonly tenant: {
        readonly name: string;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "ParticipantColumns_viewer";
};
export type ParticipantColumns_viewer$key = {
  readonly " $data"?: ParticipantColumns_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantColumns_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "studyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ParticipantColumns_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "studyId",
          "variableName": "studyId"
        }
      ],
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScreenerNode",
          "kind": "LinkedField",
          "name": "screener",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ElementNodeConnection",
              "kind": "LinkedField",
              "name": "elements",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ElementNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ElementNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dbId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "position",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c17392bfc3d70c66febe82552b0af4e7";

export default node;
