import { Alert, App, FormInstance } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link, useRouter } from "found";
import { Suspense, useCallback, useRef, useState } from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";

import { PROJECT_INCENTIVE_TYPES, PROJECT_STATUSES, RECRUIT_STATUSES } from "../../constants";
import { environment } from "../../relay";
import { ScreenersRecruitTypeChoices } from "../../schema";
import {
  getRecruitContext,
  getStudyContext,
  getTenantContext,
  getUserContext,
  mutation,
  trackEvent,
  useConfirm,
} from "../../utils";
import type { RecruitDetails_recruit$data } from "../../__generated__/RecruitDetails_recruit.graphql";
import type { RecruitDetails_study$data } from "../../__generated__/RecruitDetails_study.graphql";
import type { RecruitDetails_user$data } from "../../__generated__/RecruitDetails_user.graphql";
import type { UpdateRecruitDetailsInput } from "../../__generated__/RecruitForm_UpdateRecruitDetails_Mutation.graphql";
import LoadingPage from "../LoadingPage";
import { NotificationLink } from "../Notifications/Notification";
import { showLaunchModal } from "../Project/ProjectPage";

import RecruitForm from "./RecruitForm";
import RecruitInstructions from "./RecruitInstructions";
import RecruitKPIs from "./RecruitKPIs";
import RecruitPaymentModal from "./RecruitPaymentModal";
import RecruitTitle from "./RecruitTitle";
import RecruitTypePicker from "./RecruitTypePicker";
import RecruitYourPanel from "./RecruitYourPanel";

type Props = {
  recruit: RecruitDetails_recruit$data;
  study: RecruitDetails_study$data;
  user: RecruitDetails_user$data;
  defaultName: string;
  deleteRecruit: (recruitId: string) => void;
};
function RecruitDetails({ defaultName, recruit, study, user, deleteRecruit }: Props) {
  const confirm = useConfirm();
  const { notification } = App.useApp();
  const { router } = useRouter();

  const childFormRef = useRef<FormInstance>(null);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const isRecruitFormComplete = useCallback(() => {
    return !!(
      recruit.name &&
      recruit.goal &&
      ((study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH && recruit.incentive !== null) ||
        (study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS && recruit.incentivePoints !== null) ||
        study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL)
    );
  }, [recruit.name, recruit.goal, recruit.incentive, recruit.incentivePoints, study.incentiveType]);

  const isRecruitFunded = useCallback(() => {
    return recruit.recruitTransactions.edges.length > 0 || !study.tenant.requireStudyPayment;
  }, [recruit.recruitTransactions.edges, study.tenant.requireStudyPayment]);

  const startRecruitMutation = () => {
    const optimisticResponse = recruit.id
      ? {
          startRecruit: {
            recruit: {
              id: recruit.id,
              status: RECRUIT_STATUSES.STARTED,
              invitesSentCount: 0,
              recruitPersonCount: 0,
              completedScreenerCount: 0,
              qualifiedCount: 0,
              overNeedsReviewLimit: false,
              underGoal: true,
              incentiveType: study.incentiveType,
              incentivePoints: recruit.incentivePoints,
              incentive: recruit.incentive,
            },
          },
        }
      : undefined;

    return mutation({
      variables: { input: { recruitId: recruit.id } },
      mutation: graphql`
        mutation RecruitDetails_startRecruit_Mutation($input: StartRecruitInput!) {
          startRecruit(input: $input) {
            recruit {
              id
              status
              invitesSentCount
              recruitPersonCount
              completedScreenerCount
              qualifiedCount
              overNeedsReviewLimit
              underGoal
              incentiveType
              incentivePoints
              incentive
            }
          }
        }
      `,
      optimisticResponse,
      silenceDefaultError: true,
    }).then(() => {
      notification.success({
        message: "Recruiting round started successfully",
        description: (
          <>
            Qualifying participants will appear in the{" "}
            <NotificationLink router={router} to={`/projects/${study.id}/respondents/participants`}>
              Participants tab
            </NotificationLink>{" "}
            for your review
          </>
        ),
      });
      trackEvent("Recruiting Started", {
        ...getRecruitContext(recruit),
        ...getStudyContext(study),
        ...getTenantContext(study.tenant as any),
        ...getUserContext(user as any),
      });
    });
  };

  const startRecruit = async (paid: boolean, formValues?: UpdateRecruitDetailsInput) => {
    const onSuccess = async () => {
      const zeroIncentive = formValues
        ? study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH &&
          formValues.incentive &&
          parseFloat(formValues.incentive) === 0
        : false;
      const zeroIncentivePoints = formValues
        ? study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS && formValues.incentivePoints === 0
        : false;
      if (paid || isRecruitFunded() || zeroIncentive || zeroIncentivePoints) {
        await startRecruitMutation();
      } else {
        setShowPaymentModal(true);
      }
    };

    if (study.status === PROJECT_STATUSES.DRAFT) {
      if (
        await confirm({
          title: "Your project is still in draft.",
          content: "To proceed, we need to set the project to Active.",
          okText: "Set Project Active",
        })
      )
        showLaunchModal({
          confirm,
          study,
          onSuccess,
        });
    } else if (recruit.type === ScreenersRecruitTypeChoices.Wl || study.tenant.requireStudyPayment) {
      // user will get payment modal
      await onSuccess();
    } else if (
      await confirm({
        title: "Start recruiting round",
        content: "Are you sure you're ready to start this recruiting round?",
        okText: "Start Recruiting Round",
      })
    )
      onSuccess();
  };

  const loadingSpinner = (
    <div style={{ width: "100%", height: "30vh", margin: "auto" }}>
      <LoadingPage />
    </div>
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        {study.status === PROJECT_STATUSES.LIVE && recruit.status === RECRUIT_STATUSES.FINISHED && (
          <Alert
            type="info"
            message={
              <>You can't use recruiting rounds marked as finished. Create new ones to start recruiting again.</>
            }
            style={{ marginBottom: 16 }}
          />
        )}
        <RecruitTitle
          defaultName={defaultName}
          recruit={recruit}
          study={study}
          deleteRecruit={deleteRecruit}
          ref={childFormRef}
          isRecruitFormComplete={isRecruitFormComplete}
          isRecruitFunded={isRecruitFunded}
          setShowPaymentModal={setShowPaymentModal}
          startRecruitMutation={startRecruitMutation}
        />
        {/*For Survey Link and HubUX Panel we need to keep showing the form when it is complete
            in case the user exited out of the Payment flow and needs to restart it*/}
        {(!isRecruitFormComplete() ||
          ([ScreenersRecruitTypeChoices.Wl, ScreenersRecruitTypeChoices.Hp] as (typeof recruit)["type"][]).includes(
            recruit.type!
          )) &&
          recruit.status === RECRUIT_STATUSES.NOT_STARTED && (
            <>
              <RecruitTypePicker study={study} recruit={recruit} />
              <RecruitForm study={study} recruit={recruit} startRecruit={startRecruit} />
            </>
          )}
        <RecruitInstructions
          study={study}
          user={user}
          recruit={recruit}
          ref={childFormRef}
          isRecruitFormComplete={isRecruitFormComplete}
          isRecruitFunded={isRecruitFunded}
        />
        <RecruitKPIs study={study} recruit={recruit} />
        <RecruitPaymentModal
          recruit={recruit}
          visible={showPaymentModal}
          setVisible={setShowPaymentModal}
          startRecruit={startRecruit}
        />
      </div>
      {recruit.type !== ScreenersRecruitTypeChoices.Yp && isRecruitFormComplete() && (
        <div style={{ margin: "auto" }}>
          <p>
            Participants will appear in the{" "}
            <Link to={`/projects/${study.id}/respondents/participants`}>Participants tab</Link> once they pass the
            screener.
          </p>
        </div>
      )}
      {recruit.type === ScreenersRecruitTypeChoices.Yp && isRecruitFormComplete() && (
        <QueryRenderer
          environment={environment}
          query={graphql`
            query RecruitDetails_YourPanel_Query($studyId: String!) {
              viewer {
                study(studyId: $studyId) {
                  ...RecruitYourPanel_study
                }
                user {
                  profile {
                    tenant {
                      name
                    }
                  }
                }
              }
            }
          `}
          variables={{
            studyId: study.id,
            search: null,
          }}
          render={({ error, props }: any) => {
            if (error) {
              console.error("Error loading panelist table: ", error);
              return <div>Error</div>;
            } else if (props) {
              return (
                <Suspense fallback={loadingSpinner}>
                  <RecruitYourPanel study={props.viewer.study} recruit={recruit} />
                </Suspense>
              );
            }
            return loadingSpinner;
          }}
        />
      )}
    </>
  );
}

export default createFragmentContainer(RecruitDetails, {
  study: graphql`
    fragment RecruitDetails_study on StudyNode {
      id
      dId
      name
      needsReviewCount
      status
      screenerLink
      incentiveType
      defaultIncentive
      defaultIncentivePoints
      schedulingType
      schedulingLink
      type
      meetingLink
      availabilityBuffer
      availabilitySlots {
        edges {
          node {
            id
            duration
          }
        }
      }
      tenant {
        requireStudyPayment
        name
        dId
        vpmAccountId
        name
      }
      dId
      name
      type
      status

      ...RecruitInstructions_study
      ...RecruitKPIs_study
      ...RecruitTitle_study
      ...RecruitTypePicker_study
      ...RecruitForm_study
    }
  `,
  recruit: graphql`
    fragment RecruitDetails_recruit on RecruitNode {
      id
      type
      status
      name
      goal
      incentive
      incentivePoints
      recruitTransactions {
        edges {
          node {
            id
          }
        }
      }
      dbId
      type
      name

      ...RecruitInstructions_recruit
      ...RecruitKPIs_recruit
      ...RecruitNameModal_recruit
      ...RecruitTitle_recruit
      ...RecruitTypePicker_recruit
      ...RecruitYourPanel_recruit
      ...RecruitForm_recruit
      ...RecruitPaymentModal_recruit
    }
  `,
  user: graphql`
    fragment RecruitDetails_user on UserNode {
      dId
      vpmUserId
      panelist {
        dId
      }

      ...RecruitInstructions_user
    }
  `,
});
