import type { FilterGroup, FilterGroupGroup, PanelistFilter, ParticipantFilter } from "./types";

const isValidFilterGroup = <T extends PanelistFilter | ParticipantFilter>(
  x: Partial<FilterGroup<T>> | null | undefined
): x is FilterGroup<T> =>
  !!x?.op &&
  (x.filters?.every(
    x =>
      x != null &&
      !!x.id &&
      !!x.op &&
      !!x.type &&
      "values" in x !== "filters" in x &&
      ("values" in x
        ? !x.values.length || x.values.some(x => x != null)
        : !x.filters.length || x.filters.some(x => x != null && !!x.op && !!x.type && !!x.values))
  ) ??
    false);

export const isValidFilterGroupGroup = <T extends PanelistFilter | ParticipantFilter>(
  x: Partial<FilterGroupGroup<T>> | null | undefined
): x is FilterGroupGroup<T> => !!x?.op && (x.filters?.every(isValidFilterGroup) ?? false);
