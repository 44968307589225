import { Form, Input, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";

import { mutation } from "../../utils";
import { RecruitNameModal_recruit$data } from "../../__generated__/RecruitNameModal_recruit.graphql";
import { RecruitNameModal_updateRecruitDetails_Mutation } from "../../__generated__/RecruitNameModal_updateRecruitDetails_Mutation.graphql";

type Props = {
  visible: boolean;
  placeholderName: string;
  recruit: RecruitNameModal_recruit$data;
  onClose: () => void;
};

const RecruitNameModal: React.FC<Props> = ({ visible, placeholderName, recruit, onClose }) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    changeRoundName();
    onClose();
  };

  const changeRoundName = () => {
    return mutation<RecruitNameModal_updateRecruitDetails_Mutation>({
      variables: {
        input: {
          recruitId: recruit.id,
          ...form.getFieldsValue(),
        },
      },
      mutation: graphql`
        mutation RecruitNameModal_updateRecruitDetails_Mutation($input: UpdateRecruitDetailsInput!) {
          updateRecruitDetails(input: $input) {
            recruit {
              id
              name
              description
            }
          }
        }
      `,
    });
  };

  return (
    <Modal
      title="Rename Recruiting Round"
      onOk={onFinish}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      open={visible}
    >
      <Form
        form={form}
        initialValues={{
          name: recruit.name,
          description: recruit.description,
        }}
      >
        <Form.Item {...formItemLayout} label="Name" name="name">
          <Input autoFocus allowClear maxLength={45} placeholder={placeholderName} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Description" name="description">
          <Input.TextArea allowClear maxLength={300} rows={4} showCount />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default createFragmentContainer(RecruitNameModal, {
  recruit: graphql`
    fragment RecruitNameModal_recruit on RecruitNode {
      id
      name
      description
    }
  `,
});

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};
