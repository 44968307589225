/**
 * @generated SignedSource<<9145d04c490c7314f152f64977cb6302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRespondentApprovalStatusChoices = "A" | "D" | "NR" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type DenyRespondentInput = {
  clientMutationId?: string | null;
  respondentId: string;
  studyId: string;
};
export type Cells_DenyRespondent_Mutation$variables = {
  input: DenyRespondentInput;
};
export type Cells_DenyRespondent_Mutation$data = {
  readonly denyRespondent: {
    readonly respondent: {
      readonly approvalStatus: ScreenersRespondentApprovalStatusChoices | null;
      readonly id: string;
      readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
    } | null;
    readonly study: {
      readonly needsReviewCount: number | null;
      readonly rejectedCount: number | null;
    } | null;
  } | null;
};
export type Cells_DenyRespondent_Mutation = {
  response: Cells_DenyRespondent_Mutation$data;
  variables: Cells_DenyRespondent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RespondentNode",
  "kind": "LinkedField",
  "name": "respondent",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masterStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsReviewCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectedCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Cells_DenyRespondent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DenyRespondentPayload",
        "kind": "LinkedField",
        "name": "denyRespondent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Cells_DenyRespondent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DenyRespondentPayload",
        "kind": "LinkedField",
        "name": "denyRespondent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec07b01ffd422a5a6a0e5b299160cbdd",
    "id": null,
    "metadata": {},
    "name": "Cells_DenyRespondent_Mutation",
    "operationKind": "mutation",
    "text": "mutation Cells_DenyRespondent_Mutation(\n  $input: DenyRespondentInput!\n) {\n  denyRespondent(input: $input) {\n    respondent {\n      id\n      approvalStatus\n      masterStatus\n    }\n    study {\n      needsReviewCount\n      rejectedCount\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "374ca9ab0a8738a4a5cc052c211feae3";

export default node;
