/**
 * @generated SignedSource<<98bf44de9e1614e4b9a9429f271bc41c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FormPersonAvatar_personAvatarUploadUrlQuery$variables = {
  fileName: string;
  fileType: string;
};
export type FormPersonAvatar_personAvatarUploadUrlQuery$data = {
  readonly personAvatarUploadUrl: string;
};
export type FormPersonAvatar_personAvatarUploadUrlQuery = {
  response: FormPersonAvatar_personAvatarUploadUrlQuery$data;
  variables: FormPersonAvatar_personAvatarUploadUrlQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fileName",
        "variableName": "fileName"
      },
      {
        "kind": "Variable",
        "name": "fileType",
        "variableName": "fileType"
      }
    ],
    "kind": "ScalarField",
    "name": "personAvatarUploadUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormPersonAvatar_personAvatarUploadUrlQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormPersonAvatar_personAvatarUploadUrlQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4aa9491a56291445a99c329fe37fdf5d",
    "id": null,
    "metadata": {},
    "name": "FormPersonAvatar_personAvatarUploadUrlQuery",
    "operationKind": "query",
    "text": "query FormPersonAvatar_personAvatarUploadUrlQuery(\n  $fileName: String!\n  $fileType: String!\n) {\n  personAvatarUploadUrl(fileName: $fileName, fileType: $fileType)\n}\n"
  }
};
})();

(node as any).hash = "6f94a5abe6d083434cf2a5dc564342f3";

export default node;
