import { useUpdateEffect } from "@react-hookz/web";
import { Form, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { ReactNode, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { GivePointsModal_tenant$data } from "../../__generated__/GivePointsModal_tenant.graphql";
import { InputPoints } from "../Configure/InputPoints";
import { GivePointsPaymentModal } from "./GivePointsPaymentModal";

export type CostsSummary = {
  pointsCostUsdCents: number;
  feesUsdCents: number;
  testParticipantCount: number;
  nonTestParticipantCount: number;
};

const _GivePointsModal = ({
  tenant,
  config,
  computeCostsSummary,
  givePoints,
  visible,
  setVisible,
  onClose,
}: {
  tenant: GivePointsModal_tenant$data;
  config: {
    title: string;
    header?: ReactNode;
    footer?: ReactNode;
    buttonLabel?: string;
    getSuccessNotification?: (costsSummary: CostsSummary, points: number) => { title: string; message?: string };
  };
  computeCostsSummary: (points: number) => Promise<CostsSummary>;
  givePoints: (costsSummary: CostsSummary, points: number) => Promise<void>;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onClose?: () => void;
}) => {
  const [form] = Form.useForm();
  const [points, setPoints] = useState(0);
  const [computingCostsSummary, setComputingCostsSummary] = useState(false);
  const [costsSummary, setCostsSummary] = useState<CostsSummary>({
    pointsCostUsdCents: 0,
    feesUsdCents: 0,
    testParticipantCount: 0,
    nonTestParticipantCount: 0,
  });
  const [showGivePointsPaymentModal, setShowGivePointsPaymentModal] = useState(false);

  useUpdateEffect(() => {
    if (!visible) {
      form.resetFields();
      setPoints(0);
    }
  }, [visible]);

  const refreshCosts = async () => {
    try {
      setComputingCostsSummary(true);
      setCostsSummary(await computeCostsSummary(points));
    } finally {
      setComputingCostsSummary(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    onClose?.();
  };

  const handleFinish = async () => {
    await refreshCosts();
    setShowGivePointsPaymentModal(true);
  };

  return (
    <Modal
      centered
      title={config.title}
      open={visible}
      onCancel={handleCancel}
      onOk={handleFinish}
      okText={config.buttonLabel ?? "Give bonus incentive"}
      okButtonProps={{ disabled: points <= 0, loading: computingCostsSummary }}
    >
      {config.header}
      <Form form={form} onFinish={() => setShowGivePointsPaymentModal(true)}>
        <Form.Item name="points">
          <InputPoints form={form} pointsFieldName="points" showCaptions onChange={x => setPoints(x ?? 0)} />
        </Form.Item>
      </Form>
      {config.footer}
      <GivePointsPaymentModal
        visible={showGivePointsPaymentModal}
        setVisible={setShowGivePointsPaymentModal}
        {...{
          tenant,
          points,
          costsSummary,
          givePoints,
        }}
        getSuccessNotification={config.getSuccessNotification ?? (() => ({ title: "Points given" }))}
        onPointsGiven={() => handleCancel()}
      />
    </Modal>
  );
};

export const GivePointsModal = createFragmentContainer(_GivePointsModal, {
  tenant: graphql`
    fragment GivePointsModal_tenant on TenantNode {
      ...GivePointsPaymentModal_tenant
    }
  `,
});
