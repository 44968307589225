/**
 * @generated SignedSource<<57244fbce54d3ce47b1017b17763b479>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type RateParticipationInput = {
  clientMutationId?: string | null;
  noShow?: boolean | null;
  participantId: string;
  rating?: number | null;
};
export type Cells_RateParticipation_Mutation$variables = {
  input: RateParticipationInput;
};
export type Cells_RateParticipation_Mutation$data = {
  readonly rateParticipation: {
    readonly participant: {
      readonly id: string;
      readonly noShow: boolean;
      readonly rating: number;
    } | null;
    readonly respondent: {
      readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
    } | null;
    readonly study: {
      readonly approvedTabCount: number | null;
      readonly needsPaymentCount: number | null;
      readonly recruits: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly status: ScreenersRecruitStatusChoices;
          } | null;
        } | null>;
      };
      readonly rejectedCount: number | null;
    } | null;
  } | null;
};
export type Cells_RateParticipation_Mutation = {
  response: Cells_RateParticipation_Mutation$data;
  variables: Cells_RateParticipation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ParticipantNode",
  "kind": "LinkedField",
  "name": "participant",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noShow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedTabCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsPaymentCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectedCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Cells_RateParticipation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RateParticipationPayload",
        "kind": "LinkedField",
        "name": "rateParticipation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RespondentNode",
            "kind": "LinkedField",
            "name": "respondent",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Cells_RateParticipation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RateParticipationPayload",
        "kind": "LinkedField",
        "name": "rateParticipation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RespondentNode",
            "kind": "LinkedField",
            "name": "respondent",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2165ca63e2749219293ab93268193c6e",
    "id": null,
    "metadata": {},
    "name": "Cells_RateParticipation_Mutation",
    "operationKind": "mutation",
    "text": "mutation Cells_RateParticipation_Mutation(\n  $input: RateParticipationInput!\n) {\n  rateParticipation(input: $input) {\n    participant {\n      id\n      noShow\n      rating\n    }\n    study {\n      approvedTabCount\n      needsPaymentCount\n      rejectedCount\n      recruits {\n        edges {\n          node {\n            status\n            id\n          }\n        }\n      }\n      id\n    }\n    respondent {\n      masterStatus\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "599b0c8c1e525e9c7791aaa7520908ad";

export default node;
