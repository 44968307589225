import { CheckboxValueType } from "antd/es/checkbox/Group";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { environment } from "../../relay";
import { ParticipantColumnsQuery_Query } from "../../__generated__/ParticipantColumnsQuery_Query.graphql";
import ParticipantColumns from "./ParticipantColumns";

type Props = {
  onApplyColumns: (columns: CheckboxValueType[]) => void;
  loading: boolean;
  studyId: string;
  setColumnsDrawerVisible: (visible: boolean) => void;
  columnsDrawerVisible: boolean;
};
function ParticipantColumnsQuery({
  onApplyColumns,
  loading,
  studyId,
  setColumnsDrawerVisible,
  columnsDrawerVisible,
}: Props) {
  return (
    <QueryRenderer<ParticipantColumnsQuery_Query>
      query={graphql`
        query ParticipantColumnsQuery_Query($studyId: String!) {
          viewer {
            ...ParticipantColumns_viewer @arguments(studyId: $studyId)
          }
        }
      `}
      environment={environment}
      variables={{ studyId: studyId }}
      render={({ error, props }) => {
        if (error) {
          console.error(error);
          return null;
        }
        return (
          props?.viewer && (
            <ParticipantColumns
              loading={loading}
              viewer={props.viewer}
              onApplyColumns={onApplyColumns}
              setColumnsDrawerVisible={setColumnsDrawerVisible}
              columnsDrawerVisible={columnsDrawerVisible}
            />
          )
        );
      }}
    />
  );
}

export default ParticipantColumnsQuery;
