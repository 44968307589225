import { Alert, Button, theme, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames from "classnames";
import type { CSSProperties } from "react";
import { useFragment } from "react-relay";

import {
  getRecruitContext,
  getRespondentContext,
  getStudyContext,
  getTenantContext,
  getUserContext,
  trackEvent,
} from "../../utils";
import type { AlertVoxpopmeVideoAnalysis_respondent$key } from "../../__generated__/AlertVoxpopmeVideoAnalysis_respondent.graphql";
import type { AlertVoxpopmeVideoAnalysis_study$key } from "../../__generated__/AlertVoxpopmeVideoAnalysis_study.graphql";
import type { AlertVoxpopmeVideoAnalysis_user$key } from "../../__generated__/AlertVoxpopmeVideoAnalysis_user.graphql";
import { Label } from "../Label";

const { useToken } = theme;
const { Text } = Typography;

export const AlertVoxpopmeVideoAnalysis = ({
  respondent: respondentKey,
  study: studyKey,
  style,
  user: userKey,
}: {
  respondent: AlertVoxpopmeVideoAnalysis_respondent$key | null;
  study: AlertVoxpopmeVideoAnalysis_study$key;
  style: CSSProperties;
  user: AlertVoxpopmeVideoAnalysis_user$key;
}) => {
  const respondent = useFragment(
    graphql`
      fragment AlertVoxpopmeVideoAnalysis_respondent on RespondentNode {
        id
        dId
        recruit {
          id
          dbId
          name
          type
        }
      }
    `,
    respondentKey
  );
  const study = useFragment(
    graphql`
      fragment AlertVoxpopmeVideoAnalysis_study on StudyNode {
        id
        dId
        name
        status
        tenant {
          id
          dId
          name
          vpmAccountId
        }
        type
      }
    `,
    studyKey
  );
  const user = useFragment(
    graphql`
      fragment AlertVoxpopmeVideoAnalysis_user on UserNode {
        id
        dId
        vpmUserId
      }
    `,
    userKey
  );

  const canOpenVoxpopme = !!study.tenant.vpmAccountId && !!user.vpmUserId;

  const { token } = useToken();

  return (
    <Alert
      message={
        <div style={{ alignItems: "baseline", display: "flex", gap: token.marginXS }}>
          <Text>Analyze call transcript, themes, and more in Voxpopme.</Text>
          <Button
            className={classNames({ "vpm-pendo-guide-open-voxpopme-for-analysis": !canOpenVoxpopme })}
            style={{ height: "auto", padding: 0 }}
            href="https://www.voxpopme.com/portal"
            onClick={e => {
              if (!canOpenVoxpopme) e.preventDefault();

              trackEvent("Open Voxpopme for analysis", {
                ...getStudyContext(study),
                ...getTenantContext(study.tenant),
                ...getUserContext(user),
                ...getRespondentContext(respondent ?? undefined),
                ...getRecruitContext(respondent?.recruit ?? undefined),
              });
            }}
            type="link"
          >
            <Label icon="mdi:launch" style={{ flexDirection: "row-reverse", gap: token.marginXXS }} text="Go now" />
          </Button>
        </div>
      }
      style={{ alignItems: "baseline", ...style }}
      type="info"
    />
  );
};
