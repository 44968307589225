import classNames, { type Argument } from "classnames";
import React, { forwardRef, type ReactNode } from "react";
import styled from "styled-components";
import { GRAY_7 } from "../../style";

export const InputTitle = forwardRef<
  HTMLDivElement,
  {
    className?: Argument;
    style?: React.CSSProperties;
    text: ReactNode;
    optional?: boolean;
    required?: boolean;
  } & ({ optional?: boolean; required?: never } | { optional?: never; required?: boolean })
>(({ className, optional, required, style, text }, ref) => (
  <Styled ref={ref} className={classNames(className)} style={style}>
    {!!required && <span className="required">*</span>}
    <div className="title">{text}</div>
    {!!optional && <span className="optional">&nbsp;(optional)</span>}
  </Styled>
));

const Styled = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;

  .optional {
    color: ${GRAY_7};
    font-weight: initial;
  }

  .required {
    font-size: 14px;
    color: var(--ant-error-color);
    margin-right: 4px;
  }
`;
