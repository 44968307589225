/**
 * @generated SignedSource<<e6d1ae363a4fd55851955682f392adcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRespondentApprovalStatusChoices = "A" | "D" | "NR" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type ApproveRespondentInput = {
  clientMutationId?: string | null;
  respondentId: string;
  studyId: string;
};
export type Cells_ApproveRejectedRespondent_Mutation$variables = {
  input: ApproveRespondentInput;
};
export type Cells_ApproveRejectedRespondent_Mutation$data = {
  readonly approveRespondent: {
    readonly respondent: {
      readonly approvalStatus: ScreenersRespondentApprovalStatusChoices | null;
      readonly id: string;
      readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
    } | null;
    readonly study: {
      readonly approvedTabCount: number | null;
      readonly needsPaymentCount: number | null;
      readonly needsReviewCount: number | null;
      readonly overquotaCount: number | null;
      readonly recruits: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly status: ScreenersRecruitStatusChoices;
          } | null;
        } | null>;
      };
      readonly rejectedCount: number | null;
      readonly scheduledCount: number | null;
    } | null;
  } | null;
};
export type Cells_ApproveRejectedRespondent_Mutation = {
  response: Cells_ApproveRejectedRespondent_Mutation$data;
  variables: Cells_ApproveRejectedRespondent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RespondentNode",
  "kind": "LinkedField",
  "name": "respondent",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masterStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsReviewCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsPaymentCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedTabCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overquotaCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rejectedCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Cells_ApproveRejectedRespondent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApproveRespondentPayload",
        "kind": "LinkedField",
        "name": "approveRespondent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Cells_ApproveRejectedRespondent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApproveRespondentPayload",
        "kind": "LinkedField",
        "name": "approveRespondent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RecruitNodeConnection",
                "kind": "LinkedField",
                "name": "recruits",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecruitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecruitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dfe053e7dda728ba1aedccd2bd0392d4",
    "id": null,
    "metadata": {},
    "name": "Cells_ApproveRejectedRespondent_Mutation",
    "operationKind": "mutation",
    "text": "mutation Cells_ApproveRejectedRespondent_Mutation(\n  $input: ApproveRespondentInput!\n) {\n  approveRespondent(input: $input) {\n    respondent {\n      id\n      approvalStatus\n      masterStatus\n    }\n    study {\n      needsReviewCount\n      needsPaymentCount\n      approvedTabCount\n      scheduledCount\n      overquotaCount\n      rejectedCount\n      recruits {\n        edges {\n          node {\n            status\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e6936ebba6ad1780f7235b77959f3e8";

export default node;
