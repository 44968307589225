import { useUpdateEffect } from "@react-hookz/web";
import { Key, ReactNode, useMemo, useRef } from "react";

export const Accordion = <T,>({
  beforeExpand,
  expandedIndex,
  getCollapsedContent,
  getExpandedContent,
  getKey,
  items,
  setExpandedIndex,
}: {
  beforeExpand?: () => boolean | Promise<boolean>;
  expandedIndex: number | null;
  getCollapsedContent: (item: T) => ReactNode;
  getExpandedContent: (item: T) => ReactNode;
  getKey?: (item: T) => Key;
  items: T[] | readonly T[];
  setExpandedIndex: (index: number | null) => void;
}) => {
  const expandedRef = useRef<HTMLDivElement>(null);

  useUpdateEffect(
    () => expandedRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" }),
    [expandedRef.current]
  );

  return (
    <>
      {useMemo(
        () =>
          items.map((item, index) => (
            <div
              key={getKey?.(item) ?? index}
              {...(expandedIndex === index
                ? // allow scrolling to the active item
                  { ref: expandedRef }
                : // handle clicks to set expanded item
                  {
                    onClick: async () => {
                      if (await beforeExpand?.()) setExpandedIndex(index);
                    },
                  })}
            >
              {expandedIndex === index ? getExpandedContent(items[index]!) : getCollapsedContent(items[index]!)}
            </div>
          )),
        [items, expandedIndex, getCollapsedContent, getExpandedContent, setExpandedIndex, getKey]
      )}
    </>
  );
};
