/**
 * @generated SignedSource<<02e1261ec3fa4057e252bb70a80ef777>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ObtainSSOTokenInput = {
  callbackUrl?: string | null;
  clientMutationId?: string | null;
  code?: string | null;
  env?: string | null;
};
export type SsoCallbackPageMutation$variables = {
  input: ObtainSSOTokenInput;
};
export type SsoCallbackPageMutation$data = {
  readonly obtainSsoToken: {
    readonly tokenSet: {
      readonly accessToken: string;
      readonly refreshToken: string;
    };
  };
};
export type SsoCallbackPageMutation = {
  response: SsoCallbackPageMutation$data;
  variables: SsoCallbackPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ObtainSSOTokenPayload",
    "kind": "LinkedField",
    "name": "obtainSsoToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TokenSet",
        "kind": "LinkedField",
        "name": "tokenSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refreshToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SsoCallbackPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SsoCallbackPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5bc4474c1a61165668976af67d8d3e70",
    "id": null,
    "metadata": {},
    "name": "SsoCallbackPageMutation",
    "operationKind": "mutation",
    "text": "mutation SsoCallbackPageMutation(\n  $input: ObtainSSOTokenInput!\n) {\n  obtainSsoToken(input: $input) {\n    tokenSet {\n      accessToken\n      refreshToken\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8df7c103cb0f4edad8b220c8c583e124";

export default node;
