import { Radio, Select, Table } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { graphql } from "babel-plugin-relay/macro";
import { sortBy, uniq } from "lodash-es";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { EventsType, PROJECT_TYPES } from "../../constants";
import { GRAY_4 } from "../../style";
import { TimeZoneContext } from "../../utils";
import { pluralize } from "../../utils/misc";
import { SlotPicker_study$key } from "../../__generated__/SlotPicker_study.graphql";

type SlotRow = { slotId: string; moderator: string; time: string; available?: number };

export const SlotPicker = ({
  study: _study,
  selectedCount,
  selectedSession,
  setSelectedSession,
  selectedDate,
  setSelectedDate,
  selectedSlotId,
  setSelectedSlotId,
  respondentSessions,
}: {
  study: SlotPicker_study$key;
  selectedCount: number;
  selectedSession: string | undefined;
  setSelectedSession: (date: string) => void;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  selectedSlotId: string;
  setSelectedSlotId: (slotId: string) => void;
  respondentSessions?: Record<string, string>;
}) => {
  const study = useFragment(
    graphql`
      fragment SlotPicker_study on StudyNode {
        id
        type
        eventsType
        sessions {
          edges {
            node {
              id
              name
              availabilitySlots {
                edges {
                  node {
                    id
                    moderatorEmail
                    start
                    end
                    availablePlaces
                  }
                }
              }
              scheduledSlots {
                edges {
                  node {
                    id
                    start
                    end
                    studyAvailabilitySlot {
                      moderatorEmail
                      availablePlaces
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    _study
  );

  useEffect(() => {
    if (selectedSession && selectedDate && respondentSessions?.[selectedSession]) {
      setSelectedSlotId(respondentSessions[selectedSession]!);
    }
  }, [respondentSessions, selectedDate, selectedSession, setSelectedSlotId]);

  const handleSelectedDateChange = (evt: RadioChangeEvent) => {
    setSelectedDate(evt.target.value);
    setSelectedSlotId("");
  };

  const { shiftDate } = useContext(TimeZoneContext);

  const slotRows =
    selectedSession && selectedDate
      ? study.type === PROJECT_TYPES.INTERVIEW
        ? sortBy(
            study.sessions.edges
              .find(s => s!.node!.id === selectedSession)!
              .node!.scheduledSlots.edges.filter(
                e => moment(selectedDate).isSame(e!.node!.start, "day") && moment(e!.node!.start).isAfter()
              ),
            e => e!.node!.start
          ).map<SlotRow>(e => ({
            slotId: e!.node!.id,
            moderator: e!.node!.studyAvailabilitySlot?.moderatorEmail ?? "",
            time: `${shiftDate(moment(e!.node!.start)).format("LT")} - ${shiftDate(moment(e!.node!.end)).format("LT")}`,
            available: undefined,
          }))
        : study.type === PROJECT_TYPES.FOCUS_GROUP
        ? sortBy(
            study.sessions.edges
              .find(s => s!.node!.id === selectedSession)!
              .node!.availabilitySlots.edges.filter(
                e => moment(selectedDate).isSame(e!.node!.start, "day") && moment(e!.node!.start).isAfter()
              ),
            e => e!.node!.start
          ).map<SlotRow>((e, i) => ({
            slotId: e!.node!.id,
            moderator: e!.node!.moderatorEmail ?? "",
            time: `${shiftDate(moment(e!.node!.start)).format("LT")} - ${shiftDate(moment(e!.node!.end)).format("LT")}`,
            available: e!.node!.availablePlaces ?? undefined,
          }))
        : []
      : [];

  const handleRowSelect = ([key]: React.Key[]) => {
    if (key) setSelectedSlotId(key.toString());
  };

  if ((study.eventsType as EventsType) === "SINGLE" && !selectedSession) {
    setSelectedSession(study.sessions.edges[0]!.node!.id);
  }

  return (
    <>
      <h1>Schedule {pluralize(selectedCount, "participants", "participant")}</h1>
      <p>Assign a slot to {pluralize(selectedCount, "these participants", "this participant")}.</p>
      {(study.eventsType as EventsType) === "SESSIONS" && (
        <>
          <p className="label">Select session to change</p>
          <Select
            onChange={v => {
              setSelectedSession(v);
              setSelectedDate("");
            }}
            style={{ width: "100%" }}
            value={selectedSession}
            placeholder="Select a session"
          >
            {study.sessions.edges.map(session => (
              <Select.Option key={session!.node!.id} value={session!.node!.id}>
                {session!.node!.name}
              </Select.Option>
            ))}
          </Select>
        </>
      )}
      {selectedSession && (
        <>
          <p className="label">Select day</p>
          <Radio.Group onChange={handleSelectedDateChange} value={selectedDate} buttonStyle="solid">
            {sortBy(
              uniq(
                study.sessions.edges
                  .find(s => s!.node!.id === selectedSession)!
                  .node!.availabilitySlots!.edges // filter to future slots only
                  .filter(e => moment(e!.node!.start).isAfter())
                  .map(e => normalizeDay(e!.node!.start))
              ),
              "date"
            ).map((d, i) => (
              <Radio.Button className="date-button" value={d} key={i}>
                {shiftDate(moment(d)).format("ddd, LL")}
              </Radio.Button>
            ))}
          </Radio.Group>
        </>
      )}
      {!selectedDate ? (
        <div className="slots-placeholder">
          <p>Select a {study.eventsType === "SESSIONS" && "session and"} date to show the events</p>
        </div>
      ) : (
        <>
          <p className="label">Select event</p>
          <StyledSlotsTable
            rowSelection={{
              type: "radio",
              selectedRowKeys: selectedSlotId ? [selectedSlotId] : [],
              onChange: handleRowSelect,
            }}
            rowKey={(record: any) => record.slotId}
            pagination={false}
            onRow={(record: any) => ({
              onClick: () => setSelectedSlotId(record.slotId),
            })}
            rowClassName={(record: any) => (selectedSlotId === record.slotId ? "selected" : "")}
            columns={[
              {
                title: "Moderator",
                dataIndex: "moderator",
                align: "center",
                render: moderator => moderator || "–",
              },
              { title: "Time", dataIndex: "time", align: "center" },
              { title: "Available", dataIndex: "available", align: "center" },
            ]}
            dataSource={slotRows}
          />
          <p className="instructions">Events can be added, modified, or deleted on the Booking tab</p>
        </>
      )}
    </>
  );
};

function normalizeDay(date: string): string {
  return moment(date).toJSON();
}

const StyledSlotsTable = styled(Table)`
  thead {
    font-weight: bold;
  }

  tr {
    cursor: pointer;

    &.selected,
    &.selected:hover > td {
      font-weight: 400;
      background-color: ${GRAY_4};
    }
  }
`;
