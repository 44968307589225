import { Table } from "antd";
import Tag from "antd/es/tag";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import { QUAL_ELEMENTS } from "../../../constants";
import { FieldReportQuestionTable_element$data } from "../../../__generated__/FieldReportQuestionTable_element.graphql";

type Props = {
  element: FieldReportQuestionTable_element$data;
};
const FieldReportQuestionTable: React.FC<Props> = ({ element }) => {
  const findGridAnswer = (rowId: string, answerId: string) =>
    element.gridAnswers?.find(gridAnswer => gridAnswer?.rowId === rowId && gridAnswer?.answerId === answerId);

  const makeColumns = (e: any) => {
    return e.type && [...QUAL_ELEMENTS, "NM"].includes(e.type)
      ? [
          {
            title: e.labeledText,
            dataIndex: "text",
            render: () => "Responses can be exported from the Participants view",
          },
          {
            title: "Total",
            dataIndex: "total",
            width: 80,
          },
          {
            title: "Qualified",
            dataIndex: "qualified",
            width: 80,
          },
          {
            title: "Qualified %",
            render: (v: any, o: any) => {
              return o.qualifiedTotal ? Math.round((o.qualified / o.qualifiedTotal) * 100) : 0 + " %";
            },
            width: 100,
          },
        ]
      : [
          {
            title: e.labeledText,
            dataIndex: "text",
          },
          {
            title: "Quota",
            width: 80,
            render: (o: any) => (o.terminate ? "N/A" : findGridAnswer(e.dbId, o.dbId)?.goal ?? o.quota),
          },
          {
            title: "Terminate",
            width: 80,
            render: (o: any) => (
              <>
                <Tag>{findGridAnswer(e.dbId, o.dbId)?.terminate ?? o.terminate ? "Yes" : "No"}</Tag>
              </>
            ),
          },
          {
            title: "Total",
            dataIndex: "total",
            width: 80,
          },
          {
            title: "Qualified",
            dataIndex: "qualified",
            width: 80,
          },
          {
            title: "Qualified %",
            render: (v: any, o: any) => {
              return o.qualifiedTotal ? Math.round((o.qualified / o.qualifiedTotal) * 100) + " %" : 0 + " %";
            },
            width: 100,
          },
        ];
  };

  const makeDataSource = (e: any) => e.options.map((o: any, i: any) => ({ key: i, row: e, ...o }));

  if (element.type === "GS") {
    return (
      <>
        {element.rows?.map((row, i) => (
          <Table
            key={row?.dbId}
            columns={makeColumns(row)}
            // @ts-ignore
            dataSource={makeDataSource(row)}
            size="small"
            pagination={false}
            style={{ marginBottom: -1 }}
          />
        ))}
      </>
    );
  }

  return (
    <Table
      // @ts-ignore
      columns={makeColumns(element)}
      // @ts-ignore
      dataSource={makeDataSource(element)}
      size="small"
      pagination={false}
      style={{ marginBottom: -1 }}
    />
  );
};

export default createFragmentContainer(FieldReportQuestionTable, {
  element: graphql`
    fragment FieldReportQuestionTable_element on FieldReportElementNode {
      text
      type
      labeledText
      rows {
        dbId
        text
        labeledText
        options {
          dbId
          text
          qualified
          qualifiedTotal
          total
        }
      }
      options {
        text
        qualified
        qualifiedTotal
        total
        quota
        terminate
      }
      gridAnswers {
        rowId
        answerId
        goal
        terminate
      }
    }
  `,
});
