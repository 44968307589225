import { Alert, FormInstance } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import React, { useMemo } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { EmailTemplateContent, PROJECT_INCENTIVE_TYPES, RECRUIT_STATUSES } from "../../constants";
import { ScreenersRecruitIncentiveTypeChoices, ScreenersRecruitTypeChoices } from "../../schema";
import { usdFormatter } from "../../utils/misc";
import { RecruitInstructions_recruit$data } from "../../__generated__/RecruitInstructions_recruit.graphql";
import { RecruitInstructions_study$data } from "../../__generated__/RecruitInstructions_study.graphql";
import { RecruitInstructions_user$data } from "../../__generated__/RecruitInstructions_user.graphql";
import { CommunicationComposePage } from "../index";
import ScreenerLink from "../ScreenerLink";

type Props = {
  study: RecruitInstructions_study$data;
  user: RecruitInstructions_user$data;
  recruit: RecruitInstructions_recruit$data;
  isRecruitFormComplete: () => boolean;
  isRecruitFunded: () => boolean;
};
const RecruitInstructions = React.forwardRef<FormInstance, Props>(
  ({ study, user, recruit, isRecruitFormComplete, isRecruitFunded }, formRef) => {
    const defaultMessage = useMemo<EmailTemplateContent>(() => {
      const studyName = study?.namePublic ?? study?.screener?.title;
      const screenerTitle = studyName ? ` - ${studyName}` : "";

      let incentive = "";
      if (study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS && recruit.incentivePoints) {
        incentive = ` worth ${recruit.incentivePoints} points`;
      } else if (study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH && recruit.incentive) {
        incentive = ` worth ${usdFormatter.format(parseFloat(recruit.incentive))}`;
      }

      return {
        replyTo: "",
        subject: "",
        message: `Hello,\n\nYou're invited to participate in an upcoming research study${screenerTitle}${incentive}.\n\nBut! We need you to take a short survey to see if you qualify.\n\n%study.screener_link%`,
      };
    }, [recruit.incentive, recruit.incentivePoints, study.incentiveType, study?.namePublic, study?.screener?.title]);

    return (
      <Styled>
        {recruit.status === RECRUIT_STATUSES.STARTED ? (
          <>
            {recruit.type === ScreenersRecruitTypeChoices.Wl ? (
              <>
                <p>You can share this link anywhere. Simply copy and paste the link in an email or on social media.</p>
                <ScreenerLink recruit={recruit} />
              </>
            ) : !recruit.underGoal || recruit.overNeedsReviewLimit ? (
              <Alert
                message="Recruiting Round Paused"
                description={
                  !recruit.underGoal ? (
                    <>
                      Recruiting has been paused because either the recruitment goal was met or there are too many
                      approved participants.
                    </>
                  ) : (
                    <>
                      We have paused recruiting because you have too many participants to review. Reject{" "}
                      <Link to={`/projects/${study.id}/respondents/participants`}>participants</Link> to continue
                      sending invites.
                    </>
                  )
                }
                type="warning"
                showIcon
              />
            ) : (
              <p>
                We are recruiting for you. You can view qualified participants on the{" "}
                <Link to={`/projects/${study.id}/respondents/participants`}>Participants page</Link> as they qualify.
              </p>
            )}
          </>
        ) : recruit.status === RECRUIT_STATUSES.PAUSED ? (
          <>
            Recruiting for this round is paused. You can still view qualified participants on the{" "}
            <Link to={`/projects/${study.id}/respondents/participants`}>participant page</Link>.
          </>
        ) : (
          <>
            {recruit.type === ScreenersRecruitTypeChoices.Em &&
            isRecruitFormComplete() &&
            // workaround to prevent missing incentive details in initialMessage
            (recruit.incentiveType === ScreenersRecruitIncentiveTypeChoices.Ex ||
              recruit.incentive !== null ||
              recruit.incentivePoints !== null) ? (
              <>
                <p>Upload your email list and click save. We will take care of sending the email for you.</p>
                <p>If any emails are found for users added to other recruiting rounds, they'll be skipped.</p>
                <CommunicationComposePage
                  ref={formRef}
                  study={study}
                  user={user}
                  recruit={recruit}
                  initialMessage={defaultMessage}
                />
              </>
            ) : null}
          </>
        )}
      </Styled>
    );
  }
);

const Styled = styled.div`
  .title {
    margin-bottom: 6px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #aaaaaa;
  }
  p {
    margin-bottom: 12px;
    line-height: 18px;
  }

  .panel-settings {
    margin-top: 2rem;

    .radio-style {
      display: block;
      margin-top: 12px;
    }
  }
`;

export default createFragmentContainer(RecruitInstructions, {
  study: graphql`
    fragment RecruitInstructions_study on StudyNode {
      id
      namePublic
      status
      incentiveType
      screener {
        title
      }
      tenant {
        requireStudyPayment
      }
      ...CommunicationComposePage_study
    }
  `,
  user: graphql`
    fragment RecruitInstructions_user on UserNode {
      email
      ...CommunicationComposePage_user
    }
  `,
  recruit: graphql`
    fragment RecruitInstructions_recruit on RecruitNode {
      type
      status
      underGoal
      overNeedsReviewLimit
      incentive
      incentivePoints
      incentiveType
      ...CommunicationComposePage_recruit
      ...ScreenerLink_recruit
    }
  `,
});
