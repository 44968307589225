import { App, Drawer, Input, Radio } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useMemo, useState } from "react";
import { useRefetchableFragment } from "react-relay";
import styled, { createGlobalStyle } from "styled-components";

import { ScreenersFilterTypeChoices } from "../../schema";
import type { DispatchState } from "../../types";
import { onError, useMutation } from "../../utils";
import type { DrawerFiltersViewerRefetchQuery } from "../../__generated__/DrawerFiltersViewerRefetchQuery.graphql";
import type { DrawerFilters_DeleteFilter_Mutation } from "../../__generated__/DrawerFilters_DeleteFilter_Mutation.graphql";
import type { DrawerFilters_SaveFilter_Mutation } from "../../__generated__/DrawerFilters_SaveFilter_Mutation.graphql";
import type { DrawerFilters_viewer$key } from "../../__generated__/DrawerFilters_viewer.graphql";
import { DetailsInput } from "../Configure/DetailsInput";
import { DRAWER_HEADER_PARENT_CLASS_NAME } from "../Drawer/DrawerHeader";

import { FiltersLoad } from ".";
import { ButtonLabel } from "..";
import { PAGE_WIDTH_SM, PAGE_WIDTH_XS } from "../../style";
import { DrawerHeaderFilters } from "./DrawerHeaderFilters";
import { InputFilterGroupGroup } from "./InputFilterGroupGroup";
import { FilterType, type PanelistFilterGroupGroup, type ParticipantFilterGroupGroup } from "./types";
import { isValidFilterGroupGroup } from "./utils";

type SavedFilterVisibility = "shared" | "user";

export const DrawerFilters = <
  T extends ScreenersFilterTypeChoices,
  FilterGroupGroup extends T extends ScreenersFilterTypeChoices.Pn
    ? PanelistFilterGroupGroup
    : ParticipantFilterGroupGroup
>({
  filterGroupGroup,
  loadedFilterId,
  loading,
  onApply,
  onClear,
  onClose,
  onDelete,
  open,
  setFilterGroupGroup,
  setLoadedFilterId,
  type: _type,
  viewer: viewerKey,
}: Pick<Parameters<typeof Drawer>[0], "open"> &
  Pick<Parameters<typeof DrawerHeaderFilters>[0], "onApply" | "onClose"> & {
    filterGroupGroup: FilterGroupGroup;
    loadedFilterId: string | undefined;
    loading: boolean;
    onClear?: Parameters<typeof ButtonLabel>[0]["onClick"];
    onDelete?: () => void;
    setFilterGroupGroup: DispatchState<FilterGroupGroup>;
    setLoadedFilterId: DispatchState<string | undefined>;
    type: T;
    viewer: DrawerFilters_viewer$key;
  }) => {
  const { message, notification } = App.useApp();

  const [saving, setSaving] = useState(false);

  const [filtersName, setFiltersName] = useState("");
  const [filterRelatedToStudy, setFilterRelatedToStudy] = useState(true);
  const [filterVisibility, setFilterVisibility] = useState<SavedFilterVisibility>("shared");

  const [viewer, refetch] = useRefetchableFragment<DrawerFiltersViewerRefetchQuery, typeof viewerKey>(
    graphql`
      fragment DrawerFilters_viewer on Viewer
      @argumentDefinitions(studyId: { type: "String", defaultValue: null })
      @refetchable(queryName: "DrawerFiltersViewerRefetchQuery") {
        study(studyId: $studyId) {
          id
          filters {
            id
            name
            user {
              id
            }
            study {
              id
            }
            type
            filters
          }
          screener {
            ...InputFilterGroupGroup_screener
          }
        }
        user {
          id
          profile {
            tenant {
              id
              name
              filters {
                id
                name
                user {
                  id
                }
                study {
                  id
                }
                type
                filters
              }
              ...InputFilterGroupGroup_tenant
            }
          }
        }
      }
    `,
    viewerKey
  );

  const savedFilters = useMemo(
    () =>
      [...(viewer.study?.filters ?? []), ...(viewer.user?.profile?.tenant?.filters ?? [])].filter(
        x => x.type === _type
      ),
    [_type, viewer.study?.filters, viewer.user?.profile?.tenant?.filters]
  );

  useEffect(() => {
    const x = savedFilters.find(x => x.id === loadedFilterId);
    setFilterRelatedToStudy(!!x?.study);
    setFilterVisibility(x?.user ? "user" : "shared");
    setFiltersName(x?.name ?? "");
  }, [loadedFilterId, savedFilters]);

  const [commitSaveFilter] = useMutation<DrawerFilters_SaveFilter_Mutation>(graphql`
    mutation DrawerFilters_SaveFilter_Mutation($input: SaveFilterInput!) {
      saveFilter(input: $input) {
        filter {
          id
          filters
          name
          study {
            id
          }
          type
          user {
            id
          }
        }
        tenant {
          id
          filters {
            id
            filters
            name
            study {
              id
            }
            type
            user {
              id
            }
          }
        }
        study {
          id
          filters {
            id
            filters
            name
            study {
              id
            }
            type
            user {
              id
            }
          }
        }
      }
    }
  `);

  const saveFilterGroupGroup = (name: string, x: FilterGroupGroup) =>
    new Promise<void>((resolve, reject) => {
      setSaving(true);
      commitSaveFilter(
        {
          name,
          type: _type,
          filters: JSON.stringify(x),
          studyId: filterRelatedToStudy ? viewer.study?.id : null,
          userId: filterVisibility === "user" ? viewer.user?.id : null,
        },
        {
          onCompleted: res => {
            setLoadedFilterId(res.saveFilter?.filter.id);
            notification.success({ message: "Filter saved" });
            setSaving(false);
            resolve();
          },
          onError: e => {
            onError(e, message);
            setSaving(false);
            throw e;
          },
        }
      );
    });

  const [commitDeleteFilter] = useMutation<DrawerFilters_DeleteFilter_Mutation>(graphql`
    mutation DrawerFilters_DeleteFilter_Mutation($input: DeleteFilterInput!) {
      deleteFilter(input: $input) {
        tenant {
          id
          filters {
            id
            filters
            name
            study {
              id
            }
            type
            user {
              id
            }
          }
        }
        study {
          id
          filters {
            id
            filters
            name
            study {
              id
            }
            type
            user {
              id
            }
          }
        }
      }
    }
  `);

  return (
    <StyledDrawer
      className={DRAWER_HEADER_PARENT_CLASS_NAME}
      closable={false}
      rootClassName="hub-drawer-filters"
      title={
        <DrawerHeaderFilters
          extraRow={
            !!savedFilters.length && (
              <FiltersLoad
                filterId={loadedFilterId}
                filters={savedFilters}
                onDelete={x =>
                  commitDeleteFilter(
                    { filterId: x },
                    {
                      onCompleted: () => {
                        refetch({
                          studyId: viewer.study?.id ?? null,
                        });
                        onDelete?.();
                        notification.success({ message: "Filter deleted" });
                      },
                    }
                  )
                }
                onSelect={x => setLoadedFilterId(x)}
              />
            )
          }
          filtersValid={
            // @ts-expect-error `FilterGroupGroup` depends on `_type`'s type instead of on `filterGroupGroup`'s type, and can therefore be manually instantiated to an unexpected filter type. This isn't possible in the real world as `DrawerFilters`'s prop types do not allow it
            isValidFilterGroupGroup(filterGroupGroup)
          }
          loading={loading}
          onApply={onApply}
          onClose={onClose}
          onSave={() => saveFilterGroupGroup(filtersName, filterGroupGroup)}
          saveDisabled={!filtersName.length}
          savePopoverContent={
            <>
              <div>
                <h3 style={{ fontWeight: "inherit" }}>Save filters as</h3>
                <Input
                  maxLength={120}
                  onChange={e => setFiltersName(e.target.value)}
                  onPressEnter={() => saveFilterGroupGroup(filtersName, filterGroupGroup)}
                  value={filtersName}
                />
              </div>
              <DetailsInput
                collapseBottomMargin
                title="Who can see these filters?"
                titleStyle={{ fontWeight: "inherit" }}
                inputs={
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={e => setFilterVisibility(e.target.value as SavedFilterVisibility)}
                    optionType="button"
                    options={[
                      { label: "My team", value: "shared" },
                      { label: "Just me", value: "user" },
                    ]}
                    size="small"
                    value={filterVisibility}
                  />
                }
              />
              {_type === ScreenersFilterTypeChoices.Pt && (
                <DetailsInput
                  collapseBottomMargin
                  title="Where can this filter be used?"
                  titleStyle={{ fontWeight: "inherit" }}
                  inputs={
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={e => setFilterRelatedToStudy(!!e.target.value)}
                      optionType="button"
                      options={[
                        { label: "This project", value: true },
                        { label: "All projects", value: false },
                      ]}
                      size="small"
                      value={filterRelatedToStudy}
                    />
                  }
                />
              )}
            </>
          }
          saving={saving}
        />
      }
      placement="right"
      open={open}
      mask={false}
      width="30%"
    >
      <StyledDrawerGlobal
        $wide={filterGroupGroup.filters.some(x => x.filters.some(x => x.type === FilterType.Study))}
      />
      <InputFilterGroupGroup
        onClear={onClear}
        screener={viewer.study?.screener ?? null}
        setValue={setFilterGroupGroup}
        tenant={viewer.user?.profile?.tenant ?? null}
        type={_type}
        value={filterGroupGroup}
      />
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .filter-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .filter-operator-radio {
      display: flex;
      gap: inherit;
    }
  }
`;

// must be global, as antd renders options outside StyledDrawer
const StyledDrawerGlobal = createGlobalStyle<{ $wide: boolean }>`
  .hub-drawer-filters.ant-drawer-open .ant-drawer-content-wrapper {
    min-width: ${props => (props.$wide ? PAGE_WIDTH_SM : PAGE_WIDTH_XS)} !important;
  }
`;
