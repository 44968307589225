/**
 * @generated SignedSource<<ce3f034d4115bfaa075c5a41ff61569a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddAnswerInput = {
  clientMutationId?: string | null;
  elementId: string;
  other: boolean;
  position?: number | null;
  terminate: boolean;
};
export type GridQuestionEditor_addColumn_Mutation$variables = {
  input: AddAnswerInput;
};
export type GridQuestionEditor_addColumn_Mutation$data = {
  readonly addAnswer: {
    readonly element: {
      readonly answers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly position: number;
            readonly terminate: boolean;
            readonly text: string | null;
          } | null;
        } | null>;
      };
      readonly gridAnswers: ReadonlyArray<{
        readonly answer: {
          readonly id: string;
        };
        readonly goal: number | null;
        readonly row: {
          readonly id: string;
        };
        readonly terminate: boolean | null;
      } | null> | null;
      readonly id: string;
    } | null;
  } | null;
};
export type GridQuestionEditor_addColumn_Mutation = {
  response: GridQuestionEditor_addColumn_Mutation$data;
  variables: GridQuestionEditor_addColumn_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AnswerNodeConnection",
  "kind": "LinkedField",
  "name": "answers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "RowNode",
  "kind": "LinkedField",
  "name": "row",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AnswerNode",
  "kind": "LinkedField",
  "name": "answer",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goal",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GridQuestionEditor_addColumn_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAnswerPayload",
        "kind": "LinkedField",
        "name": "addAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GridAnswerNode",
                "kind": "LinkedField",
                "name": "gridAnswers",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GridQuestionEditor_addColumn_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAnswerPayload",
        "kind": "LinkedField",
        "name": "addAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GridAnswerNode",
                "kind": "LinkedField",
                "name": "gridAnswers",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43d8f5aed347c97aca6f5e87b549f727",
    "id": null,
    "metadata": {},
    "name": "GridQuestionEditor_addColumn_Mutation",
    "operationKind": "mutation",
    "text": "mutation GridQuestionEditor_addColumn_Mutation(\n  $input: AddAnswerInput!\n) {\n  addAnswer(input: $input) {\n    element {\n      id\n      answers {\n        edges {\n          node {\n            id\n            text\n            terminate\n            position\n          }\n        }\n      }\n      gridAnswers {\n        row {\n          id\n        }\n        answer {\n          id\n        }\n        goal\n        terminate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8d6cf1ba982456eac59f5e706f85a8e";

export default node;
