import { Select } from "antd";
import type { SetRequired } from "type-fest";

import type { AnswerNode, CharacteristicAnswerNode } from "../../schema";

import { ButtonNot } from "./ButtonNot";
import { type FilterSubtypeResponse, type PanelistFilter, type ParticipantFilter } from "./types";

export const InputFilterResponseId = <T extends ParticipantFilter | PanelistFilter>({
  answers,
  filter,
  setFilter,
}: {
  answers: SetRequired<Partial<CharacteristicAnswerNode | AnswerNode>, "dbId" | "text">[];
  filter: T & { subtype: FilterSubtypeResponse.Id };
  setFilter: (x: T) => void;
}) => {
  return (
    <div className="form-filter-row">
      <ButtonNot filter={filter} setFilter={setFilter} />
      <Select
        mode="multiple"
        className="input-values"
        value={filter.values.filter(x => !!x)}
        onChange={values => setFilter({ ...filter, values })}
        optionFilterProp="children"
        showSearch
      >
        {answers.map(x => (
          <Select.Option key={x.dbId} value={x.dbId}>
            {x.text}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
