/**
 * @generated SignedSource<<7b90887fd7805b7c36c0c7707bd8d304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_CharacteristicsEditPage_Query$variables = {};
export type RouteConfig_CharacteristicsEditPage_Query$data = {
  readonly viewer: {
    readonly user: {
      readonly profile: {
        readonly tenant: {
          readonly " $fragmentSpreads": FragmentRefs<"CharacteristicsEditPage_tenant">;
        };
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"CharacteristicsEditPage_user">;
    } | null;
  };
};
export type RouteConfig_CharacteristicsEditPage_Query = {
  response: RouteConfig_CharacteristicsEditPage_Query$data;
  variables: RouteConfig_CharacteristicsEditPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_CharacteristicsEditPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CharacteristicsEditPage_tenant"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CharacteristicsEditPage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfig_CharacteristicsEditPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CharacteristicNodeConnection",
                        "kind": "LinkedField",
                        "name": "characteristics",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CharacteristicNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CharacteristicNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "shortName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "showToPanelists",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "importKey",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CharacteristicElementMetaNode",
                                    "kind": "LinkedField",
                                    "name": "elementMeta",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "randomize",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "responseValidation",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "target",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "target2",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "targetTypes",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CharacteristicAnswerNode",
                                    "kind": "LinkedField",
                                    "name": "answers",
                                    "plural": true,
                                    "selections": [
                                      (v0/*: any*/),
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "other",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "userSpecified",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CharacteristicRowNode",
                                    "kind": "LinkedField",
                                    "name": "rows",
                                    "plural": true,
                                    "selections": [
                                      (v0/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vpmAccountId",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vpmUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e073633eab1a0209000cf19ba53f9939",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_CharacteristicsEditPage_Query",
    "operationKind": "query",
    "text": "query RouteConfig_CharacteristicsEditPage_Query {\n  viewer {\n    user {\n      profile {\n        tenant {\n          ...CharacteristicsEditPage_tenant\n          id\n        }\n        id\n      }\n      ...CharacteristicsEditPage_user\n      id\n    }\n  }\n}\n\nfragment CharacteristicsEditPage_tenant on TenantNode {\n  name\n  characteristics {\n    edges {\n      node {\n        id\n        type\n        text\n        shortName\n        showToPanelists\n        importKey\n        elementMeta {\n          randomize\n          responseValidation\n          target\n          target2\n          targetTypes\n        }\n        answers {\n          id\n          text\n          other\n          userSpecified\n        }\n        rows {\n          id\n          text\n        }\n      }\n    }\n  }\n  dId\n  vpmAccountId\n  ...EditCharacteristicActive_tenant\n}\n\nfragment CharacteristicsEditPage_user on UserNode {\n  dId\n  vpmUserId\n  panelist {\n    dId\n    id\n  }\n  ...EditCharacteristicActive_user\n}\n\nfragment EditCharacteristicActive_tenant on TenantNode {\n  dId\n  vpmAccountId\n  name\n}\n\nfragment EditCharacteristicActive_user on UserNode {\n  dId\n  vpmUserId\n  panelist {\n    dId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "634ebaa271c46fe3522dd9db78f03078";

export default node;
