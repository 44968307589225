/**
 * @generated SignedSource<<a2af3ea9edad8f594fc31a27c3bae876>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FormPanelistResponses_panelist$data = {
  readonly id: string;
  readonly selectedResponses: ReadonlyArray<{
    readonly characteristic: {
      readonly id: string;
      readonly text: string | null;
      readonly type: ScreenersCharacteristicTypeChoices;
      readonly " $fragmentSpreads": FragmentRefs<"FormItemCharacteristicResponses_characteristic">;
    };
    readonly characteristicResponses: ReadonlyArray<{
      readonly answer: {
        readonly id: string;
        readonly userSpecified: boolean;
      } | null;
      readonly customAnswer: string | null;
      readonly row: {
        readonly id: string;
      } | null;
      readonly textValue: string;
      readonly " $fragmentSpreads": FragmentRefs<"FormItemCharacteristicResponses_characteristicResponses">;
    }>;
  }>;
  readonly " $fragmentType": "FormPanelistResponses_panelist";
};
export type FormPanelistResponses_panelist$key = {
  readonly " $data"?: FormPanelistResponses_panelist$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormPanelistResponses_panelist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormPanelistResponses_panelist",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SelectedResponse",
      "kind": "LinkedField",
      "name": "selectedResponses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicNode",
          "kind": "LinkedField",
          "name": "characteristic",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormItemCharacteristicResponses_characteristic"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicResponseNode",
          "kind": "LinkedField",
          "name": "characteristicResponses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicAnswerNode",
              "kind": "LinkedField",
              "name": "answer",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userSpecified",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customAnswer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicRowNode",
              "kind": "LinkedField",
              "name": "row",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "textValue",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormItemCharacteristicResponses_characteristicResponses"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PanelistNode",
  "abstractKey": null
};
})();

(node as any).hash = "2f53ba322ca71a762dc32cfebd444e2c";

export default node;
