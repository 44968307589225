import moment from "moment";
import { useContext } from "react";
import styled from "styled-components";
import { antOnRowType, DEFAULT_PANELISTS_FETCH_COUNT, RECRUIT_STATUSES } from "../../constants";
import { TimeZoneContext } from "../../utils";
import { flattenCharacteristics } from "../../utils/misc";
import AntDTable from "../tables/AntDTable";
import { CellInner, EllipsisCell, NameCell, RecruitStatusCell } from "../tables/Cells";

const PanelTable = ({
  data,
  characteristics,
  totalCount,
  page,
  onPageChange,
  loading,
  leftAlign,
  onRow,
  rowSelection,
  recruit,
}: {
  data: any;
  characteristics: any;
  totalCount: number | undefined | null;
  page: number;
  onPageChange: (page: number) => void;
  loading: boolean;
  leftAlign?: boolean;
  onRow?: antOnRowType;
  rowSelection?: any;
  recruit?: any;
}) => {
  const { shiftDate } = useContext(TimeZoneContext);

  const flattenedCharacteristics = flattenCharacteristics(characteristics);

  const columns = () => {
    let columns: any = [
      {
        title: "Name",
        key: "name",
        width: 150,
        dataIndex: ["node", "person"],
        render: (props: any) => NameCell(props),
        fixed: "left",
      },
    ];

    if (recruit && recruit.status !== RECRUIT_STATUSES.NOT_STARTED) {
      columns.push({
        title: "Status",
        key: "recruitStatus",
        width: 160,
        dataIndex: ["node", "recruitStatus"],
        render: (recruitStatus: string) => <RecruitStatusCell status={recruitStatus} />,
      });
    }

    columns.push(
      {
        title: "Panelist ID",
        key: "panelistId",
        width: 150,
        dataIndex: ["node", "dId"],
        render: (id: any) => <div style={{ fontSize: "12px" }}>{id}</div>,
      },
      {
        title: "Email",
        key: "email",
        width: 205,
        dataIndex: ["node", "person", "email"],
        render: (email: any) => <div style={{ fontSize: "12px" }}>{email}</div>,
      },
      {
        title: "Phone Number",
        key: "phoneNumber",
        width: 205,
        dataIndex: ["node", "person", "phoneNumber"],
        render: (phoneNumber: any) => <div style={{ fontSize: "12px" }}>{phoneNumber}</div>,
      },
      {
        title: "Member since",
        key: "membership",
        width: 200,
        dataIndex: ["node", "joined"],
        render: (joined: any) => (
          <div style={{ fontSize: "12px" }}>{shiftDate(moment(joined)).format("MMM D, YYYY")}</div>
        ),
      },
      {
        title: "Completed Studies",
        key: "completedStudiesCount",
        width: 200,
        dataIndex: ["node", "completedStudiesCount"],
        render: (completedStudiesCount: number) => <div style={{ fontSize: "12px" }}>{completedStudiesCount}</div>,
      },
      {
        title: "Last Completed Screener",
        key: "lastCompletedScreener",
        width: 200,
        dataIndex: ["node"],
        render: (panelist: any) => (
          <div style={{ fontSize: "12px" }}>
            {panelist.panelistMembership?.latestCompleteScreenerName ? (
              <EllipsisCell
                value={`${moment(panelist.panelistMembership.latestCompleteScreenerDate).fromNow()}: ${
                  panelist.panelistMembership.latestCompleteScreenerName
                }`}
              />
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        title: "Last Completed Study",
        key: "lastCompletedStudy",
        width: 200,
        dataIndex: ["node"],
        render: (panelist: any) => (
          <div style={{ fontSize: "12px" }}>
            {panelist.panelistMembership?.latestStudyName ? (
              <EllipsisCell
                value={`${moment(panelist.panelistMembership.latestStudyDate).fromNow()}: ${
                  panelist.panelistMembership.latestStudyName
                }`}
              />
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        title: "Last Rating",
        key: "lastRating",
        width: 100,
        dataIndex: ["node"],
        render: (panelist: any) => (
          <CellInner>
            <div style={{ fontSize: "12px" }}>
              <span role="img" aria-label="star-icon">
                ⭐
              </span>
              <span style={{ marginLeft: 6 }}>
                {panelist.panelistMembership?.latestRatedParticipant?.rating || "?"}
              </span>
            </div>
          </CellInner>
        ),
      },
      {
        title: "Points Balance",
        key: "rewardsBalance",
        width: 130,
        dataIndex: ["node", "rewardsBalance"],
        render: (rewardsBalance: number) => <div style={{ fontSize: "12px" }}>{rewardsBalance}</div>,
      },
      ...flattenedCharacteristics
    );

    return columns;
    // eslint-disable-next-line
  };

  return (
    <StyledAntDTable
      rowSelection={rowSelection}
      columns={columns}
      onPageChange={onPageChange}
      data={data}
      loading={loading}
      totalRecords={totalCount || 0}
      page={page}
      alignPaginatorLeft={leftAlign}
      applyLeftPadding={leftAlign}
      onRow={onRow}
      defaultFetchCount={DEFAULT_PANELISTS_FETCH_COUNT}
    />
  );
};

const StyledAntDTable = styled(AntDTable)`
  .ant-layout {
    overflow: hidden;
  }

  table,
  .ant-table-cell,
  .ant-table-header {
    border-radius: 0 !important;
  }

  .ant-table-thead .ant-table-cell {
    white-space: nowrap;
  }
`;

export default PanelTable;
