/**
 * @generated SignedSource<<a3c5bb43637be0839e24ebbf8e00f231>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ParticipantsTableHeader_study$data = {
  readonly dId: any | null;
  readonly export: {
    readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
  } | null;
  readonly id: string;
  readonly name: string | null;
  readonly type: ScreenersStudyTypeChoices;
  readonly usesApiToMarkComplete: boolean;
  readonly " $fragmentType": "ParticipantsTableHeader_study";
};
export type ParticipantsTableHeader_study$key = {
  readonly " $data"?: ParticipantsTableHeader_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantsTableHeader_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ParticipantsTableHeader_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExportNode",
      "kind": "LinkedField",
      "name": "export",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExportButton_export"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usesApiToMarkComplete",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "2702dca9803d500a8db26a2640333663";

export default node;
