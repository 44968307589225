import type { MenuProps } from "antd";
import { App, Button, Dropdown, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import moment from "moment";
import { useContext, useState } from "react";
import { useFragment } from "react-relay";
import { ActionType } from "../../types";

import { mutation, TimeZoneContext, useConfirm } from "../../utils";
import { join, maybeElement } from "../../utils/misc";
import type { ScheduleCell_respondent$key } from "../../__generated__/ScheduleCell_respondent.graphql";
import type { ScheduleCell_study$key } from "../../__generated__/ScheduleCell_study.graphql";
import { ScheduleModal } from "../Slots/ScheduleModal";

import { SCHEDULED_BY, SCHEDULING_TYPE } from "../../constants";
import { CellInner } from "./Cells";

export const ScheduleCell = ({
  study: _study,
  respondent: _respondent,
  refreshData,
}: {
  study: ScheduleCell_study$key;
  respondent: ScheduleCell_respondent$key;
  refreshData?: () => void;
}) => {
  const { modal, notification } = App.useApp();
  const { shiftDate } = useContext(TimeZoneContext);

  const [showModal, setShowModal] = useState(false);

  const study = useFragment<ScheduleCell_study$key>(
    graphql`
      fragment ScheduleCell_study on StudyNode {
        id
        scheduledBy
        schedulingType
        ...ScheduleModal_study
      }
    `,
    _study
  );

  const respondent = useFragment(
    graphql`
      fragment ScheduleCell_respondent on RespondentNode {
        id
        masterStatus
        participant {
          rsvp
        }
        scheduledSlots {
          session {
            name
          }
          start
          end
          interviewed
        }
        ...ScheduleModal_respondent
      }
    `,
    _respondent
  );

  const confirm = useConfirm();

  const handleMarkInterviewedClick = () =>
    modal.confirm({
      title: "Mark as Interviewed",
      content: "By marking this participant as Interviewed, you'll be able to incentivize them.",
      okText: "Mark as Interviewed",
      onOk: async () =>
        mutation({
          variables: { input: { respondentId: respondent.id } },
          mutation: graphql`
            mutation ScheduleCell_MarkRespondentInterviewed_Mutation($input: MarkRespondentInterviewedInput!) {
              markRespondentInterviewed(input: $input) {
                respondent {
                  approvalStatus
                  masterStatus
                  screener {
                    study {
                      approvedCount
                      needsPaymentCount
                    }
                  }
                }
              }
            }
          `,
        }).then(() => {
          notification.success({ message: "Participant marked as Interviewed" });
          refreshData?.();
        }),
    });

  const handleDeclineClick = () =>
    confirm({
      title: "Decline Respondent",
      content: (
        <>
          <p>Do you wish to decline this respondent?</p>
          {!!respondent.scheduledSlots.length && (
            <p>As they've already received an event invitation, this will send a cancellation update.</p>
          )}
        </>
      ),
      okText: "Yes",
      onOk: async () => {
        await mutation({
          variables: {
            input: {
              studyId: study.id,
              respondentId: respondent.id,
            },
          },
          mutation: graphql`
            mutation ScheduleCell_denyRespondent_Mutation($input: DenyRespondentInput!) {
              denyRespondent(input: $input) {
                respondent {
                  id
                  masterStatus
                }
                study {
                  approvedTabCount
                  scheduledCount
                  needsReviewCount
                  rejectedCount
                }
              }
            }
          `,
        });
        refreshData?.();
        notification.success({ message: "Participant declined" });
      },
    });

  const handleInvitedToScheduleClick = () =>
    modal.info({
      title: "Invitation to schedule sent",
      content:
        study.schedulingType === SCHEDULING_TYPE.HUBUX ? (
          <>
            <p>This participant has been invited to schedule.</p>
            <p>
              You can wait for them to choose a slot, manually schedule them, mark them as interviewed, or decline them.
            </p>
          </>
        ) : (
          <>
            <p>This participant has been invited to schedule.</p>
            <p>You can wait for them to choose a slot, mark them as interviewed, or decline them.</p>
          </>
        ),
    });
  const handleScheduleClick = () => setShowModal(true);

  type MenuItem = NonNullable<MenuProps["items"]>[number];
  const menuItemSchedule: MenuItem = {
    key: "schedule",
    label: respondent.scheduledSlots.length ? "Re-schedule" : "Schedule",
  };
  const menuItemMarkInterviewed: MenuItem = { key: "mark-interviewed", label: "Mark as Interviewed" };
  const menuItemDecline: MenuItem = { key: "decline", label: "Decline participant" };

  const primaryActionIsSchedule =
    study.schedulingType === SCHEDULING_TYPE.HUBUX &&
    (!!respondent.scheduledSlots.length || study.scheduledBy === SCHEDULED_BY.YOUR_TEAM);

  const menu: MenuProps = {
    items: [
      ...maybeElement(!primaryActionIsSchedule && study.schedulingType === SCHEDULING_TYPE.HUBUX, menuItemSchedule),
      menuItemMarkInterviewed,
      menuItemDecline,
    ],

    onClick: menuItem => {
      switch (menuItem.key) {
        case menuItemSchedule.key:
          handleScheduleClick();
          break;

        case menuItemMarkInterviewed.key:
          handleMarkInterviewedClick();
          break;

        case menuItemDecline.key:
          handleDeclineClick();
          break;

        default:
          break;
      }
    },
  };

  return (
    <CellInner width="100%">
      <ScheduleModal
        study={study}
        respondent={respondent}
        showModal={showModal}
        setShowModal={setShowModal}
        refreshData={refreshData}
      />
      <Tooltip
        trigger={[...maybeElement<ActionType>(!!respondent.scheduledSlots.length, "hover")]}
        overlayStyle={{ maxWidth: 350 }}
        title={join(
          [...respondent.scheduledSlots],
          s => (
            <>
              {s?.session?.name ?? "<Unnamed session>"}: {shiftDate(moment(s?.start)).format("M/D/YY h:mm A")} -{" "}
              {shiftDate(moment(s?.end)).format("h:mm A")} {s?.interviewed && " (interviewed)"}
            </>
          ),
          <br />
        )}
      >
        <Dropdown.Button
          menu={menu}
          trigger={["click"]}
          size="small"
          style={{ width: "100%" }}
          buttonsRender={buttons => [
            study.schedulingType === SCHEDULING_TYPE.HUBUX ? (
              <Button
                block
                style={{ textAlign: "left", width: "100%" }}
                onClick={primaryActionIsSchedule ? handleScheduleClick : handleInvitedToScheduleClick}
              >
                {respondent.scheduledSlots.length > 1
                  ? `${respondent.scheduledSlots.length} sessions`
                  : respondent.scheduledSlots.length === 1
                  ? `${shiftDate(moment(respondent.scheduledSlots[0]!.start)).format("ddd, MMM D H:mm")} - ${shiftDate(
                      moment(respondent.scheduledSlots[0]!.end)
                    ).format("H:mm")}`
                  : primaryActionIsSchedule
                  ? "Schedule"
                  : "Invitation to schedule sent"}
              </Button>
            ) : (
              <Button block style={{ textAlign: "left", width: "100%" }} onClick={handleInvitedToScheduleClick}>
                Invitation to schedule sent
              </Button>
            ),
            ...buttons.slice(1),
          ]}
        />
      </Tooltip>
    </CellInner>
  );
};
