/**
 * @generated SignedSource<<d07488444adf7147c2b6ca2897744f1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationsPoller_remindersQuery$variables = {};
export type NotificationsPoller_remindersQuery$data = {
  readonly viewer: {
    readonly notifications: {
      readonly studiesNeedingApprovals: ReadonlyArray<{
        readonly approvalsNeeded: number;
        readonly study: {
          readonly id: string;
          readonly name: string | null;
        };
      }> | null;
      readonly studiesNeedingIncentives: ReadonlyArray<{
        readonly incentivesNeeded: number;
        readonly study: {
          readonly id: string;
          readonly name: string | null;
        };
      }> | null;
    } | null;
  };
};
export type NotificationsPoller_remindersQuery = {
  response: NotificationsPoller_remindersQuery$data;
  variables: NotificationsPoller_remindersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "StudyNode",
  "kind": "LinkedField",
  "name": "study",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationsNode",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudiesNeedingApprovalsNode",
            "kind": "LinkedField",
            "name": "studiesNeedingApprovals",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalsNeeded",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StudiesNeedingIncentivesNode",
            "kind": "LinkedField",
            "name": "studiesNeedingIncentives",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incentivesNeeded",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsPoller_remindersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsPoller_remindersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0d837b72edc021c1fd175a6143e8d54b",
    "id": null,
    "metadata": {},
    "name": "NotificationsPoller_remindersQuery",
    "operationKind": "query",
    "text": "query NotificationsPoller_remindersQuery {\n  viewer {\n    notifications {\n      studiesNeedingApprovals {\n        study {\n          id\n          name\n        }\n        approvalsNeeded\n      }\n      studiesNeedingIncentives {\n        study {\n          id\n          name\n        }\n        incentivesNeeded\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3052ca64d18d5ace112060f2a7f497ac";

export default node;
