/**
 * @generated SignedSource<<47202eb9bd5371b0aa4c1cfd8f8ca127>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
export type RespondentResponsesQuery$variables = {
  respondentId?: string | null;
  studyId?: string | null;
};
export type RespondentResponsesQuery$data = {
  readonly viewer: {
    readonly study: {
      readonly screener: {
        readonly elements: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly dbId: string;
              readonly label: string | null;
              readonly respondentAnswer: ReadonlyArray<{
                readonly row: {
                  readonly text: string;
                } | null;
                readonly selectedAnswers: {
                  readonly edges: ReadonlyArray<{
                    readonly node: {
                      readonly answer: {
                        readonly other: boolean;
                        readonly userSpecified: boolean;
                      };
                      readonly customAnswer: string | null;
                    } | null;
                  } | null>;
                };
                readonly value: string;
              } | null> | null;
              readonly text: string | null;
              readonly type: ScreenersElementTypeChoices;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null;
  };
};
export type RespondentResponsesQuery = {
  response: RespondentResponsesQuery$data;
  variables: RespondentResponsesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "respondentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studyId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "studyId",
    "variableName": "studyId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "respondentId",
    "variableName": "respondentId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSpecified",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAnswer",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RespondentResponsesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ElementNodeConnection",
                    "kind": "LinkedField",
                    "name": "elements",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ElementNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ElementNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": (v7/*: any*/),
                                "concreteType": "ElementResponseNode",
                                "kind": "LinkedField",
                                "name": "respondentAnswer",
                                "plural": true,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RowNode",
                                    "kind": "LinkedField",
                                    "name": "row",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ElementResponseAnswerNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "selectedAnswers",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ElementResponseAnswerNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ElementResponseAnswerNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AnswerNode",
                                                "kind": "LinkedField",
                                                "name": "answer",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  (v10/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v11/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RespondentResponsesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ElementNodeConnection",
                    "kind": "LinkedField",
                    "name": "elements",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ElementNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ElementNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": (v7/*: any*/),
                                "concreteType": "ElementResponseNode",
                                "kind": "LinkedField",
                                "name": "respondentAnswer",
                                "plural": true,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RowNode",
                                    "kind": "LinkedField",
                                    "name": "row",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v12/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ElementResponseAnswerNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "selectedAnswers",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ElementResponseAnswerNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ElementResponseAnswerNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AnswerNode",
                                                "kind": "LinkedField",
                                                "name": "answer",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  (v10/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v11/*: any*/),
                                              (v12/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45656057f926b47f6e6ad8be029afa7b",
    "id": null,
    "metadata": {},
    "name": "RespondentResponsesQuery",
    "operationKind": "query",
    "text": "query RespondentResponsesQuery(\n  $studyId: String\n  $respondentId: String\n) {\n  viewer {\n    study(studyId: $studyId) {\n      screener {\n        elements {\n          edges {\n            node {\n              dbId\n              text\n              label\n              type\n              respondentAnswer(respondentId: $respondentId) {\n                value\n                row {\n                  text\n                  id\n                }\n                selectedAnswers {\n                  edges {\n                    node {\n                      answer {\n                        other\n                        userSpecified\n                        id\n                      }\n                      customAnswer\n                      id\n                    }\n                  }\n                }\n                id\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "090ba6531e65c8a4485d91123f1d720e";

export default node;
