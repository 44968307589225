/**
 * @generated SignedSource<<a5f903b86aefd54bbe24d36d5023a100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersPersonCountryChoices = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Portal_user$data = {
  readonly panelist: {
    readonly customPanelistPortal: {
      readonly contactEmail: string | null;
      readonly customColor: string | null;
      readonly fadeBgBottom: boolean;
      readonly faviconUrl: string | null;
      readonly footerImageUrl: string | null;
      readonly headerMarkup: string;
      readonly headerMarkupEnabled: boolean;
      readonly heroAssetUrl: string | null;
      readonly heroBackgroundUrl: string | null;
      readonly hidePanelistProfile: boolean;
      readonly hidePanelistProfileCompleteBanner: boolean;
      readonly pageTitle: string | null;
      readonly privacyPolicyUrl: string | null;
      readonly tenant: {
        readonly id: string;
        readonly name: string;
        readonly portalCards: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly title: string | null;
              readonly " $fragmentSpreads": FragmentRefs<"PortalCard_portalCard">;
            } | null;
          } | null>;
        };
      };
      readonly termsLabel: string;
      readonly termsUrl: string | null;
    } | null;
    readonly dId: any | null;
    readonly person: {
      readonly country: ScreenersPersonCountryChoices;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly selectedResponses: ReadonlyArray<{
      readonly answers: ReadonlyArray<{
        readonly key: string;
        readonly selected: boolean;
      }>;
      readonly characteristic: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AvailableStudies_user" | "PortalCard_user" | "YourHistory_user" | "YourProfile_user">;
  readonly " $fragmentType": "Portal_user";
};
export type Portal_user$key = {
  readonly " $data"?: Portal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Portal_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Portal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customPanelistPortal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heroAssetUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heroBackgroundUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "footerImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fadeBgBottom",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "privacyPolicyUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "termsLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "termsUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "faviconUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pageTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerMarkupEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headerMarkup",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePanelistProfile",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePanelistProfileCompleteBanner",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortalCardNodeConnection",
                  "kind": "LinkedField",
                  "name": "portalCards",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PortalCardNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PortalCardNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "PortalCard_portalCard"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonNode",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SelectedResponse",
          "kind": "LinkedField",
          "name": "selectedResponses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedResponseAnswer",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selected",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicNode",
              "kind": "LinkedField",
              "name": "characteristic",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "YourProfile_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "YourHistory_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvailableStudies_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortalCard_user"
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "0ac524e38549f740a32df37102324ab0";

export default node;
