/*
 * Component displays all Study responses for a single respondent in a table,
 * with the Audition question and answer at the top
 */
import { DownloadOutlined } from "@ant-design/icons";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useUnmountEffect } from "@react-hookz/web";
import { Button, Table, Tooltip } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { getType } from "mime/lite";
import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { QueryRenderer, useFragment } from "react-relay";
import styled from "styled-components";

import { ButtonLabel, Label } from "..";
import { ELEMENT_TYPES, VIDEO_PREFIX } from "../../constants";
import { environment } from "../../relay";
import { BORDER_RADIUS, CONTAINER_BOX_SHADOW, PAGE_WIDTH_2XS } from "../../style";
import { downloadInBackground } from "../../utils/misc";
import { getS3DownloadUrl, getS3KeyFromUrl } from "../../utils/s3Download";
import { RespondentResponsesQuery$data } from "../../__generated__/RespondentResponsesQuery.graphql";
import { RespondentResponses_ModalContent_respondent$key } from "../../__generated__/RespondentResponses_ModalContent_respondent.graphql";
import { RespondentResponses_respondent$key } from "../../__generated__/RespondentResponses_respondent.graphql";
import Title from "../Title";

type RendererProps = {
  error: Error | null;
  props: RespondentResponsesQuery$data | any;
};

type EmbedType = "audio" | "image" | "video";

const Embed = ({
  type,
  unsignedSrc,
  style,
  downloadFilename,
}: {
  type: EmbedType;
  unsignedSrc: string;
  style?: CSSProperties;
  downloadFilename?: string;
}) => {
  const [objectUrl, setObjectUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useUnmountEffect(() => {
    if (objectUrl) window.URL.revokeObjectURL(objectUrl);
  });

  const loadContent = async () => {
    setIsLoading(true);
    try {
      const s3Key = getS3KeyFromUrl(unsignedSrc);
      const presignedUrl = await getS3DownloadUrl(s3Key);
      const response = await axios.get(presignedUrl, { responseType: "blob" });
      setObjectUrl(window.URL.createObjectURL(response.data));
    } finally {
      setIsLoading(false);
    }
  };

  if (!objectUrl)
    return (
      <ButtonLabel
        type="link"
        onClick={loadContent}
        disabled={isLoading}
        icon="mdi:play"
        loading={isLoading}
        text="Show"
      />
    );

  let player: ReactNode;

  switch (type) {
    case "audio":
      player = <audio src={objectUrl} controls style={style} />;
      break;

    case "image":
      player = <img src={objectUrl} alt="Respondent input" style={style} />;
      break;

    case "video":
      player = <video src={objectUrl} controls style={style} />;
      break;
  }

  return player ? (
    <>
      {player}
      {downloadFilename && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={`Download ${type}`}>
            <Button style={{ marginTop: "10px" }} onClick={() => downloadInBackground(objectUrl, downloadFilename)}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  ) : null;
};

const ModalContent = (props: {
  data: RespondentResponsesQuery$data;
  respondent: RespondentResponses_ModalContent_respondent$key;
  scrollRowIndex: number | null;
}) => {
  const { router } = useRouter();

  const respondent = useFragment(
    graphql`
      fragment RespondentResponses_ModalContent_respondent on RespondentNode {
        dId
        rhAnswer
      }
    `,
    props.respondent
  );

  // scrollRowIndex is the row in the table to scroll to
  const { data, scrollRowIndex } = props;

  const getDownloadPageUrl = (unsignedUrl: string): string => {
    const env = runtimeEnv();
    const downloadUrl = new URL(`${env.REACT_APP_FRONTEND_HOSTNAME}${router.createHref("/downloads")}`);
    downloadUrl.searchParams.set("key", getS3KeyFromUrl(unsignedUrl));
    return downloadUrl.toString();
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: "30%",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },
  ];

  // Maps over all questions in the study and returns an object with the
  // correctly formatted question text and answers in a component or text
  // dependent on question format
  const dataSource = data?.viewer?.study?.screener?.elements?.edges?.map((element, i) => {
    // Formats text for "other" options like "Other: (user specified text)"
    const formatCustomAnswer = (respondentAnswer: any, multiselectText?: string) => {
      const answerNode = respondentAnswer?.selectedAnswers?.edges?.find(
        (ans: any) => ans.node?.answer?.other && ans.node.answer.userSpecified
      )?.node;
      return answerNode?.answer?.other && answerNode.answer.userSpecified && answerNode.customAnswer ? (
        <>
          {multiselectText ?? respondentAnswer.value}: <em>{answerNode.customAnswer}</em>
        </>
      ) : (
        multiselectText ?? respondentAnswer?.value
      );
    };

    let answerValue: React.ReactNode;
    switch (element?.node?.type) {
      case ELEMENT_TYPES.AUDITION_VIDEO:
      case ELEMENT_TYPES.VIDEO:
        if (element.node?.respondentAnswer?.[0]?.value) {
          answerValue = (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={getDownloadPageUrl(element.node.respondentAnswer[0].value)}
            >
              View
            </a>
          );
        }
        break;
      case ELEMENT_TYPES.GRID_SINGLE_SELECT:
        answerValue = (
          <div>
            {element.node?.respondentAnswer?.map((answer: any, j: number) => {
              return (
                <div key={j}>
                  {answer?.row?.text}: {formatCustomAnswer(answer)}
                </div>
              );
            })}
          </div>
        );
        break;
      case ELEMENT_TYPES.MULTI_SELECT:
        const answer = element.node?.respondentAnswer?.[0];
        const v = answer?.value ?? "";
        answerValue = (
          <>
            {v.split("|").map((x, i) => (
              <>
                {
                  // add comma separators
                  i > 0 ? "," : ""
                }
                {answer?.selectedAnswers.edges[i]?.node?.answer?.other &&
                answer.selectedAnswers.edges[i]?.node?.answer?.userSpecified
                  ? formatCustomAnswer(answer, x)
                  : x}
              </>
            ))}
          </>
        );
        break;
      case ELEMENT_TYPES.FILES:
        answerValue = (
          <FilesContainer>
            {element.node?.respondentAnswer?.[0]?.value
              ?.split("|")
              .map(x => x.trim())
              .map(x => {
                const [type, subType] = getType(x)?.split("/") ?? (["", ""] as const);
                return (
                  <p key={x}>
                    {["audio", "image", "video"].includes(type) ? (
                      <Embed type={type as EmbedType} unsignedSrc={x} />
                    ) : (
                      <a href={getDownloadPageUrl(x)} target="_blank" rel="noopener noreferrer">
                        <Label
                          icon="mdi:launch"
                          text={
                            <>
                              Open <code style={{ fontWeight: 700 }}>{subType}</code> file
                            </>
                          }
                        ></Label>
                      </a>
                    )}
                  </p>
                );
              })}
          </FilesContainer>
        );
        break;
      case ELEMENT_TYPES.RANK:
        const answers: string[] = element.node.respondentAnswer?.[0]?.value?.split("|").map(x => x.trim()) ?? [];

        answerValue = !!answers.length ? (
          <ol style={{ marginInlineStart: answers.length >= 10 ? -16 : -24, marginBlock: 0 }}>
            {answers.map(x => (
              <li>{x}</li>
            ))}
          </ol>
        ) : (
          "No answer"
        );
        break;
      default:
        answerValue = formatCustomAnswer(element?.node?.respondentAnswer?.[0]);
    }

    return {
      key: i,
      question: element?.node?.label ? `${element.node.label}. ${element.node.text}` : element?.node?.text,
      answer: answerValue,
    };
  });

  useEffect(() => {
    // If a row was specified in scrollToRowIndex, this scrolls to the row specified in the table
    document.querySelector(".scroll-row")?.scrollIntoView();
  }, [dataSource]);

  const auditionQuestion = data?.viewer?.study?.screener?.elements?.edges?.find(
    (element: any) =>
      element.node.type === ELEMENT_TYPES.AUDITION_VIDEO || element.node.type === ELEMENT_TYPES.AUDITION_TEXT
  );

  return (
    <>
      <Title>{auditionQuestion ? auditionQuestion?.node?.text : "There was no audition question in this study"}</Title>
      {respondent.rhAnswer?.startsWith(VIDEO_PREFIX) ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div>
            <Embed
              type="video"
              unsignedSrc={respondent.rhAnswer.slice(VIDEO_PREFIX.length)}
              style={{
                borderRadius: BORDER_RADIUS,
                boxShadow: CONTAINER_BOX_SHADOW,
                outline: "none",
              }}
              downloadFilename={`${respondent.dId}${respondent.rhAnswer.includes(".webm") ? ".webm" : ".mp4"}`}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "10px" }}>{respondent?.rhAnswer}</div>
      )}

      <div style={{ paddingTop: "4rem" }}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowClassName={(record, index) => (index === scrollRowIndex ? "scroll-row" : "")}
        />
      </div>
    </>
  );
};

const RespondentResponses = ({
  respondent: _respondent,
  studyId,
  scrollRowIndex,
}: {
  respondent: RespondentResponses_respondent$key;
  studyId: String;
  scrollRowIndex?: number | null;
}) => {
  const respondent = useFragment(
    graphql`
      fragment RespondentResponses_respondent on RespondentNode {
        id
        ...RespondentResponses_ModalContent_respondent
      }
    `,
    _respondent
  );

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query RespondentResponsesQuery($studyId: String, $respondentId: String) {
          viewer {
            study(studyId: $studyId) {
              screener {
                elements {
                  edges {
                    node {
                      dbId
                      text
                      label
                      type
                      respondentAnswer(respondentId: $respondentId) {
                        value
                        row {
                          text
                        }
                        selectedAnswers {
                          edges {
                            node {
                              answer {
                                other
                                userSpecified
                              }
                              customAnswer
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      variables={{
        studyId: studyId,
        respondentId: respondent.id,
      }}
      render={({ error, props }: RendererProps) => {
        if (error) {
          console.log(error);
          return "";
        }
        return <ModalContent data={props} respondent={respondent} scrollRowIndex={scrollRowIndex || null} />;
      }}
    />
  );
};

const FilesContainer = styled.div`
  audio,
  img,
  video {
    display: block;
    min-width: 120px;
    max-width: 100%;
  }

  img,
  video {
    min-height: 120px;
    max-height: ${PAGE_WIDTH_2XS};
  }
`;

export default RespondentResponses;
