/**
 * @generated SignedSource<<eebf3dce25740c212df345f0d2fbfe96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SingleChoiceElement_element$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
  readonly " $fragmentType": "SingleChoiceElement_element";
};
export type SingleChoiceElement_element$key = {
  readonly " $data"?: SingleChoiceElement_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"SingleChoiceElement_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SingleChoiceElement_element",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "170bbf8cab3afa3e1a9cda162036f101";

export default node;
