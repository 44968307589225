/**
 * @generated SignedSource<<da40c663e604d1045df9eba783516db0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Segment_segment$data = {
  readonly condition: any | null;
  readonly count: number | null;
  readonly id: string;
  readonly quota: number | null;
  readonly terminate: boolean;
  readonly text: string | null;
  readonly " $fragmentType": "Segment_segment";
};
export type Segment_segment$key = {
  readonly " $data"?: Segment_segment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Segment_segment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Segment_segment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "condition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quota",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminate",
      "storageKey": null
    }
  ],
  "type": "SegmentNode",
  "abstractKey": null
};

(node as any).hash = "19a4aba83b11249458d36065a6979bd7";

export default node;
