/**
 * @generated SignedSource<<b3d13967e1c899f0fe5a480688dd9b98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertRowsInput = {
  clientMutationId?: string | null;
  elementId: string;
  position: number;
  rows: ReadonlyArray<string>;
};
export type GridQuestionEditor_insertRows_Mutation$variables = {
  input: InsertRowsInput;
};
export type GridQuestionEditor_insertRows_Mutation$data = {
  readonly insertRows: {
    readonly element: {
      readonly gridAnswers: ReadonlyArray<{
        readonly answer: {
          readonly dbId: string;
          readonly id: string;
        };
        readonly row: {
          readonly dbId: string;
          readonly id: string;
        };
        readonly terminate: boolean | null;
      } | null> | null;
      readonly rows: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly dbId: string;
            readonly id: string;
            readonly position: number;
            readonly text: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type GridQuestionEditor_insertRows_Mutation = {
  response: GridQuestionEditor_insertRows_Mutation$data;
  variables: GridQuestionEditor_insertRows_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "RowNodeConnection",
  "kind": "LinkedField",
  "name": "rows",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RowNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "RowNode",
  "kind": "LinkedField",
  "name": "row",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AnswerNode",
  "kind": "LinkedField",
  "name": "answer",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GridQuestionEditor_insertRows_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InsertRowsPayload",
        "kind": "LinkedField",
        "name": "insertRows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GridAnswerNode",
                "kind": "LinkedField",
                "name": "gridAnswers",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GridQuestionEditor_insertRows_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InsertRowsPayload",
        "kind": "LinkedField",
        "name": "insertRows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GridAnswerNode",
                "kind": "LinkedField",
                "name": "gridAnswers",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f1bdf3d6dd27b2a4cfac7cc83e51f7a",
    "id": null,
    "metadata": {},
    "name": "GridQuestionEditor_insertRows_Mutation",
    "operationKind": "mutation",
    "text": "mutation GridQuestionEditor_insertRows_Mutation(\n  $input: InsertRowsInput!\n) {\n  insertRows(input: $input) {\n    element {\n      rows {\n        edges {\n          node {\n            id\n            dbId\n            text\n            position\n          }\n        }\n      }\n      gridAnswers {\n        row {\n          id\n          dbId\n        }\n        answer {\n          id\n          dbId\n        }\n        terminate\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "744b38b07f808652823f0549f662faf4";

export default node;
