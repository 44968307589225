import { ExportOutlined } from "@ant-design/icons";
import { App, Button, Tooltip } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import moment from "moment";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";

import { FUTURE_PROOF } from "../constants";
import { ExportStatus } from "../schema";
import { handleErrorWithMessage, onError, useMutation } from "../utils";
import { downloadInBackground } from "../utils/misc";
import type { ExportButton_CreateExport_Mutation } from "../__generated__/ExportButton_CreateExport_Mutation.graphql";
import type { ExportButton_export$data, ExportButton_export$key } from "../__generated__/ExportButton_export.graphql";

import { notifyExport } from "./Notifications/Notification";

export const ExportButton = ({
  disabled = false,
  export: exportKey,
  immediate = false,
  immediateFilename,
  immediateUrl,
  filters = {},
  relatedId,
}: {
  disabled?: boolean;
  export: ExportButton_export$key | null;
  filters?: Record<string, any>;
  relatedId: string; // gid
} & (
  | {
      immediate?: false;
      immediateFilename?: undefined;
      immediateUrl?: undefined;
    }
  | {
      immediate: boolean;
      immediateFilename: string;
      immediateUrl: string;
    }
)) => {
  const { status, ...data } =
    useFragment(
      graphql`
        fragment ExportButton_export on ExportNode {
          id
          countCompleted
          countTotal
          sentOn
          sentTo
          status
          to {
            email
          }
        }
      `,
      exportKey
    ) ?? ({} as Partial<ExportButton_export$data>);

  const { notification, message } = App.useApp();

  const [_commit, commitIsInFlight] = useMutation<ExportButton_CreateExport_Mutation>(graphql`
    mutation ExportButton_CreateExport_Mutation($input: CreateExportInput!) {
      createExport(input: $input) {
        export {
          ...ExportButton_export
          to {
            email
          }
        }
        panel {
          export {
            id
          }
        }
        recruit {
          export {
            id
          }
        }
        study {
          export {
            id
          }
        }
      }
    }
  `);
  const commit = useCallback(
    () =>
      _commit(
        { filters, id: relatedId },
        {
          onCompleted: res =>
            notification.success({
              message: `Your export is processing and will be sent to ${res.createExport.export.to.email} when complete.`,
            }),
        }
      ),
    [_commit, filters, notification, relatedId]
  );

  const [requestIsInFlight, setRequestIsInFlight] = useState(false);
  const loading = useMemo(
    () =>
      commitIsInFlight ||
      requestIsInFlight ||
      [ExportStatus.Completed, ExportStatus.InProgress].includes(status as ExportStatus),
    [commitIsInFlight, requestIsInFlight, status]
  );

  const Inner = useCallback(
    ({ children }: { children: ReactNode }) => (
      <Button
        icon={<ExportOutlined />}
        type="text"
        loading={loading}
        disabled={disabled || loading}
        onClick={async () => {
          if (immediate) {
            setRequestIsInFlight(true);
            try {
              await downloadInBackground(immediateUrl!, `${immediateFilename!}.csv`);
              notifyExport(notification);
            } catch (e) {
              if (axios.isAxiosError(e) && e.response) {
                if (e.response.status === 403) commit();
                else handleErrorWithMessage(e, `Something went wrong (HTTP error code ${e.response.status})`, message);
              } else onError(e, message);
            } finally {
              setRequestIsInFlight(false);
            }
          } else commit();
        }}
      >
        {children}
      </Button>
    ),
    [loading, disabled, immediate, commit, immediateUrl, immediateFilename, notification, message]
  );

  return status ? (
    <Tooltip
      getPopupContainer={trigger => trigger.parentElement!}
      title={
        (
          {
            [ExportStatus.InProgress]: `Your export is in progress. When it's ready, we'll send it to ${data.to?.email}.`,
            [ExportStatus.Completed]: `Your export is ready and will be sent to ${data.to?.email} in a moment.`,
            [ExportStatus.Sent]: `Your recent export was sent to ${data.sentTo} at ${moment(data.sentOn).format(
              "h:mma dddd"
            )}.`,
            [ExportStatus.Failed]: "Your recent export failed. Reach out if this issue persists.",
            [FUTURE_PROOF]: "Something went wrong",
          } as const
        )[status]
      }
    >
      <div>
        <Inner>
          {
            (
              {
                [ExportStatus.InProgress]: `Exporting (${data.countCompleted!}/${data.countTotal!})`,
                [ExportStatus.Completed]: "Sending export",
                [ExportStatus.Sent]: "Export",
                [ExportStatus.Failed]: "Retry export",
                [FUTURE_PROOF]: "Export",
              } as const
            )[status]
          }
        </Inner>
      </div>
    </Tooltip>
  ) : (
    <Inner>Export</Inner>
  );
};
