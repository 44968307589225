import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import moment, { type Moment } from "moment";
import type { ChangeEvent, ChangeEventHandler, InputHTMLAttributes } from "react";
import { useFragment } from "react-relay";

import { ScreenersCharacteristicTypeChoices } from "../../../schema";
import type { InputCharacteristicResponses_characteristic$key } from "../../../__generated__/InputCharacteristicResponses_characteristic.graphql";
import type { InputCharacteristicResponses_characteristicResponses$key } from "../../../__generated__/InputCharacteristicResponses_characteristicResponses.graphql";
import { InputDate } from "../InputDate";

import { InputCharacteristicResponseDate } from "./InputCharacteristicResponseDate";
import { InputCharacteristicResponseGridSingleSelect } from "./InputCharacteristicResponseGridSingleSelect";
import { InputCharacteristicResponseMultiSelect } from "./InputCharacteristicResponseMultiSelect";
import { InputCharacteristicResponseNumber } from "./InputCharacteristicResponseNumber";
import { InputCharacteristicResponseOpenEnd } from "./InputCharacteristicResponseOpenEnd";
import { InputCharacteristicResponseSingleSelect } from "./InputCharacteristicResponseSingleSelect";

type InputDateProps = Parameters<typeof InputDate>[0];
type Value = InputHTMLAttributes<any>["value"] | Record<string, string> | InputDateProps["value"];

type OnChangeValue<T extends { onChange?: (...args: any) => any | void }> = Parameters<T["onChange"] & {}>[0];

export const InputCharacteristicResponses = <
  T extends Value,
  C extends T | OnChangeValue<InputHTMLAttributes<InputHTMLAttributes<any>["value"]>> | OnChangeValue<InputDateProps> =
    | T
    | OnChangeValue<InputHTMLAttributes<InputHTMLAttributes<any>["value"]>>
    | OnChangeValue<InputDateProps>
>({
  characteristic: characteristicKey,
  characteristicResponses: characteristicResponsesKey,
  className,
  onChange,
  value,
}: {
  characteristic: InputCharacteristicResponses_characteristic$key;
  characteristicResponses: InputCharacteristicResponses_characteristicResponses$key;
  className?: Argument;
  onChange?: (value: C | ChangeEvent) => void;
  value?: T;
}) => {
  const { type: typ, ...characteristic } = useFragment(
    graphql`
      fragment InputCharacteristicResponses_characteristic on CharacteristicNode {
        type
        ...InputCharacteristicResponseDate_characteristic
        ...InputCharacteristicResponseGridSingleSelect_characteristic
        ...InputCharacteristicResponseMultiSelect_characteristic
        ...InputCharacteristicResponseNumber_characteristic
        ...InputCharacteristicResponseOpenEnd_characteristic
        ...InputCharacteristicResponseSingleSelect_characteristic
      }
    `,
    characteristicKey
  );
  const characteristicResponses = useFragment(
    graphql`
      fragment InputCharacteristicResponses_characteristicResponses on CharacteristicResponseNode @relay(plural: true) {
        ...InputCharacteristicResponseDate_characteristicResponses
        ...InputCharacteristicResponseGridSingleSelect_characteristicResponses
        ...InputCharacteristicResponseMultiSelect_characteristicResponses
        ...InputCharacteristicResponseNumber_characteristicResponses
        ...InputCharacteristicResponseOpenEnd_characteristicResponses
        ...InputCharacteristicResponseSingleSelect_characteristicResponses
      }
    `,
    characteristicResponsesKey
  );

  return typ === ScreenersCharacteristicTypeChoices.Dt ? (
    <InputCharacteristicResponseDate
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      onChange={onChange as Parameters<typeof InputCharacteristicResponseDate>[0]["onChange"]}
      value={typeof value === "string" ? moment(value) : (value as Moment)}
    />
  ) : typ === ScreenersCharacteristicTypeChoices.Gs ? (
    <InputCharacteristicResponseGridSingleSelect
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      className={classNames(className)}
      onChange={onChange as (value: Record<string, string>) => void}
      value={(value ?? {}) as Record<string, string>}
    />
  ) : typ === ScreenersCharacteristicTypeChoices.Ms ? (
    <InputCharacteristicResponseMultiSelect
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      onChange={onChange as (value: string[] | undefined) => void}
      value={(value ?? []) as string[]}
    />
  ) : typ === ScreenersCharacteristicTypeChoices.Nm ? (
    <InputCharacteristicResponseNumber
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      onChange={onChange as (value: number | string | null) => void}
      value={(value ?? undefined) as number | undefined}
    />
  ) : typ === ScreenersCharacteristicTypeChoices.Oe ? (
    <InputCharacteristicResponseOpenEnd
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      onChange={onChange as ChangeEventHandler<HTMLTextAreaElement>}
      value={(value ?? undefined) as string | undefined}
    />
  ) : typ === ScreenersCharacteristicTypeChoices.Ss ? (
    <InputCharacteristicResponseSingleSelect
      characteristic={characteristic}
      characteristicResponses={characteristicResponses}
      onChange={onChange as (value: string) => void}
      value={(value ?? undefined) as string | undefined}
    />
  ) : null;
};
