import { Button, Popover } from "antd";
import { useState, type ReactNode } from "react";
import type { Asyncify, SetOptional } from "type-fest";

import { ButtonLabel, DrawerHeader } from "..";

type OnClick = Parameters<typeof Button>[0]["onClick"] & {};

export const DrawerHeaderFilters = ({
  extraRow,
  filtersValid,
  loading,
  onApply,
  onClose,
  onSave,
  saveDisabled,
  savePopoverContent,
  saving,
}: SetOptional<Pick<Parameters<typeof DrawerHeader>[0], "extraRow" | "onClose">, "extraRow"> & {
  filtersValid: boolean;
  loading: boolean;
  onApply: OnClick;
  onSave: OnClick | Asyncify<OnClick>;
  saveDisabled: boolean;
  savePopoverContent?: ReactNode;
  saving: boolean;
}) => {
  const [saveFilterPopupVisible, setSaveFilterPopupVisible] = useState(false);

  return (
    <DrawerHeader
      actions={
        <>
          <Popover
            content={
              <div style={{ display: "grid", gap: 16 }}>
                {savePopoverContent}
                <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
                  <Button onClick={() => setSaveFilterPopupVisible(false)} size="small">
                    Cancel
                  </Button>
                  <Button
                    disabled={saveDisabled}
                    loading={saving}
                    onClick={async e => {
                      try {
                        // @ts-expect-error `ButtonLabel.onClick` is internally inferred as an intersection instead of a union
                        await onSave(e);
                        setSaveFilterPopupVisible(false);
                      } catch {}
                    }}
                    size="small"
                    type="primary"
                  >
                    Save
                  </Button>
                </div>
              </div>
            }
            trigger={loading || !filtersValid ? [] : "click"}
            placement="left"
            open={saveFilterPopupVisible}
            onOpenChange={visible => setSaveFilterPopupVisible(visible)}
            overlayStyle={{ width: "400px" }}
            overlayClassName="save-filter-popover"
            showArrow={false}
          >
            <ButtonLabel disabled={loading || !filtersValid} icon="mdi:content-save-all-outline" />
          </Popover>
          <Button disabled={loading || !filtersValid} onClick={onApply} loading={loading} type="primary">
            Apply
          </Button>
        </>
      }
      onClose={onClose}
      extraRow={extraRow}
      title="Filters"
    />
  );
};
