import { Button, Form, Input } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { Link, useRouter } from "found";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { getApiBaseUrl, getFaviconEl } from "../utils/misc";
import { PasswordResetPage_customPortal$data } from "../__generated__/PasswordResetPage_customPortal.graphql";
import { LoggedOutPage } from "./index";

type Props = {
  customPortal: PasswordResetPage_customPortal$data;
};

const PasswordResetPage: React.FC<Props> = ({ customPortal }) => {
  const { router, match } = useRouter();
  const [error, setError] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) {
      faviconEl.href = customPortal?.faviconUrl;
    }
    if (customPortal?.pageTitle) {
      document.title = customPortal?.pageTitle;
    }
  }, [customPortal]);

  const { token } = match.location.query;
  if (!token) {
    router.push("/login");
    return null;
  }

  const finish = async ({ password }: any) => {
    setLoading(true);

    try {
      await axios.post(`${getApiBaseUrl()}/api/password_reset/confirm/`, {
        password,
        token,
      });
      setLoading(false);
      setPasswordReset(true);
    } catch (err) {
      setLoading(false);
      setError(
        t(
          "password-reset-page.error",
          "Error resetting password. You may have an expired or invalid token, or the password you've chosen is too easy to guess."
        )
      );
    }
  };

  return (
    <LoggedOutPage
      title={t("password-reset-page.reset-your-password-title", "Reset your password")}
      customLogoUrl={customPortal?.logoImageUrl}
      customColor={customPortal?.customColor}
      customFooterUrl={customPortal?.footerImageUrl}
      contactEmail={customPortal?.contactEmail}
    >
      {!passwordReset ? (
        <Form layout="vertical" onFinish={finish}>
          <Form.Item
            label={t("password-reset-page.new-password-label", "New Password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("password-reset-page.new-password-required", "Please input your new password"),
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" disabled={loading} loading={loading}>
              {t("password-reset-page.reset-password-button-label", "Reset Password")}
            </Button>
          </Form.Item>
          {error && <span className="general-error">{error}</span>}
        </Form>
      ) : (
        <p>
          <Trans i18nKey="password-reset-page.success-message">
            Your password has been reset. <Link to="/login">Take me to the login page.</Link>
          </Trans>
        </p>
      )}
    </LoggedOutPage>
  );
};

export default createFragmentContainer(PasswordResetPage, {
  customPortal: graphql`
    fragment PasswordResetPage_customPortal on CustomPortalNode {
      logoImageUrl
      footerImageUrl
      customColor
      faviconUrl
      pageTitle
      contactEmail
    }
  `,
});
