/**
 * @generated SignedSource<<842a0f3566c26f5d316d14500b7dcd47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersParticipantRsvpChoices = "ACCEPTED" | "DECLINED" | "NEEDSACTION" | "TENTATIVE" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScheduleCell_respondent$data = {
  readonly id: string;
  readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
  readonly participant: {
    readonly rsvp: ScreenersParticipantRsvpChoices;
  } | null;
  readonly scheduledSlots: ReadonlyArray<{
    readonly end: any;
    readonly interviewed: boolean;
    readonly session: {
      readonly name: string;
    } | null;
    readonly start: any;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleModal_respondent">;
  readonly " $fragmentType": "ScheduleCell_respondent";
};
export type ScheduleCell_respondent$key = {
  readonly " $data"?: ScheduleCell_respondent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleCell_respondent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleCell_respondent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masterStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ParticipantNode",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rsvp",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RespondentScheduledSlotNode",
      "kind": "LinkedField",
      "name": "scheduledSlots",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudySessionNode",
          "kind": "LinkedField",
          "name": "session",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "start",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interviewed",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduleModal_respondent"
    }
  ],
  "type": "RespondentNode",
  "abstractKey": null
};

(node as any).hash = "2e992405c66a64fc4524cd4f3591995f";

export default node;
