/**
 * @generated SignedSource<<43e1527c68e29459e992aaf27789fd65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiChoiceElement_element$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_element">;
  readonly " $fragmentType": "MultiChoiceElement_element";
};
export type MultiChoiceElement_element$key = {
  readonly " $data"?: MultiChoiceElement_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiChoiceElement_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultiChoiceElement_element",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "535f5d18e4c5f021cd1d62fe04b40b09";

export default node;
