/**
 * @generated SignedSource<<af32d025745187ebe9ca30e578ed0ba9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OptionTerminate_element$data = {
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly type: ScreenersElementTypeChoices;
  readonly " $fragmentType": "OptionTerminate_element";
};
export type OptionTerminate_element$key = {
  readonly " $data"?: OptionTerminate_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"OptionTerminate_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OptionTerminate_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AnswerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "d4e1529b5d6028150a6cef531cdc8ce4";

export default node;
