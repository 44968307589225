/**
 * @generated SignedSource<<a2facdbb01aa5d241d1c3e931a448301>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RespondentResponses_ModalContent_respondent$data = {
  readonly dId: any | null;
  readonly rhAnswer: string | null;
  readonly " $fragmentType": "RespondentResponses_ModalContent_respondent";
};
export type RespondentResponses_ModalContent_respondent$key = {
  readonly " $data"?: RespondentResponses_ModalContent_respondent$data;
  readonly " $fragmentSpreads": FragmentRefs<"RespondentResponses_ModalContent_respondent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RespondentResponses_ModalContent_respondent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rhAnswer",
      "storageKey": null
    }
  ],
  "type": "RespondentNode",
  "abstractKey": null
};

(node as any).hash = "aafb0cd20e9e4bda09b7894565490e39";

export default node;
