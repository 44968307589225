/**
 * @generated SignedSource<<1da07e04db13d51ab1b4cbc40ae40286>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CharacteristicsEditPage_user$data = {
  readonly dId: any | null;
  readonly panelist: {
    readonly dId: any | null;
  } | null;
  readonly vpmUserId: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditCharacteristicActive_user">;
  readonly " $fragmentType": "CharacteristicsEditPage_user";
};
export type CharacteristicsEditPage_user$key = {
  readonly " $data"?: CharacteristicsEditPage_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CharacteristicsEditPage_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CharacteristicsEditPage_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vpmUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditCharacteristicActive_user"
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "9dbbb048ae6105916f47ce6dd039edba";

export default node;
