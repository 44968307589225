import axios from "axios";
import { useRouter } from "found";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  HEADER_FONT_SIZE,
  HEADER_FONT_WEIGHT,
  PAGE_WIDTH_LG,
  PAGE_WIDTH_XL,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../style";
import { getAuthorizationHeader } from "../utils";
import { getApiBaseUrl } from "../utils/misc";

type DownloadTitleMessage = "Your export is ready" | "Error downloading the export" | "Getting your export...";

type DownloadStatusMessage =
  | "The download should begin shortly"
  | "You do not have access for this download"
  | "File not found, please try exporting a new file"
  | "Waiting for download to begin"
  | "Unexpected error, please try again";

export const CSVDownloadPage = () => {
  const [downloadMessage, setDownloadMessage] = useState<DownloadStatusMessage>("Waiting for download to begin");
  const [titleMessage, setTitleMessage] = useState<DownloadTitleMessage>("Getting your export...");
  const { match } = useRouter();
  useEffect(() => {
    const url = `${getApiBaseUrl()}/d/${match?.location?.query?.d}/csv-download-auth`;
    axios
      .request({
        method: "get",
        url,
        headers: getAuthorizationHeader(),
      })
      .then(response => {
        window.location.href = response.data;
        setDownloadMessage("The download should begin shortly");
        setTitleMessage("Your export is ready");
      })
      .catch(e => {
        setTitleMessage("Error downloading the export");
        if (e.response?.status === 404) {
          setDownloadMessage("File not found, please try exporting a new file");
        } else if (e.response?.status === 403) {
          setDownloadMessage("You do not have access for this download");
        } else {
          setDownloadMessage("Unexpected error, please try again");
        }
      });
  }, [match]);

  return (
    <Styled>
      <div className="wrapper">
        <div className="inner">
          <div className="heading">
            <div className="img-wrapper">
              <img src="/team-teamwork.png" srcSet="/team-teamwork2x.png 2x" alt="Teamwork" width="150" height="150" />
            </div>
            <h1>{titleMessage}</h1>
          </div>
          <p>
            <span>Status: </span>
            {downloadMessage}
          </p>
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .wrapper {
    margin: auto;
    padding-bottom: 24px;
    @media (max-width: 1080px) {
      width: ${PAGE_WIDTH_LG};
    }
    @media (min-width: 1200px) {
      width: ${PAGE_WIDTH_XL};
    }
    .inner {
      max-width: 600px;
      margin: auto;
      padding-top: 48px;
      .heading {
        padding: 24px 0 24px 0;
        .img-wrapper {
          display: flex;
          justify-content: center;
        }
        h1 {
          text-align: center;
          font-size: ${HEADER_FONT_SIZE};
          font-weight: ${HEADER_FONT_WEIGHT};
          color: #000000;
        }
      }
      .setting {
        margin-top: 24px;
        .setting-description {
          font-size: 12px;
          color: ${SECONDARY_TEXT_COLOR};
        }
        .setting-button-container {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          margin-bottom: 12px;
          .button-info {
            margin-left: 12px;
            font-size: 14px;
            font-weight: bold;
            color: ${PRIMARY_TEXT_COLOR};
          }
        }
      }
      p {
        text-align: center;
      }
      span {
        font-weight: bold;
      }
    }
  }
`;
