/**
 * @generated SignedSource<<7a01c451af9acd900263a1235a5365e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResponseValidationType = "AL" | "AM" | "BE" | "EX" | "%future added value";
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
export type UpdateElementInput = {
  clientMutationId?: string | null;
  elementId: string;
  label?: string | null;
  randomize?: boolean | null;
  responseValidation?: string | null;
  showIfCondition?: any | null;
  target?: number | null;
  target2?: number | null;
  targetTypes?: ReadonlyArray<string> | null;
  text?: string | null;
  usePreviousAnswers?: any | null;
};
export type ActiveElementContent_UpdateElement_Mutation$variables = {
  input: UpdateElementInput;
};
export type ActiveElementContent_UpdateElement_Mutation$data = {
  readonly updateElement: {
    readonly element: {
      readonly dbId: string;
      readonly id: string;
      readonly label: string | null;
      readonly position: number;
      readonly randomize: boolean;
      readonly responseValidation: ResponseValidationType | null;
      readonly showIfCondition: any | null;
      readonly target: number | null;
      readonly target2: number | null;
      readonly text: string | null;
      readonly type: ScreenersElementTypeChoices;
      readonly usePreviousAnswers: any | null;
      readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_element">;
    } | null;
  } | null;
};
export type ActiveElementContent_UpdateElement_Mutation = {
  response: ActiveElementContent_UpdateElement_Mutation$data;
  variables: ActiveElementContent_UpdateElement_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "target",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "target2",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseValidation",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "randomize",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showIfCondition",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usePreviousAnswers",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveElementContent_UpdateElement_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateElementPayload",
        "kind": "LinkedField",
        "name": "updateElement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InputFilterGroupGroup_element"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActiveElementContent_UpdateElement_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateElementPayload",
        "kind": "LinkedField",
        "name": "updateElement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b59563bec2ff04a3741418a3a646633",
    "id": null,
    "metadata": {},
    "name": "ActiveElementContent_UpdateElement_Mutation",
    "operationKind": "mutation",
    "text": "mutation ActiveElementContent_UpdateElement_Mutation(\n  $input: UpdateElementInput!\n) {\n  updateElement(input: $input) {\n    element {\n      id\n      dbId\n      text\n      type\n      target\n      target2\n      responseValidation\n      label\n      position\n      randomize\n      showIfCondition\n      usePreviousAnswers\n      ...InputFilterGroupGroup_element\n    }\n  }\n}\n\nfragment InputFilterGroupGroup_element on ElementNode {\n  id\n  ...InputFilterGroup_element\n}\n\nfragment InputFilterGroup_element on ElementNode {\n  id\n  ...InputFilter_element\n}\n\nfragment InputFilter_element on ElementNode {\n  position\n}\n"
  }
};
})();

(node as any).hash = "b854f7708eeb0d7ed34785451dc71d6f";

export default node;
