/**
 * @generated SignedSource<<340a69efcd20e053b6f51b0c77200686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectOverviewPage_study$data = {
  readonly fieldReport: {
    readonly elements: ReadonlyArray<{
      readonly gridAnswers: ReadonlyArray<{
        readonly answerId: string | null;
        readonly rowId: string | null;
        readonly terminate: boolean | null;
      } | null> | null;
      readonly options: ReadonlyArray<{
        readonly dbId: string;
        readonly quota: string | null;
        readonly terminate: boolean | null;
        readonly total: number | null;
      } | null> | null;
      readonly rows: ReadonlyArray<{
        readonly dbId: string;
        readonly options: ReadonlyArray<{
          readonly dbId: string;
          readonly terminate: boolean | null;
          readonly total: number | null;
        } | null> | null;
      } | null> | null;
      readonly text: string | null;
      readonly type: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"QuotasOverviewElementTable_element" | "TerminantsOverviewTable_element">;
    } | null> | null;
    readonly responseSummary: {
      readonly screenerDropouts: {
        readonly total: number | null;
      } | null;
      readonly totalNeeded: {
        readonly total: number | null;
      } | null;
      readonly totalStarts: {
        readonly qualified: number | null;
        readonly total: number | null;
      } | null;
    } | null;
  } | null;
  readonly id: string;
  readonly name: string | null;
  readonly scheduled: any | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly requireStudyPayment: boolean;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectFieldReport_study" | "ProjectSpend_study">;
  readonly " $fragmentType": "ProjectOverviewPage_study";
};
export type ProjectOverviewPage_study$key = {
  readonly " $data"?: ProjectOverviewPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectOverviewPage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectOverviewPage_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectFieldReport_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectSpend_study"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requireStudyPayment",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FieldReportNode",
      "kind": "LinkedField",
      "name": "fieldReport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResponseSummaryNode",
          "kind": "LinkedField",
          "name": "responseSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TotalQualedNode",
              "kind": "LinkedField",
              "name": "totalNeeded",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TotalQualedNode",
              "kind": "LinkedField",
              "name": "totalStarts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "qualified",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TotalQualedNode",
              "kind": "LinkedField",
              "name": "screenerDropouts",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FieldReportElementNode",
          "kind": "LinkedField",
          "name": "elements",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuotasOverviewElementTable_element"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TerminantsOverviewTable_element"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FieldReportOptionNode",
              "kind": "LinkedField",
              "name": "options",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quota",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FieldReportRowNode",
              "kind": "LinkedField",
              "name": "rows",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FieldReportRowOptionNode",
                  "kind": "LinkedField",
                  "name": "options",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    (v1/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FieldReportGridAnswerNode",
              "kind": "LinkedField",
              "name": "gridAnswers",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "answerId",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "87b7d7a916854adb6d433da04b83c0f6";

export default node;
