import { isEqualSimple } from "@react-hookz/deep-equal";
import { useUpdateEffect } from "@react-hookz/web";
import type { PickerProps, RangePickerProps as BaseRangePickerProps } from "antd/es/date-picker/generatePicker";
import type { Moment } from "moment";
import { useContext, useMemo, useState } from "react";
import DatePicker from "../DatePicker";

import { TimeZoneContext } from "../../utils";

export type DatePickerProps = PickerProps<Moment>;
export type RangePickerProps = BaseRangePickerProps<Moment>;

export const InputDate = ({ onChange, onOk, value: __value, ...props }: DatePickerProps) => {
  const { shiftDate, timeZoneOffsetDifference, unshiftDate } = useContext(TimeZoneContext);

  const [_value, _setValue] = useState(__value);
  useUpdateEffect(() => {
    if (!isEqualSimple(__value, _value)) _setValue(__value);
  }, [__value]);

  useUpdateEffect(() => {
    if (!isEqualSimple(_value, __value))
      onChange?.(
        _value ?? null,
        typeof props.format === "function"
          ? _value
            ? props.format(_value)
            : ""
          : typeof props.format === "string"
          ? _value?.format(props.format) || ""
          : ""
      );
  }, [_value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => (_value ? shiftDate(_value) : null), [_value, shiftDate, timeZoneOffsetDifference]);
  const setValue = (x: typeof _value) => _setValue(x ? unshiftDate(x) : x);

  return <DatePicker {...props} value={value} onChange={v => setValue(v)} onOk={x => onOk?.(unshiftDate(x))} />;
};

export const InputDateRange = ({ onChange, onOk, value: __value, ...props }: RangePickerProps) => {
  const { shiftDate, timeZoneOffsetDifference, unshiftDate } = useContext(TimeZoneContext);

  const [_value, _setValue] = useState(__value);
  useUpdateEffect(() => {
    if (!isEqualSimple(__value, _value)) _setValue(__value);
  }, [__value]);

  useUpdateEffect(() => {
    if (!isEqualSimple(_value, __value)) {
      onChange?.(
        _value ?? null,
        _value && props.format
          ? Array.isArray(props.format)
            ? (props.format.map((fn, i) => {
                const x = _value[i];

                return !!fn && !!x
                  ? typeof fn === "function"
                    ? fn(x)
                    : typeof fn === "string"
                    ? x.format(fn)
                    : ""
                  : "";
              }) as [string, string])
            : typeof props.format === "function"
            ? (_value.map(x => (x ? (props.format as typeof props.format)(x) : x)) as [string, string])
            : typeof props.format === "string"
            ? (_value.map(x => (x ? x.format(props.format as typeof props.format) : x)) as [string, string])
            : ["", ""]
          : ["", ""]
      );
    }
  }, [_value]);

  const value = useMemo(
    () => (_value ? _value.map(x => (x ? shiftDate(x) : null)) : [null, null]) as typeof _value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_value, shiftDate, timeZoneOffsetDifference]
  );
  const setValue = (x: typeof _value) =>
    _setValue((x ? x.map(y => (y ? unshiftDate(y) : null)) : [null, null]) as typeof _value);

  return (
    <DatePicker.RangePicker
      {...props}
      value={value}
      onChange={v => setValue(v)}
      onOk={x =>
        onOk?.((x ? x.map(y => (y ? unshiftDate(y) : null)) : [null, null]) as Exclude<typeof _value, null | undefined>)
      }
    />
  );
};
