import { DownOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Dropdown, Layout } from "antd";
import { ConfigProviderProps } from "antd/es/config-provider";
import { graphql } from "babel-plugin-relay/macro";
import { merge } from "lodash-es";
import { useMemo, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ConfigProviderAccentColorContext, ThemeColor, useAntdConfig } from "../../antd";
import { BREAKPOINTS, CountryType } from "../../constants";
import { HEADER_PRIMARY_TEXT_COLOR, PAGE_PADDING } from "../../style";
import { useFlag } from "../../utils";
import { PanelistLayout_user$data } from "../../__generated__/PanelistLayout_user.graphql";
import CookieConsentBar from "../CookieConsentBar";
import { Logo } from "../Logo";
import Rewards from "../ParticipantPortal/Rewards";

const PanelistLayout = ({
  children,
  user,
  menu,
  router,
  match,
}: {
  children: ReactNode;
  user: PanelistLayout_user$data;
  menu: JSX.Element;
  match: any;
  router: any;
}) => {
  const { t } = useTranslation();

  const { customPanelistPortal: customPortal } = user?.panelist ?? {};

  const customLogoUrl = customPortal?.logoImageUrl;

  const configProviderProps = useAntdConfig();
  const isVoxpopme = useFlag("platform-hubux-template");
  const theme = useMemo<ConfigProviderProps["theme"]>(
    () =>
      merge({}, configProviderProps, {
        components: {
          Layout: {
            ...(customPortal?.customColor ? { colorBgHeader: customPortal.customColor } : {}),
            colorTextBase: customPortal?.headerFontColor ?? (isVoxpopme ? "black" : HEADER_PRIMARY_TEXT_COLOR),
          },
        },
      }),
    [configProviderProps, customPortal?.customColor, customPortal?.headerFontColor, isVoxpopme]
  );

  return (
    <ConfigProvider theme={theme}>
      <ConfigProviderAccentColorContext.Provider value={customPortal?.accentColor ?? null}>
        <LayoutWrapper
          className="layout"
          $customFontColor={theme?.components?.Layout?.colorTextBase!}
          style={{ backgroundColor: configProviderProps.theme.components?.Layout?.colorBgBody }}
        >
          <Layout.Header
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 1000,
              borderBottom: `1px solid ${ThemeColor.VoxStroke}`,
            }}
          >
            <div className="header">
              <LogoWrapper>
                <div
                  className="logo"
                  onClick={() => router.push(match.location.pathname.includes("/projects") ? "/projects" : "/portal")}
                >
                  {customLogoUrl ? (
                    <CustomLogoWrapper>
                      <img className="custom-logo" src={customLogoUrl} alt="" />
                    </CustomLogoWrapper>
                  ) : (
                    <Logo height="30px" width="90px" />
                  )}
                </div>
              </LogoWrapper>
              <div className="rewards-menu-container">
                {(match.location.pathname.includes("/portal") || match.location.pathname.includes("/redeem")) &&
                  !user.panelist?.customPanelistPortal?.hidePointsAndRedemption && (
                    <>
                      <span className="rewards">
                        <Rewards
                          balance={user?.panelist?.rewardsBalance || 0}
                          convertedRewardsBalance={user.panelist?.convertedRewardsBalance || 0}
                          style={{ marginRight: 14 }}
                          country={user.panelist?.person?.country as CountryType}
                        />
                      </span>
                      <span style={{ marginRight: 14 }} className="redeem">
                        <Button
                          ghost={!isVoxpopme}
                          onClick={() => {
                            router.push("/redeem");
                          }}
                        >
                          {t("layouts.panelist.redeem-points", "Redeem Points")}
                        </Button>
                      </span>
                    </>
                  )}
                <Dropdown menu={menu.props}>
                  <div className="dropdown">
                    <span className="user-dropdown">{user.fullName || user.email.toLowerCase()}</span>
                    <DownOutlined style={{ color: theme?.components?.Layout?.colorTextBase }} />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Layout.Header>
          {children}
          <CookieConsentBar location="bottom" />
        </LayoutWrapper>
      </ConfigProviderAccentColorContext.Provider>
    </ConfigProvider>
  );
};

const LayoutWrapper = styled(Layout)<{
  $customFontColor: string | null;
}>`
  display: block;
  height: 100%;
  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-dropdown {
    display: block;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => props.$customFontColor};
    cursor: pointer;
    margin-right: 10px;
    @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      width: auto;
      max-width: 125px;
    }
    @media (min-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
      width: auto;
      max-width: 250px;
    }
  }
  .ant-layout-header {
    padding: 0 ${PAGE_PADDING};
    height: 64px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin: auto;
    .rewards-menu-container {
      color: ${props => props.$customFontColor};
      display: flex;
      .rewards {
        @media only screen and (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
          display: none;
        }
      }
      .redeem {
        padding: 0 10px;
        @media only screen and (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
          display: none;
        }
      }
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .top-nav {
    margin-left: 24px;
    background-color: #33394f;
    color: #fff;
  }
  .ant-menu-horizontal {
    border-bottom: 0;
  }
  .ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected {
    border-bottom: 2px solid var(--ant-primary-color);
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item:not(.ant-menu-item-selected) {
    border-bottom: 2px solid #33394f;
  }
`;

const CustomLogoWrapper = styled.div`
  height: 65px;
  max-width: 150px;

  display: flex;
  align-items: center;

  .custom-logo {
    max-height: 55px;
    max-width: 150px;
  }
  @media (min-width: 321px) {
    max-height: 65px;
  }
`;

export default createFragmentContainer(PanelistLayout, {
  user: graphql`
    fragment PanelistLayout_user on UserNode {
      fullName
      email
      profile {
        tenant {
          name
        }
      }
      panelist {
        customPanelistPortal {
          accentColor
          accentColorLighter
          logoImageUrl
          customColor
          headerFontColor
          hidePointsAndRedemption
        }
        totalIncentives
        rewardsBalance
        convertedRewardsBalance
        person {
          country
        }
      }
    }
  `,
});
