/**
 * @generated SignedSource<<12b90ea1990d95756f64ae8caa3f0aab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ResponseValidationType = "AL" | "AM" | "BE" | "EX" | "%future added value";
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActiveElementContent_element$data = {
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly dbId: string;
        readonly id: string;
        readonly position: number;
        readonly text: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"Option_answer">;
      } | null;
    } | null>;
  };
  readonly asset: {
    readonly id: string;
    readonly url: string;
  } | null;
  readonly characteristic: {
    readonly answers: ReadonlyArray<{
      readonly id: string;
      readonly position: number;
      readonly text: string;
    }>;
    readonly elementMeta: {
      readonly randomize: boolean | null;
      readonly responseValidation: ResponseValidationType | null;
      readonly target: number | null;
      readonly target2: number | null;
      readonly targetTypes: ReadonlyArray<string> | null;
    };
    readonly importKey: string;
    readonly shortName: string;
    readonly tenant: {
      readonly id: string;
    } | null;
  } | null;
  readonly id: string;
  readonly label: string | null;
  readonly position: number;
  readonly randomize: boolean;
  readonly responseValidation: ResponseValidationType | null;
  readonly rows: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly position: number;
        readonly text: string;
        readonly " $fragmentSpreads": FragmentRefs<"Row_row">;
      } | null;
    } | null>;
  };
  readonly screener: {
    readonly id: string;
  };
  readonly showIfCondition: any | null;
  readonly target: number | null;
  readonly target2: number | null;
  readonly targetTypes: ReadonlyArray<string> | null;
  readonly text: string | null;
  readonly type: ScreenersElementTypeChoices;
  readonly usePreviousAnswers: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"AddOption_element" | "GridQuestionEditor_element" | "InputFilterGroupGroup_element" | "Option_element" | "Row_element" | "UsePreviousAnswers_element">;
  readonly " $fragmentType": "ActiveElementContent_element";
};
export type ActiveElementContent_element$key = {
  readonly " $data"?: ActiveElementContent_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveElementContent_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "target",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "target2",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetTypes",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseValidation",
  "storageKey": null
},
v6 = [
  (v0/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "randomize",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveElementContent_element",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetNode",
      "kind": "LinkedField",
      "name": "asset",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usePreviousAnswers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScreenerNode",
      "kind": "LinkedField",
      "name": "screener",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNode",
      "kind": "LinkedField",
      "name": "characteristic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicAnswerNode",
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v7/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicElementMetaNode",
          "kind": "LinkedField",
          "name": "elementMeta",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            (v5/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "importKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantNode",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AnswerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dbId",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Option_answer"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RowNodeConnection",
      "kind": "LinkedField",
      "name": "rows",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RowNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v7/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Row_row"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showIfCondition",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddOption_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GridQuestionEditor_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilterGroupGroup_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Option_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Row_element"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UsePreviousAnswers_element"
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "2bd1825eec74f22ef1615260f9b3f3dd";

export default node;
