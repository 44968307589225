/**
 * @generated SignedSource<<3586ad13662599cd70b4220b71678933>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsePreviousAnswers_element$data = {
  readonly id: string;
  readonly usePreviousAnswers: any | null;
  readonly " $fragmentType": "UsePreviousAnswers_element";
};
export type UsePreviousAnswers_element$key = {
  readonly " $data"?: UsePreviousAnswers_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsePreviousAnswers_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsePreviousAnswers_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usePreviousAnswers",
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "dcd61315a6b3b7bdc00dad2cebbd93fe";

export default node;
