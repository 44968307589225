import { Card, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { DROPOUT_COLOR, IR_COLOR, QUALIFIED_COLOR } from "../../style";

const ProjectOverviewKPIs: React.FC<{ study: any }> = ({ study }) => {
  const qualified = study.fieldReport?.responseSummary?.totalStarts?.qualified || 0;
  const totalStarts = study.fieldReport?.responseSummary?.totalStarts?.total || 0;
  const incidenceRate = qualified === 0 && totalStarts === 0 ? 0 : Math.ceil((qualified / totalStarts) * 100);
  const dropouts = study.fieldReport?.responseSummary?.screenerDropouts?.total || 0;
  const dropoutRate = dropouts === 0 && totalStarts === 0 ? 0 : Math.ceil((dropouts / totalStarts) * 100);

  return (
    <StyledKPIs>
      <section className="kpis">
        <Tooltip title="# of participants that completed the screener with qualifying answers">
          <Card bordered id="total-qualified">
            <div className="kpi">
              <span className="label">Total Qualified</span>
              <span className="value">{qualified}</span>
            </div>
          </Card>
        </Tooltip>
        <Tooltip title="Qualified participants as a proportion of participants that started the screener">
          <Card bordered id="incidence-rate">
            <div className="kpi">
              <span className="label">Incidence Rate</span>
              <span className="value">{incidenceRate}%</span>
            </div>
          </Card>
        </Tooltip>
        <Tooltip title="% of participants that started the screener, but did not finish">
          <Card bordered id="dropout-rate">
            <div className="kpi">
              <span className="label">Dropout Rate</span>
              <span className="value">{dropoutRate}%</span>
            </div>
          </Card>
        </Tooltip>
      </section>
    </StyledKPIs>
  );
};

const StyledKPIs = styled.div`
  .kpis {
    display: flex;
    width: 870px;
    border-radius: 13px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    .ant-card-body {
      padding: 8px 24px;

      .kpi {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: inherit;

        .label {
          font-size: 19px;
          position: relative:
          top: 1px;
        }

        .value {
          font-size: 31px;
        }
      }
    }
  }

  #total-qualified {
    .value {
      color: ${QUALIFIED_COLOR};
    }
  }

  #incidence-rate {
    .value {
      color: ${IR_COLOR};
    }
  }

  #dropout-rate {
    .value {
      color: ${DROPOUT_COLOR};
    }
  }
`;

export default ProjectOverviewKPIs;
