import baselineCalendarToday from "@iconify-icons/ic/baseline-calendar-today";
import starOutlined from "@iconify-icons/ic/outline-star-outline";
import { useUpdateEffect } from "@react-hookz/web";
import { App, Modal, Rate, Space } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { useState } from "react";
import { createFragmentContainer } from "react-relay";
import {
  PROJECT_INCENTIVE_TYPES,
  PROJECT_STATUSES,
  PROJECT_TYPES,
  RESPONDENT_ICONS_MAP,
  RESPONDENT_MASTER_STATUSES,
} from "../../constants";
import { useFlag } from "../../utils";
import { pluralize } from "../../utils/misc";
import { ParticipantBatchActions_study$data } from "../../__generated__/ParticipantBatchActions_study.graphql";
import { ParticipantBatchActions_user$data } from "../../__generated__/ParticipantBatchActions_user.graphql";
import { NotificationLink } from "../Notifications/Notification";
import { BulkScheduleModal } from "../Slots/BulkScheduleModal";

import { BulkActionButton } from "./BulkActionButton";
import { ConfirmExternalIncentiveModal } from "./ConfirmExternalIncentiveModal";
import { GivePointsToParticipantsModal } from "./GivePointsToParticipantsModal";
import ParticipantPaymentModal, { RecruitsRequiringFundingType } from "./ParticipantPaymentModal";
import { BatchMutationType, IParticipantBatch } from "./ParticipantsBatchMutations";
import { notifyApproved } from "./ParticipantsNotifications";
import { TabType } from "./ParticipantsTable";
import { PARTICIPANT_TYPES } from "./ParticipantTypesPicker";

const ParticipantBatchActions = ({
  study,
  user,
  selectedTab,
  participantBatch,
  participantsType,
  batchAction,
  selectedIds,
  selectedCount,
  givePointsToParticipantId,
  setGivePointsToParticipantId,
}: {
  study: ParticipantBatchActions_study$data;
  user: ParticipantBatchActions_user$data;
  selectedTab: TabType;
  participantBatch: IParticipantBatch;
  participantsType: PARTICIPANT_TYPES;
  batchAction: (mutationType: BatchMutationType, noShow?: boolean, rating?: number, slotId?: string) => any;
  selectedIds: string[];
  selectedCount: number;
  givePointsToParticipantId?: string;
  setGivePointsToParticipantId?: (participantId: string) => void;
}) => {
  const { router } = useRouter();
  const { notification } = App.useApp();

  const [showRateModal, setShowRateModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [showApproveRejectedModal, setShowApproveRejectedModal] = useState<boolean>(false);
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);
  const [showNoShowModal, setShowNoShowModal] = useState<boolean>(false);
  const [showGivePointsModal, setShowGivePointsModal] = useState<boolean>(false);
  const [showParticipantPaymentModal, setShowParticipantPaymentModal] = useState<boolean>(false);
  const [recruitsRequiringFunding, setRecruitsRequiringFunding] = useState<RecruitsRequiringFundingType | null>(null);

  const allowGiveBonusIncentive =
    study.status !== PROJECT_STATUSES.DRAFT && !study.tenant.customportal?.hidePointsAndRedemption;

  useUpdateEffect(() => {
    if (givePointsToParticipantId) {
      setShowGivePointsModal(true);
    }
  }, [givePointsToParticipantId]);

  const participants = pluralize(selectedCount, "participants", "participant");

  const ConfirmApproveModal = () => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    let contentDetail = "";

    if ([PROJECT_TYPES.INTERVIEW, PROJECT_TYPES.FOCUS_GROUP].includes(study.type)) {
      contentDetail = ". Invitations to schedule a time will be sent to each participant.";
    } else {
      contentDetail = ", after which you will be able to rate and incentivize them.";
    }
    return (
      <Modal
        open={showApproveModal}
        title={`Approve ${selectedCount.toLocaleString()} ${participants}`}
        okText="Approve"
        onOk={async () => {
          setConfirmLoading(true);
          await batchAction(BatchMutationType.APPROVE);
          setShowApproveModal(false);
          notification.success({
            message: `You approved ${selectedCount.toLocaleString()} ${participants}`,
            description:
              study.type === PROJECT_TYPES.INTERVIEW ? (
                <>
                  They can be scheduled in the Approved section of the{" "}
                  <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                    Participants tab
                  </NotificationLink>
                </>
              ) : study.type === PROJECT_TYPES.FOCUS_GROUP ? (
                <>
                  We sent them the survey link. You can see them in the Approved section of the{" "}
                  <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                    Participants tab
                  </NotificationLink>
                </>
              ) : study.type === PROJECT_TYPES.SURVEY ? (
                <>
                  They can be incentivized in the Survey Completed section of the{" "}
                  <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                    Participants tab
                  </NotificationLink>
                </>
              ) : null,
          });
        }}
        onCancel={() => setShowApproveModal(false)}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        Approve{selectedCount.toLocaleString()} {participants}
        {contentDetail}
      </Modal>
    );
  };

  const ConfirmApproveRejectedModal = () => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const summaryText = `Approve ${pluralize(
      selectedCount,
      `${selectedCount.toLocaleString()} rejected participants`,
      "one rejected participant"
    )}`;

    return (
      <Modal
        open={showApproveRejectedModal}
        title={summaryText}
        okText="Approve"
        onOk={async () => {
          setConfirmLoading(true);
          await batchAction(BatchMutationType.APPROVE);
          setShowApproveRejectedModal(false);
          notifyApproved(selectedCount, study, notification);
        }}
        onCancel={() => setShowApproveRejectedModal(false)}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <p>If approved participants exceed recruiting round goals, you'll need to pay for them when incentivizing.</p>
      </Modal>
    );
  };

  const ConfirmDeclineModal = () => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    return (
      <Modal
        open={showDeclineModal}
        title={`Decline ${selectedCount.toLocaleString()} ${participants}`}
        okText="Decline"
        okButtonProps={{ danger: true }}
        onOk={async () => {
          setConfirmLoading(true);
          await batchAction(BatchMutationType.REJECT);
          setShowDeclineModal(false);
          notification.success({
            message: `You declined ${selectedCount.toLocaleString()} ${participants}`,
            description: (
              <>
                You can see them in the Rejected section of the{" "}
                <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                  Participants tab
                </NotificationLink>
              </>
            ),
          });
        }}
        onCancel={() => setShowDeclineModal(false)}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        Decline {selectedCount.toLocaleString()} {participants}. The {participants} will not be notified.
      </Modal>
    );
  };

  const ConfirmRateModal = () => {
    const desc = ["Terrible", "Bad", "Normal", "Good", "Wonderful"];
    const [rating, setRating] = useState<number>(0);
    const [noShow, setNoShow] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    return (
      <Modal
        open={showRateModal}
        title={`Rate ${selectedCount.toLocaleString()} ${participants}`}
        okText="Rate"
        okButtonProps={{
          disabled: !noShow && !rating,
          type: "primary",
        }}
        onOk={async () => {
          setConfirmLoading(true);
          await batchAction(BatchMutationType.RATE, noShow, rating);
          notification.success({
            message: noShow
              ? `You marked ${selectedCount.toLocaleString()} ${participants} as no-show`
              : `You rated ${selectedCount.toLocaleString()} ${participants}`,
            description: noShow ? (
              <>
                They can be seen in the Rejected section of the{" "}
                <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                  Participants tab
                </NotificationLink>
                .
              </>
            ) : (
              <>Thank you!</>
            ),
          });
          setNoShow(false);
          setRating(0);
          setShowRateModal(false);
        }}
        onCancel={() => setShowRateModal(false)}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <div>
          <div>
            How would you rate these {selectedCount.toLocaleString()} {participants}?
          </div>
          <div
            style={{ marginTop: 10, paddingLeft: 6, display: "flex", alignItems: "center" }}
            onClick={e => e.stopPropagation()}
          >
            <Rate
              tooltips={desc}
              onChange={value => {
                setNoShow(false);
                setRating(value);
              }}
              value={rating}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const rewardParticipants = async (successString: string) => {
    try {
      await batchAction(BatchMutationType.REWARD);
      notification.success({ message: successString });
    } catch (e: any) {
      if (e.errors?.[0]?.message === "INSUFFICIENT_FUNDS" && e.errors[0].extensions) {
        setRecruitsRequiringFunding(e.errors[0].extensions);
        setShowParticipantPaymentModal(true);
      }
    }
  };

  const ConfirmCashOrPointsRewardModal = () => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const paymentsEnabled = useFlag("hub-recruit-prepay");

    return (
      <Modal
        open={showRewardModal}
        title={`Incentivize ${selectedCount.toLocaleString()} ${participants}`}
        okText="Incentivize"
        okButtonProps={{ type: "primary" }}
        onOk={async () => {
          setConfirmLoading(true);
          await rewardParticipants(`You incentivized ${selectedCount.toLocaleString()} ${participants}`);
          setShowRewardModal(false);
        }}
        onCancel={() => setShowRewardModal(false)}
        cancelButtonProps={{ type: "link" }}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        closable={false}
      >
        <div>
          <p>
            You are about to incentivize {selectedCount.toLocaleString()} {participants} with a{" "}
            {study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH ? "cash" : "points"} incentive.
          </p>
          {paymentsEnabled &&
          user.profile?.tenant?.requireStudyPayment &&
          user.profile.tenant.incentivesOrderFulfillmentVerificationDays ? (
            <>
              <p>
                For security reasons, it may take up to {user.profile.tenant.incentivesOrderFulfillmentVerificationDays}{" "}
                days to issue incentives to participants.{" "}
                <a
                  href="https://app.intercom.com/a/apps/bdqzfylg/articles/articles/5894232/show"
                  target="_blank"
                  rel="noreferrer"
                >
                  Why is this happening?
                </a>
              </p>
            </>
          ) : (
            <p>It may take a few minutes for incentives to be sent.</p>
          )}
        </div>
      </Modal>
    );
  };

  const ConfirmExternalRewardModal = () => (
    <ConfirmExternalIncentiveModal
      study={study}
      title={`Incentivize ${selectedCount.toLocaleString()} ${participants}`}
      runMutation={async () => {
        await rewardParticipants(`You incentivized ${selectedCount.toLocaleString()} ${participants}`);
        setShowRewardModal(false);
      }}
      visible={showRewardModal}
      setVisible={setShowRewardModal}
    />
  );

  const ConfirmNoShowModal = () => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    return (
      <Modal
        open={showNoShowModal}
        title={`Mark ${selectedCount.toLocaleString()} ${participants} as No show`}
        okText="Mark as No show"
        okButtonProps={{ type: "primary" }}
        onOk={async () => {
          setConfirmLoading(true);
          await batchAction(BatchMutationType.RATE, true);
          notification.success({
            message: `You marked ${selectedCount.toLocaleString()} ${participants} as No show`,
            description: (
              <>
                They can be seen in the Rejected section of the{" "}
                <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                  Participants tab
                </NotificationLink>
                .
              </>
            ),
          });
          setShowNoShowModal(false);
        }}
        onCancel={() => setShowNoShowModal(false)}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <p>
          If participants didn't show up for meeting you can mark them as No show. You can't incentivize participants
          that are marked as No show.
        </p>
      </Modal>
    );
  };

  return (
    <>
      <div className="header-action-buttons">
        {selectedTab === TabType.SCREENER_COMPLETED ? (
          <Space>
            <BulkActionButton
              icon={RESPONDENT_ICONS_MAP[RESPONDENT_MASTER_STATUSES.APPROVED]}
              text={`Approve${selectedCount ? " " + selectedCount.toLocaleString() : ""}`}
              disabled={!selectedCount}
              onClick={() => setShowApproveModal(true)}
              extendedActions={[
                ...(allowGiveBonusIncentive
                  ? [{ text: "Give bonus incentive", onClick: () => setShowGivePointsModal(true) }]
                  : []),
                { text: "Decline", onClick: () => setShowDeclineModal(true), menuItemProps: { danger: true } },
              ]}
            />
          </Space>
        ) : selectedTab === TabType.COMPLETE ? (
          <BulkActionButton
            icon={starOutlined}
            text={`Rate${selectedCount ? " " + selectedCount.toLocaleString() : ""}`}
            disabled={!selectedCount}
            onClick={() => setShowRateModal(true)}
            extendedActions={[
              ...(allowGiveBonusIncentive
                ? [{ text: "Give bonus incentive", onClick: () => setShowGivePointsModal(true) }]
                : []),
              { text: "Decline", onClick: () => setShowDeclineModal(true), menuItemProps: { danger: true } },
            ]}
          />
        ) : study.type === PROJECT_TYPES.FOCUS_GROUP && [TabType.APPROVED, TabType.SCHEDULED].includes(selectedTab) ? (
          <BulkActionButton
            icon={baselineCalendarToday}
            text={`${selectedTab === TabType.SCHEDULED ? "Re-schedule" : "Schedule"} ${
              selectedCount ? " " + selectedCount.toLocaleString() : ""
            }`}
            disabled={!selectedCount}
            onClick={() => setShowScheduleModal(true)}
            extendedActions={[
              ...(allowGiveBonusIncentive
                ? [{ text: "Give bonus incentive", onClick: () => setShowGivePointsModal(true) }]
                : []),
              { text: "Decline", onClick: () => setShowDeclineModal(true), menuItemProps: { danger: true } },
            ]}
          />
        ) : selectedTab === TabType.NEEDS_REWARDING ? (
          <BulkActionButton
            icon={RESPONDENT_ICONS_MAP[RESPONDENT_MASTER_STATUSES.REWARDED]}
            text={`Incentivize${selectedCount ? " " + selectedCount.toLocaleString() : ""}`}
            disabled={!selectedCount}
            onClick={() => setShowRewardModal(true)}
            extendedActions={[
              ...(allowGiveBonusIncentive
                ? [{ text: "Give bonus incentive", onClick: () => setShowGivePointsModal(true) }]
                : []),
              { text: "Mark as no-show", onClick: () => setShowNoShowModal(true) },
            ]}
          />
        ) : selectedTab === TabType.REJECTED ? (
          <BulkActionButton
            icon={RESPONDENT_ICONS_MAP[RESPONDENT_MASTER_STATUSES.APPROVED]}
            text={`Approve${selectedCount ? " " + selectedCount.toLocaleString() : ""}`}
            disabled={!selectedCount}
            onClick={() => setShowApproveRejectedModal(true)}
          />
        ) : null}
      </div>
      <GivePointsToParticipantsModal
        tenant={study.tenant}
        studyId={study.id}
        filterComplex={participantBatch.filterComplex}
        selectAllChecked={participantBatch.selectAllChecked}
        excludeIds={participantBatch.excludeIds}
        tab={selectedTab}
        countToSelect={participantBatch.countToSelect}
        participantsType={participantsType}
        selectedIds={givePointsToParticipantId ? [givePointsToParticipantId] : selectedIds}
        visible={showGivePointsModal}
        setVisible={setShowGivePointsModal}
        onClose={() => setGivePointsToParticipantId?.("")}
      />
      <ConfirmApproveModal />
      <ConfirmApproveRejectedModal />
      <ConfirmDeclineModal />
      <BulkScheduleModal
        study={study}
        selectedCount={selectedCount}
        showModal={showScheduleModal}
        setShowModal={setShowScheduleModal}
        handleSchedule={async (slotId: string) => {
          await batchAction(BatchMutationType.SCHEDULE, undefined, undefined, slotId);
          notification.success({
            message: `You scheduled ${selectedCount.toLocaleString()} ${participants} participants`,
            description: (
              <>
                They can be seen in the Scheduled section of the{" "}
                <NotificationLink router={router} to={`/study/${study.id}/respondents/participants`}>
                  Participants tab
                </NotificationLink>
                .
              </>
            ),
          });
        }}
      />
      <ConfirmRateModal />
      {[PROJECT_INCENTIVE_TYPES.CASH, PROJECT_INCENTIVE_TYPES.POINTS].includes(study.incentiveType) && (
        <ConfirmCashOrPointsRewardModal />
      )}
      {study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL && <ConfirmExternalRewardModal />}
      <ConfirmNoShowModal />
      <ParticipantPaymentModal
        study={study}
        visible={showParticipantPaymentModal}
        setVisible={setShowParticipantPaymentModal}
        recruitsRequiringFunding={recruitsRequiringFunding}
        callback={rewardParticipants}
      />
    </>
  );
};

export default createFragmentContainer(ParticipantBatchActions, {
  study: graphql`
    fragment ParticipantBatchActions_study on StudyNode {
      id
      type
      incentiveType
      status
      ...BulkScheduleModal_study
      ...ConfirmExternalIncentiveModal_study
      ...EditExternalIncentiveEmailModal_study
      ...ParticipantPaymentModal_study
      ...SlotPicker_study
      tenant {
        ...GivePointsToParticipantsModal_tenant
        customportal {
          hidePointsAndRedemption
        }
      }
    }
  `,
  user: graphql`
    fragment ParticipantBatchActions_user on UserNode {
      profile {
        tenant {
          requireStudyPayment
          incentivesOrderFulfillmentVerificationDays
        }
      }
    }
  `,
});
