/**
 * @generated SignedSource<<256f584b8dffcf875d082b07dad803c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputFilterGroupGroup_screener$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroup_screener">;
  readonly " $fragmentType": "InputFilterGroupGroup_screener";
};
export type InputFilterGroupGroup_screener$key = {
  readonly " $data"?: InputFilterGroupGroup_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputFilterGroupGroup_screener",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputFilterGroup_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "71921c5c85e54fcc0d68a97f2490fa0c";

export default node;
