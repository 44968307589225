import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { environment } from "../../relay";
import { ParticipantFilterGroupQuery_Query } from "../../__generated__/ParticipantFilterGroupQuery_Query.graphql";
import ParticipantFilters from "./ParticipantFilters";

const ParticipantFilterGroupQuery = ({
  onApplyFilters,
  loading,
  studyId,
  setFiltersDrawerVisible,
  filtersDrawerVisible,
  value,
}: {
  filtersDrawerVisible: boolean;
  loading: boolean;
  onApplyFilters: Parameters<typeof ParticipantFilters>[0]["onApplyFilters"];
  setFiltersDrawerVisible: (visible: boolean) => void;
  studyId: string;
  value: Parameters<typeof ParticipantFilters>[0]["value"];
}) => (
  <QueryRenderer<ParticipantFilterGroupQuery_Query>
    query={graphql`
      query ParticipantFilterGroupQuery_Query($studyId: String!) {
        viewer {
          ...ParticipantFilters_viewer @arguments(studyId: $studyId)
        }
      }
    `}
    environment={environment}
    variables={{ studyId: studyId }}
    render={({ error, props }) => {
      if (error) {
        console.error(error);
        return null;
      }
      return (
        props?.viewer && (
          <ParticipantFilters
            loading={loading}
            viewerKey={props.viewer}
            onApplyFilters={onApplyFilters}
            setFiltersDrawerVisible={setFiltersDrawerVisible}
            filtersDrawerVisible={filtersDrawerVisible}
            value={value}
          />
        )
      );
    }}
  />
);

export default ParticipantFilterGroupQuery;
