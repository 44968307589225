/**
 * @generated SignedSource<<e8f7eb80a64fb6488f3940a59de7dcc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersTagColorChoices = "BL" | "CN" | "GN" | "GY" | "OG" | "PL" | "RD" | "YW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TagTag_tag$data = {
  readonly color: ScreenersTagColorChoices;
  readonly name: string;
  readonly " $fragmentType": "TagTag_tag";
};
export type TagTag_tag$key = {
  readonly " $data"?: TagTag_tag$data;
  readonly " $fragmentSpreads": FragmentRefs<"TagTag_tag">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TagTag_tag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "TagNode",
  "abstractKey": null
};

(node as any).hash = "7ad850c29992e4fcfe29400b0e463bcb";

export default node;
