import { Button, InputNumber, Switch } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

type Props = {
  allowRandomize?: boolean;
  totalCount: number | null | undefined;
  filteredCount: number | null | undefined;
  selectAllOnClick: () => void;
  selectNumberOnClick: (numberToSelect: number | undefined, randomize: boolean) => void;
  componentRef: React.RefObject<HTMLDivElement>;
};
const SelectAllPopoverContent: React.FC<Props> = ({
  allowRandomize,
  totalCount,
  filteredCount,
  selectAllOnClick,
  selectNumberOnClick,
  componentRef,
}) => {
  const [randomize, setRandomize] = useState(false);
  const [numberToSelect, setNumberToSelect] = useState<number | undefined>(undefined);

  return (
    <StyledPopoverContent>
      <div ref={componentRef}>
        {allowRandomize && (
          <div>
            <label className="randomize-toggle">
              Randomize selection
              <Switch size="small" style={{ marginLeft: 6 }} checked={randomize} onChange={setRandomize} />
            </label>
          </div>
        )}
        <div className="select-number">
          <InputNumber
            type="number"
            className="input-select-number"
            style={{ width: "100%" }}
            onChange={value => {
              if (totalCount !== null && totalCount !== undefined && totalCount >= 0) {
                if ((value ?? 0) <= totalCount) {
                  setNumberToSelect(value ?? undefined);
                } else {
                  setNumberToSelect(totalCount);
                }
              }
            }}
            value={numberToSelect}
            min={1}
          />
          <Button
            className="btn-select-number"
            type="primary"
            disabled={!numberToSelect}
            onClick={() => {
              selectNumberOnClick(numberToSelect, randomize);

              setRandomize(false);
              setNumberToSelect(undefined);
            }}
          >
            Select
          </Button>
        </div>
        <Button
          className="btn-select-all"
          type="primary"
          ghost
          onClick={selectAllOnClick}
        >{`Select all (${filteredCount?.toLocaleString()})`}</Button>
      </div>
    </StyledPopoverContent>
  );
};

const StyledPopoverContent = styled.div`
  .ant-popover-title {
    border-bottom: 0;
  }

  .ant-popover-inner-content {
    padding-top: 8px;
  }

  .randomize-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
  }

  .select-number {
    margin-bottom: 12px;
  }

  .select-number {
    display: flex;
    justify: space-between;

    .btn-select-number {
      width: 48%;
    }

    .input-select-number {
      margin-right: 8px;
      width: 48%;
    }
  }

  .btn-select-all {
    width: 100%;
  }
`;

export default SelectAllPopoverContent;
