/**
 * @generated SignedSource<<04059f5bc32b359d659996899b3303f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelistsReport_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PanelistModal_tenant">;
  readonly " $fragmentType": "PanelistsReport_tenant";
};
export type PanelistsReport_tenant$key = {
  readonly " $data"?: PanelistsReport_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistsReport_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelistsReport_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PanelistModal_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "09f07e7a6cdd7796b4461a28bcecdf1e";

export default node;
