import { Icon } from "@iconify/react";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import { Link, useRouter } from "found";
import React, { useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";

import { flattenEdges, useConfirmQuitEditing, useMutation } from "../../utils";
import type { InputSlotDetails_slot$key } from "../../__generated__/InputSlotDetails_slot.graphql";
import { InputSlotDetails_UpdateSlot_Mutation } from "../../__generated__/InputSlotDetails_UpdateSlot_Mutation.graphql";
import { InputTitle } from "../Configure/InputTitle";

export const InputSlotDetails = ({
  className,
  disabled: _disabled = false,
  slot,
  style,
}: {
  className?: Argument;
  disabled?: boolean;
  slot: InputSlotDetails_slot$key | null;
  style?: React.CSSProperties;
}) => {
  const { router } = useRouter();

  const { id, _value, ...slotFragmentRest } = useFragment(
    graphql`
      fragment InputSlotDetails_slot on StudyAvailabilitySlotNode {
        id
        _value: meetingDetails
        session {
          study {
            id
            eventsType
            sessions {
              edges {
                node {
                  id
                  name
                  duration
                  availabilitySlots {
                    edges {
                      node {
                        id
                        start
                        end
                        duration
                        interviewer {
                          email
                        }
                        inPerson
                        meetingDetails
                        meetingLink
                        meetingLocation
                        placesLimit
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    slot
  ) ?? { session: undefined };
  const study = slotFragmentRest?.session?.study;

  const [disabled, setDisabled] = useState(_disabled);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState<string | undefined>(undefined);

  const touched = useMemo(() => value !== _value, [_value, value]);

  useEffect(() => setDisabled(_disabled || !study?.id), [_disabled, study?.id]);
  useEffect(() => setValue(_value ?? undefined), [_value]);

  const [commit, loading] = useMutation<InputSlotDetails_UpdateSlot_Mutation>(graphql`
    mutation InputSlotDetails_UpdateSlot_Mutation($input: UpdateStudySessionsInput!) {
      updateStudySessions(input: $input) {
        study {
          id
          sessions {
            edges {
              node {
                availabilitySlots {
                  edges {
                    node {
                      id
                      meetingDetails
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const save = () => {
    if (study && value !== _value) {
      const sessions = flattenEdges(study.sessions)
        .filter((x): x is Exclude<typeof x, null | undefined> => !!x)
        .map(x => {
          const slots = flattenEdges(x.availabilitySlots)
            .filter((x): x is Exclude<typeof x, null | undefined> => !!x)
            .map(x => ({
              ...x,
              interviewer: x.interviewer?.email ?? null,
              placesLimit: x.placesLimit ?? null,
            }));

          return {
            ...x,
            availabilitySlots: [...slots.map(x => (x.id === id ? { ...x, meetingDetails: value } : x))],
          };
        });

      commit(
        {
          studyId: study.id,
          eventsType: study.eventsType,
          sessions,
        },
        {
          onCompleted: () => setModalVisible(false),
        }
      );
    } else setModalVisible(false);
  };

  const confirmQuitEditing = useConfirmQuitEditing();

  return (
    <>
      <Tooltip
        getPopupContainer={trigger => trigger.parentElement ?? document.body}
        title="Details"
        overlayStyle={{ paddingBottom: 4 }}
      >
        <div className={classNames("hub-input-slot-details", className)} style={style}>
          <Button
            disabled={disabled}
            style={{ padding: 4, marginTop: 4 }}
            type="text"
            onClick={() => setModalVisible(true)}
          >
            <Icon
              icon={_value ? "mdi:note-text" : "mdi:note-text-outline"}
              height="1.5em"
              style={{ color: _value ? "var(--ant-primary-color)" : "inherit" }}
            />
          </Button>
        </div>
      </Tooltip>
      <StyledModal
        cancelButtonProps={{ disabled: loading, type: "link" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ disabled: !touched, loading }}
        okText="Save"
        onCancel={() => setModalVisible(false)}
        onOk={() => save()}
        title="Meeting details"
        open={modalVisible}
      >
        <InputTitle text="Details" optional />
        <Form.Item
          help={
            <>
              <span>Details appear in invites, below the </span>
              <Link
                to={`/projects/${study?.id}/reminders`}
                onClick={async e => {
                  if (touched) {
                    e.preventDefault();
                    if (await confirmQuitEditing()) router.push(`/projects/${study?.id}/reminders`);
                  }
                }}
              >
                main invite message
              </Link>
            </>
          }
        >
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 16 }}
            maxLength={4096}
            onChange={e => setValue(e.target.value)}
            placeholder="Add info about the meeting to include in event invites"
            showCount
            size="large"
            value={value}
          />
        </Form.Item>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 24px 0;
    border-top: none;
  }

  .ant-form-item-explain {
    margin-right: 96px;

    a {
      white-space: nowrap;
      line-height: 1.3;
    }
  }
`;
