/**
 * @generated SignedSource<<dcc5add30c7c82d9329410597ac91d86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type Type = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PanelistCharacteristics_panelist$data = {
  readonly id: string;
  readonly person: {
    readonly email: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phoneNumber: string | null;
  } | null;
  readonly selectedResponses: ReadonlyArray<{
    readonly answers: ReadonlyArray<{
      readonly customAnswer: string | null;
      readonly key: string;
      readonly other: boolean | null;
      readonly row: string | null;
      readonly rowText: string | null;
      readonly selected: boolean;
      readonly text: string;
      readonly userSpecified: boolean | null;
    }>;
    readonly key: string;
    readonly text: string;
    readonly type: Type;
  }>;
  readonly studyParticipants: ReadonlyArray<{
    readonly respondent: {
      readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
      readonly startedOn: any | null;
    };
    readonly study: {
      readonly name: string | null;
      readonly tags: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"TagTag_tag">;
      }>;
    };
  } | null> | null;
  readonly " $fragmentType": "PanelistCharacteristics_panelist";
};
export type PanelistCharacteristics_panelist$key = {
  readonly " $data"?: PanelistCharacteristics_panelist$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelistCharacteristics_panelist">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelistCharacteristics_panelist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonNode",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ParticipantNode",
      "kind": "LinkedField",
      "name": "studyParticipants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyNode",
          "kind": "LinkedField",
          "name": "study",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TagNode",
              "kind": "LinkedField",
              "name": "tags",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TagTag_tag"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RespondentNode",
          "kind": "LinkedField",
          "name": "respondent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "masterStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startedOn",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SelectedResponse",
      "kind": "LinkedField",
      "name": "selectedResponses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SelectedResponseAnswer",
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "row",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "selected",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "other",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userSpecified",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customAnswer",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PanelistNode",
  "abstractKey": null
};
})();

(node as any).hash = "b2c69c6a8651809198fca2a3b89a699a";

export default node;
