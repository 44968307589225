/**
 * @generated SignedSource<<b2e13e1b46321ad8924ead8380da5186>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuditionVideoElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "AuditionVideoElement_screener";
};
export type AuditionVideoElement_screener$key = {
  readonly " $data"?: AuditionVideoElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuditionVideoElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuditionVideoElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "0fa566d2bbc975a72b474f8e1a31514e";

export default node;
