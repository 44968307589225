/**
 * @generated SignedSource<<d671f31683163da14dcda08fdd024ee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecruitDetails_YourPanel_Query$variables = {
  studyId: string;
};
export type RecruitDetails_YourPanel_Query$data = {
  readonly viewer: {
    readonly study: {
      readonly " $fragmentSpreads": FragmentRefs<"RecruitYourPanel_study">;
    } | null;
    readonly user: {
      readonly profile: {
        readonly tenant: {
          readonly name: string;
        };
      } | null;
    } | null;
  };
};
export type RecruitDetails_YourPanel_Query = {
  response: RecruitDetails_YourPanel_Query$data;
  variables: RecruitDetails_YourPanel_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "studyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "studyId",
    "variableName": "studyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecruitDetails_YourPanel_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RecruitYourPanel_study"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecruitDetails_YourPanel_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10826c028fdc0afc31b8f8152e6e1c43",
    "id": null,
    "metadata": {},
    "name": "RecruitDetails_YourPanel_Query",
    "operationKind": "query",
    "text": "query RecruitDetails_YourPanel_Query(\n  $studyId: String!\n) {\n  viewer {\n    study(studyId: $studyId) {\n      ...RecruitYourPanel_study\n      id\n    }\n    user {\n      profile {\n        tenant {\n          name\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment RecruitYourPanel_study on StudyNode {\n  id\n}\n"
  }
};
})();

(node as any).hash = "ca76b9e27f84d2126fa2bae3a20a83b0";

export default node;
