import { Icon } from "@iconify/react";
import { ConfigProvider, Layout, Menu, Popover, Tooltip, Typography, type MenuProps } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import mixpanel from "mixpanel-browser";
import { PropsWithChildren, useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ThemeColor } from "../../antd";
import { HEADER_NARROW_PX, HEADER_WIDE_PX, PAGE_PADDING } from "../../style";
import { getUserContext, trackEvent, useFlag, useLogrocketInit } from "../../utils";
import { truncate } from "../../utils/misc";
import { StaffLayout_user$data } from "../../__generated__/StaffLayout_user.graphql";
import CookieConsentBar from "../CookieConsentBar";
import { Logo } from "../Logo";
import { NotificationsPoller } from "../Notifications/NotificationsPoller";
import { SmallLogo } from "../SmallLogo";

const { Sider } = Layout;
const { Paragraph, Text } = Typography;

const ToolTipTitle = (user: StaffLayout_user$data) => (
  <div>
    <div>{user.fullName || user.email}</div>
    <div>{user?.profile?.tenant?.name || ""}</div>
  </div>
);

const StaffLayout = ({
  children,
  user,
  menu,
  menuState,
  router,
  match,
}: PropsWithChildren<{
  user: StaffLayout_user$data;
  menu: JSX.Element;
  menuState: { open?: boolean; setOpen: (value?: boolean) => void };
  match: any;
  router: any;
}>) => {
  useLogrocketInit();

  const [navWide, setNavWide] = useState();
  const [selected, setSelected] = useState<any>();
  const hasVPMLeftNav = useFlag("vpm-left-hand-nav");

  useEffect(() => {
    const isNavWide =
      match.location.pathname === "/projects" ||
      match.location.pathname.includes("/panel") ||
      match.location.pathname === "/billing";
    setNavWide(isNavWide);

    if (match.location.pathname.includes("/panel")) {
      setSelected("panel");
    } else if (match.location.pathname.includes("/projects")) {
      setSelected("projects");
    } else if (match.location.pathname.includes("/billing")) {
      setSelected("billing");
    }
  }, [match]);

  useEffect(() => {
    // track new user registrations
    if (new URL(document.location.href).searchParams.has("welcome")) {
      trackEvent("User Registered", getUserContext(user as any));
    }

    mixpanel.people.set_once({
      Email: user.email,
    });
  }, [user]);

  const menuItems: MenuProps["items"] = [
    {
      key: "projects",
      icon: <Icon width="20" icon="ic:outline-view-agenda" />,
      label: navWide ? <h3>Projects</h3> : null,
      onClick: () => router.push("/projects"),
    },
    {
      key: "panel",
      icon: <Icon width="20" icon="ic:baseline-groups" />,
      label: navWide ? <h3>Community</h3> : null,
      onClick: () => router.push(user?.profile?.tenant?.enablePanelView ? "/panel/report" : "/panel-setup"),
    },
  ];

  if (!user.profile?.tenant?.hideBillingPage) {
    menuItems.push({
      key: "billing",
      icon: <Icon width="20" icon="ic:outline-request-quote" />,
      label: navWide ? <h3>Billing</h3> : null,
      onClick: () => router.push("/billing"),
    });
  }

  if (hasVPMLeftNav) {
    menuItems.push({
      key: "vpm",
      className: !user?.vpmUserId ? "vpm-pendo-popup" : "",
      icon: <SmallLogo width={20} height={20} negate={selected === "vpm"} />,
      label: navWide ? <h3 className="vpm-link">Analyze</h3> : null,
      onClick: () => {
        if (user?.vpmUserId) {
          window.open("https://www.voxpopme.com/portal/", "_self");
        }
      },
    });
  }

  return (
    <LayoutWrapper $navWide={navWide}>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              colorItemBgHover: ThemeColor.VoxGrayHover,
              colorItemBgSelected: ThemeColor.VoxGrape,
              colorItemTextSelected: ThemeColor.VoxLavender,
              colorTextBase: ThemeColor.VoxGray,
            },
          },
          token: {
            colorTextBase: ThemeColor.VoxGray,
          },
        }}
      >
        <Sider className="sider" theme="light" width={navWide ? HEADER_WIDE_PX : HEADER_NARROW_PX}>
          <div className="logo-wrapper" onClick={() => router.push("/projects")}>
            {navWide ? <Logo /> : <SmallLogo />}
          </div>
          <Menu
            className="menu"
            onClick={e => setSelected(e?.key)}
            items={menuItems}
            selectedKeys={selected}
            style={{ borderInlineEndStyle: "none" }}
          />
          <Popover
            trigger="click"
            className="dropdown"
            content={menu}
            open={menuState.open}
            onOpenChange={open => menuState.setOpen(open || undefined)}
            placement="rightBottom"
            showArrow={false}
            overlayStyle={{ paddingLeft: navWide ? 32 : 30 }}
            overlayInnerStyle={{ padding: 0 }}
          >
            <div className="user-info">
              <Tooltip title={<ToolTipTitle {...user} />} placement="right">
                <Text>
                  <Icon icon="mdi:account" width="20" />
                </Text>
              </Tooltip>
              {navWide && (
                <>
                  <div className="user-info--details">
                    <Paragraph strong>{truncate(user.fullName || user.email.toLowerCase(), 12)}</Paragraph>
                    <Paragraph type="secondary">{truncate(user?.profile?.tenant?.name || "", 12)}</Paragraph>
                  </div>
                  <Text>
                    <Icon width="18" icon="mdi:chevron-right" />
                  </Text>
                </>
              )}
            </div>
          </Popover>
        </Sider>
      </ConfigProvider>
      <Layout.Content className="content">{children}</Layout.Content>
      <CookieConsentBar location="top" />
      <NotificationsPoller />
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled(Layout)<{ $navWide?: boolean }>`
  margin: auto;

  .ant-layout-sider-children {
    ${props =>
      props.$navWide
        ? ""
        : `
     display: flex;
     flex-direction: column;
     align-items: center;
    `}
  }

  .ant-menu-item {
    width: ${props => (props.$navWide ? "initial" : "40px")};
    display: flex;
    align-items: center;
    justify-content: ${props => (props.$navWide ? "initial" : "center")};
    margin-top: 0;
    padding: ${props => (props.$navWide ? "0 16px" : "0")};

    h3 {
      font-weight: normal;
      font-size: 14px;
      margin: 0;
      color: inherit;
      padding-left: 16px;
    }

    .vpm-link {
      margin-left: 10px;
    }
  }

  .ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected {
    border-bottom: 2px solid var(--ant-primary-color);
    color: #fff;
  }

  .ant-menu-horizontal > .ant-menu-item:not(.ant-menu-item-selected) {
    border-bottom: 2px solid #33394f;
  }

  .sider {
    border-right: 1px solid ${ThemeColor.VoxStroke};
    overflow: auto;
    left: 0;
    position: fixed;
    height: 100vh;
    z-index: 100;
    padding: 40px ${props => (props?.$navWide ? PAGE_PADDING : "12px")};
    transition: none;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
  }

  .menu {
    margin-top: 125px;
    background-color: inherit;
    border: none;

    svg + * {
      display: ${props => (props?.$navWide ? "initial" : "none")};
    }
  }

  .dropdown {
    position: absolute;
    bottom: 0;
    cursor: pointer;
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: ${props => (props?.$navWide ? "175px" : "auto")};
    margin: 24px 0;
    word-break: break-word;

    &--details {
      flex: 1;
      overflow: hidden;
    }

    .ant-typography {
      margin: 0;
      line-height: 1.3;
    }
  }

  .content {
    min-height: 100vh;
    margin-left: ${props => (props?.$navWide ? parseInt(HEADER_WIDE_PX) : HEADER_NARROW_PX)}px;
    display: flex;
    flex-direction: column;

    & > header ~ * {
      margin-left: 24px;
    }

    .hub-layout-fill {
      flex: 1;
    }
  }
`;

export default createFragmentContainer(StaffLayout, {
  user: graphql`
    fragment StaffLayout_user on UserNode {
      dId
      email
      fullName
      panelist {
        dId
        tenants {
          edges {
            node {
              dId
              name
            }
          }
        }
      }
      profile {
        tenant {
          dId
          enablePanelView
          hideBillingPage
          name
        }
      }
      dId
      vpmUserId
      panelist {
        dId
      }
    }
  `,
});
