import { useIntervalEffect, useSafeState, useUpdateEffect } from "@react-hookz/web";
import { Input, Tabs } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import moment from "moment";
import { createContext, useCallback, useContext, useMemo, useState, type CSSProperties, type ReactNode } from "react";
import { useFragment, useMutation } from "react-relay";
import styled from "styled-components";

import { Interval } from "../constants";
import type { NoteNode } from "../schema";
import { GRAY_7, GRAY_8 } from "../style";
import type { State } from "../types";
import { onError } from "../utils";
import type { Note_panelist$key } from "../__generated__/Note_panelist.graphql";
import type { Note_respondent$key } from "../__generated__/Note_respondent.graphql";
import type { Note_UpdateNote_Mutation } from "../__generated__/Note_UpdateNote_Mutation.graphql";
import { ButtonLabel } from "./ButtonLabel";

const TEXT_PLACEHOLDER = "Add notes, i.e. how the last interview went, important details about the panelist, etc.";

export const useNoteIsEditingState = (initialState: boolean | (() => boolean) = false) => useState(initialState);
export const NoteIsEditingStateContext = createContext<State<boolean> | null>(null);

const formatModified = (note: Pick<NoteNode, "modified"> | null | undefined) =>
  note?.modified ? moment(note?.modified).fromNow() : null;

const enum TabKey {
  "Panelist" = "P",
  "Respondent" = "R",
}

export const Note = ({
  className,
  panelistKey = null,
  panelistNoteHeading = "Panelist notes",
  respondentKey = null,
  respondentNoteHeading = "Project notes",
  style,
}: {
  className?: Argument;
  panelistKey?: Note_panelist$key | null;
  panelistNoteHeading?: ReactNode;
  respondentKey?: Note_respondent$key | null;
  respondentNoteHeading?: ReactNode;
  style?: CSSProperties;
}) => {
  const { id: panelistId, tenantNote: panelistNote } =
    useFragment(
      graphql`
        fragment Note_panelist on PanelistNode {
          id
          tenantNote {
            id
            modified
            modifiedBy {
              firstName
            }
            text
          }
        }
      `,
      panelistKey
    ) ?? {};

  const { id: respondentId, note: respondentNote } =
    useFragment(
      graphql`
        fragment Note_respondent on RespondentNode {
          id
          note {
            id
            modified
            modifiedBy {
              firstName
            }
            text
          }
        }
      `,
      respondentKey
    ) ?? {};

  const _tabKey = panelistId ? TabKey.Panelist : TabKey.Respondent;
  const [tabKey, setTabKey] = useState(_tabKey);
  useUpdateEffect(() => setTabKey(_tabKey), [`${panelistId}${respondentId}`]);

  const showTabs = useMemo(() => !!panelistId && !!respondentId, [panelistId, respondentId]);

  const note = useMemo(
    () => (tabKey === TabKey.Panelist ? panelistNote : respondentNote),
    [tabKey, panelistNote, respondentNote]
  );

  const _text = note?.text ?? undefined;
  const [text, setText] = useState(_text);
  useUpdateEffect(() => setText(_text), [note]);

  const [modifiedPretty, setModifiedPretty] = useSafeState(formatModified(note));
  useUpdateEffect(() => setModifiedPretty(formatModified(note)), [note]);
  useIntervalEffect(() => setModifiedPretty(formatModified(note)), Interval.RealTime);

  const isEditingState = useNoteIsEditingState();
  const isEditingStateContext = useContext(NoteIsEditingStateContext);
  const [isEditing, setIsEditing] = useMemo(
    () => isEditingStateContext ?? isEditingState,
    [isEditingState, isEditingStateContext]
  );
  useUpdateEffect(() => {
    if (!isEditing) setText(_text);
  }, [isEditing]);

  const [commit, isInFlight] = useMutation<Note_UpdateNote_Mutation>(graphql`
    mutation Note_UpdateNote_Mutation($input: UpdateNoteInput!) {
      updateNote(input: $input) {
        note {
          id
          panelist {
            ...Note_panelist
          }
          respondent {
            ...Note_respondent
          }
        }
      }
    }
  `);

  const save = useCallback(() => {
    if (text !== _text)
      commit({
        variables: {
          input: { text: text ?? null, ...(tabKey === TabKey.Panelist ? { panelistId } : { respondentId }) },
        },
        onCompleted: () => setIsEditing(false),
        onError,
      });
    else setIsEditing(false);
  }, [_text, commit, panelistId, respondentId, setIsEditing, tabKey, text]);

  const actions = useMemo(
    () =>
      isEditing ? (
        <ButtonLabel
          disabled={isInFlight}
          icon="mdi:check"
          iconScale={1.5}
          loading={isInFlight}
          onClick={() => save()}
          text="Save"
          type="link"
        />
      ) : (
        <ButtonLabel
          icon="mdi:pencil-outline"
          iconScale={1.5}
          onClick={() => setIsEditing(true)}
          text="Edit"
          type="link"
        />
      ),
    [isEditing, isInFlight, save, setIsEditing]
  );

  return panelistId || respondentId ? (
    <Section className={classNames(className)} style={style}>
      <header>
        {showTabs ? (
          <Tabs activeKey={tabKey} onChange={key => setTabKey(key as TabKey)}>
            <Tabs.TabPane disabled={isEditing || isInFlight} key={TabKey.Panelist} tab={panelistNoteHeading} />
            <Tabs.TabPane disabled={isEditing || isInFlight} key={TabKey.Respondent} tab={respondentNoteHeading} />
          </Tabs>
        ) : tabKey === TabKey.Panelist ? (
          <h3>{panelistNoteHeading}</h3>
        ) : (
          <h3>{respondentNoteHeading}</h3>
        )}
        {!showTabs && actions}
      </header>
      {isEditing ? (
        <Input.TextArea
          autoSize={{ maxRows: 10 }}
          disabled={isInFlight}
          maxLength={4096}
          placeholder={TEXT_PLACEHOLDER}
          showCount
          value={text}
          onChange={e => setText(e.target.value || undefined)}
        />
      ) : note?.text ? (
        <p>{note.text}</p>
      ) : (
        <p className="tertiary">{TEXT_PLACEHOLDER}</p>
      )}
      <footer>
        {showTabs && actions}
        {note?.text && (
          <p className="attribution">
            {note.modifiedBy?.firstName ?? "Someone"} edited {modifiedPretty}
          </p>
        )}
      </footer>
    </Section>
  ) : null;
};

export const PANELIST_NOTE_MARGIN_INNER = "8px";

const Section = styled.section`
  font-size: 1rem;

  header {
    display: flex;
    align-items: center;
    gap: ${PANELIST_NOTE_MARGIN_INNER};
    margin-bottom: ${PANELIST_NOTE_MARGIN_INNER};
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }

  h3 {
    color: inherit;
    font-size: inherit;
    font-weight: 500;
    margin: 0;
  }

  button {
    font-weight: 500;
    margin: -${PANELIST_NOTE_MARGIN_INNER} 0;
  }

  p {
    white-space: pre-line;
  }

  textarea {
    margin-bottom: ${PANELIST_NOTE_MARGIN_INNER};
  }

  .ant-input-textarea-show-count::after {
    font-size: 0.75rem;
  }

  .tertiary {
    color: ${GRAY_7};
  }

  footer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: ${PANELIST_NOTE_MARGIN_INNER};

    .ant-btn {
      padding: 4px;
      margin: -4px 0;
    }
  }

  .attribution {
    color: ${GRAY_8};
    font-size: 12px;
    margin-bottom: 0;

    &:not:only-child {
      margin-top: 2px;
    }
  }
`;
