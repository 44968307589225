import { Tabs } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Dispatch, SetStateAction } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { PROJECT_TYPES } from "../../constants";

import type { ParticipantsTableHeader_study$data } from "../../__generated__/ParticipantsTableHeader_study.graphql";

import { TabType } from "./ParticipantsTable";

const ParticipantsTableHeader = ({
  study,
  setSelectedTab,
  needsReviewCount,
  approvedTabCount,
  scheduledCount,
  allCount,
  overquotaCount,
  needsPaymentCount,
  completeCount,
  rejectedCount,
}: {
  study: ParticipantsTableHeader_study$data;
  setSelectedTab: Dispatch<SetStateAction<TabType>>;
  needsReviewCount: number;
  approvedTabCount: number;
  scheduledCount: number;
  allCount: number;
  overquotaCount: number;
  needsPaymentCount: number;
  completeCount: number;
  rejectedCount: number;
}) => (
  <StyledHeader>
    <Tabs
      defaultActiveKey={TabType.ALL}
      items={[
        {
          key: TabType.ALL,
          label: `All (${allCount.toLocaleString()})`,
        },
        {
          key: TabType.SCREENER_COMPLETED,
          label: `Screener completed (${needsReviewCount.toLocaleString()})`,
        },
        ...(study.type !== PROJECT_TYPES.SURVEY || study.usesApiToMarkComplete
          ? [
              {
                key: TabType.APPROVED,
                label: `Approved (${approvedTabCount.toLocaleString()})`,
              },
            ]
          : []),
        ...(study.type === PROJECT_TYPES.SURVEY
          ? [
              {
                key: TabType.NEEDS_REWARDING,
                label: `Survey completed (${needsPaymentCount.toLocaleString()})`,
              },
            ]
          : [
              {
                key: TabType.SCHEDULED,
                label: `Scheduled (${scheduledCount.toLocaleString()})`,
              },
              {
                key: TabType.NEEDS_REWARDING,
                label: `Interviewed (${needsPaymentCount.toLocaleString()})`,
              },
            ]),
        {
          key: TabType.COMPLETE,
          label: `Completed (${completeCount.toLocaleString()})`,
        },
        {
          key: TabType.REJECTED,
          label: `Rejected (${rejectedCount.toLocaleString()})`,
        },
        {
          key: TabType.OVER_QUOTA,
          label: `Overquota (${overquotaCount.toLocaleString()})`,
        },
      ]}
      onChange={key => setSelectedTab(key as TabType)}
      tabBarStyle={{ fontWeight: 500 }}
      type="card"
    />
  </StyledHeader>
);

const StyledHeader = styled.div`
  .ant-tabs-top .ant-tabs-nav {
    ::before {
      border: 0;
    }

    margin-bottom: 0;
  }
`;

export default createFragmentContainer(ParticipantsTableHeader, {
  study: graphql`
    fragment ParticipantsTableHeader_study on StudyNode {
      id
      dId
      export {
        ...ExportButton_export
      }
      name
      type
      usesApiToMarkComplete
    }
  `,
});
