/**
 * @generated SignedSource<<452ec880342a5658e7d2417f9a8a3664>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetGivePointsToAllParticipantsCostsInput = {
  clientMutationId?: string | null;
  countToSelect?: number | null;
  excludeIds?: ReadonlyArray<string | null> | null;
  excludeNonTest?: boolean | null;
  filterComplex?: any | null;
  includeTest?: boolean | null;
  points: number;
  studyId: string;
  tab?: string | null;
};
export type GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation$variables = {
  input: GetGivePointsToAllParticipantsCostsInput;
};
export type GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation$data = {
  readonly getGivePointsToAllParticipantsCosts: {
    readonly costsSummary: {
      readonly feesUsdCents: number;
      readonly nonTestParticipantCount: number;
      readonly pointsCostUsdCents: number;
      readonly testParticipantCount: number;
    };
  } | null;
};
export type GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation = {
  response: GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation$data;
  variables: GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetGivePointsToAllParticipantsCostsPayload",
    "kind": "LinkedField",
    "name": "getGivePointsToAllParticipantsCosts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GivePointsCostsSummary",
        "kind": "LinkedField",
        "name": "costsSummary",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointsCostUsdCents",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feesUsdCents",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "testParticipantCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nonTestParticipantCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9bdde442e10198c051a85f68903db663",
    "id": null,
    "metadata": {},
    "name": "GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation",
    "operationKind": "mutation",
    "text": "mutation GivePointsMutations_getGivePointsToAllParticipantsCosts_Mutation(\n  $input: GetGivePointsToAllParticipantsCostsInput!\n) {\n  getGivePointsToAllParticipantsCosts(input: $input) {\n    costsSummary {\n      pointsCostUsdCents\n      feesUsdCents\n      testParticipantCount\n      nonTestParticipantCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd8a447ac0903c5577f7387825a276f9";

export default node;
