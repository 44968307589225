/**
 * @generated SignedSource<<1ae75c8a1352e182b039684000f907f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRespondentApprovalStatusChoices = "A" | "D" | "NR" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type MarkRespondentInterviewedInput = {
  clientMutationId?: string | null;
  respondentId: string;
};
export type ScheduleCell_MarkRespondentInterviewed_Mutation$variables = {
  input: MarkRespondentInterviewedInput;
};
export type ScheduleCell_MarkRespondentInterviewed_Mutation$data = {
  readonly markRespondentInterviewed: {
    readonly respondent: {
      readonly approvalStatus: ScreenersRespondentApprovalStatusChoices | null;
      readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
      readonly screener: {
        readonly study: {
          readonly approvedCount: number | null;
          readonly needsPaymentCount: number | null;
        };
      };
    };
  } | null;
};
export type ScheduleCell_MarkRespondentInterviewed_Mutation = {
  response: ScheduleCell_MarkRespondentInterviewed_Mutation$data;
  variables: ScheduleCell_MarkRespondentInterviewed_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvalStatus",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterStatus",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsPaymentCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleCell_MarkRespondentInterviewed_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkRespondentInterviewedPayload",
        "kind": "LinkedField",
        "name": "markRespondentInterviewed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RespondentNode",
            "kind": "LinkedField",
            "name": "respondent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyNode",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleCell_MarkRespondentInterviewed_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkRespondentInterviewedPayload",
        "kind": "LinkedField",
        "name": "markRespondentInterviewed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RespondentNode",
            "kind": "LinkedField",
            "name": "respondent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScreenerNode",
                "kind": "LinkedField",
                "name": "screener",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudyNode",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8edfad773e96538ef3154c2813903d66",
    "id": null,
    "metadata": {},
    "name": "ScheduleCell_MarkRespondentInterviewed_Mutation",
    "operationKind": "mutation",
    "text": "mutation ScheduleCell_MarkRespondentInterviewed_Mutation(\n  $input: MarkRespondentInterviewedInput!\n) {\n  markRespondentInterviewed(input: $input) {\n    respondent {\n      approvalStatus\n      masterStatus\n      screener {\n        study {\n          approvedCount\n          needsPaymentCount\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f4ccef05ea2f021aea1c44e2a7c5d02";

export default node;
