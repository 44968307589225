/**
 * @generated SignedSource<<34a1ce6e3722f79bbae93572c4cad159>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResponseValidationType = "AL" | "AM" | "BE" | "EX" | "%future added value";
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
export type SaveCharacteristicInput = {
  answers?: ReadonlyArray<CharacteristicAnswerInput | null> | null;
  clientMutationId?: string | null;
  elementMeta?: CharacteristicElementMetaInput | null;
  id?: string | null;
  importKey?: string | null;
  rows?: ReadonlyArray<CharacteristicRowInput | null> | null;
  shortName?: string | null;
  showToPanelists?: boolean | null;
  text?: string | null;
  type?: string | null;
};
export type CharacteristicAnswerInput = {
  exclusive?: boolean | null;
  id?: string | null;
  other?: boolean | null;
  position?: number | null;
  text?: string | null;
  userSpecified?: boolean | null;
};
export type CharacteristicElementMetaInput = {
  randomize?: boolean | null;
  responseValidation?: ResponseValidationType | null;
  target?: number | null;
  target2?: number | null;
  targetTypes?: ReadonlyArray<string> | null;
};
export type CharacteristicRowInput = {
  id?: string | null;
  text?: string | null;
};
export type mutations_saveCharacteristic_Mutation$variables = {
  input: SaveCharacteristicInput;
};
export type mutations_saveCharacteristic_Mutation$data = {
  readonly saveCharacteristic: {
    readonly tenant: {
      readonly characteristics: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly answers: ReadonlyArray<{
              readonly id: string;
              readonly other: boolean;
              readonly position: number;
              readonly text: string;
              readonly userSpecified: boolean;
            }>;
            readonly elementMeta: {
              readonly randomize: boolean | null;
              readonly responseValidation: ResponseValidationType | null;
              readonly target: number | null;
              readonly target2: number | null;
              readonly targetTypes: ReadonlyArray<string> | null;
            };
            readonly id: string;
            readonly importKey: string;
            readonly rows: ReadonlyArray<{
              readonly id: string;
              readonly text: string;
            }>;
            readonly shortName: string;
            readonly showToPanelists: boolean;
            readonly text: string | null;
            readonly type: ScreenersCharacteristicTypeChoices;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type mutations_saveCharacteristic_Mutation = {
  response: mutations_saveCharacteristic_Mutation$data;
  variables: mutations_saveCharacteristic_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CharacteristicNodeConnection",
  "kind": "LinkedField",
  "name": "characteristics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "importKey",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicElementMetaNode",
              "kind": "LinkedField",
              "name": "elementMeta",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "randomize",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "responseValidation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "target",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "target2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "targetTypes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicAnswerNode",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "other",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userSpecified",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicRowNode",
              "kind": "LinkedField",
              "name": "rows",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showToPanelists",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_saveCharacteristic_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveCharacteristicPayload",
        "kind": "LinkedField",
        "name": "saveCharacteristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutations_saveCharacteristic_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveCharacteristicPayload",
        "kind": "LinkedField",
        "name": "saveCharacteristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNode",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12d360a50a338a9b55e225198dbe9b85",
    "id": null,
    "metadata": {},
    "name": "mutations_saveCharacteristic_Mutation",
    "operationKind": "mutation",
    "text": "mutation mutations_saveCharacteristic_Mutation(\n  $input: SaveCharacteristicInput!\n) {\n  saveCharacteristic(input: $input) {\n    tenant {\n      characteristics {\n        edges {\n          node {\n            id\n            type\n            shortName\n            importKey\n            text\n            elementMeta {\n              randomize\n              responseValidation\n              target\n              target2\n              targetTypes\n            }\n            answers {\n              id\n              text\n              other\n              userSpecified\n              position\n            }\n            rows {\n              id\n              text\n            }\n            showToPanelists\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "57d7e0a725316bed985f691028571bad";

export default node;
