import { Icon, IconifyIcon } from "@iconify/react";
import { Button, ButtonProps } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  icon?: IconifyIcon;
  children: ReactNode;
} & ButtonProps;
export const IconButton = ({ icon, children, ...rest }: Props) => (
  <StyledButton {...rest}>
    {icon && (
      <div className="icon-container">
        <Icon icon={icon} />
      </div>
    )}
    <div>{children}</div>
  </StyledButton>
);

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;

  .icon-container {
    padding-top: 4px;
    margin-right: 4px;
  }
`;
