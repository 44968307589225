/**
 * @generated SignedSource<<28ababfa27408e5a100c951ab0f392c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
export type ScreenersParticipantIncentivizedTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersParticipantRsvpChoices = "ACCEPTED" | "DECLINED" | "NEEDSACTION" | "TENTATIVE" | "%future added value";
export type ScreenersRecruitStatusChoices = "F" | "NS" | "P" | "S" | "%future added value";
export type ScreenersRespondentApprovalStatusChoices = "A" | "D" | "NR" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type ScreenersRespondentStatusChoices = "OQ" | "Q" | "T" | "%future added value";
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyScheduledByChoices = "P" | "T" | "%future added value";
export type ScreenersStudySchedulingTypeChoices = "CL" | "HX" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ParticipantsTable_study$data = {
  readonly allCount: number | null;
  readonly allowAutoApproval: boolean;
  readonly approvedCount: number | null;
  readonly approvedTabCount: number | null;
  readonly availabilitySlots: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly completeCount: number | null;
  readonly dId: any | null;
  readonly defaultIncentive: string | null;
  readonly defaultIncentivePoints: number | null;
  readonly export: {
    readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
  } | null;
  readonly id: string;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly name: string | null;
  readonly needsPaymentCount: number | null;
  readonly needsReviewCount: number | null;
  readonly overquotaCount: number | null;
  readonly participantsDetail: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly bonusPoints: ReadonlyArray<{
          readonly orderDate: any;
          readonly pointsAmount: number;
        } | null>;
        readonly id: string;
        readonly imported: boolean;
        readonly incentivized: boolean;
        readonly incentivizedAmount: string | null;
        readonly incentivizedOn: any | null;
        readonly incentivizedPointAmount: number | null;
        readonly incentivizedType: ScreenersParticipantIncentivizedTypeChoices | null;
        readonly noShow: boolean;
        readonly rating: number;
        readonly respondent: {
          readonly approvalStatus: ScreenersRespondentApprovalStatusChoices | null;
          readonly dId: any | null;
          readonly id: string;
          readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
          readonly person: {
            readonly email: string | null;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly optIn: boolean;
            readonly panelist: {
              readonly dId: any | null;
              readonly " $fragmentSpreads": FragmentRefs<"Note_panelist">;
            } | null;
            readonly panelistDId: string | null;
            readonly phoneNumber: string | null;
          } | null;
          readonly recruit: {
            readonly incentive: string | null;
            readonly incentivePoints: number | null;
            readonly incentivesClearDate: any | null;
            readonly isTest: boolean;
            readonly name: string | null;
          } | null;
          readonly responses: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly element: {
                  readonly type: ScreenersElementTypeChoices;
                };
                readonly value: string;
              } | null;
            } | null>;
          };
          readonly rhAnswer: string | null;
          readonly scheduledSlots: ReadonlyArray<{
            readonly date: any;
            readonly end: any;
            readonly interviewed: boolean;
            readonly session: {
              readonly name: string;
            } | null;
            readonly start: any;
          }>;
          readonly screenerResults: any | null;
          readonly segmentResponses: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly segment: {
                  readonly text: string | null;
                };
              } | null;
            } | null>;
          };
          readonly status: ScreenersRespondentStatusChoices | null;
          readonly " $fragmentSpreads": FragmentRefs<"AlertVoxpopmeVideoAnalysis_respondent" | "Cells_NextStepCell_respondent" | "Note_respondent" | "RespondentResponses_respondent" | "ScheduleModal_respondent">;
        };
        readonly rsvp: ScreenersParticipantRsvpChoices;
      } | null;
    } | null>;
    readonly totalCount: number | null;
  } | null;
  readonly recruits: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly status: ScreenersRecruitStatusChoices;
      } | null;
    } | null>;
  };
  readonly rejectedCount: number | null;
  readonly scheduled: any | null;
  readonly scheduledBy: ScreenersStudyScheduledByChoices;
  readonly scheduledCount: number | null;
  readonly schedulingType: ScreenersStudySchedulingTypeChoices;
  readonly screener: {
    readonly elements: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly answers: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly dbId: string;
                readonly position: number;
                readonly text: string | null;
              } | null;
            } | null>;
          };
          readonly dbId: string;
          readonly position: number;
          readonly rows: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly dbId: string;
                readonly position: number;
                readonly text: string;
              } | null;
            } | null>;
          };
          readonly text: string | null;
          readonly type: ScreenersElementTypeChoices;
        } | null;
      } | null>;
    } | null;
    readonly segments: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
  } | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly customportal: {
      readonly hidePointsAndRedemption: boolean;
    } | null;
    readonly dId: any | null;
    readonly hideParticipantEmails: boolean;
    readonly name: string;
    readonly vpmAccountId: number | null;
  };
  readonly testRecruit: {
    readonly completedScreenerCount: number | null;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"AlertVoxpopmeVideoAnalysis_study" | "BulkScheduleModal_study" | "Cells_NextStepCell_study" | "Cells_ReviewCell_study" | "ConfirmExternalIncentiveModal_study" | "EditExternalIncentiveEmailModal_study" | "ParticipantBatchActions_study" | "ParticipantPaymentModal_study" | "ParticipantsTableHeader_study" | "ScheduleModal_study" | "SlotPicker_study">;
  readonly " $fragmentType": "ParticipantsTable_study";
};
export type ParticipantsTable_study$key = {
  readonly " $data"?: ParticipantsTable_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantsTable_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = [
  (v0/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "elementIds"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "excludeNonTest"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterComplex"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeTest"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tab"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "participantsDetail"
        ]
      }
    ]
  },
  "name": "ParticipantsTable_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExportNode",
      "kind": "LinkedField",
      "name": "export",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExportButton_export"
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultIncentivePoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedulingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAutoApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overquotaCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsReviewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedTabCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsPaymentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completeCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rejectedCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AlertVoxpopmeVideoAnalysis_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BulkScheduleModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Cells_NextStepCell_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Cells_ReviewCell_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmExternalIncentiveModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditExternalIncentiveEmailModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantBatchActions_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantPaymentModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantsTableHeader_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduleModal_study"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SlotPicker_study"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyAvailabilitySlotConnection",
      "kind": "LinkedField",
      "name": "availabilitySlots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyAvailabilitySlotEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyAvailabilitySlotNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitNodeConnection",
      "kind": "LinkedField",
      "name": "recruits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecruitNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitNode",
      "kind": "LinkedField",
      "name": "testRecruit",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedScreenerCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScreenerNode",
      "kind": "LinkedField",
      "name": "screener",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id_In",
              "variableName": "elementIds"
            }
          ],
          "concreteType": "ElementNodeConnection",
          "kind": "LinkedField",
          "name": "elements",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ElementNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ElementNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v3/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AnswerNodeConnection",
                      "kind": "LinkedField",
                      "name": "answers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AnswerNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AnswerNode",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v7/*: any*/),
                                (v8/*: any*/),
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RowNodeConnection",
                      "kind": "LinkedField",
                      "name": "rows",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RowNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "RowNode",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v6/*: any*/),
                                (v8/*: any*/),
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SegmentNodeConnection",
          "kind": "LinkedField",
          "name": "segments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SegmentNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SegmentNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "participantsDetail",
      "args": [
        {
          "kind": "Variable",
          "name": "filterComplex",
          "variableName": "filterComplex"
        },
        {
          "kind": "Variable",
          "name": "tab",
          "variableName": "tab"
        }
      ],
      "concreteType": "ParticipantConnection",
      "kind": "LinkedField",
      "name": "__ParticipantsTable_participantsDetail_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ParticipantEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ParticipantNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RespondentNode",
                  "kind": "LinkedField",
                  "name": "respondent",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rhAnswer",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approvalStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "masterStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ElementResponseNodeConnection",
                      "kind": "LinkedField",
                      "name": "responses",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ElementResponseNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ElementResponseNode",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ElementNode",
                                  "kind": "LinkedField",
                                  "name": "element",
                                  "plural": false,
                                  "selections": [
                                    (v3/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "value",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "screenerResults",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SegmentResponseNodeConnection",
                      "kind": "LinkedField",
                      "name": "segmentResponses",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SegmentResponseNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SegmentResponseNode",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SegmentNode",
                                  "kind": "LinkedField",
                                  "name": "segment",
                                  "plural": false,
                                  "selections": [
                                    (v8/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RespondentScheduledSlotNode",
                      "kind": "LinkedField",
                      "name": "scheduledSlots",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StudySessionNode",
                          "kind": "LinkedField",
                          "name": "session",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "date",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "start",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "end",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "interviewed",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PersonNode",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "phoneNumber",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "optIn",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "panelistDId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PanelistNode",
                          "kind": "LinkedField",
                          "name": "panelist",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "Note_panelist"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RecruitNode",
                      "kind": "LinkedField",
                      "name": "recruit",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isTest",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "incentive",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "incentivePoints",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "incentivesClearDate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AlertVoxpopmeVideoAnalysis_respondent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Cells_NextStepCell_respondent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Note_respondent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "RespondentResponses_respondent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ScheduleModal_respondent"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rsvp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "noShow",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rating",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incentivized",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incentivizedType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incentivizedAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incentivizedOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incentivizedPointAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imported",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParticipantBonusPointsNode",
                  "kind": "LinkedField",
                  "name": "bonusPoints",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orderDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pointsAmount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmAccountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideParticipantEmails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customportal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePointsAndRedemption",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "26e79680ef2c5bcf1a7d5a76e66270ac";

export default node;
