/**
 * @generated SignedSource<<4bfe92e416ebf8ec148176e24a9f56e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SpendingDetails_study$data = {
  readonly dId: any | null;
  readonly goalPaidForCount: number | null;
  readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
  readonly recruits: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fee: any | null;
        readonly goalPaidForCount: number | null;
        readonly name: string | null;
        readonly participantBaseCost: string | null;
        readonly totalDeposits: any | null;
        readonly totalPayouts: any | null;
        readonly totalRefunds: any | null;
        readonly totalRefundsDue: any | null;
      } | null;
    } | null>;
  };
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly name: string;
  };
  readonly totalBaseCosts: any | null;
  readonly totalDeposits: any | null;
  readonly totalFees: any | null;
  readonly totalPayouts: any | null;
  readonly totalPerParticipantCosts: any | null;
  readonly totalRefunds: any | null;
  readonly totalRefundsDue: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExportSpendingDetailsButton_study">;
  readonly " $fragmentType": "SpendingDetails_study";
};
export type SpendingDetails_study$key = {
  readonly " $data"?: SpendingDetails_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpendingDetails_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goalPaidForCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDeposits",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPayouts",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRefunds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRefundsDue",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpendingDetails_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incentiveType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalBaseCosts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPerParticipantCosts",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RecruitNodeConnection",
      "kind": "LinkedField",
      "name": "recruits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecruitNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecruitNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "participantBaseCost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fee",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportSpendingDetailsButton_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "41ceef5a53794d40caba0c71432abb70";

export default node;
