/**
 * @generated SignedSource<<29c4ad0a6cbcb0a9cdd27937e6b325b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GridQuestionEditor_element$data = {
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly position: number;
        readonly terminate: boolean;
        readonly text: string | null;
      } | null;
    } | null>;
  };
  readonly characteristic: {
    readonly importKey: string;
  } | null;
  readonly gridAnswers: ReadonlyArray<{
    readonly answer: {
      readonly id: string;
    };
    readonly goal: number | null;
    readonly id: string;
    readonly row: {
      readonly id: string;
    };
    readonly terminate: boolean | null;
  } | null> | null;
  readonly id: string;
  readonly rows: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly position: number;
        readonly text: string;
      } | null;
    } | null>;
  };
  readonly usePreviousAnswers: any | null;
  readonly " $fragmentType": "GridQuestionEditor_element";
};
export type GridQuestionEditor_element$key = {
  readonly " $data"?: GridQuestionEditor_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"GridQuestionEditor_element">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GridQuestionEditor_element",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usePreviousAnswers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNode",
      "kind": "LinkedField",
      "name": "characteristic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "importKey",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AnswerNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RowNodeConnection",
      "kind": "LinkedField",
      "name": "rows",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RowNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GridAnswerNode",
      "kind": "LinkedField",
      "name": "gridAnswers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowNode",
          "kind": "LinkedField",
          "name": "row",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerNode",
          "kind": "LinkedField",
          "name": "answer",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "goal",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};
})();

(node as any).hash = "0aa29a24ecc626335c0e112a0273d008";

export default node;
