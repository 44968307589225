/**
 * @generated SignedSource<<62689854e9563ff8f6edae6f57a419be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersPersonCountryChoices = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RewardCard_panelist$data = {
  readonly dId: any | null;
  readonly panelistCurrencyToPointsRatio: number | null;
  readonly person: {
    readonly country: ScreenersPersonCountryChoices;
  } | null;
  readonly rewardsBalance: number | null;
  readonly " $fragmentType": "RewardCard_panelist";
};
export type RewardCard_panelist$key = {
  readonly " $data"?: RewardCard_panelist$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardCard_panelist">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RewardCard_panelist",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rewardsBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panelistCurrencyToPointsRatio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonNode",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PanelistNode",
  "abstractKey": null
};

(node as any).hash = "7c4c126ff6e10b5450d8b473eae38a5c";

export default node;
