/**
 * @generated SignedSource<<5cf1500972522d52f4ef5ddcabb33485>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsPage_viewer$data = {
  readonly studyCount: number;
  readonly user: {
    readonly dId: any | null;
    readonly panelist: {
      readonly dId: any | null;
    } | null;
    readonly vpmUserId: number | null;
  } | null;
  readonly " $fragmentType": "ProjectDetailsPage_viewer";
};
export type ProjectDetailsPage_viewer$key = {
  readonly " $data"?: ProjectDetailsPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelistNode",
          "kind": "LinkedField",
          "name": "panelist",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "4f3e17297297e68d27905146b60eb7f4";

export default node;
