import { Button } from "antd";
import { useRouter } from "found";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  code: 404 | 500;
};
const ErrorPage: React.FC<Props> = ({ code }) => {
  const { router } = useRouter();
  const { t } = useTranslation();

  const description = {
    404: {
      title: t("error-page.404.title", "Whoops!"),
      description: t("error-page.404.description", "That page does not exist."),
    },
    500: {
      title: t("error-page.500.title", "Well that's embarrassing!"),
      description: t("error-page.500.description", "Something went wrong with our system."),
    },
  };

  return (
    <StyledErrorPage>
      <div>
        <Title>{description[code].title}</Title>
        <Description>{description[code].description}</Description>
        <Button size="large" onClick={() => router.push("/projects")}>
          {t("error-page.home-button-text", "Take me home")}
        </Button>
      </div>
    </StyledErrorPage>
  );
};

const StyledErrorPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 44px;
`;

const Description = styled.div`
  font-size: 20px;
  margin-bottom: 24px;
`;

export default ErrorPage;
