/**
 * @generated SignedSource<<ca742b7e02ca34fb45a9aab9bdc4014a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseOpenEnd_characteristic$data = {
  readonly id: string;
  readonly " $fragmentType": "InputCharacteristicResponseOpenEnd_characteristic";
};
export type InputCharacteristicResponseOpenEnd_characteristic$key = {
  readonly " $data"?: InputCharacteristicResponseOpenEnd_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseOpenEnd_characteristic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputCharacteristicResponseOpenEnd_characteristic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};

(node as any).hash = "5fcfa92f58e5f422f783bed1f3639ae2";

export default node;
