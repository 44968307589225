import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import { SingleChoiceElement_element$data } from "../../__generated__/SingleChoiceElement_element.graphql";
import { SingleChoiceElement_screener$data } from "../../__generated__/SingleChoiceElement_screener.graphql";
import { CommonElementProps } from "./Element";
import { ElementBase } from "./index";

type Props = {
  element: SingleChoiceElement_element$data;
  screener: SingleChoiceElement_screener$data;
} & CommonElementProps;
const SingleChoiceElement: React.FC<Props> = props => <ElementBase {...props} />;

export default createFragmentContainer(SingleChoiceElement, {
  element: graphql`
    fragment SingleChoiceElement_element on ElementNode {
      ...ElementBase_element
    }
  `,
  screener: graphql`
    fragment SingleChoiceElement_screener on ScreenerNode {
      ...ElementBase_screener
    }
  `,
});
