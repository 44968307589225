/**
 * @generated SignedSource<<ea5576a92bbc298a3135d4504758208a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ReopenStudyInput = {
  clientMutationId?: string | null;
  studyId: string;
};
export type ProjectPage_ReopenStudy_Mutation$variables = {
  input: ReopenStudyInput;
};
export type ProjectPage_ReopenStudy_Mutation$data = {
  readonly reopenStudy: {
    readonly study: {
      readonly status: ScreenersStudyStatusChoices;
    } | null;
  } | null;
};
export type ProjectPage_ReopenStudy_Mutation = {
  response: ProjectPage_ReopenStudy_Mutation$data;
  variables: ProjectPage_ReopenStudy_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectPage_ReopenStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReopenStudyPayload",
        "kind": "LinkedField",
        "name": "reopenStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectPage_ReopenStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReopenStudyPayload",
        "kind": "LinkedField",
        "name": "reopenStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7732d2a46793c5d1369c3caf0f17deaa",
    "id": null,
    "metadata": {},
    "name": "ProjectPage_ReopenStudy_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectPage_ReopenStudy_Mutation(\n  $input: ReopenStudyInput!\n) {\n  reopenStudy(input: $input) {\n    study {\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f73711dfc3958176101c26193f3f19b3";

export default node;
