import { Icon } from "@iconify/react";
import { Card } from "antd";
import { useMemo } from "react";

import { CHARACTERISTIC_ELEMENT_TYPES } from "../../../constants";
import type { Characteristic } from "../../../types";
import { join } from "../../../utils/misc";

export const EditCharacteristicInactive = ({ characteristic }: { characteristic: Characteristic }) => {
  const summary = useMemo(() => {
    switch (characteristic.type) {
      case CHARACTERISTIC_ELEMENT_TYPES.DATE:
        return "Date";
      case CHARACTERISTIC_ELEMENT_TYPES.NUMBER:
        return "Number";
      case CHARACTERISTIC_ELEMENT_TYPES.OPENEND:
        return "Open-ended";
      default:
        break;
    }

    const answers = !!characteristic.answers?.length ? (
      join(characteristic.answers, answer => (answer.text ? answer.text : answer.other ? "other" : "(no text)"), " — ")
    ) : (
      <em>(no answers)</em>
    );

    if (
      [CHARACTERISTIC_ELEMENT_TYPES.SINGLE_SELECT, CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT].includes(
        characteristic.type as CHARACTERISTIC_ELEMENT_TYPES
      )
    ) {
      return answers;
    } else if (characteristic.type === CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT) {
      return !!characteristic.rows?.length ? (
        join(
          characteristic.rows,
          row => (
            <>
              <strong>{row.text ? row.text : "(no text)"}: </strong>
              {answers}
            </>
          ),
          <br />
        )
      ) : (
        <em>(no rows)</em>
      );
    }

    // unknown type
    return "—";
  }, [characteristic]);

  return (
    <Card className="characteristic-card collapsed clickable">
      <Icon icon="mdi:pencil-outline" style={{ float: "right" }} />
      <h1>{characteristic.shortName}</h1>
      <p>{summary}</p>
    </Card>
  );
};
