/**
 * @generated SignedSource<<0290871a17ff48c372cb281a3262eb08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResponseSummaryTable_responseSummary$data = {
  readonly rsvpAccepted: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly rsvpDeclined: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly rsvpMaybe: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly screenerDropouts: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly screenerQualified: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly screenerTermed: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly totalNeeded: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly totalStarts: {
    readonly qualified: number | null;
    readonly total: number | null;
  } | null;
  readonly " $fragmentType": "ResponseSummaryTable_responseSummary";
};
export type ResponseSummaryTable_responseSummary$key = {
  readonly " $data"?: ResponseSummaryTable_responseSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResponseSummaryTable_responseSummary">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qualified",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResponseSummaryTable_responseSummary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "totalStarts",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "screenerDropouts",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "screenerTermed",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "screenerQualified",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "totalNeeded",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "rsvpAccepted",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "rsvpDeclined",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalQualedNode",
      "kind": "LinkedField",
      "name": "rsvpMaybe",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ResponseSummaryNode",
  "abstractKey": null
};
})();

(node as any).hash = "334ddd35eb163f4b2f46dd06ba0dfaa0";

export default node;
