/**
 * @generated SignedSource<<10d20cb9065bf76f47d2a63868f73733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersRespondentApprovalStatusChoices = "A" | "D" | "NR" | "%future added value";
export type ScreenersRespondentMasterStatusChoices = "A" | "CS" | "D" | "IW" | "NR" | "NS" | "OQ" | "RT" | "RW" | "S" | "SS" | "T" | "%future added value";
export type ScreenersRespondentStatusChoices = "OQ" | "Q" | "T" | "%future added value";
export type ScreenersStudyIncentiveTypeChoices = "CA" | "EX" | "PT" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type YourHistory_user$data = {
  readonly panelist: {
    readonly customPanelistPortal: {
      readonly hidePointsAndRedemption: boolean;
      readonly tenant: {
        readonly id: string;
      };
    } | null;
    readonly person: {
      readonly respondents: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly approvalStatus: ScreenersRespondentApprovalStatusChoices | null;
            readonly completedOn: any | null;
            readonly masterStatus: ScreenersRespondentMasterStatusChoices | null;
            readonly participant: {
              readonly bonusPoints: ReadonlyArray<{
                readonly clearedOn: any | null;
                readonly pointsAmount: number;
              } | null>;
              readonly completedOn: any | null;
              readonly incentiveClearedOn: any | null;
              readonly incentivized: boolean;
              readonly noShow: boolean;
              readonly rating: number;
            } | null;
            readonly recruit: {
              readonly incentive: string | null;
              readonly incentivePoints: number | null;
              readonly isTest: boolean;
            } | null;
            readonly scheduledSlots: ReadonlyArray<{
              readonly start: any;
            }>;
            readonly screener: {
              readonly study: {
                readonly defaultIncentive: string | null;
                readonly defaultIncentivePoints: number | null;
                readonly incentiveType: ScreenersStudyIncentiveTypeChoices;
                readonly namePublic: string | null;
                readonly scheduled: any | null;
                readonly status: ScreenersStudyStatusChoices;
                readonly tenant: {
                  readonly id: string;
                };
                readonly type: ScreenersStudyTypeChoices;
              };
              readonly title: string | null;
            };
            readonly status: ScreenersRespondentStatusChoices | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "YourHistory_user";
};
export type YourHistory_user$key = {
  readonly " $data"?: YourHistory_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"YourHistory_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantNode",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedOn",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "YourHistory_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PanelistNode",
      "kind": "LinkedField",
      "name": "panelist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomPortalNode",
          "kind": "LinkedField",
          "name": "customPanelistPortal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidePointsAndRedemption",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonNode",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RespondentNodeConnection",
              "kind": "LinkedField",
              "name": "respondents",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RespondentNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RespondentNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "approvalStatus",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "masterStatus",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ParticipantNode",
                          "kind": "LinkedField",
                          "name": "participant",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "incentivized",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "incentiveClearedOn",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "noShow",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rating",
                              "storageKey": null
                            },
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ParticipantBonusPointsNode",
                              "kind": "LinkedField",
                              "name": "bonusPoints",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "clearedOn",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "pointsAmount",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RespondentScheduledSlotNode",
                          "kind": "LinkedField",
                          "name": "scheduledSlots",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "start",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ScreenerNode",
                          "kind": "LinkedField",
                          "name": "screener",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "StudyNode",
                              "kind": "LinkedField",
                              "name": "study",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "namePublic",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "type",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "defaultIncentive",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "defaultIncentivePoints",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "incentiveType",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "scheduled",
                                  "storageKey": null
                                },
                                (v2/*: any*/),
                                (v0/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RecruitNode",
                          "kind": "LinkedField",
                          "name": "recruit",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isTest",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "incentive",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "incentivePoints",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "9e9c921ce00dc2819ac61860047f8454";

export default node;
