import type { AntdTagColor } from "../components";
import type { ScreenersTagColorChoices } from "../schema";
import { FUTURE_PROOF } from "./util";

export const TAG_COLORS_ANTD: Record<ScreenersTagColorChoices | typeof FUTURE_PROOF, AntdTagColor | undefined> = {
  RD: "red",
  BL: "geekblue",
  GN: "green",
  CN: "cyan",
  PL: "purple",
  OG: "orange",
  YW: "gold",
  GY: undefined,
  [FUTURE_PROOF]: undefined,
};

export const TAG_COLORS_NAMES: Record<ScreenersTagColorChoices, string> = {
  RD: "Red",
  BL: "Blue",
  GN: "Green",
  CN: "Cyan",
  PL: "Purple",
  OG: "Orange",
  YW: "Yellow",
  GY: "Gray",
};
