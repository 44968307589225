/**
 * @generated SignedSource<<05be7427bb411b483db02c3eb6d7a640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAnswerInput = {
  answerId: string;
  clientMutationId?: string | null;
  exclusive?: boolean | null;
  quota?: number | null;
  rating?: number | null;
  terminate?: boolean | null;
  terminateCondition?: any | null;
  text?: string | null;
  userSpecified?: boolean | null;
};
export type Option_UpdateAnswer_Mutation$variables = {
  input: UpdateAnswerInput;
};
export type Option_UpdateAnswer_Mutation$data = {
  readonly updateAnswer: {
    readonly answer: {
      readonly exclusive: boolean;
      readonly id: string;
      readonly quota: number | null;
      readonly rating: number | null;
      readonly terminate: boolean;
      readonly terminateCondition: any | null;
      readonly text: string | null;
      readonly userSpecified: boolean;
    } | null;
  } | null;
};
export type Option_UpdateAnswer_Mutation = {
  response: Option_UpdateAnswer_Mutation$data;
  variables: Option_UpdateAnswer_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAnswerPayload",
    "kind": "LinkedField",
    "name": "updateAnswer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnswerNode",
        "kind": "LinkedField",
        "name": "answer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminateCondition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quota",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exclusive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userSpecified",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Option_UpdateAnswer_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Option_UpdateAnswer_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3a6028ccf6dcd6fcad9cf74bdfe98e6f",
    "id": null,
    "metadata": {},
    "name": "Option_UpdateAnswer_Mutation",
    "operationKind": "mutation",
    "text": "mutation Option_UpdateAnswer_Mutation(\n  $input: UpdateAnswerInput!\n) {\n  updateAnswer(input: $input) {\n    answer {\n      id\n      text\n      terminate\n      terminateCondition\n      quota\n      rating\n      exclusive\n      userSpecified\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fdf4fba2587d83407a74f5e98ac72ae";

export default node;
