import { Button, Progress, Tooltip, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { usdFormatter } from "../../utils/misc";
import { ProjectSpend_study$data } from "../../__generated__/ProjectSpend_study.graphql";
import SpendingDetails from "./SpendingDetails";

const { Title } = Typography;

type Props = {
  study: ProjectSpend_study$data;
};
const ProjectSpend: React.FC<Props> = ({ study }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <ProjectSpendContainer>
        <Title className="title">Recruiting Rounds Spending</Title>
        <div className="progress-row">
          <Tooltip
            title={
              <>
                <TooltipTable>
                  <tbody>
                    <tr>
                      <td>Paid:</td>
                      <td>{usdFormatter.format(Number(study.totalPayouts))}</td>
                    </tr>
                    <tr>
                      <td>Spending:</td>
                      <td>{usdFormatter.format(Number(study.totalSpend))}</td>
                    </tr>
                  </tbody>
                </TooltipTable>
              </>
            }
          >
            <Progress
              percent={100 * (Number(study.totalPayouts) / Number(study.totalSpend))}
              strokeColor="var(--ant-success-color)"
              showInfo={false}
            />
          </Tooltip>
          <span>{usdFormatter.format(Number(study.totalPayouts))}</span>
          <Button type="link" onClick={() => setShowDetails(true)}>
            Details
          </Button>
        </div>
      </ProjectSpendContainer>
      <SpendingDetails visible={showDetails} study={study} onClose={() => setShowDetails(false)} />
    </>
  );
};

const ProjectSpendContainer = styled.div`
  margin: 10px;

  .title {
    text-align: left;
    font-size: 20px;
  }

  .progress-row {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .progress-row:first-child {
    flex-grow: 1;
  }
`;

const TooltipTable = styled.table`
  td:last-child {
    padding-left: 10px;
    text-align: right;
  }
`;

export default createFragmentContainer(ProjectSpend, {
  study: graphql`
    fragment ProjectSpend_study on StudyNode {
      totalPayouts
      totalSpend
      ...SpendingDetails_study
    }
  `,
});
