import { Icon } from "@iconify/react";
import { usePrevious } from "@react-hookz/web";
import { Button, Popover, Select, Switch, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { type Argument } from "classnames";
import { isEqual } from "lodash-es";
import { localeData } from "moment";
import { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ELEMENT_TYPES } from "../../constants";
import { GRAY_6 } from "../../style";
import type { TerminateCondition } from "../../types";
import type { OptionTerminate_answer$data } from "../../__generated__/OptionTerminate_answer.graphql";
import type { OptionTerminate_element$data } from "../../__generated__/OptionTerminate_element.graphql";

const { ordinal } = localeData();

const validateTerminateCondition = (x: any): x is TerminateCondition => {
  return (
    typeof x === "object" &&
    (x === null ||
      (!Array.isArray(x) &&
        typeof x.negate === "boolean" &&
        Array.isArray(x.values) &&
        !!x.values.length &&
        x.values.every((y: any) => typeof y === "number")))
  );
};

const OptionTerminate = ({
  answer,
  className,
  element,
  onChange,
}: {
  answer: OptionTerminate_answer$data;
  className?: Argument;
  element: OptionTerminate_element$data;
  onChange: (value: { terminate?: boolean; terminateCondition?: TerminateCondition | null }) => void;
}) => {
  const [terminate, setTerminate] = useState<boolean>(answer.terminate ?? false);
  const [terminateConditionNegate, setTerminateConditionNegate] = useState(answer.terminateCondition?.negate ?? false);
  const [terminateConditionValues, setTerminateConditionValues] = useState<number[]>(
    answer.terminateCondition?.values ?? []
  );
  const [showTerminateCondition, setShowTerminateCondition] = useState(false);
  const showTerminateConditionPrevious = usePrevious(showTerminateCondition);

  // reinitialize state when answer changes
  useEffect(() => {
    setTerminate(answer.terminate ?? false);
    setTerminateConditionNegate(answer.terminateCondition?.negate ?? false);
    setTerminateConditionValues(answer.terminateCondition?.values ?? []);
  }, [answer.terminate, answer.terminateCondition]);

  // enable terminate when terminateCondition editor is opened
  useEffect(() => {
    if (showTerminateCondition && !showTerminateConditionPrevious && !terminate) setTerminate(true);
  }, [showTerminateCondition, showTerminateConditionPrevious, terminate]);

  // disable terminate when terminateCondition editor is closed without valid terminateCondition
  useEffect(() => {
    if (
      !showTerminateCondition &&
      showTerminateConditionPrevious &&
      !validateTerminateCondition({ negate: terminateConditionNegate, values: terminateConditionValues })
    )
      setTerminate(false);
  }, [
    showTerminateCondition,
    showTerminateConditionPrevious,
    terminate,
    terminateConditionNegate,
    terminateConditionValues,
  ]);

  // open terminateCondition editor when terminate enabled
  useEffect(() => {
    if (terminate && (answer.terminateCondition === null || !validateTerminateCondition(answer.terminateCondition)))
      setShowTerminateCondition(true);
  }, [answer.terminateCondition, terminate]);

  // update terminate and terminateCondition via onChange prop
  useEffect(() => {
    if (element.type === ELEMENT_TYPES.RANK) {
      const terminateCondition = { negate: terminateConditionNegate, values: terminateConditionValues };

      if (terminate && validateTerminateCondition(terminateCondition)) {
        // if terminate enabled, update terminate and terminateCondition as soon as terminateCondition is valid
        if (terminate !== answer.terminate || !isEqual(terminateCondition, answer.terminateCondition))
          onChange({ terminate, terminateCondition });
      } else if (!showTerminateCondition && (terminate || answer.terminateCondition !== null))
        // if not valid and terminate editor is closed, reset terminate and terminateCondition
        onChange({ terminate: false, terminateCondition: null });
    } else if (terminate !== answer.terminate) onChange({ terminate });
  }, [
    answer.terminate,
    answer.terminateCondition,
    element.type,
    showTerminateCondition,
    terminate,
    terminateConditionNegate,
    terminateConditionValues,
    onChange,
  ]);

  return (
    <Tooltip placement="right" title="Terminate">
      <StyledRoot className={classNames(className)} terminate={terminate}>
        <Switch
          size="small"
          style={{ backgroundColor: terminate ? "var(--ant-error-color-active)" : GRAY_6 }}
          checked={terminate}
          onChange={setTerminate}
        />
        {element.type === ELEMENT_TYPES.RANK && (
          <Popover
            arrowPointAtCenter
            content={
              <StyledPopoverContent>
                <span>Terminate if this answer</span>
                <Select value={terminateConditionNegate} onChange={setTerminateConditionNegate}>
                  <Select.Option value={false}>is</Select.Option>
                  <Select.Option value={true}>is not</Select.Option>
                </Select>
                <Select
                  mode="multiple"
                  placeholder="Select rank"
                  value={terminateConditionValues}
                  onChange={setTerminateConditionValues}
                >
                  {element.answers.edges.map((_, i) => (
                    <Select.Option key={i} value={i}>
                      {ordinal(i + 1)}
                    </Select.Option>
                  ))}
                </Select>
                <Button type="text" onClick={() => setShowTerminateCondition(false)}>
                  <Icon icon="mdi:close" height="1.2em" />
                </Button>
              </StyledPopoverContent>
            }
            getPopupContainer={trigger => trigger.parentElement!}
            overlayInnerStyle={{ width: 480 }}
            overlayStyle={{ paddingTop: 0 }}
            placement="bottomRight"
            open={showTerminateCondition}
          >
            <Button type="text" onClick={() => setShowTerminateCondition(!showTerminateCondition)}>
              <Icon icon="mdi:dots-horizontal" height="1.2em" />
            </Button>
          </Popover>
        )}
      </StyledRoot>
    </Tooltip>
  );
};

const StyledRoot = styled.div<{ readonly terminate: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  .ant-btn {
    padding: 4px;
  }

  .iconify {
    display: block;
  }
`;

const StyledPopoverContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > :not(.ant-select-multiple) {
    flex-shrink: 0;
  }

  & > .ant-select-single {
    width: 80px;
  }

  & > .ant-select-multiple {
    flex: 1;
  }

  & > button {
    padding: 4px;
    margin-right: -4px;
  }

  .iconify {
    display: block;
  }
`;

export default createFragmentContainer(OptionTerminate, {
  answer: graphql`
    fragment OptionTerminate_answer on AnswerNode {
      terminate
      terminateCondition
    }
  `,
  element: graphql`
    fragment OptionTerminate_element on ElementNode {
      type
      answers {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});
