/**
 * @generated SignedSource<<551ca85a069de0f5bafe67dd8cfc6c45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ElementBase_screener$data = {
  readonly study: {
    readonly status: ScreenersStudyStatusChoices;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActiveElementContent_screener" | "InactiveElementContent_screener">;
  readonly " $fragmentType": "ElementBase_screener";
};
export type ElementBase_screener$key = {
  readonly " $data"?: ElementBase_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementBase_screener",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActiveElementContent_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InactiveElementContent_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "690ee718c14ed1d1f790d2fa6c770736";

export default node;
