/**
 * @generated SignedSource<<307c83f79c25940ef13de80579c5ed3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersStudySchedulingTypeChoices = "CL" | "HX" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
export type CreateStudyInput = {
  clientMutationId?: string | null;
  name?: string | null;
  namePublic?: string | null;
};
export type ProjectDetailsPage_CreateStudy_Mutation$variables = {
  input: CreateStudyInput;
};
export type ProjectDetailsPage_CreateStudy_Mutation$data = {
  readonly createStudy: {
    readonly study: {
      readonly dId: any | null;
      readonly id: string;
      readonly name: string | null;
      readonly namePublic: string | null;
      readonly schedulingType: ScreenersStudySchedulingTypeChoices;
      readonly status: ScreenersStudyStatusChoices;
      readonly tenant: {
        readonly dId: any | null;
        readonly name: string;
        readonly vpmAccountId: number | null;
      };
      readonly type: ScreenersStudyTypeChoices;
    } | null;
  } | null;
};
export type ProjectDetailsPage_CreateStudy_Mutation = {
  response: ProjectDetailsPage_CreateStudy_Mutation$data;
  variables: ProjectDetailsPage_CreateStudy_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namePublic",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedulingType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vpmAccountId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDetailsPage_CreateStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStudyPayload",
        "kind": "LinkedField",
        "name": "createStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDetailsPage_CreateStudy_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStudyPayload",
        "kind": "LinkedField",
        "name": "createStudy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyNode",
            "kind": "LinkedField",
            "name": "study",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb295642cf52cb2cb1d8f681cab324db",
    "id": null,
    "metadata": {},
    "name": "ProjectDetailsPage_CreateStudy_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectDetailsPage_CreateStudy_Mutation(\n  $input: CreateStudyInput!\n) {\n  createStudy(input: $input) {\n    study {\n      id\n      dId\n      name\n      namePublic\n      type\n      schedulingType\n      status\n      tenant {\n        dId\n        vpmAccountId\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0482da11075faca5c26698de2cc94573";

export default node;
