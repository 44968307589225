import type { Except } from "type-fest";

export const enum FilterType {
  Panelist = "PANELIST",
  Participant = "PARTICIPANT",
  Person = "PERSON",
  Response = "RESPONSE",
  Segment = "SEGMENT",
  Status = "STATUS",
  Study = "STUDY",
}

export const enum FilterOp {
  After = "AFTER",
  Before = "BEFORE",
  Between = "BETWEEN",
  Contains = "CONTAINS",
  GreaterThan = "GREATER_THAN",
  Is = "IS",
  LessThan = "LESS_THAN",
}

export type FilterBase = {
  id: string;
  negate?: boolean;
};

export type NewFilter<F extends FilterBase> = Except<
  F,
  F extends { values: any } ? "id" | "values" : F extends { filters: any } ? "id" | "filters" : "id"
> & { type: FilterType };
