import { LinkOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { LINK_TEXT_COLOR } from "../style";
import { ScreenerLink_recruit$data } from "../__generated__/ScreenerLink_recruit.graphql";

type Props = {
  recruit: ScreenerLink_recruit$data;
};
const ScreenerLink: React.FC<Props> = ({ recruit }) => {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <StyledScreenerLink>
      <Input style={{ width: 456 }} value={recruit.screenerLink || ""} className="screener-link" />
      <Tooltip title={copied ? "Link copied" : "Click to copy"}>
        <CopyToClipboard onCopy={() => setCopied(true)} text={recruit.screenerLink || ""}>
          <LinkOutlined />
        </CopyToClipboard>
      </Tooltip>
    </StyledScreenerLink>
  );
};

const StyledScreenerLink = styled.div`
  .anticon {
    margin-left: 12px;
    font-size: 16px;
    color: ${LINK_TEXT_COLOR};
  }
`;

export default createFragmentContainer(ScreenerLink, {
  recruit: graphql`
    fragment ScreenerLink_recruit on RecruitNode {
      screenerLink
    }
  `,
});
