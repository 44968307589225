/**
 * @generated SignedSource<<66dd1a6df2fb7672fe5cc61b99bb474e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelStudyInput = {
  clientMutationId?: string | null;
  studyId: string;
};
export type ProjectPage_CancelStudy_Mutation$variables = {
  input: CancelStudyInput;
};
export type ProjectPage_CancelStudy_Mutation$data = {
  readonly cancelStudy: {
    readonly success: boolean | null;
  } | null;
};
export type ProjectPage_CancelStudy_Mutation = {
  response: ProjectPage_CancelStudy_Mutation$data;
  variables: ProjectPage_CancelStudy_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CancelStudyPayload",
    "kind": "LinkedField",
    "name": "cancelStudy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectPage_CancelStudy_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectPage_CancelStudy_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "46df2920590edfe46cc4dd1640bbe3a2",
    "id": null,
    "metadata": {},
    "name": "ProjectPage_CancelStudy_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectPage_CancelStudy_Mutation(\n  $input: CancelStudyInput!\n) {\n  cancelStudy(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "07082199e2c219949f34cc6604eb5ffd";

export default node;
