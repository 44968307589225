/**
 * @generated SignedSource<<381fe264f3bed14ff9af7667a1999e33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecruitInstructions_user$data = {
  readonly email: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_user">;
  readonly " $fragmentType": "RecruitInstructions_user";
};
export type RecruitInstructions_user$key = {
  readonly " $data"?: RecruitInstructions_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitInstructions_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitInstructions_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommunicationComposePage_user"
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "b60722151d14c0aa8dbf2ec835340f71";

export default node;
