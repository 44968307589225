import baselineAdd from "@iconify-icons/ic/baseline-add";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { uniqueId } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { Accordion, IconButton } from "../";
import { CHARACTERISTIC_ELEMENT_TYPES } from "../../constants";
import { PAGE_WIDTH_LG, PAGE_WIDTH_XS } from "../../style";
import type { Characteristic, WritableDeep } from "../../types";
import { flattenEdges, getTenantContext, getUserContext, trackEvent, useConfirmQuitEditing } from "../../utils";
import { maybeElement } from "../../utils/misc";
import type { CharacteristicsEditPage_tenant$data } from "../../__generated__/CharacteristicsEditPage_tenant.graphql";
import type { CharacteristicsEditPage_user$data } from "../../__generated__/CharacteristicsEditPage_user.graphql";
import Saving, { type SavingStateType } from "../Saving";
import { EditCharacteristicActive } from "./Characteristics/EditCharacteristicActive";
import { EditCharacteristicInactive } from "./Characteristics/EditCharacteristicInactive";

const _CharacteristicsEditPage = ({
  user,
  tenant,
}: {
  user: CharacteristicsEditPage_user$data;
  tenant: CharacteristicsEditPage_tenant$data;
}) => {
  const { router } = useRouter();

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [characteristics, setCharacteristics] = useState<Characteristic[]>([]);
  const [newCharacteristic, setNewCharacteristic] = useState<Characteristic | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [savingState, setSavingState] = useState<SavingStateType>("saved");

  // confirm unsaved changes on navigate
  const _confirmQuitEditing = useConfirmQuitEditing();
  const confirmQuitEditing = useCallback(async () => {
    if (hasUnsavedChanges && !(await _confirmQuitEditing())) return false;

    setHasUnsavedChanges(false);
    return true;
  }, [_confirmQuitEditing, hasUnsavedChanges]);

  useEffect(
    () =>
      router.addNavigationListener(
        location => {
          // required when beforeUnload is true
          if (hasUnsavedChanges && !location) return false;

          return confirmQuitEditing();
        },
        { beforeUnload: true }
      ),
    [router, hasUnsavedChanges, confirmQuitEditing]
  );

  const duplicateCharacteristic = useCallback(
    (characteristic: Characteristic) => {
      setNewCharacteristic({
        ...characteristic,
        shortName: `${characteristic.shortName} copy`.substring(0, 100),
        id: uniqueId("characteristic_"),
        importKey: `${characteristic.importKey}_copy`.substring(0, 512),
        answers: characteristic.answers?.map(answer => ({ ...answer, id: uniqueId("answer_") })),
        rows: characteristic.rows?.map(row => ({ ...row, id: uniqueId("row_") })),
      });
      setExpandedIndex(0);

      trackEvent("Characteristic duplicated", {
        ...getUserContext(user as any),
        ...getTenantContext(tenant as any),
        "Characteristic Import Key": characteristic.importKey,
      });
    },
    [tenant, setNewCharacteristic, user]
  );

  useEffect(() => {
    const _characteristics = flattenEdges(tenant.characteristics);
    setCharacteristics(_characteristics as WritableDeep<typeof _characteristics>);
  }, [tenant.characteristics]);

  return (
    <StyledCharacteristicsEditPage>
      <Saving savingState={savingState} />
      <div className="container">
        <IconButton
          type="primary"
          icon={baselineAdd}
          onClick={async () => {
            if (!(await confirmQuitEditing())) return;

            setNewCharacteristic({
              id: uniqueId("characteristic_"),
              type: CHARACTERISTIC_ELEMENT_TYPES.SINGLE_SELECT,
              answers: [{ id: uniqueId("answer_"), text: "" }],
            });
            setExpandedIndex(0);
          }}
        >
          New characteristic
        </IconButton>
        <p style={{ marginTop: "1em", maxWidth: "65ch" }}>
          Characteristics are specific information from your panel that will help you to better target and segment when
          recruiting, and also avoid asking redundant&nbsp;questions.
        </p>
        <Accordion
          beforeExpand={confirmQuitEditing}
          expandedIndex={expandedIndex}
          getCollapsedContent={characteristic => <EditCharacteristicInactive characteristic={characteristic} />}
          getExpandedContent={characteristic => (
            <EditCharacteristicActive
              user={user}
              tenant={tenant}
              characteristic={characteristic}
              isNew={characteristic === newCharacteristic}
              duplicate={duplicateCharacteristic}
              setHasUnsavedChanges={setHasUnsavedChanges}
              onCancel={() => {
                setNewCharacteristic(null);
                setExpandedIndex(null);
                setHasUnsavedChanges(false);
              }}
              setSavingState={setSavingState}
              handleNavigate={confirmQuitEditing}
            />
          )}
          getKey={item => item.id}
          items={[...maybeElement(!!newCharacteristic, newCharacteristic!), ...characteristics]}
          setExpandedIndex={async index => (await confirmQuitEditing()) && setExpandedIndex(index)}
        />
      </div>
    </StyledCharacteristicsEditPage>
  );
};

const StyledCharacteristicsEditPage = styled.div`
  min-width: ${PAGE_WIDTH_XS};
  width: 100%;

  .container {
    max-width: ${PAGE_WIDTH_LG};
  }

  .grabbable {
    cursor: grab;
  }

  .clickable {
    cursor: pointer;
  }

  .characteristic-card {
    margin-bottom: 16px;

    & > .ant-card-body {
      padding: 12px;
    }
  }

  .container:has(.characteristic-card.expanded) > *:has(.characteristic-card:not(.expanded)) {
    opacity: 0.5;
    transition: opacity 150ms;

    &:hover,
    &:active {
      opacity: 1;
    }
  }
`;

export const CharacteristicsEditPage = createFragmentContainer(_CharacteristicsEditPage, {
  user: graphql`
    fragment CharacteristicsEditPage_user on UserNode {
      dId
      vpmUserId
      panelist {
        dId
      }

      ...EditCharacteristicActive_user
    }
  `,
  tenant: graphql`
    fragment CharacteristicsEditPage_tenant on TenantNode {
      name
      characteristics {
        edges {
          node {
            id
            type
            text
            shortName
            showToPanelists
            importKey
            elementMeta {
              randomize
              responseValidation
              target
              target2
              targetTypes
            }
            answers {
              id
              text
              other
              userSpecified
            }
            rows {
              id
              text
            }
          }
        }
      }
      dId
      vpmAccountId
      name

      ...EditCharacteristicActive_tenant
    }
  `,
});
