/**
 * @generated SignedSource<<cc3924a883b22f365f484b7e09a69fae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MoveElementInput = {
  clientMutationId?: string | null;
  elementId: string;
  toPosition?: number | null;
};
export type ProjectScreenerPage_MoveElement_Mutation$variables = {
  input: MoveElementInput;
};
export type ProjectScreenerPage_MoveElement_Mutation$data = {
  readonly moveElement: {
    readonly screener: {
      readonly elements: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly position: number;
            readonly text: string | null;
          } | null;
        } | null>;
      } | null;
      readonly id: string;
    } | null;
  } | null;
};
export type ProjectScreenerPage_MoveElement_Mutation = {
  response: ProjectScreenerPage_MoveElement_Mutation$data;
  variables: ProjectScreenerPage_MoveElement_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MoveElementPayload",
    "kind": "LinkedField",
    "name": "moveElement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScreenerNode",
        "kind": "LinkedField",
        "name": "screener",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNodeConnection",
            "kind": "LinkedField",
            "name": "elements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ElementNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ElementNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectScreenerPage_MoveElement_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectScreenerPage_MoveElement_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3576a2f9f74361a8c1b19c99b22e9790",
    "id": null,
    "metadata": {},
    "name": "ProjectScreenerPage_MoveElement_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectScreenerPage_MoveElement_Mutation(\n  $input: MoveElementInput!\n) {\n  moveElement(input: $input) {\n    screener {\n      id\n      elements {\n        edges {\n          node {\n            id\n            position\n            text\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92689024f998ce1eef19321fcb66d01f";

export default node;
