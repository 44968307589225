import { Form, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { debounce } from "lodash-es";
import React, { useState } from "react";
import { createRefetchContainer, RelayRefetchProp } from "react-relay";
import { FormItemParticipants_study$data } from "../../__generated__/FormItemParticipants_study.graphql";
import { Loading } from "../index";

type Props = {
  formItemLayout: {
    labelCol: {
      span: number;
    };
    wrapperCol: {
      span: number;
    };
  };
  name: string;
  label: string;
  study: FormItemParticipants_study$data;
  relay: RelayRefetchProp;
  checkValidity: (name: "subject" | "message" | "replyTo" | "to") => void;
};
const FormItemParticipants: React.FC<Props> = ({ formItemLayout, name, label, study, relay, checkValidity }) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string>("");

  const refetch = (v: string) => {
    relay.refetch({ count: 10, studyId: study.id, search: v }, null, error => {
      setFetching(false);
      if (error) return console.error(error);
    });
  };

  return (
    <Form.Item {...formItemLayout} name={name} label={label} rules={[{ required: true }]}>
      <Select
        loading={fetching}
        mode="multiple"
        placeholder="Select participants"
        dropdownRender={menu => (fetching ? <Loading /> : <div>{menu}</div>)}
        onSearch={v => {
          setFetching(true);
          debounce(refetch, 300)(v);
        }}
        onChange={v => {
          setSelectValue(v);
          checkValidity("to");
        }}
        value={selectValue}
      >
        {study.participants &&
          study.participants.edges
            .filter(e => !e?.node?.respondent?.recruit?.isTest)
            .map(
              o =>
                o?.node?.respondent.person?.email && (
                  <Select.Option
                    key={o.node.respondent.dId as string}
                    value={`${o.node.respondent.person.firstName} ${
                      o.node.respondent.person.lastName &&
                      o.node.respondent.person.lastName.length > 0 &&
                      o.node.respondent.person.lastName[0]
                    } (${o.node.respondent.dId})`}
                  >
                    {`${o.node.respondent.person.firstName} ${
                      o.node.respondent.person.lastName &&
                      o.node.respondent.person.lastName.length > 0 &&
                      o.node.respondent.person.lastName[0]
                    } (${o.node.respondent.dId})`}
                  </Select.Option>
                )
            )}
      </Select>
    </Form.Item>
  );
};

export default createRefetchContainer(
  FormItemParticipants,
  {
    study: graphql`
      fragment FormItemParticipants_study on StudyNode
      @argumentDefinitions(count: { type: "Int", defaultValue: 10 }, search: { type: "String", defaultValue: null }) {
        id
        participants(first: $count, respondent_Person_FirstName_Icontains: $search, respondent_Person_OptIn: true) {
          edges {
            node {
              respondent {
                dId
                person {
                  email
                  firstName
                  lastName
                }
                recruit {
                  isTest
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query FormItemParticipantsRefetchQuery($count: Int, $studyId: String, $search: String!) {
      viewer {
        study(studyId: $studyId) {
          ...FormItemParticipants_study @arguments(count: $count, search: $search)
        }
      }
    }
  `
);
