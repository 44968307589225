import type { Router } from "found";
import { useState } from "react";
import { NotificationLink } from "./Notification";

const truncateIf = <T extends any[]>(x: T, condition: boolean) =>
  condition ? x.slice(0, truncateIf.TRUNCATE_LENGTH) : x;
truncateIf.TRUNCATE_LENGTH = 4 as const;

export const NotificationsList = ({
  notifications,
  router,
}: {
  notifications: {
    id: string;
    name: string;
    participants: number;
  }[];
  router: Router;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ul style={{ color: "var(--ant-primary-color)", paddingLeft: 24 }}>
        {truncateIf(notifications, !expanded).map((x, i) => (
          <li key={i}>
            <NotificationLink router={router} to={`/projects/${x.id}/respondents/participants`}>
              {x.name} ({x.participants.toLocaleString()})
            </NotificationLink>
          </li>
        ))}
      </ul>
      {notifications.length > truncateIf.TRUNCATE_LENGTH && (
        <span
          role="button"
          style={{ color: "var(--ant-primary-color)", cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setExpanded(!expanded)}
        >
          Show {expanded ? "less" : "more"}
        </span>
      )}
    </>
  );
};
