/**
 * @generated SignedSource<<012569cc9825557e7e85545898928178>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendToEnum = "ALL_PARTICIPANTS" | "CUSTOM_LIST" | "SPECIFIED_PARTICIPANTS" | "%future added value";
export type SendStudyEmailInput = {
  clientMutationId?: string | null;
  emails?: ReadonlyArray<string | null> | null;
  message: string;
  replyTo?: string | null;
  sendTo: SendToEnum;
  studyId: string;
  subject: string;
  to?: ReadonlyArray<string | null> | null;
};
export type CommunicationComposePageSendStudyEmailMutation$variables = {
  input: SendStudyEmailInput;
};
export type CommunicationComposePageSendStudyEmailMutation$data = {
  readonly sendStudyEmail: {
    readonly response: {
      readonly recipientCount: number | null;
      readonly sent: boolean | null;
    } | null;
  } | null;
};
export type CommunicationComposePageSendStudyEmailMutation = {
  response: CommunicationComposePageSendStudyEmailMutation$data;
  variables: CommunicationComposePageSendStudyEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendStudyEmailPayload",
    "kind": "LinkedField",
    "name": "sendStudyEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SendEmailResponse",
        "kind": "LinkedField",
        "name": "response",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recipientCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunicationComposePageSendStudyEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunicationComposePageSendStudyEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ad1d256be0b4cc4f01f737c213012d7",
    "id": null,
    "metadata": {},
    "name": "CommunicationComposePageSendStudyEmailMutation",
    "operationKind": "mutation",
    "text": "mutation CommunicationComposePageSendStudyEmailMutation(\n  $input: SendStudyEmailInput!\n) {\n  sendStudyEmail(input: $input) {\n    response {\n      sent\n      recipientCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa13fe482acd44fc3b92a99ae21dd8ac";

export default node;
