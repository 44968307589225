/**
 * @generated SignedSource<<4d962d494913926f2816abafb2a7b067>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_Login_Query$variables = {
  customLandingHostname: string;
};
export type RouteConfig_Login_Query$data = {
  readonly viewer: {
    readonly customPortal: {
      readonly " $fragmentSpreads": FragmentRefs<"LoginPage_customPortal">;
    } | null;
  };
};
export type RouteConfig_Login_Query = {
  response: RouteConfig_Login_Query$data;
  variables: RouteConfig_Login_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customLandingHostname"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "customLandingHostname",
    "variableName": "customLandingHostname"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_Login_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CustomPortalNode",
            "kind": "LinkedField",
            "name": "customPortal",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LoginPage_customPortal"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RouteConfig_Login_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CustomPortalNode",
            "kind": "LinkedField",
            "name": "customPortal",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "footerImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "faviconUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customLandingHostname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OIDCProviderNode",
                    "kind": "LinkedField",
                    "name": "oidcProviders",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "env",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88dec46489bcf5c873019e6b1dbbec83",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_Login_Query",
    "operationKind": "query",
    "text": "query RouteConfig_Login_Query(\n  $customLandingHostname: String!\n) {\n  viewer {\n    customPortal(customLandingHostname: $customLandingHostname) {\n      ...LoginPage_customPortal\n      id\n    }\n  }\n}\n\nfragment LoginPage_customPortal on CustomPortalNode {\n  logoImageUrl\n  footerImageUrl\n  customColor\n  faviconUrl\n  pageTitle\n  contactEmail\n  customLandingHostname\n  tenant {\n    oidcProviders {\n      id\n      env\n      name\n      clientId\n      authUrl\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "226173bf4ebb23be0d4e9bbfff8811ab";

export default node;
