/**
 * @generated SignedSource<<ee5a4a858d10b645d9c87233dd406922>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CharacteristicTableQuery$variables = {
  name?: string | null;
};
export type CharacteristicTableQuery$data = {
  readonly viewer: {
    readonly panel: {
      readonly characteristic: {
        readonly name: string | null;
        readonly options: ReadonlyArray<{
          readonly count: number | null;
          readonly name: string | null;
        } | null> | null;
        readonly rows: ReadonlyArray<{
          readonly name: string | null;
          readonly options: ReadonlyArray<{
            readonly count: number | null;
            readonly name: string | null;
          } | null> | null;
        }> | null;
        readonly totalCount: number | null;
        readonly type: string | null;
      } | null;
    } | null;
  };
};
export type CharacteristicTableQuery = {
  response: CharacteristicTableQuery$data;
  variables: CharacteristicTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Panel",
        "kind": "LinkedField",
        "name": "panel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "name",
                "variableName": "name"
              }
            ],
            "concreteType": "PanelCharacteristic",
            "kind": "LinkedField",
            "name": "characteristic",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelCharacteristicOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelCharacteristicRow",
                "kind": "LinkedField",
                "name": "rows",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PanelCharacteristicRowOption",
                    "kind": "LinkedField",
                    "name": "options",
                    "plural": true,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CharacteristicTableQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CharacteristicTableQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f1e4e97aada0059f61d8073b8ed215f3",
    "id": null,
    "metadata": {},
    "name": "CharacteristicTableQuery",
    "operationKind": "query",
    "text": "query CharacteristicTableQuery(\n  $name: String\n) {\n  viewer {\n    panel {\n      characteristic(name: $name) {\n        name\n        totalCount\n        type\n        options {\n          name\n          count\n        }\n        rows {\n          name\n          options {\n            name\n            count\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e061bcaf7975ffb2901507c0e695d21";

export default node;
