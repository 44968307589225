/**
 * @generated SignedSource<<b31b21b306cc4eb1bc57dca4954e086f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertAnswersInput = {
  answers: ReadonlyArray<string>;
  clientMutationId?: string | null;
  elementId: string;
  position: number;
};
export type Option_insertAnswers_Mutation$variables = {
  input: InsertAnswersInput;
};
export type Option_insertAnswers_Mutation$data = {
  readonly insertAnswers: {
    readonly element: {
      readonly answers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly position: number;
            readonly text: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type Option_insertAnswers_Mutation = {
  response: Option_insertAnswers_Mutation$data;
  variables: Option_insertAnswers_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Option_insertAnswers_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InsertAnswersPayload",
        "kind": "LinkedField",
        "name": "insertAnswers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnswerNodeConnection",
                "kind": "LinkedField",
                "name": "answers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnswerNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Option_insertAnswers_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InsertAnswersPayload",
        "kind": "LinkedField",
        "name": "insertAnswers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementNode",
            "kind": "LinkedField",
            "name": "element",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnswerNodeConnection",
                "kind": "LinkedField",
                "name": "answers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnswerNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b995db828db6dcd8b2f01de44210311",
    "id": null,
    "metadata": {},
    "name": "Option_insertAnswers_Mutation",
    "operationKind": "mutation",
    "text": "mutation Option_insertAnswers_Mutation(\n  $input: InsertAnswersInput!\n) {\n  insertAnswers(input: $input) {\n    element {\n      answers {\n        edges {\n          node {\n            text\n            position\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cde718887f2d143a847e7e3fd1d1464c";

export default node;
