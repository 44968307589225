import { Select } from "antd";
import { localeData } from "moment";
import type { SetRequired } from "type-fest";
import type { AnswerNode, CharacteristicAnswerNode } from "../../schema";

import { ButtonNot } from "./ButtonNot";
import { type FilterSubtypeResponse, type PanelistFilter, type ParticipantFilter } from "./types";

const { ordinal } = localeData();

export const InputFilterResponsePosition = <T extends ParticipantFilter | PanelistFilter>({
  answers,
  filter,
  setFilter,
}: {
  answers: SetRequired<Partial<CharacteristicAnswerNode | AnswerNode>, "dbId" | "text">[];
  filter: T & { subtype: FilterSubtypeResponse.Position };
  setFilter: (x: T) => void;
}) => (
  <div className="form-filter-row">
    <ButtonNot filter={filter} setFilter={setFilter} />
    <Select
      mode="multiple"
      className="input-values"
      value={filter.values as number[]}
      onChange={(values: any[]) => setFilter({ ...filter, values })}
      showSearch
      optionFilterProp="children"
    >
      {answers.map((_, i) => (
        <Select.Option key={i} value={i}>
          {ordinal(i + 1)}
        </Select.Option>
      ))}
    </Select>
  </div>
);
