/**
 * @generated SignedSource<<bb8a9730b2081f45afbb7f9676115488>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersAutomatedEmailTypeChoices = "A" | "D" | "NR" | "OQ" | "RT" | "RW" | "S" | "T" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AutomatedEmailsPage_study$data = {
  readonly automatedEmails: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assets: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string | null;
              readonly sizeBytes: number | null;
              readonly url: string;
            } | null;
          } | null>;
        };
        readonly ctaLabel: string | null;
        readonly ctaUrl: string | null;
        readonly enabled: boolean;
        readonly id: string;
        readonly message: string;
        readonly replyTo: string;
        readonly subject: string;
        readonly type: ScreenersAutomatedEmailTypeChoices;
      } | null;
    } | null>;
  };
  readonly id: string;
  readonly tenant: {
    readonly voxpopmeGlobal: boolean;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"AutomatedEmailEditor_study">;
  readonly " $fragmentType": "AutomatedEmailsPage_study";
};
export type AutomatedEmailsPage_study$key = {
  readonly " $data"?: AutomatedEmailsPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"AutomatedEmailsPage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AutomatedEmailsPage_study",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voxpopmeGlobal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomatedEmailNodeConnection",
      "kind": "LinkedField",
      "name": "automatedEmails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomatedEmailNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomatedEmailNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "replyTo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ctaLabel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ctaUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetNodeConnection",
                  "kind": "LinkedField",
                  "name": "assets",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "sizeBytes",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AutomatedEmailEditor_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "5f615d9f192c87707db2f3a0ede38ae8";

export default node;
