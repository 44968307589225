/**
 * @generated SignedSource<<ad446bc1df19c8bae51c4fae53c2550c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Option_answer$data = {
  readonly exclusive: boolean;
  readonly id: string;
  readonly other: boolean;
  readonly position: number;
  readonly quota: number | null;
  readonly rating: number | null;
  readonly terminate: boolean;
  readonly text: string | null;
  readonly userSpecified: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"OptionTerminate_answer">;
  readonly " $fragmentType": "Option_answer";
};
export type Option_answer$key = {
  readonly " $data"?: Option_answer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Option_answer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Option_answer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quota",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "other",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exclusive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSpecified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OptionTerminate_answer"
    }
  ],
  "type": "AnswerNode",
  "abstractKey": null
};

(node as any).hash = "b8072089f12e352a2e88de2144189144";

export default node;
