import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { DEFAULT_PANELISTS_FETCH_COUNT } from "../../constants";
import { environment } from "../../relay";
import { RecruitDetailsQuery_Query } from "../../__generated__/RecruitDetailsQuery_Query.graphql";
import { LoadingPage } from "../index";
import RecruitDetails from "./RecruitDetails";

type Props = {
  studyId: string;
  recruitId: string;
  defaultName: string;
  deleteRecruit: (recruitId: string) => void;
};
function RecruitDetailsQuery({ studyId, recruitId, defaultName, deleteRecruit }: Props) {
  return recruitId ? (
    <QueryRenderer<RecruitDetailsQuery_Query>
      environment={environment}
      query={graphql`
        query RecruitDetailsQuery_Query($studyId: String!, $recruitId: String!, $count: Int!, $search: String) {
          viewer {
            study(studyId: $studyId) {
              ...RecruitDetails_study
            }
            recruit(recruitId: $recruitId) {
              id
              ...RecruitDetails_recruit
            }
            user {
              ...RecruitDetails_user
            }
          }
        }
      `}
      variables={{ recruitId, studyId, count: DEFAULT_PANELISTS_FETCH_COUNT, search: null }}
      render={({ error, props }) => {
        if (error) {
          console.error("Error loading table: ", error);
          return <div>Error</div>;
        } else if (props?.viewer?.recruit && props?.viewer?.user && props?.viewer?.study) {
          return (
            <RecruitDetails
              defaultName={defaultName}
              recruit={props.viewer.recruit}
              study={props.viewer.study}
              user={props.viewer.user}
              deleteRecruit={deleteRecruit}
            />
          );
        }
        return (
          <div style={{ height: "30vh", width: "100%", margin: "auto" }}>
            <LoadingPage />
          </div>
        );
      }}
    />
  ) : (
    <div>Add a recruiting round to get started</div>
  );
}

export default RecruitDetailsQuery;
