import { App, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";

import { EventsType, PROJECT_TYPES } from "../../constants";
import { GRAY_7 } from "../../style";
import { mutation } from "../../utils";
import type { ScheduleModal_respondent$key } from "../../__generated__/ScheduleModal_respondent.graphql";
import type { ScheduleModal_study$key } from "../../__generated__/ScheduleModal_study.graphql";

import { SlotPicker } from "./SlotPicker";

export const ScheduleModal = ({
  study: _study,
  respondent: _respondent,
  showModal,
  setShowModal,
  refreshData,
}: {
  study: ScheduleModal_study$key;
  respondent: ScheduleModal_respondent$key;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  refreshData?: () => void;
}) => {
  const { message, notification } = App.useApp();

  const study = useFragment(
    graphql`
      fragment ScheduleModal_study on StudyNode {
        id
        type
        eventsType
        sessions {
          edges {
            node {
              id
            }
          }
        }
        availabilitySlots {
          edges {
            node {
              id
              moderatorEmail
              start
              end
              availablePlaces
            }
          }
        }
        ...SlotPicker_study
      }
    `,
    _study
  );
  const respondent = useFragment(
    graphql`
      fragment ScheduleModal_respondent on RespondentNode {
        id
        participant {
          id
        }
        scheduledSlots {
          id
          studyAvailabilitySlot {
            id
            start
            end
            session {
              id
            }
          }
        }
      }
    `,
    _respondent
  );

  const [isWorking, setIsWorking] = useState(false);
  const [selectedSession, setSelectedSession] = useState<string | undefined>(
    (study.eventsType as EventsType) === "SINGLE" ? study.sessions?.edges?.[0]?.node?.id : undefined
  );
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedSlotId, setSelectedSlotId] = useState<string>("");

  const respondentSessions = useMemo(
    () =>
      respondent.scheduledSlots
        .filter(
          scheduledSlot =>
            !!(
              scheduledSlot?.studyAvailabilitySlot?.session.id &&
              scheduledSlot?.id &&
              scheduledSlot?.studyAvailabilitySlot?.id
            )
        )
        .reduce(
          (acc, scheduledSlot) => ({
            ...acc,
            [scheduledSlot!.studyAvailabilitySlot!.session.id]:
              study.type === PROJECT_TYPES.FOCUS_GROUP ? scheduledSlot!.studyAvailabilitySlot!.id : scheduledSlot!.id,
          }),
          {} as Record<string, string>
        ),
    [respondent.scheduledSlots, study.type]
  );

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedDate("");
    setSelectedSlotId("");
  };

  const handleScheduleSlot = async () => {
    try {
      setIsWorking(true);
      await mutation({
        variables: {
          input: {
            participantId: respondent!.participant!.id,
            slotId: selectedSlotId,
          },
        },
        mutation: graphql`
          mutation ScheduleModal_ScheduleParticipant_Mutation($input: ScheduleParticipantInput!) {
            scheduleParticipant(input: $input) {
              respondent {
                id
                masterStatus
              }
              study {
                approvedTabCount
                scheduledCount
                rejectedCount
              }
            }
          }
        `,
      });
      refreshData?.();
      notification.success({ message: "Participant scheduled successfully" });
      handleModalCancel();
    } catch {
      message.error("Error scheduling participant; please try again later or choose a different slot.");
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <StyledModal
      width={600}
      open={showModal}
      okText="Assign"
      onOk={handleScheduleSlot}
      onCancel={handleModalCancel}
      cancelButtonProps={{ disabled: isWorking }}
      okButtonProps={{ loading: isWorking, disabled: !selectedDate || !selectedSlotId }}
    >
      <SlotPicker
        selectedCount={1}
        {...{
          study,
          selectedSession,
          setSelectedSession,
          selectedDate,
          setSelectedDate,
          selectedSlotId,
          setSelectedSlotId,
          respondentSessions,
        }}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .label {
    font-weight: 500;
    margin-bottom: 4px;
  }

  .instructions {
    text-align: center;
    color: ${GRAY_7};
    margin: 20px 0 0 0;
  }

  .slots-placeholder {
    display: flex;
    height: 240px;
    justify-content: center;
    align-items: center;
  }

  .date-button {
    margin: 0 2px 4px 2px;
  }
`;
