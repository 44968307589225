import classNames, { type Argument } from "classnames";
import type { CSSProperties, ReactFragment } from "react";
import styled from "styled-components";
import { GRAY_7 } from "../../style";

export const DetailsInput = ({
  className,
  collapseBottomMargin,
  description,
  descriptionStyle,
  id,
  inputStyle,
  inputs,
  optional,
  required,
  style,
  title,
  titleStyle,
}: {
  className?: Argument;
  collapseBottomMargin?: boolean;
  description?: string | ReactFragment;
  descriptionStyle?: CSSProperties;
  id?: string;
  inputStyle?: CSSProperties;
  inputs: ReactFragment;
  optional?: boolean;
  required?: boolean;
  style?: CSSProperties;
  title: string;
  titleStyle?: CSSProperties;
}) => (
  <StyledScreenerInput
    id={id}
    className={classNames(className)}
    style={{ marginBottom: collapseBottomMargin ? 0 : 32, ...style }}
  >
    <div className="title-wrapper">
      <span className="required">{required && "*"}</span>
      <div className="title" style={titleStyle}>
        {title}
      </div>
      <div className={`optional ${optional && "right-margin"}`}>{optional && "(optional)"}</div>
    </div>
    <div style={inputStyle}>{inputs}</div>
    <div className="description" style={descriptionStyle}>
      {description}
    </div>
  </StyledScreenerInput>
);

const StyledScreenerInput = styled.div`
  .title-wrapper {
    display: flex;

    .required {
      font-size: 14px;
      color: var(--ant-error-color);
    }

    .right-margin {
      margin-right: 4px;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .optional {
      font-size: 14px;
      color: ${GRAY_7};
      margin-left: 4px;
    }
  }

  .description {
    font-size: 11px;
    color: #b3b3b3;
    text-align: left;

    p {
      margin: 0;
    }
  }
`;
