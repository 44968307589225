/**
 * @generated SignedSource<<34cb475fdb002d88a1890844629c23c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScheduleModal_respondent$data = {
  readonly id: string;
  readonly participant: {
    readonly id: string;
  } | null;
  readonly scheduledSlots: ReadonlyArray<{
    readonly id: string;
    readonly studyAvailabilitySlot: {
      readonly end: any;
      readonly id: string;
      readonly session: {
        readonly id: string;
      };
      readonly start: any;
    } | null;
  }>;
  readonly " $fragmentType": "ScheduleModal_respondent";
};
export type ScheduleModal_respondent$key = {
  readonly " $data"?: ScheduleModal_respondent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleModal_respondent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleModal_respondent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ParticipantNode",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RespondentScheduledSlotNode",
      "kind": "LinkedField",
      "name": "scheduledSlots",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyAvailabilitySlotNode",
          "kind": "LinkedField",
          "name": "studyAvailabilitySlot",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "start",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "end",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StudySessionNode",
              "kind": "LinkedField",
              "name": "session",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RespondentNode",
  "abstractKey": null
};
})();

(node as any).hash = "a06e3b83e0cc451da8a3020f24439b56";

export default node;
