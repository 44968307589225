import baselineInsertChartOutlined from "@iconify-icons/ic/baseline-insert-chart-outlined";
import baselinePersonOutline from "@iconify-icons/ic/baseline-person-outline";
import outlineQuestionAnswer from "@iconify-icons/ic/outline-question-answer";
import { InlineIcon } from "@iconify/react";
import { useRouter } from "found";
import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT, HEADER_WIDE_PX } from "../../style";
import PageHeading from "../Layouts/PageHeading";
import SubMenuLink from "../SubMenuLink";

export const PanelPage: React.FC<{}> = ({ children }) => {
  const { match } = useRouter();

  return (
    <>
      <PageHeading
        pageTitle="Community"
        headerSubMenu={
          <>
            <SubMenuLink
              className="panel-nav-report"
              active={match.location.pathname.includes("report")}
              to={`/panel/report`}
              icon={<InlineIcon width={18} icon={baselineInsertChartOutlined} />}
              text="Report"
            />
            <SubMenuLink
              className="panel-nav-panelists"
              active={match.location.pathname.includes("panelists")}
              to={`/panel/panelists`}
              icon={<InlineIcon width={18} icon={baselinePersonOutline} />}
              text="Panelists"
            />
            <SubMenuLink
              className="panel-nav-panelists"
              active={match.location.pathname.includes("characteristics")}
              to={`/panel/characteristics`}
              icon={<InlineIcon width={18} icon={outlineQuestionAnswer} />}
              text="Characteristics"
            />
          </>
        }
      />
      <Content>{children}</Content>
    </>
  );
};

const Content = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  // added to line up table w/ vertical navbar
  margin-left: 0 !important;
  padding: 24px 24px 48px;
  overflow: auto;

  .page {
    width: calc(100vw - ${HEADER_WIDE_PX}px);
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0;
  }

  .ant-menu-submenu > .ant-menu {
    background-color: #f8f8f8;
  }
`;
