export type PersonFields = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export const PERSON_FIELDS: PersonFields = {
  email: "Email",
  phoneNumber: "Phone Number",
  firstName: "First Name",
  lastName: "Last Name",
};
