/**
 * @generated SignedSource<<a2ee47d17c2f37dceb5622fdcb296429>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfig_WorkspaceSettings_Query$variables = {};
export type RouteConfig_WorkspaceSettings_Query$data = {
  readonly viewer: {
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"WorkspaceSettings_user">;
    } | null;
  };
};
export type RouteConfig_WorkspaceSettings_Query = {
  response: RouteConfig_WorkspaceSettings_Query$data;
  variables: RouteConfig_WorkspaceSettings_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfig_WorkspaceSettings_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkspaceSettings_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfig_WorkspaceSettings_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "calendarConnected",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "googleCalendarEmail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableThreatDetection",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripePaymentMethodId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requirePhoneNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableCustomPortal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomPortalNode",
                        "kind": "LinkedField",
                        "name": "customportal",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "headerMarkup",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "headerMarkupEnabled",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fakeWidgetEnabled",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac55137ec7ae6624d39d4f8e4d02db7f",
    "id": null,
    "metadata": {},
    "name": "RouteConfig_WorkspaceSettings_Query",
    "operationKind": "query",
    "text": "query RouteConfig_WorkspaceSettings_Query {\n  viewer {\n    user {\n      id\n      ...WorkspaceSettings_user\n    }\n  }\n}\n\nfragment WorkspaceSettings_user on UserNode {\n  profile {\n    tenant {\n      name\n      calendarConnected\n      googleCalendarEmail\n      enableThreatDetection\n      stripePaymentMethodId\n      requirePhoneNumber\n      enableCustomPortal\n      customportal {\n        headerMarkup\n        headerMarkupEnabled\n        id\n      }\n      fakeWidgetEnabled\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c309be92f5aa33fe26faffc8f958016d";

export default node;
