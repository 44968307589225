import ConfigProvider from "antd/es/config-provider";
import type { Theme, ThemeConfig } from "antd/es/config-provider/context";
import { useEffect, useMemo } from "react";
import type { SetRequired } from "type-fest";

import { CONFIG_GLOBAL, CONFIG_PROVIDER_PROPS, ThemeColor, THEME_CONFIG_VOXPOPME } from "./config";

const createTheme = ({ token }: SetRequired<ThemeConfig, "token">): Theme => ({
  errorColor: token.colorError,
  infoColor: token.colorInfo || token.colorPrimary,
  primaryColor: token.colorPrimary,
  processingColor: token.colorInfo || token.colorPrimary,
  successColor: token.colorSuccess,
  warningColor: token.colorWarning,
});

const createConfigProviderProps = (themeConfig: ThemeConfig) => ({
  ...CONFIG_PROVIDER_PROPS,
  theme: { ...CONFIG_PROVIDER_PROPS.theme, ...themeConfig },
});

const configureAntd = (theme: Theme = createTheme(THEME_CONFIG_VOXPOPME)) =>
  ConfigProvider.config({ ...CONFIG_GLOBAL, theme });

export const useAntdConfig = ({ root = false }: { root?: boolean } = {}) => {
  //  Kept for future reference
  //  const isVoxpopme = useFlag("platform-hubux-template");

  useEffect(() => {
    if (root) {
      configureAntd(createTheme(THEME_CONFIG_VOXPOPME));

      // override antd's computed color variants with our brand colors
      const r = document.documentElement;
      if (r) {
        r.style.setProperty("--ant-primary-color-hover", ThemeColor.VoxDodgerBlueLighter);
        r.style.setProperty("--ant-error-color-active", ThemeColor.VoxPomegranate);
      }
    }
  }, [root]);

  return useMemo(() => createConfigProviderProps(THEME_CONFIG_VOXPOPME), []);
};
