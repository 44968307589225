import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ELEMENT_TYPES } from "../../constants";
import { GRAY_6, LAYOUT_BG_COLOR, UPPER_HEADER_COLOR } from "../../style";
import { useFlag } from "../../utils";
import { ProjectOverviewPage_study$data } from "../../__generated__/ProjectOverviewPage_study.graphql";
import { default as ProjectFieldReport } from "./FieldReport/ProjectFieldReport";
import ProjectOverviewKPIs from "./ProjectOverviewKPIs";
import ProjectSpend from "./ProjectSpend";
import QuotasOverviewElementTable from "./QuotasOverviewElementTable";
import TerminantsOverviewTable from "./TerminantsOverviewTable";

type Props = {
  study: ProjectOverviewPage_study$data;
};
const ProjectOverviewPage: React.FC<Props> = ({ study }) => {
  // Keep track of arrow right/left
  const [quotasPage, setQuotasPage] = useState(0);

  // Filter out the elements that have quotas, we don't want the rest
  const elementsWithQuotas =
    study?.fieldReport?.elements?.filter(e => {
      return (
        e?.options?.filter(o => {
          // Terminants are shown elsewhere
          if (o?.terminate) return false;
          if (!o?.quota) return false;
          return true;
        }).length !== 0
      );
    }) || [];

  const switchPage = (page: any) => {
    if (page < 0) return;
    if (page > elementsWithQuotas.length - 4) return;
    setQuotasPage(page);
  };

  const quotasNavButtonIsDisabled = (direction: string) => {
    if (direction === "next") {
      return quotasPage === elementsWithQuotas.length - 4;
    } else if (direction === "prev") {
      return quotasPage === 0;
    }
  };

  // Identify all the elements that has at least one option with value "Term"
  const elementsWithTerminants =
    study?.fieldReport?.elements?.filter(
      e =>
        e?.options?.filter(
          o =>
            o?.terminate || e.gridAnswers?.some(gridAnswer => gridAnswer?.terminate && gridAnswer.answerId === o?.dbId)
        ).length !== 0
    ) || [];

  // Terminants table need to display "progress" in the bars somehow
  let highestTermNumber = elementsWithTerminants.reduce((maxTerms, element) => {
    let maxTermsThisElement = 0;

    // grid elements have different term logic than other question types
    if (element?.type === ELEMENT_TYPES.GRID_SINGLE_SELECT) {
      for (const row of element.rows!) {
        for (const option of row!.options!) {
          // ignore if neither the option nor grid answer terminate
          if (
            !option?.terminate &&
            !element.gridAnswers?.some(
              gridAnswer =>
                gridAnswer?.terminate && gridAnswer.rowId === row?.dbId && gridAnswer.answerId === option?.dbId
            )
          ) {
            continue;
          }

          maxTermsThisElement = Math.max(maxTermsThisElement, option?.total ?? 0);
        }
      }
    } else {
      // for non grid questions, just use the total
      for (const option of element!.options!.filter(o => o?.terminate)) {
        maxTermsThisElement += option!.total ?? 0;
      }
    }

    return Math.max(maxTerms, maxTermsThisElement);
  }, 0);

  const paymentsEnabled = useFlag("hub-recruit-prepay");

  return (
    <ProjectOverview>
      <ProjectOverviewKPIs study={study} />
      {paymentsEnabled && study.tenant.requireStudyPayment && (
        <section className="spend">
          <ProjectSpend study={study} />
        </section>
      )}

      {elementsWithQuotas.length > 0 && (
        <section className="quotas">
          <h2>Quotas</h2>
          {elementsWithQuotas.length > 4 && (
            <>
              <div className="quotas-navigation">
                <Button
                  shape="circle"
                  icon={<CaretLeftOutlined />}
                  disabled={quotasNavButtonIsDisabled("prev")}
                  onClick={() => switchPage(quotasPage - 1)}
                />
                <Button
                  shape="circle"
                  icon={<CaretRightOutlined />}
                  disabled={quotasNavButtonIsDisabled("next")}
                  onClick={() => switchPage(quotasPage + 1)}
                />
              </div>
            </>
          )}
          <div className="quotas-container">
            {elementsWithQuotas.map((e, i) => {
              // Only show four at a time
              if (i < quotasPage || i > quotasPage + 3) return false;

              return e && <QuotasOverviewElementTable key={i} element={e} />;
            })}
          </div>
        </section>
      )}

      {elementsWithTerminants.some(e => e?.text) && (
        <section className="terminants">
          <div className="terminants">
            <h2>Terminants</h2>
            <img src="/desktop-work-notes.png" alt="Desktop work notes" />
            <div className="terminants-container">
              {elementsWithTerminants.map(
                (e, i) => e && <TerminantsOverviewTable key={i} element={e} highestTermNumber={highestTermNumber} />
              )}
            </div>
          </div>
        </section>
      )}

      <section className="field-report">
        <div className="field-report">
          <ProjectFieldReport study={study} />
        </div>
      </section>
    </ProjectOverview>
  );
};

const ProjectOverview = styled.div`
  padding: 80px 60px 0px 60px;
  text-align: center;
  header {
    img {
      float: left;
      width: 227px;
      position: relative;
      top: -45px;
      left: 95px;
    }
    h1 {
      font-size: 36px;
      margin: 5px 0;
      color: #333a4e;
    }
    .metadata {
      font-weight: bold;
      text-transform: uppercase;
      color: ${UPPER_HEADER_COLOR};
      .ant-tag {
        background: none !important;
        color: ${GRAY_6};
        padding: 0;
        font-size: 14px;
      }
    }
  }
  .spend,
  .quotas,
  .terminants,
  .field-report {
    margin-top: 50px;
    h2 {
      font-size: 28px;
    }
  }
  .quotas {
    text-align: center;
    .quotas-navigation {
      position: relative;
      button {
        position: absolute;
        top: 95px;
      }
      /* Prev page */
      button:first-child {
        padding-right: 2px;
        left: -45px;
      }
      /* Next page */
      button:last-child {
        padding-left: 2px;
        right: -45px;
      }
    }
    button {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 9px rgba(0, 0, 0, 0.05);
    }
    .quotas-container {
      display: inline-flex;
      margin: 25px auto 0;
      > div {
        & + div {
          margin-left: 20px;
        }
      }
    }
  }
  .terminants {
    min-height: 400px;
    img {
      float: right;
      width: 302px;
      position: relative;
      right: 50px;
      top: 10px;
    }
    h2 {
      text-align: left;
      margin-left: 160px;
    }
    .terminants-container {
      background: ${LAYOUT_BG_COLOR};
      border-radius: 13px;
      padding: 20px;
      margin-top: 25px;
      width: 50%;
      text-align: left;
    }
  }
`;

export default createFragmentContainer(ProjectOverviewPage, {
  study: graphql`
    fragment ProjectOverviewPage_study on StudyNode {
      id
      name
      scheduled
      status
      type
      ...ProjectFieldReport_study
      ...ProjectSpend_study
      tenant {
        requireStudyPayment
      }
      fieldReport {
        responseSummary {
          totalNeeded {
            total
          }
          totalStarts {
            qualified
            total
          }
          screenerDropouts {
            total
          }
        }
        elements {
          ...QuotasOverviewElementTable_element
          ...TerminantsOverviewTable_element
          text
          type
          options {
            dbId
            quota
            terminate
            total
          }
          rows {
            dbId
            options {
              dbId
              total
              terminate
            }
          }
          gridAnswers {
            rowId
            answerId
            terminate
          }
        }
      }
    }
  `,
});
