import { graphql } from "babel-plugin-relay/macro";
import React, { useMemo } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { createFragmentContainer } from "react-relay";
import {
  AuditionTextElement,
  AuditionVideoElement,
  CommentElement,
  DropdownElement,
  FilesElement,
  MultiChoiceElement,
  NumberElement,
  OpenEndElement,
  RankElement,
  SingleChoiceElement,
  VideoElement,
} from ".";
import { Element_element$data } from "../../__generated__/Element_element.graphql";
import { Element_screener$data } from "../../__generated__/Element_screener.graphql";
import { SavingStateType } from "../Saving";
import DateElement from "./DateElement";
import GridSingleSelectElement from "./GridSingleSelectElement";

type Props = {
  screener: Element_screener$data;
  element: Element_element$data;
} & CommonElementProps;

export type CommonElementProps = {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isActive: boolean;
  setActive: (offset?: number) => void;
  showLabel: boolean;
  setSavingState: (savingState: SavingStateType) => void;
  savingState: SavingStateType;
};

const Element = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const contents = useMemo(() => {
    switch (props.element.type) {
      case "SS":
        return <SingleChoiceElement {...props} />;
      case "MS":
        return <MultiChoiceElement {...props} />;
      case "GS":
        return <GridSingleSelectElement {...props} />;
      case "RK":
        return <RankElement {...props} />;
      case "NM":
        return <NumberElement {...props} />;
      case "DS":
        return <DropdownElement {...props} />;
      case "OE":
        return <OpenEndElement {...props} />;
      case "RH":
        return <AuditionTextElement {...props} />;
      case "AV":
        return <AuditionVideoElement {...props} />;
      case "CM":
        return <CommentElement {...props} />;
      case "VD":
        return <VideoElement {...props} />;
      case "DT":
        return <DateElement {...props} />;
      case "FL":
        return <FilesElement {...props} />;
    }
  }, [props]);

  return contents ? <div ref={ref}>{contents}</div> : null;
});

export default createFragmentContainer(Element, {
  element: graphql`
    fragment Element_element on ElementNode {
      type
      ...ElementBase_element
      ...SingleChoiceElement_element
      ...MultiChoiceElement_element
      ...RankElement_element
      ...GridSingleSelectElement_element
      ...DropdownElement_element
      ...OpenEndElement_element
      ...AuditionTextElement_element
      ...AuditionVideoElement_element
      ...CommentElement_element
      ...NumberElement_element
      ...VideoElement_element
      ...DateElement_element
      ...FilesElement_element
    }
  `,
  screener: graphql`
    fragment Element_screener on ScreenerNode {
      ...ElementBase_screener
      ...SingleChoiceElement_screener
      ...MultiChoiceElement_screener
      ...RankElement_screener
      ...GridSingleSelectElement_screener
      ...DropdownElement_screener
      ...OpenEndElement_screener
      ...AuditionTextElement_screener
      ...AuditionVideoElement_screener
      ...CommentElement_screener
      ...NumberElement_screener
      ...VideoElement_screener
      ...DateElement_screener
      ...FilesElement_screener
    }
  `,
});
