import { Button, Progress } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { GRAY_5, LAYOUT_BG_COLOR, OVERQUOTA_COLOR, QUALIFIED_COLOR, UPPER_HEADER_COLOR } from "../../style";
import { truncate } from "../../utils/misc";
import { QuotasOverviewElementTable_element$data } from "../../__generated__/QuotasOverviewElementTable_element.graphql";

type Props = {
  element: QuotasOverviewElementTable_element$data;
};

/*
 * In the dashboard, the vertical table that shows an element's options
 * and their individual quota status.
 */
const QuotasOverviewElementTable: React.FC<Props> = ({ element }) => {
  const CUTOFF_AMOUNT = 6;
  const [showMore, setShowMore] = useState(false);

  let quotasOptionCount = 0;

  if (!element?.options) return null;

  return (
    <StyledQuotasOverviewElementTable>
      <span className="element">
        {element.label && <span className="label">{element.label}.</span>}
        <span className="text">{element.text}</span>
      </span>
      <div className="options-container">
        {element?.options?.map((o, i) => {
          // Only show options with quotas
          if (o?.terminate || !o?.quota || !o?.text) return null;

          // Count how many options we're actually showing
          quotasOptionCount++;

          // If we've shown too many without clicking "Show more", stop
          if (quotasOptionCount > CUTOFF_AMOUNT && !showMore) return null;

          let qualified = o?.qualified || 0;
          let quota = o?.quota ? parseInt(o?.quota) : 0;
          let percent = Math.ceil((qualified / quota) * 100);

          // Purple if we're over quota, else green
          let overquota = qualified > quota;
          let color = overquota ? OVERQUOTA_COLOR : QUALIFIED_COLOR || QUALIFIED_COLOR;

          const text = truncate(o?.text, 36);

          return (
            <div className="option" key={i}>
              <span className="text">{text}</span>
              <span className="value">
                <span className={"qualified " + (overquota ? "overquota" : "")}>{qualified}</span>
                <span className="quota">/{quota}</span>
              </span>
              <Progress percent={percent} showInfo={false} strokeColor={color} />
            </div>
          );
        })}

        {quotasOptionCount > CUTOFF_AMOUNT && (
          <Button size="small" onClick={() => setShowMore(showMore !== true)}>
            {showMore ? "Show less" : `+ Show ${quotasOptionCount - CUTOFF_AMOUNT} more`}
          </Button>
        )}
      </div>
    </StyledQuotasOverviewElementTable>
  );
};

const StyledQuotasOverviewElementTable = styled.div`
  width: 207px;

  .element {
    color: ${UPPER_HEADER_COLOR};
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.3;
    display: inline-block;
    padding: 7px;
    min-height: 50px;

    .label {
      color: ${GRAY_5};
      padding-right: 2px;
    }
  }

  .options-container {
    padding: 12px;
    margin-top: 15px;
    background: ${LAYOUT_BG_COLOR};
    border-radius: 13px;

    .option {
      background: white;
      text-align: left;
      border-radius: 7px;
      padding: 8px 12px 6px;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
      line-height: 1.4;
      font-size: 13px;

      .value {
        float: right;

        .qualified {
          font-weight: bold;

          &.overquota {
            color: ${OVERQUOTA_COLOR};
          }
        }

        .quota {
          color: ${GRAY_5};
        }
      }

      & + .option {
        margin-top: 13px;
      }
    }
  }

  button {
    margin-top: 15px;
    box-shadow: 1px 1px 1px #efefef !important;
    border: none !important;
    font-size: 13px;
  }
`;

export default createFragmentContainer(QuotasOverviewElementTable, {
  element: graphql`
    fragment QuotasOverviewElementTable_element on FieldReportElementNode {
      text
      type
      label
      labeledText
      options {
        text
        qualified
        qualifiedTotal
        total
        quota
        terminate
      }
    }
  `,
});
