/**
 * @generated SignedSource<<0b931038dd0236b773d3e693f0977c09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DropdownElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "DropdownElement_screener";
};
export type DropdownElement_screener$key = {
  readonly " $data"?: DropdownElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"DropdownElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DropdownElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "8508d0069e7fca1b8f5b3291c443f423";

export default node;
