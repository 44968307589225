/**
 * @generated SignedSource<<a4b7c2d09908ff4db7e0eeafd9063ed4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetails_study$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_study">;
  readonly " $fragmentType": "ProjectDetails_study";
};
export type ProjectDetails_study$key = {
  readonly " $data"?: ProjectDetails_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetails_study",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDetailsPage_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "065f49517f783860298348cff2498140";

export default node;
