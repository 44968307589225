/**
 * @generated SignedSource<<dbabc3b375ab8ab69b5a8cbe722e1f6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortalProfileComplete_characteristic$data = {
  readonly id: string;
  readonly responses: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly answer: {
          readonly id: string;
          readonly userSpecified: boolean;
        } | null;
        readonly customAnswer: string | null;
        readonly panelist: {
          readonly id: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"FormItemCharacteristicResponses_characteristicResponses">;
      } | null;
    } | null>;
  };
  readonly text: string | null;
  readonly type: ScreenersCharacteristicTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"FormItemCharacteristicResponses_characteristic">;
  readonly " $fragmentType": "PortalProfileComplete_characteristic";
};
export type PortalProfileComplete_characteristic$key = {
  readonly " $data"?: PortalProfileComplete_characteristic$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalProfileComplete_characteristic">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalProfileComplete_characteristic",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicResponseNodeConnection",
      "kind": "LinkedField",
      "name": "responses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicResponseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicResponseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CharacteristicAnswerNode",
                  "kind": "LinkedField",
                  "name": "answer",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userSpecified",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customAnswer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PanelistNode",
                  "kind": "LinkedField",
                  "name": "panelist",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FormItemCharacteristicResponses_characteristicResponses"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormItemCharacteristicResponses_characteristic"
    }
  ],
  "type": "CharacteristicNode",
  "abstractKey": null
};
})();

(node as any).hash = "3dc35ab7dd71b532b4acfd8d78882c00";

export default node;
