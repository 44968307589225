/**
 * @generated SignedSource<<35a24d94c41dd6f83731ae9e64edb161>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputCharacteristicResponseMultiSelect_characteristicResponses$data = ReadonlyArray<{
  readonly answer: {
    readonly id: string;
    readonly userSpecified: boolean;
  } | null;
  readonly customAnswer: string | null;
  readonly id: string;
  readonly " $fragmentType": "InputCharacteristicResponseMultiSelect_characteristicResponses";
}>;
export type InputCharacteristicResponseMultiSelect_characteristicResponses$key = ReadonlyArray<{
  readonly " $data"?: InputCharacteristicResponseMultiSelect_characteristicResponses$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputCharacteristicResponseMultiSelect_characteristicResponses">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InputCharacteristicResponseMultiSelect_characteristicResponses",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAnswer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicAnswerNode",
      "kind": "LinkedField",
      "name": "answer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userSpecified",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CharacteristicResponseNode",
  "abstractKey": null
};
})();

(node as any).hash = "02f2ffa29b23a0703dea53fd4eccacdd";

export default node;
