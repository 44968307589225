import { HistoryOutlined, ProfileOutlined, UserOutlined } from "@ant-design/icons";
import feedbackOutlinedIcon from "@iconify-icons/ic/outline-feedback";
import { InlineIcon } from "@iconify/react";
import { Button, Card, ConfigProvider, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { ConfigProviderAccent, ThemeColor } from "../../antd";

import { PAGE_WIDTH_LG, PAGE_WIDTH_MD } from "../../style";
import { sentenceCase } from "../../utils";
import { getFaviconEl } from "../../utils/misc";
import type { Portal_user$key } from "../../__generated__/Portal_user.graphql";

import AvailableStudies from "./Cards/AvailableStudies";
import PortalCard from "./Cards/PortalCard";
import StyledCard from "./Cards/StyledCard";
import YourHistory from "./Cards/YourHistory";
import YourProfile from "./Cards/YourProfile";
import PortalBanner from "./PortalBanner";
import { PortalFooter } from "./PortalFooter";
import { PortalPageLayout } from "./PortalPageLayout";
import { useCustomPortalSanitized } from "./utils";

const Portal = ({ user: userKey }: { user: Portal_user$key }) => {
  const user = useFragment(
    graphql`
      fragment Portal_user on UserNode {
        panelist {
          dId
          customPanelistPortal {
            heroAssetUrl
            heroBackgroundUrl
            footerImageUrl
            fadeBgBottom
            customColor
            privacyPolicyUrl
            termsLabel
            termsUrl
            faviconUrl
            pageTitle
            contactEmail
            headerMarkupEnabled
            headerMarkup
            hidePanelistProfile
            hidePanelistProfileCompleteBanner
            tenant {
              id
              name
              portalCards {
                edges {
                  node {
                    id
                    title
                    ...PortalCard_portalCard
                  }
                }
              }
            }
          }
          person {
            firstName
            lastName
            country
          }
          selectedResponses {
            answers {
              key
              selected
            }
            characteristic {
              id
            }
          }
        }
        ...YourProfile_user
        ...YourHistory_user
        ...AvailableStudies_user
        ...PortalCard_user
      }
    `,
    userKey
  );
  const customPortal = user.panelist?.customPanelistPortal;
  const { contactEmail, heroAssetUrl } = useCustomPortalSanitized(customPortal as any);

  const { t } = useTranslation();

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) faviconEl.href = customPortal?.faviconUrl;
    document.title = customPortal?.pageTitle ?? "HubUX";
  }, [customPortal]);

  const selectedResponses =
    user.panelist?.selectedResponses?.filter(x => x.characteristic.id && !x.answers.some(x => x.selected)) ?? [];

  return (
    <PortalPageLayout
      customColor={customPortal?.customColor}
      footer={
        <PortalFooter
          privacyPolicyLink={customPortal?.privacyPolicyUrl}
          termsLabel={customPortal?.termsLabel}
          termsUrl={customPortal?.termsUrl}
          customFooterUrl={customPortal?.footerImageUrl}
          contactEmail={contactEmail}
        />
      }
    >
      <Styled>
        <header className="col-span-2">
          {/* wrapped to contain custom markup*/}
          <PortalBanner
            customBackgroundUrl={customPortal?.heroBackgroundUrl}
            customHeroAssetUrl={heroAssetUrl}
            isBgFaded={customPortal?.fadeBgBottom}
            markup={customPortal && customPortal!.headerMarkup}
            markupEnabled={customPortal && customPortal!.headerMarkupEnabled}
            panelistId={user?.panelist?.dId}
          />
        </header>
        <div className="main">
          {!!selectedResponses[0] && !customPortal?.hidePanelistProfileCompleteBanner && (
            <ConfigProviderAccent>
              <ConfigProvider
                theme={{
                  token: {
                    colorTextBase: ThemeColor.VoxGrape,
                  },
                }}
              >
                <Card className="hub-card-profile-complete col-span-2">
                  <div>
                    <Typography.Title level={5}>
                      {t("portal.complete-profile.title", { name: user?.panelist?.person?.firstName ?? "there" })}
                    </Typography.Title>
                    <Typography.Paragraph>{t("portal.complete-profile.subtitle")}</Typography.Paragraph>
                  </div>
                  <Link
                    to={`/portal/profile/complete/${encodeURIComponent(selectedResponses[0].characteristic.id)}${
                      selectedResponses[1] ? `?next=${encodeURIComponent(selectedResponses[1].characteristic.id)}` : ""
                    }`}
                  >
                    {({ href, onClick }) => (
                      <Button href={href} onClick={onClick} type="primary">
                        {sentenceCase(`${t("dictionary.verb.complete")} ${t("dictionary.noun.profile")}`)}
                      </Button>
                    )}
                  </Link>
                </Card>
              </ConfigProvider>
            </ConfigProviderAccent>
          )}
          {!user.panelist?.customPanelistPortal?.hidePanelistProfile && (
            <StyledCard
              title={
                <div className="card-title-container">
                  <UserOutlined className="icon" />{" "}
                  <div className="card-title">{t("portal.your-profile", "Your Profile")}</div>
                </div>
              }
            >
              <YourProfile user={user} />
            </StyledCard>
          )}
          <StyledCard
            title={
              <div className="card-title-container">
                <ProfileOutlined className="icon" />{" "}
                <div className="card-title">{t("portal.available-studies", "Available Studies")}</div>
              </div>
            }
          >
            <AvailableStudies user={user} />
          </StyledCard>
          <StyledCard
            title={
              <div className="card-title-container">
                <HistoryOutlined className="icon" />{" "}
                <div className="card-title">{t("portal.reward-history", "Reward History")}</div>
              </div>
            }
          >
            <YourHistory user={user} />
          </StyledCard>
          {user.panelist?.customPanelistPortal?.tenant.portalCards.edges.map(portalCard => {
            if (portalCard?.node) {
              return (
                <StyledCard
                  key={portalCard.node?.id}
                  title={
                    <div className="card-title-container">
                      <InlineIcon className="icon" icon={feedbackOutlinedIcon} />
                      <div className="card-title">{portalCard.node?.title}</div>
                    </div>
                  }
                >
                  <PortalCard user={user} portalCard={portalCard.node} />
                </StyledCard>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Styled>
    </PortalPageLayout>
  );
};

const Styled = styled.section`
  --hub-portal-space: 16px;

  @media (min-width: ${PAGE_WIDTH_MD}) {
    --hub-portal-space: 32px;
  }

  .main {
    display: grid;
    grid-template-columns: 1fr;
    padding: var(--hub-portal-space);
    gap: var(--hub-portal-space);
    max-width: ${PAGE_WIDTH_LG};
    margin: 0 auto;

    @media (min-width: ${PAGE_WIDTH_MD}) {
      grid-template-columns: repeat(2, 1fr);

      .col-span-2 {
        grid-column: span 2 / span 2;
      }
    }
  }

  .card-title-container {
    display: flex;
    align-items: center;

    .icon {
      font-size: 1.5rem;
    }

    .card-title {
      display: inline;
      padding-left: 0.7rem;
    }
  }

  .hub-card-profile-complete {
    background-color: initial;
    border: 2px solid currentColor;

    .ant-card-body {
      padding: 16px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      text-align: center;
      gap: var(--hub-portal-space);

      @media (min-width: ${PAGE_WIDTH_MD}) {
        grid-template-columns: 1fr auto;
        justify-items: initial;
        text-align: initial;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    .ant-typography {
      margin: 0;

      &:is(h5) {
        margin-bottom: 4px;
      }
    }
  }
`;
export default Portal;
