import { useFlags } from "launchdarkly-react-client-sdk";

// synced with backend's `utils/features.js`
export const CONTEXT_KEY_SHARED = "respondent";

type Flags = {
  // https://app.launchdarkly.com/hub-ux/test/features/fake-widget-enabled
  "fake-widget-enabled": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-project-link-to-vpm-participant-responded
  "hub-project-link-to-vpm-participant-responded": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-project-link-to-vpm-participant-interviewed
  "hub-project-link-to-vpm-participant-interviewed": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-project-link-to-vpm-participants
  "hub-project-link-to-vpm-participants": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-project-name-public-required
  "hub-project-name-public-required": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-screener-researchdefender
  "hub-screener-researchdefender": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-signup-self
  "hub-signup-self": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-recruit-prepay
  "hub-recruit-prepay": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hub-recruit-type-hubux-panel
  "hub-recruit-type-hubux-panel": boolean;

  /**
   * https://app.launchdarkly.com/hub-ux/test/features/platform-hubux-template
   *
   * Brand theme
   * true = Voxpopme
   * false = HubUX (deprecated)
   */
  "platform-hubux-template": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/vpm-left-hand-nav
  "vpm-left-hand-nav": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/hubux-vpm-export
  "hubux-vpm-export": boolean;

  // https://app.launchdarkly.com/hub-ux/test/features/notetaker-enabled
  "notetaker-enabled": boolean;

  "hub-vpm-gloabl-panel": boolean;
};

// Usage:
// const useNewTemplate = useFlag("platform-hubux-template");
export const useFlag = <K extends keyof Flags>(key: K) => useFlags<Flags>()[key];
