/**
 * @generated SignedSource<<2caeb91919d7756fa5f1a7fc5d346fb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsPage_tenant$data = {
  readonly allowExternalIncentive: boolean;
  readonly defaultSurveysToUseApi: boolean;
  readonly externalIncentivePerParticipantCostUsdCents: number;
  readonly voxpopmeApiKey: string | null;
  readonly voxpopmeLiveDefaultProjectId: number | null;
  readonly vpmAccountId: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"InputTags_tenant">;
  readonly " $fragmentType": "ProjectDetailsPage_tenant";
};
export type ProjectDetailsPage_tenant$key = {
  readonly " $data"?: ProjectDetailsPage_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsPage_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsPage_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultSurveysToUseApi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowExternalIncentive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalIncentivePerParticipantCostUsdCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vpmAccountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voxpopmeLiveDefaultProjectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voxpopmeApiKey",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputTags_tenant"
    }
  ],
  "type": "TenantNode",
  "abstractKey": null
};

(node as any).hash = "f0f60272c587fffc54fad3744e21f4fa";

export default node;
