/**
 * @generated SignedSource<<24a1f56e10ef408d4929f71623039c0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmExternalIncentiveModal_study$data = {
  readonly externalIncentive: {
    readonly automatedEmail: {
      readonly enabled: boolean;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditExternalIncentiveEmailModal_study">;
  readonly " $fragmentType": "ConfirmExternalIncentiveModal_study";
};
export type ConfirmExternalIncentiveModal_study$key = {
  readonly " $data"?: ConfirmExternalIncentiveModal_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmExternalIncentiveModal_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmExternalIncentiveModal_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalIncentiveNode",
      "kind": "LinkedField",
      "name": "externalIncentive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomatedEmailNode",
          "kind": "LinkedField",
          "name": "automatedEmail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditExternalIncentiveEmailModal_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "9c68c801c06d48376666a6b60e4a4e4e";

export default node;
