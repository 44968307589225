/**
 * @generated SignedSource<<00488bdc6cc588daf3220913a8d15dcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenersRecruitTypeChoices = "EM" | "HP" | "RO" | "WL" | "YP" | "%future added value";
export type ChangeRecruitTypeInput = {
  clientMutationId?: string | null;
  recruitId: string;
  recruitType: string;
};
export type RecruitTypePickerMutation$variables = {
  input: ChangeRecruitTypeInput;
};
export type RecruitTypePickerMutation$data = {
  readonly changeRecruitType: {
    readonly recruit: {
      readonly id: string;
      readonly type: ScreenersRecruitTypeChoices | null;
    } | null;
  } | null;
};
export type RecruitTypePickerMutation = {
  response: RecruitTypePickerMutation$data;
  variables: RecruitTypePickerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeRecruitTypePayload",
    "kind": "LinkedField",
    "name": "changeRecruitType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RecruitNode",
        "kind": "LinkedField",
        "name": "recruit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecruitTypePickerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecruitTypePickerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6bf75fbb8603efb5b78831e60375d1c6",
    "id": null,
    "metadata": {},
    "name": "RecruitTypePickerMutation",
    "operationKind": "mutation",
    "text": "mutation RecruitTypePickerMutation(\n  $input: ChangeRecruitTypeInput!\n) {\n  changeRecruitType(input: $input) {\n    recruit {\n      id\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "12d23667867e15dd5fd63456f81587e9";

export default node;
