import { DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { App, Input, InputNumber, InputRef, Popover, Switch, Table, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { SAVING_STATES } from "../../constants";
import { GRAY_2, GRAY_6 } from "../../style";
import { mutation } from "../../utils";
import { cleanDataTransferText } from "../../utils/misc";
import type { GridQuestionEditor_addColumn_Mutation } from "../../__generated__/GridQuestionEditor_addColumn_Mutation.graphql";
import type { GridQuestionEditor_addRow_Mutation } from "../../__generated__/GridQuestionEditor_addRow_Mutation.graphql";
import type { GridQuestionEditor_deleteColumn_Mutation } from "../../__generated__/GridQuestionEditor_deleteColumn_Mutation.graphql";
import type { GridQuestionEditor_deleteRow_Mutation } from "../../__generated__/GridQuestionEditor_deleteRow_Mutation.graphql";
import type { GridQuestionEditor_element$data } from "../../__generated__/GridQuestionEditor_element.graphql";
import type { GridQuestionEditor_insertColumns_Mutation } from "../../__generated__/GridQuestionEditor_insertColumns_Mutation.graphql";
import type { GridQuestionEditor_insertRows_Mutation } from "../../__generated__/GridQuestionEditor_insertRows_Mutation.graphql";
import type { GridQuestionEditor_updateColumn_Mutation } from "../../__generated__/GridQuestionEditor_updateColumn_Mutation.graphql";
import type { GridQuestionEditor_updateGridAnswer_Mutation } from "../../__generated__/GridQuestionEditor_updateGridAnswer_Mutation.graphql";
import type { GridQuestionEditor_updateRow_Mutation } from "../../__generated__/GridQuestionEditor_updateRow_Mutation.graphql";
import { SavingStateType } from "../Saving";

type Props = {
  element: GridQuestionEditor_element$data;
  setSavingState: (savingState: SavingStateType) => void;
};

function GridQuestionEditor({ element, setSavingState }: Props) {
  const { message } = App.useApp();

  const ADD_ROW_KEY = "ADD_ROW";

  // handle focusing on a new row when adding one
  const [newRowIndex, setNewRowIndex] = useState<number | null>();
  const newRowRef = useRef<InputRef>(null);
  useEffect(() => newRowRef.current?.focus(), [newRowIndex]);

  // handle focusing on a new column when adding one
  const [newColumnIndex, setNewColumnIndex] = useState<number | null>();
  const newColumnRef = useRef<InputRef>(null);
  useEffect(() => newColumnRef.current?.focus(), [newColumnIndex]);

  async function addRow(position?: number) {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<GridQuestionEditor_addRow_Mutation>({
        variables: {
          input: {
            elementId: element.id,
            position,
          },
        },
        mutation: graphql`
          mutation GridQuestionEditor_addRow_Mutation($input: AddRowInput!) {
            addRow(input: $input) {
              element {
                id
                rows {
                  edges {
                    node {
                      id
                      dbId
                      text
                      position
                    }
                  }
                }
                gridAnswers {
                  row {
                    id
                    dbId
                    position
                  }
                  answer {
                    id
                    dbId
                  }
                  goal
                  terminate
                }
              }
            }
          }
        `,
      });
      // focus the row at position if provided, otherwise the last row
      setNewRowIndex(typeof position === "number" ? position : element.rows.edges.length);
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function insertRows(rows: string[], position: number) {
    setSavingState(SAVING_STATES.SAVING);

    try {
      await mutation<GridQuestionEditor_insertRows_Mutation>({
        variables: {
          input: { elementId: element.id, rows, position },
        },
        mutation: graphql`
          mutation GridQuestionEditor_insertRows_Mutation($input: InsertRowsInput!) {
            insertRows(input: $input) {
              element {
                rows {
                  edges {
                    node {
                      id
                      dbId
                      text
                      position
                    }
                  }
                }
                gridAnswers {
                  row {
                    id
                    dbId
                  }
                  answer {
                    id
                    dbId
                  }
                  terminate
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function updateRow(rowId: string, text: string) {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<GridQuestionEditor_updateRow_Mutation>({
        variables: {
          input: {
            rowId,
            text,
          },
        },
        mutation: graphql`
          mutation GridQuestionEditor_updateRow_Mutation($input: UpdateRowInput!) {
            updateRow(input: $input) {
              row {
                id
                text
              }
            }
          }
        `,
        optimisticResponse: {
          updateRow: {
            row: {
              id: rowId,
              text,
            },
          },
        },
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function deleteRow(rowId: string) {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<GridQuestionEditor_deleteRow_Mutation>({
        variables: {
          input: {
            rowId,
          },
        },
        mutation: graphql`
          mutation GridQuestionEditor_deleteRow_Mutation($input: DeleteRowInput!) {
            deleteRow(input: $input) {
              element {
                id
                rows {
                  edges {
                    node {
                      id
                      dbId
                      text
                      position
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function addColumn({
    position,
    other,
    terminate,
  }: {
    position?: number;
    other?: boolean;
    terminate?: boolean;
  }) {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<GridQuestionEditor_addColumn_Mutation>({
        variables: {
          input: {
            elementId: element.id,
            position,
            other: other ?? false,
            terminate: terminate ?? false,
          },
        },
        mutation: graphql`
          mutation GridQuestionEditor_addColumn_Mutation($input: AddAnswerInput!) {
            addAnswer(input: $input) {
              element {
                id
                answers {
                  edges {
                    node {
                      id
                      text
                      terminate
                      position
                    }
                  }
                }
                gridAnswers {
                  row {
                    id
                  }
                  answer {
                    id
                  }
                  goal
                  terminate
                }
              }
            }
          }
        `,
      });
      // focus the column at position if provided, otherwise the last column
      setNewColumnIndex(typeof position === "number" ? position : element.answers.edges.length);
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function insertColumns(columns: string[], position: number) {
    setSavingState(SAVING_STATES.SAVING);

    try {
      await mutation<GridQuestionEditor_insertColumns_Mutation>({
        variables: {
          input: { elementId: element.id, answers: columns, position },
        },
        mutation: graphql`
          mutation GridQuestionEditor_insertColumns_Mutation($input: InsertAnswersInput!) {
            insertAnswers(input: $input) {
              element {
                answers {
                  edges {
                    node {
                      text
                      position
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function updateColumn(column: any, updates: { terminate?: boolean; text?: string }) {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<GridQuestionEditor_updateColumn_Mutation>({
        variables: { input: { answerId: column.id, ...updates } },
        mutation: graphql`
          mutation GridQuestionEditor_updateColumn_Mutation($input: UpdateAnswerInput!) {
            updateAnswer(input: $input) {
              answer {
                id
                terminate
                text
              }
              element {
                answers {
                  edges {
                    node {
                      id
                      terminate
                    }
                  }
                }
                rows {
                  edges {
                    node {
                      id
                    }
                  }
                }
                gridAnswers {
                  row {
                    id
                  }
                  answer {
                    id
                  }
                  goal
                  terminate
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  async function deleteColumn(columnId: string) {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<GridQuestionEditor_deleteColumn_Mutation>({
        variables: {
          input: {
            answerId: columnId,
          },
        },
        mutation: graphql`
          mutation GridQuestionEditor_deleteColumn_Mutation($input: DeleteAnswerInput!) {
            deleteAnswer(input: $input) {
              element {
                id
                answers {
                  edges {
                    node {
                      id
                      text
                      position
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  }

  const updateGridAnswer = useCallback(
    async ({ rowId, answerId, ...rest }: { rowId: string; answerId: string; goal?: number; terminate?: boolean }) => {
      try {
        setSavingState(SAVING_STATES.SAVING);
        await mutation<GridQuestionEditor_updateGridAnswer_Mutation>({
          variables: {
            input: {
              rowId,
              answerId,
              ...rest,
            },
          },
          mutation: graphql`
            mutation GridQuestionEditor_updateGridAnswer_Mutation($input: UpdateGridAnswerInput!) {
              updateGridAnswer(input: $input) {
                element {
                  gridAnswers {
                    row {
                      id
                    }
                    answer {
                      id
                    }
                    goal
                    terminate
                  }
                  rows {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                  answers {
                    edges {
                      node {
                        id
                        terminate
                      }
                    }
                  }
                }
              }
            }
          `,
        });
        setSavingState(SAVING_STATES.SAVED);
      } catch {
        setSavingState(SAVING_STATES.ERROR);
      }
    },
    [setSavingState]
  );

  function findGridAnswer(rowId?: string, columnId?: string) {
    return element.gridAnswers?.find(a => a?.row.id === rowId && a?.answer.id === columnId);
  }

  // Popovers are outside the main CSS context so we'll use style properties
  const flex: CSSProperties = { display: "flex", alignItems: "center", gap: 8 };
  const flexGrow: CSSProperties = { flexGrow: 1 };

  const isCharacteristic = !!element.characteristic?.importKey;
  const usePreviousAnswers = !!JSON.parse(element?.usePreviousAnswers)?.enabled;

  const handleRowEnterPress = (evt: React.KeyboardEvent<HTMLTextAreaElement>, newRowIndex: number) => {
    // allow newline if shift is held
    if (evt.shiftKey) {
      return;
    }

    // prevent newline in TextArea and add a row below
    evt.preventDefault();
    addRow(newRowIndex);
  };

  const dataSource = [
    ...element.rows.edges.map(e => ({ key: e?.node?.id, name: e?.node?.text ?? "", ...e?.node })),
    { key: ADD_ROW_KEY },
  ];
  const columns = [
    {
      dataIndex: "name",
      fixed: true,
      width: 190,
      render: (name: string, record: any, i: number) =>
        record.key !== ADD_ROW_KEY ? (
          <div id={`screener-element-row-answer-${i}`} className="flex">
            <Input.TextArea
              ref={newRowIndex === i ? newRowRef : null}
              disabled={isCharacteristic || usePreviousAnswers}
              placeholder={`Row ${i + 1}`}
              defaultValue={name}
              onPressEnter={evt => handleRowEnterPress(evt, i + 1)}
              onBlur={evt => updateRow(record.key, evt.target.value)}
              onPaste={async evt => {
                const newRows = cleanDataTransferText(evt.clipboardData);
                if (newRows.length > 1) {
                  evt.preventDefault();
                  await insertRows(newRows, record.position);
                  message.info(`Added ${newRows.length} rows`);
                }
              }}
              className="grow"
              autoSize
              maxLength={512}
            />
            {!isCharacteristic && !usePreviousAnswers && <DeleteOutlined onClick={() => deleteRow(record.key)} />}
          </div>
        ) : !isCharacteristic && !usePreviousAnswers ? (
          <span
            className="action screener-element-add-row"
            style={{ color: "var(--ant-primary-color)" }}
            onClick={() => addRow()}
          >
            <PlusOutlined /> Add row
          </span>
        ) : null,
    },
    ...element.answers.edges.map((e, i) => ({
      title: (
        <div id={`screener-element-answer-${i}`} className="flex">
          {!isCharacteristic && (
            <Tooltip title="Add column" placement="bottom">
              <PlusOutlined onClick={() => addColumn({ position: e?.node?.position })} />
            </Tooltip>
          )}
          <Input
            ref={newColumnIndex === i ? newColumnRef : null}
            disabled={isCharacteristic}
            placeholder={`Column ${i + 1}`}
            defaultValue={e?.node?.text ?? ""}
            onPressEnter={() => addColumn({ position: i + 1 })}
            onBlur={evt => updateColumn(e?.node, { text: evt.target.value })}
            onPaste={async evt => {
              const newColumns = cleanDataTransferText(evt.clipboardData);
              if (newColumns.length > 1) {
                evt.preventDefault();
                await insertColumns(newColumns, e!.node!.position);
                message.info(`Added ${newColumns.length} columns`);
              }
            }}
            className="grow"
            maxLength={512}
          />
          <Popover
            trigger="click"
            getPopupContainer={trigger => trigger.parentElement!}
            content={
              <div style={{ ...flex, width: 150 }}>
                <label style={{ ...flex, ...flexGrow }}>
                  <span style={flexGrow}>Terminate all</span>
                  <Switch
                    checked={e?.node?.terminate}
                    onChange={terminate => updateColumn(e?.node, { terminate })}
                    size="small"
                    style={{ backgroundColor: e?.node?.terminate ? "var(--ant-error-color-active)" : GRAY_6 }}
                  />
                </label>
                {!isCharacteristic && <DeleteOutlined onClick={() => deleteColumn(e!.node!.id)} />}
              </div>
            }
          >
            <MoreOutlined />
          </Popover>
        </div>
      ),
      dataIndex: e?.node?.id,
      render: (_: any, record: any) =>
        record.key !== ADD_ROW_KEY && (
          <GridAnswerOptions row={record} column={e?.node} gridAnswer={findGridAnswer(record.key, e?.node?.id)} />
        ),
    })),
    {
      title: !isCharacteristic ? (
        <span
          className="action screener-element-add-option"
          style={{ color: "var(--ant-primary-color)" }}
          onClick={() => addColumn({ other: false })}
        >
          <PlusOutlined /> Add column
        </span>
      ) : null,
    },
  ];

  const [activeCell, setActiveCell] = useState<{ rowId?: string; columnId?: string }>();

  const GridAnswerOptions = useCallback(
    ({ row, column, gridAnswer }: { row: any; column: any; gridAnswer?: any }) => {
      const rowId = row.key;
      const columnId = column.id;
      const isActiveCell = rowId === activeCell?.rowId && columnId === activeCell?.columnId;

      return (
        <div
          className="flex"
          style={{ width: "100%", minHeight: 30, justifyContent: "space-around" }}
          onMouseEnter={() => setActiveCell({ rowId, columnId })}
          onMouseLeave={() => setActiveCell({ rowId: undefined, columnId: undefined })}
        >
          {(isActiveCell || typeof gridAnswer?.goal === "number") && (
            <InputNumber
              type="number"
              placeholder="Goal"
              value={gridAnswer?.goal}
              onChange={goal => updateGridAnswer({ rowId, answerId: columnId, goal })}
              className="grow"
              style={{ maxWidth: "50%" }}
            />
          )}
          {(isActiveCell || gridAnswer?.terminate) && (
            <div className="grow" style={{ maxWidth: "50%" }}>
              <label className="flex">
                <span className="grow">Terminate</span>{" "}
                <Switch
                  checked={gridAnswer?.terminate}
                  size="small"
                  onChange={terminate => updateGridAnswer({ rowId, answerId: columnId, terminate })}
                  style={{ backgroundColor: gridAnswer?.terminate ? "var(--ant-error-color-active)" : GRAY_6 }}
                />
              </label>
            </div>
          )}
        </div>
      );
    },
    [activeCell, updateGridAnswer]
  );

  return (
    <StyledGridQuestionEditor>
      <Table dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 220 * columns.length }} />
    </StyledGridQuestionEditor>
  );
}

const StyledGridQuestionEditor = styled.div`
  .flex {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .grow {
    flex-grow: 1;
  }

  Table {
    th {
      background-color: ${GRAY_2};
      font-weight: normal;
    }
    tbody td:first-child {
      background-color: ${GRAY_2};
    }
  }
`;

export default createFragmentContainer(GridQuestionEditor, {
  element: graphql`
    fragment GridQuestionEditor_element on ElementNode {
      id
      usePreviousAnswers
      characteristic {
        importKey
      }
      answers {
        edges {
          node {
            id
            position
            terminate
            text
          }
        }
      }
      rows {
        edges {
          node {
            id
            text
            position
          }
        }
      }
      gridAnswers {
        row {
          id
        }
        answer {
          id
        }
        id
        goal
        terminate
      }
    }
  `,
});
