/**
 * @generated SignedSource<<66af5df4dab391ba3cb38f0e32a9c9f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudySchedulingTypeChoices = "CL" | "HX" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SlotsPage_study$data = {
  readonly approvedCount: number | null;
  readonly availabilityBuffer: number | null;
  readonly availabilitySlots: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly availabilityBuffer: number | null;
        readonly countOfBookedSlots: number | null;
        readonly duration: number | null;
        readonly end: any;
        readonly id: string;
        readonly inPerson: boolean;
        readonly interviewer: {
          readonly email: string;
        } | null;
        readonly meetingDetails: string | null;
        readonly meetingLink: string | null;
        readonly meetingLocation: string | null;
        readonly placesLimit: number | null;
        readonly start: any;
      } | null;
    } | null>;
  };
  readonly dId: any | null;
  readonly id: string;
  readonly meetingLink: string | null;
  readonly schedulingLink: string | null;
  readonly schedulingType: ScreenersStudySchedulingTypeChoices;
  readonly status: ScreenersStudyStatusChoices;
  readonly type: ScreenersStudyTypeChoices;
  readonly voxpopmeRecorderEnabled: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"SessionsForm_study">;
  readonly " $fragmentType": "SlotsPage_study";
};
export type SlotsPage_study$key = {
  readonly " $data"?: SlotsPage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlotsPage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityBuffer",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meetingLink",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlotsPage_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyAvailabilitySlotConnection",
      "kind": "LinkedField",
      "name": "availabilitySlots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudyAvailabilitySlotEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudyAvailabilitySlotNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "end",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "duration",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InterviewerNode",
                  "kind": "LinkedField",
                  "name": "interviewer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inPerson",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "meetingLocation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "meetingDetails",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "placesLimit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "countOfBookedSlots",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedulingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedulingLink",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voxpopmeRecorderEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SessionsForm_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "0ab4f65cecb76869dda1a140f98c3c3b";

export default node;
