import { CreditCardOutlined } from "@ant-design/icons";
import { Card, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import React, { useState } from "react";
import { commitMutation, createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ModalPrepayDisabled } from "..";
import { PROJECT_INCENTIVE_TYPES } from "../../constants";
import { environment } from "../../relay";
import { CONTAINER_BOX_SHADOW } from "../../style";
import { handleErrorWithMessage, useFlag } from "../../utils";
import {
  calculateParticipantCost,
  calculateProjectCost,
  managePaymentMethod,
  usdFormatterNeat,
} from "../../utils/misc";

type Props = {
  recruit: any;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  startRecruit: (paid: boolean) => void;
};
const RecruitPaymentModal = ({ recruit, visible, setVisible, startRecruit }: Props) => {
  const { match } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  const addPayment = async () => {
    setLoading(true);
    await managePaymentMethod(match.location.pathname);
    setLoading(false);
  };

  const payMutation = () => {
    return new Promise((resolve, reject) => {
      const handleError = (err: any) => {
        handleErrorWithMessage(err, "Payment failed. Please retry or contact us.");
        reject(err);
      };
      commitMutation(environment, {
        mutation: graphql`
          mutation RecruitPaymentModal_PayForRecruit_Mutation($input: PayForRecruitInput!) {
            payForRecruit(input: $input) {
              recruitTransaction {
                id
                transactionType
                value
                recruit {
                  id
                }
              }
            }
          }
        `,
        variables: { input: { recruitId: recruit.id } },
        onCompleted: (response, errors) => {
          if (errors) return handleError(errors);
          resolve(response);
        },
        onError: handleError,
      });
    });
  };

  const makePayment = async () => {
    setLoading(true);
    try {
      await payMutation();
      await startRecruit(true);
    } catch (e) {
      // Errors are handled in the mutations. Above try is to ensure startRecruit only runs
      // if payMutation() is successful
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  return useFlag("hub-recruit-prepay") ? (
    recruit.study.tenant.stripePaymentMethodId ? (
      <Modal
        title="Pay for recruiting round and start recruiting"
        open={visible}
        width={350}
        centered={true}
        okButtonProps={{ disabled: !recruit.study.tenant.stripePaymentMethodId, loading: loading }}
        onOk={makePayment}
        okText="Pay and Start Recruiting"
        onCancel={() => setVisible(false)}
      >
        <StyledModal>
          <div className="modal-container">
            <div className="modal-cost-table">
              <table className="modal-table">
                <tbody>
                  <tr className="table-row">
                    <td>Participants</td>
                    <td>{recruit.goal}</td>
                  </tr>
                  <tr className="table-row">
                    <td>Incentive + Fee</td>
                    <td>
                      {usdFormatterNeat.format(
                        calculateParticipantCost(
                          recruit.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
                            ? recruit.incentivePoints
                            : parseFloat(recruit.incentive),
                          recruit.incentiveType,
                          recruit.type,
                          recruit.study.tenant.externalIncentivePerParticipantCostUsdCents
                        ) || 0
                      )}
                    </td>
                  </tr>
                  <tr className="table-row table-summary">
                    <td>Total</td>
                    <td>
                      {usdFormatterNeat.format(
                        calculateProjectCost(
                          recruit.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
                            ? recruit.incentivePoints
                            : parseFloat(recruit.incentive),
                          recruit.incentiveType,
                          recruit.goal,
                          recruit.type,
                          recruit.study.tenant.externalIncentivePerParticipantCostUsdCents
                        ) || 0
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="payment-container">
              <div className="payment-title">Pay using this card</div>
              <Card className="payment-card">
                <CreditCardOutlined className="card-icon" />{" "}
                {`**** **** **** ${recruit.study.tenant.stripePaymentMethodLast4}`}
              </Card>
              <div className="payment-title">
                If the round doesn't reach your recruitment goal, we will refund all unused funds on project completion.
              </div>
            </div>
          </div>
        </StyledModal>
      </Modal>
    ) : (
      <Modal
        title="Add Payment Method"
        open={visible}
        width={350}
        centered={true}
        okButtonProps={{ loading: loading }}
        onOk={addPayment}
        okText="Add payment method"
        onCancel={() => setVisible(false)}
      >
        Before you can start recruiting you'll need to add a payment method. You'll only need to add this once.
      </Modal>
    )
  ) : (
    <ModalPrepayDisabled open={visible} setOpen={setVisible} />
  );
};

const StyledModal = styled.div`
  .modal-table {
    width: 100%;

    .table-row {
      display: flex;
      justify-content: space-between;
    }

    .table-summary {
      font-weight: 500;
    }
  }

  .payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .payment-title {
      margin-top: 1rem;
      font-size: 12px;
      text-align: center;
    }

    .payment-card {
      margin-top: 0.5rem;
      width: 100%;
      box-shadow: ${CONTAINER_BOX_SHADOW};
      font-weight: 500;

      .card-icon {
        font-size: 20px;
        padding-right: 0.5rem;
      }
    }

    .add-payment-btn {
      margin-top: 0.5rem;
    }
  }
`;

export default createFragmentContainer(RecruitPaymentModal, {
  recruit: graphql`
    fragment RecruitPaymentModal_recruit on RecruitNode {
      id
      name
      type
      status
      goal
      incentiveType
      incentive
      incentivePoints
      recruitTransactions {
        edges {
          node {
            id
          }
        }
      }
      study {
        tenant {
          stripePaymentMethodId
          stripePaymentMethodLast4
          externalIncentivePerParticipantCostUsdCents
        }
      }
    }
  `,
});
