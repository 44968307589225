import axios from "axios";

import { getAuthorizationHeader } from "./auth";
import { getApiBaseUrl } from "./misc";

export const getS3KeyFromUrl = (url: string) => new URL(url).pathname.slice(1);

export const getS3DownloadUrl = async (s3Key: string): Promise<string> => {
  const downloadUrl = new URL(`${getApiBaseUrl()}/download`);
  downloadUrl.searchParams.append("key", s3Key);
  const response = await axios.get(downloadUrl.toString(), {
    headers: getAuthorizationHeader(),
  });

  return response.data;
};
