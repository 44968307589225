/**
 * @generated SignedSource<<021be127fae4908a7e46982d48274968>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersScreenerTerminateRespondentChoices = "ET" | "IT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AutomatedEmailEditor_study$data = {
  readonly allowAutoApproval: boolean;
  readonly externalIncentive: {
    readonly claimLink: string | null;
  } | null;
  readonly id: string;
  readonly screener: {
    readonly terminateRespondent: ScreenersScreenerTerminateRespondentChoices;
  } | null;
  readonly tenant: {
    readonly emailTemplates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly message: string;
          readonly name: string;
          readonly replyTo: string;
          readonly subject: string;
        } | null;
      } | null>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SaveTemplateModal_tenant" | "SelectTemplateModal_tenant">;
  };
  readonly " $fragmentType": "AutomatedEmailEditor_study";
};
export type AutomatedEmailEditor_study$key = {
  readonly " $data"?: AutomatedEmailEditor_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"AutomatedEmailEditor_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AutomatedEmailEditor_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAutoApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalIncentiveNode",
      "kind": "LinkedField",
      "name": "externalIncentive",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "claimLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScreenerNode",
      "kind": "LinkedField",
      "name": "screener",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminateRespondent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailTemplateConnection",
          "kind": "LinkedField",
          "name": "emailTemplates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailTemplateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EmailTemplateNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "replyTo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subject",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SelectTemplateModal_tenant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaveTemplateModal_tenant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "8c4d8f8923b8db51c2c12e8e82a54a6b";

export default node;
