/**
 * @generated SignedSource<<661a2aff3ea2e9ec2e563ff26731c119>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersElementTypeChoices = "AV" | "CM" | "DS" | "DT" | "FL" | "GS" | "MS" | "NM" | "OE" | "RH" | "RK" | "SS" | "VD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Row_element$data = {
  readonly characteristic: {
    readonly id: string;
  } | null;
  readonly type: ScreenersElementTypeChoices;
  readonly " $fragmentType": "Row_element";
};
export type Row_element$key = {
  readonly " $data"?: Row_element$data;
  readonly " $fragmentSpreads": FragmentRefs<"Row_element">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Row_element",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CharacteristicNode",
      "kind": "LinkedField",
      "name": "characteristic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElementNode",
  "abstractKey": null
};

(node as any).hash = "04acc30897332cd061e42483e246bab5";

export default node;
