import { useUpdateEffect } from "@react-hookz/web";
import { Button, Upload } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import classNames, { Argument } from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchQuery, useFragment, useMutation } from "react-relay";
import styled from "styled-components";

import { Avatar } from "../..";
import { ConfigProviderAccent } from "../../../antd";
import { environment } from "../../../relay";
import { handleErrorWithMessage, sentenceCase } from "../../../utils";
import type { FormPersonAvatar_person$key } from "../../../__generated__/FormPersonAvatar_person.graphql";
import type { FormPersonAvatar_personAvatarUploadUrlQuery } from "../../../__generated__/FormPersonAvatar_personAvatarUploadUrlQuery.graphql";
import type { FormPersonAvatar_UpdatePerson_Mutation } from "../../../__generated__/FormPersonAvatar_UpdatePerson_Mutation.graphql";

const IMAGE_TYPES = ["image/jpeg", "image/png", "image/webp"];

const getUploadUrl = async (fileName: string, fileType: string) => {
  try {
    const data = await fetchQuery<FormPersonAvatar_personAvatarUploadUrlQuery>(
      environment,
      graphql`
        query FormPersonAvatar_personAvatarUploadUrlQuery($fileName: String!, $fileType: String!) {
          personAvatarUploadUrl(fileName: $fileName, fileType: $fileType)
        }
      `,
      { fileName, fileType },
      {
        fetchPolicy: "network-only",
      }
    ).toPromise();
    if (!data) throw new Error("Unable to resolve upload URL");
    return data.personAvatarUploadUrl;
  } catch (e: any) {
    handleErrorWithMessage(e, e.errors?.[0]?.message ?? e.message);
    return null;
  }
};

export const FormPersonAvatar = ({
  className,
  onReset,
  onTouch,
  person: personKey,
}: {
  className?: Argument;
  onReset: () => void;
  onTouch: () => void;
  person: FormPersonAvatar_person$key;
}) => {
  const person = useFragment(
    graphql`
      fragment FormPersonAvatar_person on PersonNode {
        id
        avatarUrl
        firstName
        lastName
      }
    `,
    personKey
  );

  const [commit] = useMutation<FormPersonAvatar_UpdatePerson_Mutation>(graphql`
    mutation FormPersonAvatar_UpdatePerson_Mutation($input: UpdatePersonInput!) {
      updatePerson(input: $input) {
        person {
          id
          ...FormPersonAvatar_person
        }
      }
    }
  `);
  const [isInFlight, setIsInFlight] = useState(false);

  useUpdateEffect(() => {
    if (isInFlight) onTouch?.();
    else onReset?.();
  }, [isInFlight]);

  const { t } = useTranslation();

  return (
    <Root className={classNames("hub-form-person-avatar", className)}>
      <Avatar size={96} src={person.avatarUrl} />
      <Upload
        accept={IMAGE_TYPES.join(",")}
        action={async file => (await getUploadUrl(file.name, file.type)) as string}
        customRequest={async ({ action, file, onError, onProgress, onSuccess }) => {
          // this is for typing, we don't expect the file to ever not be an UploadFile
          if (typeof file !== "object" || !Object.hasOwn(file, "uid")) return;

          // cheeky windows user switched to "all files" and tried to upload something invalid
          if (!IMAGE_TYPES.includes(file.type)) return onError?.(new Error("Invalid file type."));

          try {
            setIsInFlight(true);
            const res = await axios.put(action, file, {
              headers: {
                "Content-Type": typeof file === "string" ? "text/plain" : file.type,
              },
              onUploadProgress: progressEvent => {
                onProgress?.({
                  ...progressEvent,
                  percent: (progressEvent.loaded / progressEvent.total) * 100,
                });
              },
            });

            commit({
              onCompleted: () => {
                setIsInFlight(false);
                onSuccess?.(res.data, res.request);
              },
              onError: (e: any) => {
                setIsInFlight(false);
                handleErrorWithMessage(e, e.errors?.[0]?.message);
              },
              variables: {
                input: {
                  avatarUrl: action.split("?")[0],
                },
              },
              onUnsubscribe: () => alert("wao"),
            });
          } catch (e) {
            setIsInFlight(false);
            if (axios.isAxiosError(e))
              onError?.(
                {
                  ...e,
                  method: "PUT",
                  status: e.response?.status,
                  url: action,
                },
                e.request.data
              );
            else onError?.(e as any);
          }
        }}
        maxCount={1}
        showUploadList={false}
      >
        <ConfigProviderAccent>
          <Button disabled={isInFlight} loading={isInFlight} type="primary">
            {sentenceCase(`${t("dictionary.verb.upload")} ${t("dictionary.noun.photo")}`)}
          </Button>
        </ConfigProviderAccent>
      </Upload>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
