/**
 * @generated SignedSource<<b54808e8b79e559e0f2eee7639b07f43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommunicationComposePage_study$data = {
  readonly dId: any | null;
  readonly id: string;
  readonly name: string | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly dId: any | null;
    readonly emailDomain: string | null;
    readonly googleCalendarEmail: string | null;
    readonly name: string;
    readonly vpmAccountId: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"SaveTemplateModal_tenant">;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentSpreads": FragmentRefs<"FormItemParticipants_study">;
  readonly " $fragmentType": "CommunicationComposePage_study";
};
export type CommunicationComposePage_study$key = {
  readonly " $data"?: CommunicationComposePage_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationComposePage_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationComposePage_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleCalendarEmail",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmAccountId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaveTemplateModal_tenant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "args": [
        {
          "kind": "Variable",
          "name": "count",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "kind": "FragmentSpread",
      "name": "FormItemParticipants_study"
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "8f056ba426c370a7f9a121f1f2ba5340";

export default node;
