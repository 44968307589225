/**
 * @generated SignedSource<<03b6862b25e0abece44de9f7b4725f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Element_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuditionTextElement_screener" | "AuditionVideoElement_screener" | "CommentElement_screener" | "DateElement_screener" | "DropdownElement_screener" | "ElementBase_screener" | "FilesElement_screener" | "GridSingleSelectElement_screener" | "MultiChoiceElement_screener" | "NumberElement_screener" | "OpenEndElement_screener" | "RankElement_screener" | "SingleChoiceElement_screener" | "VideoElement_screener">;
  readonly " $fragmentType": "Element_screener";
};
export type Element_screener$key = {
  readonly " $data"?: Element_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"Element_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Element_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SingleChoiceElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultiChoiceElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RankElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GridSingleSelectElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DropdownElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OpenEndElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuditionTextElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuditionVideoElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NumberElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VideoElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DateElement_screener"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FilesElement_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "bb8b5fd86b8dcbf60ba977f311108ec9";

export default node;
