/**
 * @generated SignedSource<<6c6fa0019a44d09d333b8b05d281a411>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilesElement_screener$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ElementBase_screener">;
  readonly " $fragmentType": "FilesElement_screener";
};
export type FilesElement_screener$key = {
  readonly " $data"?: FilesElement_screener$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilesElement_screener">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilesElement_screener",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementBase_screener"
    }
  ],
  "type": "ScreenerNode",
  "abstractKey": null
};

(node as any).hash = "28a200130ec9129ad32df7fbaf6dc675";

export default node;
