/**
 * @generated SignedSource<<94f0561c72921cf69393bb4bef6d1d84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersCharacteristicTypeChoices = "DT" | "GS" | "MS" | "NM" | "OE" | "SS" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecruitMatch_study$data = {
  readonly dId: any | null;
  readonly id: string;
  readonly name: string | null;
  readonly panelFilters: any | null;
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly characteristics: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly answers: ReadonlyArray<{
            readonly id: string;
            readonly text: string;
          }>;
          readonly id: string;
          readonly importKey: string;
          readonly rows: ReadonlyArray<{
            readonly id: string;
            readonly text: string;
          }>;
          readonly shortName: string;
          readonly text: string | null;
          readonly type: ScreenersCharacteristicTypeChoices;
        } | null;
      } | null>;
    };
    readonly dId: any | null;
    readonly enablePanelExport: boolean;
    readonly globalCharacteristics: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly answers: ReadonlyArray<{
            readonly id: string;
            readonly text: string;
          }>;
          readonly id: string;
          readonly importKey: string;
          readonly rows: ReadonlyArray<{
            readonly id: string;
            readonly text: string;
          }>;
          readonly shortName: string;
          readonly text: string | null;
          readonly type: ScreenersCharacteristicTypeChoices;
        } | null;
      } | null>;
    } | null;
    readonly name: string;
    readonly vpmAccountId: number | null;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly " $fragmentType": "RecruitMatch_study";
};
export type RecruitMatch_study$key = {
  readonly " $data"?: RecruitMatch_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecruitMatch_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CharacteristicNode",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "importKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      (v4/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CharacteristicAnswerNode",
        "kind": "LinkedField",
        "name": "answers",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CharacteristicRowNode",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecruitMatch_study",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panelFilters",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enablePanelExport",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CharacteristicNodeConnection",
          "kind": "LinkedField",
          "name": "characteristics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CharacteristicNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": (v6/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TenantGlobalCharacteristicsConnection",
          "kind": "LinkedField",
          "name": "globalCharacteristics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantGlobalCharacteristicsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": (v6/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmAccountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "b846f08034fb14243ed92ba0d3a14435";

export default node;
