import React from "react";
import styled from "styled-components";
import { LAYOUT_BG_COLOR } from "../style";

export const EmptyApp: React.FC<{}> = () => <Wrapper />;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${LAYOUT_BG_COLOR};
`;
