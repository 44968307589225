/**
 * @generated SignedSource<<99a4553134cf8253834cd54a212bf56d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ScreenersFilterTypeChoices = "PN" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DrawerFilters_viewer$data = {
  readonly study: {
    readonly filters: ReadonlyArray<{
      readonly filters: any;
      readonly id: string;
      readonly name: string;
      readonly study: {
        readonly id: string;
      } | null;
      readonly type: ScreenersFilterTypeChoices;
      readonly user: {
        readonly id: string;
      } | null;
    }>;
    readonly id: string;
    readonly screener: {
      readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_screener">;
    } | null;
  } | null;
  readonly user: {
    readonly id: string;
    readonly profile: {
      readonly tenant: {
        readonly filters: ReadonlyArray<{
          readonly filters: any;
          readonly id: string;
          readonly name: string;
          readonly study: {
            readonly id: string;
          } | null;
          readonly type: ScreenersFilterTypeChoices;
          readonly user: {
            readonly id: string;
          } | null;
        }>;
        readonly id: string;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"InputFilterGroupGroup_tenant">;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "DrawerFilters_viewer";
};
export type DrawerFilters_viewer$key = {
  readonly " $data"?: DrawerFilters_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DrawerFilters_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FilterNode",
  "kind": "LinkedField",
  "name": "filters",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filters",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "studyId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./DrawerFiltersViewerRefetchQuery.graphql')
    }
  },
  "name": "DrawerFilters_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "studyId",
          "variableName": "studyId"
        }
      ],
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ScreenerNode",
          "kind": "LinkedField",
          "name": "screener",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InputFilterGroupGroup_screener"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TenantNode",
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "InputFilterGroupGroup_tenant"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "e67e007f2cadb3a09851e9f164541b07";

export default node;
