import runtimeEnv from "@mars/heroku-js-runtime-env";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { App, Button, Switch } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie-consent";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import {
  HEADER_FONT_SIZE,
  HEADER_FONT_WEIGHT,
  PAGE_WIDTH_LG,
  PAGE_WIDTH_XL,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../../style";
import { getAuthorizationHeader, handleErrorWithMessage, mutation, useFlag } from "../../utils";
import { getApiBaseUrl, hasConsentedToCookies, managePaymentMethod } from "../../utils/misc";
import { WorkspaceSettings_user$data } from "../../__generated__/WorkspaceSettings_user.graphql";

const env = runtimeEnv();

type Props = {
  user: WorkspaceSettings_user$data;
  match: any;
};
const WorkspaceSettings: React.FC<Props> = ({ user, match }) => {
  const { message } = App.useApp();

  const [calAuthMsgShown, setCalAuthMsgShown] = useState<boolean>(false);
  const [paymentUpdatedMsgShown, setPaymentUpdatedMsgShown] = useState<boolean>(false);
  const [fakeWidgetEnabledToggle, setFakeWidgetEnabledToggle] = useState<boolean>(
    user?.profile?.tenant?.fakeWidgetEnabled !== undefined ? user.profile.tenant.fakeWidgetEnabled : false
  );
  const [threatDetectionToggle, setThreatDetectionToggle] = useState<boolean>(
    user?.profile?.tenant?.enableThreatDetection !== undefined ? user.profile.tenant.enableThreatDetection : true
  );
  const [requirePhoneNumberToggle, setRequirePhoneNumberToggle] = useState<boolean>(
    user.profile?.tenant?.requirePhoneNumber !== undefined ? user.profile.tenant.requirePhoneNumber : false
  );
  const [consentToCookiesToggle, setConsentToCookiesToggle] = useState<boolean>(hasConsentedToCookies());
  const [loadingManagePayment, setLoadingManagePayment] = useState<boolean>(false);
  const [headerMarkupEnabled, setHeaderMarkupEnabled] = useState<boolean>(
    user?.profile?.tenant?.customportal?.headerMarkupEnabled !== undefined
      ? user.profile.tenant.customportal.headerMarkupEnabled
      : false
  );
  const [code, setCode] = React.useState(
    user.profile?.tenant?.customportal?.headerMarkup ||
      `<div class="hw">
  Hello World
</div>

<style>
.hw {
  color: blue;
}
</style>

<script>
function helloWorld() {
  alert('Hello World!');
}
</script>`
  );
  const [codeSaving, setCodeSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!calAuthMsgShown) {
      if (match.location.query["cal-auth-success"] === "true") {
        setCalAuthMsgShown(true);
        message.success("Calendar successfully connected");
      } else if (match.location.query["cal-auth-success"] === "false") {
        setCalAuthMsgShown(true);
        message.error("Calendar connection error");
      }
    }

    if (!paymentUpdatedMsgShown) {
      if (match.location.query["update-payment-success"] === "true") {
        setPaymentUpdatedMsgShown(true);
        message.success("Payment method updated");
      } else if (match.location.query["update-payment-success"] === "false") {
        setPaymentUpdatedMsgShown(true);
        message.error("Payment method update error. Please try again.");
      }
    }
  }, [match, calAuthMsgShown, paymentUpdatedMsgShown, message]);

  const updateSettingsMutation = (input: {
    enableThreatDetection?: boolean;
    requirePhoneNumber?: boolean;
    headerMarkupEnabled?: boolean;
    headerMarkup?: string;
    fakeWidgetEnabled?: boolean;
  }) => {
    const { enableThreatDetection, requirePhoneNumber, headerMarkupEnabled, headerMarkup, fakeWidgetEnabled } = input;
    return mutation({
      variables: {
        input: {
          enableThreatDetection,
          requirePhoneNumber,
          headerMarkupEnabled,
          headerMarkup,
          fakeWidgetEnabled,
        },
      },
      mutation: graphql`
        mutation WorkspaceSettings_UpdateSettings_Mutation($input: UpdateWorkspaceSettingsInput!) {
          updateWorkspaceSettings(input: $input) {
            tenant {
              enableThreatDetection
              requirePhoneNumber
              customportal {
                headerMarkup
                headerMarkupEnabled
              }
            }
          }
        }
      `,
    }).then(() => {
      if (enableThreatDetection !== undefined) {
        message.info(`Quality filter ${enableThreatDetection ? "enabled" : "disabled"}`);
      } else if (requirePhoneNumber !== undefined) {
        message.info(`Require phone numbers ${requirePhoneNumber ? "enabled" : "disabled"}`);
      } else if (headerMarkupEnabled !== undefined) {
        message.info(`Portal Banner custom code ${headerMarkupEnabled ? "enabled" : "disabled"}`);
      }
    });
  };

  const redirectToGoogleAuth = () => {
    // call back-end to get auth URL
    const url = `${getApiBaseUrl()}/calendar-auth/auth-url`;

    axios
      .request({
        method: "get",
        url,
        headers: getAuthorizationHeader(),
      })
      .then(response => {
        if (response.data.url) {
          // redirect to auth URL
          window.location.href = response.data.url;
        } else {
          handleErrorWithMessage("Could not connect calendar. Error: did not receive URL");
        }
      })
      .catch(err => {
        if (err.response.data.detail) {
          console.error(err.response.data.detail);
        }
        handleErrorWithMessage("Could not connect calendar. Error: could not get auth URL");
      });
  };

  const changeFakeWidgetEnabled = async (checked: boolean) => {
    setFakeWidgetEnabledToggle(checked);
    try {
      await updateSettingsMutation({ fakeWidgetEnabled: checked });
    } catch (e) {
      handleErrorWithMessage(e);
    }
  };

  const changeThreatDetection = async (checked: boolean) => {
    setThreatDetectionToggle(checked);
    try {
      await updateSettingsMutation({ enableThreatDetection: checked });
    } catch (e) {
      handleErrorWithMessage(e);
    }
  };

  const changeRequirePhoneNumberToggle = async (checked: boolean) => {
    setRequirePhoneNumberToggle(checked);
    try {
      await updateSettingsMutation({ requirePhoneNumber: checked });
    } catch (e) {
      handleErrorWithMessage(e);
    }
  };

  const changeConsentToCookies = (checked: boolean) => {
    setConsentToCookiesToggle(checked);
    const newValue = checked ? "true" : "false";
    Cookies.set("CookieConsent", newValue);
  };

  const changePortalBannerCodeToggle = async (checked: boolean) => {
    setHeaderMarkupEnabled(checked);
    try {
      await updateSettingsMutation({ headerMarkupEnabled: checked });
    } catch (e) {
      handleErrorWithMessage(e);
    }
  };

  const saveMarkup = async () => {
    try {
      setCodeSaving(true);
      await updateSettingsMutation({ headerMarkup: code });
      message.info(`Markup code saved.`);
    } catch (e) {
      handleErrorWithMessage(e);
    } finally {
      setCodeSaving(false);
    }
  };

  const managePayment = async () => {
    setLoadingManagePayment(true);
    await managePaymentMethod(match.location.pathname);
    setLoadingManagePayment(false);
  };

  const showFakeWidgetEnabled = useFlag("fake-widget-enabled");
  const researchDefenderEnabled = useFlag("hub-screener-researchdefender");
  const paymentsEnabled = useFlag("hub-recruit-prepay");

  return (
    <Styled>
      <div className="wrapper">
        <div className="inner">
          <div className="heading">
            <div className="img-wrapper">
              <img src="/desktop-work-area.png" alt="Workspace" width="150" height="150" />
            </div>
            <h1>{user?.profile?.tenant?.name} Workspace Setup</h1>
          </div>
          {!!showFakeWidgetEnabled && (
            <div className="setting">
              <div className="setting-switch-container">
                <Switch checked={fakeWidgetEnabledToggle} onChange={changeFakeWidgetEnabled} />
                <div className="setting-label">Fake widget</div>
              </div>
              <div className="setting-description">Add a fake widget to the screener and portal.</div>
            </div>
          )}
          {!!researchDefenderEnabled && (
            <div className="setting">
              <div className="setting-switch-container">
                <Switch checked={threatDetectionToggle} onChange={changeThreatDetection} />
                <div className="setting-label">Quality Filter</div>
              </div>
              <div className="setting-description">
                Prevent spammers from completing your screeners with an automated quality filter.
              </div>
            </div>
          )}
          <div className="setting">
            <div className="setting-switch-container">
              <Switch checked={requirePhoneNumberToggle} onChange={changeRequirePhoneNumberToggle} />
              <div className="setting-label">Require Phone Numbers</div>
            </div>
            <div className="setting-description">
              We ask for phone numbers at the end of screeners. This setting turns that field to required.
            </div>
          </div>
          <div className="setting">
            <div className="setting-switch-container">
              <Switch checked={consentToCookiesToggle} onChange={changeConsentToCookies} />
              <div className="setting-label">Cookies</div>
            </div>
            <div className="setting-description">
              We use cookies in Voxpopme & HubUX to track your usage of our platform, provide assistance, and improve
              the product. This setting allows us to help you with issues you're experiencing.
            </div>
          </div>
          {user.profile?.tenant?.enableCustomPortal && (
            <div className="setting" style={{ paddingBottom: 12 }}>
              <div className="setting-switch-container">
                <Switch checked={headerMarkupEnabled} onChange={changePortalBannerCodeToggle} />
                <div className="setting-label">Enable Portal Banner Custom Code</div>
              </div>
              <div className="setting-description">
                Execute custom code in the Panelist Portal banner. You can use HTML, CSS, and JavaScript to customize
                the style and function of the banner.
              </div>
            </div>
          )}
          {user.profile?.tenant?.enableCustomPortal && headerMarkupEnabled && (
            <div className="setting" style={{ padding: 0 }}>
              <CodeEditor
                value={code}
                language="html"
                placeholder="Please enter HTML code."
                onChange={evn => setCode(evn.target.value)}
                padding={15}
                style={{
                  fontSize: 12,
                  backgroundColor: "#f5f5f5",
                  fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                  width: "100%",
                }}
              />
              <div className="setting-description" style={{ marginTop: 12 }}>
                CSS should be wrapped in style tags, and JavaScript should be wrapped in script tags. You can include
                %panelist.id% variables.
              </div>
              <Button
                type="primary"
                loading={codeSaving}
                style={{ float: "right", marginTop: 12 }}
                onClick={() => {
                  saveMarkup();
                }}
              >
                Save
              </Button>
            </div>
          )}
          <div className="setting">
            <div className="setting-button-container">
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  redirectToGoogleAuth();
                }}
              >
                Google Calendar Integration
              </Button>
              {user.profile?.tenant?.calendarConnected && (
                <div className="button-info">{user?.profile?.tenant?.googleCalendarEmail} connected</div>
              )}
            </div>
            <div className="setting-description">
              Want to send invites from your company email address instead of{" "}
              {env.REACT_APP_INVITES_EMAIL || "invites@hubux.net"}? Connect your calendar and grant access to HubUX to
              view and edit events in your calendar.
            </div>
          </div>
          {!!paymentsEnabled && (
            <div className="setting">
              <div className="setting-button-container">
                <Button size="large" type="primary" loading={loadingManagePayment} onClick={() => managePayment()}>
                  {user.profile?.tenant?.stripePaymentMethodId ? "Manage Payment Method" : "Add Payment Method"}
                </Button>
              </div>
              <div className="setting-description">
                Add / edit a payment method for project costs. You will be redirected to our payment partner, Stripe.
              </div>
            </div>
          )}
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .wrapper {
    margin: auto;
    padding-bottom: 24px;
    @media (max-width: 1080px) {
      width: ${PAGE_WIDTH_LG};
    }
    @media (min-width: 1200px) {
      width: ${PAGE_WIDTH_XL};
    }

    .inner {
      max-width: 600px;
      margin: auto;
      padding-top: 48px;

      .heading {
        padding: 24px 0 24px 0;
        .img-wrapper {
          display: flex;
          justify-content: center;
        }

        h1 {
          text-align: center;
          font-size: ${HEADER_FONT_SIZE};
          font-weight: ${HEADER_FONT_WEIGHT};
          color: #000000;
        }
      }

      .setting {
        padding: 24px 0px;
        clear: right;

        .setting-label {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 500;
          color: ${PRIMARY_TEXT_COLOR};
        }

        .setting-heading {
          font-size: 16px;
          font-weight: 500;
          color: ${PRIMARY_TEXT_COLOR};
        }

        .setting-description {
          font-size: 12px;
          color: ${SECONDARY_TEXT_COLOR};
        }

        .setting-button-container {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          margin-bottom: 12px;

          .button-info {
            margin-left: 12px;
            font-size: 14px;
            font-weight: bold;
            color: ${PRIMARY_TEXT_COLOR};
          }
        }

        .setting-switch-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 12px;
        }
      }
    }
  }
`;

export default createFragmentContainer(WorkspaceSettings, {
  user: graphql`
    fragment WorkspaceSettings_user on UserNode {
      profile {
        tenant {
          name
          calendarConnected
          googleCalendarEmail
          enableThreatDetection
          stripePaymentMethodId
          requirePhoneNumber
          enableCustomPortal
          customportal {
            headerMarkup
            headerMarkupEnabled
          }
          fakeWidgetEnabled
        }
      }
    }
  `,
});
