/*
 * Component showing a "Review" button, which opens a modal allowing the user
 * to review the respondent's study answers
 */
import { Button } from "antd";
import React from "react";

const CellInner: React.FC = ({ children }) => {
  return <div style={{ padding: 8 }}>{children}</div>;
};

const ResponsesCell = (value: any, openReviewModal: (participant: any) => void) => {
  return (
    <CellInner>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        onClick={e => e.stopPropagation()}
      >
        <Button onClick={() => openReviewModal(value)} type="primary" size="small">
          Start Review
        </Button>
      </div>
    </CellInner>
  );
};

export default ResponsesCell;
