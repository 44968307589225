/**
 * @generated SignedSource<<652a5520bb8289833d8df4da2e5c9d89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyScheduledByChoices = "P" | "T" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Cells_ApproveRejectedCell_study$data = {
  readonly id: string;
  readonly scheduledBy: ScreenersStudyScheduledByChoices;
  readonly type: ScreenersStudyTypeChoices;
  readonly usesApiToMarkComplete: boolean;
  readonly " $fragmentType": "Cells_ApproveRejectedCell_study";
};
export type Cells_ApproveRejectedCell_study$key = {
  readonly " $data"?: Cells_ApproveRejectedCell_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"Cells_ApproveRejectedCell_study">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Cells_ApproveRejectedCell_study",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usesApiToMarkComplete",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};

(node as any).hash = "f34abd87aef9f78dbd236babf360a7cf";

export default node;
