import { StarFilled } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { PROJECT_INCENTIVE_TYPES } from "../../constants";
import { LINK_TEXT_COLOR } from "../../style";
import { TimeZoneContext, trackEvent } from "../../utils";
import { usdFormatterNeat } from "../../utils/misc";

const { Paragraph, Text } = Typography;

type CellInnerProps = {
  style?: any;
  children?: any;
};
export const CellInner: React.FC<CellInnerProps> = ({ style, children }) => {
  return <div style={style}>{children}</div>;
};

export const TextCell = ({ value }: any) => {
  return (
    <CellInner style={{ width: value?.width, maxWidth: "50vw", overflow: "hidden", whiteSpace: "nowrap" }}>
      <Text style={{ width: "100%" }} ellipsis={{ tooltip: value.text }}>
        {value.text}
      </Text>
    </CellInner>
  );
};

export const WrappedTextCell = ({ value }: any) => {
  return (
    <CellInner style={{ width: value?.width, minWidth: "250px", maxWidth: "70vw" }}>
      <Paragraph style={{ width: "100%" }} ellipsis={{ tooltip: value.text, rows: 2 }}>
        {value.text}
      </Paragraph>
    </CellInner>
  );
};

export const LinkedTextCell = ({ value }: any) => {
  return (
    <CellInner style={{ width: value?.width, overflow: "hidden", whiteSpace: "nowrap" }}>
      {
        <a
          href={value.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
          onClick={() =>
            trackEvent(`Clicked '${value.tableName}' link`, {
              "Link Text": value.text,
              "Link Location": value.link,
              "User dID": value.userdId,
              "Panelist dID": value.panelistdId,
              "Tenant Name": value.tenantName,
              Page: "Participant Portal",
            })
          }
        >
          <Text style={{ width: "100%", color: LINK_TEXT_COLOR }} ellipsis={{ tooltip: value.text }}>
            {value.text}
          </Text>
        </a>
      }
    </CellInner>
  );
};

export const PointsCell = ({ value }: any) => {
  return value.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL ? (
    <CellInner>&mdash;</CellInner>
  ) : (
    <CellInner>
      {value.incentiveType === PROJECT_INCENTIVE_TYPES.CASH ? (
        typeof value.incentive === "string" && value.incentive.length ? (
          `${usdFormatterNeat.format(parseFloat(value.incentive as string))}`
        ) : (
          <>&mdash;</>
        )
      ) : (
        <>
          <span style={{ display: "inline-block", width: "14px" }}>
            <StarFilled style={{ color: "#FADB14" }} />
          </span>
          <span style={{ marginLeft: "5px" }}>{value.incentivePoints}</span>
        </>
      )}
    </CellInner>
  );
};

export const StatusCell = ({ value }: any) => {
  const { shiftDate } = useContext(TimeZoneContext);

  const linkText = value?.status?.studyAvailabilitySlot?.start ? (
    <>
      <div>Scheduled for</div>
      <div>{shiftDate(moment(value.status.studyAvailabilitySlot.start)).format("MMMM Do, YYYY")}</div>
    </>
  ) : (
    value.status.statusType
  );

  return (
    <CellInner>
      <Tooltip title={value.status.tooltipText}>
        <a
          href={value.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackEvent(`Clicked '${value.tableName}' link`, {
              "Link Text": value.text,
              "Link Location": value.link,
              "User dID": value.userdId,
              "Panelist dID": value.panelistdId,
              "Tenant Name": value.tenantName,
              Page: "Participant Portal",
            })
          }
        >
          {linkText}
        </a>
      </Tooltip>
    </CellInner>
  );
};

export const DateCell = ({ value }: any) => {
  const { shiftDate } = useContext(TimeZoneContext);

  return (
    <CellInner style={{ whiteSpace: "nowrap" }}>
      {["Available now", "Pending"].includes(value) ? value : shiftDate(moment(value)).format("MM/DD/YY")}
    </CellInner>
  );
};
