import { Button } from "antd";
import classNames, { type Argument } from "classnames";
import type { CSSProperties } from "react";

import { Label } from "./Label";

type LabelProps = Parameters<typeof Label>[0];

export const ButtonLabel = ({
  className,
  icon,
  iconScale,
  labelStyle = {},
  style,
  text,
  ...props
}: Parameters<typeof Button>[0] &
  Pick<LabelProps, "iconScale"> & {
    className?: Argument;
    labelStyle?: CSSProperties;
  } & (
    | { icon?: LabelProps["icon"]; text: LabelProps["text"] }
    | { icon: LabelProps["icon"]; text?: LabelProps["text"] }
  )) => (
  <Button
    className={classNames(className)}
    style={{
      ...(!text ? { aspectRatio: "1", display: "grid", padding: 4, placeContent: "center" } : {}),
      ...style,
    }}
    {...props}
  >
    <Label icon={icon} iconScale={iconScale} style={{ justifyContent: "center", ...labelStyle }} text={text} />
  </Button>
);
