import { CHARACTERISTIC_ELEMENT_TYPES } from "../../../constants";

export const CHARACTERISTIC_ELEMENTS = [
  { type: CHARACTERISTIC_ELEMENT_TYPES.SINGLE_SELECT, label: "Single Choice" },
  { type: CHARACTERISTIC_ELEMENT_TYPES.MULTI_SELECT, label: "Multi Choice" },
  { type: CHARACTERISTIC_ELEMENT_TYPES.GRID_SINGLE_SELECT, label: "Grid" },
  { type: CHARACTERISTIC_ELEMENT_TYPES.NUMBER, label: "Number" },
  { type: CHARACTERISTIC_ELEMENT_TYPES.OPENEND, label: "Open-Ended" },
  { type: CHARACTERISTIC_ELEMENT_TYPES.DATE, label: "Date" },
];
