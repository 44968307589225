/**
 * @generated SignedSource<<7fe05fcd8eb72db239fff7a1429e5298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelFilters_recruit$data = {
  readonly dbId: string;
  readonly export: {
    readonly " $fragmentSpreads": FragmentRefs<"ExportButton_export">;
  } | null;
  readonly id: string;
  readonly name: string | null;
  readonly study: {
    readonly dId: any | null;
    readonly name: string | null;
  };
  readonly " $fragmentType": "PanelFilters_recruit";
};
export type PanelFilters_recruit$key = {
  readonly " $data"?: PanelFilters_recruit$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelFilters_recruit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelFilters_recruit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExportNode",
      "kind": "LinkedField",
      "name": "export",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExportButton_export"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNode",
      "kind": "LinkedField",
      "name": "study",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "RecruitNode",
  "abstractKey": null
};
})();

(node as any).hash = "4083d0280285e42cc872d9769ebfe801";

export default node;
