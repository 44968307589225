export const CHARACTERISTIC_TYPE = {
  TENANT_CHARACTERISTIC: "TENANT_CHARACTERISTIC",
  GLOBAL_CHARACTERISTIC: "GLOBAL_CHARACTERISTIC",
};

export const enum CHARACTERISTIC_ELEMENT_TYPES {
  DATE = "DT",
  GRID_SINGLE_SELECT = "GS",
  MULTI_SELECT = "MS",
  NUMBER = "NM",
  OPENEND = "OE",
  SINGLE_SELECT = "SS",
  FUTURE = "%future added value",
}

export const enum ELEMENT_TYPES {
  AUDITION_TEXT = "RH",
  AUDITION_VIDEO = "AV",
  COMMENT = "CM",
  DATE = "DT",
  DROPDOWN_SELECT = "DS",
  FILES = "FL",
  GRID_SINGLE_SELECT = "GS",
  MULTI_SELECT = "MS",
  NUMBER = "NM",
  OPENEND = "OE",
  RANK = "RK",
  SINGLE_SELECT = "SS",
  VIDEO = "VD",
  FUTURE = "%future added value",
}

export const QUAL_ELEMENTS = [ELEMENT_TYPES.AUDITION_TEXT, ELEMENT_TYPES.OPENEND, ELEMENT_TYPES.DATE];

export const NON_FILTERABLE_ELEMENT_TYPES = ["AV", "CM", "VD", "FL"];
export const SELECTABLE_ELEMENT_TYPES = ["SS", "MS", "DS", "GS"];

export const enum RESPONSE_VALIDATION_TYPE {
  EXACTLY = "EX",
  AT_LEAST = "AL",
  AT_MOST = "AM",
  BETWEEN = "BE",
}
